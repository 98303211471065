import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  background: var(--colors-background);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 25px;
  min-width: 100px;
  max-width: 360px;
`;

export const Title = styled.span`
  color: #3f4952;
  font-size: 14px;
`;

export const Updated = styled.span`
  font-weight: 500;
  font-size: 11px;
  color: var(--colors-oslo-gray-2);
`;

export const DateLabel = styled.span`
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #3f4952;
  white-space: nowrap;
`;

export const Status = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  background-color: #f3f1f1;
  color: var(--colors-river-bad);
  border-radius: 40px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  max-height: 28px;
  text-transform: capitalize;

  &[data-status="complete"] {
    background-color: #f3f1f1;
  }

  &[data-status="active"] {
    background-color: #b0d4ff;
  }
  &[data-status="past due"] {
    background-color: rgba(255, 0, 0, 0.15);
  }
  &[data-status="draft"] {
    background-color: #fbf7a3;
  }
  &[data-status="achieved"] {
    background-color: rgba(75, 81, 91, 0.37);
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  padding: 8px;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid var(--colors-eastern-blue);
  box-sizind: border-box;

  &.primary {
    background-color: var(--colors-eastern-blue);
    color: var(--colors-white);
  }

  &.secondary {
    color: var(--colors-eastern-blue);
    background-color: var(--colors-white);
  }

  &.disabled {
    pointer-events: none;
  }
`;
