import { FC, useState, useRef, useMemo } from "react";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { ReactComponent as ArrowIcon } from "../../../../assets/images/menu_arrow.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";
import { Option } from "react-select/src/filters";
import {
  Wrapper,
  SectionTitle,
  DropdownInput,
  Label,
  Divider,
  WorksheetSelect,
  WorksheetWrapper,
  StyledModal,
  modalFooterStyles,
} from "./WorkOrderSubtask.styled";
import { Button } from "../../../../components/Button";
import { Checkbox } from "../../../../components/Checkbox";
import { UNIT_NAMES } from "../../../../components/WorksheetArea";
import { WorkTypeItem } from "../../../../components/WorkTypeItem";
import { IconButton } from "../../../../components/IconButton";
import { RangeInput } from "../../../../components/RangeInput";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { getWorkTypeImage } from "../../../../pages/MapScreen/MapScreen.utils";
import { AddCrewType } from "../AddCrewType";
import { ICrewType, ISubtask, IWorkType } from "../../types";
import { useCrewTypes } from "../../hooks/useCrewTypes";
import {
  getTotal,
  getTotalWorkableArea,
  getAdditionalDescription,
  getWorktypeOptions,
  getTotalCost,
} from "../../utils/subtaskHelper";

interface IOption extends Option, IWorkType {}
interface IProps {
  subtask: ISubtask;
  deleteSubtask: (taskId: string) => void;
  setWorktypeQuantity: (taskId: string, worktypeId: string, quantity: number) => void;
  setCrewType: (taskId: string, crewType: ICrewType) => void;
  toggleWorkType: (taskId: string, worktypeId: string, removeTask?: boolean) => void;
}

export const WorkOrderSubtask: FC<IProps> = ({
  subtask,
  deleteSubtask,
  setCrewType,
  setWorktypeQuantity,
  toggleWorkType,
}) => {
  const { data } = useCrewTypes();
  const [isCrewPopupOpened, setCrewPopupOpened] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isCrewPopupOpened && setCrewPopupOpened(false));
  const [activeWorktype, setActiveWorktype] = useState<IWorkType | null>(null);
  const [rangeInputValue, setRangeInputValue] = useState(1);
  const wtOptions = useMemo(() => getWorktypeOptions(subtask.worktypes), [subtask]);
  const addedWorktypes = useMemo(() => subtask.worktypes.filter((item) => item.added), [subtask.worktypes]);

  const onQuantityClick = (activeWt: IWorkType) => {
    setActiveWorktype(activeWt);
    const rangValue = activeWt.quantity / activeWt.totalQuantity;

    setRangeInputValue(rangValue);
  };

  const onSave = () => {
    setWorktypeQuantity(subtask.id, activeWorktype?.workTypeId!, rangeInputValue * activeWorktype?.totalQuantity!);
    setActiveWorktype(null);
  };

  return (
    <Wrapper>
      <StyledFlex alignItems="center" justifyContent="space-between" width="100%" mb="20px">
        <SectionTitle>Crew Type</SectionTitle>
        <Button variant="warningSecondary" fontSize="14px" onClick={() => deleteSubtask(subtask.id)}>
          Delete
        </Button>
      </StyledFlex>
      <DropdownInput ref={wrapperRef} onClick={() => setCrewPopupOpened(!isCrewPopupOpened)}>
        <span className={subtask.selectedCrewType ? "haveCrew" : ""}>
          {subtask.selectedCrewType ? subtask.selectedCrewType.name : "Choose Crew Type"}
        </span>
        <ArrowIcon />
        {isCrewPopupOpened && (
          <AddCrewType
            crewTypes={data!}
            setSelectedCrew={(crew) => setCrewType(subtask.id, crew)}
            closePopup={() => setCrewPopupOpened(false)}
          />
        )}
      </DropdownInput>
      {subtask.selectedCrewType && (
        <StyledFlex gap="20px" mt="30px">
          <StyledFlex flexDirection="column" gap="5px">
            <Label>Mandatory Members</Label>
            <span>{subtask.selectedCrewType?.memberTypes?.map((memType) => memType.name).join(", ")}</span>
          </StyledFlex>
          <StyledFlex flexDirection="column" gap="5px">
            <Label>Mandatory Equipment</Label>
            <span>{subtask.selectedCrewType?.equipmentTypes?.map((memType) => memType.name).join(", ")}</span>
          </StyledFlex>
        </StyledFlex>
      )}
      <Divider />
      <StyledFlex flexDirection="column">
        <StyledFlex alignItems="center" justifyContent="space-between" mb="20px">
          <SectionTitle>Worksheet</SectionTitle>
          <Label className="totalCost">
            Total estimated cost: <span>{getTotalCost(subtask.worktypes)}</span>{" "}
          </Label>
        </StyledFlex>
        <WorksheetSelect
          name="worksheet"
          placeholder="Choose Work Type"
          options={wtOptions}
          value={null}
          closeMenuOnSelect={false}
          isSearchable
          formatOptionLabel={(option: IOption) => (
            <Checkbox
              id={option.workTypeId}
              label={option.label}
              name={option.value}
              value={option.value}
              checked={option.added}
              defaultChecked={option.added}
              onClick={() => toggleWorkType(subtask.id, option.workTypeId)}
              image={getWorkTypeImage(option.code)}
            />
          )}
        />
      </StyledFlex>
      <WorksheetWrapper mt="20px">
        {addedWorktypes.map((activeWt) => (
          <WorkTypeItem
            key={activeWt.workTypeId}
            id={activeWt.workTypeId}
            name={activeWt.category}
            isAutoAssigned={activeWt.autoAssigned}
            showOnlyDelete={true}
            {...activeWt}
            deleteWorkType={() => toggleWorkType(subtask.id, activeWt.workTypeId, true)}
          >
            <WorkTypeItem.AdditionalDescription
              onClick={() => onQuantityClick(activeWt)}
              label={getAdditionalDescription(activeWt)}
            />
          </WorkTypeItem>
        ))}
      </WorksheetWrapper>
      {activeWorktype && (
        <StyledModal hidden={!activeWorktype} p="35px">
          <StyledFlex
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #F4F4F4"
            p="20px 20px 10px"
          >
            <SectionTitle>Edit Work</SectionTitle>
            <IconButton Icon={CloseIcon} className="close" onClick={() => setActiveWorktype(null)} />
          </StyledFlex>
          <StyledFlex flexDirection="column" p="10px 20px">
            <Label className="modalLabel">Total Workable Area: {getTotalWorkableArea(activeWorktype)}</Label>
            <RangeInput
              defaultValue={rangeInputValue}
              border="1px solid #F4F4F4"
              onClick={(e) => setRangeInputValue(Number(e.currentTarget.value))}
              step={UNIT_NAMES[activeWorktype.unit] === "tree" ? 1 / activeWorktype.totalQuantity : 0.1}
            />
          </StyledFlex>
          <StyledFlex {...modalFooterStyles}>
            <SectionTitle className="capitalize">Total: {getTotal(activeWorktype, rangeInputValue)}</SectionTitle>
            <Button variant="primary" fontSize="14px" padding="10px 20px" onClick={onSave}>
              Save
            </Button>
          </StyledFlex>
        </StyledModal>
      )}
    </Wrapper>
  );
};
