import { useMutation, useQueryClient } from "react-query";
import { workOrderUrl } from "../../../api";
import ApiClient from "../../../utils/apiClient";
import { ICreateWorkOrder } from "../types";

const createWorkOrder = (data: ICreateWorkOrder) => ApiClient.post(workOrderUrl, data).then((res) => res.data);

export const useCreateWorkOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(async (data: ICreateWorkOrder) => createWorkOrder(data), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(`work-orders-for-${variables.objectId}`);
    },
  });
};
