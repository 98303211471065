import styled from "styled-components";

import { Data1 } from "../../assets/styles/fonts.styled";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const CommentWrapper = styled.div`
  display: flex;
  .comment-status path {
    stroke: none;
  }
`;

export const CommentReplyWrapper = styled.div`
  display: flex;
`;

export const CommentIcon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CommentContent = styled.div<{ selected?: boolean; hoverDisabled?: boolean }>`
  background-color: ${(props) => (props.selected ? props.theme.colors.whiteIce : props.theme.colors.white)};
  box-shadow: ${(props) => (props.hoverDisabled ? "none" : "0px 4px 1px rgba(0, 0, 0, 0.01);")};
  border-radius: 6px;
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  :hover {
    ${(props) => {
      if (!props.hoverDisabled) {
        return `background-color: #eeeeee;`;
      }
    }};
  }
  cursor: ${(props) => (props.hoverDisabled ? "default" : "pointer")};
`;

export const ReplyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CommentContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .comment-username {
    ${Data1}
    display: flex;
    align-items: center;
    .comment-time {
      margin-left: 5px;
      font-weight: 500;
      font-size: 11px;
      color: #b1b1b1;
    }
  }
`;

export const CommentContentTxt = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #7b8182;
  min-height: 20px;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
`;

export const CommentContentReplies = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: ${(props) => props.theme.colors.easternBlue};
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 10px;
  svg {
    margin-right: 2px;
  }
`;

export const CommentStatus = styled.div`
  cursor: pointer;
`;

export const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EnterCommentWrapper = styled.div``;

export const EnterCommentTextarea = styled.textarea`
  border: none;
  padding: 10px;
  outline: none;
  resize: none;
  border-radius: 10px;
  ::placeholder {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    color: var(--colors-silver-2);
  }
`;
