import styled from "styled-components";

import { Data2 } from "../../../assets/styles/fonts.styled";

export const SpecialLayerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 17px;
  margin-bottom: 10px;

  div {
    background: var(--colors-white);
    border: 1px solid var(--colors-gallery);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    height: 50px;
    display: flex;
    ${Data2}
    padding-left: 15px;
    label {
      img {
        background-color: transparent;
        width: 42px;
        height: 30px;
      }
      span {
        font-size: 14px;
      }
    }
  }
`;
