import styled, { css } from "styled-components";

import { WHITE } from "../../constants/colors";
import { MAP_MENU, RIGHT_DRAWER } from "../../constants/z-index";
import { Head1, Head2, Data2 } from "../../assets/styles/fonts.styled";
import { LAYERS_MENU_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";
import { IconButton } from "../IconButton";

const FIXED_HEADER_HEIGHT = 61;

export const LayersMenuContainer = styled.div<{ isRightDrawerVisible: boolean; isLayerControlVisible: boolean }>`
  background-color: ${(props) => props.theme.colors.white};
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMCAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjQwNzUgMTAuMjIyQzE5LjM4NzYgMTAuMjA4MiAxOS4zNjcxIDEwLjE5NTUgMTkuMzQ1OSAxMC4xODM5TDE3Ljk0NjkgOS40MDM1NkwxMC4xODgyIDEzLjY1ODFDOS45ODIwNyAxMy43NzEyIDkuNzM1MjcgMTMuNzcxMiA5LjUyOTE1IDEzLjY1ODFMMS43NzA1IDkuNDAzNTZMMC4zNzEzOTIgMTAuMTgzOUMwLjAzMTM1MjQgMTAuMzcwOCAtMC4wOTgwMjkzIDEwLjgwNzkgMC4wODI0Njk3IDExLjE2QzAuMDkzNjkxNiAxMS4xODE5IDAuMTA1OTg2IDExLjIwMzIgMC4xMTkzMTIgMTEuMjIzOEw5Ljg1ODY3IDE2LjU2NDJMMTkuNTk4IDExLjIyMzhDMTkuODEyNSAxMC44OTI3IDE5LjcyNzIgMTAuNDQ0MiAxOS40MDc1IDEwLjIyMloiIGZpbGw9IiMxRkE3QjMiLz4KPHBhdGggZD0iTTE5LjYzMiAxNC44NTU5QzE5LjU2NjggMTQuNzMwOCAxOS40Njc2IDE0LjYyODEgMTkuMzQ2OCAxNC41NjA2TDE3Ljk0NzggMTMuNzgwM0wxMC4xODkxIDE4LjAzNDhDOS45ODI5NiAxOC4xNDc5IDkuNzM2MTYgMTguMTQ3OSA5LjUzMDA0IDE4LjAzNDhMMS43NzEzNSAxMy43ODAzTDAuMzcyMjQ4IDE0LjU2MDZDMC4wMzAwMjIgMTQuNzUxOSAtMC4wOTc2MjY5IDE1LjE5NDMgMC4wODcwODAzIDE1LjU0ODdDMC4xNTIzMDcgMTUuNjczOSAwLjI1MTQ0OCAxNS43NzY2IDAuMzcyMjQ4IDE1Ljg0NDFMOS41MjU3OSAyMC45NDg5QzkuNzM0MTQgMjEuMDY1MSA5Ljk4NDk0IDIxLjA2NTEgMTAuMTkzMyAyMC45NDg5TDE5LjM0NjggMTUuODQ0MUMxOS42ODkgMTUuNjUyOCAxOS44MTY3IDE1LjIxMDQgMTkuNjMyIDE0Ljg1NTlaIiBmaWxsPSIjMUZBN0IzIiBmaWxsLW9wYWNpdHk9IjAuMzkiLz4KPHBhdGggZD0iTTE5LjQwNjUgNS44NDgwOUMxOS4zODY2IDUuODM0MjggMTkuMzY2MSA1LjgyMTU1IDE5LjM0NSA1LjgwOTkzTDEwLjE5MTUgMC43MDUwNThDOS45ODMxMSAwLjU4ODg3NCA5LjczMjMxIDAuNTg4ODc0IDkuNTIzOTYgMC43MDUwNThMMC4zNzA0MTUgNS44MDk5M0MwLjAzMDM3NTkgNS45OTY4NyAtMC4wOTkwMDU4IDYuNDMzOTIgMC4wODE0OTMyIDYuNzg2MUMwLjA5MjcxNTEgNi44MDc5OCAwLjEwNTAxIDYuODI5MjYgMC4xMTgzMzYgNi44NDk4Nkw5Ljg1NzY5IDEyLjE5MDNMMTkuNTk3IDYuODQ5ODZDMTkuODExNSA2LjUxODc0IDE5LjcyNjMgNi4wNzAyNCAxOS40MDY1IDUuODQ4MDlaIiBmaWxsPSIjMUZBN0IzIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 68px;
  width: 35px;
  border-radius: 5px;
  height: 34px;
  z-index: ${MAP_MENU};
  font-weight: 600;
  font-size: 15px;
  :hover {
    background-color: ${(props) => props.theme.colors.oysterBay};
  }
  background-size: 17px 17px;
  right: ${({ isLayerControlVisible, isRightDrawerVisible }) =>
    isLayerControlVisible || isRightDrawerVisible ? "371px" : "12px"};
`;

export const LayersMenuTitleWrapper = styled.div`
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  padding: 9.5px 17.5px;
  border-radius: 6px;

  &:hover {
    background: var(--colors-link-water);
  }
`;

export const LayersMenuContentTitle = styled.div<{ disabled?: boolean }>`
  ${Head2}
  padding-left: 16px;
  ${(props) => (props.disabled ? `color: var(--colors-silver)` : ``)};
`;

export const LayersMenuOption = styled.div<{ isRangeVisible: boolean; disabled?: boolean }>`
  color: ${(props) => props.theme.colors.riverBad};
  display: flex;
  ${(props) => (props.isRangeVisible ? `flex-direction: column;` : `align-items: center`)};
  box-sizing: border-box;
  margin: 0 20px 15px;

  border: 1px solid #ebeaea;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding: 9px 14px 9px 10px;
  > div {
    ${Data2}
  }
  cursor: ${(props) => (props.disabled ? "not-allowed" : "inherited;")};
  &:hover {
    ${(props) => (props.disabled ? "" : "border-color: var(--colors-blue-chill)")};
  }
`;

export const LayersMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${FIXED_HEADER_HEIGHT}px;
  width: ${LAYERS_MENU_WIDTH}px;
  box-sizing: border-box;
  padding: 20px 15px 25px;
`;

export const LayersMenuTitle = styled.div`
  ${Head1}
  flex: 1;
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;
export const LayersMenuClose = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.mystic};
  box-sizing: border-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const LayersMenuContent = styled.div<{ isLayerControlVisible: boolean }>`
  background-color: #f9f9f9;
  position: fixed;
  right: 0;
  bottom: 0;
  color: ${WHITE};
  font-family: Barlow;
  font-weight: 500;
  font-size: 14px;
  width: ${LAYERS_MENU_WIDTH}px;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  display: ${(props) => (props.isLayerControlVisible ? "block" : "none")};
  overflow: hidden;
  z-index: ${RIGHT_DRAWER + 1};
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`;

export const ExpandCollapseButton = styled.img<{ disabled?: boolean }>`
  width: 12px;
  height: 9px;
  ${(props) => (props.disabled ? "filter: grayscale(100%)" : "")}
`;

export const LayersMenuLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const LayersMenuItemWrapper = styled.div`
  background-color: ${WHITE};
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 4px 1px rgb(0 0 0 / 1%);
`;

export const LayersMenuList = styled.div`
  margin-top: 20px;

  &.isDisabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const FilterButton = styled(IconButton)<{ isActive: boolean }>`
  background-color: transparent;
  border: 1px solid #dadada;
  padding: 9px;
  border-radius: 3px;

  ${({ isActive }) =>
    isActive &&
    css`
      svg,
      path {
        fill: var(--colors-eastern-blue);
      }
    `}

  &[disabled] {
    background-color: transparent;
    border-color: #dadada;
  }
`;
