import { useQuery } from "react-query";

import { getMaintenanceAreasAlliUrl } from "../api";

import { useMapSelectionStore } from "../stores";
import { GetMaintenanceAreasByExtentResponseDto } from "../types/responses";
import ApiClient from "../utils/apiClient";

export const QUERY_KEY = "maintenance-areas-all";

export const useMaintenanceAreaAll = () => {
  const framePolygon = useMapSelectionStore((store) => store.framePolygon);

  const fetchFn = () =>
    ApiClient.get<Nullable<GetMaintenanceAreasByExtentResponseDto>>(
      getMaintenanceAreasAlliUrl({ lat: framePolygon?.center.lat!, lon: framePolygon?.center.lng! })
    ).then((data) => data.data?.maintenanceAreas);

  return useQuery(QUERY_KEY, fetchFn);
};
