import { FC, memo, useMemo } from "react";
import { Marker } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";

import { getStructureIconByMode } from "./MapStructures.utils";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { useCustomQuery, useMapOptimizedHiddenFlag } from "../../hooks";
import { StructuresProperties, StructuresResponse, STRUCTURE_MODE } from "../../types/responses/StructuresResponse";
import "./MapStructures.styles.css";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { EMPTY_FN } from "../../constants";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { StructuresById } from "../../stores/StructuresStore";
import StructureBigIcon from "./../../assets/images/structure_big.svg";
import StructureSmallIcon from "./../../assets/images/structure_small.svg";
import { MIN_ZOOM_LEVEL_FOR_STRUCTURES } from "../../pages/MapScreen/MapView/MapView.constants";

interface MapStructuresProps {
  mode: STRUCTURE_MODE;
  displayStatusColors?: boolean;
  geoJSON?: Nullable<StructuresResponse>;
  hidden?: boolean;
  isSwitch: (id: string) => boolean;
  map: Nullable<L.Map>;
  onClick: (e: L.LeafletMouseEvent, id: string) => void;
  url?: string;
  visibleZoomThreshold?: number;
  zIndex?: number;
  selectedStructures: Nullable<string[]>;
  structuresById: Nullable<StructuresById>;
}

const SMALL_SELECTED_STRUCTURE = L.icon({
  iconUrl: StructureSmallIcon,
  iconSize: [26, 26],
  className: "selected-structure",
});

const BIG_SELECTED_STRUCTURE = L.icon({
  iconUrl: StructureBigIcon,
  iconSize: [48, 48],
  className: "selected-structure",
});

const STRUCTURES_PANE_NAME = "structures-pane";

const SELECTED_STRUCTURES_PANE_NAME = "selected-structures-pane";

export const MapStructures: FC<MapStructuresProps> = memo(
  ({
    mode,
    displayStatusColors = false,
    geoJSON,
    hidden = false,
    isSwitch,
    map,
    onClick = EMPTY_FN,
    url = "",
    zIndex = 400,
    selectedStructures,
    structuresById,
  }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);

    useCustomMapPane({
      map,
      name: STRUCTURES_PANE_NAME,
      zIndex: zIndex + 1,
    });

    useCustomMapPane({
      map,
      name: SELECTED_STRUCTURES_PANE_NAME,
      zIndex,
    });
    const shouldFetchData = !geoJSON && !!url;

    const { data } = useCustomQuery<StructuresResponse>(url, {
      enabled: shouldFetchData,
    });

    const renderedStructures = useMemo(() => {
      const structures = shouldFetchData ? data : geoJSON;
      if (isGeoJSONEmpty(structures)) {
        return <></>;
      }

      return structures!.features.map((item: GeoJSON.Feature<GeoJSON.Point, StructuresProperties>) => {
        return (
          <Marker
            icon={getStructureIconByMode(
              mode,
              item.properties.id,
              item.properties?.metadata?.status || "undetermined",
              item.properties?.metadata?.label || "",
              item.properties?.type?.name || "",
              displayStatusColors,
              isSwitch(item.properties.id)
            )}
            alt={item.properties.id}
            position={[item.geometry.coordinates[1], item.geometry.coordinates[0]]}
            key={uuidv4()}
            pane={STRUCTURES_PANE_NAME}
            eventHandlers={{
              click: (e) => onClick(e, item.properties.id),
            }}
          />
        );
      });
    }, [geoJSON, mode, data, shouldFetchData, displayStatusColors, isSwitch, onClick]);

    const selectedMarker = useMemo(() => {
      if (!structuresById || !Object.keys(structuresById)?.length || !selectedStructures?.length) {
        return <></>;
      }

      return selectedStructures.map((structureId) => {
        return structuresById[structureId] ? (
          <Marker
            icon={mode === STRUCTURE_MODE.DETAILED ? BIG_SELECTED_STRUCTURE : SMALL_SELECTED_STRUCTURE}
            position={[
              structuresById[structureId]?.geometry?.coordinates[1],
              structuresById[structureId]?.geometry?.coordinates[0],
            ]}
            key={uuidv4()}
            pane={SELECTED_STRUCTURES_PANE_NAME}
          />
        ) : (
          <></>
        );
      });
    }, [selectedStructures, structuresById, mode]);

    if (optimizedHidden) {
      return <></>;
    }

    return (
      <>
        {selectedMarker}
        <MapMarkerCluster disableClusteringAtZoom={MIN_ZOOM_LEVEL_FOR_STRUCTURES + 1}>
          {renderedStructures}
        </MapMarkerCluster>
      </>
    );
  }
);
