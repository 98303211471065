import { useMemo } from "react";
import { useHierarchyStore, useFeederStore } from "../stores";

export const useSelectedRegion = () => {
  const currentRegion = useHierarchyStore((store) => store.currentRegion);
  const regions = useHierarchyStore((store) => store.regions);

  const selectedFeeder = useFeederStore((store) => store.selectedFeeder);

  const selectedRegion = useMemo(() => {
    const rootRegionId =
      selectedFeeder &&
      regions?.[currentRegion ?? ""]?.regions?.find((region) => region.properties.feederId === selectedFeeder)
        ?.properties.id;
    return rootRegionId ?? currentRegion;
  }, [currentRegion, selectedFeeder, regions]);

  return selectedRegion;
};
