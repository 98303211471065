import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import { LEGEND_WORK_TYPE } from "../../constants";

export const WorkTypeLegendWrapper = styled.div<{ leftMenuHidden: boolean; expanded: boolean; bottom?: number }>`
  background-color: var(--colors-legend-popup-bgd);
  border-radius: 10px;
  z-index: ${LEGEND_WORK_TYPE};
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 220px;
  padding-bottom: ${(props) => (props.expanded ? "15px" : "5px")};
`;

export const WorkTypeLegendName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--colors-white);
`;

export const WorkTypeLegendSelectAll = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  text-decoration-line: underline;
  color: #20c6d4;
  cursor: pointer;
  max-width: 80px;
`;

export const WorkTypeLegendHeader = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => (props.expanded ? "rgb(56, 64, 58)" : "transparent")};
  padding: 15px 15px 15px 15px;
`;

export const WorkTypeLegendBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-left: 15px;
  min-height: 100px;
  max-height: 180px;
  &:hover {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      background: #667072;
    }
    &::-webkit-scrollbar-track {
      background: var(--colors-cape-cod);
    }
    &::-webkit-scrollbar-thumb {
      background: #667072;
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #667072;
    }
  }
`;

export const WorkTypeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--colors-white);
    margin-left: 7px;
  }
  & div label svg.checkbox-unchecked {
    fill: #303b3c;
    stroke: #5f6d6e;
  }
`;

export const WorkTypeItemColor = styled.div<ColorProps>`
  border-radius: 12px;
  width: 14px;
  height: 4px;
  box-sizing: border-box;
  ${color}
`;

export const ExpandCollapseWorkTypeLegend = styled.div<{ selected?: boolean }>`
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border: 1px solid #80888a;
  :hover {
    border: 1px solid #27a4ae;
  }
  cursor: pointer;
`;

export const WorkTypeLegendNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MinMaxImage = styled.img<{ expanded: boolean }>`
  width: 10px;
  height: 10px;
  padding-bottom: ${(props) => (props.expanded ? "0px" : "5px")};
  padding-left: ${(props) => (props.expanded ? "0px" : "7px")};
`;
