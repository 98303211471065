import styled from "styled-components";

export const BreadcrumbsStyled = styled.div`
  margin-bottom: 17px;
  padding-left: 20px;
  margin-top: 17px;
`;

export const StyledLink = styled.span<{ isActive: boolean }>`
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 13px;
  color: ${(props) => (props.isActive ? "#0F8C96" : props.theme.colors.trout)};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.abbey2};
  }
  &::after {
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
    top: 0.5px;
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjcwMDI5IDUuMDAwMDJDNS43MDAyOSA1LjE3OTI0IDUuNjMxODYgNS4zNTg0MyA1LjQ5NTI5IDUuNDk1MDdMMS4xOTU0NiA5Ljc5NDg2QzAuOTIxOTQgMTAuMDY4NCAwLjQ3ODQ3MSAxMC4wNjg0IDAuMjA1MDU5IDkuNzk0ODZDLTAuMDY4MzUzIDkuNTIxNDUgLTAuMDY4MzUzIDkuMDc4MDcgMC4yMDUwNTkgOC44MDQ1Mkw0LjAwOTc4IDUuMDAwMDJMMC4yMDUxOTEgMS4xOTU0OUMtMC4wNjgyMjA1IDAuOTIxOTY4IC0wLjA2ODIyMDUgMC40Nzg2MzIgMC4yMDUxOTEgMC4yMDUyNDJDMC40Nzg2MDMgLTAuMDY4NDEyOCAwLjkyMjA3MyAtMC4wNjg0MTI4IDEuMTk1NTkgMC4yMDUyNDJMNS40OTU0MiA0LjUwNDk2QzUuNjMyMDIgNC42NDE2NiA1LjcwMDI5IDQuODIwODYgNS43MDAyOSA1LjAwMDAyWiIgZmlsbD0iI0RERENFNCIvPgo8L3N2Zz4K");
  }

  &:last-child:after {
    content: "";
  }
`;
