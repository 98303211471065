import styled from "styled-components";

import { TRIANGLE_TOOLTIP } from "../../constants/z-index";

export const TriangleTooltipContent = styled.div<{
  left?: Nullable<number>;
  width?: Nullable<number>;
  bottom?: Nullable<number>;
  scrollY: number;
  rotate?: number;
  top?: number;
  light?: boolean;
}>`
  position: absolute;
  left: ${(props) => (props.left && props.width ? `${props.left - 10 + props.width / 2}px` : "")};
  top: ${(props) => (props.top ? `${props.top}px` : "")};
  z-index: ${TRIANGLE_TOOLTIP};
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${(props) => (props.light ? `var(--colors-white)` : "#2C2F30")};
  ${(props) => {
    if (props.rotate) {
      return `transform: rotate(${props.rotate}deg);`;
    }
  }};
`;
