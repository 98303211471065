import styled from "styled-components";

import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";

export const GanttChartContainer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : "440px")};
  z-index: ${CUT_PLAN_Z_INDEX};
`;

export const SelectContainer = styled.div<{ isFullScreen: boolean }>`
  position: absolute;
  left: 9px;
  top: ${(props) => (props.isFullScreen ? "7px" : "66px")};
`;

export const ButtonContainer = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 17px;
  padding-right: 12px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 56px;
  background: #f1f1f1;
  margin: 0 20px;
`;
