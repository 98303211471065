import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import cloneDeep from "lodash.clonedeep";

import { getAllWorksheetsForSpanUrl, getWorksheetsForSpansUrl } from "../../api";
import { useCustomQuery } from "../../hooks";
import { logError } from "../../services";
import { ASSET_TYPES, useFeederStore, useMapSelectionStore, useSpansStore } from "../../stores";
import {
  SpanWorksheetDto,
  SpanWorksheetsDto,
  UpdateSpanWorksheetsBulkRequestDto,
  WorkTypeResponse,
} from "../../types/responses";
import ApiClient from "../../utils/apiClient";
import { Option } from "../Select";
import { mapIntoUpdateBulkSpanRequest, mapSpans } from "./SelectionToolRHS.utils";
import useFeederSegmentStore from "../../stores/FeederSegmentStore";

const useSpanContent = ({ workTypeResponse }: { workTypeResponse: Nullable<Record<string, WorkTypeResponse>> }) => {
  const selectedSpans = useMapSelectionStore((store) => store.selectedLayers[ASSET_TYPES.SPAN]);
  const selectedAsset = useMapSelectionStore((store) => store.selectedAssetType);
  const [currentSpans, setCurrentSpans] = useState<Nullable<SpanWorksheetDto[]>>(null);
  const feedersById = useFeederStore((store) => store.feedersData);
  const feederSegments = useFeederSegmentStore((store) => store.geoJSON);
  const geoJson = useSpansStore((store) => store.geoJSON);

  const spansById = useMemo(() => {
    if (!geoJson?.features?.length) {
      return null;
    }

    const byId: Record<string, string> = {};
    geoJson.features.forEach((feature) => {
      byId[feature.properties.id] = feature.properties.name;
    });
    return byId;
  }, [geoJson]);

  const {
    data: selectedSpansFromResponse,
    refetch: refetchSpans,
    isLoading: loadingSpans,
  } = useCustomQuery<SpanWorksheetsDto>(
    getWorksheetsForSpansUrl(),
    {
      enabled: selectedAsset === ASSET_TYPES.SPAN && !selectedSpans,
      cacheTime: 0,
      staleTime: 0,
    },
    {
      spanIds: selectedSpans?.join(","),
    }
  );

  const saveAllSpanChangesMutation = useMutation((value: UpdateSpanWorksheetsBulkRequestDto) =>
    ApiClient.put(getAllWorksheetsForSpanUrl(true), value)
  );

  const voltageBySpanId = useMemo(() => {
    if (!selectedSpans?.length || !feederSegments?.features?.length || !Object.keys(feedersById ?? {})?.length) {
      return {};
    }
    const voltages: Record<string, string> = {};
    selectedSpans.forEach((span) => {
      const feederSegment = feederSegments?.features?.find((feederSegment) => feederSegment.properties.spanId === span);
      if (feederSegment) {
        const feeder = feedersById?.[feederSegment.properties.feederId];
        if (feeder) {
          voltages[span] = feeder.properties.voltage;
        }
      }
    });
    return voltages;
  }, [selectedSpans, feedersById, feederSegments]);

  const saveAllWorkTypes = useCallback(() => {
    if (!currentSpans) {
      return;
    }
    const spanChanges = mapIntoUpdateBulkSpanRequest(currentSpans);
    if (!spanChanges) {
      return;
    }

    saveAllSpanChangesMutation
      .mutateAsync(spanChanges)
      .then(() => {
        refetchSpans();
      })
      .catch((e) => logError(e));
  }, [saveAllSpanChangesMutation, refetchSpans, currentSpans]);

  const cancelAll = useCallback(() => {
    setCurrentSpans(
      selectedSpansFromResponse ? mapSpans(cloneDeep(selectedSpansFromResponse), spansById, voltageBySpanId) : null
    );
  }, [selectedSpansFromResponse, spansById, voltageBySpanId]);

  const deleteWorkType = useCallback(
    (workTypeId: string, objectId?: string, manuallyAdded?: boolean, cost?: number) => {
      if (!currentSpans?.length) {
        return;
      }
      const spans: SpanWorksheetDto[] = Object.assign([], currentSpans);
      const span = spans.find((span) => span.spanId === objectId);
      if (!span) {
        return;
      }

      const index = span.items?.findIndex((workType) => workType.workTypeId === workTypeId && workType.cost === cost);
      const indexInherited = span.items?.findIndex(
        (workType) => workType.workTypeId === workTypeId && workType.inherited
      );

      if (index !== undefined && index >= 0) {
        span.items[index].selected = false;
      }
      if (indexInherited !== undefined && indexInherited >= 0) {
        span.items[indexInherited].selected = false;
      }

      setCurrentSpans(spans);
    },
    [currentSpans]
  );

  const addWorkType = useCallback(
    (workTypeOption: Option) => {
      const workTypeFromResponse = workTypeResponse?.[workTypeOption?.value ?? ""];
      if (!currentSpans?.length || !workTypeOption || !workTypeFromResponse) {
        return;
      }
      const spans: SpanWorksheetDto[] = cloneDeep(currentSpans);
      spans.forEach((span) => {
        const workSheet = span.items.find((span) => span.workTypeId === workTypeOption?.value);
        if (workSheet) {
          workSheet.selected = true;
        }
      });
      setCurrentSpans(spans);
    },
    [currentSpans, workTypeResponse]
  );

  const deleteAllWorkTypes = useCallback(() => {
    if (!currentSpans?.length) {
      return;
    }
    const spans: SpanWorksheetDto[] = cloneDeep(currentSpans);

    spans?.forEach((span) => {
      span.items = span.items.map((item) => {
        item.selected = false;
        return item;
      });
    });
    setCurrentSpans(spans);
  }, [currentSpans]);

  useEffect(() => {
    if (!selectedSpans?.length && selectedAsset === ASSET_TYPES.SPAN) {
      return setCurrentSpans(null);
    }
    setCurrentSpans(
      selectedSpansFromResponse ? mapSpans(cloneDeep(selectedSpansFromResponse), spansById, voltageBySpanId) : null
    );
  }, [selectedSpansFromResponse, spansById, voltageBySpanId, selectedSpans, selectedAsset]);

  useEffect(() => {
    if (!selectedSpans?.length) {
      return;
    }
    refetchSpans();
  }, [selectedSpans, refetchSpans]);

  return {
    selectedSpans: currentSpans,
    saveAllWorkTypesForSpans: saveAllWorkTypes,
    cancelAllSpanChanges: cancelAll,
    deleteWorkTypeForSpan: deleteWorkType,
    deleteAllWorkTypesForSpan: deleteAllWorkTypes,
    addNewWorkTypeForSpan: addWorkType,
    isSpanLoading: loadingSpans,
  };
};

export default useSpanContent;
