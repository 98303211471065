import styled from "styled-components";
import { MarginProps, margin } from "styled-system";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { IconButton } from "../../components/IconButton";
import { Button } from "../../components/Button";
import { Select } from "../../components/Select";

export const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  button {
    line-height: 15px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #3f4952;
  margin: 0;
`;

export const BackButton = styled(IconButton)`
  border: none;
  color: var(--colors-eastern-blue);
  font-weight: 600;
  font-size: 13px;
  padding-left: 0;
`;

export const MainContent = styled(StyledFlex)`
  flex-grow: 1;
  display: flex;
  gap: 50px;
`;

export const Section = styled(StyledFlex)`
  box-shadow: -1px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: var(--colors-white);
  padding: 10px 20px;
  height: fit-content;
  flex-direction: column;
  width: 391px;
  box-sizing: border-box;
  gap: 0;
  overflow-y: auto;
  max-height: 591px;

  &.isStreched {
    flex: 1;
  }
`;

export const SectionItem = styled(StyledFlex)`
  padding: 7.5px 0;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 14px;
    color: #6a6c71;
    font-weight: 600;
  }

  span:first-child {
    font-weight: 400;
  }

  span.withPrimaryColor {
    color: var(--colors-eastern-blue);
  }
`;

export const Subtasks = styled(StyledFlex)`
  flex: 1;
  flex-direction: column;
  gap: 0;
`;

export const AddNewBtn = styled(Button)`
  border: 0;
  font-size: 15px;
  padding: 10px;
  background-color: transparent;

  &:not([disabled]):hover {
    background-color: transparent;
    color: var(--colors-eastern-blue);
  }
`;

export const SectionLabel = styled.span<MarginProps>`
  color: var(--colors-eastern-blue);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  ${margin}
`;

export const SelectLabel = styled.label`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #8d8e91;
  margin-bottom: 7px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 15px;

  fieldset {
    border: none;
    padding: 0;
  }
`;

export const DependencySelect = styled(Select)`
  .react-select__control,
  .react-select__value-container,
  .react-select__input,
  .react-select__input input {
    height: auto;
  }

  .react-select__option {
    padding: 0;
  }

  .react-select__option:not(:last-child) {
    margin-bottom: 5px;
  }

  .react-select__option--is-disabled {
    cursor: not-allowed;
  }

  .react-select__option--is-disabled > div {
    opacity: 0.6;
    pointer-events: none;
  }

  .react-select__menu-list {
    padding: 10px;
  }

  .radio-text-wrapper {
    margin-left: 10px;
  }

  .radio-label {
    font-weight: 600;
    font-size: 14px;
    color: #262c2d;
    margin-bottom: 5px;
  }
`;

export const subtaskBarStyles = {
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #EEEEEE",
  mb: "20px",
  pb: "10px",
};

export const formButtonStyles = {
  fontSize: "15px",
  lineHeight: 1,
  padding: "9px",
  width: "120px",
};
