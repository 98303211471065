import styled from "styled-components";
import { position, PositionProps } from "styled-system";

import { MAP_MENU } from "../../constants";

export const MapControlsWrapper = styled.div<PositionProps>`
  ${position}
  display: flex;
  flex-direction: column;
  z-index: ${MAP_MENU};
  background-color: ${(props) => props.theme.colors.white};
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 10px 10px);
  border-radius: 5px;
  overflow: hidden;
`;

export const Control = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${MAP_MENU};
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: ${(props) => (props.checked ? props.theme.colors.easternBlue : "transparent")};
  & > svg {
    fill: ${(props) => (props.checked ? props.theme.colors.white : props.theme.colors.easternBlue)};
  }
  &:hover {
    background-color: ${(props) => (props.checked ? props.theme.colors.easternBlue : props.theme.colors.oysterBay)};
  }
`;
