import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../utils/apiClient";
import { deleteAlert } from "../api";

export interface UpdateOpts {
  categoryId?: string;
  lat?: string;
  lon?: string;
}

export const useEditAlert = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ alertId, updateOpts }: { alertId: string; updateOpts: UpdateOpts }) =>
      ApiClient.put(deleteAlert(alertId), updateOpts),
    {
      onSettled: () => queryClient.invalidateQueries("alerts"),
    }
  );
};
