import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { IconButton } from "../IconButton";
import { Button } from "../Button";
import { TOP_HEADER_HEIGHT } from "../../constants/dimensions";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow_pagination_right.svg";
import { Link } from "react-router-dom";
import { StyledButton } from "../AlertPopup/AddAlertPopup.styled";

export const Wrapper = styled(StyledFlex)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  height: ${TOP_HEADER_HEIGHT}px;
  box-sizing: border-box;
  z-index: 1003;
  border-bottom: 1px solid #f2f2f2;

  > * + * {
    border-left: 1px solid #eeeeee;
  }

  > * {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    height: 100%;
  }

  > button {
    border-radius: 0;
    &:not(.active):not([disabled]):hover {
      background-color: #eeeeee;
    }
  }
`;

export const CloseIcon = styled(Close)`
  width: 17px;
  path {
    fill: #2ca3af;
  }
`;

export const AvatarButton = styled.button`
  padding: 0 18px;
  background: white;
  border: 0;

  img {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    outline: 1px solid #1fa7b2;
    padding: 3px;
  }

  &:hover {
    background-color: #eeeeee;
  }
`;

export const HeaderButton = styled(IconButton)`
  border-radius: 0;

  &.active {
    background-color: #1fa7b2;

    svg,
    path {
      fill: white;
    }
  }

  &[disabled] {
    background-color: transparent;
    border-color: #eeeeee;
  }

  &:first-child {
    border: none;
  }
`;

export const LogoutButton = styled(IconButton)`
  margin: 10px 0;
  white-space: nowrap;
  border: none;
  color: var(--colors-trout-2);
  font-weight: 400;
  gap: 10px;
  justify-content: flex-start;
  border-radius: 0;

  path {
    fill: #0cb2c0;
  }

  &:hover {
    background-color: #eeeeee;
  }
`;

export const Dropdown = styled(StyledFlex)`
  position: absolute;
  top: 50px;
  right: 5px;
  background-color: var(--colors-white);
  box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  width: 150px;
`;

export const Username = styled.span`
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
`;

export const HamburgerDropdown = styled(StyledFlex)`
  position: absolute;
  left: 0;
  top: 48px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  max-height: 0;
  transition: max-height 0.15s ease-out;
  background-color: var(--colors-white);
  overflow: hidden;

  &.isOpen {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }
`;

export const HamburgerButton = styled(Button)`
  border-radius: 0;
  width: 100%;
  border-top: 1px solid #eeeeee;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: var(--colors-black);

  &:not([disabled]):hover {
    background-color: #eeeeee;
  }

  &[disabled] {
    background-color: var(--colors-white);
    color: var(--colors-black);
  }
`;

export const Arrow = styled(ArrowRight)`
  margin-left: 90px;
  path {
    fill: black;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--colors-eastern-blue);
  background-color: var(--colors-white);
  border-top: 1px solid #eeeeee;
  padding: 10.5px 25px;
`;

export const Message = styled(StyledFlex)`
  width: 100%;
  position: absolute;
  top: 0;
  height: 30px;
  transform: translateY(48px);
  border: none;
  align-items: center;
  justify-content: center;
  color: var(--colors-white);
  background-color: var(--colors-burnt-sienna);
  gap: 10px;

  &.success {
    background-color: var(--colors-good);
  }

  path:first-child {
    fill: var(--colors-white);
  }

  span {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const CloseMessage = styled(StyledButton)`
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-right: 10px;

  svg {
    width: 10px;
    height: 10px;
  }
`;
