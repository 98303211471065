import { useCallback, useMemo } from "react";

import { useHierarchyStore, useMaintenanceAreaStore, useMapStore, useParcelsStore } from "../stores";
import { OVERLAY_LAYERS } from "../stores/MapStore";
import useStructuresStore from "../stores/StructuresStore";
import { CUSTOMER_ASSETS_LAYERS, INITIAL_FEEDER_LAYERS, INITIAL_REGION_LAYERS } from "../types/Layers";

export const useCustomerAssetsLayer = () => {
  const isFeederLayerVisible = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.FEEDERS] ?? false);
  const isParcelLayerVisible = useParcelsStore((store) => !store.parcelLayerHidden);
  const isEasementVisible = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.CLEARANCE] ?? false);
  const areStructuresVisible = useStructuresStore((store) => !store.riskViewHidden);
  const isMaintenanceAreaVisible = useMaintenanceAreaStore((store) => store.isLayerVisible);
  const areRegionsVisible = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.REGIONS] ?? false);
  const isFeederLevel = useHierarchyStore((store) => store.isFeederLevel);

  const toggleMaLayer = useMaintenanceAreaStore((store) => store.toggleLayer);
  const toggleStructures = useStructuresStore((store) => store.toggleRiskViewHidden);
  const toggleParcelLayer = useParcelsStore((store) => store.toggleParcelLayer);
  const toggleOverlay = useMapStore((store) => store.setOverlayMapLayerVisibility);

  const sectionData = useMemo(() => {
    const itemCheckedByKey = {
      [CUSTOMER_ASSETS_LAYERS.FEEDERS]: isFeederLayerVisible,
      [CUSTOMER_ASSETS_LAYERS.CLEARANCE]: isEasementVisible,
      [CUSTOMER_ASSETS_LAYERS.PARCELS]: isParcelLayerVisible,
      [CUSTOMER_ASSETS_LAYERS.STRUCTURES]: areStructuresVisible,
      [CUSTOMER_ASSETS_LAYERS.MAINTENANCE_AREA]: isMaintenanceAreaVisible,
      [CUSTOMER_ASSETS_LAYERS.REGIONS]: areRegionsVisible,
    };
    const layers = isFeederLevel ? INITIAL_FEEDER_LAYERS : INITIAL_REGION_LAYERS;
    return layers.map((item) => {
      const newItem = { ...item };
      newItem.checked = itemCheckedByKey[item.key];
      return newItem;
    });
  }, [
    isFeederLayerVisible,
    isParcelLayerVisible,
    isEasementVisible,
    areStructuresVisible,
    isMaintenanceAreaVisible,
    areRegionsVisible,
    isFeederLevel,
  ]);

  const toggleLayer = useCallback(
    (key: string, value: boolean) => {
      switch (key) {
        case CUSTOMER_ASSETS_LAYERS.FEEDERS:
          return toggleOverlay({ name: OVERLAY_LAYERS.FEEDERS, key: OVERLAY_LAYERS.FEEDERS, value });
        case CUSTOMER_ASSETS_LAYERS.CLEARANCE:
          return toggleOverlay({ name: OVERLAY_LAYERS.CLEARANCE, key: OVERLAY_LAYERS.CLEARANCE, value });
        case CUSTOMER_ASSETS_LAYERS.MAINTENANCE_AREA:
          return toggleMaLayer();
        case CUSTOMER_ASSETS_LAYERS.PARCELS:
          return toggleParcelLayer();
        case CUSTOMER_ASSETS_LAYERS.STRUCTURES:
          return toggleStructures();
        case CUSTOMER_ASSETS_LAYERS.REGIONS:
          return toggleOverlay({ name: OVERLAY_LAYERS.REGIONS, key: OVERLAY_LAYERS.REGIONS, value });
        default:
          return;
      }
    },
    [toggleMaLayer, toggleOverlay, toggleParcelLayer, toggleStructures]
  );

  return { data: sectionData, toggleLayer };
};
