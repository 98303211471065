import styled from "styled-components";
import { PaddingProps, padding } from "styled-system";

export const SwitchWrapper = styled.div<PaddingProps & { disabled?: boolean }>`
  display: flex;
  ${padding}
  cursor: ${(props) => (props.disabled ? "not-allowed" : "inherited")};
`;

export const SwitchButtonStyled = styled.span<{ disabled?: boolean; toggled?: boolean }>`
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 17px;
  background: ${(props) => {
    if (props.disabled) {
      return "#A2A2A2";
    }
    return props.toggled ? props.theme.colors.teal3 : props.theme.colors.white;
  }};

  box-shadow: 0px 2px 3px ${(props) => (props.disabled ? "#A2A2A2" : "#97c9ce")};
  &:hover {
    background: ${(props) => (props.disabled ? "#A2A2A2" : props.theme.colors.shakespeare)};
  }
  box-shadow: 0px 2px 3px #949696;
  transition: transform 0.3s;
`;

export const SwitchLabel = styled.label<{ toggled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 11px;
  border-radius: 30px;
  position: relative;
  background-color: ${(props) => (props.toggled ? props.theme.colors.teal3 : props.theme.colors.bonJour)};
`;

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  background: var(--colors-silver-2);
  &:checked + ${SwitchLabel} ${SwitchButtonStyled} {
    transform: translateX(calc(100% - 4px));
    background: ${(props) => (props.disabled ? "#a2a2a2" : props.theme.colors.teal3)};
    &:hover {
      background: ${(props) => props.theme.colors.shakespeare};
    }
    &:disabled {
      background: "#a2a2a2";
    }
  }
  &:checked + ${SwitchLabel} {
    background-color: ${(props) => (props.disabled ? "#DDDDDD" : props.theme.colors.morningGlory)};
  }
`;
