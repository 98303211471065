import { FC, useCallback, useEffect, useState } from "react";

import { Portal } from "../Portal";
import { ASSET_TYPES, StructuresWithWorksheet, useMapSelectionStore } from "../../stores";
import { UpdateStructuresWorksheetsBulkRequestDto, WorkTypeResponse } from "../../types/responses";
import { ButtonStyled } from "../Button/Button.styled";
import {
  RightDrawerWrapper,
  RightDrawerHeaderColumn,
  RightDrawerHeaderPartLeft,
  CloseImageWrapper,
  CloseIcon,
} from "../RightDrawer/RightDrawer.styled";
import { StructureRHSContent } from "../StructureRHSContent";
import { ParcelRHSContent } from "../ParcelRHSContent";
import { MaintenanceAreaContentRHS } from "../MaintenanceAreaContentRHS";
import { ReactComponent as SelectionImage } from "./../../assets/images/selection.svg";
import {
  ImageWrapper,
  CancelButton,
  CancelSaveWrapper,
  SectionHeaderActionWrapper,
  ItemsWrapper,
  RightDrawerHeader,
  LoaderContent,
} from "./SelectionToolRHS.styled";
import { logError } from "../../services";
import { statusInitialSelectValue } from "../ParcelRHSContent/ParcelRHSContent.utils";
import useParcelDetails from "./useParcelDetails";
import useUpdateParcels from "./useUpdateParcels";
import { useAssignMaToSpans } from "../../hooks/useAssignMaToSpans";
import { cancelAllWorkTypesForStructures, mapStructuresToRequest } from "./SelectionToolRHS.utils";
import { SpansContentRHS } from "../SpansContentRHS";
import useSpanContent from "./useSpanContent";
import { Loader } from "../Loader";

interface SelectionToolRHSProperties {
  closeDrawer: () => void;
  cancelSelection: () => void;
  type: Nullable<ASSET_TYPES>;
  selectedStructures: Nullable<StructuresWithWorksheet>;
  workTypeResponse: Nullable<Record<string, WorkTypeResponse>>;
  deleteAllWorkTypes: () => void;
  deleteWorkType: (workTypeId: string, objectId: string) => void;
  saveAllChanges: (structures: Nullable<UpdateStructuresWorksheetsBulkRequestDto>) => void;
  isVisible: boolean;
}
export const SelectionToolRHS: FC<SelectionToolRHSProperties> = ({
  type,
  closeDrawer,
  selectedStructures,
  workTypeResponse,
  deleteWorkType,
  deleteAllWorkTypes,
  saveAllChanges,
  isVisible,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusInitialSelectValue);
  const parcelIds = useMapSelectionStore((store) => store.selectedLayers[ASSET_TYPES.PARCEL]);
  const [selectedStructuresLocally, setSelectedStructuresLocally] = useState(selectedStructures);
  const [selectedMaID, setSelectedMaID] = useState("");
  const { mutateAsync: assignMaToSpans } = useAssignMaToSpans();

  /*  Extract custom logic for each type parcel, span, structure in separate custom hook  */
  const { parcelDetails, reset: resetParcelDetails, setParcelDetails } = useParcelDetails();
  const { updateParcels } = useUpdateParcels();

  const {
    selectedSpans,
    deleteWorkTypeForSpan,
    cancelAllSpanChanges,
    deleteAllWorkTypesForSpan,
    saveAllWorkTypesForSpans,
    addNewWorkTypeForSpan,
    isSpanLoading,
  } = useSpanContent({ workTypeResponse });

  const handleEditClick = () => setIsEditMode(true);

  const handleCancelAction = () => {
    setIsEditMode(false);
    try {
      switch (type) {
        case ASSET_TYPES.PARCEL:
          setSelectedStatus(statusInitialSelectValue);
          resetParcelDetails();
          return;
        case ASSET_TYPES.STRUCTURE: {
          cancelALLStructures();
          return;
        }
        case ASSET_TYPES.SPAN:
          cancelAllSpanChanges();
          return;
        default:
          return;
      }
    } catch (error) {
      logError(error);
    }
  };

  const handleSaveAction = async () => {
    setIsEditMode(false);

    try {
      switch (type) {
        case ASSET_TYPES.PARCEL: {
          return await updateParcels({ parcelIds, status: selectedStatus.value });
        }
        case ASSET_TYPES.STRUCTURE: {
          saveAllChanges(mapStructuresToRequest(selectedStructuresLocally));
          return;
        }
        case ASSET_TYPES.SPAN:
          saveAllWorkTypesForSpans();
          return;
        case ASSET_TYPES.MA:
          const data = {
            maId: selectedMaID,
            spanIdsToAdd: selectedSpans?.map((item) => item.spanId) ?? [],
          };
          assignMaToSpans(data);
          return;
        default:
          return;
      }
    } catch (error) {
      logError(error);
    }
  };

  useEffect(() => {
    setSelectedStructuresLocally(selectedStructures);
  }, [selectedStructures]);

  const cancelALLStructures = useCallback(() => {
    setSelectedStructuresLocally(cancelAllWorkTypesForStructures(selectedStructuresLocally));
  }, [selectedStructuresLocally]);

  if (!type || !isVisible) {
    return <></>;
  }
  return (
    <Portal selector="layer-menu-root">
      <RightDrawerWrapper>
        <RightDrawerHeader>
          <RightDrawerHeaderColumn singleColumn={true}>
            <ImageWrapper>
              <SelectionImage />
            </ImageWrapper>
            <RightDrawerHeaderPartLeft id="rhs-title">Selection</RightDrawerHeaderPartLeft>
            {isEditMode ? (
              <CancelSaveWrapper>
                <CancelButton variant="cancel" size="small" height="30px" fontSize="14px" onClick={handleCancelAction}>
                  Cancel
                </CancelButton>

                <ButtonStyled
                  variant="primary"
                  height="30px"
                  minWidth="70px"
                  fontSize="14px"
                  position="relative"
                  type="submit"
                  padding="0px"
                  onClick={handleSaveAction}
                >
                  Save
                </ButtonStyled>
              </CancelSaveWrapper>
            ) : (
              <SectionHeaderActionWrapper>
                <ButtonStyled
                  variant="primary"
                  height="30px"
                  minWidth="70px"
                  fontSize="14px"
                  position="relative"
                  type="submit"
                  padding="0px"
                  disabled={false}
                  onClick={handleEditClick}
                >
                  Edit
                </ButtonStyled>
              </SectionHeaderActionWrapper>
            )}
            <CloseImageWrapper onClick={closeDrawer} marginRight={0}>
              <CloseIcon />
            </CloseImageWrapper>
          </RightDrawerHeaderColumn>
        </RightDrawerHeader>
        <ItemsWrapper id={`rhs-${type ? type.toLowerCase() : ""}-content`}>
          {type === ASSET_TYPES.STRUCTURE && (
            <StructureRHSContent
              isEditMode={isEditMode}
              deleteAllWorkTypes={deleteAllWorkTypes}
              selectedStructures={selectedStructuresLocally}
              workTypeResponse={workTypeResponse}
              deleteWorkType={deleteWorkType}
              setSelectedStructures={setSelectedStructuresLocally}
            />
          )}
          {type === ASSET_TYPES.PARCEL && (
            <ParcelRHSContent
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              isEditMode={isEditMode}
              parcelDetails={parcelDetails}
              setParcelDetails={setParcelDetails}
            />
          )}

          {type === ASSET_TYPES.SPAN && (
            <>
              {isSpanLoading && (
                <LoaderContent>
                  <Loader />
                </LoaderContent>
              )}
              {!isSpanLoading && (
                <SpansContentRHS
                  workTypeResponse={workTypeResponse}
                  isEditMode={isEditMode}
                  selectedSpans={selectedSpans}
                  deleteWorkType={deleteWorkTypeForSpan}
                  deleteAllWorkTypes={deleteAllWorkTypesForSpan}
                  addNewWorkType={addNewWorkTypeForSpan}
                />
              )}
            </>
          )}

          {type === ASSET_TYPES.MA && (
            <>
              {isSpanLoading && (
                <LoaderContent>
                  <Loader />
                </LoaderContent>
              )}
              {!isSpanLoading && (
                <MaintenanceAreaContentRHS
                  selectedSpans={selectedSpans}
                  isEditMode={isEditMode}
                  setSelectedMaID={setSelectedMaID}
                />
              )}
            </>
          )}
        </ItemsWrapper>
      </RightDrawerWrapper>
    </Portal>
  );
};
