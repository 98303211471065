import { Form, Formik, FormikProps } from "formik";
import { FC, useCallback, useEffect, useRef, useState, memo, useMemo } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { components, OptionProps } from "react-select";

import { putParcelInfoUrl } from "../../../api";
import { INVALID_EMAIL } from "../../../constants";
import { useReloadParcels } from "../../../hooks/useReloadParcels";

import { MUTATION_STATE } from "../../../pages/MapScreen/RightDrawerMenu/RightDrawerSections/ParcelInfoSection";
import { logError } from "../../../services/Logger/Logger.service";
import { PARCEL_STATUS, UpdateParcelContactInfoDto } from "../../../types/responses";
import ApiClient from "../../../utils/apiClient";
import { Button } from "../../Button";
import { Checkbox } from "../../Checkbox";
import { Input } from "../../Input/Input";
import { Textarea } from "../../Textarea";
import { Loader } from "../../Loader";
import {
  ButtonWrapper,
  CheckboxWrapper,
  EditButtonWrapper,
  InputContainer,
  ParcelContactFormWrapper,
  ParcelStatusBodyWrapper,
  ParcelStatusButtonBodyStatus,
  ParcelStatusItem,
  ParcelStatusNoteBody,
  SectionFormLabel,
} from "./ParcelStatus.styled";
import { ReactComponent as EditIcon } from "./../../../assets/images/edit.svg";
import { parcelSelectOptions } from "../../../stores";
import { StyledFlex } from "../../../assets/styles/flex.styled";
import { getParcelDropdownColor } from "../../ParcelRHSContent/ParcelRHSContent.utils";
import { Select } from "../../Select/Select";

const CustomSelectOptionComponent = (props: OptionProps<any, any, any> | any) => (
  <StyledFlex alignItems="center">
    <ParcelStatusButtonBodyStatus backgroundColor={getParcelDropdownColor(props.data.value as PARCEL_STATUS)} />
    <ParcelStatusItem>{props.data.label}</ParcelStatusItem>
  </StyledFlex>
);

const CustomOption = (props: OptionProps<any, any, any>) => (
  <components.Option {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.Option>
);

export const CustomSingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.SingleValue>
);

interface ParcelStatusContactProps {
  editable: boolean;
  parcelId: Maybe<string>;
  formData: UpdateParcelContactInfoDto;
  setEditable: (editable: boolean) => void;
}
type ParcelForm = FormikProps<{
  ownerFirstName: Nullable<string>;
  ownerLastName: Nullable<string>;
  contactEmail: Nullable<string>;
  contactPhone: Nullable<string>;
  onSiteOwnerFirstName: Nullable<string>;
  onSiteOwnerLastName: Nullable<string>;
  onSiteOwnerContactEmail: Nullable<string>;
  onSiteOwnerContactPhone: Nullable<string>;
  ownerSameAsOnSiteContact: Nullable<boolean>;
  status: Nullable<PARCEL_STATUS>;
  note: Nullable<string>;
}>;

const OwnerSchema = Yup.object().shape({
  ownerFirstName: Yup.string(),
  ownerLastName: Yup.string(),
  contactPhone: Yup.number(),
  contactEmail: Yup.string().email(INVALID_EMAIL),
});

const OnSiteContactSchema = Yup.object().shape({
  onSiteOwnerFirstName: Yup.string().required(),
  onSiteOwnerLastName: Yup.string().required(),
  onSiteOwnerContactPhone: Yup.number().required("Phone number is required"),
  onSiteOwnerContactEmail: Yup.string().email(INVALID_EMAIL).required("Email is required."),
});

export const ParcelStatusContact: FC<ParcelStatusContactProps> = memo(
  ({ editable, parcelId, setEditable, formData }) => {
    const [mutationState, setMutationState] = useState(MUTATION_STATE.Idle);
    const formikRef = useRef<Nullable<ParcelForm>>(null);
    const [formikData, setFormikData] = useState(formData);
    const [currentStatus, setStatus] = useState(formikData.status);
    const [checkboxState, setCheckboxState] = useState(formikData.ownerSameAsOnSiteContact);

    const { reloadParcels } = useReloadParcels();

    useEffect(() => {
      if (!checkboxState) {
        formikRef.current?.validateForm();
      }
    }, [checkboxState]);

    const changeStatus = useCallback(
      (status: PARCEL_STATUS) => {
        if (!editable) {
          return;
        }

        setStatus(status);
        formikRef?.current && formikRef.current.setFieldValue("status", status);
      },
      [editable]
    );

    useEffect(() => {
      setFormikData(formData);
      setStatus(formikData.status);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const updateContact = useMutation(
      (value: UpdateParcelContactInfoDto) =>
        ApiClient.put<UpdateParcelContactInfoDto>(putParcelInfoUrl(parcelId ?? ""), value),
      {
        onSettled: () => {
          reloadParcels();
        },
        onMutate: (value: UpdateParcelContactInfoDto) => {
          setFormikData(value);
        },
      }
    );

    useEffect(() => {
      if (editable || !formikRef) {
        return;
      }

      formikRef?.current?.resetForm();
      setCheckboxState(formikData.ownerSameAsOnSiteContact);
    }, [editable, formikData]);

    const clearFormData = useCallback(
      (clear: boolean) => {
        if (!formikRef?.current) {
          return;
        }

        formikRef.current.setFieldValue("onSiteOwnerLastName", clear ? "" : formikData.onSiteOwnerLastName);
        formikRef.current.setFieldValue("onSiteOwnerFirstName", clear ? "" : formikData.onSiteOwnerFirstName);
        formikRef.current.setFieldValue("onSiteOwnerContactEmail", clear ? "" : formikData.onSiteOwnerContactEmail);
        formikRef.current.setFieldValue("onSiteOwnerContactPhone", clear ? "" : formikData.onSiteOwnerContactPhone);
      },
      [formikData]
    );

    useEffect(() => {
      setCheckboxState(formikData.ownerSameAsOnSiteContact);
    }, [formikData.ownerSameAsOnSiteContact]);

    const currentSelectedValue = useMemo(() => {
      if (!currentStatus) {
        return parcelSelectOptions[3]; //unassigned value
      }
      return parcelSelectOptions.find((item) => item.value === currentStatus) ?? parcelSelectOptions[3];
    }, [currentStatus]);

    const invokeUpdateContact = useCallback(
      (value) => {
        setMutationState(MUTATION_STATE.Loading);
        updateContact
          .mutateAsync(value)
          .then(() => {
            setEditable(false);
          })
          .catch((error) => logError(error))
          .finally(() => setMutationState(MUTATION_STATE.Done));
      },
      [updateContact, setEditable]
    );
    return (
      <ParcelContactFormWrapper>
        <Formik
          initialValues={{
            ownerFirstName: formikData.ownerFirstName,
            ownerLastName: formikData.ownerLastName,
            contactEmail: formikData.contactEmail,
            contactPhone: formikData.contactPhone,
            onSiteOwnerFirstName: formikData.onSiteOwnerFirstName,
            onSiteOwnerLastName: formikData.onSiteOwnerLastName,
            onSiteOwnerContactEmail: formikData.onSiteOwnerContactEmail,
            onSiteOwnerContactPhone: formikData.onSiteOwnerContactPhone,
            ownerSameAsOnSiteContact: formikData.ownerSameAsOnSiteContact,
            note: formikData.note,
            status: formikData.status,
          }}
          onSubmit={invokeUpdateContact}
          enableReinitialize={true}
          innerRef={formikRef}
          validationSchema={checkboxState ? OwnerSchema : OwnerSchema.concat(OnSiteContactSchema)}
        >
          {({ isValid, handleSubmit, dirty, errors }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <>
                <ParcelStatusBodyWrapper disabled={!editable}>
                  <SectionFormLabel>Parcel Status</SectionFormLabel>
                  <Select
                    isDisabled={false}
                    ml={2}
                    className="parcel-statuses"
                    options={parcelSelectOptions}
                    value={currentSelectedValue}
                    width={208}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                    onChange={(value) => {
                      changeStatus(value.value as PARCEL_STATUS);
                    }}
                  />
                </ParcelStatusBodyWrapper>
                <ParcelStatusNoteBody>
                  <SectionFormLabel>Notes:</SectionFormLabel>
                  <Textarea placeholder="Enter note" disabled={!editable} editable={editable} name="note" />
                </ParcelStatusNoteBody>
              </>
              <InputContainer>
                <SectionFormLabel>Owner Info</SectionFormLabel>
                <Input
                  {...{ disabled: !editable }}
                  name="ownerFirstName"
                  type="string"
                  label="First Name"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="ownerLastName"
                  type="string"
                  label="Last Name"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="contactPhone"
                  type="string"
                  label="Phone Number"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="contactEmail"
                  type="email"
                  label="Email Address"
                  displayErrorMessage={false}
                />
              </InputContainer>
              <CheckboxWrapper>
                <Checkbox
                  name="ownerSameAsOnSiteContact"
                  value={"ownerSameAsOnSiteContact"}
                  label="Parcel Owner Same as On-Site Contact"
                  defaultChecked={formikData.ownerSameAsOnSiteContact}
                  checked={checkboxState}
                  onClick={(e) => {
                    if (!editable) {
                      setEditable(true);
                    }
                    const checked = (e.target as HTMLInputElement).checked;

                    if (formikRef?.current) {
                      formikRef.current.setFieldValue("ownerSameAsOnSiteContact", checked);
                    }
                    setCheckboxState(checked);
                    clearFormData(checked);
                  }}
                  id="ownerSameAsOnSiteContact"
                />
              </CheckboxWrapper>
              <InputContainer notVisible={checkboxState}>
                <SectionFormLabel>On-Site Contact Info</SectionFormLabel>
                <Input
                  {...{ disabled: !editable }}
                  name="onSiteOwnerFirstName"
                  type="string"
                  label="First Name"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="onSiteOwnerLastName"
                  type="string"
                  label="Last Name"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="onSiteOwnerContactPhone"
                  type="string"
                  label="Phone Number"
                  displayErrorMessage={false}
                />
                <Input
                  {...{ disabled: !editable }}
                  name="onSiteOwnerContactEmail"
                  type="email"
                  label="Email Address"
                  displayErrorMessage={false}
                />
              </InputContainer>

              <ButtonWrapper>
                {!editable && (
                  <EditButtonWrapper className="save_cancel_wrapper">
                    <Button
                      variant="editable"
                      size="small"
                      height="35px"
                      width="70px"
                      fontSize="14px"
                      onClick={() => {
                        setEditable(true);
                      }}
                    >
                      <EditIcon className="edit-icon" />
                      Edit
                    </Button>
                  </EditButtonWrapper>
                )}
                {editable && (
                  <ButtonWrapper className="save_cancel_wrapper">
                    <Button
                      variant="cancel"
                      size="small"
                      height="35px"
                      width="50%"
                      fontSize="14px"
                      onClick={() => {
                        setEditable(false);
                        changeStatus(formikData.status);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!editable || !dirty || !isValid}
                      variant="primary"
                      size="small"
                      height="35px"
                      width="50%"
                      fontSize="14px"
                      onClick={() => {}}
                    >
                      {mutationState === MUTATION_STATE.Loading ? <Loader /> : "Save Changes"}
                    </Button>
                  </ButtonWrapper>
                )}
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </ParcelContactFormWrapper>
    );
  }
);
