import { FC, memo, MouseEvent } from "react";
import fileDownload from "js-file-download";

import ApiClient from "../../utils/apiClient";
import { logError } from "../../services";

export interface DownloadLinkProps {
  disabled?: boolean;
  filename?: string;
  href: string;
  timestamp?: boolean;
}

export const DownloadLink: FC<DownloadLinkProps> = memo(
  ({ href, disabled, filename = "file", children, timestamp = true }) => {
    if (disabled) {
      return <>{children}</>;
    }

    const onClick = (e: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }
      e.preventDefault();
      let name = filename;
      if (timestamp) {
        const splittedName = name.split(".");
        const date = new Date();
        name = `${splittedName[0]}-${date.toISOString()}.${splittedName[1]}`;
      }
      ApiClient.get<Blob>(href, null, null, {
        responseType: "blob",
      })
        .then((res) => fileDownload(res.data, name))
        .catch(logError);
    };

    return (
      <a href={href} onClick={onClick} style={{ marginRight: "12px" }}>
        {children}
      </a>
    );
  }
);

export default DownloadLink;
