import styled from "styled-components";

export const TaxonomyItemBox = styled.div<{ scroll?: string }>`
  width: 100%;
  padding-bottom: 2em;
  overflow-y: ${(props) => props.scroll ?? "auto"};
  max-height: calc(100vh - 193px);
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c9ca;
  }
`;

export const TaxonomyCoverItem = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 4px;
  transition: all 0.5s;
  opacity: 1;
`;

export const TaxonomyWrapper = styled.div`
  &:hover {
    > :nth-child(2) {
      opacity: 0;
      transition: all 0.5s;
    }
  }
`;
