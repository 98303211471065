export const SIDEBAR_MENU = 1000;

export const LOADER_OVERLAY = 1100;

export const LEAFLET_MAP = 400;

export const LEAFLET_VANTAGE_TILE_SERVER_LAYER = 500;

export const LEAFLET_ROADS_TILE_LAYER = LEAFLET_VANTAGE_TILE_SERVER_LAYER + 1;

export const MAP_LOGO = LEAFLET_MAP + 1;

export const MAP_MENU = LEAFLET_MAP + 1;

export const TOOLTIP = 1000;

export const MODAL = 5001;

export const ERROR_MESSAGE = 40;

export const ADMIN_HEADER = 20;

export const RIGHT_DRAWER = 1001;

export const TRIANGLE_TOOLTIP = 1003;

export const LEGEND_WORK_TYPE = 600;

export const STICKY_HEADER_LHS = 999;

export const OPACITY_MAP_CONTROL = 400;

export const OPACITY_LAYER = 350;

export const MEASURE_TOOL_LAYER = 600;

export const MEASURE_TOOL_MARKER_LAYER = 2300;

export const COMMENTS_LAYER = 699;

export const TREE_MARKER_INDEX = 599;

export const LEAFLET_POPUP_PANE = 6000;
