import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { STAR_COMMAND_RHS_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";
import { RIGHT_DRAWER } from "../../constants/z-index";

export const Wrapper = styled.div`
  width: ${STAR_COMMAND_RHS_WIDTH}px;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  max-height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  position: fixed;
  top: ${TOP_HEADER_HEIGHT}px;
  z-index: ${RIGHT_DRAWER + 1};
  background-color: var(--colors-alabaster-3);
  right: -${STAR_COMMAND_RHS_WIDTH}px;
  display: flex;
  flex-direction: column;

  &.isOpen {
    right: 0;
  }
`;

export const ComponentTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: var(--colors-river-bad);
  text-transform: uppercase;
`;

export const Section = styled.div`
  background-color: var(--colors-white);
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  margin: 10px;
  padding: 10px 0 30px;
  overflow: auto;
  .list-header {
    & > span {
      text-align: center;
    }
  }
`;

export const LabelItem = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.035em;
  color: #74828f;
  flex: 1;
`;

export const ListItem = styled.div<{ hovered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid var(--colors-light-gray);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  flex: 1;
  user-select: none;
  ${(props) => {
    if (props.hovered) {
      return ` background-color: var(--colors-white-ice);
      border-color: var(--colors-teal-3);`;
    }
  }};

  &.isDragging {
    background-color: var(--colors-white-ice);
  }

  &.isDraggingOver {
    background-color: yellow;
  }

  &:hover {
    background-color: var(--colors-white-ice);
    border-color: var(--colors-teal-3);
  }

  .checkbox__text {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 15px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: var(--colors-outer-space);
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 30px;
    height: 1.2em;
    white-space: nowrap;
  }

  & > span {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #6a6c71;
  }
`;

export const List = styled(StyledFlex)`
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  padding: 10px;

  &.isDraggingOver {
    border: 1px dashed lightgray;
  }
`;
