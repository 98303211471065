import React, { FC, useState } from "react";
import { Wrapper, InnerWrapper, Label, CrewConfItem } from "./AddCrewPopup.styled";
import { Checkbox } from "../Checkbox";
import { StyledFlex, FlexItem } from "../../assets/styles/flex.styled";
import { ReactComponent as MoreInfo } from "../../assets/images/more-info.svg";
import { Crew, CrewConfig } from "../../types/responses/Crews";

interface Props {
  toggleCrew: (crewId: string) => void;
  crews: Crew[];
  crewsByRegion:
    | {
        id: string;
        name: string;
      }[]
    | [];
}

export const AddCrewPopup: FC<Props> = ({ crews, crewsByRegion, toggleCrew }) => {
  const [selectedCrewIdx, setSelectedCrewIdx] = useState(0);
  const seletedCrew = crews.find((crew, idx) => idx === selectedCrewIdx);

  return (
    <Wrapper flexDirection="column" onClick={(e) => e.stopPropagation()}>
      <InnerWrapper>
        <FlexItem flex={1}>
          <StyledFlex flexDirection="column" height="100%" padding="20px 0 0 20px">
            <StyledFlex
              flexDirection="column"
              overflowY="scroll"
              overflowX="hidden"
              gap="10px"
              className="crewsList"
              paddingBottom="10px"
            >
              {crews.map((crew, idx) => (
                <Checkbox
                  key={crew.id}
                  label={crew.name}
                  name={crew.name}
                  value={crew.name}
                  id={crew.id}
                  checked={crewsByRegion?.some((crewByReg) => crewByReg.id === crew.id)}
                  onClick={() => {
                    setSelectedCrewIdx(idx);
                    toggleCrew(crew?.id);
                  }}
                  defaultChecked={false}
                />
              ))}
            </StyledFlex>
            <StyledFlex mt="auto" gap="5px" padding="20px 0 0">
              <Label className="info">Crew management is done in the company configuration panel.</Label>
              <MoreInfo />
            </StyledFlex>
          </StyledFlex>
        </FlexItem>
        <FlexItem flex={1}>
          <StyledFlex flexDirection="column" padding="20px" overflowY="scroll" maxHeight="100%">
            <span>{seletedCrew?.name}</span>
            <Label className="crewInfo">Work Performed</Label>
            <StyledFlex mt="10px" flexDirection="column">
              {seletedCrew?.compatibleWorkTypes?.map((work) => work.name).join(", ")}
            </StyledFlex>
            <Label className="crewInfo">Crew Configuration</Label>
            <StyledFlex mt="10px" flexDirection="column">
              {seletedCrew?.configuration?.map((conf: CrewConfig, idx) => (
                <CrewConfItem key={idx} justifyContent="space-between" alignItems="center">
                  <span>{conf.roleName}</span>
                  <span>{conf.numberOfPeople}</span>
                </CrewConfItem>
              ))}
            </StyledFlex>
          </StyledFlex>
        </FlexItem>
      </InnerWrapper>
    </Wrapper>
  );
};
