import { buffer, Feature, LineString, lineString, Point, Properties, point } from "@turf/turf";
import create from "zustand";

import { EMPTY_FN } from "../constants";
import { calculateBounds } from "../pages/MapScreen/MapScreen.utils";
import { FeedersResponse, HierarchyResponse, RegionDTOProperties } from "../types/responses";
import MapHelper from "../utils/mapHelper";
import { RISK_PRIORITY } from "./MapStore";

export type HierarchyStoreState = {
  currentRegion: Nullable<string>;
  regions: Nullable<Record<string, HierarchyResponse>>;
  rootRegion: Nullable<string>;
  boundsByRegion: Nullable<Record<string, L.LatLngBoundsExpression>>;
  isFeederLevel: boolean;
  defaultCenter?: Nullable<L.LatLngExpression>;
  riskPrioritySelected: Nullable<RISK_PRIORITY>;
  regionPositions: Nullable<Record<string, number[]>>;
  regionsByRiskPriority: Nullable<{
    positions: Nullable<RegionDTOProperties[]>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }>;
  actions: {
    setRegion: (regionId: string, response: HierarchyResponse, feeders?: Nullable<FeedersResponse>) => void;
    setCurrentRegion: (regionId: string) => void;
    setRootRegion: (regionId: Nullable<string>) => void;
    setRiskPriority: (riskPriority: Nullable<RISK_PRIORITY>) => void;
    setSpanRiskPriority: (RiskPriority: RISK_PRIORITY, feederId: Nullable<string>) => void;
    addPosition: (centerPoint: Point, id: string) => void;
  };
};

const INITIAL_STATE = {
  currentRegion: null,
  rootRegion: null,
  defaultCenter: null,
  regions: {},
  boundsByRegion: {},
  isFeederLevel: false,
  regionPositions: {},
  riskPrioritySelected: null,
  regionsByRiskPriority: null,
  actions: {
    setRegion: EMPTY_FN,
    setCurrentRegion: EMPTY_FN,
    setRootRegion: EMPTY_FN,
    setRiskPriority: EMPTY_FN,
    setSpanRiskPriority: EMPTY_FN,
    addPosition: EMPTY_FN,
  },
};

export const useHierarchyStore = create<HierarchyStoreState>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    setRegion: (regionId: string, response: HierarchyResponse, feeders) => {
      const regions = { ...get().regions };
      const boundsByRegion = { ...get().boundsByRegion };
      const regionPositions = { ...get().regionPositions };

      regions[regionId] = response;
      const bounds = calculateBounds(response, feeders!);
      if (bounds && !boundsByRegion?.[regionId]) boundsByRegion[regionId] = bounds;
      if (response?.regions?.length) {
        response.regions.forEach((region) => {
          region.properties.riskPriority = {
            priority: region.properties.riskPriority?.priority ?? RISK_PRIORITY.LOW,
            score: region.properties.riskPriority?.score ?? 0,
          };
          if (region.geometry !== null && region.properties.spanId === null) {
            const bounds = MapHelper.normalizeBounds(
              MapHelper.bBox(false, region.geometry)
            ) as L.LatLngBoundsExpression;
            if (bounds && !boundsByRegion?.[region.properties.id]) boundsByRegion[region.properties.id] = bounds;
          } else {
            if (region.properties.centerPoint && region.properties.spanId)
              regionPositions[region.properties.spanId] = region.properties.centerPoint
                .map((item) => Number(item))
                .reverse();
          }
        });
      }
      //set defaultCenter only once
      if (get().rootRegion === regionId) {
        const center = MapHelper.centroid(true, response.regions ?? []);
        center && set({ defaultCenter: [center.geometry.coordinates[1], center.geometry.coordinates[0]] });
      }

      set({ regions, boundsByRegion, regionPositions });
    },
    setCurrentRegion: (currentRegion: string) => {
      if (get().currentRegion === currentRegion) return;
      set({
        currentRegion: currentRegion ?? get().rootRegion,
        isFeederLevel: !!currentRegion && get().rootRegion !== currentRegion,
      });
    },
    setRootRegion: (regionId) => set({ rootRegion: regionId, currentRegion: regionId }),
    setRiskPriority: (risk) => set({ riskPrioritySelected: risk }),
    setSpanRiskPriority: (risk, feederId) => {
      if (risk === get().riskPrioritySelected) {
        return;
      }
      const currentRegion = get().currentRegion;
      const regions = get().regions;
      const feederSelected =
        currentRegion && feederId
          ? regions?.[currentRegion]?.regions?.find((region) => region.properties.feederId === feederId)?.properties.id
          : null;
      if (!feederSelected || !regions?.[feederSelected] || !risk) {
        set({ regionsByRiskPriority: null, riskPrioritySelected: null });
        return;
      }

      const spanPositions = regions?.[feederSelected].regions
        .filter(
          (region) =>
            region.properties.centerPoint !== null &&
            region.properties.spanId !== null &&
            region.properties.riskPriority?.priority === risk
        )
        .map((region) => {
          return {
            ...region.properties,
            centerPoint: (region.properties.centerPoint?.map((point) => Number(point)) ?? []).reverse(),
          } as RegionDTOProperties;
        });

      set({
        regionsByRiskPriority: {
          bounds: getBounds(spanPositions),
          positions: spanPositions,
        },
        riskPrioritySelected: risk,
      });
    },
    addPosition: (point, id) => {
      if (!point) return;
      const regionPositions = { ...get().regionPositions };

      regionPositions[id] = [point.coordinates[1], point.coordinates[0]];
      set({ regionPositions });
    },
  },
}));

const getBounds = (spanPositions: RegionDTOProperties[]) => {
  if (!spanPositions || !spanPositions?.length) {
    return null;
  }

  const geometry: Feature<LineString, Properties> | Feature<Point, Properties> =
    spanPositions?.length > 1
      ? lineString(spanPositions.map((item) => [item.centerPoint?.[1]!, item.centerPoint?.[0]!]))
      : point([spanPositions?.[0].centerPoint?.[1]!, spanPositions[0].centerPoint?.[0]!]);
  return MapHelper.normalizeBounds(MapHelper.bBox(false, buffer(geometry, 100, { units: "meters" })));
};

export default useHierarchyStore;
