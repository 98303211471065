import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Wrapper,
  AvatarButton,
  HeaderButton,
  Dropdown,
  Username,
  LogoutButton,
  CloseIcon,
  HamburgerDropdown,
  HamburgerButton,
  Arrow,
  Message,
  CloseMessage,
} from "./TopHeader.styled";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { VantageLogo } from "../VantageLogo";
import { ReactComponent as HamburgerIcon } from "../../assets/images/hamburger_menu.svg";
import { ReactComponent as Notification } from "../../assets/images/notification.svg";
import { ReactComponent as ChatBubble } from "../../assets/images/chat_bubble.svg";
import { ReactComponent as CutPlan } from "../../assets/images/cut_plan.svg";
import { ReactComponent as Logout } from "../../assets/images/logout.svg";
import avatar from "../../assets/images/avatar.png";
import useCommentsStore from "../../stores/CommentStore";
import useAuthStore from "../../stores/AuthStore";
import useMessagesStore, { MessageType } from "../../stores/MessagesStore";
import { ReactComponent as WarningImage } from "./../../assets/images/warning_white.svg";
import { ReactComponent as CheckMark } from "./../../assets/images/check_mark.svg";
import { ReactComponent as Close } from "./../../assets/images/close.svg";
import { OkCancelModalDialog } from "../ModalDialog/OkCancelModalDialog";
import useCwcStore from "../../stores/MapCwcStore";
import { useCutPlanStore } from "../../stores";
import { getStarCommandRoute, plansRoute, getAdminRoute } from "../../routes";

type TopHeaderProps = {
  commentDisabled?: boolean;
  cutPlanDisabled?: boolean;
};
export const TopHeader: FC<TopHeaderProps> = ({ commentDisabled = false, cutPlanDisabled = false }) => {
  const { toggleCutPlanHidden } = useCutPlanStore((store) => store.actions);
  const cutPlanHidden = useCutPlanStore((store) => store.cutPlanHidden);
  const areCommentsVisible = useCommentsStore((store) => store.isLayerVisible);
  const toggleComments = useCommentsStore((store) => store.toggleLayer);
  const user = useAuthStore((store) => store.user);
  const isAdmin = useAuthStore((store) => store.isAdmin);
  const logOut = useAuthStore((store) => store.logOut);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [modalHidden, setModalHidden] = useState(true);
  const isEditModeEnabled = useCwcStore((store) => store.isEditEnabled);
  const { setEditState } = useCwcStore((store) => store.actions);
  const hideModal = () => setModalHidden(true);
  const history = useHistory();
  const message = useMessagesStore((store) => store.errorMessage);
  const messageType = useMessagesStore((store) => store.type);
  const resetStore = useMessagesStore((store) => store.resetStore);

  const checkBeforeToggle = () => {
    if (isEditModeEnabled) {
      setModalHidden(false);
    } else {
      toggleComments();
    }
  };

  const callAction = () => {
    setEditState(false);
    toggleComments();
    setModalHidden(true);
  };

  return (
    <Wrapper alignItems="center">
      <StyledFlex position="relative">
        <HeaderButton
          Icon={isHamburgerOpen ? CloseIcon : HamburgerIcon}
          onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
          className={isHamburgerOpen ? "active" : ""}
          width="47px"
          padding="0"
        />
        <HamburgerDropdown flexDirection="column" className={isHamburgerOpen ? "isOpen" : ""}>
          <HamburgerButton
            variant="light"
            onClick={() => {
              setIsHamburgerOpen(false);
              history.push(getStarCommandRoute());
            }}
          >
            Star Command
          </HamburgerButton>
          {isAdmin && (
            <HamburgerButton
              variant="light"
              onClick={() => {
                setIsHamburgerOpen(false);
                history.push(plansRoute);
              }}
            >
              Plan Dashboard
            </HamburgerButton>
          )}
          {isAdmin && (
            <HamburgerButton
              variant="light"
              onClick={() => {
                setIsHamburgerOpen(false);
                history.push(getAdminRoute());
              }}
            >
              Admin Dashboard
            </HamburgerButton>
          )}
          <HamburgerButton variant="light" disabled>
            Cut Planning
          </HamburgerButton>
          <HamburgerButton variant="light" disabled>
            Reports
            <Arrow />
          </HamburgerButton>
        </HamburgerDropdown>
      </StyledFlex>
      <VantageLogo px="18px" />
      <HeaderButton
        disabled={cutPlanDisabled}
        Icon={CutPlan}
        marginLeft="auto"
        onClick={toggleCutPlanHidden}
        className={cutPlanHidden ? "" : "active"}
      />
      <HeaderButton
        disabled={commentDisabled}
        Icon={ChatBubble}
        onClick={checkBeforeToggle}
        className={areCommentsVisible ? "active" : ""}
      />
      <HeaderButton Icon={Notification} disabled />

      <StyledFlex position="relative">
        <AvatarButton onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
          <img src={avatar} alt={user?.name} />
        </AvatarButton>
        {isUserMenuOpen && (
          <Dropdown flexDirection="column">
            <Username>{user?.name}</Username>
            <LogoutButton Icon={Logout} text="Log out" onClick={logOut} />
          </Dropdown>
        )}
      </StyledFlex>
      {!modalHidden && <OkCancelModalDialog hideModal={hideModal} callAction={callAction} modalHidden={modalHidden} />}
      {message && (
        <Message className={messageType === MessageType.Success ? "success" : ""}>
          {messageType === MessageType.Error ? <WarningImage /> : <CheckMark width={15} height={15} />}
          <span>{message}</span>
          <CloseMessage Icon={Close} onClick={() => resetStore()} />
        </Message>
      )}
    </Wrapper>
  );
};

export default TopHeader;
