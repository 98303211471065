import styled from "styled-components";
import { Data1, Data2 } from "../../assets/styles/fonts.styled";
import { IconButton } from "../IconButton";

export const WorkTypeWrapper = styled.div<{ isEditMode: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colors-white);
  border: 1px solid var(--colors-light-gray);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding: 10px;
  margin: 0 15px;
  ${Data2}

  &:last-child {
    margin-bottom: 10px;
  }
`;

export const WorkTypeLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const WorkTypeRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const WorkTypeImage = styled.img``;

export const WorkTypeNameWrapper = styled.div<{ hasAdditionalText?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding-left: 10px;
  ${Data2}
  color: ${(props) => props.theme.colors.colorRiverBad};
  letter-spacing: -0.035em;
  ${({ hasAdditionalText }) => (hasAdditionalText ? "align-items: flex-start;" : "line-height: 13px;")};

  span.additionalLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--parcel-note-passive);
    display: flex;
    width: 100%;
  }
`;

export const WorkTypeExtendedName = styled.div<{ isAutoAssigned?: boolean }>`
  display: flex;
  height: 20px;
  align-items: center;
  div:last-child {
    color: ${({ isAutoAssigned }) => (isAutoAssigned ? "#0a919c" : "inherit")};
  }
  svg {
    margin-left: 4px;
  }
`;

export const WorkTypeCost = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  ${Data1}
  color: #636778;
`;

export const MoreOptions = styled.span<{ isEditMode: boolean; dataTooltip?: string }>`
  position: relative;
  height: 18px;
  width: 4px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: ${({ isEditMode }) => (isEditMode ? "pointer" : "default")};

  div {
    position: absolute;
    top: 100%;
    right: 10px;
    background: var(--colors-white);
    padding: 10px 0;
    border: 1px solid var(--colors-light-gray);
    box-shadow: 0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
    transform: scaleY(0);
    transition: all 0.15s ease-out;
    overflow: hidden;
    transform-origin: top right;
    z-index: 1;
  }

  &.open div {
    transform: scaleY(1);
  }

  &:hover {
    ${({ isEditMode }) =>
      isEditMode
        ? `
        background-color: var(--colors-light-gray);
        svg path { fill: #1EA7B2; }
      `
        : `
        background-color: transparent;
        ${Tooltip} { display: block; }
      `};
  }

  &.open {
    background-color: #dfdddd;

    svg path {
      fill: #117c85;
    }
  }
`;

export const OptionButton = styled.button`
  display: flex;
  gap: 10px;
  border: none;
  background-color: var(--colors-white);
  padding: 7px 15px 7px 7px;
  font-weight: 600;
  user-select: none;
  white-space: nowrap;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: var(--colors-light-gray);
  }
`;

export const Tooltip = styled.span`
  display: none;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-70%, 70%);
  background-color: black;
  border-radius: 5px;
  padding: 5px;
  color: white;
  user-select: none;
  z-index: 2;
  font-size: 12px;
  text-align: center;
`;

export const DeleteWorktypeButton = styled(IconButton)`
  border: none;
`;

export const AdditionalLabelText = styled.span`
  font-weight: 500;
  font-size: 13px;
  color: var(--colors-vantage-green);
  display: flex;
  width: 100%;
`;
