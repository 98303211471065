import create from "zustand";

import { EMPTY_FN } from "../constants";
import { SwitchesResponse, Switch } from "../types/responses";

export type SwitchesById = Record<string, Switch>;

export type SwitchStore = {
  switchesGeoJSON: Nullable<SwitchesResponse>;
  switchesById: SwitchesById;
  switchesByStructureId: SwitchesById;
  resetStore: () => void;
  setSwitchesData: (switches: SwitchesResponse) => void;
  getSwitchByStructureId: (id: string) => Nullable<Switch>;
  getSwitchById: (id: string) => Nullable<Switch>;
};

const INITIAL_STATE = {
  switchesGeoJSON: null,
  switchesById: {} as SwitchesById,
  switchesByStructureId: {} as SwitchesById,
  resetStore: EMPTY_FN,
};

export const useSwitchesStore = create<SwitchStore>((set, get) => ({
  ...INITIAL_STATE,
  setSwitchesData: (switches) => set(mapSwitchesResponse(switches)),
  getSwitchById: (id: string) => get().switchesById?.[id],
  getSwitchByStructureId: (id: string) => get().switchesByStructureId?.[id],
  resetStore: () => set({ ...INITIAL_STATE }),
}));

export const mapSwitchesResponse = (switchesGeoJSON: SwitchesResponse) => {
  const switchesById: SwitchesById = switchesGeoJSON.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr;

    return prev;
  }, {} as SwitchesById);

  const switchesByStructureId: SwitchesById = switchesGeoJSON.features.reduce((prev, curr) => {
    if (!curr.properties.structureId) {
      return prev;
    }
    prev[curr.properties.structureId] = curr;

    return prev;
  }, {} as SwitchesById);

  return {
    switchesGeoJSON,
    switchesById,
    switchesByStructureId,
  };
};

export default useSwitchesStore;
