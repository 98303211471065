import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { Cell, Column, Row, TableCommonProps } from "react-table";
import * as Yup from "yup";
import { getWorkTypesApiUrl } from "../../../api";

import { StyledFlex } from "../../../assets/styles/flex.styled";
import { AddRole } from "../../../components/AddRole";
import { Button } from "../../../components/Button";
import { CrewConfig } from "../../../components/CrewConfig/CrewConfig";
import { JobCompatibility } from "../../../components/JobCompatibility";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { ModalDialog } from "../../../components/ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";

import { CustomColumn, Table } from "../../../components/Table";
import { Toast } from "../../../components/Toast";
import { NUMBER_ERROR, POSITIVE_NUMBER_ERROR_MSG, SOMETHING_WENT_WRONG_MESSAGE } from "../../../constants";
import { ONLY_NUMBERS_WITH_TWO_DECIMALS } from "../../../constants/regex";
import { useCrewRoles, useCustomQuery } from "../../../hooks";
import { useAddCrewRole } from "../../../hooks/useAddCrewRole";
import { useAddNewCrew } from "../../../hooks/useAddNewCrew";
import useCrews from "../../../hooks/useCrews";
import { useDeleteContractorRole } from "../../../hooks/useDeleteContractorRole";
import { useDeleteCrew } from "../../../hooks/useDeleteCrew";
import useRegions from "../../../hooks/useRegions";
import { useUpdateCompatibleWork } from "../../../hooks/useUpdateCompatibleWork";
import { useUpdateCrewConfiguration } from "../../../hooks/useUpdateCrewConfiguration";
import { useUpdateCrewName } from "../../../hooks/useUpdateCrewName";
import { useUpdateNumberOfPeople } from "../../../hooks/useUpdateNumberOfPeople";
import { logError } from "../../../services";
import { LoadingState, useAdminLoadingStore, useMessageStore } from "../../../stores";
import { WorkTypeResponse } from "../../../types/responses";
import { ConfigType, CrewConfig as CrewConfigModel, ValueType } from "../../../types/responses/Crews";
import { capitalizeFirstLetter, toUsdCurrency } from "../../../utils/stringUtils";
import { SubPageWrapper, HeaderText, TableFooterRowWrapper, CrewTableWrapper, HeaderWrapper } from "./SubPages.styled";
import { checkError } from "./SubPages.util";

type CrewConfigurationColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

const validationParams = {
  value: Yup.number()
    .typeError(NUMBER_ERROR)
    .required()
    .positive()
    .min(0, POSITIVE_NUMBER_ERROR_MSG)
    .test("maxDigitsAfterDecimal", "Number field must have 2 digits after decimal or less.", (value) =>
      value ? ONLY_NUMBERS_WITH_TWO_DECIMALS.test(value.toString()) : true
    ),
};

export const CrewConfiguration: FC = () => {
  const setLoadingState = useAdminLoadingStore((store) => store.setLoadingState);
  const setErrorMessage = useMessageStore((store) => store.setErrorMessage);
  const resetStore = useAdminLoadingStore((store) => store.resetStore);
  const [selectedCrewId, setCrewId] = useState<Nullable<string>>(null);
  const [modalHidden, hideModal] = useState(true);
  const [createCrewDisabled, setCreateCrewDisabled] = useState(false);

  useEffect(() => {
    resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRequestMutate = useCallback(() => {
    setLoadingState(LoadingState.Loading);
    setErrorMessage(null);
  }, [setLoadingState, setErrorMessage]);

  const onRequestError = useCallback(
    (error) => {
      setLoadingState(LoadingState.Error);
      let message = SOMETHING_WENT_WRONG_MESSAGE;
      if (checkError(error)) {
        message = error.response?.data?.message;
      }
      setErrorMessage(message);
    },
    [setLoadingState, setErrorMessage]
  );

  const onRequestSuccess = useCallback(() => {
    setLoadingState(LoadingState.Success);
  }, [setLoadingState]);

  const isTableEditable = selectedCrewId !== null;

  const { data: crews, isLoading: crewLoading, isError: crewError, refetch } = useCrews();

  const { data: crewRoles } = useCrewRoles();

  const { data: regionGroups } = useRegions();

  const { mutateAsync: addNewRole } = useAddCrewRole();

  const useDeleteRole = useDeleteContractorRole();

  const deleteCrew = useDeleteCrew();

  const updateJobs = useUpdateCompatibleWork();

  const updateCrewConfiguration = useUpdateCrewConfiguration();

  const updateNumberOfPeople = useUpdateNumberOfPeople();

  const updateCrewNameMutation = useUpdateCrewName();

  const addNewCrewMutation = useAddNewCrew();

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());

  const allJobs = useMemo(() => {
    if (!workTypesResponse) {
      return [];
    }

    return workTypesResponse.map((item) => ({ name: item.name, id: item.id }));
  }, [workTypesResponse]);

  const addNewCrewRole = useCallback(
    (id: string) => {
      if (!selectedCrewId) {
        return;
      }

      addNewRole({ crewId: selectedCrewId, id: id })
        .then(() => refetch())
        .catch((e) => logError(e));
    },
    [addNewRole, selectedCrewId, refetch]
  );

  const updateCrewName = useCallback(
    (name: string, crewId: string) => {
      onRequestMutate();
      updateCrewNameMutation
        .mutateAsync({ id: crewId, name: name })
        .then(() => {
          refetch();
          onRequestSuccess();
        })
        .catch((e) => onRequestError(e));
    },
    [updateCrewNameMutation, onRequestSuccess, onRequestError, onRequestMutate, refetch]
  );

  const addNewCrew = useCallback(
    (name: string) => {
      onRequestMutate();
      addNewCrewMutation
        .mutateAsync(name)
        .then(() => {
          refetch();
          onRequestSuccess();
        })
        .catch((e) => onRequestError(e));
    },
    [addNewCrewMutation, onRequestSuccess, onRequestError, onRequestMutate, refetch]
  );

  const updateCompatiableJobs = useCallback(
    (jobs: string[]) => {
      if (!selectedCrewId) {
        return;
      }
      onRequestMutate();
      updateJobs
        .mutateAsync({ id: selectedCrewId, list: jobs })
        .then(() => {
          onRequestSuccess();
          refetch();
        })
        .catch((e) => onRequestError(e));
    },
    [updateJobs, selectedCrewId, refetch, onRequestMutate, onRequestError, onRequestSuccess]
  );

  const deleteCrewConfig = useCallback(() => {
    hideModal(true);
    if (!selectedCrewId) {
      return;
    }

    onRequestMutate();
    deleteCrew
      .mutateAsync(selectedCrewId)
      .then(() => {
        refetch();
        onRequestSuccess();
        setCrewId(null);
      })
      .catch((e) => onRequestError(e));
  }, [deleteCrew, selectedCrewId, onRequestError, onRequestMutate, onRequestSuccess, refetch]);

  const createNewCrew = () => {
    setCrewId(null);
  };

  const updateNumberOfPeopleFiled = useCallback(
    (contractorRoleId: string, value: ValueType) => {
      if (!selectedCrewId) {
        return Promise.resolve(LoadingState.Idle);
      }
      onRequestMutate();
      return updateNumberOfPeople
        .mutateAsync({
          crewId: selectedCrewId,
          contractorRoleId: contractorRoleId,
          value: value,
        })
        .then(() => {
          onRequestSuccess();
          return Promise.resolve(LoadingState.Success);
        })
        .catch((e) => {
          onRequestError(e);
          return Promise.resolve(LoadingState.Error);
        });
    },
    [updateNumberOfPeople, selectedCrewId, onRequestMutate, onRequestSuccess, onRequestError]
  );

  const updateBillingRate = useCallback(
    (value: ValueType) => {
      if (!selectedCrewId) {
        return Promise.resolve(LoadingState.Idle);
      }
      onRequestMutate();
      return updateCrewConfiguration
        .mutateAsync({
          crewId: selectedCrewId,
          value: value,
        })
        .then(() => {
          refetch();
          onRequestSuccess();
          return Promise.resolve(LoadingState.Success);
        })
        .catch((e) => {
          onRequestError(e);
          return Promise.resolve(LoadingState.Error);
        });
    },

    [updateCrewConfiguration, selectedCrewId, refetch, onRequestError, onRequestMutate, onRequestSuccess]
  );

  const updateField = (
    dataType: ConfigType | "number-of-people",
    newValue: number,
    roleID: string,
    regionId?: string
  ) => {
    if (dataType === "number-of-people") {
      return updateNumberOfPeopleFiled(roleID, { numberOfPeople: newValue });
    }
    return updateBillingRate({
      contractorRoleId: roleID,
      regionGroupId: regionId ?? "",
      billingRate: newValue,
      overtime: (dataType as ConfigType) === ConfigType.OVERTIME,
    });
  };

  useEffect(() => {
    if (selectedCrewId) {
      return;
    }
    setCrewId(
      crews?.sort(
        (crew1, crew2) =>
          new Date(crew2.updated ?? new Date().getTime())?.getTime() -
          new Date(crew1.updated ?? new Date().getTime())?.getTime()
      )?.[0]?.id ?? null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crews]);

  const deleteOneRoleForCrew = (roleId: string) => {
    if (!selectedCrewId) {
      return;
    }
    return useDeleteRole
      .mutateAsync({ crewId: selectedCrewId, roleId: roleId })
      .then(() => {
        return Promise.resolve(LoadingState.Success);
      })
      .catch((e) => {
        logError(e);
        return Promise.resolve(LoadingState.Error);
      })
      .finally(() => refetch());
  };

  const columns: CrewConfigurationColumns<CrewConfigModel>[] = useMemo(() => {
    const dynamicFields: CrewConfigurationColumns<CrewConfigModel>[] = [];
    dynamicFields.push({
      Header: "Role",
      Footer: () => <TableFooterRowWrapper></TableFooterRowWrapper>,

      columns: [
        {
          accessor: (data) => {
            return capitalizeFirstLetter(data.roleName);
          },
          id: "roleName",
          //@ts-ignore
          type: "string",
          Cell: ({ row }: Cell<CrewConfigModel>) => {
            return <div className="role-name">{capitalizeFirstLetter(row.original.roleName)}</div>;
          },
          Footer: () => (
            <TableFooterRowWrapper>
              <AddRole
                selectedRoles={
                  crews?.find((crew) => crew.id === selectedCrewId)?.configuration?.map((config) => config.roleId) ?? []
                }
                editable={isTableEditable}
                roles={crewRoles ?? []}
                saveRole={(role: { name: string; id: string }) => addNewCrewRole(role.id)}
              />
            </TableFooterRowWrapper>
          ),
          className: "role-name",
        },
      ],
    });
    dynamicFields.push({
      Header: "Number of people",
      editable: isTableEditable,
      canRemove: false,
      type: "number",
      Footer: () => <TableFooterRowWrapper></TableFooterRowWrapper>,
      columns: [
        {
          Header: "",
          accessor: "numberOfPeople",
          id: "numberOfPeople",
          //@ts-ignore
          editable: isTableEditable,
          dataType: "number-of-people",
          disabled: true,
          Footer: () => (
            <TableFooterRowWrapper>
              <div className="footer-number-of-people">0</div>
            </TableFooterRowWrapper>
          ),
          type: "number",
          className: "number-of-people",
          name: "numberOfPeople",
          validationParams: {
            numberOfPeople: Yup.number()
              .typeError(NUMBER_ERROR)
              .required()
              .positive()
              .integer("Number of people can't be decimal number."),
          },
        },
      ],
    });
    regionGroups?.forEach((item, id) => {
      dynamicFields.push({
        Header: item.name,
        editable: isTableEditable,
        canRemove: true,
        id: item.id,
        type: "number",
        Footer: () => <TableFooterRowWrapper></TableFooterRowWrapper>,
        columns: [
          {
            Header: `Straight time billing rate`,
            id: `${id}-straight`,
            accessor: (data: CrewConfigModel) => {
              return (
                data?.costByRegions?.find((region) => region.regionGroupId === item.id)?.straightTimeBillingRate ?? 0
              );
            },
            //@ts-ignore
            regionId: item.id,
            editable: isTableEditable,
            type: "string",
            dataType: ConfigType.STRAIGHT,
            className: "billing-rate",
            formatInactive: (value: number) => toUsdCurrency(value.toString(), 2),
            Footer: () => (
              <TableFooterRowWrapper>
                <div className="footer-billing-rate">{toUsdCurrency(0, 0)}</div>
              </TableFooterRowWrapper>
            ),
          },
          {
            Header: `Overtime time billing rate`,
            id: `${id}-overtime`,
            accessor: (data: CrewConfigModel) => {
              return (
                data?.costByRegions?.find((region) => region.regionGroupId === item.id)?.overtimeTimeBillingRate ?? 0
              );
            },
            //@ts-ignore
            editable: isTableEditable,
            regionId: item.id,
            type: "string",
            dataType: ConfigType.OVERTIME,
            className: "billing-rate",
            formatInactive: (value: number) => toUsdCurrency(value.toString(), 2),
            Footer: () => (
              <TableFooterRowWrapper>
                <div className="footer-billing-rate">{toUsdCurrency(0, 0)}</div>
              </TableFooterRowWrapper>
            ),
          },
        ],
      });
    });
    dynamicFields.push({
      Header: "",
      editable: isTableEditable,
      canRemove: true,
      type: "number",
      width: 50,
      className: "remove-role",
      Footer: () => <></>,
      id: "remove-role",
    });
    return dynamicFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crews, regionGroups, isTableEditable, selectedCrewId]);

  const hasError = crewError;
  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!crewLoading} />

      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>
      <ModalDialog
        hidden={modalHidden}
        warning
        title="Delete voltage"
        message={"Deleting Crew will remove it from crew list."}
        onClose={() => hideModal(true)}
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <Button variant="cancel" size="small" onClick={() => hideModal(true)}>
              Cancel
            </Button>

            <Button variant="warning" size="small" onClick={deleteCrewConfig}>
              Delete
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </ModalDialog>
      <HeaderWrapper>
        <HeaderText>Crew Configuration</HeaderText>
        <StyledFlex gap="20px">
          <Button
            className="delete-button"
            variant="transparent"
            size="small"
            onClick={() => hideModal(false)}
            disabled={!selectedCrewId}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            size="small"
            className="add-new-crew"
            onClick={() => createNewCrew()}
            disabled={createCrewDisabled}
          >
            Create New Crew
          </Button>
        </StyledFlex>
      </HeaderWrapper>
      <>
        <CrewConfig
          selectedCrew={selectedCrewId ?? undefined}
          crews={crews ?? []}
          addCrew={(name: string) => addNewCrew(name)}
          editCrew={(name: string, id: string) => updateCrewName(name, id)}
          selectCrew={(id) => setCrewId(id)}
          setCreateCrewDisabled={setCreateCrewDisabled}
        />
        <JobCompatibility
          skills={allJobs ?? []}
          selectedSkills={crews?.find((crew) => crew?.id === selectedCrewId)?.compatibleWorkTypes ?? []}
          updateSkills={(jobs: string[]) => updateCompatiableJobs(jobs)}
        />
      </>

      {!crewLoading && !hasError && (
        <CrewTableWrapper>
          <Table<CrewConfigModel>
            columns={columns}
            data={crews?.find((item) => item.id === selectedCrewId)?.configuration ?? []}
            multiItem
            validationParams={validationParams}
            updateField={(row: Row<CrewConfigModel>, column: Column<{}>, value: string) => {
              //@ts-ignore
              return updateField(column.dataType, Number(value), row.original.roleId, column.regionId);
            }}
            removeField={(row) => deleteOneRoleForCrew(row.original.roleId)}
          ></Table>
        </CrewTableWrapper>
      )}
    </SubPageWrapper>
  );
};
