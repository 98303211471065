export const TREE_CANOPY_LAYER_STYLE = {
  color: "var(--colors-white)",
  fill: true,
  fillColor: "rgb(56, 6, 73)",
  opacity: 1,
  weight: 1,
  fillOpacity: 1,
};

export const TREE_CANOPY_LAYER_STYLE_SELECTED = {
  color: "var(--colors-white)",
  opacity: 1,
  weight: 3,
  fillOpacity: 1,
};
export const TREE_CANOPY_LEGEND: Record<string, { min: number; max: number; color: string; name: string }> = {
  LOW1: { min: 0, max: 35, color: "#04287B", name: "35 ft" },
  LOW2: { min: 35, max: 45, color: "#1749A2", name: "45 ft" },
  MEDIUM: { min: 45, max: 55, color: "#7614B7", name: "55 ft" },
  HIGH1: { min: 55, max: 70, color: "#C512CF", name: "70 ft" },
  HIGH2: { min: 70, max: 100, color: "#F42097", name: "75 ft" },
};

export const TREE_CANOPY_LAYER_KEY = "TREE_CANOPY";

export const TREE_CANOPY_PANE_NAME = "tree-layer-pane";

export const TREE_CANOPY_POPUP_CLASS_NAME = "tree-canopy-popup";
