import { FC, useMemo, memo, useEffect, useState } from "react";
import { GeoJSON, Marker } from "react-leaflet";
import L from "leaflet";
import { Geometry } from "geojson";

import MapHelper from "../../utils/mapHelper";
import { COUNTY_STYLE } from "./MapCounties.constants";
import { CountiesProperties, CountiesResponse } from "../../types/responses";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { MapMarkerCluster } from "../MapMarkerCluster";
import "./MapCounties.styles.css";
import { DEFAULT_ZOOM_LEVEL } from "../../stores/MapStore";
import { useCustomQuery, useExtentParams } from "../../hooks";
import { getCountiesApiUrl, getVectorLayersByExtent } from "../../api";

interface MapCountiesProps {
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
  enabled: boolean;
}

const COUNTIES_PANE_NAME = "counties-pane";

export const MapCounties: FC<MapCountiesProps> = memo(({ hidden = false, map, zIndex = 399, enabled }) => {
  useCustomMapPane({
    map,
    name: COUNTIES_PANE_NAME,
    zIndex,
  });

  const requestParams = useExtentParams();

  const [countiesData, setCounties] = useState<Nullable<CountiesResponse>>(null);

  const { refetch } = useCustomQuery<CountiesResponse>(
    requestParams ? getVectorLayersByExtent({ ...requestParams, url: getCountiesApiUrl() }) : getCountiesApiUrl(),
    {
      enabled: !hidden && requestParams !== null && enabled,
      onSuccess: (response: CountiesResponse) => setCounties(response),
    }
  );

  useEffect(() => {
    if (!requestParams || !hidden || !enabled) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams, hidden, enabled]);

  // calculate counties
  const counties = useMemo(() => {
    if (isGeoJSONEmpty(countiesData)) {
      return <></>;
    }

    return countiesData!.features.map((item: GeoJSON.Feature<Geometry, CountiesProperties>) => {
      const polylabel = MapHelper.findPolylabel(item);
      return (
        <GeoJSON
          data={item}
          interactive={false}
          key={item.properties.id}
          style={COUNTY_STYLE}
          pane={COUNTIES_PANE_NAME}
        >
          <Marker
            icon={L.divIcon({
              iconSize: [0, 0],
              html: `<span>${item.properties.name}</span>`,
              className: "county-name",
            })}
            title={item.properties.name}
            position={[polylabel[0], polylabel[1]]}
            pane={COUNTIES_PANE_NAME}
            key={item.properties.id}
          />
        </GeoJSON>
      );
    });
  }, [countiesData]);

  if (hidden) {
    return <></>;
  }

  return <MapMarkerCluster disableClusteringAtZoom={DEFAULT_ZOOM_LEVEL}>{counties}</MapMarkerCluster>;
});
