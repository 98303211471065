import React, { FC, memo, useCallback, useMemo, useState } from "react";

import { TaxName, TaxSubOptions } from "../";
import { ContentItemStyled } from "./TaxItem.styled";
import { TaxonomyItem } from "../../../types/TaxonomyItem";
import { filterOptionsByStatus } from "./TaxItem.utils";
import { RISK_PRIORITY } from "../../../types/responses";
import { SpanRiskPriority } from "../../../stores";
import { SpanPriority } from "../SpanPriority";
import { EMPTY_FN } from "../../../constants";
import { useIntersection } from "../../../hooks";

const ROOT_MARGIN = "-43px";
interface TaxItemProps {
  item: TaxonomyItem;
  onSelect: (item: TaxonomyItem, riskPriority?: SpanRiskPriority) => void;
  selectedItem: Nullable<string>;
  selectedSubItem: Nullable<string>;
  setHoveredObjectId: (id: Nullable<string>) => void;
  spanRiskPriority: SpanRiskPriority;
  hideFeeder: (feederId: string) => void;
  isItemHidden: boolean;
}

export const TaxItem: FC<TaxItemProps> = memo(
  ({
    item,
    onSelect,
    selectedItem,
    selectedSubItem,
    setHoveredObjectId,
    spanRiskPriority,
    hideFeeder,
    isItemHidden,
  }) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const inViewport = useIntersection(ref, ROOT_MARGIN);

    const isTaxItemSelected = selectedItem === item.id;
    const violationStatusItems = useMemo(() => filterOptionsByStatus(item.data, RISK_PRIORITY.VIOLATION), [item.data]);
    const criticalStatusItems = useMemo(() => filterOptionsByStatus(item.data, RISK_PRIORITY.CRITICAL), [item.data]);
    const highStatusItems = useMemo(() => filterOptionsByStatus(item.data, RISK_PRIORITY.HIGH), [item.data]);
    const mediumStatusItems = useMemo(() => filterOptionsByStatus(item.data, RISK_PRIORITY.MEDIUM), [item.data]);
    const lowStatusItems = useMemo(() => filterOptionsByStatus(item.data, RISK_PRIORITY.LOW), [item.data]);

    const selectFilter = useCallback(
      (e, selectedFilter: RISK_PRIORITY) => {
        e.stopPropagation();
        let numberOfItems = 0;

        switch (selectedFilter) {
          case RISK_PRIORITY.VIOLATION:
            numberOfItems = violationStatusItems.length;
            break;
          case RISK_PRIORITY.CRITICAL:
            numberOfItems = criticalStatusItems.length;
            break;
          case RISK_PRIORITY.HIGH:
            numberOfItems = highStatusItems.length;
            break;
          case RISK_PRIORITY.MEDIUM:
            numberOfItems = mediumStatusItems.length;
            break;
          case RISK_PRIORITY.LOW:
            numberOfItems = lowStatusItems.length;
            break;
        }

        if (!numberOfItems) {
          return;
        }

        onSelect(item, selectedFilter);
      },
      [item, onSelect, violationStatusItems, criticalStatusItems, highStatusItems, mediumStatusItems, lowStatusItems]
    );

    const subOptions = useMemo(() => {
      if (!item?.data) {
        return [];
      }

      switch (spanRiskPriority) {
        case RISK_PRIORITY.VIOLATION:
          return violationStatusItems;
        case RISK_PRIORITY.CRITICAL:
          return criticalStatusItems;
        case RISK_PRIORITY.HIGH:
          return highStatusItems;
        case RISK_PRIORITY.MEDIUM:
          return mediumStatusItems;
        case RISK_PRIORITY.LOW:
          return lowStatusItems;
        default:
          return item.data;
      }
    }, [
      item?.data,
      spanRiskPriority,
      violationStatusItems,
      criticalStatusItems,
      highStatusItems,
      lowStatusItems,
      mediumStatusItems,
    ]);

    const lengthByPriority = useMemo(() => {
      const lengths: Record<string, number> = {};
      lengths[RISK_PRIORITY.LOW] = lowStatusItems.length;
      lengths[RISK_PRIORITY.MEDIUM] = mediumStatusItems.length;
      lengths[RISK_PRIORITY.HIGH] = highStatusItems.length;
      lengths[RISK_PRIORITY.CRITICAL] = criticalStatusItems.length;
      lengths[RISK_PRIORITY.VIOLATION] = violationStatusItems.length;
      return lengths;
    }, [lowStatusItems, violationStatusItems, highStatusItems, criticalStatusItems, mediumStatusItems]);

    return (
      <>
        <ContentItemStyled
          sticky={inViewport}
          hasItems={item.data && item.data.length > 0 ? true : false}
          isItemSelected={isTaxItemSelected}
          isOpen={isTaxItemSelected}
          isSubItemSelected={!!selectedSubItem}
          onClick={() => onSelect(item)}
          onMouseOut={() => setHoveredObjectId(null)}
          onMouseOver={() => setHoveredObjectId(item.id)}
        >
          <TaxName
            name={item.name}
            riskPriority={item.riskPriority}
            selected={isTaxItemSelected}
            additionalLabel={item.additionalLabel}
            isSubItemSelected={!!selectedSubItem}
            isFeederLevel={item.type === "feeder"}
            feederId={item.type === "feeder" ? item.id : null}
            hideFeeder={item.type === "feeder" ? hideFeeder : EMPTY_FN}
            isItemHidden={isItemHidden}
          />
          {isTaxItemSelected && (
            <SpanPriority
              lengthByPriority={lengthByPriority}
              selectFilter={selectFilter}
              spanRiskPriority={spanRiskPriority}
            />
          )}
        </ContentItemStyled>
        {isTaxItemSelected && subOptions.length > 0 && (
          <>
            <TaxSubOptions
              setRef={setRef}
              onSelect={(subOption) => onSelect(subOption, spanRiskPriority)}
              options={subOptions}
              selectedItem={selectedItem}
              selectedSubItem={selectedSubItem}
              isTaxItemSelected={isTaxItemSelected}
              spanRiskPriority={spanRiskPriority}
            />
          </>
        )}
      </>
    );
  }
);
