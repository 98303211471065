import React, { FC, useEffect, useState, useCallback, useMemo, useReducer } from "react";
import { useMutation } from "react-query";
import { Column, Row, TableCommonProps } from "react-table";
import * as Yup from "yup";

import { getElevationUrl, getElevationEntryUrl } from "../../../api";
import { Button } from "../../../components/Button";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { ModalDialog } from "../../../components/ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";
import { CustomColumn, Table } from "../../../components/Table";
import { FooterCell } from "../../../components/Table/FooterCell/FooterCell";
import { Rows } from "../../../components/Table/RowsWrapper/RowsWrapper";
import { Toast } from "../../../components/Toast";
import { getDeleteMessage, NUMBER_ERROR, SOMETHING_WENT_WRONG_MESSAGE } from "../../../constants";
import { ONLY_NUMBERS_WITH_TWO_DECIMALS } from "../../../constants/regex";
import { useCustomQuery } from "../../../hooks";
import { logError } from "../../../services";
import { LoadingState, useAdminLoadingStore, useMessageStore } from "../../../stores";
import { ElevationLevel } from "../../../types/responses";
import ApiClient from "../../../utils/apiClient";
import { mapElevationResponse, makeNewElevation, unitConverter, isValueChanged, isInputEmpty } from "./Elevation.utils";
import { errorReducer, valueReducer } from "./SubPages.reducer";
import { SubPageWrapper, HeaderText, Placeholder, TableFooterRowWrapper } from "./SubPages.styled";
import { checkError } from "./SubPages.util";

type ElevationConfigurationColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

const validationParams = {
  value: Yup.number()
    .typeError(NUMBER_ERROR)
    .required()
    .positive()
    .min(0)
    .max(29029)
    .test("maxDigitsAfterDecimal", "number field must have 2 digits after decimal or less", (value) =>
      value ? ONLY_NUMBERS_WITH_TWO_DECIMALS.test(value.toString()) : true
    ),
};

export const Elevation: FC = () => {
  const [elevationConfigurations, setElevationConfiguration] = useState<Nullable<ElevationLevel[]>>(null);
  const {
    isLoading: loading,
    isError: hasError,
    data: elevationsConfigurationResponse,
    refetch: refetchElevations,
  } = useCustomQuery<ElevationLevel[]>(getElevationUrl());

  const setLoadingState = useAdminLoadingStore((store) => store.setLoadingState);
  const setErrorMessage = useMessageStore((store) => store.setErrorMessage);
  const resetStore = useAdminLoadingStore((store) => store.resetStore);
  const [addItemLoading, setAddItemLoading] = useState(LoadingState.Idle);
  const [inputState, dispatchInputState] = useReducer(valueReducer, {
    minValue: "",
    maxValue: "",
  });

  const [inputValidationState, dispatchInputValidationState] = useReducer(errorReducer, {
    minValueError: false,
    maxValueError: false,
  });

  const areInputsValid = !isInputEmpty(inputState.maxValue) && !isInputEmpty(inputState.minValue);

  const hasValidationError = inputValidationState.maxValueError || inputValidationState.minValueError;

  const resetErrorMessages = useCallback(() => {
    dispatchInputValidationState({ type: "CLEAR" });
  }, []);

  const onRequestMutate = useCallback(() => {
    setLoadingState(LoadingState.Loading);
    setErrorMessage(null);
  }, [setLoadingState, setErrorMessage]);

  const onRequestError = useCallback(
    (error) => {
      setLoadingState(LoadingState.Error);
      let message = SOMETHING_WENT_WRONG_MESSAGE;
      if (checkError(error)) {
        message = error.response?.data?.message;
      }
      setErrorMessage(message);
    },
    [setLoadingState, setErrorMessage]
  );

  const onRequestSuccess = useCallback(() => {
    setLoadingState(LoadingState.Success);
  }, [setLoadingState]);

  const [isModalShown, setModalShown] = useState(false);
  const [selectedIdForRemoval, setSelectedId] = useState<Nullable<string>>(null);

  const saveNewElevation = useMutation((newValue: ElevationLevel) => ApiClient.post(getElevationUrl(), newValue), {
    onMutate: onRequestMutate,
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });

  const deleteElevation = useMutation((id: string) => ApiClient.delete(getElevationEntryUrl(id)), {
    onMutate: onRequestMutate,
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });

  const updateElevation = useMutation(
    (newValue: ElevationLevel) =>
      ApiClient.put(getElevationEntryUrl(newValue.id), { min: newValue.min, max: newValue.max }),
    { onMutate: onRequestMutate, onSuccess: onRequestSuccess, onError: onRequestError }
  );

  useEffect(() => {
    if (!elevationsConfigurationResponse) {
      return;
    }
    setElevationConfiguration(mapElevationResponse(elevationsConfigurationResponse));
  }, [elevationsConfigurationResponse]);

  useEffect(() => {
    resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideModalDialog = useCallback(() => {
    setModalShown(false);
  }, []);

  const deleteItem = useCallback(() => {
    hideModalDialog();
    if (selectedIdForRemoval) {
      deleteElevation
        .mutateAsync(selectedIdForRemoval)
        .then(() => {
          refetchElevations();
        })
        .catch((error) => {
          logError(error);
        });
    }
  }, [selectedIdForRemoval, deleteElevation, refetchElevations, hideModalDialog]);

  const updateField = useCallback(
    (row: Row<ElevationLevel>, column: Column, value: string): Promise<LoadingState> => {
      if (!isValueChanged(row.original, value, column.id)) {
        return Promise.resolve(LoadingState.Idle);
      }
      const newElevation = makeNewElevation(row.original, value, column.id);
      if (newElevation) {
        return updateElevation
          .mutateAsync(newElevation)
          .then(() => {
            refetchElevations();
            return Promise.resolve(LoadingState.Success);
          })
          .catch((error) => {
            logError(error);
            return Promise.resolve(LoadingState.Error);
          });
      }
      return Promise.resolve(LoadingState.Idle);
    },
    [refetchElevations, updateElevation]
  );

  const removeField = useCallback((row: Row<ElevationLevel>) => {
    if (row.original.id) {
      setSelectedId(row.original.id);
      setModalShown(true);
    }
  }, []);

  const addField = useCallback(() => {
    if (areInputsValid) {
      setAddItemLoading(LoadingState.Loading);
      saveNewElevation
        .mutateAsync({
          min: unitConverter(false, Number(inputState.minValue)),
          max: unitConverter(false, Number(inputState.maxValue)),
        })
        .then(() => {
          refetchElevations();
          setAddItemLoading(LoadingState.Success);
        })
        .catch((error) => {
          logError(error);
          setAddItemLoading(LoadingState.Error);
        })
        .finally(() => {
          resetErrorMessages();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveNewElevation, refetchElevations]);

  const columns: ElevationConfigurationColumns<ElevationLevel>[] = useMemo(() => {
    return [
      {
        Header: " ",
        accessor: "voltageText",
        editable: false,
        canRemove: true,
        type: "string",
        Footer: () => (
          <TableFooterRowWrapper>
            <Rows rows={elevationConfigurations ? elevationConfigurations.length : 0} />
            <Placeholder />
          </TableFooterRowWrapper>
        ),
      },
      {
        Header: "Min",
        accessor: "min",
        editable: true,
        canRemove: false,
        type: "number",
        Footer: () => (
          <TableFooterRowWrapper>
            <FooterCell
              type={"number"}
              style={{ width: "320px" }}
              onError={(hasError: boolean) => {
                dispatchInputValidationState({ type: "MIN", payload: hasError });
              }}
              validationParams={validationParams}
              onChangeEvent={(value: string) => {
                dispatchInputState({ type: "MIN", payload: value });
              }}
            />
          </TableFooterRowWrapper>
        ),
      },
      {
        Header: "Max",
        accessor: "max",
        editable: true,
        canRemove: false,
        type: "number",
        Footer: () => (
          <TableFooterRowWrapper>
            <FooterCell
              type={"number"}
              style={{ width: "320px" }}
              onError={(hasError: boolean) => {
                dispatchInputValidationState({ type: "MAX", payload: hasError });
              }}
              validationParams={validationParams}
              onChangeEvent={(value: string) => {
                dispatchInputState({ type: "MAX", payload: value });
              }}
            />
          </TableFooterRowWrapper>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elevationConfigurations]);

  const disabled = addItemLoading === LoadingState.Loading || hasValidationError || !areInputsValid;

  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!loading} />

      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>

      <HeaderText>Elevation</HeaderText>

      <ModalDialog
        hidden={!isModalShown}
        warning
        title="Delete voltage"
        message={getDeleteMessage("elevation")}
        onClose={hideModalDialog}
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <Button variant="cancel" size="small" onClick={hideModalDialog}>
              Cancel
            </Button>

            <Button variant="warning" size="small" onClick={deleteItem}>
              Delete
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </ModalDialog>
      {!loading && !hasError && (
        <>
          <Table<ElevationLevel>
            columns={columns}
            data={elevationConfigurations}
            multiItem
            validationParams={validationParams}
            updateField={updateField}
            removeField={removeField}
          >
            <ButtonWrapper inlineButton>
              <Button variant="primary" size={"small"} mt={24} onClick={addField} disabled={disabled}>
                {"Add"}
              </Button>
            </ButtonWrapper>
          </Table>
        </>
      )}
    </SubPageWrapper>
  );
};
