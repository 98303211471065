import { FC } from "react";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { Button } from "../Button";
import { Portal } from "../Portal";
import { ModalDialog } from "./ModalDialog";
type ModalProps = {
  modalHidden: boolean;
  hideModal: () => void;
  callAction: () => void;
};
export const OkCancelModalDialog: FC<ModalProps> = ({ modalHidden, hideModal, callAction }) => {
  return (
    <>
      <Portal selector="layer-menu-root">
        <ModalDialog
          hidden={modalHidden}
          message={"Are you sure you want to stop editing?"}
          onClose={hideModal}
          warning
        >
          <StyledFlex justifyContent="flex-end" gap="10px" mt="40px">
            <Button variant="cancel" padding="7.5px 12px" onClick={hideModal}>
              Cancel
            </Button>
            <Button variant="primary" padding="7.5px 27.5px" onClick={callAction}>
              OK
            </Button>
          </StyledFlex>
        </ModalDialog>
      </Portal>
    </>
  );
};
