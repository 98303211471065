import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { Select } from "../Select";

export const Wrapper = styled.div`
  padding: 108px 52px;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font-weight: 700;
  font-size: clamp(22px, 3vw, 30px);
  line-height: 36px;
  color: var(--colors-river-bad);
  margin: 0;
  white-space: nowrap;

  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  background-color: var(--colors-eastern-blue);
  color: var(--colors-white);
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  padding: 9px 26px;
  border-radius: 5px;
  white-space: nowrap;
  text-align: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const FilterButton = styled.button`
  background-color: #f2f2f2;
  border-radius: 30px;
  margin: 5px 0 5px auto;
  border: none;
  padding: 7px 25px;
  font-weight: 500;
  font-size: 13px;
`;

export const FilterDropdown = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(40px);
  background-color: var(--colors-white);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
  box-sizing: border-box;
  width: 374px;
  padding: 3px 0;

  &[data-visible="false"] {
    display: none;
  }

  > * {
    flex: 1;
  }
`;

export const FilterItem = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  color: #3f4952;

  span {
    margin-left: 10px;
  }

  span.count {
    margin-left: auto;
    color: var(--colors-river-bad);
  }

  i {
    width: 10px;
  }

  &.isSelected {
    background-color: rgba(12, 178, 192, 0.1);
  }

  path {
    fill: var(--colors-river-bad);
  }

  &[disabled] {
    color: #898ea1;
  }
`;

export const FilterItemDropdown = styled(StyledFlex)`
  position: absolute;
  background-color: var(--colors-white);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  border-radius: 5px;
  left: -106px;
  top: 0;
  padding: 10px;
  width: 80px;
  display: none;
`;

export const StyledSelect = styled(Select)`
  .react-select__menu {
    box-shadow: none;
    margin: 0;
  }

  .react-select__menu-list {
    height: 155px;
  }

  .react-select__control:hover,
  .react-select__control:active,
  .react-select__control:focus {
    background-color: var(--colors-white);
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: none;
  }

  .react-select__control--menu-is-open {
    box-shadow: none;
  }

  .react-select__control,
  .react-select__value-container,
  .react-select__input,
  .react-select__input input {
    height: auto;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__control {
    margin: 10px;
    background-color: #fbfbfb;
  }

  .react-select__menu {
    padding: 0 10px;
  }

  .react-select__menu-list {
    border-top: 1px solid var(--colors-alto);
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledOption = styled(StyledFlex)`
  span {
    margin-left: 5px;
  }

  i {
    width: 10px;
  }

  path {
    fill: var(--colors-river-bad);
  }
`;
