import { FEET_UNIT_SHORT_NAME, MISSING_VALUE } from "../../../constants";
import { SpanRiskPriority } from "../../../stores";
import { WorkTypeResponse } from "../../../types/responses";
import { roundValue } from "../../../utils/unitHelper";
import { getRiskPriorityColor, getTreeSpeciesImageByName } from "./MapView.utils";

export interface AdditionalOverlayArgumentsInterface {
  R: string;
  G: string;
  B: string;
  r: string;
  g: string;
  b: string;
  legenField: string;
  [x: string]: string;
}

export const getAdditionalOverlayPopupHTML = (data: AdditionalOverlayArgumentsInterface, name: string) => {
  const fieldName = data?.legenField;
  if (!fieldName) {
    return "";
  }
  let rgb;
  // color data can be RGB or rgb
  if (data.R) {
    rgb = `rgb(${data.R}, ${data.G}, ${data.B}`;
  } else {
    rgb = `rgb(${data.r}, ${data.g}, ${data.b}`;
  }
  return `<div class="vantage-additional-container">
    <div class="vantage-legend-container">
    <div style="background: ${rgb})" class="additional_overlay_color">${" "}</div>
    <span class="additional_overlay_name">${name}:</span>
    </div>
    <div class="vantage-legend-container">
    <div class="additional_overlay_color placeholder">${" "}</div>
    <span class="additional_overlay_label">
    ${data[fieldName] || data[fieldName.toLowerCase()]}
    </span>
    </div>
    </div>`;
};

export const getRegionMarkerHtml = (value: number, style: Record<string, string>) => {
  return `
    <svg width="42" height="42" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
    <circle cx="48" cy="44" r="44" fill="${style.bigCircleFill}" />
    </g>
    <g>
    <circle cx="48" cy="44" r="38" fill="${style.smallCircleFill}"/>
    <circle cx="48" cy="44" r="34" fill="${style.bigCircleFill}"/>
    <text x="50%" y="48%" fill="#ffffff" font-size="2em" text-anchor="middle" alignment-baseline="middle">
    ${value}
    </text>
    </g>
    <defs>
    <filter id="filter0_d" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="7.5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>
  `;
};

export const getHydrologyPopupHTML = (color: string, name: string) => {
  return `<div class="vantage-additional-container">
    <div class="hydrology_legend_container">
    <div style="background: ${color}" class="hydrology_popup_color">${" "}</div>
    <span class="hydrology_popup_name">${name}</span>
    </div>`;
};

export const getTreeCanopyPopupHTML = (
  treeHeightMin: Nullable<number>,
  treeHeightMax: Nullable<number>,
  treeSpecies: string
) => {
  const treeHeight =
    treeHeightMin && treeHeightMax
      ? `${roundValue(treeHeightMin, 0)} - ${roundValue(treeHeightMax, 0)}${FEET_UNIT_SHORT_NAME}`
      : MISSING_VALUE;
  return `<div class="vantage-additional-container">
  <div class="tree_canopy_wrapper">
    <div class="tree_canopy_container">
    <div class="tree_canopy_item"><div class="tree-canopy-label-height">Height: </div><div class="tree-canopy-value">${treeHeight} </div> <div class="tree_canopy_img_height">  
    </div> </div>
    <div class="tree_canopy_item"><div class="tree-canopy-label-species">Species: </div><div class="tree-canopy-value">${treeSpecies} </div>
    <div class="tree_canopy_img_species" style="background-image: url(${getTreeSpeciesImageByName(treeSpecies)})">  
    </div></div>
    </div>
    </div>`;
};

export const getWorkTypeContentPopup = (workTypes: Nullable<WorkTypeResponse[]>) => {
  if (!workTypes?.length) {
    return null;
  }
  const items = ` 
  ${workTypes
    .map((workType) => {
      return `<div class="vantage_work_type_item_wrapper"><div class="vantage_work_type_image ${
        workType.code ?? "manually_added"
      }"> </div> <div class="vantage_work_type_name">${workType.name} </div> </div>`;
    })
    .reduce((prev, curr) => {
      return prev + curr;
    })}`;

  return `<div class="vantage-work-type-container">
  <div class="vantage-work_type_wrapper">
  <div class="vantage_work_type_item_container">
${items}
  </div></div></div>`;
};

export const getRiskScorePopup = (spanRiskPriority: Maybe<SpanRiskPriority>, riskScore: Maybe<number>) => {
  if (riskScore === undefined) {
    return null;
  }
  return `<div class="risk-score-popup-content"><div class="risk-score-popup-color" style="background: ${getRiskPriorityColor(
    spanRiskPriority
  )}"> </div> <div>Risk Score: ${riskScore}  </div></div>`;
};
