import React, { ChangeEvent, FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { ValidationParams } from "../Table";
import { ErrorIcon, ErrorMessage, TableInputItem } from "./FooterCell.styled";

interface FooterCellProperties {
  onChangeEvent: (value: string) => void;
  onError: (hasError: boolean) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  type: "number" | "string";
  validationParams: ValidationParams;
  value?: string;
}
export const FooterCell: FC<FooterCellProperties> = ({
  validationParams,
  onChangeEvent,
  value,
  style,
  onError,
  type,
  placeholder,
}) => {
  const [isErrorShown, setShownError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<Nullable<string>>(null);
  const [schemaValidatorObject, setSchemaValidatorObject] = useState(Yup.object().shape({}));
  const [inputStateActive, setInputStateActive] = useState(false);

  useEffect(() => {
    if (!validationParams) {
      return;
    }
    setSchemaValidatorObject(Yup.object().shape(validationParams));
  }, [validationParams]);

  const validateChange = (e: ChangeEvent) => {
    onChangeEvent((e.target as HTMLInputElement).value);
    setShownError(false);
    setValidationMessage(null);
    schemaValidatorObject
      .validate({ value: (e.target as HTMLInputElement).value })
      .then(() => {
        onError(false);
      })
      .catch((e) => {
        setShownError(true);
        setValidationMessage(e.message);
        onError(true);
      });
  };

  return (
    <div>
      <TableInputItem
        onBlur={() => {
          setInputStateActive(false);
        }}
        onFocus={() => {
          setInputStateActive(true);
        }}
        style={style}
        type={type}
        onChange={validateChange}
        error={isErrorShown}
        placeholder={placeholder}
      />
      {isErrorShown && inputStateActive && <ErrorIcon />}
      {validationMessage && inputStateActive && <ErrorMessage style={style}>{validationMessage}</ErrorMessage>}
    </div>
  );
};
