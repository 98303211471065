import { useMemo } from "react";
import { Cell, Column, Row, TableCommonProps } from "react-table";
import * as Yup from "yup";

import { LoadingOverlay } from "../../../../components/LoadingOverlay";
import { CustomColumn, Table } from "../../../../components/Table";
import { Toast } from "../../../../components/Toast";
import { EMPTY_FN, SOMETHING_WENT_WRONG_MESSAGE } from "../../../../constants";
import { LoadingState } from "../../../../stores/AdminLoadingStore";
import { CrewTypeWithMembersAndEquipment } from "../../../../types/responses/Crews";
import { SubPageWrapper } from "../SubPages.styled";
import { CrewTypeTableWrapper } from "./CrewTypeConfiguration.styled";
import { useCrewWithMembersAndEquipment } from "./hooks/useCrewWithMembersAndEquipment";

type CreTypeConfigurationColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

const validationParams = {
  value: Yup.string().required().typeError("Please enter string value!"),
};

export const CrewTypeConfigurations = () => {
  const hasError = false;
  const { loading: isLoading, data } = useCrewWithMembersAndEquipment();

  const columns: CreTypeConfigurationColumns<CrewTypeWithMembersAndEquipment>[] = useMemo(() => {
    return [
      {
        Header: "Crew ID",
        accessor: "id",
        editable: false,
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Crew Type",
        accessor: (data: CrewTypeWithMembersAndEquipment) => {
          return data.workTypeScope === "STRUCTURE" ? "Asset" : "Vegetation";
        },
        editable: false,
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Crew Name",
        accessor: "name",
        Cell: ({ row }: Cell<CrewTypeWithMembersAndEquipment>) => {
          return <span className="crew-type-row">{row.original.name}</span>;
        },
        editable: false,
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Size",
        accessor: (data: CrewTypeWithMembersAndEquipment) => {
          return (data?.crewMemberTypes?.length ?? 0).toString();
        },
        editable: false,
        id: "crewTypeSIze",
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Mandatory Members",
        Cell: ({ row }: Cell<CrewTypeWithMembersAndEquipment>) => {
          return (
            <span className="crew-type-row">{row.original.crewMemberTypes?.map((item) => item.name).join(", ")}</span>
          );
        },
        editable: false,
        id: "crewMembers",
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Mandatory Equipment",
        Cell: ({ row }: Cell<CrewTypeWithMembersAndEquipment>) => {
          return (
            <span className="crew-type-row">{row.original.equipmentTypes?.map((item) => item.name).join(", ")}</span>
          );
        },
        editable: false,
        id: "equipment",
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
      {
        Header: "Work Types",
        Cell: ({ row }: Cell<CrewTypeWithMembersAndEquipment>) => {
          return <span className="crew-type-row">{row.original.workTypes?.map((item) => item.name).join(", ")}</span>;
        },
        editable: false,
        id: "workTypes",
        canRemove: false,
        type: "string",
        Footer: () => <></>,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!isLoading} />

      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>

      {!isLoading && !hasError && (
        <CrewTypeTableWrapper>
          <Table<CrewTypeWithMembersAndEquipment>
            columns={columns}
            data={data}
            multiItem
            validationParams={validationParams}
            removeField={EMPTY_FN}
            updateField={(row: Row<CrewTypeWithMembersAndEquipment>, column: Column<{}>, value: string) => {
              return Promise.resolve(LoadingState.Success);
            }}
          ></Table>
        </CrewTypeTableWrapper>
      )}
    </SubPageWrapper>
  );
};
