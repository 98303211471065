import styled from "styled-components";
import { position, PositionProps } from "styled-system";

import { PURPLE_4, PURPLE_5 } from "../../../constants/colors";
import { HOVERED_OBJECT_CLASS_PREFIX } from "./MapView.constants";
import { ReactComponent as MapOpacityImg } from "../../../assets/images/opacity.svg";
import { LEAFLET_POPUP_PANE, MEASURE_TOOL_LAYER } from "../../../constants/z-index";
import { TOP_HEADER_HEIGHT } from "../../../constants/dimensions";

const TOP = 60;
interface MapViewProps {
  mapStructuresHidden?: boolean;
  spansHidden?: boolean;
  encroachmentsHidden?: boolean;
  feedersHidden?: boolean;
  clearanceHidden?: boolean;
  countiesHidden?: boolean;
  hoveredObjectId?: Nullable<string>;
  satelliteMode?: boolean;
  selectedStructureId?: Nullable<string>;
  parcelsHidden?: boolean;
  treeCanopyHidden?: boolean;
  parcelMarkersHidden?: boolean;
  commentsVisible?: boolean;
  commentLayerVisible?: boolean;
  alertsLayerVisible?: boolean;
  alertsVisible?: boolean;
  areAccessPointsVisible?: boolean;
  isPolygonDrawerVisible?: boolean;
  areTreeMarkersVisible?: boolean;
  isMeasurementToolVisible?: boolean;
}

export const MapDynamicStyles = styled.div<MapViewProps>`
  position: relative;
  & + #map .leaflet-container {
    cursor: ${(props) => (props.commentLayerVisible ? "default" : "inherited")};

    &.crosshair-cursor-enabled {
      cursor: crosshair;
    }
  }
  & + #map .leaflet-control-zoom.leaflet-bar.leaflet-control {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(10px);
    border-radius: 7px;
    & a {
      background-color: ${PURPLE_5};
      color: white;
      border-bottom: 1px solid ${PURPLE_4};
    }
  }

  // perf optimization to not remove dom elements but to visually hide
  & + #map .leaflet-structures-pane-pane {
    visibility: ${(props) => (props.mapStructuresHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-selected-structures-pane-pane {
    visibility: ${(props) => (props.mapStructuresHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-spans-pane-pane {
    visibility: ${(props) => (props.spansHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-encroachments-pane-pane {
    visibility: ${(props) => (props.encroachmentsHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-feeders-pane-pane {
    visibility: ${(props) => (props.feedersHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-clearance-pane-pane {
    visibility: ${(props) => (props.clearanceHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-counties-pane-pane {
    visibility: ${(props) => (props.countiesHidden ? "hidden" : "visible")};
  }
  & + #map .leaflet-counties-pane-pane .county-name {
    color: ${(props) => (props.satelliteMode ? "rgba(255, 255, 255, 0.9)" : "#b5b5b5")};
  }

  & + #map .leaflet-counties-pane-pane path {
    stroke: ${(props) => (props.satelliteMode ? "rgba(255, 255, 255, 0.8)" : "rgba(161, 198, 202, 0.3)")};
  }

  & + #map ${(props) => `.${HOVERED_OBJECT_CLASS_PREFIX}${props.hoveredObjectId}`} {
    stroke: var(--colors-white);
  }

  & + #map ${(props) => `.structure__tower.object-id-${props.selectedStructureId}`} svg g circle,
  & + #map ${(props) => `.structure__switch.object-id-${props.selectedStructureId}`} svg g rect {
    stroke: var(--colors-white);
    stroke-width: 3px;
    r: 11.5;
    rx: 7;
  }

  & + #map .leaflet-additional-layer-pane-pane {
    visibility: visible;
  }

  & + #map .leaflet-popup-pane {
    z-index: ${LEAFLET_POPUP_PANE};
  }

  & + #map .leaflet-parcels-markers-pane-pane {
    visibility: ${(props) => (props.parcelMarkersHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-parcels-pane-pane {
    visibility: ${(props) => (props.parcelsHidden ? "hidden" : "visible")};
  }

  & + #map .leaflet-tree-layer-pane-pane {
    visibility: ${(props) => (props.treeCanopyHidden ? "hidden" : "visible")};
  }

  & + #map .map-span:hover,
  & + #map .map-feeder:hover {
    stroke-width: 6px;
    stroke: #b4f4ff;
  }
  & + #map .map-span {
  }

  & + #map .leaflet-overlay-pane {
    z-index: ${MEASURE_TOOL_LAYER};
  }

  & + #map .leaflet-marker-pane {
    z-index: ${(props) => (props.isPolygonDrawerVisible ? "603" : "599")};
  }
  & + #map .leaflet-marker-pane {
    ${(props) => (props.areTreeMarkersVisible ? `z-index: ${MEASURE_TOOL_LAYER};` : "")};
  }

  & + #map .leaflet-marker-pane {
    ${(props) => (props.areAccessPointsVisible ? `z-index:699;` : "")};
  }
  & + #map .leaflet-comments-pane-pane {
    visibility: ${(props) => (props.commentsVisible ? "visible" : "hidden")};
  }

  & + #map .leaflet-marker-pane {
    ${(props) => (props.isMeasurementToolVisible ? `z-index:${MEASURE_TOOL_LAYER};` : "")};
  }

  & + #map .leaflet-alerts-pane-pane {
    visibility: ${(props) => (props.alertsVisible ? "visible" : "hidden")};
  }
`;

export const MapViewStyled = styled.div`
  position: relative;
`;

export const MenuSectionWrapper = styled.div`
  top: ${TOP}px;
  position: absolute;
  overflow-y: scroll;
  max-height: calc(100vh - ${TOP_HEADER_HEIGHT + TOP}px);
  width: 100%;
  overflow-x: hidden;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
`;

export const TopButtonsContainer = styled.div<PositionProps>`
  ${position}
  z-index: 400;
  display: flex;
  gap: 10px;
`;
export const MapWrapper = styled.div`
  position: relative;
`;

export const MapOpacityImage = styled(MapOpacityImg)<{ checked: boolean }>`
  circle {
    fill: ${(props) => (props.checked ? props.theme.colors.white : props.theme.colors.easternBlue)};
  }
  path {
    fill: ${(props) => (props.checked ? props.theme.colors.easternBlue : props.theme.colors.white)};
  }
`;

export const LegendWrapper = styled.div<{ leftMenuHidden?: boolean }>`
  left: ${(props) => (props.leftMenuHidden ? "12px" : "343px")};
  display: flex;
  height: 100vh;
  position: absolute;
  bottom: 57px;
  flex-direction: column-reverse;
  gap: 9px;
`;
