import { FC, useCallback, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

import { ReplyTextAreaWrapper, ReplyButton, ReplyButtonWrapper } from "./ReplyTextArea.styled";

const SELECTION_RANGE = 1000;

type ReplyTextAreaProps = {
  placeholder: string;
  onSubmit: (text: string) => void;
  onEcape?: () => void;
  setReplyAreaState?: (state: boolean) => void;
  maxRows?: number;
  initialValue?: string;
  useSubmitOnEnter?: boolean;
};
export const ReplyTextArea: FC<ReplyTextAreaProps> = ({
  placeholder,
  onSubmit,
  onEcape,
  setReplyAreaState,
  maxRows,
  useSubmitOnEnter = true,
  initialValue = "",
  ...props
}) => {
  const [replyEnabled, setReplyEnabled] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaContent, setTextAreaContent] = useState(initialValue ?? "");
  const [textareaDisabled, setTextareaDisabled] = useState(false);

  const textAreaEnabled = textAreaContent?.length > 0;

  useEffect(() => {
    if (!textareaRef?.current) {
      return;
    }
    textareaRef.current?.focus();
    textareaRef.current?.setSelectionRange(SELECTION_RANGE, SELECTION_RANGE);
  }, [textareaRef]);

  useEffect(() => {
    setTextAreaContent(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!setReplyAreaState) {
      return;
    }
    setReplyAreaState(textAreaEnabled && replyEnabled);
  }, [replyEnabled, textAreaEnabled, setReplyAreaState]);

  const onChange = useCallback(
    (e) => {
      const text = e.target.value;
      setTextAreaContent(text);
    },
    [setTextAreaContent]
  );

  const onKeypress = useCallback(
    (e) => {
      if (!e?.target || e.shiftKey) {
        return;
      }

      if (useSubmitOnEnter && e.key === "Enter") {
        setTextareaDisabled(true);
        setTimeout(() => setTextareaDisabled(false), 200);
        onSubmit(e.target.value);
        return;
      }

      if (e.key === "Escape") {
        onEcape && onEcape();
      }
    },
    [useSubmitOnEnter, onEcape, onSubmit]
  );

  return (
    <ReplyTextAreaWrapper enabled={textAreaEnabled}>
      <form>
        <TextareaAutosize
          ref={textareaRef}
          maxRows={maxRows}
          placeholder={placeholder}
          onFocus={() => setReplyEnabled(true)}
          onBlur={() => setReplyEnabled(false)}
          onChange={onChange}
          value={textAreaContent}
          disabled={textareaDisabled}
          onKeyPress={onKeypress}
          {...props}
        >
          {textAreaContent}
        </TextareaAutosize>
      </form>
      <ReplyButtonWrapper enabled={textAreaEnabled}>
        <ReplyButton
          enabled={textAreaEnabled}
          disabled={textareaDisabled}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            textAreaEnabled && onSubmit(textAreaContent);
            setTextareaDisabled(true);
            setTimeout(() => setTextareaDisabled(false), 200);
          }}
        />
      </ReplyButtonWrapper>
    </ReplyTextAreaWrapper>
  );
};
