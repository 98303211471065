import React, { FC } from "react";
import { Tooltip as ReachTooltip } from "@reach/tooltip";
import "@reach/tooltip/styles.css";

import { LeftItem, NameTax, AdditionalNameTax, FeederLevelWrapper, CircuitWrapper, EyeWrapper } from "./TaxName.styled";
import { StatusViolationTriangle, FeederCircleStatus } from "../StatusCircle";
import { getRiskPriorityColor } from "../../../pages/MapScreen/MapView/MapView.utils";
import { SpanRiskPriority } from "../../../stores";
import "./TaxName.styles.css";
import ViolationImg from "./../../../assets/images/violation.svg";
import { RISK_PRIORITY } from "../../../types/responses";

import { ReactComponent as VisibleEye } from "./../../../assets/images/visibility_none.svg";
import { ReactComponent as InVisibleEye } from "./../../../assets/images/eye.svg";

interface TaxNameProps {
  name: string;
  riskPriority: SpanRiskPriority;
  selected: boolean;
  displayTooltip?: boolean;
  additionalLabel?: Maybe<Nullable<string>>;
  isFeederLevel: boolean;
  isSubItemSelected: boolean;
  hideFeeder?: (feederId: string) => void;
  feederId?: Nullable<string>;
  isItemHidden: boolean;
}

export const TaxName: FC<TaxNameProps> = ({
  name,
  riskPriority,
  selected,
  displayTooltip = false,
  additionalLabel,
  isFeederLevel,
  isSubItemSelected,
  hideFeeder,
  feederId,
  isItemHidden,
}) => (
  <LeftItem itemSelected={selected}>
    {displayTooltip ? (
      <ReachTooltip label={name}>
        <NameTax selected={selected && !isSubItemSelected}>
          {name} {additionalLabel && <AdditionalNameTax selected={selected}>{additionalLabel}</AdditionalNameTax>}
        </NameTax>
      </ReachTooltip>
    ) : (
      <NameTax selected={selected && !isSubItemSelected}>
        {name} {additionalLabel && <AdditionalNameTax selected={selected}>{additionalLabel}</AdditionalNameTax>}
      </NameTax>
    )}

    <FeederLevelWrapper violation={riskPriority === RISK_PRIORITY.VIOLATION}>
      <>
        {isFeederLevel && (
          <EyeWrapper
            isSubItemSelected={isSubItemSelected}
            selected={selected}
            violation={riskPriority === RISK_PRIORITY.VIOLATION}
            isItemHidden={isItemHidden}
            onClick={(e) => {
              hideFeeder && hideFeeder(feederId ?? "");
              e.stopPropagation();
            }}
          >
            {!isItemHidden && <InVisibleEye className="invisible-eye" />}
            {isItemHidden && <VisibleEye className="visible-eye" />}
          </EyeWrapper>
        )}
        {riskPriority !== RISK_PRIORITY.VIOLATION && (
          <CircuitWrapper>
            <FeederCircleStatus backgroundColor={getRiskPriorityColor(riskPriority, true)} />
          </CircuitWrapper>
        )}
        {riskPriority === RISK_PRIORITY.VIOLATION && <StatusViolationTriangle src={ViolationImg} />}
      </>
    </FeederLevelWrapper>
  </LeftItem>
);

export default TaxName;
