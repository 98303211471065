import { useQuery } from "react-query";
import { useEffect } from "react";

import ApiClient from "../utils/apiClient";
import { getSpanInfoUrl } from "../api";
import { logError } from "../services";
import { useSpansStore } from "../stores";
import { SpansResponseProperties } from "../types/responses";

export const useSingleSpan = () => {
  const spanId = useSpansStore((store) => store.selectedId);
  const addSpan = useSpansStore((store) => store.addSpan);

  const { refetch } = useQuery(
    "single-span",
    () => ApiClient.get<SpansResponseProperties>(getSpanInfoUrl(spanId ?? "")),
    {
      onError: (e) => logError(e),
      onSuccess: (data) => addSpan(data.data as SpansResponseProperties),
      enabled: spanId !== null,
    }
  );

  useEffect(() => {
    if (!spanId) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spanId]);
};

export default useSingleSpan;
