import { getWorkTypeColor } from "../../pages/MapScreen/MapScreen.utils";
import { HOVERED_OBJECT_CLASS_PREFIX } from "../../pages/MapScreen/MapView/MapView.constants";
import { WorksheetResponse, WorkTypeResponse } from "../../types/responses";
import {
  SPAN_STYLE,
  SPAN_STYLE_GROUP_SELECTED,
  SPAN_STYLE_HOVER_SELECTED,
  SPAN_STYLE_WORK_TYPE,
} from "./MapFeederSegments.constants";

export const getSpanStyle = ({
  item,
  selectedGroupId,
  selectedId,
  enabledWorkType,
  color,
  lineWidth,
  selectedSpans,
}: {
  item: GeoJSON.Feature<GeoJSON.Geometry, { id: string; feederId: string; spanId: string }>;
  selectedGroupId: Nullable<string>;
  selectedId: Nullable<string>;
  enabledWorkType: boolean;
  color: Nullable<string>;
  lineWidth: number;
  selectedSpans: Nullable<string[]>;
}) => {
  const className = `${HOVERED_OBJECT_CLASS_PREFIX}${item.properties.id} map-span`;
  if (
    selectedId === item.properties.id ||
    (selectedSpans !== null && selectedSpans.findIndex((span) => span === item.properties.spanId) >= 0)
  ) {
    return { className: className, ...SPAN_STYLE_HOVER_SELECTED };
  }
  if (enabledWorkType) {
    const style = Object.assign(SPAN_STYLE_WORK_TYPE, { color, fillColor: color, weight: lineWidth });
    return { className: className, ...style };
  }

  if (item.properties.feederId === selectedGroupId) {
    return { className: className, ...SPAN_STYLE_GROUP_SELECTED };
  }

  return { className: className, ...SPAN_STYLE };
};

export const getSpanKey = (
  item: GeoJSON.Feature<GeoJSON.Geometry, { id: string; feederId: string }>,
  selectedGroupId: Nullable<string>
) => {
  if (item.properties.feederId === selectedGroupId) {
    return `${item.properties.id}_GROUP_SELECTED`;
  }

  return `${item.properties.id}`;
};

export const isWorkTypeEnabled = (
  workTypeEnabled: boolean,
  spansWithWorkType: Nullable<Record<string, WorkTypeResponse>>,
  spanId: string
) => {
  return workTypeEnabled && spansWithWorkType && spansWithWorkType[spanId];
};

export const getColorByWorkType = (
  workTypeEnabled: boolean,
  spansWithWorkType: Nullable<Record<string, WorkTypeResponse>>,
  spanId: string
) => {
  if (!workTypeEnabled) {
    return null;
  }
  return getWorkTypeColor(spansWithWorkType?.[spanId]?.code ?? "");
};

export const filterByFeeder = (hiddenFeeders: Nullable<string[]>, feederId: string) => {
  if (!hiddenFeeders) {
    return true;
  }
  return !hiddenFeeders.find((item) => item === feederId);
};
export const getWorkTypesFromResponse = (
  workTypesById: Nullable<Record<string, WorkTypeResponse>>,
  response: WorksheetResponse
): Nullable<WorkTypeResponse[]> => {
  if (!workTypesById || !Object.keys(workTypesById).length || !response?.items?.length) {
    return null;
  }
  return response.items
    .filter((item) => !!workTypesById[item.workTypeId])
    .map((item) => {
      return workTypesById[item.workTypeId];
    });
};
