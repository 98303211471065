import { UNIT_NAMES } from "../../../components/WorksheetArea";
import { roundValue, getUnitPluralName } from "../../../utils/unitHelper";
import { toUsdCurrency } from "../../../utils/stringUtils";
import { ISubtask, ICrewType, IWorkType } from "../types";

export const setQuantity = (prevTasks: ISubtask[], taskId: string, worktypeId: string, quantity: number) => {
  return prevTasks.map((task) => {
    if (task.id === taskId) {
      return {
        ...task,
        worktypes: task.worktypes.map((wt) => {
          if (wt.workTypeId === worktypeId) {
            return { ...wt, quantity };
          }
          return wt;
        }),
      };
    }
    return task;
  });
};

export const addRemoveWorktype = (prevTasks: ISubtask[], taskId: string, worktypeId: string, removeTask?: boolean) => {
  return prevTasks.map((task) => {
    if (task.id === taskId) {
      return {
        ...task,
        worktypes: task.worktypes.map((wt) => {
          if (wt.workTypeId === worktypeId) {
            return {
              ...wt,
              added: removeTask ? false : wt.added ? false : true,
            };
          }

          return wt;
        }),
      };
    }
    return task;
  });
};

export const setSelectedCrewType = (prevTasks: ISubtask[], taskId: string, crewType: ICrewType) => {
  return prevTasks.map((task) => {
    if (task.id === taskId) {
      return {
        ...task,
        selectedCrewType: crewType,
      };
    }
    return task;
  });
};

// Subtask is valid when it has a Crew Type and at least one Worktype added
export const isSubtaskValid = (subtask: ISubtask) =>
  subtask.selectedCrewType !== null && subtask.worktypes.filter((wt) => wt.added).length > 0;

export const getTotal = (activeWorktype: IWorkType, rangeInputValue: number) => {
  if (!activeWorktype) return;

  const totalValue = roundValue(rangeInputValue * activeWorktype.totalQuantity, 2);

  return `${totalValue} ${getUnitPluralName(UNIT_NAMES[activeWorktype.unit], totalValue)}`;
};

export const getTotalWorkableArea = (activeWorktype: IWorkType) => {
  if (!activeWorktype) return;

  const totalWorkableAreaValue = roundValue(activeWorktype.totalQuantity, 2);

  return `${totalWorkableAreaValue} ${getUnitPluralName(UNIT_NAMES[activeWorktype.unit], totalWorkableAreaValue)}`;
};

export const getAdditionalDescription = (activeWt: IWorkType) =>
  `${roundValue(activeWt.quantity, 2)}
 ${getUnitPluralName(UNIT_NAMES[activeWt.unit], roundValue(activeWt.quantity, 2))}`;

export const getWorktypeOptions = (worktypes: IWorkType[]) => {
  const options = worktypes.map((item) => ({ ...item, value: item.category, label: item.category }));
  return [
    { label: "Suggested", options: options.filter((opt) => opt.group === "Suggested") },
    { label: "All", options: options.filter((opt) => opt.group === "All") },
  ];
};

export const getTotalCost = (worktypes: IWorkType[]) => {
  const sum = worktypes.reduce((acc, value) => {
    if (value.added) {
      return acc + value.cost;
    }
    return acc;
  }, 0);

  return toUsdCurrency(sum);
};
