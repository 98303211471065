import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left.svg";
import { Button } from "../../components/Button";
import { WorkOrderSubtask } from "../../feature/work-order/components/WorkOrderSubtask";
import {
  Wrapper,
  BackButton,
  Title,
  MainContent,
  Section,
  SectionItem,
  Subtasks,
  AddNewBtn,
  SectionLabel,
  SelectLabel,
  FormWrapper,
  subtaskBarStyles,
  formButtonStyles,
  DependencySelect,
} from "./WorkOrder.styled";
import { SectionItem as SectionElement } from "../../components/RightDrawer/DrawerSectionItems/SectionItem";
import { TreeSpecies } from "../../components/RightDrawer/DrawerSectionItems/TreeSpecies";
import { LABELS } from "../../constants";
import { Select } from "../../components/Select";
import { DatepickerField } from "../../components/Datepicker";
import {
  setQuantity,
  addRemoveWorktype,
  setSelectedCrewType,
  isSubtaskValid,
} from "../../feature/work-order/utils/subtaskHelper";
import { ICrewType, ISubtask, IWorkOrderState } from "../../feature/work-order/types";
import { WorkOrderTile } from "../../feature/work-order/components/WorkOrderTile";
import { useWorkOrdersForObject } from "../../feature/work-order/hooks/useWorkOrdersForObject";
import { useCreateWorkOrder } from "../../feature/work-order/hooks/useCreateWorkOrder";
import { components, OptionProps } from "react-select";
import { toLocalDate } from "../../utils/dateUtils";
import { Spinner } from "../../components/Loader/Spinner";
import { toUsdCurrency } from "../../utils/stringUtils";

const DATE_FORMAT = "MMMM d, yyyy";
const DATE_FORMAT_RESPONSE = "yyyy-MM-dd";

const woPriorities = ["Low", "Medium", "High", "Critical"] as const;
const PRIORITY_OPTIONS = woPriorities.map((item) => ({ value: item.toLocaleUpperCase(), label: item }));
const woStatus = ["Planned", "Emergent"] as const;
const WORKTYPE_OPTIONS = woStatus.map((item) => ({ value: item.toLocaleUpperCase(), label: item }));

const workOrderSchema = Yup.object().shape({
  priority: Yup.string().required("Priority is required."),
  status: Yup.string().required("Work type is required."),
  dueDate: Yup.date().typeError("Invalid date.").required("Due date is required."),
  dependency: Yup.string().nullable(),
});
interface IForm {
  priority: string;
  status: string;
  dueDate: string;
  dependency: string | null;
}

const emptyTask = {
  selectedCrewType: null,
  worktypes: [],
};

const CustomOption = (props: OptionProps<any, any, any>) => (
  <components.Option {...props}>
    <WorkOrderTile name={props.data?.label ?? ""} date={toLocalDate(props.data.date, DATE_FORMAT)} />
  </components.Option>
);

export const WorkOrder = () => {
  const history = useHistory();
  const { state } = useLocation<IWorkOrderState>();
  const [subtasks, setSubtasks] = useState<ISubtask[]>(state?.subtask ? [state.subtask] : []);
  const formInitialValues = useMemo(() => {
    return {
      priority: "",
      status: "",
      dueDate: "",
      dependency: null,
    };
  }, []);
  const isFirstSubtaskValid = useMemo(() => subtasks?.length > 0 && isSubtaskValid(subtasks[0]), [subtasks]);
  const [selectedDependency, setSelectedDependency] = useState<any | null>(null);
  const { data = [] } = useWorkOrdersForObject(state.spanId);
  const { mutateAsync: createWorkOrder, isLoading, isSuccess } = useCreateWorkOrder();

  useEffect(() => {
    // Redirect to landing page if WO is successfuly created
    isSuccess && history.goBack();
  }, [isSuccess, history.goBack]);

  const workOrderOptions = useMemo(
    () => data?.map((item) => ({ id: item.id, label: `WO ${item.id}`, value: `WO ${item.id}`, date: item.dueDate })),
    [data]
  );

  const totalCost = useMemo(() => {
    return subtasks.reduce((acc, task) => {
      const taskTotal = task.worktypes.reduce((wAcc, workType) => {
        if (workType.added) {
          return wAcc + workType.cost;
        }
        return wAcc;
      }, 0);

      return acc + taskTotal;
    }, 0);
  }, [subtasks]);

  const deleteSubtask = (taskId: string) => setSubtasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

  const addSubtask = () =>
    setSubtasks((prevTasks) => [{ id: uuidv4(), ...emptyTask, worktypes: state.worktypes }, ...prevTasks]);

  const setCrewType = (taskId: string, crewType: ICrewType) =>
    setSubtasks((prevTasks) => setSelectedCrewType(prevTasks, taskId, crewType));

  const toggleWorkType = (taskId: string, worktypeId: string, removeTask?: boolean) => {
    setSubtasks((prevTasks) => addRemoveWorktype(prevTasks, taskId, worktypeId, removeTask));
  };

  const setWorktypeQuantity = (taskId: string, worktypeId: string, quantity: number) => {
    setSubtasks((prevTasks) => setQuantity(prevTasks, taskId, worktypeId, quantity));
  };

  const onSubmit = (formData: IForm) => {
    const data = {
      objectId: state.spanId,
      workTypeScope: state.workTypeScope,
      dueDate: toLocalDate(formData.dueDate, DATE_FORMAT_RESPONSE),
      priority: formData.priority,
      status: formData.status,
      dependencyOrderId: selectedDependency?.id,
      tasks: subtasks.map((task) => ({
        crewTypeId: task.selectedCrewType?.id ?? "",
        units: task.worktypes.map((sWt) => ({ workTypeId: sWt.workTypeId, quantity: sWt.quantity })),
      })),
    };
    createWorkOrder(data);
  };

  return (
    <Wrapper>
      <Formik
        initialValues={formInitialValues}
        validationSchema={workOrderSchema}
        validateOnBlur={true}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ values: formData, setFieldValue, isValid, dirty }) => {
          const isSubmitDisabled = !(isValid && dirty && isFirstSubtaskValid);
          return (
            <>
              <StyledFlex mt="65px" padding="15px 50px" justifyContent="space-between" alignItems="flex-end">
                <StyledFlex flexDirection="column" alignItems="flex-start">
                  <BackButton Icon={ArrowLeft} text="Back" onClick={() => history.goBack()} />
                  <Title>Create Work Order</Title>
                </StyledFlex>
                <StyledFlex gap="20px">
                  <Button variant="secondary" {...formButtonStyles} onClick={() => history.goBack()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    {...formButtonStyles}
                    disabled={isSubmitDisabled || isLoading}
                    onClick={() => onSubmit(formData)}
                  >
                    {isLoading ? (
                      <StyledFlex width="100%" alignItems="center" justifyContent="center">
                        <Spinner color={`var(--colors-white)`} width="10px" height="10px" m={0} />
                      </StyledFlex>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </StyledFlex>
              </StyledFlex>
              <MainContent background="#FBFBFB" padding="20px 40px 0">
                <StyledFlex flexDirection="column" gap="10px">
                  <Section>
                    <SectionItem>
                      <span>Plan</span>
                      <span className="withPrimaryColor">2023 - 2025</span>
                    </SectionItem>
                    <SectionItem>
                      <span>Program</span>
                      <span className="withPrimaryColor">Cycle Trim</span>
                    </SectionItem>
                    <SectionItem>
                      <span>MA</span>
                      <span>MA1</span>
                    </SectionItem>
                    <SectionItem>
                      <span>Span</span>
                      <span className="withPrimaryColor">133 - 365</span>
                    </SectionItem>
                    <SectionItem>
                      <span>Total Estimated Cost</span>
                      <span>{toUsdCurrency(totalCost)}</span>
                    </SectionItem>
                  </Section>
                  <Section className="isStreched">
                    <Form>
                      <FormWrapper>
                        <fieldset>
                          <SelectLabel>Priority</SelectLabel>
                          <Select
                            isDisabled={false}
                            name="priority"
                            onChange={(option) => setFieldValue("priority", option.value)}
                            options={PRIORITY_OPTIONS}
                            placeholder="Choose Priority"
                          />
                        </fieldset>
                        <fieldset>
                          <SelectLabel>Work Type</SelectLabel>
                          <Select
                            isDisabled={false}
                            name="status"
                            onChange={(option) => setFieldValue("status", option.value)}
                            options={WORKTYPE_OPTIONS}
                            placeholder="Choose Work Type"
                          />
                        </fieldset>
                        <DatepickerField
                          label="Due Date"
                          name="dueDate"
                          dateFormat="MMMM d, yyyy"
                          selected={new Date(formData.dueDate)}
                          isClearable
                        />
                        <DependencySelect
                          name="dependency"
                          placeholder="Dependency"
                          options={workOrderOptions}
                          value={workOrderOptions.find((woOpt) => woOpt.id === selectedDependency?.id)}
                          onChange={(option: any) => setSelectedDependency(option)}
                          components={{ Option: CustomOption }}
                          isOptionDisabled={(option: any) => selectedDependency?.id === option.id}
                          closeMenuOnSelect={false}
                          isSearchable={true}
                          isClearable={true}
                        />
                      </FormWrapper>
                    </Form>
                    <SectionLabel mb="20px">{state.workTypeScope} Info</SectionLabel>
                    <SectionElement name={LABELS.riskPriority}>{state?.riskPriority}</SectionElement>
                    <SectionElement name={LABELS.riskScore}>{state?.riskScore}</SectionElement>
                    <SectionElement name={LABELS.treeSpecies}>{!state?.treeSpecies ? "N/A" : ""}</SectionElement>
                    <TreeSpecies species={state?.treeSpecies as string[]} />
                    <SectionElement name="Span Length">{state?.spanLength}</SectionElement>
                    <SectionElement name="ROW Area">{state?.rowArea}</SectionElement>
                  </Section>
                </StyledFlex>
                <Subtasks>
                  <StyledFlex {...subtaskBarStyles}>
                    <SectionLabel>Subtasks</SectionLabel>
                    <AddNewBtn
                      variant="secondary"
                      onClick={addSubtask}
                      disabled={subtasks.length > 0 && !isFirstSubtaskValid}
                    >
                      +Add new
                    </AddNewBtn>
                  </StyledFlex>
                  <StyledFlex flexDirection="column" gap="20px" overflowY="scroll" height="710px" padding="5px 0">
                    {subtasks.map((subtask) => (
                      <WorkOrderSubtask
                        key={subtask.id}
                        subtask={subtask}
                        deleteSubtask={deleteSubtask}
                        setCrewType={setCrewType}
                        setWorktypeQuantity={setWorktypeQuantity}
                        toggleWorkType={toggleWorkType}
                      />
                    ))}
                  </StyledFlex>
                </Subtasks>
              </MainContent>
            </>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
