import { FC } from "react";
import { Portal } from "../Portal";
import { PopupContent, PopupWrapper } from "./StarCommandMaPopup.styled";

interface StarCommandMaPopupProps {
  hidden: boolean;
  rightMenuEnabled?: boolean;
  layerMenuEnabled?: boolean;
  leftMenuEnabled?: boolean;
  area?: string;
  length?: string;
}

export const StarCommandMaPopup: FC<StarCommandMaPopupProps> = ({
  hidden,
  rightMenuEnabled,
  leftMenuEnabled,
  layerMenuEnabled,
  area,
  length,
}) => {
  if (hidden) {
    return <></>;
  }
  return (
    <Portal selector="layer-menu-root">
      <PopupContent rhsEnabled={rightMenuEnabled} lhsEnabled={leftMenuEnabled} layerMenuEnabled={layerMenuEnabled}>
        <PopupWrapper>
          {length && <span>{`Corridor length: ${length}${area ? ", " : " "}`}</span>}
          {area && <span> &nbsp;{`Area: ${area}`}</span>}
        </PopupWrapper>
      </PopupContent>
    </Portal>
  );
};
