import { useMutation } from "react-query";
import { useComments } from ".";

import { putStatusCommentUrl } from "../api";
import { logError } from "../services";
import ApiClient from "../utils/apiClient";

export const COMMENT_STATUS_MUTATION_KEY = "change-comment-status";
export const useChangeCommentStatus = () => {
  const comments = useComments();

  return useMutation(
    COMMENT_STATUS_MUTATION_KEY,
    ({ commentId, status }: { commentId: string; status: "unresolve" | "resolve" | "read" }) =>
      ApiClient.put<string>(putStatusCommentUrl(commentId, status)),
    {
      onSettled: () => comments.mutate(),
      onError: (e) => logError(e),
    }
  );
};
