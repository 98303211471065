import create from "zustand";

import { EMPTY_FN } from "../constants";
import { SearchCommentsResponseDto } from "../types/responses";

export type FilterAlerts = {
  text: Nullable<string>;
  sortBy: "UNREAD" | "DATE";
  showResolved: boolean;
  showRead: boolean;
};

const INITIAL_FILTER: FilterAlerts = { text: null, sortBy: "DATE", showResolved: false, showRead: true };

export type AlertsStore = {
  actions: {
    toggleLayer: () => void;
    setData: (data: SearchCommentsResponseDto) => void;
    showResolved: (state: boolean) => void;
    showRead: (state: boolean) => void;
    setSortBy: (sortBy: "UNREAD" | "DATE") => void;
    setTextFilter: (text: string) => void;
    setMarkerId: (id: string) => void;
    setCategories: (categories: { id: string; name: string }[]) => void;
    getCategoryId: (id: string) => void;
  };
  isLayerVisible: boolean;
  data: Nullable<SearchCommentsResponseDto>;
  filter: FilterAlerts;
  selectedMarkerId: Nullable<string>;
  categories: { id: string; name: string }[];
};

const INITIAL_STATE = {
  isLayerVisible: false,
  categories: [],
  filter: INITIAL_FILTER,
  data: null,
  selectedMarkerId: null,
  actions: {
    toggleLayer: EMPTY_FN,
    setData: EMPTY_FN,
    showResolved: EMPTY_FN,
    showRead: EMPTY_FN,
    setSortBy: EMPTY_FN,
    setTextFilter: EMPTY_FN,
    setMarkerId: EMPTY_FN,
    setCategories: EMPTY_FN,
    getCategoryId: EMPTY_FN,
  },
};

export const useAlertsStore = create<AlertsStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    toggleLayer: () => set((state) => ({ isLayerVisible: !state.isLayerVisible })),
    setData: (data) => set(() => ({ data })),
    showResolved: (showResolved) => set((state) => ({ filter: { ...state.filter, showResolved } })),
    showRead: (showRead) => set((state) => ({ filter: { ...state.filter, showRead } })),
    setSortBy: (sortBy) => set((state) => ({ filter: { ...state.filter, sortBy } })),
    setTextFilter: (text) => set((state) => ({ filter: { ...state.filter, text } })),
    setMarkerId: (selectedMarkerId) => set(() => ({ selectedMarkerId })),
    setCategories: (categories: any) => set(() => ({ categories })),
    getCategoryId: (id: string) => get()?.categories?.find((item) => item.id === id)?.name,
  },
}));

export default useAlertsStore;
