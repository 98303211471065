import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { updateCrewConfigUrl } from "../api";
import { ValueType } from "../types/responses/Crews";

export const UPDATE_CREW_CONFIG_MUTATION_KEY = "update-crew-config";

export const useUpdateCrewConfiguration = () => {
  return useMutation(UPDATE_CREW_CONFIG_MUTATION_KEY, (item: { crewId: string; value: ValueType }) =>
    ApiClient.put(updateCrewConfigUrl(item.crewId), item.value)
  );
};
