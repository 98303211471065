import { FC, useMemo, useState } from "react";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { SpanWorksheetDto } from "../../types/responses";
import { Select, Option } from "../Select";
import { Button } from "../Button";
import { ModalDialog } from "../ModalDialog";
import { Input } from "../Input/Input.styled";

import {
  SpanHeader,
  SpanHeaderName,
  SpanHeaderVoltage,
  SpanHeaderVoltageWrapper,
  SpansContentWrapper,
  SpanWrapperItem,
  SpanHeaderItemName,
} from "../SpansContentRHS/SpansContentRHS.styled";
import { CHeader, CTitle, Divider } from "./MaintenanceAreaContentRHS.styled";
import VoltageImage from "./../../assets/images/voltage.svg";
import { StructureHeaderVoltageImg } from "../StructureRHSContent/StructureRHSContent.styled";
import { VOLTAGE_UNIT_SHORT_NAME } from "../../constants";
import { useMaintenanceAreaAll } from "../../hooks/useMaintenanceAreaAll";
import { useCreateMA } from "../../hooks/useCreateMA";

const ADD_NEW_AREA = "+ Create New Area";

interface IProps {
  selectedSpans: Nullable<SpanWorksheetDto[]>;
  isEditMode: boolean;
  setSelectedMaID: React.Dispatch<React.SetStateAction<string>>;
}

export const MaintenanceAreaContentRHS: FC<IProps> = ({ selectedSpans, isEditMode, setSelectedMaID }) => {
  const { data: maintenanceAreas, isLoading } = useMaintenanceAreaAll();
  const [selectedMA, setSelectedMA] = useState<Option | null>(null);
  const [newMA, setNewMA] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: createMA } = useCreateMA();

  const maintenanceAreasOptions = useMemo(() => {
    if (isLoading) return [];

    return [
      { label: ADD_NEW_AREA, value: ADD_NEW_AREA, id: "", name: "" },
      // @ts-ignore
      ...maintenanceAreas?.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.name,
      })),
    ];
  }, [maintenanceAreas, isLoading]);

  if (isLoading) return <></>;

  const submitNewMA = async () => {
    const data = {
      name: newMA,
      spanIds: selectedSpans?.map((item) => item.spanId) ?? [],
    };

    await createMA(data);
    setNewMA("");
    setSelectedMA(null);
    setShowModal(false);
  };

  return (
    <>
      <SpansContentWrapper>
        <StyledFlex flexDirection="column" borderTop="1px solid #EFEFEF" paddingTop="20px">
          {isEditMode && (
            <>
              <CTitle>Assign to Maintenance Area:</CTitle>
              <CHeader padding="10px 15px" gap="8px">
                <Select
                  isDisabled={false}
                  name="maintenance-areas"
                  placeholder="Search MA"
                  onChange={(option) => {
                    if (option.label === ADD_NEW_AREA) {
                      setSelectedMA(null);
                      setShowModal(true);
                      return;
                    }

                    setSelectedMaID(option.id);
                    setSelectedMA(option);
                  }}
                  selected={selectedMA}
                  options={maintenanceAreasOptions}
                />
              </CHeader>
            </>
          )}
          <SpanHeaderItemName>Spans</SpanHeaderItemName>
          <Divider />
          {selectedSpans?.map((span) => {
            return (
              <SpanWrapperItem key={span.spanId}>
                <SpanHeader>
                  <SpanHeaderName>{span.name}</SpanHeaderName>
                  {span.voltage && (
                    <SpanHeaderVoltageWrapper>
                      <StructureHeaderVoltageImg src={VoltageImage} />
                      <SpanHeaderVoltage>
                        {span.voltage}
                        {VOLTAGE_UNIT_SHORT_NAME}
                      </SpanHeaderVoltage>
                    </SpanHeaderVoltageWrapper>
                  )}
                </SpanHeader>
              </SpanWrapperItem>
            );
          })}
        </StyledFlex>
      </SpansContentWrapper>
      <ModalDialog hidden={!showModal} p="35px">
        <Input
          value={newMA}
          onChange={(e) => setNewMA(e.target.value)}
          placeholder="Enter new MA name"
          hasError={false}
        />
        <StyledFlex mt="10px" gap="10px" justifyContent="flex-end" width="100%">
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setSelectedMA(null);
            }}
            fontSize="14px"
            padding="10px"
          >
            Cancel
          </Button>
          <Button variant="primary" fontSize="14px" padding="10px" onClick={submitNewMA} disabled={newMA.trim() === ""}>
            Create MA
          </Button>
        </StyledFlex>
      </ModalDialog>
    </>
  );
};
