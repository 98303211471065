import { FC, useState, useMemo } from "react";
import { Wrapper, Title, StyledCheckbox, StyledFlexItem, SearchWrapper, Footer } from "./PatrolFilter.styled";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/images/magnifier.svg";
import { StyledFlex, FlexItem } from "../../assets/styles/flex.styled";
import { Portal } from "../Portal";
import { Backdrop } from "../Backdrop";
import { IconButton } from "../IconButton";
import { Datepicker } from "../Datepicker";
import { Button } from "../Button";
import { Spinner } from "../Loader/Spinner";
import { Input } from "../Input/Input.styled";
import { usePatrolsSearch } from "../../hooks/usePatrolsSearch";
import { usePatrolsById } from "../../hooks/usePatrols";
import { PatrolSearch } from "../../types/responses/Patrols";
import { usePatrolsStore } from "../../stores/PatrolsStore";

function bySearchTerm(item: PatrolSearch, searchText: string) {
  if (searchText === "") return true;

  // Search by name
  if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
    return true;
  }
  // Search by MA
  if (item.maintenanceAreasNames.some((maItem) => maItem.toLowerCase().includes(searchText.toLowerCase()))) {
    return true;
  }

  return false;
}

function byEndDate(itemEndPatrol: string, from: Date | null, to: Date | null) {
  if (from === null && to === null) return true;

  const itemEndDate = new Date(itemEndPatrol);

  // If start date is null, check for end date
  if (from === null) return itemEndDate <= to!;
  // If end date is null, check for start date
  if (to === null) return from <= itemEndDate;
  // Check if date is in range
  return itemEndDate >= from && itemEndDate <= to;
}

function toggleItemInArray(arr: string[], item: string) {
  const alreadyExists = arr.some((patrolId) => patrolId === item);
  if (alreadyExists) {
    // remove item
    return [...arr.filter((el) => el !== item)];
  } else {
    // add item
    return [...arr, item];
  }
}

interface IProps {
  closeFilterPopup: () => void;
}

export const PatrolFilter: FC<IProps> = ({ closeFilterPopup }) => {
  const { data: patrols = [], isLoading: arePatrolsLoading } = usePatrolsSearch();
  const { mutateAsync: getPatrolsById, isLoading } = usePatrolsById();
  const patrolData = usePatrolsStore((store) => store.data);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState("");
  const [patrolIDs, setPatrolIDs] = useState<string[]>(
    Array.from(new Set([...patrolData?.map((item) => item.id)])) ?? []
  );

  const patrolList = useMemo(
    () =>
      patrols
        .filter((item) => byEndDate(item.ended, startDate, endDate))
        .filter((item) => bySearchTerm(item, searchText)),
    [patrols, searchText, startDate, endDate]
  );

  const onItemClick = (itemId: string) => {
    setPatrolIDs((prevPatrolIDs) => toggleItemInArray(prevPatrolIDs, itemId));
  };

  const onSave = () => {
    getPatrolsById(patrolIDs).then(closeFilterPopup);
  };

  return (
    <Portal selector="layer-menu-root">
      <Backdrop isHidden={false} setIsHidden={closeFilterPopup}>
        <Wrapper flexDirection="column">
          <StyledFlex
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid #F4F4F4"
            padding="15px 20px"
          >
            <Title>Field Patrol</Title>
            <IconButton Icon={CloseIcon} className="close" onClick={closeFilterPopup} />
          </StyledFlex>
          <StyledFlex flexDirection="column" padding="20px">
            <SearchWrapper>
              <MagnifierIcon />
              <Input
                hasError={false}
                placeholder="Search"
                type="text"
                autoFocus={true}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </SearchWrapper>
            <StyledFlex width="100%" gap="10px" mt="20px" pb="10px" borderBottom="1px solid #F4F4F4">
              <FlexItem flex={1}>
                <Datepicker
                  label="From"
                  name="startDate"
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date(endDate ?? "02/01/2970")}
                  onChange={(date) => setStartDate(date)}
                  selected={startDate}
                  isClearable
                />
              </FlexItem>
              <FlexItem flex={1}>
                <Datepicker
                  label="To"
                  name="endDate"
                  dateFormat="MMMM d, yyyy"
                  minDate={new Date(startDate ?? "02/01/1970")}
                  onChange={(date) => setEndDate(date)}
                  selected={endDate}
                  isClearable
                />
              </FlexItem>
            </StyledFlex>
            <StyledFlex
              flexDirection="column"
              gap="10px"
              pt="20px"
              overflowY="scroll"
              overflowX="hidden"
              alignItems="center"
              justifyContent="flex-start"
              minHeight="250px"
              maxHeight="350px"
            >
              {arePatrolsLoading && (
                <StyledFlexItem flex={100}>
                  <Spinner color="var(--colors-eastern-blue)" width="40px" height="40px" m={0} />
                </StyledFlexItem>
              )}
              {!arePatrolsLoading && patrolList.length === 0 && (
                <StyledFlexItem flex={100}>
                  <span>No patrols</span>
                </StyledFlexItem>
              )}
              {!arePatrolsLoading &&
                patrolList.map((patrol) => (
                  <StyledCheckbox
                    key={patrol.id}
                    id={patrol.id}
                    name={patrol.name}
                    onClick={() => onItemClick(patrol.id)}
                    checked={patrolIDs.some((itemId) => itemId === patrol.id)}
                    defaultChecked={patrolIDs.some((itemId) => itemId === patrol.id)}
                    additionalText={`Completed - ${new Date(patrol.ended).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}`}
                    value={patrol.id}
                    label={patrol.name}
                  />
                ))}
            </StyledFlex>
          </StyledFlex>
          <Footer gap="20px" justifyContent="flex-end" padding="15px 20px 20px 100px">
            <Button variant="secondary" fontSize="14px" onClick={closeFilterPopup}>
              Cancel
            </Button>
            <Button variant="primary" fontSize="14px" onClick={onSave} disabled={patrolIDs.length === 0}>
              {isLoading ? (
                <StyledFlex width="100%" alignItems="center" justifyContent="center">
                  <Spinner color={`var(--colors-white)`} width="10px" height="10px" m={0} />
                </StyledFlex>
              ) : (
                "Save"
              )}
            </Button>
          </Footer>
        </Wrapper>
      </Backdrop>
    </Portal>
  );
};
