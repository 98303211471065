import { Feature, Polygon } from "@turf/helpers";
import create from "zustand";

import { SelectionToolTypes } from "../components/SelectionTools/SelectionTools.utils";
import { StructureWorksheetDto } from "../types/responses";
import MapHelper from "../utils/mapHelper";

export enum ASSET_TYPES {
  STRUCTURE = "Structure",
  SPAN = "Span",
  PARCEL = "Parcel",
  MA = "MA",
}

export enum LAYERS_PANE {
  STRUCTURE = "structures-pane",
  SPAN = "clearance-pane",
  PARCEL = "parcels-pane",
  MA = "ma-pane",
}
export type SelectedStructures = {
  name: string;
  voltage: number;
  structureWithWorksheet: StructureWorksheetDto;
};

export type StructuresWithWorksheet = {
  items: SelectedStructures[];
  totalCost: number;
};

export interface LayerType extends L.Layer {
  options: { pane: string; alt: Nullable<string> };
  feature: any;
}
export type PolygonFrameData = {
  polygon: Nullable<Feature<Polygon, any>>;
  bounds: Nullable<L.LatLngBoundsExpression>;
  markerPosition: Nullable<number[]>;
  center: L.LatLng;
};

const INITIAL_LAYER_STATE = { Structure: [], Span: [], Parcel: [], MA: [] };

export type MapSelectionStoreState = {
  hidden: boolean;
  selectedLayers: Record<string, Nullable<string[]>>; //eg.{'Structure': [structureId1, structureId2, ....]}
  isSelectionRHSDrawerVisible: boolean;
  selectedAssetType: ASSET_TYPES | null;
  hiddenSelectionPopup: boolean;
  initialParcelValues: Array<any>;
  activeSelectionTool: SelectionToolTypes | null;
  parcelDetails: Array<any>;
  temporaryShiftKeyElements: Nullable<string[]>;
  temporaryId: Nullable<string>;
  selectionFrameHidden: boolean;
  framePolygon: Nullable<PolygonFrameData>;
  toggle: () => void;
  resetState: () => void;
  setAssetTypeSelection: (value: ASSET_TYPES) => void;
  setLayers: (layers: Nullable<LayerType[]>, latLngs?: Nullable<L.LatLng[]>) => void;
  setSelectionRHSDrawerVisibility: (value: boolean) => void;
  setSelectionPopup: (value: boolean) => void;
  setInitialParcelValues: (parcels: Array<any>) => void;
  setParcelDetails: (parcels: Array<any>) => void;
  setActiveSelectionTool: (value: SelectionToolTypes | null) => void;
  addSelectedByShiftKey: (id: string, assetType: ASSET_TYPES) => Maybe<Nullable<string>>;
  addSelectedIDs: (id: string[], assetType: ASSET_TYPES) => void;
  removeSelectedId: (id: string, assetType: ASSET_TYPES) => void;
  resetSelectionTool: (id: string, assetType: ASSET_TYPES) => void;
  setSelectionFrameState: (value: boolean) => void;
};

const INITIAL_STATE = {
  hidden: true,
  selectedAssetType: ASSET_TYPES.SPAN,
  selectedLayers: INITIAL_LAYER_STATE,
  isSelectionRHSDrawerVisible: false,
  hiddenSelectionPopup: true,
  initialParcelValues: [],
  parcelDetails: [],
  activeSelectionTool: null,
  temporaryShiftKeyElements: [],
  temporaryId: null,
  selectionFrameHidden: true,
  framePolygon: null,
};

const useMapSelectionStore = create<MapSelectionStoreState>((set, get) => ({
  ...INITIAL_STATE,
  toggle: () => {
    set({ hidden: !get().hidden });
    if (get().hidden) {
      set({ hiddenSelectionPopup: true });
      set({ activeSelectionTool: null });
    }
  },
  setAssetTypeSelection: (type: ASSET_TYPES) => {
    set({ selectedAssetType: type, temporaryShiftKeyElements: get().selectedLayers?.[type] });
  },
  resetState: () => {
    set({ ...INITIAL_STATE });
  },
  setLayers: (layers, latLngs?) => {
    const isRHSVisible = !!layers?.length;
    const selectedLayers = mapLayersIntoIds(layers);
    let framePolygon = null;
    if (latLngs && latLngs.length >= 2) {
      framePolygon = MapHelper.makePolygonFromPoints(latLngs);
    }
    set({
      selectedLayers,
      isSelectionRHSDrawerVisible: isRHSVisible,
      temporaryShiftKeyElements: selectedLayers?.[get().selectedAssetType ?? ""],
      framePolygon,
    });
  },
  setSelectionRHSDrawerVisibility: (value) => {
    set({ isSelectionRHSDrawerVisible: value });
  },
  setSelectionPopup: (value: boolean) => {
    set({ hiddenSelectionPopup: value });
  },
  setInitialParcelValues: (parcels) => {
    set({ initialParcelValues: [...parcels] });
  },
  setParcelDetails: (parcels) => {
    set({ parcelDetails: [...parcels] });
  },
  setActiveSelectionTool: (selectionTool) => {
    set({ activeSelectionTool: selectionTool });
  },
  addSelectedIDs: (iDs, assetType) => {
    if (!iDs.length) return;

    set({ selectedAssetType: assetType });

    const selectedLayers = { ...get().selectedLayers };
    selectedLayers[assetType] = [...iDs];

    set({
      selectedLayers,
      isSelectionRHSDrawerVisible: iDs.length >= 2,
      temporaryShiftKeyElements: [...iDs] ?? null,
    });
  },
  removeSelectedId: (id, assetType) => {
    if (!id) return {};

    const idExists = get().temporaryShiftKeyElements?.some((tempId) => tempId === id) || [];
    if (!idExists) return {};

    const temporaryShiftKeyElements = get().temporaryShiftKeyElements?.filter((tempId) => tempId !== id) || [];

    const selectedLayers = { ...get().selectedLayers };
    selectedLayers[assetType] = [...temporaryShiftKeyElements];

    return {
      selectedLayers,
      isSelectionRHSDrawerVisible: temporaryShiftKeyElements.length >= 2,
      temporaryShiftKeyElements,
    };
  },
  addSelectedByShiftKey: (id, assetType) => {
    let temporaryShiftKeyElements = get().temporaryShiftKeyElements;

    if (!temporaryShiftKeyElements?.length) {
      temporaryShiftKeyElements = [];
    }
    const selectedLayers = { ...get().selectedLayers };
    //if user click for the first time, set asset type
    if (temporaryShiftKeyElements.length === 0) {
      set({ selectedAssetType: assetType });
    }
    //if user clicks on other assets than ignore it
    if (temporaryShiftKeyElements.length > 0 && assetType !== get().selectedAssetType) {
      return;
    }

    const tempIndex = temporaryShiftKeyElements.findIndex((tempId) => tempId === id);
    if (tempIndex === undefined || tempIndex < 0) {
      temporaryShiftKeyElements.push(id);
    } else {
      temporaryShiftKeyElements.splice(tempIndex, 1);
    }

    let isSelectionRHSVisible = false;
    //show mapSelectionTool only if user choose 2 or more elements
    if (temporaryShiftKeyElements.length >= 2) {
      isSelectionRHSVisible = true;
    }
    selectedLayers[assetType] = Object.assign([], temporaryShiftKeyElements);

    set({
      selectedLayers: Object.assign({}, selectedLayers),
      isSelectionRHSDrawerVisible: isSelectionRHSVisible,
      temporaryShiftKeyElements,
    });
    return selectedLayers[assetType]?.length === 1 ? selectedLayers[assetType]?.[0] : null;
  },
  resetSelectionTool: (id: string, assetType: ASSET_TYPES) => {
    const selectedLayers = { ...get().selectedLayers };
    selectedLayers[assetType] = Object.assign([], []);
    set({
      temporaryShiftKeyElements: [id],
      selectedAssetType: assetType,
      selectedLayers: Object.assign({}, selectedLayers),
      isSelectionRHSDrawerVisible: false,
    });
  },
  setSelectionFrameState: (value) => {
    set({ selectionFrameHidden: value });
  },
}));

export const mapLayersIntoIds = (layers: Nullable<LayerType[]>) => {
  if (!layers?.length) {
    return INITIAL_LAYER_STATE;
  }
  const records: Record<string, Nullable<string[]>> = {};
  layers = layers?.filter(
    (layer) =>
      layer?.options?.pane === LAYERS_PANE.SPAN ||
      layer?.options?.pane === LAYERS_PANE.STRUCTURE ||
      layer?.options?.pane === LAYERS_PANE.PARCEL
  );

  records[ASSET_TYPES.STRUCTURE] = getFilteredLayers(LAYERS_PANE.STRUCTURE, layers, false);
  records[ASSET_TYPES.SPAN] = getFilteredLayers(LAYERS_PANE.SPAN, layers);
  records[ASSET_TYPES.PARCEL] = getFilteredLayers(LAYERS_PANE.PARCEL, layers);
  records[ASSET_TYPES.MA] = getFilteredLayers(LAYERS_PANE.SPAN, layers);

  return records;
};

const getFilteredLayers = (layerPane: string, layers: Nullable<LayerType[]>, fromFeature = true) => {
  if (!layers?.length) {
    return [];
  }

  if (layerPane === LAYERS_PANE.MA) {
    return (
      layers
        //@ts-ignore
        ?.filter((layer) => layer?.options?.pane === layerPane && layer?.options?.className === "maintenance-area")
        .map((layer) => (fromFeature ? layer?.feature?.properties?.id : layer?.options.alt)) ?? null
    );
  }

  return (
    layers
      ?.filter((layer) => layer?.options?.pane === layerPane)
      .map((layer) => {
        //@ts-ignore
        return fromFeature ? layer?.feature?.properties?.id : layer?.options.alt;
      }) ?? null
  );
};

export { useMapSelectionStore };
