import { FC, memo, useEffect } from "react";
import { PositionProps } from "styled-system";
import { MapControlsWrapper } from "../MapControl";
import { Portal } from "../Portal";
import { StyledControl } from "./MapSelectionControl.styled";

interface MapZoomControlProps extends PositionProps {
  map: L.Map;
}

interface LayerProps {
  map: L.Map;
  layer?: L.Layer;
  onClick: (layerName: string) => void;
  name: string;
  turnedOn: boolean;
  toggle: () => void;
  currentZoomLevel: number;
  isDisabled: boolean;
}

export const SelectionLayer: FC<LayerProps> = memo(
  ({ map, layer, onClick, name, children, toggle, turnedOn, isDisabled }) => {
    useEffect(() => {
      if (!map || !layer) {
        return;
      }
    }, [map, layer]);

    const handleClick = (name: string) => {
      if (!isDisabled) {
        toggle();
        onClick(name);
      }
    };

    return (
      <StyledControl turnedOn={turnedOn} isDisabled={isDisabled} onClick={() => handleClick(name)}>
        {children}
      </StyledControl>
    );
  }
);

export const MapSelectionControl: FC<MapZoomControlProps> = memo(({ map, children, ...props }) => (
  <Portal selector="layer-menu-root">
    <MapControlsWrapper id="selection-control" {...props}>
      {children}
    </MapControlsWrapper>
  </Portal>
));
