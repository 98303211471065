import { FC, useMemo } from "react";

import { SpanWorksheetDto, Worksheet } from "../../types/responses";
import { WorkTypeResponse } from "../../types/responses/WorkTypeResponse";
import { toUsdCurrency } from "../../utils/stringUtils";
import { calculateSubItem, calculateTotalCost } from "../SelectionToolRHS/SelectionToolRHS.utils";
import { Option } from "../Select";
import { WorksheetAreaControl } from "../WorksheetArea/WorksheetAreaControl";
import { WorkTypeItem } from "../WorkTypeItem";
import {
  SpanHeader,
  SpanHeaderControlWrapper,
  SpanHeaderItemDeleteAll,
  SpanHeaderItemName,
  SpanHeaderName,
  SpanHeaderVoltage,
  SpanHeaderVoltageWrapper,
  SpansContentWrapper,
  SpanWrapperItem,
  SubTotalItem,
  WorksheetWrapper,
} from "./SpansContentRHS.styled";
import VoltageImage from "./../../assets/images/voltage.svg";
import { StructureHeaderVoltageImg } from "../StructureRHSContent/StructureRHSContent.styled";
import { VOLTAGE_UNIT_SHORT_NAME } from "../../constants";

type SpansContentRHSProps = {
  selectedSpans: Nullable<SpanWorksheetDto[]>;
  workTypeResponse: Nullable<Record<string, WorkTypeResponse>>;
  isEditMode: boolean;
  deleteWorkType: (workTypeId: string, objectId?: string, manuallyAdded?: boolean, cost?: number) => void;
  deleteAllWorkTypes: () => void;
  addNewWorkType: (workType: Option) => void;
};
export const SpansContentRHS: FC<SpansContentRHSProps> = ({
  selectedSpans,
  workTypeResponse,
  isEditMode,
  deleteWorkType,
  deleteAllWorkTypes,
  addNewWorkType,
}) => {
  const selectedSpansById: Record<string, Worksheet[]> = useMemo(() => {
    if (!selectedSpans?.length) {
      return {};
    }

    const selectedSpanByID: Record<string, Worksheet[]> = {};
    selectedSpans.forEach((span) => {
      selectedSpanByID[span.spanId] = span.items.filter((item) => !item.inherited && item.selected);
    });
    return selectedSpanByID;
  }, [selectedSpans]);

  const spansWithoutInherited = useMemo(() => {
    if (!selectedSpans) {
      return null;
    }
    return selectedSpans.map((span) => {
      return {
        spanId: span.spanId,
        items: span.items?.filter((item) => item.selected && !item.inherited),
        name: span.name,
        voltage: span.voltage,
      };
    });
  }, [selectedSpans]);

  return (
    <SpansContentWrapper>
      {isEditMode && (
        <WorksheetWrapper>
          <WorksheetAreaControl
            selectedWorkTypes={[]}
            readonly={!isEditMode}
            addNewWorkType={addNewWorkType}
            scope="SPAN"
          />
        </WorksheetWrapper>
      )}
      <SpanHeaderControlWrapper>
        <SpanHeaderItemName>Work Types</SpanHeaderItemName>
        {isEditMode && (
          <SpanHeaderItemDeleteAll onClick={() => deleteAllWorkTypes()}>Delete All</SpanHeaderItemDeleteAll>
        )}
      </SpanHeaderControlWrapper>
      {selectedSpans?.map((span) => {
        return (
          <SpanWrapperItem key={span.spanId}>
            <SpanHeader>
              <SpanHeaderName>{span.name}</SpanHeaderName>
              {span.voltage && (
                <SpanHeaderVoltageWrapper>
                  <StructureHeaderVoltageImg src={VoltageImage} />
                  <SpanHeaderVoltage>
                    {span.voltage}
                    {VOLTAGE_UNIT_SHORT_NAME}
                  </SpanHeaderVoltage>
                </SpanHeaderVoltageWrapper>
              )}
            </SpanHeader>
            {selectedSpansById?.[span.spanId] &&
              selectedSpansById?.[span.spanId].map((item, index) => {
                return (
                  <>
                    <WorkTypeItem
                      isEditMode={isEditMode}
                      key={workTypeResponse?.[item.workTypeId].code ?? ""}
                      code={workTypeResponse?.[item.workTypeId].code ?? ""}
                      cost={item.cost}
                      name={workTypeResponse?.[item.workTypeId].name ?? ""}
                      deleteWorkType={deleteWorkType}
                      objectId={span.spanId}
                      id={item.workTypeId}
                      manuallyAdded={false}
                    ></WorkTypeItem>
                    {selectedSpansById?.[span.spanId]?.length &&
                      selectedSpansById[span.spanId].length > 1 &&
                      index === selectedSpansById[span.spanId].length - 1 && (
                        <SubTotalItem>
                          <span>Sub:</span>
                          <div>{toUsdCurrency(calculateSubItem(selectedSpansById[span.spanId]))} </div>
                        </SubTotalItem>
                      )}
                  </>
                );
              })}
          </SpanWrapperItem>
        );
      })}
      <SubTotalItem>
        <span>Total:</span>
        <div>{toUsdCurrency(calculateTotalCost(spansWithoutInherited))} </div>
      </SubTotalItem>
    </SpansContentWrapper>
  );
};
