import { useEffect } from "react";
import { getFeederSegmentsByExtentUrl } from "../api";

import { MIN_ZOOM_LEVEL_FOR_SPANS_LAYER } from "../pages/MapScreen/MapView/MapView.constants";
import { useMapStore } from "../stores";
import useFeederSegmentStore from "../stores/FeederSegmentStore";

import { FeederSegmentResponse } from "../types/responses/FeederSegmentResponse";
import { useCustomQuery } from "./useCustomQuery";

import { useExtentParams } from "./useExtentParams";

const QUERY_KEY = "feeder-segments";

export const useFeederSegments = () => {
  const params = useExtentParams();
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const setFeederSegments = useFeederSegmentStore((store) => store.setData);

  const isLayerVisible = currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_SPANS_LAYER;

  const { refetch } = useCustomQuery<FeederSegmentResponse>(
    params !== null ? getFeederSegmentsByExtentUrl({ ...params, zoomLevel: currentZoomLevel }) : `/${QUERY_KEY}`,
    {
      onSuccess: (response: FeederSegmentResponse) => setFeederSegments(response),
      enabled: isLayerVisible && params !== null,
    }
  );

  useEffect(() => {
    if (!params || !isLayerVisible) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, isLayerVisible]);
};
