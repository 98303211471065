import { useState, useRef, useCallback } from "react";

export const useUserConfirmation = () => {
  const [confirmationPromise, setConfirmationPromise] = useState<Nullable<Promise<boolean>>>(null);
  const promiseResolver = useRef<Nullable<(state: boolean) => void>>(null);
  const promiseRejecter = useRef<Nullable<(state: boolean | Error) => void>>(null);

  const confirmUserConsent = useCallback(() => {
    const promise = new Promise<boolean>((resolve, reject) => {
      promiseResolver.current = resolve;
      promiseRejecter.current = reject;
    });

    setConfirmationPromise(promise);

    return promise;
  }, [setConfirmationPromise]);

  const onConfirm = useCallback(() => {
    if (!confirmationPromise) {
      return;
    }
    promiseResolver.current?.(true);
    setConfirmationPromise(null);
    promiseResolver.current = null;
    promiseRejecter.current = null;
  }, [confirmationPromise]);

  const onReject = useCallback(() => {
    if (!confirmationPromise) {
      return;
    }
    promiseRejecter.current?.(new Error("User canceled action"));
    setConfirmationPromise(null);
    promiseResolver.current = null;
    promiseRejecter.current = null;
  }, [confirmationPromise]);

  return { confirmUserConsent, onConfirm, onReject, waitingForConfirmation: !!confirmationPromise };
};
