import { FC } from "react";

import { MapTreeMarkers } from "../../../components/MapTreeMarkers";
import { useTreeMarkers } from "../../../hooks/useTreeMarkers";
import { useMapStore } from "../../../stores";
import useTreeMarkersStore from "../../../stores/TreeMarkersStore";
import { MARKER_ZOOM_LEVEL } from "./TreeMarkerView.utils";

export const TreeMarkersView: FC<{ map: Nullable<L.Map> }> = ({ map }) => {
  const areMarkersVisible = useTreeMarkersStore((store) => store.isLayerVisible);
  const treeMarkers = useTreeMarkersStore((store) => store.data);
  const categories = useTreeMarkersStore((store) => store.categories);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  useTreeMarkers();
  const areTreeMarkersVisible = areMarkersVisible && currentZoomLevel > MARKER_ZOOM_LEVEL;

  return <>{areTreeMarkersVisible && <MapTreeMarkers categories={categories} map={map} treeMarkers={treeMarkers} />}</>;
};
