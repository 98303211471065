import styled from "styled-components";
import { position, PositionProps } from "styled-system";

import { OPACITY_MAP_CONTROL } from "../../constants";
import { ReactComponent as Close } from "./../../assets/images/close_map_overlay.svg";

export const MapOpacityLayerWrapper = styled.div<PositionProps>`
  ${position}
  height: 35px;
  background: rgba(15, 22, 22, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  bottom: 24px;
  position: absolute;
  z-index: ${OPACITY_MAP_CONTROL};
  display: flex;
  user-select: none;
`;

export const MapOpacityHeader = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--colors-white);
  min-height: 31px;
  border-bottom: 1px solid #303935;
  align-items: center;
  justify-content: space-between;
  div {
    margin-left: 12px;
  }
`;
export const CloseIcon = styled(Close)`
  height: 10px;
  width: 10px;
  margin-right: 12px;
  &:hover {
    fill: ${(props) => props.theme.colors.easternBlue};
  }
  &:active {
    fill: ${(props) => props.theme.colors.white};
  }
  cursor: pointer;
`;

export const MapOpacityControlSlider = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    padding-left: 6px;
    span {
      padding: 0px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  div:last-child {
    background: transparent;
    margin: 0;
    color: var(--colors-white);

    div {
      padding: 0px;
      padding-right: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      padding-left: 4px;
      width: 50px;
    }
  }
`;
