import React, { useState, FC, forwardRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useField, useFormikContext } from "formik";
import { SpaceProps } from "styled-system";
import { Wrappper, Label, DateInputWrapper, DatepickerPlaceholder } from "./Datepicker.styled";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import { toLocalDate } from "../../utils/dateUtils";

import "react-datepicker/dist/react-datepicker.css";

const CustomInput = forwardRef<HTMLDivElement, any>((props, ref) => (
  <DateInputWrapper onClick={props.onClick} ref={ref}>
    {props.value ? <span>{props.value}</span> : <DatepickerPlaceholder>{props.placeholder}</DatepickerPlaceholder>}
    <CalendarIcon onClick={props.onClick} />
  </DateInputWrapper>
));

interface IProps {
  label?: string;
  name: string;
}

export const Datepicker: FC<IProps & SpaceProps & ReactDatePickerProps> = ({
  label,
  name,
  selected,
  onChange,
  minDate,
  maxDate,
  placeholderText,
  ...props
}) => {
  return (
    <Wrappper {...props}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <DatePicker
        name={name}
        className="datepicker"
        selected={selected}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        customInput={<CustomInput />}
        placeholderText={placeholderText}
        {...props}
      />
    </Wrappper>
  );
};

export const DatepickerField: FC<IProps & SpaceProps & Partial<ReactDatePickerProps>> = ({
  label,
  name,
  onChange,
  dateFormat = "MMMM d, yyyy",
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, validateField } = useFormikContext();
  const [wasOpened, setWasOpened] = useState(false);

  return (
    <Wrappper className={(meta.touched || wasOpened) && meta.error ? "hasError" : ""} {...props}>
      <Label htmlFor={name}>{label}</Label>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        className="datepicker"
        customInput={<CustomInput />}
        shouldCloseOnSelect={true}
        showYearDropdown
        isClearable
        scrollableYearDropdown
        onChange={(value, e) => {
          setFieldValue(field.name, toLocalDate(value?.toString(), dateFormat as string));
          onChange?.(value!, e);
        }}
        onCalendarClose={() => {
          setWasOpened(true);
          validateField(field.name);
        }}
      />
      {(meta.touched || wasOpened) && meta.error && <span className="errorMsg">{meta.error}</span>}
    </Wrappper>
  );
};
