import { FC, useCallback, useMemo, useRef, useState } from "react";
import StateManager from "react-select";
import cloneDeep from "lodash.clonedeep";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { useCustomQuery } from "../../hooks";
import { WorksheetResponse } from "../../types/responses/WorksheetResponse";
import { Button } from "../Button";
import { Select, Option } from "../Select";
import { SelectWrapper, WorkTypeTotal, WorkTypeTotalLabel, WorkTypeTotalValue } from "./WorksheetArea.styled";
import { mapOptionsFromWorksheets, mapWorksheetById, mapWorkTypesById } from "./WorksheetArea.utils";
import { WorkTypes } from "./WorkTypes/WorkTypes";
import { toUsdCurrency } from "../../utils/stringUtils";
import { getWorkTypesApiUrl } from "../../api";
import { WorkTypeResponse } from "../../types/responses";
import { ModalDialog } from "../ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../ModalDialog/ModalDialog.styled";
import { Portal } from "../Portal";
import { Loader } from "../Loader";
import { CustomOption, CustomSingleValue } from "./WorksheetArea";

interface WorksheetAreaProps {
  readonly: boolean;
  worksheetData?: Nullable<WorksheetResponse>;
  scope?: Nullable<"SPAN" | "INFRINGEMENT">;
  showSections?: boolean;
  isWorksheetLoading: boolean;
  invokeUpdateWorksheet: (values: string[]) => Promise<unknown>;
  deleteWorkTypeFromInfringement?: (workTypeId: string) => Promise<unknown>;
  assignToNewWorkorder?: (workTypeId: string) => void;
}

export const WorksheetAllArea: FC<WorksheetAreaProps> = ({
  readonly = false,
  worksheetData,
  scope = "SPAN",
  showSections = false,
  isWorksheetLoading,
  invokeUpdateWorksheet,
  deleteWorkTypeFromInfringement,
  assignToNewWorkorder,
}) => {
  const selectInputRef = useRef<Nullable<StateManager>>(null);
  const [selectedIdForRemoval, setSelectedId] = useState<Nullable<string>>(null);
  const [workTypeForInfringement, setWorkTypeForInfringement] = useState<Nullable<boolean>>(false);
  const hideModal = () => setSelectedId(null);

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());

  const workTypesForSpan = useMemo(() => {
    if (!worksheetData?.items?.length || !workTypesResponse?.length) {
      return [];
    }
    return worksheetData.items.map((item) => {
      const workType = workTypesResponse.find((workType) => workType.id === item.workTypeId);
      return {
        defaultFlag: false,
        scope: scope,
        id: item.workTypeId,
        unit: workType?.unit ?? "",
        name: workType?.name ?? "",
        code: workType?.code ?? "",
      } as WorkTypeResponse;
    });
  }, [worksheetData, workTypesResponse, scope]);

  const workTypesById = useMemo(() => mapWorkTypesById(workTypesForSpan), [workTypesForSpan]);

  const worksheetById = useMemo(() => mapWorksheetById(worksheetData), [worksheetData]);

  const selectedWorkTypes = useMemo(() => {
    return (
      worksheetData?.items
        ?.filter((item) => item.selected)
        .map((item) => ({
          ...item,
          category: workTypesById[item.workTypeId]?.name,
          unit: workTypesById[item.workTypeId]?.unit,
          code: workTypesById[item.workTypeId]?.code,
        })) || []
    );
  }, [worksheetData, workTypesById]);

  const addNewWorkType = useCallback(
    (workTypeOption: Option) => {
      const clonedWorksheetResponse = cloneDeep(worksheetData);
      const item = clonedWorksheetResponse?.items?.find((item) => item.workTypeId === workTypeOption?.value);
      if (!item) {
        return;
      }
      item.selected = true;
      const workTypeIds = clonedWorksheetResponse?.items.filter(
        (worksheetData) => worksheetData.selected && !worksheetData.inherited
      );

      return invokeUpdateWorksheet(workTypeIds?.length ? workTypeIds.map((item) => item.workTypeId) : []).then(() =>
        selectInputRef.current?.select?.clearValue()
      );
    },
    [worksheetData, invokeUpdateWorksheet]
  );

  const removeWorkType = useCallback(() => {
    if (selectedIdForRemoval && workTypeForInfringement && deleteWorkTypeFromInfringement) {
      deleteWorkTypeFromInfringement(selectedIdForRemoval).then(hideModal);
      return;
    }
    const clonedWorksheetResponse = cloneDeep(worksheetData);
    const spanItem = clonedWorksheetResponse?.items?.find(
      (item) => item.workTypeId === selectedIdForRemoval && !item.inherited && item.selected
    );
    const inheritedItem = clonedWorksheetResponse?.items?.find(
      (item) => item.workTypeId === selectedIdForRemoval && item.inherited && item.selected
    );

    if (!spanItem && !inheritedItem) {
      return;
    }
    if (spanItem) {
      spanItem.selected = false;
    }
    if (inheritedItem) {
      inheritedItem.selected = false;
    }
    const workTypeIds = clonedWorksheetResponse?.items.filter((worksheetResponse) => {
      return (
        worksheetResponse.selected &&
        (deleteWorkTypeFromInfringement === undefined ||
          (deleteWorkTypeFromInfringement !== undefined && !worksheetResponse.inherited))
      );
    });

    return invokeUpdateWorksheet(workTypeIds?.length ? workTypeIds.map((item) => item.workTypeId) : []).then(hideModal);
  }, [
    worksheetData,
    invokeUpdateWorksheet,
    selectedIdForRemoval,
    deleteWorkTypeFromInfringement,
    workTypeForInfringement,
  ]);

  const confirmRemoval = useCallback(
    (id: string, fromInfringement?: boolean) => {
      if (!id) {
        return;
      }
      setSelectedId(id);
      setWorkTypeForInfringement(fromInfringement ?? false);
    },
    [setSelectedId]
  );

  return (
    <>
      <Portal selector="layer-menu-root">
        <ModalDialog
          hidden={!selectedIdForRemoval}
          message={"Are you sure you want to delete this work type entry?"}
          onClose={hideModal}
          title="Delete work type"
          warning
        >
          <ButtonsWrapper>
            <ButtonWrapper>
              <Button variant="cancel" size="small" onClick={hideModal}>
                Cancel
              </Button>
              <Button variant="warning" size="small" onClick={removeWorkType}>
                Delete
              </Button>
            </ButtonWrapper>
          </ButtonsWrapper>
        </ModalDialog>
      </Portal>
      <StyledFlex flexDirection="column">
        {!readonly && (
          <StyledFlex alignItems="center" paddingTop="3px" paddingBottom="16px">
            <SelectWrapper>
              <Select
                innerRef={selectInputRef}
                isDisabled={false}
                name="workType"
                onChange={addNewWorkType}
                options={mapOptionsFromWorksheets(workTypesById, worksheetById)}
                placeholder="+Add Work Type"
                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                isOptionDisabled={(item: Option) => worksheetById?.[item.value]?.selected}
              />
            </SelectWrapper>
          </StyledFlex>
        )}

        {isWorksheetLoading ? (
          <StyledFlex alignItems="center" justifyContent="center">
            <Loader />
          </StyledFlex>
        ) : (
          <WorkTypes
            showSections={showSections}
            readonly={readonly}
            workTypes={selectedWorkTypes}
            removeWorkType={confirmRemoval}
            assignToNewWorkorder={assignToNewWorkorder}
          />
        )}
        <WorkTypeTotal>
          <WorkTypeTotalLabel>Total:</WorkTypeTotalLabel>
          <WorkTypeTotalValue>{toUsdCurrency(worksheetData?.totalCost ?? 0)}</WorkTypeTotalValue>
        </WorkTypeTotal>
      </StyledFlex>
    </>
  );
};
