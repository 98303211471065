import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { getPlanUrl } from "../api";
import { logError } from "../services";

interface Plan {
  planDetails: {
    id: string;
    name: string;
    start: Date;
    end: Date;
    updated: Date;
    status: string;
  };
  planBudget: {
    totalBudget: number;
    budgetByRegions: { budget: number; regionGroupId: string }[];
  };
  planCrew: {
    crewsByRegion: {
      crewId: string;
      regionGroupId: string;
    }[];
  };
}

export const usePlan = (planId: string, isEnabled: boolean) => {
  return useQuery(`plan-${planId}`, () => ApiClient.get<Plan>(getPlanUrl(planId)).then((response) => response.data), {
    onError: (e) => logError(e),
    enabled: isEnabled,
  });
};

export default usePlan;
