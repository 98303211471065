import create from "zustand";

import { EMPTY_FN } from "../constants";
import {
  FeederSegment,
  FeederSegmentProperties,
  FeederSegmentResponse,
} from "../types/responses/FeederSegmentResponse";
import MapHelper from "../utils/mapHelper";

export type FeederSegmentsById = Record<string, FeederSegment>;

export type FeederSegmentPositions = Record<
  string,
  {
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }
>;

export type FeederSegmentStore = {
  byId: FeederSegmentsById;
  geoJSON: Nullable<FeederSegmentResponse>;
  getById: (id: string) => Nullable<FeederSegment>;
  resetStore: () => void;
  setData: (structures: FeederSegmentResponse) => void;
  positions: FeederSegmentPositions;
  selectedId: Nullable<string>;
  setSelectedFeederSegmentById: (selectedFeederSegmentId: Nullable<string>) => void;
};

const INITIAL_STATE = {
  byId: {},
  geoJSON: null,
  positions: {},
  resetStore: EMPTY_FN,
  selectedId: null,
};

export const useFeederSegmentStore = create<FeederSegmentStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setData: (data) => set(mapFeederSegmentResponse(data)),
  getById: (id: string) => get().byId?.[id],
  setSelectedFeederSegmentById: (selectedFeederSegmentId) => {
    if (selectedFeederSegmentId && selectedFeederSegmentId === get().selectedId) {
      return;
    }
    set({
      selectedId: selectedFeederSegmentId || null,
    });
  },
}));

export const computeCenterAndBoundsForCollection = (
  collection: GeoJSON.FeatureCollection<GeoJSON.Geometry, { id: string }>
) => {
  const positions: FeederSegmentPositions = {};

  collection.features.forEach((feature) => {
    const center = MapHelper.centroid(false, feature);
    const bounds = MapHelper.normalizeBounds(MapHelper.bBox(true, [feature]));
    positions[feature.properties.id] = {
      center: [center.geometry.coordinates[1], center.geometry.coordinates[0]],
      bounds,
    };
  });
  return positions;
};

const mapFeederSegmentById = (collection: GeoJSON.FeatureCollection<GeoJSON.Geometry, FeederSegmentProperties>) => {
  return collection.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr;

    return prev;
  }, {} as FeederSegmentsById);
};

export const mapFeederSegmentResponse = (geoJSON: FeederSegmentResponse) => {
  const positions = computeCenterAndBoundsForCollection(geoJSON);
  const byId = mapFeederSegmentById(geoJSON);

  return {
    byId,
    geoJSON,
    positions,
  };
};

export default useFeederSegmentStore;
