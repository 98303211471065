import create from "zustand";

export type MapMeasureStoreState = {
  hidden: boolean;
  distance: number; //in ft
  area: Nullable<number>; //sq meters
  toggle: () => void;
  resetState: () => void;
  setDistance: (distance: number) => void;
  setArea: (area: Nullable<number>) => void;
};

const INITIAL_STATE = {
  distance: 0,
  area: null,
  hidden: true,
};

const useMapMeasureStore = create<MapMeasureStoreState>((set, get) => ({
  ...INITIAL_STATE,
  toggle: () => {
    if (get().hidden) {
      get().resetState();
    }
    set({ hidden: !get().hidden });
  },
  resetState: () => {
    set({ distance: INITIAL_STATE.distance, area: INITIAL_STATE.area });
  },
  setArea: (area) => {
    set({ area });
  },
  setDistance: (distance) => {
    set({ distance });
  },
}));

export { useMapMeasureStore };
