import { FC, useMemo } from "react";

import { Spinner } from "../Loader/Spinner";
import { Portal } from "../Portal";
import { MessagePopup, StatusWrapper, UploadingBarContent, CloseIcon as CloseImg } from "./UploadingProgress.styled";
import { ReactComponent as DoneImg } from "./../../assets/images/check.svg";

type UploadingProgressProps = {
  status: "LOADING" | "DONE" | "ERROR" | "PROCESSING";
};
export const UploadingProgress: FC<UploadingProgressProps> = ({ status }) => {
  const statusMessage = useMemo(() => {
    switch (status) {
      case "LOADING":
        return "Uploading Attachments";
      case "PROCESSING":
        return "Processing Attachments";
      case "ERROR":
        return "Error! Please try again!";
      default:
        return "Done";
    }
  }, [status]);
  return (
    <Portal selector="layer-menu-root">
      <UploadingBarContent id="loading">
        <MessagePopup justifyContent={status === "LOADING" ? "start" : "space-between"}>
          {status === "LOADING" || status === "PROCESSING" ? (
            <Spinner ml="11px" />
          ) : (
            <StatusWrapper backgroundColor={status === "DONE" ? "#17d284" : "#d22717"}>
              {status === "DONE" ? <DoneImg /> : <CloseImg />}
            </StatusWrapper>
          )}
          <div>{statusMessage}</div>
        </MessagePopup>
      </UploadingBarContent>
    </Portal>
  );
};
