import { distance, lineString, point, Position, length, polygon, area } from "@turf/turf";
import { LatLng } from "leaflet";

import { getFormattedDistance, squareMetersToAcres } from "../../../utils/unitHelper";

export const eventHandlers = {
  onCreated: "draw:created",
  onEdited: "draw:edited",
  onDrawStart: "draw:drawstart",
  onDrawStop: "draw:drawstop",
  onDrawVertex: "draw:drawvertex",
  onEditStart: "draw:editstart",
  onEditMove: "draw:editmove",
  onEditResize: "draw:editresize",
  onEditVertex: "draw:editvertex",
  onEditStop: "draw:editstop",
  onDeleted: "draw:deleted",
  onDeleteStart: "draw:deletestart",
  onDeleteStop: "draw:deletestop",
};

export const distanceFromPointToPoint = (
  pointFrom: Nullable<L.LatLng>,
  pointTo: Nullable<L.LatLng>,
  currentDistance = 0
) => {
  if (!pointFrom || !pointTo) {
    return "";
  }
  const from = point([pointFrom.lng, pointFrom.lat]);
  const to = point([pointTo.lng, pointTo.lat]);

  const feetDistance = distance(from, to, { units: "feet" });
  if (!feetDistance) {
    return "";
  }

  return getFormattedDistance(feetDistance + currentDistance);
};

export const lineStringLength = (lineStringPositions: Position[]) => {
  if (!lineStringPositions?.length) {
    return 0;
  }
  const lineStringGeo = lineString(lineStringPositions);
  const distance = length(lineStringGeo, { units: "feet" });
  if (!distance) {
    return 0;
  }

  return distance;
};

export const calculateArea = (positions: Position[]) => {
  if (!positions?.length) {
    return null;
  }

  const polygonFromPositions = polygon([positions]);
  if (!polygonFromPositions) {
    return null;
  }
  return squareMetersToAcres(area(polygonFromPositions), 2);
};

export const isPolygon = (latLngs: LatLng[]) => {
  if (!latLngs?.length) {
    return false;
  }

  return (
    latLngs.length >= 4 &&
    latLngs[0].lat === latLngs[latLngs.length - 1].lat &&
    latLngs[0].lng === latLngs[latLngs.length - 1].lng
  );
};

export const overrideMeasurementString = () => {
  return "";
};
