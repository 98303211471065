import { FC, useState, useMemo } from "react";

import { Wrapper, AllAttachmentWrapper, ItemWrapper } from "./AttachmentThumbnails.styled";
import { ReactComponent as SelectOpenIcon } from "./../../assets/images/select_open_icon.svg";
import { Thumbnail, FileAttachment } from "../Thumbnail";
import { Preview } from "../Preview";
import { AttachmentDto, AttachmentSize } from "../../types/responses/Attachments";
import { Loader } from "../Loader";
import { StyledFlex } from "../../assets/styles/flex.styled";

export type AttachmentThumbnailsProps = {
  attachments: FileAttachment[];
  showAllAttachments: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  sliceNumberOfElements?: Maybe<number>;
  onDelete?: (fileId: string) => void;
  getFile: (attachmentId: string, size: AttachmentSize) => Promise<Maybe<AttachmentDto>>;
};

export const AttachmentThumbnails: FC<AttachmentThumbnailsProps> = ({
  attachments,
  showAllAttachments = true,
  isLoading,
  onClick,
  sliceNumberOfElements,
  onDelete,
  getFile,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [initialFile, setInitialFile] = useState<Maybe<AttachmentDto>>();

  const onThumbnailClick = async (file: FileAttachment) => {
    const previewFile = await getFile(file.id, AttachmentSize.Preview);

    if (previewFile !== null) {
      setInitialFile(previewFile!);
      setSelectedIdx(attachments?.indexOf(file) || 0);
      setShowPreview(true);
    }
  };

  const files = useMemo(() => {
    if (!sliceNumberOfElements || !attachments?.length) {
      return attachments;
    }
    return attachments.slice(0, sliceNumberOfElements) || [];
  }, [attachments, sliceNumberOfElements]);

  return (
    <Wrapper>
      <ItemWrapper>
        {!isLoading &&
          files?.map((file) => (
            <Thumbnail key={file.id} file={file} onClick={() => onThumbnailClick(file)} onDelete={onDelete} />
          ))}
      </ItemWrapper>

      {!isLoading && showAllAttachments && sliceNumberOfElements && attachments?.length > sliceNumberOfElements && (
        <AllAttachmentWrapper onClick={onClick}>
          <span>All Attachments </span>
          <SelectOpenIcon />
        </AllAttachmentWrapper>
      )}
      {!isLoading && showPreview && attachments?.length > 0 && (
        <Preview
          files={attachments}
          showPreview={showPreview}
          closePreivew={() => setShowPreview(false)}
          initialFile={initialFile}
          onDelete={onDelete}
          getFile={getFile}
          selectedIdx={selectedIdx}
        />
      )}
      {isLoading && (
        <StyledFlex justifyContent="center">
          <Loader />
        </StyledFlex>
      )}
      {!isLoading && attachments?.length < 1 && (
        <StyledFlex justifyContent="center">
          <span>No attachments</span>
        </StyledFlex>
      )}
    </Wrapper>
  );
};
