import L, { LatLngBoundsExpression } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  useHierarchyStore,
  useMapSelectionStore,
  useMapStore,
  useSpansStore,
  useFeederStore,
  useThreatsStore,
} from "../../../stores";
import { AnimationData } from "../../../stores/MapStore";
import useStructuresStore from "../../../stores/StructuresStore";
import { compareObject } from "../../../utils/objectUtils";

type SELECTED_OBJECT_TYPE =
  | "STRUCTURE"
  | "ENCROACHMENT"
  | "SPAN"
  | "SPANS_BY_RISK_PRIORITY"
  | "FEEDER"
  | "REGION"
  | "COMMENT";

interface SelectedObject {
  id: Nullable<string>;
  type: SELECTED_OBJECT_TYPE;
  bounds?: Maybe<Nullable<LatLngBoundsExpression>>;
  point?: Nullable<number[]>;
}

const ANIMATION_VALID_TIME_DIFF = 5000;

const SPAN_ZOOM_LEVEL = 17;

export const useDynamicMapBounds = () => {
  const [selectedObject, setSelectedObject] = useState<Nullable<SelectedObject>>(null);
  const isPageLoading = useRef(true);
  const map = useMapStore((store) => store.map);
  const selectedSpanId = useSpansStore((store) => store.selectedId);
  const feedersData = useFeederStore((store) => store.feedersData);
  const selectedFeeder = useFeederStore((store) => store.selectedFeeder);
  const selectedEncroachment = useThreatsStore((store) => store.selectedThreat);
  const selectedStructureId = useStructuresStore((store) => store.selectedId);
  const getStructureById = useStructuresStore((store) => store.getStructureById);
  const structuresById = useStructuresStore((store) => store.structuresById);
  const encroachmentPositions = useThreatsStore((store) => store.threatsPosition);
  const spanRiskPriority = useHierarchyStore((store) => store.riskPrioritySelected);
  const regionsByRiskPriority = useHierarchyStore((store) => store.regionsByRiskPriority);
  const getLatestObjectSelectedForMapAnimation = useMapStore((store) => store.getLatestObjectSelectedForMapAnimation);
  const rootRegion = useHierarchyStore((store) => store.rootRegion);
  const isSelectedFrameHidden = useMapSelectionStore((store) => store.selectionFrameHidden);
  const selectedPolygon = useMapSelectionStore((store) => store.framePolygon);
  const selectedRegionId = useHierarchyStore((store) => store.currentRegion);
  const boundsByRegion = useHierarchyStore((store) => store.boundsByRegion);
  const regionPositions = useHierarchyStore((store) => store.regionPositions);
  const selectedRegion = useMemo(() => {
    return selectedFeeder ?? selectedRegionId;
  }, [selectedRegionId, selectedFeeder]);

  useEffect(() => {
    setTimeout(() => {
      isPageLoading.current = false;
    }, 2000);
  }, []);

  // Condition order matter. Most nested object first.
  const lastSelectedObject = useMemo(() => {
    if (selectedStructureId) {
      const coordinates = getStructureById(selectedStructureId)?.geometry?.coordinates;
      if (!coordinates) {
        return;
      }
      return {
        bounds: L.latLng(coordinates[1], coordinates[0]).toBounds(100),
        id: selectedStructureId,
        type: "STRUCTURE",
      };
    }

    if (selectedEncroachment) {
      return {
        bounds: encroachmentPositions?.[selectedEncroachment]?.bounds,
        id: selectedEncroachment,
        type: "ENCROACHMENT",
      };
    }

    if (!selectedSpanId && spanRiskPriority) {
      return {
        bounds: regionsByRiskPriority?.bounds,
        id: spanRiskPriority,
        type: "SPANS_BY_RISK_PRIORITY",
      };
    }

    if (selectedSpanId) {
      return {
        point: regionPositions?.[selectedSpanId!],
        id: selectedSpanId,
        type: "SPAN",
      };
    }

    if (selectedFeeder) {
      return {
        bounds: feedersData?.[selectedFeeder]?.feederBox,
        id: selectedFeeder,
        type: "FEEDER",
      };
    }

    if (selectedRegion) {
      return {
        bounds: boundsByRegion?.[selectedRegion]!,
        id: selectedRegion,
        type: "REGION",
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSpanId,
    selectedFeeder,
    selectedRegion,
    selectedEncroachment,
    spanRiskPriority,
    selectedStructureId,
    boundsByRegion,
    encroachmentPositions,
    regionPositions,
    regionsByRiskPriority,
    structuresById,
  ]);

  useEffect(() => {
    const isSpanSelected = lastSelectedObject?.type === "SPAN";
    const isLastSpanSelected = selectedObject?.type === "SPAN";
    const arePointsEqual = compareObject(selectedObject?.point, lastSelectedObject?.point);
    const areBoundsEqual = compareObject(selectedObject?.bounds, lastSelectedObject?.bounds);

    if (
      !lastSelectedObject ||
      (!isSpanSelected && !lastSelectedObject?.bounds) ||
      (isSpanSelected && !lastSelectedObject?.point)
    ) {
      return;
    }

    //if span is previously selected
    if (isSpanSelected && isLastSpanSelected && arePointsEqual) {
      return;
    }
    //if selected object which is not span
    if (!isSpanSelected && !isLastSpanSelected && areBoundsEqual) {
      return;
    }
    //Change the bounds only if they are not the same as the previous ones.
    setSelectedObject(lastSelectedObject as SelectedObject);
  }, [lastSelectedObject, selectedObject]);

  useEffect(() => {
    if (isSelectedFrameHidden || !selectedPolygon || !selectedPolygon?.bounds || !map) {
      return;
    }
    L.Util.requestAnimFrame(() => {
      selectedPolygon.bounds !== null && map.flyToBounds(selectedPolygon.bounds, { padding: [400, 10] });
    });
  }, [isSelectedFrameHidden, selectedPolygon, map]);

  useEffect(() => {
    if (
      !map ||
      (selectedObject?.type !== "SPAN" && !selectedObject?.bounds) ||
      (selectedObject?.type === "SPAN" && !selectedObject?.point)
    ) {
      return;
    }
    const latestObjectSelectedForMapAnimation = getLatestObjectSelectedForMapAnimation();
    // only animate if it is fresh click
    const isSelectedFromLeftMenu =
      latestObjectSelectedForMapAnimation?.id === selectedObject.id &&
      latestObjectSelectedForMapAnimation?.timestamp &&
      latestObjectSelectedForMapAnimation?.timestamp > Date.now() - ANIMATION_VALID_TIME_DIFF;

    if (!isPageLoading.current && !isSelectedFromLeftMenu && selectedObject.id !== rootRegion) {
      return;
    }

    L.Util.requestAnimFrame(() => {
      // stop in progress animations
      // on page load sometimes more animations are fired in small period of time
      if (isPageLoading.current) {
        map.setView(map.getCenter(), map.getZoom(), { animate: false });
      }
      map.closePopup();
      // fly to new bounds
      selectedObject?.type !== "SPAN" && map.flyToBounds(selectedObject?.bounds!, AnimationData);
      selectedObject?.type === "SPAN" && map.flyTo(selectedObject?.point as L.LatLngExpression, SPAN_ZOOM_LEVEL);
    });
  }, [map, selectedObject, getLatestObjectSelectedForMapAnimation, rootRegion]);
};
