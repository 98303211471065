import encroachmentsImage from "../assets/images/encroachments.png";
import dangerTreesImages from "../assets/images/danger_trees.png";
import hazardTreesImages from "../assets/images/vegetation_tree.png";
import temperatureImage from "../assets/images/temp_layer.svg";

import urbanAreaImage from "../assets/images/urban_areas_layer.svg";
import roadsImage from "../assets/images/roads_layer.svg";
import nonAccessImage from "../assets/images/non_access_roads_layer.svg";
import closedRoadsImage from "../assets/images/closed_roads_layer.svg";

import clearanceImage from "../assets/images/clearance.png";
import structureIcon from "../assets/images/structure_layer.png";
import maIcon from "../assets/images/ma_layer.png";
import feederLinesImage from "../assets/images/feeder_lines.png";
import parcelsImage from "../assets/images/parcel_layer.png";
import regions from "../assets/images/regions.png";

export type LayerRow = {
  name: string;
  checked: boolean;
  key: string;
  image?: string;
  additionalInfo?: string;
  backgroundColor?: string;
  showRangeButton?: boolean;
  initialRange?: number;
  disabled?: boolean;
};
export enum RISK_LAYERS {
  INFRINGEMENTS = "Infringements",
  DANGER_TREES = "Danger Trees",
  TREEconaissance = "TREEconaissance",
}

export const RISK_LAYERS_VALUES: LayerRow[] = [
  {
    name: RISK_LAYERS.INFRINGEMENTS,
    checked: false,
    key: RISK_LAYERS.INFRINGEMENTS,
    showRangeButton: true,
    initialRange: 0.8,
    image: encroachmentsImage,
  },
  {
    name: RISK_LAYERS.DANGER_TREES,
    checked: false,
    key: RISK_LAYERS.DANGER_TREES,
    disabled: true,
    image: dangerTreesImages,
  },
  {
    name: RISK_LAYERS.TREEconaissance,
    checked: false,
    key: RISK_LAYERS.TREEconaissance,
    additionalInfo: "SpaceAI",
    showRangeButton: true,
    initialRange: 0.8,
    image: hazardTreesImages,
  },
];

enum WEATHER_LAYERS {
  WIND = "Wind",
  TEMP = "Temp",
}

export const WEATHER_LAYERS_VALUES: LayerRow[] = [
  {
    name: "Wind",
    checked: false,
    key: WEATHER_LAYERS.WIND,
    disabled: true,
    image: temperatureImage,
  },
  {
    name: "Temp",
    checked: false,
    key: WEATHER_LAYERS.WIND,
    disabled: true,
    image: temperatureImage,
  },
];

export enum MAP_INFO_LAYERS {
  URBAN_AREAS = "Urban Areas",
  ROADS = "Roads",
  CLOSED_SYSTEM = "Closed System Roads",
  NON_ACCESS = "Non-System Access Roads",
}

export const MAP_INFO_LAYERS_VALUES: LayerRow[] = [
  {
    name: MAP_INFO_LAYERS.URBAN_AREAS,
    checked: false,
    key: MAP_INFO_LAYERS.URBAN_AREAS,

    image: urbanAreaImage,
  },
  {
    name: MAP_INFO_LAYERS.ROADS,
    checked: false,
    key: MAP_INFO_LAYERS.ROADS,
    image: roadsImage,
  },
  {
    name: MAP_INFO_LAYERS.CLOSED_SYSTEM,
    checked: false,
    key: MAP_INFO_LAYERS.CLOSED_SYSTEM,
    image: closedRoadsImage,
  },
  {
    name: MAP_INFO_LAYERS.NON_ACCESS,
    checked: false,
    key: MAP_INFO_LAYERS.NON_ACCESS,
    image: nonAccessImage,
  },
];

export enum CUSTOMER_ASSETS_LAYERS {
  FEEDERS = "Circuits",
  CLEARANCE = "Easement",
  PARCELS = "Parcels",
  STRUCTURES = "Structure Conditions",
  MAINTENANCE_AREA = "Maintenance Area",
  REGIONS = "Regions",
}

export const INITIAL_REGION_LAYERS = [
  { name: CUSTOMER_ASSETS_LAYERS.REGIONS, key: CUSTOMER_ASSETS_LAYERS.REGIONS, checked: true, image: regions },
];

export const INITIAL_FEEDER_LAYERS = [
  { name: CUSTOMER_ASSETS_LAYERS.FEEDERS, key: CUSTOMER_ASSETS_LAYERS.FEEDERS, checked: true, image: feederLinesImage },
  {
    name: CUSTOMER_ASSETS_LAYERS.CLEARANCE,
    key: CUSTOMER_ASSETS_LAYERS.CLEARANCE,
    checked: true,
    image: clearanceImage,
  },
  { name: CUSTOMER_ASSETS_LAYERS.PARCELS, key: CUSTOMER_ASSETS_LAYERS.PARCELS, checked: true, image: parcelsImage },
  {
    name: CUSTOMER_ASSETS_LAYERS.STRUCTURES,
    key: CUSTOMER_ASSETS_LAYERS.STRUCTURES,
    checked: false,
    image: structureIcon,
  },
  {
    name: CUSTOMER_ASSETS_LAYERS.MAINTENANCE_AREA,
    key: CUSTOMER_ASSETS_LAYERS.MAINTENANCE_AREA,
    checked: false,
    image: maIcon,
  },
];
