import styled from "styled-components";

import { RIGHT_DRAWER } from "../../constants/z-index";
import { LEFT_SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";

const ToggleSideBarStyled = styled.div<{ toggled: boolean }>`
  position: fixed;
  left: 0;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  z-index: ${RIGHT_DRAWER + 1};
  background: ${(props) => props.theme.colors.teal1};
  border-radius: 0 0.66em 0.66em 0px;
  height: 54px;
  width: 31px;
  left: ${(props) => (props.toggled ? `${LEFT_SIDEBAR_WIDTH}px` : `0px`)};
  &:hover {
    background: ${(props) => props.theme.colors.teal2};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${TOP_HEADER_HEIGHT + 10}px;
`;

const ArrowImg = styled.img`
  margin: auto auto;
`;

export { ToggleSideBarStyled, ArrowImg };
