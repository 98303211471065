import { FC } from "react";

import { CommentDto } from "../../types/responses";
import { Comment } from "./Comment";
import { Wrapper } from "./Comments.styled";

type CommentsProps = {
  comments: Nullable<CommentDto[]>;
  reloadComments: () => void;
  selectComment: (id: string) => void;
  selectedCommentId: Nullable<string>;
  showReadButton?: boolean;
  showResolvedButton?: boolean;
  hoverDisabled?: boolean;
  showReplies?: boolean;
};

export const Comments: FC<CommentsProps> = ({
  comments,
  reloadComments,
  selectComment,
  selectedCommentId,
  showReadButton = true,
  showResolvedButton = true,
  hoverDisabled = false,
  showReplies = true,
}) => {
  if (!comments?.length) {
    return <></>;
  }
  return (
    <Wrapper>
      {comments.map((comment) => {
        return (
          <Comment
            hoverDisabled={hoverDisabled}
            showReadStatus={showReadButton}
            showStatus={showResolvedButton}
            selectedCommentId={selectedCommentId}
            selectComment={selectComment}
            key={comment.id}
            comment={comment}
            reloadComments={reloadComments}
            showReplies={showReplies}
          />
        );
      })}
    </Wrapper>
  );
};
