import styled from "styled-components";
import { ColorProps, color } from "styled-system";

interface ChipsProps extends ColorProps {
  selected: boolean;
  hasItems?: boolean;
}

export const ChipsStyled = styled.div<ChipsProps>`
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#0C8893" : props.theme.colors.white)};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.trout2)};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  width: 41px;
  height: 26px;
  padding: 6px 11px 6px 11px;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid #008e9a;
    box-sizing: border-box;
  }
  & span:last-child {
    position: relative;
    top: -1px;
  }
  position: relative;
`;

export const SpanPriorityCircle = styled.span<ColorProps>`
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  ${color}
`;
