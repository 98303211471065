import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e6e6e6;
`;

export const ParcelItemsContainer = styled.div`
  display: flex;
  padding: 11px 19px;
  justify-content: space-between;
  border-bottom: 1px solid var(--colors-gallery);
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ParcelItem = styled.span`
  font-weight: 600;
  color: var(--colors-river-bad);
  font-size: 13px;
`;

export const ParcelStatus = styled(ParcelItem)`
  margin-right: 12px;
  font-size: 13px;
`;
