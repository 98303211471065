import { useEffect } from "react";
import { useCustomQuery } from ".";

import { getMaintenanceAreasApiUrl } from "../api";

import { MIN_ZOOM_LEVEL_FOR_MA_LAYER } from "../pages/MapScreen/MapView/MapView.constants";
import { useMaintenanceAreaStore, useMapStore, useMapSelectionStore } from "../stores";
import { GetMaintenanceAreasByExtentResponseDto } from "../types/responses";
import { useExtentParams } from "./useExtentParams";

export const useMaintenanceArea = () => {
  const requestParams = useExtentParams();

  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);

  const setMaintenanceAreas = useMaintenanceAreaStore((store) => store.setData);
  const isMaintenanceAreasVisible = useMaintenanceAreaStore((store) => store.isLayerVisible);
  const hidden = useMapSelectionStore((store) => store.hidden);

  const { data: maintenanceAreaResponse } = useCustomQuery<GetMaintenanceAreasByExtentResponseDto>(
    requestParams !== null ? getMaintenanceAreasApiUrl(requestParams) : "/maintenance-area",
    {
      enabled:
        (requestParams != null && isMaintenanceAreasVisible && currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_MA_LAYER) ||
        !hidden,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  useEffect(() => {
    if (!maintenanceAreaResponse) {
      return;
    }

    setMaintenanceAreas(maintenanceAreaResponse);
  }, [maintenanceAreaResponse, setMaintenanceAreas]);
};
