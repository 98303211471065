import { roundValue, unitConverter } from "../../utils/unitHelper";
import {
  TREE_CANOPY_LAYER_STYLE,
  TREE_CANOPY_LAYER_STYLE_SELECTED,
  TREE_CANOPY_LEGEND,
} from "./MapTreeCanopyLayer.constants";

export const getTreeCanopyStyle = (
  treeHeight: Maybe<Nullable<number>>,
  canopyId: string,
  selectedCanopyPolygonId: Nullable<string>,
  opacity: number
) => {
  treeHeight = treeHeight ? roundValue(unitConverter(true, treeHeight), 0) : 0;
  let style = Object.assign(TREE_CANOPY_LAYER_STYLE);
  if (selectedCanopyPolygonId && selectedCanopyPolygonId === canopyId) {
    style = Object.assign(TREE_CANOPY_LAYER_STYLE_SELECTED);
  }
  if (treeHeight <= TREE_CANOPY_LEGEND["LOW1"].max && treeHeight >= TREE_CANOPY_LEGEND["LOW1"].min) {
    style.fillColor = TREE_CANOPY_LEGEND["LOW1"].color;
  } else if (treeHeight <= TREE_CANOPY_LEGEND["LOW2"].max && treeHeight > TREE_CANOPY_LEGEND["LOW2"].min) {
    style.fillColor = TREE_CANOPY_LEGEND["LOW2"].color;
  } else if (treeHeight <= TREE_CANOPY_LEGEND["MEDIUM"].max && treeHeight > TREE_CANOPY_LEGEND["MEDIUM"].min) {
    style.fillColor = TREE_CANOPY_LEGEND["MEDIUM"].color;
  } else if (treeHeight <= TREE_CANOPY_LEGEND["HIGH1"].max && treeHeight > TREE_CANOPY_LEGEND["HIGH1"].min) {
    style.fillColor = TREE_CANOPY_LEGEND["HIGH1"].color;
  } else if (treeHeight <= TREE_CANOPY_LEGEND["HIGH2"].max && treeHeight > TREE_CANOPY_LEGEND["HIGH2"].min) {
    style.fillColor = TREE_CANOPY_LEGEND["HIGH2"].color;
  }
  style.opacity = style.fillOpacity = opacity;
  return style;
};
