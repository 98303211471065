import React, { FC } from "react";

import { TreeSpeciesImg, TreeSpeciesItemWrapper, TreeSpeciesName, TreeSpeciesWrapper } from "./TreeSpecies.styled";
import { getTreeSpeciesImageByName } from "../../../pages/MapScreen/MapView/MapView.utils";

interface TreeSpeciesProperties {
  species: string[];
}
export const TreeSpecies: FC<TreeSpeciesProperties> = ({ species }) => {
  if (!species?.length) {
    return <> </>;
  }
  return (
    <TreeSpeciesWrapper>
      {species.map((item) => (
        <TreeSpeciesItemWrapper key={item}>
          <TreeSpeciesImg src={getTreeSpeciesImageByName(item)} />
          <TreeSpeciesName>{item}</TreeSpeciesName>
        </TreeSpeciesItemWrapper>
      ))}
    </TreeSpeciesWrapper>
  );
};
