import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { updateAttachmentDescriptionUrl } from "../api";

export const useUpdateAttachmentDescription = () => {
  return useMutation(({ description, entityId }: { description: string; entityId: string }) =>
    ApiClient.put(updateAttachmentDescriptionUrl(entityId), { description: description })
  );
};
