import { FC } from "react";
import { Redirect, Route, Switch } from "react-router";

import { BrandPageLayout } from "../../components/BrandPageLayout";
import {
  Content,
  Navigation,
  Layout,
  NavigationTitle,
  NavigationSection,
  NavigationSectionTitle,
  NavigationSectionItem,
  StyledLink,
} from "./Admin.styled";
import { Elevation } from "./subpages/Elevation";
import { VoltageConfiguration } from "./subpages/VoltageConfiguration";
import {
  getAdminCrewConfigurationRoute,
  getAdminCrewTypeConfigurationRoute,
  getAdminElevationRoute,
  getAdminFacilitiesRoute,
  getAdminRoute,
  getAdminVoltageConfigurationRoute,
  getAdminWorkTypeRatesRoute,
} from "../../routes";
import { Facilities } from "./subpages/Facilties";
import { WorkTypeRates } from "./subpages/WorkTypeRates";
import { CrewConfiguration } from "./subpages/CrewConfiguration";
import { CrewTypeConfigurations } from "./subpages/CrewTypeConfiguration/CrewTypeConfigurations";

export const Admin: FC = () => {
  return (
    <BrandPageLayout justifyContent="flex-start" alignItems="flex-start">
      <Layout>
        <Navigation>
          <NavigationTitle>Configuration</NavigationTitle>
          <NavigationSection>
            <NavigationSectionTitle>Voltage</NavigationSectionTitle>
            <NavigationSectionItem>
              <StyledLink to={getAdminVoltageConfigurationRoute()}>Voltage configuration</StyledLink>
            </NavigationSectionItem>
            <NavigationSectionItem>
              <StyledLink to={getAdminElevationRoute()}>Elevation</StyledLink>
            </NavigationSectionItem>
            <NavigationSectionItem>
              <StyledLink to={getAdminFacilitiesRoute()}>Structures configuration</StyledLink>
            </NavigationSectionItem>
            <NavigationSectionItem>
              <StyledLink to={getAdminWorkTypeRatesRoute()}>Intervention Costs</StyledLink>
            </NavigationSectionItem>
            <NavigationSectionItem>
              <StyledLink to={getAdminCrewConfigurationRoute()}>Crew Configuration</StyledLink>
            </NavigationSectionItem>
            <NavigationSectionItem>
              <StyledLink to={getAdminCrewTypeConfigurationRoute()}>Crew Type Configuration</StyledLink>
            </NavigationSectionItem>
          </NavigationSection>
        </Navigation>
        <Content>
          <Switch>
            <Route path={getAdminVoltageConfigurationRoute()} component={VoltageConfiguration} />
            <Route path={getAdminElevationRoute()} component={Elevation} />
            <Route path={getAdminFacilitiesRoute()} component={Facilities} />
            <Route path={getAdminWorkTypeRatesRoute()} component={WorkTypeRates} />
            <Route path={getAdminCrewConfigurationRoute()} component={CrewConfiguration} />
            <Route path={getAdminCrewTypeConfigurationRoute()} component={CrewTypeConfigurations} />
            <Redirect to={getAdminRoute()} />
          </Switch>
        </Content>
      </Layout>
    </BrandPageLayout>
  );
};
