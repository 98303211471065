import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Cell, Column, Row, TableCommonProps } from "react-table";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import StateManager from "react-select";
import cloneDeep from "lodash.clonedeep";

import { SubPageWrapper, HeaderText, WorkTypeRatesWrapper } from "./SubPages.styled";
import { useCustomQuery } from "../../../hooks";
import { getWorkTypesRatesApiUrl, getAdminWorkTypesApiUrl } from "../../../api";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { Toast } from "../../../components/Toast";
import { SOMETHING_WENT_WRONG_MESSAGE, NUMBER_ERROR, EMPTY_FN, ONLY_NUM_AND_LETTERS } from "../../../constants";
import ApiClient from "../../../utils/apiClient";
import { CustomColumn, Table } from "../../../components/Table";
import { ModalDialog } from "../../../components/ModalDialog";
import { Button } from "../../../components/Button";
import { logError } from "../../../services";
import useAdminLoadingStore, { LoadingState } from "../../../stores/AdminLoadingStore";
import { useMessageStore } from "../../../stores";
import { checkError, getBulkRequestBodyForWorkTypes } from "./SubPages.util";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";
import { WorkTypeRatesResponse } from "../../../types/responses";
import { StyledFlex } from "../../../assets/styles/flex.styled";
import { ControlledFooterCell } from "../../../components/Table/FooterCell/ControlledFooterCell";
import { StyledText } from "../../../assets/styles/text.styled";
import { Spacer } from "../../../assets/styles/spacer.styled";
import { FooterSelect } from "../../../components/Table/FooterSelect/FooterSelect";
import { toUsdCurrency } from "../../../utils/stringUtils";

import DefaultImage from "./../../../assets/images/default_yellow_flag.svg";
import { useUserConfirmation } from "../../../hooks/useUserConfirmation";
import { ONLY_NUMBER_AND_LETTERS } from "../../../constants/regex";

export type WorkTypeColumnType = Record<string, string | string[]>;

type WorkUnitsRatesColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

type AddWorkType = {
  name: string;
  unit: {
    label: string;
    value: string;
  };
};

export type UpdateWorkTypeRateFieldType = { regionId: string; workTypeId: string; rate: number };

const INITIAL_ADD_WORK_TYPE_FORM_VALUES = { name: "", unit: { label: "", value: "" } };

const WORK_TYPE_UNIT_OPTIONS = [
  { label: "$ / Tree", value: "DOLLAR_PER_TREE" },
  { label: "$ / Acre", value: "DOLLAR_PER_ACRE" },
];

const WORK_TYPE_COLUMN = "Work type";

const UNIT_COLUMN = "Unit";

const validationParams = {
  value: Yup.number().typeError(NUMBER_ERROR).required().positive().min(0),
};

export const WorkTypeRates: FC = () => {
  const selectInputRef = useRef<Nullable<StateManager>>(null);
  const setLoadingState = useAdminLoadingStore((store) => store.setLoadingState);
  const setErrorMessage = useMessageStore((store) => store.setErrorMessage);
  const resetLoadingStore = useAdminLoadingStore((store) => store.resetStore);
  const [editableTableData, setTableEditableTableData] = useState<Nullable<WorkTypeColumnType[]>>(null);
  const [regionsByName, setRegions] = useState<Nullable<Record<string, string>>>(null);
  const {
    confirmUserConsent: confirmAddWorkType,
    onConfirm: onWorkTypeAddConfirm,
    onReject: onWorkTypeAddReject,
    waitingForConfirmation: workTypeAddWaitingForConfirmation,
  } = useUserConfirmation();
  const {
    confirmUserConsent: confirmWorkTypeEdit,
    onConfirm: onWorkTypeEditConfirm,
    onReject: onWorkTypeEditReject,
    waitingForConfirmation: workTypeEditWaitingForConfirmation,
  } = useUserConfirmation();
  const [isTableEditable, setTableEditable] = useState(false);

  useEffect(() => {
    resetLoadingStore();
  }, [resetLoadingStore]);

  const {
    isLoading: loading,
    isError: hasError,
    data: workUnitsRatesResponse,
    refetch: refetchWorkUnitsRates,
  } = useCustomQuery<WorkTypeRatesResponse>(getWorkTypesRatesApiUrl());

  const onRequestMutate = useCallback(() => {
    setLoadingState(LoadingState.Loading);
    setErrorMessage(null);
  }, [setLoadingState, setErrorMessage]);

  const onRequestError = useCallback(
    (error) => {
      setLoadingState(LoadingState.Error);
      let message = SOMETHING_WENT_WRONG_MESSAGE;
      if (checkError(error)) {
        message = error.response?.data?.message;
      }
      setErrorMessage(message);
    },
    [setLoadingState, setErrorMessage]
  );

  const onRequestSuccess = useCallback(() => {
    setLoadingState(LoadingState.Success);
  }, [setLoadingState]);

  const addNewWorkUnit = useMutation(
    (newValue: AddWorkType) =>
      ApiClient.post(getAdminWorkTypesApiUrl(), { name: newValue.name, unit: newValue.unit.value }),
    {
      onMutate: onRequestMutate,
      onSuccess: onRequestSuccess,
      onError: onRequestError,
    }
  );

  const updateWorkTypeRates = useMutation(
    (newValues: { workUnits: UpdateWorkTypeRateFieldType[] }) => ApiClient.put(getWorkTypesRatesApiUrl(), newValues),
    { onMutate: onRequestMutate, onSuccess: onRequestSuccess, onError: onRequestError }
  );

  const addWorkTypeField = useCallback(
    (data: AddWorkType, { resetForm }): Promise<LoadingState> => {
      return confirmAddWorkType()
        .then(() => addNewWorkUnit.mutateAsync(data))
        .then(() => {
          refetchWorkUnitsRates();
          resetForm();
          selectInputRef.current?.select?.clearValue();
          return Promise.resolve(LoadingState.Success);
        })
        .catch((error) => {
          logError(error);
          return Promise.resolve(LoadingState.Error);
        });
    },
    [addNewWorkUnit, refetchWorkUnitsRates, confirmAddWorkType]
  );

  const originalTableData: WorkTypeColumnType[] = useMemo(() => {
    if (!workUnitsRatesResponse) {
      return [] as WorkTypeColumnType[];
    }

    const regionsByName: Record<string, string> = {};
    workUnitsRatesResponse.regions?.forEach((region) => {
      regionsByName[region.name] = region.id;
    });
    setRegions(regionsByName);
    const data = workUnitsRatesResponse.rows.map((item) => ({
      id: item.workType.id,
      type: item.workType.name,
      ...(item.workType.defaultFlag ? { unremovableItem: "unremovableItem" } : {}),
      unit: item.workType.unit,
      ...item.rates.reduce((prev, curr, index) => {
        prev[workUnitsRatesResponse.regions[index]?.name] = curr ? String(curr) : "0";

        return prev;
      }, {} as WorkTypeColumnType),
      dirtyFields: [] as string[],
    }));
    return data;
  }, [workUnitsRatesResponse, setRegions]);

  const resetTableData = useCallback(() => {
    setTableEditableTableData(cloneDeep(originalTableData));
  }, [setTableEditableTableData, originalTableData]);

  useEffect(() => resetTableData(), [resetTableData]);

  const updateWorkTypes = useCallback(() => {
    confirmWorkTypeEdit()
      .then(() => updateWorkTypeRates.mutateAsync(getBulkRequestBodyForWorkTypes(editableTableData, regionsByName)))
      .then(() => {
        setTableEditable(false);
        refetchWorkUnitsRates();
      })
      .catch(logError);
  }, [refetchWorkUnitsRates, editableTableData, regionsByName, confirmWorkTypeEdit, updateWorkTypeRates]);

  const updateField = useCallback(
    (row: Row<WorkTypeColumnType>, column: Column, value: string): Promise<LoadingState> => {
      const tableData = cloneDeep(editableTableData);
      const entry = tableData?.find((item) => item.id === row.original.id);
      if (!entry) {
        return Promise.resolve(LoadingState.Error);
      }
      if (Array.isArray(entry.dirtyFields) && !entry.dirtyFields.includes(column.id!)) {
        entry.dirtyFields.push(column.id!);
      }
      entry[column?.id as string] = value;
      setTableEditableTableData(tableData);
      return Promise.resolve(LoadingState.Success);
    },
    [setTableEditableTableData, editableTableData]
  );

  const existingWorkTypeNames = useMemo(
    () => workUnitsRatesResponse?.rows.map((item) => item.workType.name) || [],
    [workUnitsRatesResponse]
  );

  const uniqueWorkTypeNameValidation = useMemo(
    () =>
      Yup.string()
        .max(23, "Maximum 23 characters allowed.")
        .test("Work type name", "Please enter unique value.", (value) => !existingWorkTypeNames.includes(value!))
        .test("withoutSpecChars", ONLY_NUM_AND_LETTERS, (value) =>
          value ? ONLY_NUMBER_AND_LETTERS.test(value.toString()) : true
        )
        .required(),
    [existingWorkTypeNames]
  );

  const columns: WorkUnitsRatesColumns<WorkTypeColumnType>[] = useMemo(() => {
    const staticColumns = [
      {
        accessor: "type",
        canRemove: false,
        editable: false,
        Header: WORK_TYPE_COLUMN,
        minWidth: 219,
        type: "string",
        className: "sticky-work-type",
        unremovableItemImage: DefaultImage,
        validationParams: {
          value: uniqueWorkTypeNameValidation,
        },
      },
      {
        accessor: "unit",
        canRemove: false,
        editable: false,
        Header: UNIT_COLUMN,
        className: "sticky-unit",
        minWidth: 119,
        type: "string",
        validationParams: {
          value: Yup.string().required(),
        },
        Cell: ({ row }: Cell<WorkTypeColumnType>) => (
          <StyledText fontSize={14} fontWeight={500} color="var(--colors-trout-2)">
            {WORK_TYPE_UNIT_OPTIONS.find((item) => item.value === row.original.unit)?.label || ""}
          </StyledText>
        ),
      },
    ];

    if (!workUnitsRatesResponse?.regions || !workUnitsRatesResponse?.regions.length) {
      return staticColumns;
    }

    const dynamicColumns =
      workUnitsRatesResponse?.regions.map((item) => ({
        accessor: item.name,
        canRemove: false,
        editable: isTableEditable,
        formatInactive: (value: string) => toUsdCurrency(value, 2),
        Header: item.name,
        minWidth: 119,
        type: "string",
      })) || [];

    return [...staticColumns, ...dynamicColumns];
  }, [workUnitsRatesResponse, uniqueWorkTypeNameValidation, isTableEditable]);

  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!loading} />
      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>
      <ModalDialog
        hidden={!workTypeEditWaitingForConfirmation}
        message={"Are you sure you want to update intervention cost?"}
        onClose={() => {
          onWorkTypeEditReject();
          resetTableData();
        }}
        title="Edit work type"
        warning
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <Button
              variant="cancel"
              size="small"
              onClick={() => {
                onWorkTypeEditReject();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" size="small" onClick={onWorkTypeEditConfirm}>
              Save
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </ModalDialog>
      <StyledFlex justifyContent="space-between">
        <HeaderText>Intervention Costs</HeaderText>
        <StyledFlex justifyContent={"flex-end"} alignItems={"center"} gap="7px">
          {!isTableEditable && (
            <Button
              variant="primary"
              size="small"
              onClick={() => setTableEditable(!isTableEditable)}
              paddingLeft={28}
              paddingRight={28}
              fontWeight={500}
              fontSize={14}
            >
              Edit
            </Button>
          )}
          {isTableEditable && (
            <>
              <Button
                variant="cancel"
                size="small"
                onClick={() => {
                  setTableEditable(!isTableEditable);
                  resetTableData();
                }}
                fontWeight={500}
                fontSize={14}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="small"
                onClick={updateWorkTypes}
                fontWeight={500}
                fontSize={14}
                height={32}
              >
                Save
              </Button>
            </>
          )}
          <Spacer marginRight={"28px"} />
        </StyledFlex>
      </StyledFlex>
      {!loading && !hasError && (
        <WorkTypeRatesWrapper>
          <Table<WorkTypeColumnType>
            columns={columns}
            data={editableTableData}
            displayColumnFooter={false}
            updateField={updateField}
            validationParams={validationParams}
            stripes={true}
          >
            <Formik
              enableReinitialize={true}
              initialValues={INITIAL_ADD_WORK_TYPE_FORM_VALUES}
              onSubmit={addWorkTypeField}
              validationSchema={Yup.object().shape({
                name: uniqueWorkTypeNameValidation,
                unit: Yup.object().shape({
                  label: Yup.string().required(),
                  value: Yup.string().required(),
                }),
              })}
            >
              {({ isValid, handleSubmit, dirty, values }) => (
                <>
                  <ModalDialog
                    hidden={!workTypeAddWaitingForConfirmation}
                    message={
                      <>
                        Are you sure you want to add{" "}
                        <StyledText color="var(--colors-eastern-blue)">{values.name}</StyledText> in{" "}
                        <StyledText color="var(--colors-eastern-blue)">{values.unit.label}</StyledText>. New work types
                        cannot be deleted.
                      </>
                    }
                    onClose={onWorkTypeAddReject}
                    title="Add work type"
                    warning
                  >
                    <ButtonsWrapper>
                      <ButtonWrapper>
                        <Button variant="cancel" size="small" onClick={onWorkTypeAddReject}>
                          Cancel
                        </Button>
                        <Button variant="primary" size="small" onClick={onWorkTypeAddConfirm}>
                          Save
                        </Button>
                      </ButtonWrapper>
                    </ButtonsWrapper>
                  </ModalDialog>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    // @ts-ignore
                    autocomplete="off"
                  >
                    <StyledFlex alignItems="center">
                      <Spacer ml={"10px"} />
                      <StyledText fontWeight={500} fontSize={14}>
                        Add:
                      </StyledText>
                      <ControlledFooterCell
                        name="name"
                        placeholder="Work type name"
                        style={{ width: "320px" }}
                        type={"string"}
                        width={250}
                      />
                      <FooterSelect
                        isDisabled={false}
                        name="unit"
                        onChange={EMPTY_FN}
                        options={WORK_TYPE_UNIT_OPTIONS}
                        innerRef={selectInputRef}
                        width={160}
                        placeholder="Select unit cost"
                      />
                      <Spacer pr={"6px"} />
                      <Button
                        disabled={!isValid || !dirty}
                        height="30px"
                        paddingBottom="0px"
                        paddingLeft="20px"
                        paddingRight="20px"
                        paddingTop="0px"
                        type="submit"
                        variant="primary"
                      >
                        Save
                      </Button>
                    </StyledFlex>
                  </Form>
                </>
              )}
            </Formik>
          </Table>
        </WorkTypeRatesWrapper>
      )}
    </SubPageWrapper>
  );
};
