import { FC, useRef, useState } from "react";
import { useClickOutside } from "../../hooks";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { AddRoleText, RoleItem, RoleWrapper } from "./AddRole.styled";

interface AddRoleProps {
  roles: { name: string; id: string }[];
  saveRole: (role: { name: string; id: string }) => void;
  editable: boolean;
  selectedRoles?: string[];
}
export const AddRole: FC<AddRoleProps> = ({ roles, saveRole, editable, selectedRoles }) => {
  const wrapperRef = useRef(null);
  const [popupOpened, openPopup] = useState(false);
  useClickOutside(wrapperRef, () => popupOpened && openPopup(false));

  return (
    <>
      <AddRoleText editable={editable} onClick={() => editable && openPopup(!popupOpened)}>
        +Add Role
      </AddRoleText>
      {popupOpened && (
        <RoleWrapper ref={wrapperRef}>
          {roles &&
            roles
              .filter((role) => !selectedRoles?.length || selectedRoles?.find((id) => id !== role.id))
              .map((role) => (
                <RoleItem
                  key={role.id}
                  onClick={() => {
                    if (!editable) {
                      return;
                    }
                    saveRole(role);
                    openPopup(false);
                  }}
                  disabled={selectedRoles?.some((item) => item === role.id)}
                >
                  {capitalizeFirstLetter(role.name)}
                </RoleItem>
              ))}
        </RoleWrapper>
      )}
    </>
  );
};
