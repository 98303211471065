import styled, { css } from "styled-components";
import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";

export const MapSelectionPopupWrapper = styled.div`
  z-index: ${CUT_PLAN_Z_INDEX + 1};
  width: 244px;
  height: 54px;
  background: rgba(0, 0, 0, 0.69);
  border-radius: 62px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  user-select: none;
`;

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
  width: 35px;
  height: 35px;
  border: 1px solid #494848;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  :hover {
    border: 1px solid var(--colors-eastern-blue);
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--colors-eastern-blue);
      border: 1px solid var(--colors-eastern-blue);
      path {
        fill: var(--colors-white);
      }
    `}
`;
