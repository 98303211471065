import React, { FC } from "react";

import { ItemsRowWrapper, ItemsRow, AddNewLabel } from "./RowsWrapper.styled";

export const Rows: FC<{ rows: number }> = ({ rows }) => (
  <ItemsRowWrapper>
    <ItemsRow>{`${rows} items`}</ItemsRow>
    <AddNewLabel>Add new:</AddNewLabel>
  </ItemsRowWrapper>
);
