import React, { FC } from "react";
import { PaddingProps } from "styled-system";
import { SwitchButtonStyled, SwitchInput, SwitchLabel, SwitchWrapper } from "./SwitchButton.styled";

import "./SwitchButton.styled";
interface SwitchButtonProps extends PaddingProps {
  defaultChecked: boolean;
  id: string;
  name: string;
  onClick: () => void;
  checked: boolean;
  disabled?: boolean;
}
export const SwitchButton: FC<SwitchButtonProps> = ({
  defaultChecked,
  id,
  name,
  onClick,
  checked,
  disabled = false,
  ...props
}) => {
  return (
    <SwitchWrapper {...props}>
      <SwitchInput
        checked={checked}
        name={name}
        defaultChecked={defaultChecked}
        id={id}
        type="checkbox"
        onClick={onClick}
        disabled={disabled}
      />
      <SwitchLabel htmlFor={id} toggled={checked}>
        <SwitchButtonStyled disabled={disabled} toggled={checked} />
      </SwitchLabel>
    </SwitchWrapper>
  );
};
