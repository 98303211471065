import { HOVERED_OBJECT_CLASS_PREFIX } from "../../pages/MapScreen/MapView/MapView.constants";
import { FEEDER_STYLE, FEEDER_STYLE_SELECTED } from "./MapFeeders.constants";

export const getFeederStyle = (
  item: GeoJSON.Feature<GeoJSON.Geometry, { id: string }>,
  selectedFeeder: Nullable<string>
) => {
  if (selectedFeeder && item.properties.id === selectedFeeder) {
    return { className: `${HOVERED_OBJECT_CLASS_PREFIX}${item.properties.id} map-feeder`, ...FEEDER_STYLE_SELECTED };
  }

  return { className: `${HOVERED_OBJECT_CLASS_PREFIX}${item.properties.id} map-feeder`, ...FEEDER_STYLE };
};
