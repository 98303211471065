import styled from "styled-components";

export const TableFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemsRow = styled.div`
  margin-left: 10px;
`;

export const AddNewLabel = styled.div`
  margin-right: 12px;
`;

export const ItemsRowWrapper = styled.div`
  flex: 25%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: ${(props) => props.theme.colors.manatee};
`;
