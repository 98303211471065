import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  div {
    text-align: center;
  }
`;

export const LabelContent = styled.div`
  color: var(--colors-trout-2);
`;

export const AdditionalLabelContent = styled.div`
  color: var(--colors-black); ;
`;
