import "leaflet-draw/dist/leaflet.draw.css";

import { Position } from "@turf/turf";
import L, { LatLng } from "leaflet";
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import { MapMeasurePopup } from "../../../components/MapMeasurePopup";
import {
  calculateArea,
  distanceFromPointToPoint,
  eventHandlers,
  isPolygon,
  lineStringLength,
} from "./MapMeasureTool.utils";
import { useMapMeasureStore } from "../../../stores";
import "./MapMeasureTool.css";
import { useCustomMapPane } from "../../../hooks";
import { MEASURE_TOOL_MARKER_LAYER } from "../../../constants";
import { getDistanceUnit, getReadableDistance } from "../../../utils/unitHelper";
interface MapMeasureToolProps {
  map: Nullable<L.Map>;
  rightMenuEnabled?: boolean;
  layerMenuEnabled?: boolean;
  leftMenuEnabled?: boolean;
}

const SHAPE_OPTIONS = {
  color: "var(--colors-broom)",
  weight: 3,
  opacity: 0.7,
  fillColor: "transparent",
};
const DRAW_CONTROL_OPTIONS = {
  shapeOptions: SHAPE_OPTIONS,
  metric: false,
  feet: false,
  zIndexOffset: 1200,
};

const icon = L.divIcon({
  iconSize: [20, 20],
  iconAnchor: [12, 12],
  html: `<span></span>`,
  className: "empty-marker",
});

const POLYLINE_LAYER_TYPE = "polyline";

const POLYGON_LAYER_TYPE = "polygon";

const PANE_NAME = "MARKER_PANE_WITH_OFFSET";

type Layers = Record<
  string,
  { _latlng: LatLng; on: (type: string, handler: () => void) => void; off: (type: string) => void }
>;

export type PolylineLayer = {
  enable: () => void;
  disable: () => void;
  _finishShape: () => void;
  _getMeasurementString: () => string;
  _currentLatLng: Nullable<LatLng>;
  _markers: Nullable<L.Marker[]>;
  removeAllLayers: () => void;
  addVertex: (latLng: LatLng) => void;
  initialize: () => void;
  deleteLastVertex: () => void;
};

interface DrawLeafletEvent extends L.LeafletEvent {
  layers: { _layers: Layers };
}

export const MapMeasureTool: FC<MapMeasureToolProps> = memo(
  ({ map, rightMenuEnabled, leftMenuEnabled, layerMenuEnabled }) => {
    const distance = useMapMeasureStore((store) => store.distance);
    const setDistance = useMapMeasureStore((store) => store.setDistance);
    const area = useMapMeasureStore((store) => store.area);
    const setArea = useMapMeasureStore((store) => store.setArea);
    const resetState = useMapMeasureStore((store) => store.resetState);
    const hidden = useMapMeasureStore((store) => store.hidden);
    const toggle = useMapMeasureStore((store) => store.toggle);
    const [layerType, setLayerType] = useState<Nullable<string>>(null);
    const [layer, setLayer] = useState<Nullable<PolylineLayer>>(null);

    const [editControlRef, setEditControlRef] = useState<Nullable<L.Control>>(null);
    const [featureGroupRef, setFeatureGroupRef] = useState<Nullable<L.FeatureGroup<any>>>(null);

    useEffect(() => {
      L.Edit.PolyVerticesEdit = L.Edit.PolyVerticesEdit.extend({
        _removeMarker: () => {
          return;
        },
        //override methods in order to stop adding a new points
        _createMiddleMarker: () => {
          return;
        },
        _updatePrevNext: () => {
          return;
        },
      });
    }, []);

    useCustomMapPane({
      map,
      name: PANE_NAME,
      zIndex: MEASURE_TOOL_MARKER_LAYER,
    });

    const formattedDistance = useMemo(() => {
      if (distance === 0) {
        return null;
      }
      return getReadableDistance(distance);
    }, [distance]);

    const distanceUnit = useMemo(() => {
      if (distance === 0) {
        return null;
      }
      return getDistanceUnit(distance);
    }, [distance]);

    useEffect(() => {
      if (!editControlRef || !map) {
        return;
      }

      return () => {
        map.removeControl(editControlRef);
        editControlRef.remove();
      };
    }, [map, editControlRef]);

    const clearMapMarkerPane = useCallback(() => {
      if (!map) {
        return;
      }
      map.getPane(PANE_NAME)?.firstElementChild?.remove();
    }, [map]);

    const clearAllLayers = useCallback(() => {
      if (!featureGroupRef) {
        return;
      }
      featureGroupRef.clearLayers();
    }, [featureGroupRef]);

    const onCreated = useCallback(
      (e) => {
        const { layer, layerType } = e;
        const { _latlngs } = layer;

        setLayerType(layerType);
        if (layerType === POLYLINE_LAYER_TYPE && isPolygon(_latlngs)) {
          clearAllLayers();
          const newPositions: LatLng[] = Object.assign([], _latlngs);
          const polygonLayer = L.polygon(newPositions, SHAPE_OPTIONS);
          setArea(calculateArea(_latlngs.map((latLng: LatLng) => [latLng.lng, latLng.lat])));
          map?.fire(eventHandlers.onCreated, { layerType: POLYGON_LAYER_TYPE, layer: polygonLayer });
          setArea(calculateArea(newPositions.map((latLng: LatLng) => [latLng.lng, latLng.lat])));
          return;
        }
        clearMapMarkerPane();
        layer.editing.enable();
      },
      [setArea, map, clearAllLayers, clearMapMarkerPane]
    );

    const onDrawVertex = useCallback(
      (e) => {
        const event = e as DrawLeafletEvent;
        const length = Object.keys(event.layers._layers).length;

        //finish shape when user clicks on first point (as polygon)
        if (length === 1 && layer?._markers?.length === 1) {
          Object.keys(event.layers._layers).forEach((key: string) => {
            const biggerMarker = L.marker(event?.layers._layers[key]?._latlng as LatLng, {
              icon: icon,
              pane: PANE_NAME,
            });

            biggerMarker.on("click", () => {
              layer?.addVertex(event?.layers._layers[key]?._latlng);
              layer?._finishShape();
              map && map.removeLayer(biggerMarker);
            });
            map && biggerMarker.addTo(map);
          });
        } else {
          const lineStringPositions: Position[] = [];
          const latLngs: LatLng[] = [];
          Object.keys(event.layers._layers).forEach((key: string, index: number) => {
            latLngs.push(event?.layers._layers[key]?._latlng);
            lineStringPositions.push([
              event?.layers._layers[key]?._latlng.lng,
              event?.layers._layers[key]?._latlng.lat,
            ]);
          });
          lineStringPositions.length >= 2 && setDistance(lineStringLength(lineStringPositions));
          if (layerType === POLYGON_LAYER_TYPE && lineStringPositions.length >= 4) {
            lineStringPositions.push(lineStringPositions[0]);
            setArea(calculateArea(lineStringPositions));
          }
        }
      },
      [layer, setDistance, setArea, layerType, map]
    );

    useEffect(() => {
      //@ts-ignore
      if (!L.drawLocal?.draw?.handlers?.polyline) {
        return;
      }
      //@ts-ignore
      L.drawLocal.draw.handlers.polyline.tooltip = {
        start: "Click and draw to measurement area.",
        end: "",
        cont: "",
      };
    }, []);

    useEffect(() => {
      return () => {
        layer?.disable();
        clearAllLayers();
      };
    }, [hidden, layer, clearAllLayers]);

    const initLayer = useCallback(() => {
      if (!map) {
        return;
      }

      //@ts-ignore
      const polylineLayer: PolylineLayer = new L.Draw.Polyline(map, DRAW_CONTROL_OPTIONS);
      polylineLayer?.enable();
      setLayer(polylineLayer);
    }, [map]);

    useEffect(() => {
      initLayer();
    }, [initLayer]);

    useEffect(() => {
      if (!layer) {
        return;
      }

      layer._getMeasurementString = () => {
        if (!layer?._markers || !layer._currentLatLng) {
          return "";
        }

        return distanceFromPointToPoint(
          layer._currentLatLng,
          layer._markers[layer._markers.length - 1].getLatLng(),
          distance
        );
      };
    }, [layer, distance]);

    useEffect(() => {
      if (!map || !layer) {
        return;
      }
      map.on(eventHandlers.onCreated, onCreated);
      map.on(eventHandlers.onDrawVertex, onDrawVertex);
      map.on(eventHandlers.onEditVertex, onDrawVertex);
      return () => {
        map.off(eventHandlers.onCreated, onCreated);
        map.off(eventHandlers.onDrawVertex, onDrawVertex);
        map.off(eventHandlers.onEditVertex, onDrawVertex);
        clearMapMarkerPane();
      };
    }, [layer, map, onDrawVertex, onCreated, clearAllLayers, clearMapMarkerPane]);

    const cancelDrawing = useCallback(() => {
      clearAllLayers();
      resetState();
      layer?.disable();
      setLayerType(POLYLINE_LAYER_TYPE);
      clearMapMarkerPane();
      layer?.enable();
    }, [layer, clearAllLayers, resetState, setLayerType, clearMapMarkerPane]);

    const onEscape = useCallback(
      (e: KeyboardEvent) => {
        if (e.code === "Escape") {
          cancelDrawing();
        }
      },
      [cancelDrawing]
    );

    useEffect(() => {
      document.addEventListener("keydown", onEscape, false);

      return () => {
        document.removeEventListener("keydown", onEscape, false);
      };
    }, [onEscape]);

    if (hidden) {
      return <></>;
    }

    return (
      <>
        <FeatureGroup
          ref={(featureGroupRef) => {
            if (featureGroupRef) {
              setFeatureGroupRef(featureGroupRef);
            }
          }}
        >
          <EditControl
            onMounted={(ref: L.Control) => {
              setEditControlRef(ref);
            }}
            position="bottomleft"
            draw={{
              polygon: false,
              polyline: false,
              rectangle: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
          />
        </FeatureGroup>
        <MapMeasurePopup
          restartDrawing={cancelDrawing}
          cancelDrawing={toggle}
          hidden={hidden}
          area={area}
          distance={formattedDistance}
          distanceUnit={distanceUnit}
          leftMenuEnabled={leftMenuEnabled}
          rightMenuEnabled={rightMenuEnabled}
          layerMenuEnabled={layerMenuEnabled}
        />
      </>
    );
  }
);
