import styled from "styled-components";

import { RIGHT_DRAWER } from "../../../constants/z-index";
import { LEFT_SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../../../constants/dimensions";

interface LeftSideControlStyledProps {
  toggled: boolean;
}

export const LeftSideControlStyled = styled.div<LeftSideControlStyledProps>`
  width: ${LEFT_SIDEBAR_WIDTH}px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: ${TOP_HEADER_HEIGHT}px;
  left: ${(props) => (props.toggled ? "0px" : `-${LEFT_SIDEBAR_WIDTH}px`)};
  z-index: ${RIGHT_DRAWER + 1};
  background-color: white;
  padding-right: 0px;
`;

export const PrioritySection = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-left: 20px;
  height: 57px;
  border-bottom: solid 1px var(--colors-cumulus);
  border-top: solid 1px var(--colors-cumulus);
  & > :first-child {
    margin-right: 7px;
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const PriorityTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  max-width: 165px;
  color: #74828f;
`;

export const LeftSideContentStyled = styled.div`
  position: relative;
`;
