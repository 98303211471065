import styled from "styled-components";
import { Head1, DataLow } from "../../assets/styles/fonts.styled";

export const StructureContentWrapper = styled.div``;

export const StructureHeaderControlWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  justify-content: space-between;
  height: 47px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border-top: 1px solid #edeeee;
`;
export const StructureHeaderItemName = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  color: #676973;
`;
export const StructureHeaderItemDeleteAll = styled.div`
  justify-content: flex-end;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.easternBlue}; ;
`;

export const WorksheetWrapper = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  height: 59px;
  padding-left: 17px;
  padding-right: 17px;
  & > :first-child {
    padding-top: 13px;
    & > form {
      & > :first-child {
        padding: 0;
        margin: 0;
      }
    }
  }
`;

export const StructureWrapperItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StructureHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.alabaster2};
  box-sizing: border-box;
  height: 34px;
  padding: 0 15px;
  border-top: 1px solid #edeeee;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: calc(100% - 30px);
    background-color: #edeeee;
    box-sizing: border-box;
    margin: 0 15px;
  }
`;

export const StructureHeaderName = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: ${(props) => props.theme.colors.mineralGreen};
`;

export const StructureHeaderVoltageWrapper = styled.div`
  display: flex;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${(props) => props.theme.colors.mineralGreen};
`;

export const StructureHeaderVoltageImg = styled.img``;

export const StructureHeaderVoltage = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
  padding-left: 8px;
`;

export const WorksheetAreaControlWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const SubTotalItem = styled.div`
  position: relative;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 0 15px;
  text-align: right;
  ${DataLow}

  div {
    ${Head1}
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: calc(100% - 30px);
    background-color: #edeeee;
    box-sizing: border-box;
    margin: 0 15px;
  }
`;
