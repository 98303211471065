import { WorkTypeResponse } from "../../types/responses";
import { Worksheet, WorksheetResponse } from "../../types/responses/WorksheetResponse";

export const mapOptionsFromWorkTypes = (
  workTypes: Maybe<WorkTypeResponse[]>,
  worksheets?: Record<string, Worksheet>
) => {
  if (!workTypes) {
    return [];
  }
  return workTypes.map((workType) => ({
    value: workType.id,
    label: workType.name,
    code: workType.code,
    autoAssigned: worksheets?.[workType.id]?.autoAssigned ?? false,
  }));
};

export const mapOptionsFromWorksheets = (
  workTypes: Maybe<Record<string, WorkTypeResponse>>,
  worksheets: Record<string, Worksheet>
) => {
  if (!Object.keys(workTypes ?? {})?.length || !Object.keys(worksheets)?.length) {
    return [];
  }

  return Object.keys(worksheets).map((key) => {
    return {
      value: key,
      label: workTypes?.[key]?.name ?? "",
      code: workTypes?.[key]?.code ?? "",
      autoAssigned: worksheets?.[key]?.autoAssigned ?? false,
    };
  });
};

export const mapWorkTypesById = (workTypes: Maybe<WorkTypeResponse[]>): Record<string, WorkTypeResponse> => {
  const result: Record<string, WorkTypeResponse> = {};
  if (!workTypes) {
    return result;
  }

  workTypes.forEach((workType) => {
    result[workType.id] = workType;
  });

  return result;
};

export const mapWorksheetById = (worksheet: Maybe<Nullable<WorksheetResponse>>): Record<string, Worksheet> => {
  const result: Record<string, Worksheet> = {};
  if (!worksheet || !worksheet.items) {
    return result;
  }

  worksheet.items.forEach((item) => {
    if (!item.inherited) result[item.workTypeId] = item;
  });

  return result;
};
