import { FC, useMemo } from "react";
import { LayoutProps, PositionProps, SpaceProps } from "styled-system";

import { ToastStyled, WarningIcon } from "./Toast.styled";

export type ToastKind = "info" | "success" | "error";

interface ToastProps extends SpaceProps, PositionProps, LayoutProps {
  hidden: boolean;
  kind?: ToastKind;
}

// TODO add support for success and info kinds
const TOAST_ICON_COMPONENTS = {
  error: WarningIcon,
  success: WarningIcon,
  info: WarningIcon,
};

function getIconComponent(kind: ToastKind) {
  return TOAST_ICON_COMPONENTS[kind] || TOAST_ICON_COMPONENTS["info"];
}

export const Toast: FC<ToastProps> = ({ children, hidden, kind = "info", ...props }) => {
  const IconComponent = useMemo(() => getIconComponent(kind), [kind]);

  if (hidden) {
    return <></>;
  }

  return (
    <ToastStyled kind={kind} {...props}>
      <IconComponent mr={2} />
      {children}
    </ToastStyled>
  );
};
