// TODO optimise and refactor
import { LatLng } from "leaflet";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Geometry } from "geojson";
import { useMutation } from "react-query";

import { RightDrawer } from "../../../components/RightDrawer";
import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import { getForecastRoute } from "../../../routes";
import {
  useMapStore,
  usePredominatedWorkTypesStore,
  useRightOfWayOverridesStore,
  useSpansStore,
  useLeftControlStore,
  useCutPlanStore,
  useFeederStore,
  useThreatsStore,
} from "../../../stores";
import useDrawerStore from "../../../stores/DrawerStore/DrawerStore";
import { ContentType } from "../../../stores/DrawerStore/DrawerStore.types";
import useStructuresStore from "../../../stores/StructuresStore";
import {
  getSearchParams,
  hasQueryParam,
  resetToFeederLevelSearchParams,
  resetToLeftMenuSearchParams,
} from "../../../utils/navigationUtils";
import { EncroachmentResponse } from "../../../types/responses";
import {
  getEncroachmentContent,
  getCustomPolygonContent,
  getParcelContent,
  getSelectedObjectFromQueryParams,
  getSpanContent,
  getStructureContent,
  getUrlParam,
  SectionData,
} from "./RightDrawerMenu.utils";
import { Portal } from "../../../components/Portal";
import useSwitchesStore from "../../../stores/SwitchesStore";
import useParcelsStore from "../../../stores/ParcelsStore";
import { Sections, SectionWrapper } from "../../../components/RightDrawer/RightDrawer.styled";
import { SpanInfoSection } from "./RightDrawerSections/SpanInfoSection";
import { StructureInfoSection } from "./RightDrawerSections/StructureInfoSection";
import { ParcelInfoSection } from "./RightDrawerSections/ParcelInfoSection";
import { InfringementInfoSection } from "./RightDrawerSections/InfringementInfoSection";
import { RightDrawerModal } from "./RightDrawerModal";
import { ModalContent, ModalContentValue } from "./RightDrawerMenu.styled";
import ApiClient from "../../../utils/apiClient";
import { deleteRightOfWayOverridesEntryApiUrl, getRightOfWayOverridesApiUrl } from "../../../api";
import { logError } from "../../../services";
import { unitConverter } from "../../../utils/unitHelper";
import useFeederSegmentStore from "../../../stores/FeederSegmentStore";
import { useTreeCanopyStore } from "../../../stores/TreeCanopyStore";
import { RISK_LAYERS_VALUES } from "../../../types/Layers";
import { CustomPolygonRHS } from "../../../components/CustomPolygonRHS";
import { useDeleteCustomPolygon } from "../../../hooks";

const SECTION_ID = "section-wrapper-id";
const SCROLL_LIMIT_TOP = 30;

export const RightDrawerMenu: FC = memo(() => {
  const stack = useDrawerStore((store) => store.stack);
  const pop = useDrawerStore((store) => store.pop);
  const pushOnStack = useDrawerStore((store) => store.push);
  const clearStack = useDrawerStore((store) => store.clearStack);
  const getLastElement = useDrawerStore((store) => store.getLastElement);
  const feedersGeoJson = useFeederStore((store) => store.feedersGeoJson);
  const encroachments = useThreatsStore((store) => store.threatsPosition);
  const spansById = useSpansStore((store) => store.byId);
  const getSpanById = useSpansStore((store) => store.getById);
  const spans = useSpansStore((store) => store.geoJSON);
  const toggleLayerControl = useMapStore((store) => store.toggleLayerControl);
  const layerControlVisible = useMapStore((store) => store.layerControlVisible);
  const getStructureById = useStructuresStore((store) => store.getStructureById);
  const structuresGeoJSON = useStructuresStore((store) => store.structuresGeoJSON);
  const structuresById = useStructuresStore((store) => store.structuresById);
  const getSwitchByStructureId = useSwitchesStore((store) => store.getSwitchByStructureId);
  const getParcelById = useParcelsStore((store) => store.getById);
  const parcelsGeoJSON = useParcelsStore((store) => store.geoJSON);
  const switchesById = useSwitchesStore((store) => store.switchesById);
  const vectorLayers = useMapStore((store) => store.vectorLayers);
  const urbanLayerId = useMapStore((store) => store.urbanLayerId);
  const setVectorLayerVisibility = useMapStore((store) => store.setVectorLayerVisibility);
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );
  const { setCutPlanHidden } = useCutPlanStore((store) => store.actions);
  const { setCutPlanSelectedId } = useCutPlanStore((store) => store.actions);
  const setRightOfWayLocalOverrides = useRightOfWayOverridesStore((store) => store.setLocalOverrides);
  const getOverrideBySpanId = useRightOfWayOverridesStore((store) => store.getBySpanId);
  const getOverrideByParcelId = useRightOfWayOverridesStore((store) => store.getByParcelId);
  const rightOfWayOverrides = useRightOfWayOverridesStore((store) => store.overrides);
  const selectedFeederSegment = useFeederSegmentStore((store) => store.selectedId);
  const feederSegmentsById = useFeederSegmentStore((store) => store.byId);
  const feederSegments = useFeederSegmentStore((store) => store.geoJSON);
  const setToggled = useLeftControlStore((store) => store.setToggled);
  const { setSelectedFeederById } = useFeederStore((store) => store.actions);
  const { getFeederDataById } = useFeederStore((store) => store.actions);
  const map = useMapStore((store) => store.map);
  const areTreesVisible = useTreeCanopyStore((store) => store.layerVisible);
  const toggleTrees = useTreeCanopyStore((store) => store.toggleLayer);

  const [isRightOfWayResetToDefaultsModalVisible, setRightOfWayResetToDefaultsModalVisible] = useState(false);
  const [isRightOfWayEditConfirmationModalVisible, setRightOfWayEditConfirmationModalVisible] = useState(false);
  const [isRightOfWayRequestPending, setRightOfWayRequestPending] = useState(false);
  const [isRightOfWayRequestError, setRightOfWayRequestError] = useState(false);
  const threats = useThreatsStore((store) => store.threatsData);
  const [rightOfWayOverrideValues, setRightOfWayOverrideValues] =
    useState<Nullable<{ leftOverride: Nullable<number>; rightOverride: Nullable<number> }>>(null);

  const [currentContent, setCurrentContent] = useState<SectionData>(null);
  const [fromHyperLink, setFromHyperLink] = useState<boolean>(false);

  const [scrollTop, setScrollTop] = useState<number>(0);

  const enableReloadForPredominatedWorkTypes = usePredominatedWorkTypesStore((store) => store.enableReload);
  const { mutateAsync: deteleCustomPolygon } = useDeleteCustomPolygon();

  const createRightOfWayOverrideValue = useMutation(
    (value: { leftOverride?: number; rightOverride?: number; spanId?: string; parcelId?: string; id?: string }) =>
      ApiClient.post<{ rowOverrideId: string; polygons: { spanId: string; polygon: Geometry }[] }>(
        getRightOfWayOverridesApiUrl(),
        value
      )
  );

  const updateRightOfWayOverrideValue = useMutation(
    (value: { leftOverride?: number; rightOverride?: number; spanId?: string; parcelId?: string; id?: string }) => {
      const { id, ...data } = value;
      return ApiClient.put<{ rowOverrideId: string; polygons: { spanId: string; polygon: Geometry }[] }>(
        deleteRightOfWayOverridesEntryApiUrl(id!),
        data
      );
    }
  );

  const deleteRightOfWayOverride = useMutation((id: string) =>
    ApiClient.delete(deleteRightOfWayOverridesEntryApiUrl(id))
  );

  const history = useHistory();
  const location = useLocation();

  //scroll on top
  useEffect(() => {
    const element = document.querySelector(`#${SECTION_ID}`);
    element?.scrollTo({ top: 0 });
  }, [currentContent]);

  // close right menu when currently selected id is removed
  useEffect(() => {
    const lastElement = getLastElement();

    switch (lastElement?.type) {
      case ContentType.PARCEL:
        if (!hasQueryParam(FORECAST_QUERY_PARAMS.PARCEL_ID)) {
          clearStack();
        }

        break;
      case ContentType.ENCROACHMENT:
        if (!hasQueryParam(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID)) {
          clearStack();
        }

        break;
      case ContentType.SPAN:
        if (!hasQueryParam(FORECAST_QUERY_PARAMS.SPAN_ID)) {
          clearStack();
        }

        break;
      case ContentType.STRUCTURE:
        if (!hasQueryParam(FORECAST_QUERY_PARAMS.STRUCTURE_ID)) {
          clearStack();
        }
        break;
      case ContentType.CIRCUIT:
        if (!hasQueryParam(FORECAST_QUERY_PARAMS.FEEDER_ID)) {
          clearStack();
        }

        break;
    }
  }, [location.search, getLastElement, clearStack]);

  const openLink = useCallback(
    (type: ContentType, id: string) => {
      setLatestObjectIdSelectedForMapAnimation(id);
      const queryParams = getSearchParams();
      const urlParamType = getUrlParam(type);
      if (urlParamType === FORECAST_QUERY_PARAMS.FEEDER_ID) {
        setToggled(true);
        setSelectedFeederById(id);
        const feederData = getFeederDataById(id);
        map?.flyToBounds(feederData?.feederBox!);
        return;
      }

      if (urlParamType === FORECAST_QUERY_PARAMS.SPAN_ID || urlParamType === FORECAST_QUERY_PARAMS.PARCEL_ID) {
        resetToLeftMenuSearchParams(queryParams);
      }

      queryParams.set(urlParamType, id);
      setFromHyperLink(true);
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, stack]
  );

  const onBack = useCallback(
    () => {
      pop();
      const lastElement = getLastElement();
      if (!stack || !lastElement) {
        return;
      }

      openLink(lastElement.type, lastElement.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  const clearDrawerStack = useCallback(() => {
    clearStack();
    const queryParams = getSearchParams();
    queryParams.delete(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER);
    resetToFeederLevelSearchParams(queryParams);
    history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
  }, [clearStack, history]);

  const computeStackContent = () => {
    setCurrentContent(null);
    const lastElement = getLastElement();
    if (!stack || !lastElement) {
      return;
    }
    //close layer control if is open
    layerControlVisible && toggleLayerControl(true);
    switch (lastElement.type) {
      case ContentType.SPAN:
        return setCurrentContent(
          getSpanContent(
            getSpanById(lastElement.id),
            feedersGeoJson,
            lastElement.coordinates as LatLng,
            structuresGeoJSON,
            null, //get this from regions
            switchesById,
            getParcelById,
            vectorLayers,
            urbanLayerId,
            selectedFeederSegment ? feederSegmentsById[selectedFeederSegment] : null
          )
        );
      case ContentType.PARCEL:
        return setCurrentContent(
          getParcelContent({
            parcel: getParcelById(lastElement.id),
          })
        );
      case ContentType.STRUCTURE:
        return setCurrentContent(
          getStructureContent({
            structure: getStructureById(lastElement.id),
            feeders: feedersGeoJson,
            spans,
            isSwitch: !!getSwitchByStructureId(lastElement.id),
          })
        );
      case ContentType.ENCROACHMENT:
        return setCurrentContent(
          getEncroachmentContent(
            threats?.[lastElement.id]?.threat ?? null,
            feedersGeoJson,
            lastElement.id,
            lastElement.coordinates as LatLng,
            encroachments,
            feederSegments
          )
        );
      case ContentType.CUSTOM_POLYGON:
        const content = getCustomPolygonContent(
          threats?.[lastElement.id]?.threat as EncroachmentResponse,
          feedersGeoJson,
          lastElement.id,
          lastElement.coordinates as LatLng,
          encroachments,
          feederSegments
        );
        return setCurrentContent(content);
      default:
        setCurrentContent(null);
    }
  };

  const resetRightOfWayToDefaults = useCallback(async () => {
    const activeElement = getLastElement();
    let overrideId;
    if (!activeElement?.id) {
      return;
    }
    if (activeElement.type === ContentType.SPAN) {
      overrideId = getOverrideBySpanId(activeElement?.id)?.id;
    }
    if (activeElement.type === ContentType.PARCEL) {
      overrideId = getOverrideByParcelId(activeElement?.id)?.id;
    }
    if (!overrideId) {
      return;
    }
    try {
      setRightOfWayRequestPending(true);
      setRightOfWayRequestError(false);
      const result = await deleteRightOfWayOverride.mutateAsync(overrideId);
      setRightOfWayResetToDefaultsModalVisible(false);
      setRightOfWayLocalOverrides(result?.data);
    } catch (e) {
      logError(e);
      setRightOfWayRequestError(true);
    } finally {
      setRightOfWayRequestPending(false);
    }
  }, [
    deleteRightOfWayOverride,
    setRightOfWayResetToDefaultsModalVisible,
    getLastElement,
    getOverrideBySpanId,
    getOverrideByParcelId,
    setRightOfWayLocalOverrides,
  ]);

  const openRightOfWayEditConfirmationModal = useCallback(
    (args: typeof rightOfWayOverrideValues) => {
      setRightOfWayEditConfirmationModalVisible(true);
      setRightOfWayOverrideValues({
        leftOverride: typeof args?.leftOverride === "number" ? args?.leftOverride : null,
        rightOverride: typeof args?.rightOverride === "number" ? args?.rightOverride : null,
      });
    },
    [setRightOfWayOverrideValues, setRightOfWayEditConfirmationModalVisible]
  );

  const closeRightOfWayEditConfirmationModal = () => {
    setRightOfWayEditConfirmationModalVisible(false);
    setRightOfWayOverrideValues(null);
    setRightOfWayRequestError(false);
    setRightOfWayRequestPending(false);
  };

  const closeRightOfWayResetToDefaultsConfirmationModal = () => {
    setRightOfWayResetToDefaultsModalVisible(false);
    setRightOfWayRequestError(false);
    setRightOfWayRequestPending(false);
  };

  const updateRightOfWay = useCallback(async () => {
    const activeElement = getLastElement();
    let overrideId;
    if (!activeElement) {
      return;
    }
    if (activeElement.type === ContentType.SPAN) {
      overrideId = getOverrideBySpanId(activeElement?.id)?.id;
    }
    if (activeElement.type === ContentType.PARCEL) {
      overrideId = getOverrideByParcelId(activeElement?.id)?.id;
    }
    const request = overrideId ? updateRightOfWayOverrideValue : createRightOfWayOverrideValue;
    try {
      setRightOfWayRequestPending(true);
      setRightOfWayRequestError(false);
      const result = await request.mutateAsync({
        ...(activeElement?.type === ContentType.PARCEL
          ? { parcelId: activeElement?.id }
          : { spanId: activeElement?.id }),
        ...(rightOfWayOverrideValues?.leftOverride
          ? { leftOverride: unitConverter(false, Number(rightOfWayOverrideValues?.leftOverride)) }
          : {}),
        ...(rightOfWayOverrideValues?.rightOverride
          ? { rightOverride: unitConverter(false, Number(rightOfWayOverrideValues?.rightOverride)) }
          : {}),
        ...(overrideId ? { id: overrideId } : null),
      });
      setRightOfWayLocalOverrides(result?.data);
      setRightOfWayEditConfirmationModalVisible(false);
      setRightOfWayOverrideValues(null);
    } catch (e) {
      logError(e);
      setRightOfWayRequestError(true);
    } finally {
      setRightOfWayRequestPending(false);
    }
  }, [
    createRightOfWayOverrideValue,
    setRightOfWayEditConfirmationModalVisible,
    setRightOfWayOverrideValues,
    setRightOfWayLocalOverrides,
    rightOfWayOverrideValues,
    getLastElement,
    setRightOfWayRequestError,
    setRightOfWayRequestPending,
    getOverrideBySpanId,
    getOverrideByParcelId,
    updateRightOfWayOverrideValue,
  ]);

  const defaultRightOfWayValue = useMemo(() => {
    const activeElement = getLastElement();
    if (!activeElement || activeElement.type !== ContentType.SPAN) {
      return null;
    }

    const spanProperties = getSpanById(activeElement.id);

    return spanProperties?.leftClearance && spanProperties?.rightClearance
      ? {
          left: unitConverter(true, Number(spanProperties.leftClearance), 0),
          right: unitConverter(true, Number(spanProperties.rightClearance), 0),
        }
      : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent, getSpanById, getLastElement, rightOfWayOverrides]);

  const rightOfWayOverridenValue = useMemo(() => {
    const activeElement = getLastElement();

    if (!activeElement || !activeElement.type) {
      return null;
    }

    if (activeElement.type === ContentType.SPAN) {
      const override = getOverrideBySpanId(activeElement.id);

      return override
        ? {
            left: override.leftOverride ? unitConverter(true, override.leftOverride, 0) : null,
            right: override.rightOverride ? unitConverter(true, override.rightOverride, 0) : null,
          }
        : null;
    }

    if (activeElement.type === ContentType.PARCEL) {
      const override = getOverrideByParcelId(activeElement.id);
      return override
        ? {
            left: override.leftOverride ? unitConverter(true, override.leftOverride, 0) : null,
            right: override.rightOverride ? unitConverter(true, override.rightOverride, 0) : null,
          }
        : null;
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent, getSpanById, getLastElement, getOverrideByParcelId, getOverrideBySpanId, rightOfWayOverrides]);
  const rightOfWayValue = rightOfWayOverridenValue || defaultRightOfWayValue;

  // on parcels dynamic load recompute
  useEffect(() => {
    computeStackContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelsGeoJSON]);

  useEffect(() => {
    computeStackContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack]);

  useEffect(() => {
    computeStackContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spansById]);

  useEffect(() => {
    computeStackContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threats]);

  useEffect(() => {
    computeStackContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structuresById]);

  useEffect(() => {
    const queryParams = getSearchParams();
    const selectedObject = getSelectedObjectFromQueryParams(queryParams);
    if (!selectedObject || selectedObject.type === ContentType.CIRCUIT) {
      return;
    }

    pushOnStack(selectedObject, fromHyperLink);
    setFromHyperLink(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, pushOnStack]);

  const lastStackElement = getLastElement();

  const onViewInPlanClick = useCallback(
    (id) => {
      setCutPlanHidden(false);
      setCutPlanSelectedId(id);
    },
    [setCutPlanHidden, setCutPlanSelectedId]
  );

  const handleScroll = useCallback((e) => {
    setScrollTop(e.target.scrollTop);
  }, []);

  if (!currentContent || !lastStackElement) {
    return <></>;
  }

  const hasPreviousLevel = stack && stack.length > 1;

  return (
    <Portal selector="layer-menu-root">
      <RightDrawer
        content={currentContent}
        closeDrawer={clearDrawerStack}
        onBack={onBack}
        hasPreviousLevel={hasPreviousLevel}
        type={lastStackElement?.type}
        scroll={scrollTop > SCROLL_LIMIT_TOP}
      >
        <SectionWrapper id={SECTION_ID} onScroll={handleScroll}>
          <Sections>
            {lastStackElement.type === ContentType.SPAN && (
              <SpanInfoSection
                data={currentContent}
                openHyperlink={openLink}
                vectorLayers={vectorLayers}
                setVectorLayerVisibility={setVectorLayerVisibility}
                onActionNameClick={() => setRightOfWayResetToDefaultsModalVisible(true)}
                onRightOfWayChange={openRightOfWayEditConfirmationModal}
                rightOfWayValue={rightOfWayValue}
                actionDisabled={!getOverrideBySpanId(lastStackElement.id)}
                onViewInPlanClick={onViewInPlanClick}
              />
            )}
            {lastStackElement.type === ContentType.STRUCTURE && (
              <StructureInfoSection
                data={currentContent}
                openHyperlink={openLink}
                enableReloadForPredominatedWorkTypes={enableReloadForPredominatedWorkTypes}
              />
            )}
            {lastStackElement.type === ContentType.PARCEL && (
              <ParcelInfoSection
                closeDrawer={clearDrawerStack}
                data={currentContent}
                onActionNameClick={() => setRightOfWayResetToDefaultsModalVisible(true)}
                onRightOfWayChange={openRightOfWayEditConfirmationModal}
                rightOfWayValue={rightOfWayValue}
                actionDisabled={!getOverrideByParcelId(lastStackElement.id)}
              />
            )}
            {lastStackElement.type === ContentType.ENCROACHMENT && (
              <InfringementInfoSection
                data={currentContent}
                openHyperlink={openLink}
                specialLayers={[RISK_LAYERS_VALUES[2]]}
                setSpecialLayer={toggleTrees}
                specialLayersState={{ TREEconaissance: areTreesVisible }}
                enableReloadForPredominatedWorkTypes={enableReloadForPredominatedWorkTypes}
              />
            )}
            {lastStackElement.type === ContentType.CUSTOM_POLYGON && (
              <CustomPolygonRHS
                data={currentContent}
                openHyperlink={openLink}
                specialLayers={[RISK_LAYERS_VALUES[2]]}
                setSpecialLayer={toggleTrees}
                specialLayersState={{ TREEconaissance: areTreesVisible }}
                enableReloadForPredominatedWorkTypes={enableReloadForPredominatedWorkTypes}
                deleteCustomWorkContainer={(polygonId) => deteleCustomPolygon(polygonId).then(() => pop())}
                closeDrawer={clearDrawerStack}
              />
            )}
          </Sections>
        </SectionWrapper>
      </RightDrawer>
      <RightDrawerModal
        hidden={!isRightOfWayEditConfirmationModalVisible}
        message={`Are you sure you want to edit ${lastStackElement.type === ContentType.SPAN ? "span" : "parcel"} ROW?`}
        onCancel={closeRightOfWayEditConfirmationModal}
        onChange={updateRightOfWay}
        loading={isRightOfWayRequestPending}
        error={isRightOfWayRequestError}
      >
        {defaultRightOfWayValue && (
          <ModalContent pt="5px">
            Default values:{" "}
            {defaultRightOfWayValue.left && (
              <>
                Left <ModalContentValue>{defaultRightOfWayValue.left}ft</ModalContentValue>
              </>
            )}
            {defaultRightOfWayValue.left && defaultRightOfWayValue.right && <>, </>}
            {defaultRightOfWayValue.right && (
              <>
                Right <ModalContentValue>{defaultRightOfWayValue.right}ft</ModalContentValue>
              </>
            )}
          </ModalContent>
        )}
        {rightOfWayOverrideValues && (
          <>
            <br />
            <ModalContent>
              New values:{" "}
              {rightOfWayOverrideValues.leftOverride && (
                <>
                  Left <ModalContentValue>{rightOfWayOverrideValues.leftOverride}ft</ModalContentValue>
                </>
              )}
              {rightOfWayOverrideValues.leftOverride && rightOfWayOverrideValues.rightOverride && <>, </>}
              {rightOfWayOverrideValues.rightOverride && (
                <>
                  Right <ModalContentValue>{rightOfWayOverrideValues.rightOverride}ft</ModalContentValue>
                </>
              )}
            </ModalContent>
          </>
        )}
      </RightDrawerModal>
      <RightDrawerModal
        hidden={!isRightOfWayResetToDefaultsModalVisible}
        message="Are you sure you want to reset ROW to default values?"
        onCancel={closeRightOfWayResetToDefaultsConfirmationModal}
        onChange={resetRightOfWayToDefaults}
        loading={isRightOfWayRequestPending}
        error={isRightOfWayRequestError}
      >
        {defaultRightOfWayValue && (
          <ModalContent pt="5px">
            Default values:
            {defaultRightOfWayValue.left && (
              <>
                Left: <ModalContentValue>{defaultRightOfWayValue.left}ft</ModalContentValue>
              </>
            )}
            {defaultRightOfWayValue.left && defaultRightOfWayValue.right && <>, </>}
            {defaultRightOfWayValue.right && (
              <>
                Right: <ModalContentValue>{defaultRightOfWayValue.right}ft</ModalContentValue>
              </>
            )}
          </ModalContent>
        )}
      </RightDrawerModal>
    </Portal>
  );
});
