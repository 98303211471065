import L from "leaflet";

import { StructureName, StructureStatus, STRUCTURE_MODE } from "../../types/responses/StructuresResponse";

export const getDotSvg = () => `
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="9.5" cy="5.5" r="5.5" fill="white"/>
<circle cx="9.5" cy="5.5" r="4.5" stroke-width="2" fill="none"/>
<circle cx="9.5" cy="5.5" r="2.5" />
</g>
<defs>
<filter id="filter0_d" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.54 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
`;

export const getDotSvgWithName = (name: string) => `
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="9.5" cy="5.5" r="5.5" fill="white"/>
<circle cx="9.5" cy="5.5" r="4.5" stroke-width="2" fill="none"/>
<circle cx="9.5" cy="5.5" r="2.5" />
</g>
<defs>
<filter id="filter0_d" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.54 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
<div class="structure__name name">${name}</div>
`;

export const getPoleSvg = (name: string, isSwitch: boolean) => `
  <svg y="10" width="27" height="${isSwitch ? 22 : 27}" viewBox="0 0 27 ${
  isSwitch ? 22 : 27
}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  ${
    isSwitch
      ? '<rect width="27" height="22" rx="5" fill="url(#not-inspected)" /> <rect width="27" height="22" rx="6" stroke="white"/>'
      : '<circle cx="13.5" cy="11.5" r="11.5" fill="url(#not-inspected)"/><circle cx="13.5" cy="11.5" r="12" stroke="white"/>'
  }
  </g>
  <path d="M15.0234 5.15442C15.0234 4.47198 14.5108 3.91663 13.8809 3.91663H13.1191C12.4892 3.91663 11.9766 4.47198 11.9766 5.15442V6.85982H15.0234V5.15442Z" fill="white"/>
  <path d="M11.9766 17.5873C11.9766 17.8151 12.1472 17.9999 12.3574 17.9999H14.6426C14.8528 17.9999 15.0234 17.8151 15.0234 17.5873V10.5732H11.9766V17.5873Z" fill="white"/>
  <path d="M19.6191 7.685H18.4512V6.8598C18.4512 6.63194 18.2806 6.4472 18.0703 6.4472C17.86 6.4472 17.6895 6.63194 17.6895 6.8598V7.685H16.9277V6.8598C16.9277 6.63194 16.7572 6.4472 16.5469 6.4472C16.3365 6.4472 16.166 6.63194 16.166 6.8598V7.685H10.834V6.8598C10.834 6.63194 10.6635 6.4472 10.4531 6.4472C10.2428 6.4472 10.0723 6.63194 10.0723 6.8598V7.685H9.31055V6.8598C9.31055 6.63194 9.14002 6.4472 8.92969 6.4472C8.71935 6.4472 8.54883 6.63194 8.54883 6.8598V7.685H7.38086C7.17052 7.685 7 7.86973 7 8.0976V9.33539C7 9.56325 7.17052 9.74799 7.38086 9.74799H19.6191C19.8295 9.74799 20 9.56325 20 9.33539V8.0976C20 7.86973 19.8295 7.685 19.6191 7.685Z" fill="white"/>
  <path d="M8.03125 10.5732L10.4116 12.6362H11.2117V12.2728L9.25051 10.5732H8.03125Z" fill="white"/>
  <path d="M15.7852 12.2728V12.6362H16.5852L18.9656 10.5732H17.7463L15.7852 12.2728Z" fill="white"/>
  <defs>
  <linearGradient id="critical" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF3D3D"/>
  <stop offset="1" stop-color="#BB0000"/>
  </linearGradient>
  <linearGradient id="reject" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF9634"/>
  <stop offset="1" stop-color="#D06400"/>
  </linearGradient>
  <linearGradient id="needs-repair" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FFD978"/>
  <stop offset="1" stop-color="#DAAE12"/>
  </linearGradient>
  <linearGradient id="good" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#37EB0A"/>
  <stop offset="1" stop-color="#1F9A00"/>
  </linearGradient>
  <linearGradient id="not-inspected" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#479EFF"/>
  <stop offset="1" stop-color="#0065D6"/>
  </linearGradient>
  </defs>
  </svg>
  <div class="structure__name">
    ${name}
  </div>
`;

export const getLatticeSvg = (name: string, isSwitch: boolean) => `
  <svg height="${isSwitch ? 22 : 27}" viewBox="0 0 27 ${
  isSwitch ? 22 : 27
}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  ${
    isSwitch
      ? '<rect width="27" height="22" rx="5" fill="url(#not-inspected)" /> <rect width="27" height="22" rx="6" stroke="white"/>'
      : '<circle cx="13.5" cy="11.5" r="11.5" fill="url(#not-inspected)"/><circle cx="13.5" cy="11.5" r="12" stroke="white"/>'
  }
  </g>
  <rect width="1.01338" height="7.93203" transform="matrix(0.85216 0.523281 -0.830588 0.556887 16.6367 9.05249)" fill="white"/>
  <rect x="12.9375" y="3.25885" width="2" height="5.59822" transform="rotate(43.7415 12.9375 3.25885)" fill="white"/>
  <rect width="0.994415" height="8.39066" transform="matrix(-0.841641 0.540038 -0.841641 -0.540038 17.4609 13.4403)" fill="white"/>
  <path d="M17.6953 7.2804L16.2811 8.69461L12 4.5L13.4149 2.99998L17.6953 7.2804Z" fill="white"/>
  <path d="M16 8H18V13.5L19 19H17L16 13.5V8Z" fill="white"/>
  <path d="M11 8H9V13.5L8 19H10L11 13.5V8Z" fill="white"/>
  <rect x="7" y="7" width="13" height="2" fill="white"/>
  <rect x="6" y="11" width="4" height="2" fill="white"/>
  <rect x="17" y="11" width="4" height="2" fill="white"/>
  <rect x="20" y="7" width="1" height="3" fill="white"/>
  <rect x="6" y="7" width="1" height="3" fill="white"/>
  <rect x="20" y="11" width="1" height="3" fill="white"/>
  <rect x="6" y="11" width="1" height="3" fill="white"/>
  <defs>
  <linearGradient id="critical" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF3D3D"/>
  <stop offset="1" stop-color="#BB0000"/>
  </linearGradient>
  <linearGradient id="reject" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF9634"/>
  <stop offset="1" stop-color="#D06400"/>
  </linearGradient>
  <linearGradient id="needs-repair" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FFD978"/>
  <stop offset="1" stop-color="#DAAE12"/>
  </linearGradient>
  <linearGradient id="good" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#37EB0A"/>
  <stop offset="1" stop-color="#1F9A00"/>
  </linearGradient>
  <linearGradient id="not-inspected" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#479EFF"/>
  <stop offset="1" stop-color="#0065D6"/>
  </linearGradient>
  </defs>
  </svg>
  <div class="structure__name">
    ${name}
  </div>
`;

export const getHFrameSvg = (name: string, isSwitch: boolean) => `
  <svg height="${isSwitch ? 22 : 27}" viewBox="0 0 27 ${
  isSwitch ? 22 : 27
}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  ${
    isSwitch
      ? '<rect width="27" height="22" rx="5" fill="url(#not-inspected)" /> <rect width="27" height="22" rx="6" stroke="white"/>'
      : '<circle cx="13.5" cy="11.5" r="11.5" fill="url(#not-inspected)"/><circle cx="13.5" cy="11.5" r="12" stroke="white"/>'
  }
  </g>
  <rect x="16.6367" y="10.1326" width="1.1953" height="9.52877" transform="rotate(43.7415 16.6367 10.1326)" fill="white"/>
  <rect x="12.9375" y="3.25885" width="2" height="5.59822" transform="rotate(43.7415 12.9375 3.25885)" fill="white"/>
  <rect x="17.4609" y="16.9709" width="1.18361" height="9.98707" transform="rotate(135 17.4609 16.9709)" fill="white"/>
  <path d="M17.6953 7.2804L16.2811 8.69461L12 4.5L13.4149 2.99998L17.6953 7.2804Z" fill="white"/>
  <rect x="16" y="8" width="2" height="11" fill="white"/>
  <rect x="9" y="8" width="2" height="11" fill="white"/>
  <rect x="7" y="7" width="13" height="2" fill="white"/>
  <rect x="19" y="7" width="1" height="3" fill="white"/>
  <rect x="7" y="7" width="1" height="3" fill="white"/>
  <defs>
  <linearGradient id="critical" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF3D3D"/>
  <stop offset="1" stop-color="#BB0000"/>
  </linearGradient>
  <linearGradient id="reject" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF9634"/>
  <stop offset="1" stop-color="#D06400"/>
  </linearGradient>
  <linearGradient id="needs-repair" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FFD978"/>
  <stop offset="1" stop-color="#DAAE12"/>
  </linearGradient>
  <linearGradient id="good" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#37EB0A"/>
  <stop offset="1" stop-color="#1F9A00"/>
  </linearGradient>
  <linearGradient id="not-inspected" x1="14.5" y1="1" x2="14.5" y2="24" gradientUnits="userSpaceOnUse">
  <stop stop-color="#479EFF"/>
  <stop offset="1" stop-color="#0065D6"/>
  </linearGradient>
  </defs>
  </svg>
  <div class="structure__name">
    ${name}
  </div>
`;
interface Icon {
  status: StructureStatus;
  html: string;
  displayStatusColors: boolean;
  id: string;
}

const getStatusColorClass = (status: StructureStatus) => {
  switch (status) {
    case "GOOD":
    case "REPLACED":
    case "REPAIRS MADE":
      return "structure--good";
    case "CRITICAL":
    case "URGENT REPAIR":
      return "structure--critical";
    case "NEEDS REPAIR":
      return "structure--needs-repair";
    case "REJECT":
      return "structure--reject";
    case "NOT INSPECTED":
      return "structure--not-inspected";
    default:
      return "structure--default";
  }
};

export const getTowerIcon = ({ status, displayStatusColors, id, html }: Icon, isSwitch: boolean) => {
  const baseClassName = `structure ${isSwitch ? "structure__switch" : "structure__tower"} object-id-${id} `;
  const colorClassName = displayStatusColors ? getStatusColorClass(status) : "structure--default";

  return L.divIcon({
    className: `${baseClassName} ${colorClassName}`,
    html,
  });
};

export const getDotIcon = ({
  status,
  displayStatusColors,
  id,
}: {
  status: StructureStatus;
  displayStatusColors: boolean;
  id: string;
}) => {
  const html = getDotSvg();
  const baseClassName = `structure structure__dot object-id-${id} `;
  const colorClassName = displayStatusColors ? getStatusColorClass(status) : "structure--default";

  return L.divIcon({
    className: `${baseClassName} ${colorClassName}`,
    html,
  });
};

export const getDotIconWithName = ({
  status,
  displayStatusColors,
  id,
  name,
}: {
  status: StructureStatus;
  displayStatusColors: boolean;
  id: string;
  name: string;
}) => {
  const html = getDotSvgWithName(name);
  const baseClassName = `structure structure__dot object-id-${id} `;
  const colorClassName = displayStatusColors ? getStatusColorClass(status) : "structure--default";

  return L.divIcon({
    className: `${baseClassName} ${colorClassName}`,
    html,
  });
};

export const getStructureIcon = ({
  status,
  name,
  structureName,
  displayStatusColors,
  id,
  isSwitch,
}: {
  status: StructureStatus;
  name: string;
  structureName: StructureName;
  displayStatusColors: boolean;
  id: string;
  isSwitch: boolean;
}) => {
  switch (structureName) {
    case "Wood Pole":
      return getTowerIcon({ status, displayStatusColors, id, html: getPoleSvg(name, isSwitch) }, isSwitch);
    case "Wood H-Frame":
      return getTowerIcon({ status, displayStatusColors, id, html: getHFrameSvg(name, isSwitch) }, isSwitch);
    case "Steel Pole":
      return getTowerIcon({ status, displayStatusColors, id, html: getPoleSvg(name, isSwitch) }, isSwitch);
    case "Steel H-Frame":
      return getTowerIcon({ status, displayStatusColors, id, html: getHFrameSvg(name, isSwitch) }, isSwitch);
    case "Lattice":
      return getTowerIcon({ status, displayStatusColors, id, html: getLatticeSvg(name, isSwitch) }, isSwitch);
    case "FOS":
      return getTowerIcon({ status, displayStatusColors, id, html: getPoleSvg(name, isSwitch) }, isSwitch);
    case "Concrete Pole":
      return getTowerIcon({ status, displayStatusColors, id, html: getHFrameSvg(name, isSwitch) }, isSwitch);
    case "Concrete H-Frame":
      return getTowerIcon({ status, displayStatusColors, id, html: getPoleSvg(name, isSwitch) }, isSwitch);
    default:
      return getTowerIcon({ status, displayStatusColors, id, html: getPoleSvg(name, isSwitch) }, isSwitch);
  }
};

export const getStructureIconByMode = (
  mode: STRUCTURE_MODE,
  id: string,
  status: StructureStatus,
  name: string,
  structureName: StructureName,
  displayStatusColors: boolean,
  isSwitch: boolean
) => {
  switch (mode) {
    case STRUCTURE_MODE.DETAILED:
      return getStructureIcon({ status, name: name, structureName, displayStatusColors, id, isSwitch });
    case STRUCTURE_MODE.OVERVIEW:
      return getDotIcon({ status, displayStatusColors, id });
    case STRUCTURE_MODE.OVERVIEW_WITH_DETAILS:
      return getDotIconWithName({ status, displayStatusColors, id, name });
    default:
      return getDotIcon({ status, displayStatusColors, id });
  }
};
