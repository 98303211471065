import { DynamicMapLayer, ImageMapLayer } from "react-esri-leaflet";
import L, { Content, LatLngTuple } from "leaflet";
import { memo, useCallback, useMemo, useEffect, useState, useRef } from "react";

import { ADDITIONAL_OVERLAY_NAME, EMPTY_FN } from "./../../../constants/general";
import { canopyStyleRadius, createCircle, disableMapPrefix } from "./MapView.utils";
import { useMapStore } from "../../../stores/MapStore";
import {
  ADDITIONAL_OVERLAYS_Z_INDEX,
  CANOPY_RADIUS_ZOOM,
  MAX_CANOPY_RADIUS,
  MIN_CANOPY_RADIUS,
  MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER,
} from "./MapView.constants";
import { LayerMenuTitle, LayersMenu as LayersMenuComponent, MenuSection } from "../../../components/LayersMenu";
import { FilterButton } from "../../../components/LayersMenu/LayersMenu.styled";
import { MenuSectionWrapper } from "./MapView.styled";

import { useDrawerStore, useMapSelectionStore, usePredominatedWorkTypesStore } from "../../../stores";
import { useCustomMapPane } from "../../../hooks";
import { MapTreeCanopyLayer } from "../../../components/MapTreeCanopyLayer/MapTreeCanopyLayer";
import { useTreeCanopyStore } from "../../../stores/TreeCanopyStore";
import useCommentsStore from "../../../stores/CommentStore";
import {
  AdditionalOverlayArgumentsInterface,
  getAdditionalOverlayPopupHTML,
  getTreeCanopyPopupHTML,
} from "./MapViewPopup.utils";
import { unitConverter } from "../../../utils/unitHelper";
import { MISSING_VALUE } from "../RightDrawerMenu/RightDrawerMenu.utils";
import {
  TREE_CANOPY_LAYER_KEY,
  TREE_CANOPY_POPUP_CLASS_NAME,
} from "../../../components/MapTreeCanopyLayer/MapTreeCanopyLayer.constants";
import { MapOpacityLayer } from "../../../components/MapOpacityLayer";
import { centerOfMass } from "@turf/turf";
import { CanopyGradient } from "../../../components/MapTreeCanopyLayer/CanopyGradient";
import { TileLayer } from "react-leaflet";
import useAlertsStore from "../../../stores/AlertsStore";
import { usePatrolsStore } from "../../../stores/PatrolsStore";
import { useCustomerAssetsLayer } from "../../../hooks/useCustomerAssetsLayer";
import { useRiskLayer } from "../../../hooks/useRiskLayer";
import { useEnvironmentalLayers } from "../../../hooks/useEnvironmentalLayers";
import { useLandfireLayers } from "../../../hooks/useLandfireLayers";
import { useWeatherLayers } from "../../../hooks/useWeatherLayers";
import { useMapInfoLayers } from "../../../hooks/useMapInfoLayers";
import { useImagerySource } from "../../../hooks/useImagerySource";
import { ReactComponent as FilterIcon } from "../../../assets/images/filter.svg";
import { PatrolFilter } from "../../../components/PatrolFilter";

const ADDITIONAL_OVERLAY_PANE_NAME = "ADDITIONAL_OVERLAY_PANE";

const CANOPY_CENTER_STYLE = {
  radius: 0.5,
  color: "var(--colors-white)",
  fillColor: "var(--colors-white)",
  fillOpacity: 1,
  interactive: false,
};

export const LayersMenu = memo(() => {
  const map = useMapStore((store) => store.map);
  const toggleLayerControl = useMapStore((store) => store.toggleLayerControl);
  const isLayerControlVisible = useMapStore((store) => store.layerControlVisible);
  const overlaySwitchControl = useMapStore((store) => store.overlaySwitchControl);
  const boundBox = useMapStore((store) => store.currentMapBounds);
  const selectedAdditionalOverlay = useMapStore((store) => store.selectedAdditionalOverlay);
  const additionalOverlayList = useMapStore((store) => store.additionalOverlayList);
  const additionalOverlayOpacity = useMapStore((store) => store.additionalOverlayOpacity);
  const treeCanopyData = useTreeCanopyStore((store) => store.geoJSON);
  const selectTreeCanopyId = useTreeCanopyStore((store) => store.selectCanopyId);
  const selectedCanopyId = useTreeCanopyStore((store) => store.selectedCanopyId);
  const clearStack = useDrawerStore((store) => store.clearStack);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const closeMapPopup = useMapStore((store) => store.closeMapPopup);
  const stack = useDrawerStore((store) => store.stack);
  const mapOpacityLayerControl = useMapStore((store) => store.mapOpacityLayerControl);
  const areCommentsVisible = useCommentsStore((store) => store.isLayerVisible);
  const treeCanopyOpacity = useTreeCanopyStore((store) => store.initialOpacity);
  const { toggleLayer: toggleAlerts } = useAlertsStore((store) => store.actions);
  const areAlertsVisible = useAlertsStore((store) => store.isLayerVisible);
  const isSelectionDrawerVisible = useMapSelectionStore((store) => store.isSelectionRHSDrawerVisible);
  const isRightDrawerVisible = (stack && stack.length > 0) || areCommentsVisible || isSelectionDrawerVisible;
  const [canopyTreeHeightMax, setCanopyTreeHeightMax] = useState(0);
  const isWorkTypeLayerVisible = usePredominatedWorkTypesStore((store) => !store.hidden);
  const toggleWorkTypeLayer = usePredominatedWorkTypesStore((store) => store.toggleHidden);
  const canopyCircleRef = useRef<L.Circle<any> | null>(null);
  const isPatrolLayerOn = usePatrolsStore((store) => store.isVisible);
  const togglePatrolLayer = usePatrolsStore((store) => store.setToggled);
  const [showPatrolFilter, setShowPatrolFilter] = useState(false);

  const {
    data: landfireData,
    toggleLayer: toggleLandfire,
    changeOverlay: changeLandfireOverlay,
    additionalLandfireData,
    checked: landfireChecked,
    showSwitch: showLandfireSwitch,
  } = useLandfireLayers();

  const { data: customerAssetsData, toggleLayer: toggleAssetsAndCustomerLayer } = useCustomerAssetsLayer();
  const {
    data: environmentalData,
    setVectorLayerVisibility,
    showSwitch: showEnvironmentalSwitch,
    switchChecked: environmentalSwitchChecked,
  } = useEnvironmentalLayers();

  const {
    data: imageryData,
    checked: imageryChecked,
    showSwitch: showImagerySwitch,
    toggleLayer: toggleImageryLayer,
    changeImagerySource,
  } = useImagerySource();

  const { data: mapInfoData, toggleLayer: toggleMapInfoLayer } = useMapInfoLayers();

  const { data: riskData, changeOverlayVisibility, toggleLayer: toggleRiskLayer, areTreesVisible } = useRiskLayer();

  const { data: weatherData } = useWeatherLayers();

  useCustomMapPane({
    name: ADDITIONAL_OVERLAY_PANE_NAME,
    zIndex: ADDITIONAL_OVERLAYS_Z_INDEX,
    map,
  });

  useEffect(() => {
    if (!canopyCircleRef.current) {
      return;
    }

    const radiusValue =
      currentZoomLevel > CANOPY_RADIUS_ZOOM
        ? canopyTreeHeightMax - MIN_CANOPY_RADIUS
        : canopyTreeHeightMax - MAX_CANOPY_RADIUS;

    canopyCircleRef.current.setRadius(radiusValue);
  }, [currentZoomLevel, canopyTreeHeightMax]);
  const openTreeCanopyPopup = useCallback(
    (e) => {
      if (!map || !e.target.feature || !e.target.feature.properties) {
        return;
      }
      const {
        properties: { treeHeightMin, treeHeightMax, treeSpecies, fallInRisk, id: canopyId },
        geometry,
      } = e.target.feature;

      const html = getTreeCanopyPopupHTML(
        treeHeightMin ? unitConverter(true, treeHeightMin) : null,
        treeHeightMax ? unitConverter(true, treeHeightMax) : null,
        treeSpecies ?? MISSING_VALUE
      );
      if (!html) {
        return;
      }
      let tooltipCoordinates: LatLngTuple = e.latlng;
      let canopyRadius: Nullable<L.Circle> = null;
      let canopyCenter: Nullable<L.Circle> = null;
      if (treeHeightMax) {
        setCanopyTreeHeightMax(treeHeightMax);
        const center = centerOfMass(geometry);
        const centerCoordinates = center.geometry?.coordinates.reverse() as [number, number];
        const radiusValue =
          currentZoomLevel > CANOPY_RADIUS_ZOOM
            ? canopyTreeHeightMax - MIN_CANOPY_RADIUS
            : canopyTreeHeightMax - MAX_CANOPY_RADIUS;

        canopyRadius = createCircle(centerCoordinates, canopyStyleRadius(radiusValue ?? 0, fallInRisk));
        canopyCenter = createCircle(centerCoordinates, CANOPY_CENTER_STYLE);
        canopyRadius.setStyle({ className: "canopy-circle", interactive: false });
        fallInRisk && canopyRadius.setStyle({ className: "canopy-fall-in-risk", interactive: false });

        canopyRadius.addTo(map);
        canopyCenter.addTo(map);

        canopyCircleRef.current = canopyRadius;

        const canopyRadiusBounds = canopyRadius.getBounds();
        tooltipCoordinates = [canopyRadiusBounds.getNorthEast().lat, centerCoordinates[1]];
      }

      const popup = L.popup({ className: TREE_CANOPY_POPUP_CLASS_NAME });
      popup.setLatLng(tooltipCoordinates);

      popup.setContent(html);

      popup.openOn(map);

      popup.on("remove", () => {
        canopyRadius && canopyRadius.remove();
        canopyCenter && canopyCenter.remove();
        setCanopyTreeHeightMax(0);
        canopyCircleRef.current = null;
      });
      selectTreeCanopyId(canopyId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map]
  );
  useEffect(() => {
    if (
      selectedAdditionalOverlay === TREE_CANOPY_LAYER_KEY &&
      overlaySwitchControl.ADDITIONAL_OVERLAYS.checked &&
      currentZoomLevel <= MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER
    ) {
      closeMapPopup();
    }
  }, [currentZoomLevel, selectedAdditionalOverlay, overlaySwitchControl, closeMapPopup]);

  useEffect(() => {
    if (!overlaySwitchControl.ADDITIONAL_OVERLAYS.checked) {
      selectTreeCanopyId(null);
    }
  }, [selectTreeCanopyId, overlaySwitchControl]);

  const additionalOverlay = useMemo(() => {
    if (!additionalOverlayList || !overlaySwitchControl.ADDITIONAL_OVERLAYS.checked || !selectedAdditionalOverlay) {
      return <></>;
    }
    const params = {
      opacity: additionalOverlayOpacity[selectedAdditionalOverlay],
      url: additionalOverlayList[selectedAdditionalOverlay].url,
    };

    if (additionalOverlayList[selectedAdditionalOverlay].layerType === "IMAGE_MAP") {
      return (
        <ImageMapLayer
          pane={ADDITIONAL_OVERLAY_PANE_NAME}
          name={ADDITIONAL_OVERLAY_NAME}
          {...params}
          {...additionalOverlayList[selectedAdditionalOverlay].params}
        />
      );
    }
    if (additionalOverlayList[selectedAdditionalOverlay].layerType === "TILE_MAP") {
      return (
        <TileLayer
          url={additionalOverlayList[selectedAdditionalOverlay].url}
          opacity={additionalOverlayOpacity[selectedAdditionalOverlay]}
        />
      );
    } else {
      return (
        <DynamicMapLayer
          f="image"
          key={selectedAdditionalOverlay}
          name={ADDITIONAL_OVERLAY_NAME}
          eventHandlers={{
            load: () => {
              disableMapPrefix(map);
            },
            remove: (e: L.LeafletEvent) => e?.layer?.unbindPopup(),
            add: (e: L.LeafletEvent) => {
              e.target.bindPopup(
                (_en: unknown, t: GeoJSON.FeatureCollection<GeoJSON.Geometry, AdditionalOverlayArgumentsInterface>) => {
                  if (t?.features?.length < 1) {
                    return null;
                  }

                  const popupName =
                    selectedAdditionalOverlay && additionalOverlayList
                      ? additionalOverlayList![selectedAdditionalOverlay!].popupName
                      : "";

                  return getAdditionalOverlayPopupHTML(t.features?.[0]?.properties, popupName) as Content;
                },
                { className: "additional-overlay-popup" }
              );
            },
          }}
          {...params}
          interactive={true}
          {...additionalOverlayList[selectedAdditionalOverlay].params}
          pane={ADDITIONAL_OVERLAY_PANE_NAME}
        />
      );
    }
  }, [
    additionalOverlayList,
    overlaySwitchControl.ADDITIONAL_OVERLAYS.checked,
    selectedAdditionalOverlay,
    additionalOverlayOpacity,
    map,
  ]);

  useEffect(() => {
    if (isPatrolLayerOn) setShowPatrolFilter(true);
  }, [isPatrolLayerOn]);

  return (
    <>
      <CanopyGradient />
      <LayersMenuComponent
        toggleLayerControl={toggleLayerControl}
        isLayerControlVisible={isLayerControlVisible}
        clearStack={clearStack}
        isRightDrawerVisible={isRightDrawerVisible}
      >
        <LayerMenuTitle toggleLayerControl={toggleLayerControl} />
        <MenuSectionWrapper>
          <MenuSection
            data={null}
            disabled={true}
            isSwitchChecked={areAlertsVisible}
            onClick={EMPTY_FN}
            switchOverlay={toggleAlerts}
            showSwitch={true}
            title="Alerts"
            type="multiple-select"
          />
          <MenuSection
            data={null}
            isSwitchChecked={false}
            onClick={EMPTY_FN}
            switchOverlay={EMPTY_FN}
            showSwitch={true}
            title="ACCESS POINTS"
            type="multiple-select"
            disabled
          />
          <MenuSection
            data={null}
            isSwitchChecked={isPatrolLayerOn}
            onClick={EMPTY_FN}
            switchOverlay={() => {
              togglePatrolLayer(!isPatrolLayerOn);
            }}
            showSwitch={true}
            title="Field Patrol"
            type="multiple-select"
            isExpandedByDefault={true}
            navButtons={
              <FilterButton
                Icon={FilterIcon}
                isActive={isPatrolLayerOn}
                onClick={() => setShowPatrolFilter(true)}
                disabled={!isPatrolLayerOn}
              />
            }
          />
          <MenuSection
            data={customerAssetsData}
            isSwitchChecked={true}
            onClick={({ name, key, value }) => toggleAssetsAndCustomerLayer(key, value as boolean)}
            showSwitch={false}
            title="Assets & Customer"
            type="multiple-select"
            isExpandedByDefault={true}
          />

          <MenuSection
            data={null}
            isSwitchChecked={isWorkTypeLayerVisible}
            onClick={EMPTY_FN}
            switchOverlay={toggleWorkTypeLayer}
            showSwitch={true}
            title="Work Types"
            type="multiple-select"
          />
          <MenuSection
            data={riskData}
            onClick={({ name, key, value }) => toggleRiskLayer(key, value)}
            showSwitch={false}
            title="Risk"
            isExpandedByDefault
            type="multiple-select"
            additionalOverlayData={{
              showSlideControl: false,
              onChangeSlideControl: (item) => changeOverlayVisibility(item.key, item.value),
              selectedAdditionalOverlay: null,
            }}
          />

          <MenuSection
            data={weatherData}
            onClick={EMPTY_FN}
            showSwitch={true}
            title="Weather"
            isExpandedByDefault
            type="multiple-select"
            disabled
          />
          <MenuSection
            data={environmentalData}
            isSwitchChecked={environmentalSwitchChecked}
            onClick={setVectorLayerVisibility}
            showSwitch={showEnvironmentalSwitch}
            title="Environmental"
            type="multiple-select"
            isExpandedByDefault
          />
          <MenuSection
            type="single-select"
            title="LANDFIRE LAYERS"
            data={landfireData}
            onClick={(item) => changeLandfireOverlay(item.key)}
            showSwitch={showLandfireSwitch}
            isSwitchChecked={landfireChecked}
            switchOverlay={toggleLandfire}
            additionalOverlayData={additionalLandfireData}
            isExpandedByDefault
          />
          <MenuSection
            data={mapInfoData}
            isSwitchChecked={false}
            onClick={(item) => toggleMapInfoLayer(item.key, item.value as boolean)}
            showSwitch={false}
            title="Map info"
            type="multiple-select"
            isExpandedByDefault
          />
          <MenuSection
            type="single-select"
            title="Imagery Sources"
            data={imageryData}
            onClick={({ key }) => changeImagerySource(key)}
            showSwitch={showImagerySwitch}
            isSwitchChecked={imageryChecked}
            switchOverlay={toggleImageryLayer}
            isExpandedByDefault
          />
        </MenuSectionWrapper>
      </LayersMenuComponent>
      {additionalOverlay}
      <MapTreeCanopyLayer
        map={map}
        hidden={!areTreesVisible}
        opacity={treeCanopyOpacity}
        geoJSON={treeCanopyData}
        onClick={openTreeCanopyPopup}
        selectedId={selectedCanopyId}
      />
      <MapOpacityLayer
        hidden={!mapOpacityLayerControl?.checked}
        boundBox={boundBox ? boundBox.pad(Math.sqrt(2) / 2) : null} //double bounds size by x,y axis
        map={map}
        opacity={mapOpacityLayerControl?.opacity ?? 0.3}
      />
      {showPatrolFilter && <PatrolFilter closeFilterPopup={() => setShowPatrolFilter(false)} />}
    </>
  );
});
