import L from "leaflet";

import ReadCommentMarker from "./../../assets/images/read_comment.svg";
import UnReadCommentMarker from "./../../assets/images/unread_comment.svg";

export const getCommentIcon = (read: boolean) => {
  const marker = !read ? ReadCommentMarker : UnReadCommentMarker;
  return new L.Icon({
    iconUrl: marker,
    iconSize: [20, 23],
  });
};
