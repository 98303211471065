import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { deleteContractorRoleUrl } from "../api";

export const DELETE_ROLE_MUTATION_KEY = "delete-contractor-role";

export const useDeleteContractorRole = () => {
  return useMutation(DELETE_ROLE_MUTATION_KEY, (item: { roleId: string; crewId: string }) =>
    ApiClient.delete(deleteContractorRoleUrl(item.crewId, item.roleId))
  );
};
