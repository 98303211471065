import styled from "styled-components";

import ReplyButtonImg from "./../../assets/images/reply_submit.svg";
import ReplyButtonEnabled from "./../../assets/images/reply_submit_enabled.svg";

export const ReplyTextAreaWrapper = styled.div<{ enabled?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.enabled ? "column" : "row")};
  ${(props) => (!props.enabled ? " align-items: center;" : "")};
  form {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  textarea {
    border: none;
    font-family: "Barlow";
    font-style: normal;
    outline: none;
    resize: none;
    font-weight: 600;
    font-size: 11px;
    color: var(--colors-black);
    border-radius: 10px;
    padding: 7px 9px 9px 9px;
    width: 90%;
    height: auto;
    ::placeholder {
      font-weight: 600;
      font-size: 11px;
      color: var(--colors-silver-2);
    }
  }
`;

export const ReplyTextArea = styled.textarea``;
export const ReplyButtonWrapper = styled.div<{ enabled?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: end;
  flex: 1;
  border-top: ${(props) => (props.enabled ? "1px solid #F1F1F1" : "none")};
`;

export const ReplyButton = styled.button<{ enabled: boolean }>`
  width: 19px;
  height: 19px;
  margin: 9px 9px 9px 0px;
  background-color: #e7ecee;
  border-radius: 3px;
  cursor: pointer;
  background-position: center;
  background-size: ${(props) => (props.enabled ? "18px 18px" : "10px 10px")};
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.enabled ? ReplyButtonEnabled : ReplyButtonImg)});
  border: none;
`;
