import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import {
  HierarchyResponse,
  MappedFeederGeoJson,
  RegionDTOProperties,
  RegionsResponseProperties,
  RISK_PRIORITY,
} from "../../../types/responses";
import { Breadcrumb } from "../../../components/Breadcrumbs";
import { TaxonomyItem, TaxonomyType } from "../../../types/TaxonomyItem";
import { getWholeFeederName } from "../MapView/MapView.utils";
import { FeederSegmentResponse } from "../../../types/responses/FeederSegmentResponse";

interface ComputeBreadcrumbsHierarchyArguments {
  regions: Nullable<Record<string, HierarchyResponse>>;
  selectedRegion: Nullable<string>;
  selectedFeeder?: MappedFeederGeoJson;
  rootRegionId: Nullable<string>;
}

export const computeBreadcrumbsHierarchy = ({
  regions,
  selectedRegion,
  selectedFeeder,
  rootRegionId,
}: ComputeBreadcrumbsHierarchyArguments) => {
  // root is always present
  const breadcrumbLinks: Breadcrumb[] = [{ name: "All", key: "", value: "" }];

  // add region hierarchy
  if (regions && selectedRegion && selectedRegion !== rootRegionId) {
    const regionBreadcrumbs = Object.keys(regions).map((key) => {
      return regions?.[key]?.regions?.find((region) => region.properties.id === selectedRegion);
    });

    regionBreadcrumbs
      .filter((item) => !!item)
      .slice(0, 1)
      .forEach((item) => {
        breadcrumbLinks.push({
          name: item?.properties.name ?? "",
          key: FORECAST_QUERY_PARAMS.REGION_ID,
          value: item?.properties.id ?? "",
        });
      });
  }

  // add selected feeder to breadcrumbs
  if (selectedFeeder) {
    breadcrumbLinks.push({
      name: getWholeFeederName(selectedFeeder.properties),
      key: FORECAST_QUERY_PARAMS.FEEDER_ID,
      value: selectedFeeder.properties.id,
    });
  }

  return breadcrumbLinks;
};

export const getTaxonomyItemType = (
  regionProperties: RegionsResponseProperties | RegionDTOProperties
): TaxonomyType => {
  if (regionProperties.feederId) {
    return "feeder";
  } else if (regionProperties.spanId) {
    return "span";
  } else {
    return "region";
  }
};

export const getTaxonomyItemId = (regionProperties: RegionsResponseProperties | RegionDTOProperties): string =>
  regionProperties.feederId || regionProperties.spanId || regionProperties.id;

export const RISK_PRIORITY_ORDINALS = {
  [RISK_PRIORITY.VIOLATION]: 5,
  [RISK_PRIORITY.CRITICAL]: 4,
  [RISK_PRIORITY.HIGH]: 3,
  [RISK_PRIORITY.MEDIUM]: 2,
  [RISK_PRIORITY.LOW]: 1,
};

export const sortTaxonomyData = (a: TaxonomyItem, b: TaxonomyItem) => {
  if (a.riskPriority !== b.riskPriority) {
    return RISK_PRIORITY_ORDINALS[b.riskPriority!] - RISK_PRIORITY_ORDINALS[a.riskPriority!];
  }

  if (b.riskScore === a.riskScore) {
    return a.name.localeCompare(b.name);
  }

  return (b.riskScore || 0) - (a.riskScore || 0);
};

export const mapSpansByFeederSegments = (feederId: string, feederSegments: Nullable<FeederSegmentResponse>) => {
  if (!feederId || !feederSegments?.features?.length) {
    return null;
  }
  return feederSegments.features.filter((item) => item.properties.feederId === feederId);
};
