import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { postCommentUrl } from "../api";
import { PostCommentRequestDto } from "../types/responses";

export const CREATE_COMMENT_MUTATION_KEY = "create-comment";

export const useCreateComment = ({ reloadComments = true }: { reloadComments?: boolean }) => {
  const queryClient = useQueryClient();

  return useMutation(
    CREATE_COMMENT_MUTATION_KEY,
    (value: PostCommentRequestDto) => ApiClient.post(postCommentUrl(), value),
    {
      onSettled: () => {
        if (!reloadComments) {
          return;
        }
        queryClient.invalidateQueries("comments");
      },
    }
  );
};
