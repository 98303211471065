import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { getFeedersApiUrl } from "../api";
import { logError } from "../services";
import { FeedersResponse } from "../types/responses";
import { useFeederStore } from "../stores";
import { isGeoJSONEmpty } from "../utils/mapUtils";
import { mapFeedersResponse } from "../pages/MapScreen/MapScreen.utils";

export const FEEDER_KEY = "feeders";

export const useFeeders = () => {
  const feeders = useFeederStore((store) => store.feedersGeoJson);
  const { setData } = useFeederStore((store) => store.actions);

  return useQuery(
    FEEDER_KEY,
    () => ApiClient.get<FeedersResponse>(getFeedersApiUrl()).then((response) => response.data),
    {
      enabled: !feeders || isGeoJSONEmpty(feeders),
      onError: (e) => logError(e),
      select: mapFeedersResponse,
      onSuccess: setData,
    }
  );
};

export default useFeeders;
