import styled, { css } from "styled-components";
import { Data2 } from "../../assets/styles/fonts.styled";
import { LAYERS_MENU_WIDTH, LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "../../constants/dimensions";
import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";

interface PopupWrapperProps {
  rhsEnabled?: boolean;
  lhsEnabled?: boolean;
  layerMenuEnabled?: boolean;
}

export const PopupContent = styled.div<PopupWrapperProps>`
  position: absolute;
  bottom: 29px;
  z-index: 1000;
  right: 0;
  height: 1px;
  ${(props) => {
    const width =
      (props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0) +
      (props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : 0) +
      (props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0);
    const marginLeft = props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0;
    const marginRight = props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0;
    return `width: calc(100% - ${width}px); margin-left: ${marginLeft}px; margin-right: ${marginRight}px;`;
  }}
  z-index: ${CUT_PLAN_Z_INDEX};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const PopupWrapper = styled.div`
  z-index: ${CUT_PLAN_Z_INDEX + 1};
  min-width: 438px;
  height: 54px;
  background: rgba(0, 0, 0, 0.69);
  border-radius: 62px;
  user-select: none;
  ${Data2}
  color: var(--colors-white);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div<{ isActive?: boolean }>`
  width: 35px;
  height: 35px;
  border: 1px solid #494848;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  :hover {
    border: 1px solid var(--colors-eastern-blue);
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: var(--colors-eastern-blue);
      border: 1px solid var(--colors-eastern-blue);
      path {
        fill: var(--colors-white);
      }
    `}
`;
