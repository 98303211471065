import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../utils/apiClient";
import { maintenanceAreas } from "../api";
import { MaintenanceAreaWithSpansDto, MaintenanceAreaDto } from "../types/responses";
import { QUERY_KEY } from "./useMaintenanceAreaAll";

export const CREATE_COMMENT_MUTATION_KEY = "create-comment";

export const useCreateMA = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, spanIds }: { name?: string; spanIds: string[] }) =>
      ApiClient.post<MaintenanceAreaWithSpansDto>(maintenanceAreas, { name, spanIds }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(QUERY_KEY, (oldData) => [
          { ...data.maintenanceArea },
          ...(oldData as MaintenanceAreaDto[]),
        ]);
      },
    }
  );
};
