import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)`
  flex-direction: column;
  background-color: var(--colors-white);
  height: 70%;
  width: 100%;
  align-self: flex-end;

  .checkbox__text {
    margin-left: 10px;
  }
`;

export const Header = styled(StyledFlex)`
  justify-content: space-between;
  align-ttems: center;
  padding: 15px;
  background-color: var(--colors-white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1;

  span:first-child {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #74828f;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
  }
`;

export const SectionElement = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--colors-white);
  padding: 7px 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.01);
  gap: 15px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--colors-nevada);
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: var(--colors-blue-chill);
    margin-top: 10px;
  }

  > div {
    padding: 0;
  }
`;

export const SeasonRadio = styled.div`
  flex: 1;

  label {
    font-weight: 600;
    cursor: pointer;
    font-size: 10px;
    letter-spacing: -0.035em;
    color: var(--colors-nevada);
    background-color: var(--colors-gallery);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 20px;
    border-radius: 3px;
    text-transform: capitalize;
  }

  input {
    opacity: 0;
    position: fixed;
    width: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:checked + label {
    background-color: var(--colors-teal-3);
    color: var(--colors-white);
  }
`;
