export const capitalizeFirstLetter = (text: string) => {
  if (!text?.length) {
    return "";
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const toUsdCurrency = (value: number | string, maximumFractionDigits = 0) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maximumFractionDigits,
  }).format(Number(value));
