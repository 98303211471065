import create from "zustand";

import { EMPTY_FN } from "../constants";
import { AccessPoint, AccessPointCategory } from "../types/responses";

export type AccessPointsStore = {
  actions: {
    toggleLayer: () => void;
    setData: (data: Nullable<AccessPoint[]>) => void;

    setCategories: (categories: Nullable<AccessPointCategory[]>) => void;
    getCategoryId: (id: string) => void;
  };
  isLayerVisible: boolean;
  data: Nullable<AccessPoint[]>;
  categories: Nullable<AccessPointCategory[]>;
};

const INITIAL_STATE = {
  isLayerVisible: false,
  categories: null,
  data: null,
  selectedMarkerId: null,
  actions: {
    toggleLayer: EMPTY_FN,
    setData: EMPTY_FN,

    setCategories: EMPTY_FN,
    getCategoryId: EMPTY_FN,
  },
};

export const useAccessPointsStore = create<AccessPointsStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    toggleLayer: () => set((state) => ({ isLayerVisible: !state.isLayerVisible })),
    setData: (data) => set({ data }),
    setCategories: (categories) => set({ categories }),
    getCategoryId: (id: string) => id,
  },
}));

export default useAccessPointsStore;
