import "leaflet/dist/leaflet.css";
import "./MapView.styles.css";

import L from "leaflet";
import { MapContainer, GeoJSON, Marker, ScaleControl } from "react-leaflet";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { EMPTY_FN } from "../../../constants/general";
import { bringElementToTop, disableMapPrefix, getRightPosition } from "./MapView.utils";
import { useMapStore } from "../../../stores/MapStore";
import {
  HOVERED_OBJECT_CLASS_PREFIX,
  MAX_ZOOM_LEVEL,
  MAP_OPACITY_CONTROL_OFFSET,
  MIN_ZOOM_LEVEL_FOR_SELECTION_TOOLS,
  SELECTED_FRAME_STYLE,
} from "./MapView.constants";
import { LAYERS_MENU_WIDTH, LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "../../../constants/dimensions";
import { Layer, MapLayerControl } from "../../../components/MapLayerControl";
import { MapOpacityImage, MapViewStyled } from "./MapView.styled";
import { MARKER_ZOOM_LEVEL } from "./TreeMarkerView.utils";
import { Feeders } from "./Feeders";
import { MAP_LOGO } from "../../../constants/z-index";
import { ReactComponent as MapImage } from "../../../assets/images/map.svg";
import { MapZoomControl } from "../../../components/MapZoomControl";
import { ReactComponent as SatelliteImage } from "../../../assets/images/satellite.svg";
import { ReactComponent as SelectionImage } from "../../../assets/images/selection.svg";
import { ReactComponent as SelectionActiveImage } from "../../../assets/images/selection_active.svg";
import { ServiceAreas } from "./ServiceAreas";
import { VantageLogo } from "../../../components/VantageLogo";
import { useLeftControlStore } from "../../../stores/LeftToggleStore";
import { useLegendExpandCollapse, useWindowSize } from "../../../hooks";
import { RightDrawerMenu } from "../RightDrawerMenu/RightDrawerMenu";
import {
  useDrawerStore,
  useMapMeasureStore,
  useMapSelectionStore,
  ASSET_TYPES,
  useCutPlanStore,
  useHierarchyStore,
} from "../../../stores";
import { useDynamicMapBounds } from "./useDynamicMapBounds";
import { LayersMenu } from "./LayersMenu";
import { useDynamicQueryParams } from "../useDynamicQueryParams";
import { MapDynamicStyles } from "./MapDynamicStyles";
import { CutPlan } from "../../../components/CutPlan/CutPlan";
import { CutPlan as CutPlanType } from "../../../types/responses/CutPlanResponse";
import { getCutPlanExportApiUrl } from "../../../api";
import { GanttStatic } from "../../../types/dhtmlxgantt";
import { MapOpacityControl } from "../../../components/MapOpacityControl";
import { MapMeasureTool } from "./MapMeasureTool";
import { MapCwcTool } from "./MapCwcTool";
import { MeasureToolButton } from "../../../components/MeasureToolButton";
import { EditModeButton } from "../../../components/EditModeButton";
import { SelectionTools } from "../../../components/SelectionTools";
import { MapSelectionControl, SelectionLayer } from "../../../components/MapSelectionControl/MapSelectionControl";
import { SelectionToolMenu } from "./SelectionToolMenu/SelectionToolMenu";
import { useMaintenanceArea } from "../../../hooks/useMaintainanceArea";
import useCommentsStore from "../../../stores/CommentStore";
import { Comments } from "./Comments";
import { AccessPoints } from "./AccessPoints";
import { TreeMarkersView } from "./TreeMarkersView";
import useCwcStore from "../../../stores/MapCwcStore";
import { OkCancelModalDialog } from "../../../components/ModalDialog/OkCancelModalDialog";
import { satelliteLayer, whiteLayer } from "../../../utils/mapUtils";
import { BASE_LAYER } from "../../../constants/map";
import { RISK_PRIORITY } from "../../../types/responses";

const ZOOM_SNAP = 0.44;

interface MapViewProps {
  displayLogo?: boolean;
}

const SELECTION_FRAME_ICON = new L.DivIcon({
  html: '<div class="selection-frame-icon"></div>',
  className: "selection-frame-icon",
  iconSize: [0, 0],
});

export const MapView: FC<MapViewProps> = memo(({ displayLogo = true }) => {
  const zoomLevel = useMapStore((store) => store.defaultZoomLevel);
  const setMap = useMapStore((store) => store.setMap);
  const invalidateMapSize = useMapStore((store) => store.invalidateMapSize);
  const baseLayer = useMapStore((store) => store.baseLayer);
  const map = useMapStore((store) => store.map);
  const isLeftControlOpen = useLeftControlStore((store) => store.toggled);
  const isLogoDisplayed = displayLogo && !isLeftControlOpen;
  const isFeederLevel = useHierarchyStore((store) => store.isFeederLevel);
  const isLayerControlVisible = useMapStore((store) => store.layerControlVisible);
  const setCurrentMapBounds = useMapStore((store) => store.setCurrentMapBounds);
  const setBaseLayerName = useMapStore((store) => store.setBaseLayerName);
  const stack = useDrawerStore((store) => store.stack);
  const isRightDrawerVisible = stack && stack.length > 0;
  const hoveredObjectId = useMapStore((store) => store.hoveredObjectId);
  const { toggleCutPlanHidden } = useCutPlanStore((store) => store.actions);
  const cutPlanHidden = useCutPlanStore((store) => store.cutPlanHidden);
  const isLeftSidebarOpen = useLeftControlStore((store) => store.toggled);
  const getRightSidebarItem = useDrawerStore((store) => store.getLastElement);
  const cutPlanSelectedId = useCutPlanStore((store) => store.cutPlanSelectedId);
  const { setCutPlanSelectedId } = useCutPlanStore((store) => store.actions);
  const toggleMapOpacityLayer = useMapStore((store) => store.toggleOpacityMapLayer);
  const changeMapLayerOpacity = useMapStore((store) => store.setMapLayerOpacity);
  const mapOpacityLayerControl = useMapStore((store) => store.mapOpacityLayerControl);
  const hiddenMapMeasureTool = useMapMeasureStore((store) => store.hidden);
  const toggleMapMeasureTool = useMapMeasureStore((store) => store.toggle);
  const hiddenSelectionTool = useMapSelectionStore((store) => store.hidden);
  const toggleMapSelectionTool = useMapSelectionStore((store) => store.toggle);
  const selectedTypeSelection = useMapSelectionStore((store) => store.selectedAssetType);
  const setAssetTypeSelection = useMapSelectionStore((store) => store.setAssetTypeSelection);
  const setLayers = useMapSelectionStore((store) => store.setLayers);
  const isSelectionDrawerVisible = useMapSelectionStore((store) => store.isSelectionRHSDrawerVisible);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const selectionFrameHidden = useMapSelectionStore((store) => store.selectionFrameHidden);
  const setSelectionFrameState = useMapSelectionStore((store) => store.setSelectionFrameState);
  const framePolygon = useMapSelectionStore((store) => store.framePolygon);
  const addSelectedIDs = useMapSelectionStore((store) => store.addSelectedIDs);
  const removeSelectedId = useMapSelectionStore((store) => store.removeSelectedId);
  const areCommentsVisible = useCommentsStore((store) => store.isLayerVisible);
  const editEnabled = useCwcStore((store) => store.isEditEnabled);
  const { setEditState: setEditEnabled } = useCwcStore((store) => store.actions);
  const [modalHidden, setModalHidden] = useState(true);
  const [action, setAction] = useState<() => void>();
  const hideModal = () => setModalHidden(true);
  const isCwcLayerVisible = useCwcStore((store) => store.isLayerVisible);
  const resetMapStore = useMapStore((store) => store.resetMapState);
  const center = useHierarchyStore((store) => store.defaultCenter);
  const setZoomControlStatus = useMapStore((store) => store.setZoomControlStatus);
  const { addPosition } = useHierarchyStore((store) => store.actions);

  const windowSize = useWindowSize();

  const isRightSidebarVisible = !!getRightSidebarItem();

  const gantt: GanttStatic = (window as any).gantt;

  useDynamicQueryParams({ map });
  useDynamicMapBounds();
  useLegendExpandCollapse();
  useMaintenanceArea();

  useEffect(() => {
    if (!map) {
      return;
    }
    L.Util.requestAnimFrame(() => setCurrentMapBounds(map.getBounds()));
  }, [map, setCurrentMapBounds]);

  useEffect(() => {
    setZoomControlStatus(isFeederLevel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeederLevel]);

  useEffect(() => {
    if (!map) {
      return;
    }
    invalidateMapSize();
  }, [invalidateMapSize, isLeftControlOpen, map, windowSize]);

  useEffect(() => {
    disableMapPrefix(map);
  }, [map, baseLayer]);

  useEffect(() => {
    return () => {
      resetMapStore();
    };
  }, [resetMapStore]);

  // bring hovered elements on the map to the top in case they are covered with other elements
  useEffect(() => {
    if (!hoveredObjectId) {
      return;
    }

    const element = document.getElementsByClassName(`${HOVERED_OBJECT_CLASS_PREFIX}${hoveredObjectId}`);
    if (!element[0]) {
      return;
    }

    bringElementToTop(element[0]);
  }, [hoveredObjectId]);

  const visibleAreaPosition = useMemo(() => {
    const left = isLeftSidebarOpen ? LEFT_SIDEBAR_WIDTH : 0;
    let right = 0;

    if (isLayerControlVisible) {
      right = LAYERS_MENU_WIDTH;
    }

    if (isRightSidebarVisible || isSelectionDrawerVisible || areCommentsVisible) {
      right = RIGHT_SIDEBAR_WIDTH;
    }

    return {
      left,
      right,
    };
  }, [isLeftSidebarOpen, isRightSidebarVisible, isLayerControlVisible, isSelectionDrawerVisible, areCommentsVisible]);

  const cutPlanMapFunction = useCallback(
    (cutPlan: CutPlanType, parentId: Nullable<string>) => {
      if (!cutPlan) {
        return;
      }

      return {
        id: cutPlan.id,
        text: cutPlan.name,
        start_date: cutPlan.startTime,
        end_date: cutPlan.finishTime,
        parent: cutPlan.spanId ? parentId : null,
        type: cutPlan.spanId ? gantt.config.types.task : gantt.config.types.project,
        additionalData: { hasStructureWork: cutPlan.hasStructureWork, parcelFlag: cutPlan.parcelFlag },
        priority: cutPlan?.riskPriority ?? RISK_PRIORITY.LOW,
        nodeId: cutPlan.id,
        spanId: cutPlan.spanId,
        feederId: cutPlan.feederId,
        centerPoint: cutPlan.centerPoint,
      };
    },
    [gantt.config.types.project, gantt.config.types.task]
  );

  const selectedPolygon = useMemo(() => {
    if (selectionFrameHidden || !framePolygon?.polygon) {
      return null;
    }
    return <GeoJSON data={framePolygon.polygon} style={SELECTED_FRAME_STYLE} key={uuidv4()} />;
  }, [framePolygon, selectionFrameHidden]);

  const addSelectedSpanBy = useCallback(
    (id: string[]): void => {
      addSelectedIDs(id, ASSET_TYPES.SPAN);
    },
    [addSelectedIDs]
  );

  const removeSelectedSpanBy = useCallback(
    (id: string): void => {
      removeSelectedId(id, ASSET_TYPES.SPAN);
    },
    [removeSelectedId]
  );

  const callAction = useCallback(() => {
    action !== undefined && action();
    setEditEnabled(false);
    hideModal();
  }, [action, setEditEnabled]);

  const checkEditBeforeToggle = (action: () => void) => {
    if (editEnabled) {
      return setModalHidden(() => {
        setAction(() => action);
        return false;
      });
    }
    return action();
  };

  useEffect(() => {
    if (!editEnabled || hiddenMapMeasureTool) {
      return;
    }
    !hiddenMapMeasureTool && toggleMapMeasureTool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenMapMeasureTool, editEnabled]);

  useEffect(() => {
    if (!map) return;

    return () => {
      map.removeLayer(satelliteLayer);
    };
  }, [map]);

  return (
    <>
      <MapDynamicStyles />
      <MapViewStyled data-testid="map" id="map" className={`${isLayerControlVisible ? "right-drawer-visible" : ""}`}>
        <MapContainer
          center={center!}
          className="map"
          doubleClickZoom={isFeederLevel}
          key="map"
          maxBoundsViscosity={1}
          maxZoom={MAX_ZOOM_LEVEL}
          whenCreated={setMap}
          zoom={zoomLevel}
          zoomControl={false}
          zoomSnap={ZOOM_SNAP}
        >
          {map && (
            <>
              <VantageLogo
                hidden={!isLogoDisplayed}
                variant="white"
                position="absolute"
                left="60px"
                top="28px"
                zIndex={MAP_LOGO}
              />
              <CutPlan
                hidden={cutPlanHidden}
                mapper={cutPlanMapFunction}
                left={visibleAreaPosition.left}
                right={visibleAreaPosition.right}
                addPosition={addPosition}
                selectedId={cutPlanSelectedId}
                setCutPlanSelectedId={setCutPlanSelectedId}
                onClose={toggleCutPlanHidden}
                exportUrl={getCutPlanExportApiUrl()}
                addSelectedSpanBy={addSelectedSpanBy}
                removeSelectedSpanBy={removeSelectedSpanBy}
              />
              <LayersMenu />
              {!hiddenMapMeasureTool && (
                <MapMeasureTool
                  map={map}
                  rightMenuEnabled={isRightDrawerVisible || isSelectionDrawerVisible || areCommentsVisible}
                  leftMenuEnabled={isLeftControlOpen}
                  layerMenuEnabled={isLayerControlVisible}
                />
              )}
              {isCwcLayerVisible && currentZoomLevel > MARKER_ZOOM_LEVEL && <MapCwcTool map={map} />}
              <RightDrawerMenu />
              <SelectionToolMenu />
              <Feeders />
              <ServiceAreas />
              <Comments map={map} />
              <MapSelectionControl
                top="247px"
                map={map}
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
              ></MapSelectionControl>
              <MapSelectionControl
                top="157px"
                map={map}
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
              >
                <SelectionLayer
                  map={map}
                  name={"MAP-SELECTION"}
                  turnedOn={!hiddenSelectionTool}
                  toggle={() => checkEditBeforeToggle(toggleMapSelectionTool)}
                  onClick={EMPTY_FN}
                  currentZoomLevel={currentZoomLevel}
                  isDisabled={currentZoomLevel < MIN_ZOOM_LEVEL_FOR_SELECTION_TOOLS}
                >
                  {hiddenSelectionTool ? <SelectionImage /> : <SelectionActiveImage />}
                </SelectionLayer>
              </MapSelectionControl>
              <MeasureToolButton
                top="202px"
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
                turnedOn={hiddenMapMeasureTool}
                toggle={() => checkEditBeforeToggle(toggleMapMeasureTool)}
              />
              <EditModeButton
                isRightDrawerVisible={
                  isRightDrawerVisible || isSelectionDrawerVisible || areCommentsVisible || isLayerControlVisible
                }
                setEditEnabled={setEditEnabled}
                editEnabled={editEnabled}
                onClick={() => {
                  if (!hiddenMapMeasureTool) toggleMapMeasureTool();
                  if (!hiddenSelectionTool) toggleMapSelectionTool();
                }}
              />
              <MapZoomControl
                bottom="110px"
                hidden={!isFeederLevel}
                map={map}
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
              />
              <MapLayerControl
                bottom={isFeederLevel ? "195px" : "114px"}
                map={map}
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
              >
                <Layer
                  checked={!!mapOpacityLayerControl?.checked}
                  map={map}
                  name={"MAP-OPACITY"}
                  onClick={toggleMapOpacityLayer}
                  id="opacity"
                >
                  <MapOpacityImage checked={!!mapOpacityLayerControl?.checked} />
                </Layer>
              </MapLayerControl>
              <SelectionTools
                rightMenuEnabled={isRightDrawerVisible || isSelectionDrawerVisible || areCommentsVisible}
                leftMenuEnabled={isLeftControlOpen}
                layerMenuEnabled={isLayerControlVisible}
                hidden={hiddenSelectionTool}
                selectedType={selectedTypeSelection}
                selectType={setAssetTypeSelection}
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "48px",
                  50
                )}
                setSelectedFeatures={setLayers}
                setSelectionFrame={setSelectionFrameState}
                selectionFrameHidden={selectionFrameHidden}
              />
              <MapOpacityControl
                hidden={!mapOpacityLayerControl?.checked}
                opacity={mapOpacityLayerControl?.opacity ?? 0}
                setOpacity={changeMapLayerOpacity}
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "64px",
                  isRightDrawerVisible || areCommentsVisible || isLayerControlVisible ? MAP_OPACITY_CONTROL_OFFSET : 0
                )}
              />
              <MapLayerControl
                bottom="24px"
                map={map}
                position="absolute"
                right={getRightPosition(
                  isSelectionDrawerVisible,
                  isLayerControlVisible,
                  isRightDrawerVisible || areCommentsVisible,
                  "12px",
                  12
                )}
              >
                <Layer
                  checked={baseLayer === BASE_LAYER.LIGHT_LAYER}
                  layer={whiteLayer}
                  map={map}
                  name={BASE_LAYER.LIGHT_LAYER}
                  onClick={setBaseLayerName}
                >
                  <MapImage />
                </Layer>
                <Layer
                  checked={baseLayer === BASE_LAYER.SATELLITE_LAYER}
                  layer={satelliteLayer}
                  map={map}
                  name={BASE_LAYER.SATELLITE_LAYER}
                  onClick={setBaseLayerName}
                >
                  <SatelliteImage />
                </Layer>
              </MapLayerControl>
              <AccessPoints map={map} />
            </>
          )}
          <ScaleControl position="bottomleft" metric={false} />
          <TreeMarkersView map={map} />
          {framePolygon && !selectionFrameHidden && (
            <>
              {selectedPolygon}
              {framePolygon.markerPosition && (
                <Marker position={framePolygon.markerPosition as L.LatLngExpression} icon={SELECTION_FRAME_ICON} />
              )}
            </>
          )}
        </MapContainer>
      </MapViewStyled>
      {!modalHidden && <OkCancelModalDialog hideModal={hideModal} callAction={callAction} modalHidden={modalHidden} />}
    </>
  );
});
