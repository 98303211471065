import { CircleMarkerOptions } from "leaflet";
import L from "leaflet";

import NdviImage from "./../../../assets/images/ndvi.png";
import FuelImage from "./../../../assets/images/fuel.png";
import VegHeightImage from "./../../../assets/images/veg_height.png";
import CoverImage from "./../../../assets/images/cover.png";
import VegTypeImage from "./../../../assets/images/veg_type.png";
import VegFuelImage from "./../../../assets/images/fuels.png";
import VegClassImage from "./../../../assets/images/clasification.png";
import OverlayPlaceholder from "./../../../assets/images/placeholder.png";
import SourceHighResImage from "./../../../assets/images/source_high_res.png";
import SourceMidResImage from "./../../../assets/images/source_mid_res.png";
import SourceLowResImage from "./../../../assets/images/source_low_res.png";
import { ADDITIONAL_LAYER_TYPE } from "../../../stores/MapStore";
import Bats from "./../../../assets/images/bats.svg";
import Orchids from "./../../../assets/images/orchids.svg";
import Flower from "./../../../assets/images/flowers.svg";
import ClosedSystemRoads from "./../../../assets/images/roads2.svg";
import NonActiveSystemRoads from "./../../../assets/images/non_system_roads.svg";
import NationalForest from "./../../../assets/images/national_forest.svg";
import NoSpray from "./../../../assets/images/no_spray.svg";
import HazardTree from "./../../../assets/images/hazard_tree.svg";
import Hydrology from "./../../../assets/images/hydrology_icon.svg";
import Lands from "./../../../assets/images/lands.svg";
import Wetlands from "./../../../assets/images/wetlands.svg";
import Placeholder from "./../../../assets/images/placeholder_vlayer.svg";
import TopographicImage from "./../../../assets/images/topo.png";
import VegetationTreeImage from "./../../../assets/images/vegetation_tree.png";
import { FeedersResponseProperties, RISK_PRIORITY } from "../../../types/responses";
import { SpanRiskPriority } from "../../../stores";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import DyingImg from "./../../../assets/images/dead.svg";
import MapleImg from "./../../../assets/images/maple.svg";
import PineImg from "./../../../assets/images/pine.svg";
import PearImg from "./../../../assets/images/pear.svg";
import DecidouousImg from "./../../../assets/images/deciduous.svg";
import CedarImg from "./../../../assets/images/cedar.svg";
import TreeSpeciesPlaceholder from "./../../../assets/images/tree_species.svg";
import { FeederSegmentResponse } from "../../../types/responses/FeederSegmentResponse";
import { LAYERS_MENU_WIDTH, RIGHT_SIDEBAR_WIDTH } from "../../../constants/dimensions";

export type AdditionalOverlays =
  | "NDVI"
  | "COVER"
  | "FUEL_DISTURBANCE"
  | "VEG_HEIGHT"
  | "VEG_TYPE"
  | "FUEL"
  | "VEG_CLASS"
  | "TOPOGRAPHIC"
  | "TREE_CANOPY";

export const HYDROLOGY_LAYER_NAMES = {
  Floodplain: "Floodplain",
  Floodway: "Floodway",
};

export const HYDROLOGY_LAYER_COLORS = {
  Floodplain: "rgba(43, 127, 255, 0.44)",
  Floodway: "rgba(2, 28, 67, 0.51)",
};

export const TREE_SPECIES_NAMES = {
  Dying: "Dying",
  Deciduous: "Other deciduous",
  Pear: "Bradford pear",
  Cedar: "Cedar",
  Maple: "Maple",
  Pine: "Pine",
};

export const getImagerySourceImage = (resolution: number) => {
  if (resolution <= 0.5) {
    return SourceHighResImage;
  } else if (resolution <= 2) {
    return SourceMidResImage;
  } else {
    return SourceLowResImage;
  }
};

export const getImageForAdditionalOverlay = (key: AdditionalOverlays) => {
  switch (key) {
    case "NDVI":
      return NdviImage;
    case "COVER":
      return CoverImage;
    case "FUEL_DISTURBANCE":
      return FuelImage;
    case "VEG_HEIGHT":
      return VegHeightImage;
    case "VEG_TYPE":
      return VegTypeImage;
    case "FUEL":
      return VegFuelImage;
    case "VEG_CLASS":
      return VegClassImage;
    case "TOPOGRAPHIC":
      return TopographicImage;
    case "TREE_CANOPY":
      return VegetationTreeImage;
    default:
      return OverlayPlaceholder;
  }
};

export const getImageForAdditionalLayer = (key: ADDITIONAL_LAYER_TYPE) => {
  switch (key) {
    case ADDITIONAL_LAYER_TYPE.BATS:
      return Bats;
    case ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS:
      return ClosedSystemRoads;
    case ADDITIONAL_LAYER_TYPE.CONSERVATION_LANDS:
      return Lands;
    case ADDITIONAL_LAYER_TYPE.CREES_FLOWER:
      return Flower;
    case ADDITIONAL_LAYER_TYPE.HAZARD_TREE:
      return HazardTree;
    case ADDITIONAL_LAYER_TYPE.NATIONAL_FOREST:
      return NationalForest;
    case ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS:
      return NonActiveSystemRoads;
    case ADDITIONAL_LAYER_TYPE.NO_SPRAY:
      return NoSpray;
    case ADDITIONAL_LAYER_TYPE.ORCHIDS:
      return Orchids;
    case ADDITIONAL_LAYER_TYPE.WETLANDS:
      return Wetlands;
    case ADDITIONAL_LAYER_TYPE.HYDROLOGY:
      return Hydrology;
    default:
      return Placeholder;
  }
};

export const disableMapPrefix = (map: Nullable<L.Map>) => {
  if (!map) {
    return;
  }
  map.attributionControl.setPrefix(false);
};

export const getRightPosition = (
  isSelectionDrawerVisible: boolean,
  isLayerMenuVisible: boolean,
  isRightMenuVisible: boolean,
  defaultValue: string,
  offset = 0
) => {
  if (!isRightMenuVisible && !isLayerMenuVisible && !isSelectionDrawerVisible) {
    return defaultValue;
  }

  if (isLayerMenuVisible) return `${LAYERS_MENU_WIDTH + offset}px;`;

  if (isRightMenuVisible || isSelectionDrawerVisible) {
    return `${RIGHT_SIDEBAR_WIDTH + offset}px;`;
  }

  return `${280 + offset}px;`;
};

export const bringElementToTop = (targetElement: Element) => {
  if (!targetElement || !targetElement.parentNode) {
    return;
  }
  // put the element at the bottom of its parent
  targetElement?.parentNode?.appendChild(targetElement);
};

export const getRiskPriorityColor = (priority: Maybe<SpanRiskPriority>, fromLHS = false) => {
  switch (priority) {
    case RISK_PRIORITY.VIOLATION:
      return fromLHS ? "var(--colors-transparent)" : "var(--colors-priority-violation)";
    case RISK_PRIORITY.CRITICAL:
      return "var(--colors-priority-critical)";
    case RISK_PRIORITY.HIGH:
      return "var(--colors-priority-high)";
    case RISK_PRIORITY.MEDIUM:
      return "var(--colors-priority-medium)";
    case RISK_PRIORITY.LOW:
      return "var(--colors-priority-low)";
    default:
      return "var(--colors-transparent)";
  }
};

export const getWholeFeederName = (feederProperties: Maybe<FeedersResponseProperties>) => {
  const partOne = getFeederName(feederProperties);
  const partTwo = getFeederName(feederProperties, true);
  return partTwo
    ? `${capitalizeFirstLetter(partOne ?? "")} - ${capitalizeFirstLetter(partTwo ?? "")}`
    : `${capitalizeFirstLetter(partOne ?? "")}`;
};

export const getFeederName = (feederProperties: Maybe<FeedersResponseProperties>, isAdditional = false) => {
  const feederNameParts = partsOfFeederName(feederProperties);
  if (!feederNameParts?.[0] && !isAdditional) {
    return mapFeederName(feederProperties);
  }
  if (!isAdditional) {
    return feederNameParts?.[1] ? `${feederNameParts[0]}` : feederNameParts[0];
  }
  return feederNameParts?.[1] ?? "";
};

export const partsOfFeederName = (feederProperties: Maybe<FeedersResponseProperties>) => {
  if (!feederProperties) {
    return ["", null];
  }
  const index =
    feederProperties.name.indexOf("–") < 0 ? feederProperties.name.indexOf("-") : feederProperties.name.indexOf("–");
  if (index < 1) {
    return [capitalizeFirstLetter(feederProperties.name), null];
  }
  return [
    capitalizeFirstLetter(feederProperties.name.substr(0, index - 1)),
    capitalizeFirstLetter(feederProperties.name.substr(index + 2)),
  ];
};

export const mapFeederName = (feederProperties: Maybe<FeedersResponseProperties>, withoutLabel = false) => {
  if (!feederProperties) {
    return "";
  }
  return `${capitalizeFirstLetter(feederProperties.name.split(" ")[0])}${
    withoutLabel ? `` : ` - ${feederProperties.label}`
  }`;
};

export const getTwoPartFeederName = (feederProperties: Maybe<FeedersResponseProperties>) => {
  if (!feederProperties) {
    return ["", null];
  }
  const dividedString = feederProperties.name.split(" - ");
  const partOne = capitalizeFirstLetter(dividedString?.[0]?.split(" ")?.[0]);
  const partTwo = capitalizeFirstLetter(dividedString?.[1]?.split(" ")?.[0]);
  return [partOne, partTwo];
};

export const getTreeSpeciesImageByName = (name: string) => {
  switch (name) {
    case TREE_SPECIES_NAMES.Cedar:
      return CedarImg;
    case TREE_SPECIES_NAMES.Deciduous:
      return DecidouousImg;
    case TREE_SPECIES_NAMES.Dying:
      return DyingImg;
    case TREE_SPECIES_NAMES.Pine:
      return PineImg;
    case TREE_SPECIES_NAMES.Maple:
      return MapleImg;
    case TREE_SPECIES_NAMES.Pear:
      return PearImg;
    default:
      return TreeSpeciesPlaceholder;
  }
};

export const filterSpansByFeederId = (feederSegments: Nullable<FeederSegmentResponse>, feederId: Nullable<string>) => {
  if (!feederId || !feederSegments?.features?.length) {
    return null;
  }
  return feederSegments.features
    .filter((feature) => feature.properties.feederId === feederId)
    .map((feature) => feature.properties.spanId);
};

export const createCircle = (center: [number, number], style: CircleMarkerOptions) => {
  return L.circle(center, style);
};

export const canopyStyleRadius = (radius: Maybe<number>, fallInRisk: boolean) => {
  return {
    radius: radius,
    fillColor: "var(--colors-transparent)",
    color: fallInRisk ? "var(--colors-canopy-risk)" : "var(--colors-white)",
    fillOpacity: 0.5,
    interactive: false,
  };
};
