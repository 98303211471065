import { useMutation } from "react-query";
import ApiClient from "../utils/apiClient";
import { deleteCommentUrl } from "../api";
import { useComments } from "./useComment";

export const DELETE_COMMENT_MUTATION_KEY = "delete-comment";

export const useDeleteComment = () => {
  const comments = useComments();
  return useMutation(
    DELETE_COMMENT_MUTATION_KEY,
    (commentID: string) => ApiClient.delete(deleteCommentUrl(commentID)),
    {
      onSettled: () => comments.mutate(),
    }
  );
};
