import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { crewsUrl } from "../api";

export const ADD_NEW_CREW_MUTATION_KEY = "add-new-crew";

export const useAddNewCrew = () => {
  return useMutation(ADD_NEW_CREW_MUTATION_KEY, (name: string) => ApiClient.post(crewsUrl, { name: name }));
};
