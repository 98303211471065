import styled from "styled-components";
import {
  variant,
  space,
  layout,
  SpaceProps,
  LayoutProps,
  fontSize,
  FontSizeProps,
  position,
  PositionProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  FontWeightProps,
  fontWeight,
  LineHeightProps,
} from "styled-system";

import { WHITE } from "../../constants";

interface ButtonStyledProps
  extends SpaceProps,
    LayoutProps,
    FontSizeProps,
    FontWeightProps,
    PositionProps,
    FlexboxProps,
    LineHeightProps,
    BorderProps {
  variant:
    | "primary"
    | "secondary"
    | "dark"
    | "light"
    | "transparent"
    | "pagination"
    | "warning"
    | "cancel"
    | "view"
    | "editable"
    | "warningSecondary";
  size?: "small";
}

const DefaultButton = styled.button<ButtonStyledProps>((props) => ({
  paddingLeft: props.size === "small" ? "22.5px" : "25px",
  paddingRight: props.size === "small" ? "22.5px" : "25px",
  paddingTop: props.size === "small" ? "6px" : "12px",
  paddingBottom: props.size === "small" ? "6px" : "13px",
  fontFamily: "Barlow",
  fontWeight: 600,
  fontSize: "18px",
  borderRadius: "5px",
  borderWidth: "0px",
  outline: "none",
  color: WHITE,
  maxHeight: "47px",
  cursor: "pointer",
}));

export const ButtonStyled = styled(DefaultButton)<ButtonStyledProps>(
  layout,
  space,
  fontSize,
  fontWeight,
  position,
  flexbox,
  border,
  variant({
    variants: {
      primary: {
        background: "linear-gradient(180deg, #1FA7B3 0%, #199FAA 100%);",
        border: "1px solid #139FAB;",
        "&:hover": {
          background: "linear-gradient(180deg, #20C5D2 0%, #16B0BD 100%)",
          border: "1px solid #29ADB9;",
        },
        "&:active": {
          background: "var(--colors-blue-chill)",
        },
        "&:disabled": {
          background: "#E7ECEE",
          color: "var(--colors-disabled-color)",
          cursor: "not-allowed",
          border: "1px solid #E7ECEE;",
        },
      },
      secondary: {
        color: "var(--colors-eastern-blue)",
        border: "1px solid var(--colors-eastern-blue)",
        cursor: "pointer",
        backgroundColor: "var(--colors-white)",
        "&:hover": {
          color: "var(--colors-white)",
          backgroundColor: "var(--colors-eastern-blue)",
        },
        "&:disabled": {
          background: "#E7ECEE",
          color: "var(--colors-disabled-color)",
          cursor: "not-allowed",
          border: "1px solid #E7ECEE;",
        },
      },
      light: {
        backgroundColor: "var(--colors-white)",
        "&:hover": {
          backgroundColor: "#c9e9f1",
        },
        "&:disabled": {
          backgroundColor: "#E0E4EA",
          color: "var(--colors-white)",
          cursor: "not-allowed",
        },
        color: "var(--colors-river-bad)",
      },
      dark: {
        backgroundColor: "#2B283D",
      },
      transparent: {
        backgroundColor: "transparent",
        "&:disabled": {
          color: "var(--colors-disabled-color)",
          cursor: "not-allowed",
        },
      },
      pagination: {
        height: "32px",
        width: "34px",
        padding: "0px",
        backgroundColor: "#242133",
        "&:hover:not(:disabled)": {
          backgroundColor: "#0084FF",
        },
        "&:active": {
          backgroundColor: "#0157A7",
        },
        "&:disabled": {
          color: "#3f3a54",
        },
      },
      warning: {
        margin: "0 auto",
        padding: "0",
        display: "inline-block",
        lineHeight: "34px",
        textAlign: " center",
        width: "79px",
        height: "34px",
        backgroundColor: "#BB1F1F",
        color: "var(--colors-white)",
        "&:hover": {
          backgroundColor: "#FE4B4B",
        },
        "&:active": {
          backgroundColor: "#E53A3A",
        },
      },
      cancel: {
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "inline-block",
        textAlign: " center",
        backgroundColor: "var(--colors-white)",
        border: " 1px solid #E4E4E4",
        boxSizing: "border-box",
        color: "var(--colors-black)",
        "&:hover": {
          border: "1px solid #454545",
          backgroundColor: "#D3D5D9",
        },
        "&:active": {
          border: "1px solid #E4E4E4",
          backgroundColor: "#D3D5D9",
        },
      },
      view: {
        padding: "0",
        display: "inline-block",
        lineHeight: "34px",
        textAlign: "center",
        width: "115px",
        height: "34px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "15px",
        backgroundColor: "var(--colors-eastern-blue)",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "var(--colors-java)",
        },
        "&:active": {
          backgroundColor: "var(--colors-blue-chill)",
        },
        "&:disabled": {
          backgroundColor: "#E0E4EA",
          color: "var(--colors-white)",
          cursor: "not-allowed",
        },
      },
      editable: {
        color: "var(--colors-eastern-blue)",
        borderRadius: "6px",
        border: "1px solid var(--colors-eastern-blue)",
        height: "30px",
        cursor: "pointer",
        backgroundColor: "var(--colors-white)",
        padding: "0 10px",
      },
      warningSecondary: {
        borderRadius: "6px",
        cursor: "pointer",
        backgroundColor: "var(--colors-white)",
        color: "#FF5858",
        "&:disabled": {
          backgroundColor: "var(--colors-light-gray)",
          color: "var(--colors-disabled-color)",
          cursor: "not-allowed",
        },
      },
    },
  })
);
