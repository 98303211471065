import { useCallback, useMemo } from "react";

import { useMapStore } from "../stores";
import { ADDITIONAL_LAYER_TYPE, OVERLAY_LAYERS } from "../stores/MapStore";
import { MAP_INFO_LAYERS, MAP_INFO_LAYERS_VALUES } from "../types/Layers";

export const useMapInfoLayers = () => {
  const areRoadsVisible = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.ROADS] ?? false);
  const isUrbanAreaVisible = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.URBAN_AREAS] ?? false);
  const vectorLayers = useMapStore((store) => store.vectorLayers);
  const setVectorLayerVisibility = useMapStore((store) => store.setVectorLayerVisibility);
  const toggleOverlay = useMapStore((store) => store.setOverlayMapLayerVisibility);

  const sectionData = useMemo(() => {
    const itemCheckedByKey = {
      [MAP_INFO_LAYERS.URBAN_AREAS]: isUrbanAreaVisible,
      [MAP_INFO_LAYERS.ROADS]: areRoadsVisible,
      [MAP_INFO_LAYERS.CLOSED_SYSTEM]: vectorLayers?.[ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS]?.checked ?? false,
      [MAP_INFO_LAYERS.NON_ACCESS]: vectorLayers?.[ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS]?.checked ?? false,
    };
    return MAP_INFO_LAYERS_VALUES.map((item) => {
      const newItem = { ...item };
      newItem.checked = itemCheckedByKey[item.key as MAP_INFO_LAYERS];
      return newItem;
    });
  }, [isUrbanAreaVisible, areRoadsVisible, vectorLayers]);

  const toggleLayer = useCallback(
    (key: string, value: boolean) => {
      switch (key) {
        case MAP_INFO_LAYERS.ROADS:
          return toggleOverlay({ name: OVERLAY_LAYERS.ROADS, key: OVERLAY_LAYERS.ROADS, value });
        case MAP_INFO_LAYERS.URBAN_AREAS:
          return toggleOverlay({ name: OVERLAY_LAYERS.URBAN_AREAS, key: OVERLAY_LAYERS.URBAN_AREAS, value });
        case MAP_INFO_LAYERS.NON_ACCESS:
          return setVectorLayerVisibility({
            name: ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS,
            key: ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS,
            value,
          });
        case MAP_INFO_LAYERS.CLOSED_SYSTEM:
          return setVectorLayerVisibility({
            name: ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS,
            key: ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS,
            value,
          });

        default:
          return;
      }
    },
    [setVectorLayerVisibility, toggleOverlay]
  );

  return { data: sectionData, toggleLayer };
};
