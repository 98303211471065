import { useCallback, useMemo } from "react";
import { useMapStore } from "../stores";
import { OVERLAY_LAYERS } from "../stores/MapStore";
import { useTreeCanopyStore } from "../stores/TreeCanopyStore";

import { RISK_LAYERS, RISK_LAYERS_VALUES } from "../types/Layers";

export const useRiskLayer = () => {
  const areInfringementsVisible = useMapStore(
    (store) => store.overlayMapLayers?.[OVERLAY_LAYERS.ENCROACHMENTS] ?? false
  );
  const areTreesVisible = useTreeCanopyStore((store) => store.layerVisible);

  const toggleOverlay = useMapStore((store) => store.setOverlayMapLayerVisibility);
  const changeVisibility = useMapStore((store) => store.setOpacityOverlayByKey);
  const toggleTrees = useTreeCanopyStore((store) => store.toggleLayer);
  const changeOpacityTree = useTreeCanopyStore((store) => store.setOpacity);

  const toggleLayer = useCallback(
    (key, value) => {
      switch (key) {
        case RISK_LAYERS.DANGER_TREES:
          break;
        case RISK_LAYERS.INFRINGEMENTS:
          toggleOverlay({ name: OVERLAY_LAYERS.ENCROACHMENTS, key: OVERLAY_LAYERS.ENCROACHMENTS, value: value });
          break;
        case RISK_LAYERS.TREEconaissance:
          toggleTrees();
          break;
        default:
          break;
      }
    },
    [toggleOverlay, toggleTrees]
  );

  const data = useMemo(() => {
    const itemCheckedByKey = {
      [RISK_LAYERS.DANGER_TREES]: false,
      [RISK_LAYERS.INFRINGEMENTS]: areInfringementsVisible,
      [RISK_LAYERS.TREEconaissance]: areTreesVisible,
    };
    return RISK_LAYERS_VALUES.map((item) => {
      const newItem = { ...item };
      newItem.checked = itemCheckedByKey[item.key as RISK_LAYERS];
      return newItem;
    });
  }, [areInfringementsVisible, areTreesVisible]);

  const changeOverlayVisibility = useCallback(
    (key, overlayValue) => {
      switch (key) {
        case RISK_LAYERS.INFRINGEMENTS:
          changeVisibility(OVERLAY_LAYERS.ENCROACHMENTS, overlayValue);
          break;
        case RISK_LAYERS.TREEconaissance:
          changeOpacityTree(overlayValue);
          break;

        default:
          break;
      }
    },
    [changeVisibility, changeOpacityTree]
  );

  return { toggleLayer, changeOverlayVisibility, data, areTreesVisible };
};
