import { GeoJSON, Marker } from "react-leaflet";
import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { useCustomMapPane, useCustomQuery } from "../../hooks";
import { VectorLayerResponse } from "../../types/responses";
import { getHydrologyStyle, getMarkerImage, getMarkerPositions, getStyleByCategory } from "./MapAdditionalLayer.utils";
import { ADDITIONAL_LAYER_CATEGORY, ADDITIONAL_LAYER_TYPE } from "../../stores/MapStore";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { useMapTooltip } from "../../hooks/useMapTooltip";
import { useExtentParams } from "../../hooks/useExtentParams";
import { getVectorLayersByExtent } from "../../api";
interface AdditionalLayerProps {
  geoJSON?: VectorLayerResponse;
  hidden?: boolean;
  map: Nullable<L.Map>;
  url?: string;
  zIndex?: number;
  category: string;
  additionalLayerType: string;
  disableMarkers?: boolean;
  onHoverDisabled?: boolean;
  checked: boolean;
  interactive: boolean;
  onClick?: (item: L.LeafletMouseEvent) => void;
  disablePolygons: boolean;
}

const ADDITIONAL_LAYER_PANE_NAME = "additional-layer-pane";

const ADDITIONAL_LAYER_DISABLE_CLUSTERING = 18;

export const MapAdditionalLayer: FC<AdditionalLayerProps> = memo(
  ({
    geoJSON,
    hidden = false,
    map,
    url = "",
    zIndex = 397,
    category,
    additionalLayerType,
    disableMarkers,
    onHoverDisabled = true,
    checked,
    interactive = false,
    onClick,
    disablePolygons = false,
  }) => {
    const { onMouseMove, onMouseOut } = useMapTooltip({ layer: map });
    const requestParams = useExtentParams();

    useCustomMapPane({
      map,
      name: ADDITIONAL_LAYER_PANE_NAME,
      zIndex,
    });

    const eventHandler = useCallback(
      (e: L.LeafletMouseEvent, mouseOut: boolean, tooltipContent = null) => {
        if (onHoverDisabled) {
          return;
        }
        if (mouseOut) {
          onMouseOut();
          return;
        }
        onMouseMove(e, tooltipContent);
      },
      [onHoverDisabled, onMouseMove, onMouseOut]
    );

    const shouldFetchData = !geoJSON && !!url && checked && !hidden;

    const { data, refetch } = useCustomQuery<VectorLayerResponse>(
      requestParams ? getVectorLayersByExtent({ ...requestParams, url: url }) : url,
      {
        enabled: shouldFetchData && requestParams !== null,
        keepPreviousData: true,
      }
    );

    useEffect(() => {
      if (!requestParams || !shouldFetchData) {
        return;
      }
      refetch();
    }, [requestParams, refetch, shouldFetchData]);

    const layerData = shouldFetchData ? data : geoJSON;

    const markers = useMemo(() => {
      const markersWithPositionsAndName = getMarkerPositions(layerData);
      if (disableMarkers || !layerData || !markersWithPositionsAndName) {
        return <></>;
      }
      return markersWithPositionsAndName.map((marker, id) => {
        if (!marker) {
          return <></>;
        }
        return (
          <Marker
            eventHandlers={{
              mouseover: (e: L.LeafletMouseEvent) => {
                eventHandler(e, false, marker.name);
              },
              mouseout: (e: L.LeafletMouseEvent) => {
                eventHandler(e, true);
              },
            }}
            icon={getMarkerImage(additionalLayerType as ADDITIONAL_LAYER_TYPE)}
            position={[marker.position[0], marker.position[1]]}
            key={`additionalLayerType_${uuidv4()}`}
            pane={ADDITIONAL_LAYER_PANE_NAME}
          />
        );
      });
    }, [layerData, additionalLayerType, disableMarkers, eventHandler]);

    const onEachFeature = useCallback(
      (feature, layer) => {
        if (!onClick) {
          return;
        }
        layer.on({
          click: onClick,
        });
      },
      [onClick]
    );

    if (hidden || !layerData || !checked) {
      return <></>;
    }

    return (
      <>
        {!disablePolygons && (
          <GeoJSON
            data={layerData}
            interactive={interactive}
            pane={ADDITIONAL_LAYER_PANE_NAME}
            onEachFeature={onEachFeature}
            style={
              category !== ADDITIONAL_LAYER_CATEGORY.HYDROLOGY
                ? getStyleByCategory(category as ADDITIONAL_LAYER_CATEGORY)
                : getHydrologyStyle
            }
          />
        )}
        {!disableMarkers && (
          <MapMarkerCluster disableClusteringAtZoom={ADDITIONAL_LAYER_DISABLE_CLUSTERING}>{markers}</MapMarkerCluster>
        )}
      </>
    );
  }
);
