import { useQuery } from "react-query";
import { getWorkOrdersByObjectId } from "../../../api";
import ApiClient from "../../../utils/apiClient";
import { WorkOrderByObjIdResponse } from "../types";

const getWorkOrders = (objectId: string) =>
  ApiClient.get<WorkOrderByObjIdResponse>(getWorkOrdersByObjectId(objectId)).then((res) => res.data.workOrders);

export const useWorkOrdersForObject = (objectId: string) => {
  return useQuery(`work-orders-for-${objectId}`, () => getWorkOrders(objectId), {
    retry: false,
  });
};
