import { LatLngBounds } from "leaflet";
import { FC, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { FeatureGroup, GeoJSON } from "react-leaflet";
import L from "leaflet";

import { useCustomMapPane, useMapOptimizedHiddenFlag } from "../../hooks";
import MapHelper from "../../utils/mapHelper";

const MAP_OPACITY_PANE_NAME = "opacity";

interface MapOpacityLayerProps {
  hidden: boolean;
  boundBox: Nullable<LatLngBounds>;
  map: Nullable<L.Map>;
  zIndex?: number;
  opacity?: number;
}

export const OPACITY_MAP_STYLE = {
  fillColor: "rgb(0, 0, 0)",
  fillOpacity: 0.3,
  weight: 0,
  color: "transparent",
};

const renderer = L.svg({ padding: 1, pane: MAP_OPACITY_PANE_NAME });

export const MapOpacityLayer: FC<MapOpacityLayerProps> = ({ hidden, boundBox, map, zIndex = 350, opacity = 0.3 }) => {
  const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
  useCustomMapPane({
    name: MAP_OPACITY_PANE_NAME,
    zIndex,
    map,
  });

  const rectangle = useMemo(() => {
    if (!boundBox) {
      return <></>;
    }

    return (
      <GeoJSON
        data={MapHelper.bboxPolygonFromBounds(boundBox)}
        key={uuidv4()}
        pane={MAP_OPACITY_PANE_NAME}
        style={Object.assign(OPACITY_MAP_STYLE, { fillOpacity: opacity, renderer: renderer })}
        interactive={false}
      />
    );
  }, [boundBox, opacity]);

  if (optimizedHidden) {
    return <></>;
  }

  return <FeatureGroup pane={MAP_OPACITY_PANE_NAME}>{rectangle} </FeatureGroup>;
};
