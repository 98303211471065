import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;

  video {
    width: 100%;
  }

  img {
    max-width: 480px;
    object-fit: contain;
  }
`;
