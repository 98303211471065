import React, { useEffect, useState, useMemo } from "react";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Wrapper, StyledLink, FormWrapper, FormTitle, Label, AddCrew, HeaderButton } from "./AddEditPlan.styled";
import { StyledFlex, StyledGrid, FlexItem } from "../../assets/styles/flex.styled";
import { ReactComponent as Shareable } from "../../assets/images/shareable-crew.svg";
import { ReactComponent as MoreInfo } from "../../assets/images/more-info.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left.svg";
import { CREATE_PLAN_SUCCESSFULL, EDIT_PLAN_SUCCESSFULL, SOMETHING_WENT_WRONG_MESSAGE } from "../../constants/messages";
import { plansRoute } from "../../routes";
import { Chip } from "../Chip";
import { Status } from "../PlanCard/PlanCard.styled";
import { Input } from "../Input/Input";
import { AddCrewPopup } from "../AddCrewPopup";
import { DatepickerField } from "../Datepicker";
import useRegions from "../../hooks/useRegions";
import useCrews from "../../hooks/useCrews";
import usePlan from "../../hooks/usePlan";
import useEditPlan from "../../hooks/useEditPlan";
import useCreatePlan from "../../hooks/useCreatePlan";
import useMessageStore, { MessageType } from "../../stores/MessagesStore";
import { useParams } from "react-router-dom";
import { toLocalDate } from "../../utils/dateUtils";
import { PLAN_STATUS } from "../../types/responses";
import { roundValue } from "../../utils/unitHelper";
import { calculatePercent, getAmountFromPercent } from "./AddEditPlan.util";

const DATE_FORMAT = "MMMM d, yyyy";

const planSchema = Yup.object().shape({
  planName: Yup.string().required("Plan name is required."),
  startDate: Yup.string().required("Start date is required."),
  endDate: Yup.string().required("End date is required."),
  regionGroups: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().typeError("Value must be a number."),
    })
  ),
});

interface FormData {
  planName: string;
  startDate: string;
  endDate: string;
  totalBudget: number;
  budgetByRegions: any;
}

export const AddEditPlan = () => {
  const { data: regionGroups } = useRegions();
  const { data: crews } = useCrews();
  const [crewsByRegion, setCrewsByRegion] = useState<{ crewId: string; regionGroupId: string }[]>([]);
  const { id: planId } = useParams<{ id: string }>();
  const isEditMode = !!planId;
  const { data: plan } = usePlan(planId, isEditMode);
  const [budgets, setBudgets] = useState<{ budget: number; regionGroupId: string; percentage: number }[]>([]);
  const { mutateAsync: editPlan } = useEditPlan();
  const { mutateAsync: createPlan } = useCreatePlan();
  const setMessage = useMessageStore((store) => store.setErrorMessage);
  const resetStore = useMessageStore((store) => store.resetStore);
  const [isCrewsFormDirty, setCrewsFormDirty] = useState(false);

  const formInitialValues: FormData = useMemo(() => {
    return {
      planName: plan?.planDetails.name ?? "",
      startDate: toLocalDate(plan?.planDetails.start.toString(), DATE_FORMAT) ?? "",
      endDate: toLocalDate(plan?.planDetails.end?.toString(), DATE_FORMAT) ?? "",
      totalBudget: plan?.planBudget.totalBudget ?? 0,
      budgetByRegions: plan?.planBudget.budgetByRegions,
    };
  }, [plan]);

  useEffect(() => {
    if (regionGroups?.length === 0) return;

    setCrewsByRegion(plan?.planCrew.crewsByRegion ?? []);
    const initialBudgets =
      regionGroups?.map((regionItem) => {
        const planBudget = plan?.planBudget.budgetByRegions.find((item) => item.regionGroupId === regionItem.id);
        return {
          percentage: calculatePercent(plan?.planBudget.totalBudget ?? 0, planBudget?.budget ?? 0),
          budget: planBudget?.budget ?? 0,
          regionGroupId: regionItem.id,
        };
      }) ?? [];

    setBudgets(initialBudgets);
  }, [plan, setCrewsByRegion, setBudgets, regionGroups]);

  useEffect(() => {
    return () => resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const closeAddCrewPopup = (e: MouseEvent) => {
      if ((e.target as HTMLElement).classList.contains("addCrewBtn")) return;

      const isInPopup = Array.from(document.querySelectorAll(".addCrewBtn")).some((item: any) =>
        item.contains(e.target)
      );
      if (isInPopup) return;

      document.querySelector(".addCrewBtn.isOpen")?.classList.remove("isOpen");
    };

    document.addEventListener("mousedown", closeAddCrewPopup);

    return () => {
      document.removeEventListener("mousedown", closeAddCrewPopup);
    };
  }, []);

  const removeCrewFromRegion = (regionId: string, crewId: string) => {
    setCrewsByRegion((prevValues) =>
      prevValues.filter((item) => !(item.crewId === crewId && item.regionGroupId === regionId))
    );
    setCrewsFormDirty(true);
  };

  const addCrewToRegion = (regionId: string, crewId: string) => {
    setCrewsByRegion((prevValues) => [...prevValues, { crewId, regionGroupId: regionId }]);
    setCrewsFormDirty(true);
  };

  const isShareable = (regionGroupId: string, crewId: string) => {
    return crewsByRegion.filter((item) => item.regionGroupId !== regionGroupId && item.crewId === crewId).length > 0;
  };

  const submitPlan = (formValues: FormData, status: PLAN_STATUS) => {
    const { planName, startDate, endDate, totalBudget } = formValues;

    const planData = {
      planDetails: {
        planName,
        start: toLocalDate(startDate, "yyyy-MM-dd"),
        end: toLocalDate(endDate, "yyyy-MM-dd"),
        status: status.toUpperCase(),
      },
      planBudget: {
        totalBudget: totalBudget,
        budgetByRegions: budgets.map((budget) => ({ budget: budget.budget, regionGroupId: budget.regionGroupId })),
      },
      planCrew: {
        crewsByRegion: crewsByRegion,
      },
    };

    resetStore();
    if (isEditMode) {
      editPlan({ planId, plan: planData })
        .then(() => setMessage(EDIT_PLAN_SUCCESSFULL, MessageType.Success))
        .catch(() => setMessage(SOMETHING_WENT_WRONG_MESSAGE, MessageType.Error));
    } else {
      createPlan({ plan: planData })
        .then(() => setMessage(CREATE_PLAN_SUCCESSFULL, MessageType.Success))
        .catch(() => setMessage(SOMETHING_WENT_WRONG_MESSAGE, MessageType.Error));
    }
  };

  const updateByPercent = (regionId: string, newPercent: number, totalBudget: number) => {
    const updatedBudgets = budgets.map((budget) => {
      if (budget.regionGroupId === regionId) {
        return {
          ...budget,
          percentage: roundValue(newPercent),
          budget: getAmountFromPercent(newPercent, totalBudget ?? 0),
        };
      }
      return budget;
    });
    setBudgets(updatedBudgets);
  };

  const updateBudget = (totalBudget: number) => {
    const updatedBudgets = budgets.map((budget) => ({
      ...budget,
      percentage: roundValue(budget.percentage),
      budget: getAmountFromPercent(budget.percentage, totalBudget ?? 0),
    }));
    setBudgets(updatedBudgets);
  };

  return (
    <Wrapper>
      <Formik
        initialValues={formInitialValues}
        validationSchema={planSchema}
        isInitialValid={isEditMode}
        validateOnBlur={true}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ values: formData, setFieldValue, isValid, setFieldTouched, dirty }) => {
          const isSubmitDisabled = !(isValid && (dirty || isCrewsFormDirty));
          return (
            <>
              <StyledLink to={plansRoute} className="backLink">
                <ArrowLeft /> Back
              </StyledLink>
              <StyledFlex alignItems="center" paddingBottom="10px" gap="20px" borderBottom="1px solid #EEEEEE">
                <span>{formData.planName || "PLAN NAME"}</span>
                {plan && (
                  <Status data-status={plan.planDetails.status.toLocaleLowerCase()}>
                    {plan.planDetails.status.toLocaleLowerCase()}
                  </Status>
                )}
                <Label className="year">
                  <span>{formData.startDate ? new Date(formData.startDate).getFullYear() : ""}</span>
                  {(formData.startDate || formData.endDate) && " - "}
                  <span>{formData.endDate ? new Date(formData.endDate).getFullYear() : ""}</span>
                </Label>
                <StyledFlex gap="20px" marginLeft="auto">
                  {isEditMode && (
                    <HeaderButton
                      variant="warningSecondary"
                      padding="8px"
                      onClick={() => submitPlan(formData, PLAN_STATUS.Archived)}
                      disabled={isSubmitDisabled}
                    >
                      Archive
                    </HeaderButton>
                  )}
                  <StyledLink to={plansRoute}>Cancel</StyledLink>
                  <HeaderButton
                    variant="secondary"
                    padding="7.5px 23.5px"
                    onClick={() => submitPlan(formData, PLAN_STATUS.Draft)}
                    disabled={isSubmitDisabled}
                  >
                    Save as Draft
                  </HeaderButton>
                  <HeaderButton
                    variant="primary"
                    padding="7.5px 29px"
                    onClick={() => submitPlan(formData, PLAN_STATUS.Active)}
                    disabled={isSubmitDisabled}
                  >
                    Activate
                  </HeaderButton>
                </StyledFlex>
              </StyledFlex>

              <Form>
                <StyledGrid
                  gridTemplateColumns="repeat(auto-fit, minmax(300px, 355px))"
                  gap="50px"
                  padding="30px"
                  height="calc(100vh - 350px)"
                  overflowY="scroll"
                >
                  <FormWrapper>
                    <FormTitle>Plan setup</FormTitle>
                    <Input name="planName" type="string" label="Plan Name" onChange={resetStore} />
                    <DatepickerField
                      my="15px"
                      label="Start Date"
                      name="startDate"
                      maxDate={new Date(formData.endDate || "02/01/2970")}
                      onChange={resetStore}
                    />
                    <DatepickerField
                      my="15px"
                      label="End Date"
                      name="endDate"
                      minDate={new Date(formData.startDate || "02/01/1970")}
                      onChange={resetStore}
                    />
                  </FormWrapper>
                  <FormWrapper>
                    <FormTitle>Budget configuration</FormTitle>
                    <Input
                      unitSign="$"
                      name="totalBudget"
                      onChange={(e) => {
                        updateBudget(Number(e.target.value));
                      }}
                      type="number"
                      label="Total budget"
                    />
                    <Label>Regions</Label>
                    <StyledFlex mt="5px" flexDirection="column">
                      <StyledFlex gap="7px" borderTop="1px solid var(--colors-seashell)" pt="9px" pb="20px">
                        <MoreInfo />
                        <Label className="info">Regions management is done in the company configuration panel.</Label>
                      </StyledFlex>
                      <FieldArray
                        name="regionGroups"
                        render={() => (
                          <div>
                            {regionGroups?.map((group, idx) => (
                              <div key={`regionInput-${idx}`}>
                                <Label className="regionName">{group.name}</Label>
                                <StyledFlex gap="20px" mt="9px">
                                  <FlexItem flex={35}>
                                    <Input
                                      name={`regionGroups[${idx}].percent`}
                                      unitSign="%"
                                      type="number"
                                      min={0}
                                      disabled={!formData.totalBudget}
                                      max={100}
                                      required
                                      value={budgets.find((budget) => budget.regionGroupId === group.id)?.percentage}
                                      onKeyUp={(e) => {
                                        const targetEl = e.target as HTMLInputElement;
                                        if (parseInt(targetEl.value) > parseInt(targetEl.max)) {
                                          return (targetEl.value = targetEl.max);
                                        }
                                        if (parseInt(targetEl.value) < parseInt(targetEl.min)) {
                                          return (targetEl.value = targetEl.min);
                                        }
                                      }}
                                      onChange={(e) => {
                                        updateByPercent(group.id, Number(e.target.value), formData.totalBudget);
                                        setFieldValue(
                                          `regionBudget[${idx}].amount`,
                                          getAmountFromPercent(Number(e.target.value), formData.totalBudget)
                                        );
                                      }}
                                    />
                                  </FlexItem>
                                  <FlexItem flex={75}>
                                    <Input
                                      name={`regionGroups[${idx}].amount`}
                                      unitSign="$"
                                      type="text"
                                      disabled={!formData.totalBudget}
                                      min={0}
                                      value={budgets.find((budget) => budget.regionGroupId === group.id)?.budget}
                                      onChange={(e) => {
                                        setFieldTouched(`regionGroups[${idx}].amount`);
                                        setBudgets((prevBudgets) =>
                                          prevBudgets.map((budget) => {
                                            if (budget.regionGroupId === group.id) {
                                              return {
                                                ...budget,
                                                percentage: calculatePercent(
                                                  formData.totalBudget ?? 0,
                                                  Number(e.target.value)
                                                ),
                                                budget: Number(e.target.value),
                                              };
                                            }
                                            return budget;
                                          })
                                        );
                                      }}
                                      required
                                    />
                                  </FlexItem>
                                </StyledFlex>
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    </StyledFlex>
                  </FormWrapper>
                  <FormWrapper>
                    <FormTitle>Crew</FormTitle>
                    {regionGroups?.map((regionGroup, idx) => (
                      <div key={`crewByRegion-${idx}`}>
                        <StyledFlex
                          mt="9px"
                          justifyContent="space-between"
                          borderBottom="1px solid var(--colors-seashell)"
                          paddingBottom="7.5px"
                        >
                          <Label>{regionGroup.name}</Label>
                          <AddCrew
                            className="addCrewBtn"
                            onClick={(e) => {
                              const targetElement = e.target as HTMLElement;
                              if (targetElement?.classList.contains("isOpen")) {
                                targetElement?.classList.remove("isOpen");
                                return;
                              }
                              document.querySelector(".addCrewBtn.isOpen")?.classList.remove("isOpen");
                              targetElement?.classList.add("isOpen");
                            }}
                          >
                            Add
                            <AddCrewPopup
                              crews={crews || []}
                              toggleCrew={(crewId) => {
                                const isExist = crewsByRegion
                                  .filter((item) => item.regionGroupId === regionGroup.id)
                                  .some((item) => item.crewId === crewId);

                                if (isExist) {
                                  removeCrewFromRegion(regionGroup.id, crewId);
                                } else {
                                  addCrewToRegion(regionGroup.id, crewId);
                                }
                              }}
                              crewsByRegion={
                                crewsByRegion
                                  .filter((item) => item.regionGroupId === regionGroup.id)
                                  .map((item) => ({
                                    id: item.crewId,
                                    name: crews?.find((crew) => crew.id === item.crewId)?.name!,
                                  })) ?? []
                              }
                            />
                          </AddCrew>
                        </StyledFlex>
                        <StyledFlex height="77px" mt="10px" flexWrap="wrap" gap="10px" overflowY="scroll">
                          {crewsByRegion
                            .filter((item) => item.regionGroupId === regionGroup.id)
                            .map((item) => (
                              <Chip
                                value={crews?.find((crew) => crew.id === item.crewId)?.name!}
                                onDelete={() => removeCrewFromRegion(regionGroup.id, item.crewId)}
                                Icon={isShareable(regionGroup.id, item.crewId) ? Shareable : null}
                              />
                            ))}
                        </StyledFlex>
                      </div>
                    ))}
                  </FormWrapper>
                </StyledGrid>
              </Form>
            </>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
