import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { crewsConfigurationUrl } from "../api";
import { logError } from "../services";
import { CrewConfiguration } from "../types/responses/Crews";

export const CREW_CONFIG_QUERY_KEY = "crews-configuration";

export const useCrewConfiguration = () => {
  return useQuery(
    CREW_CONFIG_QUERY_KEY,
    () => ApiClient.get<CrewConfiguration>(crewsConfigurationUrl).then((response) => response.data),
    {
      onError: (e) => logError(e),
    }
  );
};

export default useCrewConfiguration;
