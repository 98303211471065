import { Position } from "geojson";
import L from "leaflet";

import { ADDITIONAL_LAYER_CATEGORY, ADDITIONAL_LAYER_TYPE } from "../../stores/MapStore";
import BatsMarker from "./../../assets/images/bat_area_marker.svg";
import ClosedRoads from "./../../assets/images/closed_system_roads_marker.svg";
import ConservationLands from "./../../assets/images/conservation_marker.svg";
import GladeFlower from "./../../assets/images/glade_flower_marker.svg";
import HazardTreeMarker from "./../../assets/images/hazard_tree_marker.svg";
import NationalForestMarker from "./../../assets/images/nat_forest_marker.svg";
import NoAccessRoad from "./../../assets/images/no_access_roads_marker.svg";
import NoSprayMarker from "./../../assets/images/no_spray_marker.svg";
import OrchidMarker from "./../../assets/images/orchid_marker.svg";
import WetlandsMarker from "./../../assets/images/wetlands_marker.svg";
import PlaceholderMarker from "./../../assets/images/placeholder_marker.svg";
import HydrologyMarker from "./../../assets/images/hydrology_marker.svg";

import BatsIcon from "./../../assets/images/bat_icon.svg";
import ClosedRoadsIcon from "./../../assets/images/closed_system_roads_icon.svg";
import ConservationLandsIcon from "./../../assets/images/conservation_lands_icon.svg";
import GladeFlowerIcon from "./../../assets/images/glade_flower_icon.svg";
import HazardTreeIcon from "./../../assets/images/hazard_icon.svg";
import NationalForestIcon from "./../../assets/images/nat_forest_icon.svg";
import NoAccessRoadIcon from "./../../assets/images/no_access_roads_icon.svg";
import NoSprayIcon from "./../../assets/images/no_spray_icon.svg";
import OrchidIcon from "./../../assets/images/orchids_icon.svg";
import WetlandsIcon from "./../../assets/images/wetlands_icon.svg";
import HydrologyIcon from "./../../assets/images/hydrology.svg";
import {
  DEMOGRAPHIC_STYLE,
  HYDROLOGY_DEFAULT_STYLE,
  HYDROLOGY_FLOODPLAIN_STYLE,
  HYDROLOGY_FLOODWAY_STYLE,
  LANDS_STYLE,
  NONE_CATEGORY_STYLE,
  ROADS_STYLE,
  SENSITIVE_SPECIES_STYLE,
} from "./MapAdditionalLayer.constants";
import { VectorLayerResponse } from "../../types/responses";
import MapHelper from "../../utils/mapHelper";

export const getMarkerImage = (additionalLayerType: ADDITIONAL_LAYER_TYPE) => {
  let marker = PlaceholderMarker;
  switch (additionalLayerType) {
    case ADDITIONAL_LAYER_TYPE.BATS:
      marker = BatsMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS:
      marker = ClosedRoads;
      break;
    case ADDITIONAL_LAYER_TYPE.CONSERVATION_LANDS:
      marker = ConservationLands;
      break;
    case ADDITIONAL_LAYER_TYPE.CREES_FLOWER:
      marker = GladeFlower;
      break;
    case ADDITIONAL_LAYER_TYPE.HAZARD_TREE:
      marker = HazardTreeMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.NATIONAL_FOREST:
      marker = NationalForestMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS:
      marker = NoAccessRoad;
      break;
    case ADDITIONAL_LAYER_TYPE.NO_SPRAY:
      marker = NoSprayMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.ORCHIDS:
      marker = OrchidMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.WETLANDS:
      marker = WetlandsMarker;
      break;
    case ADDITIONAL_LAYER_TYPE.HYDROLOGY:
      marker = HydrologyMarker;
      break;
  }
  return new L.Icon({
    iconUrl: marker,
    iconSize: [35, 34],
  });
};

export const getHydrologyStyle = (item: any) => {
  switch (item?.properties?.name) {
    case "Floodway":
      return HYDROLOGY_FLOODWAY_STYLE;
    case "Floodplain":
      return HYDROLOGY_FLOODPLAIN_STYLE;
    default:
      return HYDROLOGY_DEFAULT_STYLE;
  }
};

export const getStyleByCategory = (category: ADDITIONAL_LAYER_CATEGORY) => {
  if (!category) {
    return NONE_CATEGORY_STYLE;
  }

  switch (category) {
    case ADDITIONAL_LAYER_CATEGORY.DEMOGRAPHIC:
      return DEMOGRAPHIC_STYLE;
    case ADDITIONAL_LAYER_CATEGORY.LANDS:
      return LANDS_STYLE;
    case ADDITIONAL_LAYER_CATEGORY.ROADS:
      return ROADS_STYLE;
    case ADDITIONAL_LAYER_CATEGORY.SENSITIVE_SPECIES:
      return SENSITIVE_SPECIES_STYLE;
    default:
      return NONE_CATEGORY_STYLE;
  }
};

export const getMarkerPositions = (layerFeatures: Maybe<VectorLayerResponse>) => {
  if (!layerFeatures) {
    return null;
  }

  return layerFeatures.features
    .filter((feature) => feature?.geometry)
    .map(
      (feature): Nullable<{ position: Position; name?: string }> => {
        if (feature.geometry.type === "Point") {
          return {
            position: [feature.geometry.coordinates[1], feature.geometry.coordinates[0]],
            name: feature.properties.UNIQUEID ?? feature.properties.id,
          };
        }

        const polylabel = MapHelper.centroid(false, feature);
        return {
          position: polylabel && [polylabel.geometry.coordinates[1], polylabel.geometry.coordinates[0]],
          name: feature.properties.id,
        };
      }
    );
};

export const getLayerImage = (additionalLayerType: ADDITIONAL_LAYER_TYPE) => {
  switch (additionalLayerType) {
    case ADDITIONAL_LAYER_TYPE.BATS:
      return BatsIcon;
    case ADDITIONAL_LAYER_TYPE.CLOSED_SYSTEM_ROADS:
      return ClosedRoadsIcon;
    case ADDITIONAL_LAYER_TYPE.CONSERVATION_LANDS:
      return ConservationLandsIcon;
    case ADDITIONAL_LAYER_TYPE.CREES_FLOWER:
      return GladeFlowerIcon;
    case ADDITIONAL_LAYER_TYPE.HAZARD_TREE:
      return HazardTreeIcon;
    case ADDITIONAL_LAYER_TYPE.NATIONAL_FOREST:
      return NationalForestIcon;
    case ADDITIONAL_LAYER_TYPE.NON_SYSTEM_ROADS:
      return NoAccessRoadIcon;
    case ADDITIONAL_LAYER_TYPE.NO_SPRAY:
      return NoSprayIcon;
    case ADDITIONAL_LAYER_TYPE.ORCHIDS:
      return OrchidIcon;
    case ADDITIONAL_LAYER_TYPE.WETLANDS:
      return WetlandsIcon;
    case ADDITIONAL_LAYER_TYPE.HYDROLOGY:
      return HydrologyIcon;
  }
};
