import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { TOP_HEADER_HEIGHT } from "../../constants/dimensions";

export const Layout = styled.main`
  width: 100%;
  margin-top: ${TOP_HEADER_HEIGHT}px;
  display: flex;
`;

export const MainSection = styled.main`
  display: flex;
`;

export const Navigation = styled.nav`
  display: flex;
  width: 320px;
  height: 100vh;
  flex-direction: column;
  margin-top: 20px;
  & ul + ul {
    margin-top: 35px;
  }
`;

export const NavigationTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  padding-left: 20px;
  padding-top: 25px;
  color: ${(props) => props.theme.colors.trout};
`;

export const Content = styled.section`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.colors.athensGray};
  padding-left: 30px;
  padding-top: 27px;
  overflow-y: scroll;
`;

export const NavigationSection = styled.ul`
  margin: 0px;
  padding: 0px;
  padding-left: 6px;
  padding-right: 6px;
`;

export const NavigationSectionTitle = styled.h3`
  padding-left: 14px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.smaltBlue};
  margin-top: 16px;
`;

export const NavigationSectionItem = styled.li`
  text-decoration: none;
  list-style: none;
  margin-top: 5px;
`;

const activeClassName = "nav-item-active";

export const StyledLink = styled(NavLink).attrs({ activeClassName })`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  height: 29px;
  color: ${(props) => props.theme.colors.waterloo};
  text-decoration: none;
  &.${activeClassName} {
    color: white;
    background-color: ${(props) => props.theme.colors.easternBlue};
  }
  ${NavigationSectionItem}:hover & {
  }
  :hover {
    background-color: ${(props) => props.theme.colors.catskillWhite};
    color: ${(props) => props.theme.colors.easternBlue};
  }
`;
