import styled from "styled-components";
import { color, ColorProps } from "styled-system";

export const StatusCircle = styled.div<ColorProps>`
  ${color}
  box-sizing: border-box;
  min-height: 10px;
  min-width: 10px;
  border-radius: 4.5px;
  margin-right: 3px;
`;

export const FeederCircleStatus = styled.div<ColorProps>`
  ${color}
  box-sizing: border-box;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  margin-right: 3px;
`;

export const StatusViolationTriangle = styled.img`
  height: 15px;
  width: 15px;
`;

export const ShowAllImage = styled.img``;
