import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)`
  background-color: var(--colors-white);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  width: 502px;
  height: 339px;
  z-index: 2;
  color: var(--colors-black);
  font-weight: 400;

  * {
    box-sizing: border-box;
  }
`;

export const InnerWrapper = styled(StyledFlex)`
  flex: 1;
  padding: 20px 0;
  max-height: 100%;

  > div:first-child {
    border-right: 1px solid #efefef;
  }

  > {
    flex: 1;
  }

  & + div {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);
  }

  .crewsList .checkbox__text {
    margin-left: 10px;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1;

  &.info {
    order: 2;
  }

  &.crewInfo {
    margin-top: 20px;
  }

  & + svg {
    min-width: 13px;
  }
`;

export const CrewConfItem = styled(StyledFlex)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--colors-outer-space);
  padding: 10px 0 8px;
  border-bottom: 1px solid #efefef;
`;
