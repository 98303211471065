import { useMutation } from "react-query";
import ApiClient from "../utils/apiClient";
import { logError } from "../services";
import { patrolsByIDsUrl, problemCodesUrl } from "../api";
import { useCustomQuery } from "../hooks";
import { usePatrolsStore, MapPatrol } from "../stores/PatrolsStore";
import { ProblemCodeResponse, PatrolResposnse } from "../types/responses/Patrols";

export const usePatrolsById = () => {
  const { data: pcData } = useCustomQuery<ProblemCodeResponse>(problemCodesUrl);
  const setPatrols = usePatrolsStore((store) => store.setData);

  return useMutation(
    "patrols",
    (ids: string[]) => ApiClient.post<PatrolResposnse>(patrolsByIDsUrl, { ids }).then((response) => response),
    {
      onSuccess: ({ data }) => {
        let patrols: MapPatrol[] = [];
        data.patrols.forEach((patrol) => {
          patrol.problemCodes.forEach((pc) => {
            const arrItem = {
              id: patrol.id,
              name: patrol.name,
              lat: pc.location.coordinates[1],
              lon: pc.location.coordinates[0],
              shortDescription: pcData?.problemCodes.find((item) => item.id === pc.problemCodeId)?.shortDescription,
              iconUrl: pcData?.problemCodes.find((item) => item.id === pc.problemCodeId)?.iconUrl!,
              type: pcData?.problemCodes.find((item) => item.id === pc.problemCodeId)?.type!,
            };
            patrols.push(arrItem);
          });
        });

        setPatrols(patrols);
      },
      onError: logError,
    }
  );
};

export default usePatrolsById;
