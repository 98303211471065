import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { starCommandMAreasUrl } from "../api";
import { MaintenanceAreaGeometryResponse } from "../types/responses/MaintenanceArea";
import useStarCommandMapStore from "../stores/StarCommandMapStore/StarCommandMapStore";

export const STAR_COMMAND_MA_MUTATION_KEY = "maintenance-area-star-command";

export const useStarCommandMaintenanceArea = () => {
  const { setMaintenanceAreas } = useStarCommandMapStore((store) => store.actions);
  return useMutation(
    STAR_COMMAND_MA_MUTATION_KEY,
    (ids: string[]) =>
      ApiClient.post<MaintenanceAreaGeometryResponse>(starCommandMAreasUrl, { maintenanceAreaIdList: ids }),
    { onSuccess: (data) => setMaintenanceAreas(data?.data?.maintenanceAreaGeometryList) }
  );
};
