import styled from "styled-components";
import { space, position, layout, SpaceProps } from "styled-system";

import { ReactComponent as WarningIconSvg } from "../../assets/images/warning_icon.svg";
import { ERROR_RED, INFO_ORANGE } from "../../constants/colors";
import { ToastKind } from "./Toast";

export const ToastStyled = styled.div<{ kind: ToastKind }>`
  ${space}
  ${position}
  ${layout}
  color: ${(props) => (props.kind === "info" ? INFO_ORANGE : ERROR_RED)};
  font-size: 15px;
  font-weight: 500;
  min-height: 52px;
  width: fit-content;
  background-color: #3d1723;
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-left: 1.4rem;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const WarningIcon = styled(WarningIconSvg)<SpaceProps>`
  ${space}
`;
