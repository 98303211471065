import React, { FC, useCallback, useMemo } from "react";

import { WorkTypeItem } from "../WorkTypeItem";
import {
  StructureContentWrapper,
  StructureHeader,
  StructureHeaderControlWrapper,
  StructureHeaderItemDeleteAll,
  StructureHeaderItemName,
  StructureHeaderName,
  StructureHeaderVoltage,
  StructureHeaderVoltageImg,
  StructureHeaderVoltageWrapper,
  StructureWrapperItem,
  SubTotalItem,
  WorksheetWrapper,
} from "./StructureRHSContent.styled";
import VoltageImage from "./../../assets/images/voltage.svg";
import { StructuresWithWorksheet } from "../../stores";
import { VOLTAGE_UNIT_SHORT_NAME } from "../../constants";
import { WorksheetAreaControl } from "../WorksheetArea/WorksheetAreaControl";
import { WorkTypeResponse } from "../../types/responses";
import { toUsdCurrency } from "../../utils/stringUtils";
import { Option } from "../Select";

interface StructureRHSContentProps {
  selectedStructures: Nullable<StructuresWithWorksheet>;
  workTypeResponse: Nullable<Record<string, WorkTypeResponse>>;
  deleteWorkType: (workTypeId: string, objectId: string) => void;
  deleteAllWorkTypes: () => void;
  setSelectedStructures: (selectedStructures: Nullable<StructuresWithWorksheet>) => void;
  isEditMode: boolean;
}

export const StructureRHSContent: FC<StructureRHSContentProps> = ({
  selectedStructures,
  workTypeResponse,
  deleteWorkType,
  deleteAllWorkTypes,
  setSelectedStructures,
  isEditMode,
}) => {
  const addWorkType = useCallback(
    (workTypeOption: Option) => {
      if (!selectedStructures?.items?.length || !workTypeOption) {
        return;
      }
      const newStructures = Object.assign({}, selectedStructures);
      newStructures.items.forEach((items) => {
        items.structureWithWorksheet.items = items.structureWithWorksheet.items ?? [];
        items.structureWithWorksheet.items = items.structureWithWorksheet.items?.concat({
          workTypeId: workTypeOption?.value ?? "",
          selected: true,
          cost: 0,
          unitRate: 1,
          manuallyAdded: true,
        });
      });
      setSelectedStructures(newStructures);
    },
    [setSelectedStructures, selectedStructures]
  );

  const deleteWorkTypeFormArray = useCallback(
    (workTypeId: string, objectId?: string, manuallyAdded?: boolean) => {
      if (!objectId) {
        return;
      }
      if (!manuallyAdded) {
        deleteWorkType(workTypeId, objectId);
        return;
      }

      const newStructures = Object.assign({}, selectedStructures);
      const chosenStructure = newStructures.items
        .filter((item) => item.structureWithWorksheet.structureId === objectId)
        .reduce((item) => item);
      if (!chosenStructure) {
        return;
      }
      const itemIndex =
        chosenStructure.structureWithWorksheet.items?.findIndex(
          (item) => item.workTypeId === workTypeId && item.manuallyAdded
        ) ?? -1;
      if (itemIndex >= 0) {
        chosenStructure.structureWithWorksheet.items?.splice(itemIndex, 1);
        setSelectedStructures(newStructures);
      }
    },
    [deleteWorkType, selectedStructures, setSelectedStructures]
  );

  const selectedWorkTypes = useMemo(() => {
    if (!selectedStructures) {
      return [];
    }
    const addedWorkTypes: string[] = [];

    selectedStructures.items.forEach((structure) => {
      structure.structureWithWorksheet.items
        ?.filter((item) => item.manuallyAdded)
        .forEach((item) => {
          if (!addedWorkTypes.find((workType) => workType === item.workTypeId)) {
            addedWorkTypes.push(item.workTypeId);
          }
        });
    });
    return addedWorkTypes;
  }, [selectedStructures]);

  if (!selectedStructures?.items?.length) {
    return <></>;
  }

  return (
    <StructureContentWrapper>
      {isEditMode && (
        <WorksheetWrapper>
          <WorksheetAreaControl
            selectedWorkTypes={selectedWorkTypes}
            readonly={!isEditMode}
            addNewWorkType={addWorkType}
            scope="STRUCTURE"
          />
        </WorksheetWrapper>
      )}
      <StructureHeaderControlWrapper>
        <StructureHeaderItemName>Work Types</StructureHeaderItemName>
        {isEditMode && (
          <StructureHeaderItemDeleteAll onClick={() => deleteAllWorkTypes()}>Delete All</StructureHeaderItemDeleteAll>
        )}
      </StructureHeaderControlWrapper>
      {selectedStructures?.items.map((structureItem) => {
        return (
          <StructureWrapperItem key={structureItem.structureWithWorksheet.structureId}>
            <StructureHeader>
              <StructureHeaderName>{structureItem.name}</StructureHeaderName>
              <StructureHeaderVoltageWrapper>
                <StructureHeaderVoltageImg src={VoltageImage} />
                <StructureHeaderVoltage>
                  {structureItem.voltage}
                  {VOLTAGE_UNIT_SHORT_NAME}
                </StructureHeaderVoltage>
              </StructureHeaderVoltageWrapper>
            </StructureHeader>
            {structureItem?.structureWithWorksheet?.items &&
              structureItem?.structureWithWorksheet?.items.map((item, index) => {
                return (
                  <>
                    <WorkTypeItem
                      isEditMode={isEditMode}
                      key={workTypeResponse?.[item.workTypeId].code ?? ""}
                      code={workTypeResponse?.[item.workTypeId].code ?? ""}
                      cost={item.cost}
                      name={workTypeResponse?.[item.workTypeId].name ?? ""}
                      deleteWorkType={deleteWorkTypeFormArray}
                      objectId={structureItem.structureWithWorksheet.structureId}
                      id={item.workTypeId}
                      manuallyAdded={item.manuallyAdded}
                    ></WorkTypeItem>
                    {structureItem.structureWithWorksheet.items?.length &&
                      structureItem.structureWithWorksheet.items.length > 1 &&
                      index === structureItem.structureWithWorksheet.items.length - 1 && (
                        <SubTotalItem>
                          <span>Sub:</span>
                          <div>{toUsdCurrency(structureItem.structureWithWorksheet.totalCost)} </div>
                        </SubTotalItem>
                      )}
                  </>
                );
              })}
          </StructureWrapperItem>
        );
      })}
      <SubTotalItem>
        <span>Total:</span>
        <div>{toUsdCurrency(selectedStructures.totalCost)} </div>
      </SubTotalItem>
    </StructureContentWrapper>
  );
};
