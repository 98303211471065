import { MaintenanceAreaGeometryDto } from "./MaintenanceArea";
import { RISK_PRIORITY } from "./ThreatHierarchyResponse";

export enum RESOLUTION_SET {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum AGE_CATEGORY {
  FRESH = "FRESH",
  RECENT = "RECENT",
  STANDARD = "STANDARD",
  DATED = "DATED",
  STALE = "STALE",
}

export enum SEASON {
  SPRING = "SPRING",
  SUMMER = "SUMMER",
  AUTUMN = "AUTUMN",
  WINTER = "WINTER",
}

export enum AOI_VALIDATION_STATE {
  VALID = "VALID",
  GEOMETRY_INVALID = "GEOMETRY_INVALID",
  WRONG_GEOMETRY_TYPE = "WRONG_GEOMETRY_TYPE",
  AREA_TOO_SMALL = "AREA_TOO_SMALL",
  TOO_MANY_VERTICES = "TOO_MANY_VERTICES",
  CONTAINS_Z_AXIS = "CONTAINS_Z_AXIS",
}

export type RefreshFilterType = {
  resolutionSet: RESOLUTION_SET[];
  ageCategorySet: AGE_CATEGORY[];
  seasonalFilter: Nullable<{ year: number; season: SEASON }>;
  maxCloudCover: number;
  maxAreaOffNadir: number;
};

export type StarCommandNode = {
  id: string;
  name: string;
  maintenanceAreaIds?: string[];
  lastVisit: string;
  nextVisit: string;
  priority: RISK_PRIORITY;
};

export type StarCommandResponse = {
  feederList: StarCommandNode[];
  maintenanceAreaList: StarCommandNode[];
};

export type SatelliteQueryRequest = {
  maintenanceAreaList: MaintenanceAreaGeometryDto[];
  filters: RefreshFilterType;
};
