import { FEET_TO_METER_CONST, METER_TO_FEET_CONST } from "../../../constants/general";
import { ElevationLevel } from "../../../types/responses";

export const mapElevationResponse = (elevations: ElevationLevel[]): Nullable<ElevationLevel[]> => {
  if (!elevations) {
    return null;
  }

  return elevations.map((item) => {
    const min = unitConverter(true, item.min);
    const max = unitConverter(true, item.max);
    return { min: min, max: max, id: item.id, voltageText: `Over ${min} ft up to ${max} ft` };
  });
};

export const makeNewElevation = (
  oldElevation: ElevationLevel,
  newValue: string,
  accessor: string | undefined
): Nullable<ElevationLevel> => {
  if (!oldElevation || !newValue || !accessor) {
    return null;
  }
  const test = {
    min: accessor === "min" ? unitConverter(false, Number(newValue)) : unitConverter(false, Number(oldElevation.min)),
    max: accessor === "max" ? unitConverter(false, Number(newValue)) : unitConverter(false, Number(oldElevation.max)),
  };
  return test;
};

export const isValueChanged = (
  oldElevation: ElevationLevel,
  newValue: string,
  accessor: string | undefined
): boolean => {
  if (!oldElevation || !newValue || !accessor) {
    return false;
  }
  if (accessor === "min") {
    return roundValue(Number(oldElevation.min), 2) !== roundValue(Number(newValue), 2);
  } else if (accessor === "max") {
    return roundValue(Number(oldElevation.max), 2) !== roundValue(Number(newValue), 2);
  }
  return false;
};

export const unitConverter = (meterToFeet: boolean, value: number) => {
  if (meterToFeet) {
    return roundValue(METER_TO_FEET_CONST * value, 2);
  } else {
    return roundValue(FEET_TO_METER_CONST * value, 2);
  }
};

export const roundValue = (value: number, fractionDigits: number): number => {
  if (!value) {
    return 0;
  }
  return parseFloat(value.toFixed(fractionDigits));
};

export const isInputEmpty = (value: string) => {
  return !value || value.trim().length <= 0;
};
