import L from "leaflet";
import { FC, useCallback, useRef, useState } from "react";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { Key } from "../../constants/keys";
import { useClickOutside, useKeyPress } from "../../hooks";
import { ReplyTextArea } from "../Comments/ReplyTextArea";
import { DescriptionContent, DescriptionUsername, LeaveDescriptionWrapper } from "./Preview.styled";

type DescriptionProperties = {
  username: string;
  description: string;
  isEditable: boolean;
  placeholder?: string;
  saveDescription: (value: string) => void;
};

export const Description: FC<DescriptionProperties> = ({
  username,
  description,
  isEditable,
  placeholder = "",
  saveDescription,
}) => {
  const isShiftKeyPressed = useKeyPress(Key.Shift);
  const [replyEnabled, setReplyEnabled] = useState(false);
  const hasDescription = description?.length > 0;
  const [editEnabled, enableEdit] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isEditable && enableEdit(false));

  const saveChanges = useCallback(
    (value: string) => {
      saveDescription(value);
      enableEdit(false);
    },
    [saveDescription]
  );

  const onKeypress = useCallback(
    (e) => {
      if (!e?.target || isShiftKeyPressed) {
        return;
      }

      if (e.key === "Enter") {
        L.DomEvent.stop(e);
        saveChanges(e.target.value);
        return;
      }
    },
    [saveChanges, isShiftKeyPressed]
  );
  return (
    <StyledFlex
      ref={wrapperRef}
      marginLeft={!editEnabled || !hasDescription ? "20px" : "13px"}
      marginRight={"13px"}
      flexDirection={"column"}
      onClick={(e) => {
        isEditable && enableEdit(true);
        e.stopPropagation();
      }}
      className={isEditable ? "editable-content" : ""}
    >
      <>{hasDescription && <DescriptionUsername>{username}</DescriptionUsername>}</>
      <>
        <DescriptionContent>
          {!editEnabled && <div>{description}</div>}
          {(editEnabled || !hasDescription) && (
            <LeaveDescriptionWrapper enabled={replyEnabled}>
              <ReplyTextArea
                maxRows={7}
                setReplyAreaState={setReplyEnabled}
                {...{
                  onKeyPress: onKeypress,
                }}
                onSubmit={saveChanges}
                placeholder={placeholder}
                initialValue={description}
              />
            </LeaveDescriptionWrapper>
          )}
        </DescriptionContent>
      </>
    </StyledFlex>
  );
};
