import styled from "styled-components";
import { space, flexbox, SpaceProps, FlexboxProps } from "styled-system";

export const BrandPageLayout = styled.main<SpaceProps & FlexboxProps>`
  ${space}
  ${flexbox}
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Logo = styled.img`
  position: absolute;
  top: -110px;
`;

export const BrandPageLayoutContent = styled.div<SpaceProps>`
  ${space}
  display: flex;
  position: relative;
`;
