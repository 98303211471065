export const LANDS_STYLE = {
  fillColor: "rgb(0, 174, 122)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#00AE7A",
};

export const ROADS_STYLE = {
  fillColor: "rgb(255, 0, 0)",
  fillOpacity: 0.2,
  weight: 1,
  opacity: 1,
  color: "#EC8F96",
};

export const SENSITIVE_SPECIES_STYLE = {
  fillColor: "rgb(253, 225, 76)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FDCA14",
};

export const DEMOGRAPHIC_STYLE = {
  fillColor: "rgb(255, 0, 0)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#EC8F96",
};

export const NONE_CATEGORY_STYLE = {
  fillColor: "rgb(253, 225, 76)",
  fillOpacity: 0.1,
  weight: 1,
  opacity: 1,
  color: "#FDCA14",
};

export const HYDROLOGY_FLOODPLAIN_STYLE = {
  fillColor: "rgba(43, 127, 255, 0.44)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "transparent",
};

export const HYDROLOGY_FLOODWAY_STYLE = {
  fillColor: "rgba(2, 28, 67, 0.51)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "rgba(255, 255, 255, 0.5)",
};

export const HYDROLOGY_DEFAULT_STYLE = {
  fillColor: "rgba(0, 27, 98, 0.7)",
  fillOpacity: 1,
  weight: 1,
  opacity: 1,
  color: "rgba(255, 255, 255, 0.5)",
};
