import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { getStructureByIdUrl } from "../api";
import { logError } from "../services";
import { StructuresResponse } from "../types/responses";
import useStructuresStore from "../stores/StructuresStore";

export const useSingleStructure = () => {
  const structureId = useStructuresStore((store) => store.selectedId);
  const addStructure = useStructuresStore((store) => store.addStructure);

  return useQuery("single-structure", () => ApiClient.get<StructuresResponse>(getStructureByIdUrl(structureId ?? "")), {
    onError: (e) => logError(e),
    onSuccess: (data) => addStructure(data.data),
    enabled: structureId !== null,
    retry: false,
  });
};

export default useSingleStructure;
