import { FC, useEffect, useMemo } from "react";
import { MapContainer } from "react-leaflet";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

import { MAX_ZOOM_LEVEL } from "../MapScreen/MapView/MapView.constants";
import { Layer, MapLayerControl } from "../../components/MapLayerControl";
import { satelliteLayer, starCommandLightLayer } from "../../utils/mapUtils";
import { BASE_LAYER } from "../../constants/map";
import { MapZoomControl } from "../../components/MapZoomControl";
import useStarCommandMapStore from "../../stores/StarCommandMapStore/StarCommandMapStore";
import { ReactComponent as SatelliteImage } from "../../assets/images/satellite.svg";
import { ReactComponent as MapImage } from "../../assets/images/map.svg";
import { MapFeeders } from "../../components/MapFeeders";
import { ACRES_UNIT_NAME_SHORT, EMPTY_FN } from "../../constants";
import { MapMAStarCommand } from "../../components/MapMAStarCommand";
import { StarCommandMapStyle } from "./StarCommandMap.styled";
import { StarCommandMaPopup } from "../../components/StarCommandMaPopup";
import { getDistanceUnit, getReadableDistance, squareMetersToAcres, unitConverter } from "../../utils/unitHelper";
import { useFeederStore, useLeftControlStore } from "../../stores";
import { StarCommandMapImages } from "../../components/StarCommandMapImages/StarCommandMapImages";
import { StarCommandRHS } from "../../components/StarCommandRHS";

const ANIMATION = { animate: true, duration: 0.5 };
export const DEFAULT_ZOOM_LEVEL = 8;

export const StarCommandMap: FC = () => {
  const { setMap } = useStarCommandMapStore((store) => store.actions);
  const { changeSelectedBaseLayer } = useStarCommandMapStore((store) => store.actions);
  const { resetMap } = useStarCommandMapStore((store) => store.actions);
  const { setHoveredId } = useStarCommandMapStore((store) => store.actions);
  const map = useStarCommandMapStore((store) => store.map);
  const defaultCenter = useFeederStore((store) => store.defaultCenter);
  const currentBounds = useStarCommandMapStore((store) => store.currentBounds);
  const baseLayer = useStarCommandMapStore((store) => store.currentLayerSelected);
  const feederData = useFeederStore((store) => store.feedersGeoJson);
  const maintenanceAreaGeoJson = useStarCommandMapStore((store) => store.maintenanceAreas);
  const checkedMaintenanceAreas = useStarCommandMapStore((store) => store.checkedMaintenanceAreas);
  const colorsById = useStarCommandMapStore((store) => store.colorById);
  const toggled = useLeftControlStore((store) => store.toggled);
  const images = useStarCommandMapStore((store) => store.images);
  const rhsEnabled = useStarCommandMapStore((store) => store.rhsVisible);
  const hoveredId = useStarCommandMapStore((store) => store.hoveredId);

  useEffect(() => {
    if (!map || !currentBounds) {
      return;
    }

    L.Util.requestAnimFrame(() => {
      map.setView(map.getCenter(), map.getZoom(), { animate: false });
      map.flyToBounds(currentBounds!, ANIMATION);
    });
  }, [currentBounds, map]);

  useEffect(() => {
    return () => {
      resetMap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const measurementData = useMemo(() => {
    if (!maintenanceAreaGeoJson?.length || !checkedMaintenanceAreas?.length) {
      return null;
    }

    const filteredItems = maintenanceAreaGeoJson?.filter((item) =>
      checkedMaintenanceAreas.find((checkedItem) => checkedItem === item.id)
    );
    if (!filteredItems?.length) {
      return null;
    }
    return filteredItems
      .map((item) => ({ area: item.area, circuitLength: item.corridorLength }))
      .reduce((prev, next) => {
        prev.area += next.area;
        prev.circuitLength += next.circuitLength;
        return prev;
      });
  }, [maintenanceAreaGeoJson, checkedMaintenanceAreas]);

  useEffect(() => {
    if (!map) return;

    map.invalidateSize();
  }, [toggled, map]);

  return (
    <>
      <StarCommandMapStyle numberOfElements={images?.availableImages?.length} />
      <MapContainer
        center={defaultCenter!}
        className="star-command-map"
        key="star-command-map"
        maxBoundsViscosity={1}
        maxZoom={MAX_ZOOM_LEVEL}
        whenCreated={setMap}
        zoom={DEFAULT_ZOOM_LEVEL}
        zoomControl={false}
      >
        {map && (
          <>
            <MapFeeders
              hidden={false}
              geoJSON={feederData}
              map={map}
              onClick={EMPTY_FN}
              selectedFeeder={null}
              hiddenFeeders={null}
            />
            {images?.availableImages?.length && (
              <StarCommandMapImages hoveredId={hoveredId} setHoveredId={setHoveredId} map={map} images={images} />
            )}
            <MapMAStarCommand
              colorsById={colorsById}
              geoJSON={maintenanceAreaGeoJson}
              checkedMaintenanceAreas={checkedMaintenanceAreas}
              map={map!}
            />
            <MapZoomControl bottom="110px" hidden={false} map={map!} position="absolute" right={12} />
            <MapLayerControl bottom="24px" map={map!} position="absolute" right={12}>
              <Layer
                checked={baseLayer === BASE_LAYER.LIGHT_LAYER}
                layer={starCommandLightLayer}
                map={map!}
                name={BASE_LAYER.LIGHT_LAYER}
                onClick={() => changeSelectedBaseLayer(BASE_LAYER.LIGHT_LAYER)}
              >
                <MapImage />
              </Layer>
              <Layer
                checked={baseLayer === BASE_LAYER.SATELLITE_LAYER}
                layer={satelliteLayer}
                map={map!}
                name={BASE_LAYER.SATELLITE_LAYER}
                onClick={() => changeSelectedBaseLayer(BASE_LAYER.SATELLITE_LAYER)}
              >
                <SatelliteImage />
              </Layer>
            </MapLayerControl>
            <StarCommandRHS />
            <StarCommandMaPopup
              hidden={!measurementData?.area || !measurementData?.circuitLength}
              area={
                measurementData?.area
                  ? `${squareMetersToAcres(measurementData.area)} ${ACRES_UNIT_NAME_SHORT}`
                  : undefined
              }
              length={
                measurementData?.circuitLength
                  ? `${getReadableDistance(unitConverter(true, measurementData?.circuitLength))} ${getDistanceUnit(
                      unitConverter(true, measurementData?.circuitLength)
                    )}`
                  : undefined
              }
              leftMenuEnabled={toggled}
              rightMenuEnabled={rhsEnabled}
            />
          </>
        )}
      </MapContainer>
    </>
  );
};
