import React, { FC, useEffect, useState } from "react";
import { Wrapper, LayerButton, ButtonWrapper, ToolkitButton } from "./EditModeButton.styled";
import { ReactComponent as Trees } from "../../assets/images/trees.svg";
import { ReactComponent as AccessPoint } from "../../assets/images/access-point.svg";
import { ReactComponent as Alerts } from "../../assets/images/alerts.svg";
import { ReactComponent as Alert } from "../../assets/images/alert.svg";
import { ReactComponent as CWC } from "../../assets/images/cwc.svg";
import { Portal } from "../Portal";
import useAlertsStore from "../../stores/AlertsStore";
import useCwcStore from "../../stores/MapCwcStore";
import useTreeMarkersStore from "../../stores/TreeMarkersStore";
import { useAccessPointsStore } from "../../stores";

interface IProps {
  isRightDrawerVisible: boolean;
  editEnabled: boolean;
  setEditEnabled: (isEnabled: boolean) => void;
  onClick: () => void;
}

enum ACTIVE_BUTTON_TYPE {
  ALERTS = "alerts",
  CWC = "cwc",
  TREE_MARKERS = "tree-markers",
  ACCESS_POINTS = "access-points",
}

const getLayerIcon = (actionType: string = ""): FC<React.SVGProps<SVGSVGElement>> => {
  switch (actionType as ACTIVE_BUTTON_TYPE) {
    case ACTIVE_BUTTON_TYPE.ALERTS:
      return Alerts;
    case ACTIVE_BUTTON_TYPE.CWC:
      return CWC;
    case ACTIVE_BUTTON_TYPE.TREE_MARKERS:
      return Trees;
    case ACTIVE_BUTTON_TYPE.ACCESS_POINTS:
      return AccessPoint;
    default:
      return CWC;
  }
};
const DEFAULT_BUTTON_TYPE = ACTIVE_BUTTON_TYPE.CWC;

const getLayerButtonClass = (activeType: string, isCwcVisible: boolean) => {
  if (activeType === ACTIVE_BUTTON_TYPE.ALERTS) return "isActive";
  if (activeType === ACTIVE_BUTTON_TYPE.CWC) return isCwcVisible ? "isActive" : "";
  if (activeType === ACTIVE_BUTTON_TYPE.TREE_MARKERS) return "isActive";
  if (activeType === ACTIVE_BUTTON_TYPE.ACCESS_POINTS) return "isActive";
  return "";
};

const allControls = [
  { key: ACTIVE_BUTTON_TYPE.TREE_MARKERS, icon: Trees },
  { key: ACTIVE_BUTTON_TYPE.ACCESS_POINTS, icon: AccessPoint },
  { key: ACTIVE_BUTTON_TYPE.ALERTS, icon: Alert },
  { key: ACTIVE_BUTTON_TYPE.CWC, icon: CWC },
];

export const EditModeButton: FC<IProps> = ({ isRightDrawerVisible, editEnabled, setEditEnabled, onClick }) => {
  const areAlertsVisible = useAlertsStore((store) => store.isLayerVisible);
  const { toggleLayer: toggleAlertsLayer } = useAlertsStore((store) => store.actions);
  const isCwcVisible = useCwcStore((store) => store.isLayerVisible);
  const { toggleLayer: toggleCwcLayer } = useCwcStore((store) => store.actions);
  const [isActive, setIsActive] = useState(false);
  const [activeButtonType, setActiveButtonType] = useState(DEFAULT_BUTTON_TYPE);
  const { toggleLayer: toggleTreeMarkers } = useTreeMarkersStore((store) => store.actions);
  const areTreeMarkersVisible = useTreeMarkersStore((store) => store.isLayerVisible);
  const areAccessPointVisible = useAccessPointsStore((store) => store.isLayerVisible);
  const { toggleLayer: toggleAccessPointsLayer } = useAccessPointsStore((store) => store.actions);

  useEffect(() => {
    if (!editEnabled) {
      isCwcVisible && toggleCwcLayer();
      areAccessPointVisible && toggleAccessPointsLayer();
      areTreeMarkersVisible && toggleTreeMarkers();
      areAlertsVisible && toggleAlertsLayer();
      setActiveButtonType(DEFAULT_BUTTON_TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEnabled, isCwcVisible, areAccessPointVisible, areTreeMarkersVisible, areAlertsVisible]);

  const callAction = (actionType: string = "") => {
    switch (actionType) {
      case ACTIVE_BUTTON_TYPE.ALERTS:
        areAlertsVisible && setActiveButtonType(DEFAULT_BUTTON_TYPE);
        return toggleAlertsLayer();
      case ACTIVE_BUTTON_TYPE.CWC:
        return toggleCwcLayer();
      case ACTIVE_BUTTON_TYPE.TREE_MARKERS:
        areTreeMarkersVisible && setActiveButtonType(DEFAULT_BUTTON_TYPE);
        return toggleTreeMarkers();
      case ACTIVE_BUTTON_TYPE.ACCESS_POINTS:
        areAccessPointVisible && setActiveButtonType(DEFAULT_BUTTON_TYPE);
        return toggleAccessPointsLayer();
      default:
        return toggleCwcLayer();
    }
  };

  const toggleControl = (key: string) => {
    callAction(key);
    setEditEnabled(true);
    setActiveButtonType(key as ACTIVE_BUTTON_TYPE);
    if (activeButtonType === key) {
      isCwcVisible && toggleCwcLayer();
    }
    switch (key) {
      case ACTIVE_BUTTON_TYPE.ALERTS:
        isCwcVisible && toggleCwcLayer();
        areAccessPointVisible && toggleAccessPointsLayer();
        areTreeMarkersVisible && toggleTreeMarkers();
        break;
      case ACTIVE_BUTTON_TYPE.CWC:
        areAlertsVisible && toggleAlertsLayer();
        areAccessPointVisible && toggleAccessPointsLayer();
        areTreeMarkersVisible && toggleTreeMarkers();
        break;
      case ACTIVE_BUTTON_TYPE.TREE_MARKERS:
        isCwcVisible && toggleCwcLayer();
        areAccessPointVisible && toggleAccessPointsLayer();
        areAlertsVisible && toggleAlertsLayer();
        break;
      case ACTIVE_BUTTON_TYPE.ACCESS_POINTS:
        isCwcVisible && toggleCwcLayer();
        areAlertsVisible && toggleAlertsLayer();
        areTreeMarkersVisible && toggleTreeMarkers();
        break;
      default:
        return;
    }
  };

  return (
    <Portal selector="layer-menu-root">
      <Wrapper
        isRightDrawerVisible={isRightDrawerVisible}
        onMouseOver={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <LayerButton
          Icon={getLayerIcon(activeButtonType)}
          onClick={() => {
            callAction(activeButtonType);
            setEditEnabled(!editEnabled);
            !editEnabled && setActiveButtonType(ACTIVE_BUTTON_TYPE.CWC);
            onClick();
          }}
          className={getLayerButtonClass(activeButtonType, isCwcVisible)}
        />
        <ButtonWrapper
          className={isActive ? "isOpen" : ""}
          gap="10px"
          data-selected-toolkit={editEnabled ? activeButtonType : "none"}
        >
          {allControls
            .filter((item) => item.key !== activeButtonType)
            .map((item) => (
              <ToolkitButton key={item.key} Icon={item.icon} onClick={() => toggleControl(item.key)} />
            ))}
        </ButtonWrapper>
      </Wrapper>
    </Portal>
  );
};
