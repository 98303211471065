import { Point } from "@turf/turf";
import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { PositionProps } from "styled-system";

import { getCutPlanApiUrl } from "../../api";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constants";
import { useCustomQuery } from "../../hooks";
import { logError } from "../../services";
import { RISK_PRIORITY } from "../../types/responses";
import { CutPlanResponse, CutPlan as CutPlanType, UpdateCutPlanDto } from "../../types/responses/CutPlanResponse";
import ApiClient from "../../utils/apiClient";
import { GanttChart } from "../GanttChart";
import { Loader } from "../Loader";
import { Portal } from "../Portal";
import { CutPlanErrorMessage, CutPlanWrapper, ResizeButton } from "./CutPlan.styled";

export interface MappedCutPlan {
  end_date: string;
  id: Nullable<string>;
  parent: Nullable<string>;
  priority: Maybe<RISK_PRIORITY>;
  start_date: string;
  text: Maybe<string>;
  type: any;
  spanId?: Nullable<string>;
}

interface CutPlanProps extends PositionProps {
  hidden: boolean;
  mapper: (plan: CutPlanType, parentId: Nullable<string>) => Maybe<MappedCutPlan>;
  addSelectedSpanBy: (id: string[]) => void;
  removeSelectedSpanBy: (id: string) => void;
  selectedId: Nullable<string>;
  setCutPlanSelectedId: (name: string) => void;
  onClose: () => void;
  exportUrl: string;
  addPosition: (point: Point, id: string) => void;
}

export const CutPlan: FC<CutPlanProps> = memo(
  ({
    hidden,
    mapper,
    selectedId,
    setCutPlanSelectedId,
    onClose,
    exportUrl,
    addSelectedSpanBy,
    removeSelectedSpanBy,
    addPosition,
    ...props
  }) => {
    const { data, isLoading, isError } = useCustomQuery<CutPlanResponse>(getCutPlanApiUrl(), {
      enabled: !hidden,
      staleTime: 0,
      cacheTime: 0,
    });

    const [size, setSize] = useState({ y: 550 });
    const ref = useRef<any>();
    const handleMouseUp = () => {
      if (ref && ref.current) {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };

    const handleMouseMove = (e: any) => {
      setSize((prevSize) => ({ y: prevSize.y - e.movementY }));
    };
    const handleMouseDown = () => {
      if (ref && ref.current) {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    };

    const updateCutPlanMutation = useMutation((value: UpdateCutPlanDto) =>
      ApiClient.put<UpdateCutPlanDto>(getCutPlanApiUrl(), value)
    );

    const updateCutPlan = useCallback(
      (value: UpdateCutPlanDto) => {
        if (!value?.cutPlanNodes) {
          return;
        }

        updateCutPlanMutation.mutateAsync(value).catch((e) => logError(e));
      },
      [updateCutPlanMutation]
    );

    const getParentId = useCallback(
      (node: CutPlanType) => {
        if (!data?.nodes?.length) {
          return null;
        }
        if (node.spanId === null) {
          return null;
        }
        const parentNode = data.nodes.find(
          (nodeItem) => nodeItem.feederId === node.feederId && nodeItem.spanId === null
        );
        return parentNode ? parentNode.id : null;
      },
      [data]
    );

    const ganttChartData = useMemo(() => {
      if (!data) {
        return null;
      }

      const startDates: number[] = [];
      const endDates: number[] = [];
      const mappedNodes = data?.nodes?.map((item) => {
        startDates.push(new Date(item.startTime).getTime());
        endDates.push(new Date(item.finishTime).getTime());
        return mapper(item, getParentId(item));
      });
      const minDate = Math.min.apply(null, startDates);
      const maxDate = Math.max.apply(null, endDates);

      return {
        data: { data: mappedNodes },
        maxDate: maxDate,
        minDate: minDate,
      };
    }, [data, mapper, getParentId]);

    if (hidden) {
      return <></>;
    }

    if (isLoading) {
      return (
        <Portal selector="layer-menu-root">
          <CutPlanWrapper {...props} justifyContent="center" alignItems="center">
            <Loader />
          </CutPlanWrapper>
        </Portal>
      );
    }

    if (isError) {
      return (
        <Portal selector="layer-menu-root">
          <CutPlanWrapper {...props} justifyContent="center" alignItems="center">
            <CutPlanErrorMessage>{SOMETHING_WENT_WRONG_MESSAGE}</CutPlanErrorMessage>
          </CutPlanWrapper>
        </Portal>
      );
    }
    return (
      <Portal selector="layer-menu-root">
        <CutPlanWrapper height={`${size.y}px`} {...props}>
          <ResizeButton ref={ref} onMouseDown={handleMouseDown} />

          <GanttChart
            height={size.y}
            data={ganttChartData?.data ?? null}
            selectedId={selectedId}
            setCutPlanSelectedId={setCutPlanSelectedId}
            onClose={onClose}
            exportUrl={exportUrl}
            updateCutPlan={updateCutPlan}
            addPosition={addPosition}
            addSelectedSpanBy={addSelectedSpanBy}
            removeSelectedSpanBy={removeSelectedSpanBy}
            minDate={ganttChartData?.minDate}
            maxDate={ganttChartData?.maxDate}
          />
        </CutPlanWrapper>
      </Portal>
    );
  }
);
