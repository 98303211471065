import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { updateCrewNameUrl } from "../api";

export const UPDATE_CREW_MUTATION_KEY = "update-crew-name";

export const useUpdateCrewName = () => {
  const queryClient = useQueryClient();
  return useMutation(
    UPDATE_CREW_MUTATION_KEY,
    (item: { id: string; name: string }) => ApiClient.put(updateCrewNameUrl(item.id), { name: item.name }),
    { onSettled: () => queryClient.invalidateQueries("crews") }
  );
};
