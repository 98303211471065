import { FC } from "react";

import { EMPTY_FN } from "../../../constants";
import { InputUpload } from "../../InputUpload";

import {
  SectionContent,
  SectionHeader,
  SectionHeaderAction,
  SectionContainer,
  SectionAction,
} from "../RightDrawer.styled";

export const DrawerSection: FC<{
  name: string;
  actionName?: string;
  onActionNameClick?: () => void;
  actionDisabled?: boolean;
  actionHeader?: boolean;
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  name,
  children,
  actionName,
  onActionNameClick = EMPTY_FN,
  actionDisabled = false,
  actionHeader = true,
  onUpload,
}) => (
  <SectionContainer>
    <SectionHeader>
      {name}
      {actionName &&
        actionHeader &&
        (!onUpload ? (
          <SectionHeaderAction disabled={actionDisabled} onClick={() => !actionDisabled && onActionNameClick()}>
            {actionName}
          </SectionHeaderAction>
        ) : (
          <InputUpload actionName={actionName} onUpload={onUpload} />
        ))}
    </SectionHeader>
    <SectionContent>{children}</SectionContent>

    {actionName && !actionHeader && (
      <SectionAction disabled={actionDisabled} onClick={() => !actionDisabled && onActionNameClick()}>
        {actionName}
      </SectionAction>
    )}
  </SectionContainer>
);
