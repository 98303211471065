import { FC, memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";
import { Marker } from "react-leaflet";

import "./MapAlerts.style.css";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { AlertsCategoriesDto } from "../../types/responses/Alerts";
import { getAlertsCategories } from "../../api";
import { AlertPopup } from "../AlertPopup";
import {
  useCreateAlert,
  useAlerts,
  useDeleteAlert,
  useEditAlert,
  UpdateOpts,
  useCustomQuery,
  useMapOptimizedHiddenFlag,
  useCrosshairCursor,
} from "../../hooks";
import { getAlertIcon } from "./MapAlerts.utils";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { AlertDto } from "../../types/responses/Alerts";
import { POPUP_OPT } from "../../pages/MapScreen/MapView/TreeMarkerView.utils";

interface MapAlertsProps {
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
  reloadComments?: () => void;
  selectedMarkerId?: Nullable<string>;
}

const ALERTS_PANE_NAME = "alerts-pane";

const renderCategoryIcon = (subcategoryCode: string, isResolved: boolean, isEditing?: boolean) => {
  const AlertIcon = getAlertIcon(subcategoryCode);
  const className = `markerIcon ${!isResolved ? "isResolved" : ""} ${isEditing ? "isEditing" : ""}`;
  const html = document.createElement("div");
  ReactDOM.render(<AlertIcon />, html);

  return L.divIcon({ html, className, iconSize: [36, 36] });
};

export const MapAlerts: FC<MapAlertsProps> = memo(({ hidden = false, map, zIndex = 400, selectedMarkerId }) => {
  useCustomMapPane({ map, name: ALERTS_PANE_NAME, zIndex });
  const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
  const createAlert = useCreateAlert();
  const deleteAlert = useDeleteAlert();
  const editAlert = useEditAlert();
  const [markerForEdit, setMarkerForEdit] = useState("");
  useCrosshairCursor(map);

  const { data: categoriesData } = useCustomQuery<Nullable<AlertsCategoriesDto>>(getAlertsCategories());
  const { data: alertsData } = useAlerts();

  const editAlertOnMap = useCallback(
    (id: string, updateOpts: UpdateOpts) => {
      setMarkerForEdit(id);
      editAlert.mutateAsync({ alertId: id, updateOpts }).then(() => setMarkerForEdit(""));
      map!.closePopup();
    },
    [map, editAlert]
  );

  const addAlert = useCallback(
    (e: L.LeafletMouseEvent, item?: AlertDto) => {
      L.DomEvent.stopPropagation(e);

      const createAlertOnMap = (categoryId: string) => {
        createAlert.mutate({ categoryId, lat: e.latlng.lat, lon: e.latlng.lng });
        map!.closePopup();
      };

      const deleteAlertOnMap = (id: string) => {
        deleteAlert.mutate(id);
        map!.closePopup();
      };

      const popup = L.popup({ ...POPUP_OPT, offset: [0, 0] });
      const container = L.DomUtil.create("div");
      ReactDOM.render(
        <AlertPopup
          categories={categoriesData?.categories || []}
          createAlert={createAlertOnMap}
          deleteAlert={deleteAlertOnMap}
          editAlert={editAlertOnMap}
          item={item}
          updatePopup={() => popup.update()}
          closePopup={() => map!.closePopup()}
        />,
        container
      );

      popup.setLatLng(e.latlng);
      popup.setContent(container);
      popup.openOn(map!);
      popup.setLatLng(e.latlng);
      popup.openOn(map!);
    },
    [map, categoriesData?.categories, createAlert, deleteAlert, editAlertOnMap]
  );

  useEffect(() => {
    map?.addEventListener("click", addAlert);
    return () => {
      map?.removeEventListener("click", addAlert);
    };
  }, [map, addAlert]);

  if (optimizedHidden || alertsData?.markers.length === 0) return <></>;

  return (
    <MapMarkerCluster disableClusteringAtZoom={8}>
      {alertsData?.markers.map((item: any) => (
        <Marker
          icon={renderCategoryIcon(item.categoryCode, item.resolved, markerForEdit === item.id)}
          position={[item.lat, item.lon]}
          key={uuidv4()}
          pane={ALERTS_PANE_NAME}
          interactive={true}
          draggable={true}
          eventHandlers={{
            click: (e) => addAlert(e, item),
            dragstart: () => map?.closePopup(),
            dragend: (e) => {
              alertsData.markers = alertsData.markers.map((marker: any) =>
                marker.id === item.id ? { ...marker, lat: e.target._latlng.lat, lon: e.target._latlng.lng } : marker
              );
              editAlertOnMap(item.id, { lat: e.target._latlng.lat, lon: e.target._latlng.lng });
            },
          }}
        />
      ))}
    </MapMarkerCluster>
  );
});
