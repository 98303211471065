import { GeoJSON } from "react-leaflet";
import { FC, memo, useEffect } from "react";

import { useCustomMapPane, useCustomQuery, useExtentParams } from "../../hooks";
import { URBAN_STYLE } from "./MapUrbanAreas.constants";
import { VectorLayerResponse } from "../../types/responses";
import { getVectorLayersByExtent } from "../../api";

interface MapUrbanAreasProps {
  geoJSON?: VectorLayerResponse;
  hidden?: boolean;
  map: Nullable<L.Map>;
  url?: string;
  zIndex?: number;
}

const URBAN_AREAS_PANE_NAME = "urban-areas-pane";

export const MapUrbanAreas: FC<MapUrbanAreasProps> = memo(
  ({ geoJSON, hidden = false, map, url = "", zIndex = 398 }) => {
    const params = useExtentParams();

    useCustomMapPane({
      map,
      name: URBAN_AREAS_PANE_NAME,
      zIndex,
    });

    const shouldFetchData = !hidden && !geoJSON && !!url;

    const { data, refetch } = useCustomQuery<VectorLayerResponse>(
      params ? getVectorLayersByExtent({ ...params, url: url }) : url,
      {
        enabled: shouldFetchData && params !== null,
        keepPreviousData: true,
      }
    );

    useEffect(() => {
      if (!shouldFetchData || !params) {
        return;
      }
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetchData, params]);

    const urbanAreasData = shouldFetchData ? data : geoJSON;

    if (hidden || !urbanAreasData) {
      return <></>;
    }

    return <GeoJSON data={urbanAreasData} interactive={false} pane={URBAN_AREAS_PANE_NAME} style={URBAN_STYLE} />;
  }
);
