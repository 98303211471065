import { FC } from "react";

import { EnterCommentWrapper } from "./Comments.styled";
import { ReplyTextArea } from "./ReplyTextArea";

export const EnterComment: FC<{
  closePopup: () => void;
  postComment: (text: string) => void;
  placeholder: string;
  rows?: number;
}> = ({ postComment, closePopup, placeholder, rows = 1 }) => {
  return (
    <EnterCommentWrapper>
      <ReplyTextArea
        {...{ rows: rows }}
        onSubmit={(value: string) => postComment(value)}
        onEcape={() => closePopup()}
        placeholder={placeholder}
      />
    </EnterCommentWrapper>
  );
};
