import { memo, useCallback, useEffect, useMemo } from "react";
import { TileLayer } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";
import { useHistory } from "react-router";

import { useMapStore, OVERLAY_LAYERS, ADDITIONAL_LAYER_TYPE } from "../../../stores/MapStore";
import {
  MAX_ZOOM_LEVEL,
  CLEARANCE_Z_INDEX,
  SPANS_Z_INDEX,
  FEEDERS_Z_INDEX,
  URBAN_AREAS_Z_INDEX,
  MIN_ZOOM_LEVEL_FOR_VANTAGE_TILE_LAYER,
  ADDITIONAL_LAYERS_Z_INDEX,
  MIN_ZOOM_LEVEL_FOR_ADDITIONAL_LAYER_MARKERS,
  PARCELS_Z_INDEX,
  PARCELS_MARKERS_Z_INDEX,
  MIN_ZOOM_LEVEL_FOR_DETAILED_PARCELS,
  ENCROACHMENTS_Z_INDEX,
  MIN_ZOOM_LEVEL_FOR_SPANS_LAYER,
  MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE,
} from "./MapView.constants";
import { EMPTY_FN, Key, LEAFLET_ROADS_TILE_LAYER, LEAFLET_VANTAGE_TILE_SERVER_LAYER } from "../../../constants";
import { getForecastRoute } from "../../../routes";
import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import {
  getSearchParams,
  resetToRegionLevelSearchParams,
  resetToLeftMenuSearchParams,
  setStructureIdParam,
  setParcelIdParam,
  setEncroachmentIdParam,
  setSpanIdParam,
  setVisibleDrawerParam,
  setFeederIdParam,
  getQueryParamValue,
} from "../../../utils/navigationUtils";
import { MapClearance } from "../../../components/MapClearance";
import { MapEncroachments } from "../../../components/MapEncroachments";
import { MapFeeders } from "../../../components/MapFeeders";
import { MapStructures } from "../../../components/MapStructures";
import { getUrbanAreasApiUrl, getVectorsApiUrl } from "../../../api";
import {
  useDrawerStore,
  useRightOfWayOverridesStore,
  useSpansStore,
  usePredominatedWorkTypesStore,
  useLeftControlStore,
  useMapSelectionStore,
  ASSET_TYPES,
  useLegendManagementStore,
  LegendStateType,
  useMaintenanceAreaStore,
  useFeederStore,
  useThreatsStore,
  useHierarchyStore,
} from "../../../stores";
import { ContentType } from "../../../stores/DrawerStore/DrawerStore.types";
import useSwitchesStore from "../../../stores/SwitchesStore";
import useStructuresStore from "../../../stores/StructuresStore";
import { useKeyPress, useMapOptimizedHiddenFlag, useStructures } from "../../../hooks";
import { MapAdditionalLayer } from "../../../components/MapAdditionalLayer/MapAdditionalLayer";
import { MapUrbanAreas } from "../../../components/MapUrbanAreas";
import { MapParcels } from "../../../components/MapParcels/MapParcels";
import useParcelsStore from "../../../stores/ParcelsStore";
import { getSpanLineWeight, isGeoJSONEmpty } from "../../../utils/mapUtils";
import { filterParcel, getSpanMarkerIconByRiskPriority } from "./Feeders.utils";
import { RISK_PRIORITY, Span, SpansResponse, WorkTypeResponse } from "../../../types/responses";
import { HydrologyLegend } from "../../../components/HydrologyLegend";
import { getHydrologyPopupHTML, getRiskScorePopup } from "./MapViewPopup.utils";
import { HYDROLOGY_LAYER_COLORS, HYDROLOGY_LAYER_NAMES } from "./MapView.utils";
import { TreeCanopyLegend } from "../../../components/TreeCanopyLegend";
import { WorkTypeLegend } from "../../../components/WorkTypeLegend";
import { getStructureMode } from "../MapScreen.utils";
import { ParcelLegend } from "../../../components/ParcelLegend";
import { MapFeederSegments } from "../../../components/MapFeederSegments";
import useFeederSegmentStore from "../../../stores/FeederSegmentStore";
import { useDynamicSelectedAsset } from "./useDynamicSelectedAsset";
import { Portal } from "../../../components/Portal";
import { LegendWrapper } from "./MapView.styled";
import useCommentsStore from "../../../stores/CommentStore";
import useAlertsStore from "../../../stores/AlertsStore";
import { MapAlerts } from "../../../components/MapAlerts";
import { MapPatrols } from "../../../components/MapPatrols";
import { useTreeCanopyStore } from "../../../stores/TreeCanopyStore";
import { MapMarkers } from "../../../components/MapMarkers";
import { BASE_LAYER } from "../../../constants/map";
import { useThreats } from "../../../hooks/useThreats";
import { useFeederSegments } from "../../../hooks/useFeederSegments";
import { useSpans } from "../../../hooks/useSpans";
import { useParcels } from "../../../hooks/useParcels";
import { usePatrolsStore } from "../../../stores/PatrolsStore";

const VANTAGE_TILE_SERVER_URL = process.env.REACT_APP_VANTAGE_TILE_SERVER_URL;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN_MAP;
const VANTAGE_TILE_SERVER_ID = "VANTAGE_TILE_SERVER";
const MAPBOX_ROADS_URL = process.env.REACT_APP_MAPBOX_ROADS_URL;

export const Feeders = memo(() => {
  const feedersGeoJson = useFeederStore((store) => store.feedersGeoJson);
  const hiddenFeeders = useFeederStore((store) => store.hiddenFeeders);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const { setSelectedFeederById } = useFeederStore((store) => store.actions);
  const selectedFeeder = useFeederStore((store) => store.selectedFeeder);
  const map = useMapStore((store) => store.map);
  const selectedEncroachment = useThreatsStore((store) => store.selectedThreat);
  const imagerySources = useMapStore((store) => store.imagerySources);
  const selectedImagerySourceId = useMapStore((store) => store.selectedImagerySourceId);
  const overlayMapLayers = useMapStore((store) => store.overlayMapLayers);
  const overlaySwitchControl = useMapStore((store) => store.overlaySwitchControl);
  const spansGeoJson = useSpansStore((store) => store.geoJSON);
  const spansById = useSpansStore((store) => store.byId);
  const selectedSpanId = useSpansStore((store) => store.selectedId);
  const spanRiskPriority = useHierarchyStore((store) => store.riskPrioritySelected);
  const vectorLayers = useMapStore((store) => store.vectorLayers);
  const pushOnDrawer = useDrawerStore((store) => store.push);
  const clearDrawerStack = useDrawerStore((store) => store.clearStack);
  const structuresGeoJSON = useStructuresStore((store) => store.structuresGeoJSON);
  const selectedStructure = useStructuresStore((store) => store.selectedId);
  const structuresById = useStructuresStore((store) => store.structuresById);
  const getSwitchByStructureId = useSwitchesStore((store) => store.getSwitchByStructureId);
  const parcelsGeoJSON = useParcelsStore((store) => store.geoJSON);
  const parcelSelectedId = useParcelsStore((store) => store.selectedId);
  const localRightOfWayOverrides = useRightOfWayOverridesStore((store) => store.localOverrides);
  const getOverrideBySpanId = useRightOfWayOverridesStore((store) => store.getBySpanId);
  const closeMapPopup = useMapStore((store) => store.closeMapPopup);
  const baseLayer = useMapStore((store) => store.baseLayer);
  const isRiskViewHidden = useStructuresStore((store) => store.riskViewHidden);
  const isSpanOverridenByParcel = useRightOfWayOverridesStore((store) => store.isSpanOverridenByParcel);
  const plannersViewHidden = usePredominatedWorkTypesStore((store) => store.hidden);
  const selectedWorkTypes = usePredominatedWorkTypesStore((store) => store.selectedWorkTypes);
  const selectAllWorkTypes = usePredominatedWorkTypesStore((store) => store.selectAll);
  const toggleWorkType = usePredominatedWorkTypesStore((store) => store.toggleWorkType);
  const visibleWorkTypes = usePredominatedWorkTypesStore((store) => store.visibleWorkTypes);
  const spansWithPredominantWorkType = useSpansStore((store) => store.spansWithPredominatedWorkType);
  const toggled = useLeftControlStore((store) => store.toggled);
  const setToggled = useLeftControlStore((store) => store.setToggled);
  const legendStateManagement = useLegendManagementStore((store) => store.legendState);
  const setLegendExpandState = useLegendManagementStore((store) => store.toggleExpand);
  const workTypesById = usePredominatedWorkTypesStore((store) => store.workTypesById);
  const selectedAllWorkTypes = usePredominatedWorkTypesStore((store) => store.selectedAll);
  const hiddenParcelLayer = useParcelsStore((store) => store.parcelLayerHidden);
  const toggleAllParcelStatuses = useParcelsStore((store) => store.toggleAll);
  const toggleParcelStatus = useParcelsStore((store) => store.toggleSelectedStatus);
  const parcelStatuses = useParcelsStore((store) => store.selectedStatuses);
  const isSelectedAllParcelStatuses = useParcelsStore((store) => store.isAllSelected);
  const feederSegmentGeoJson = useFeederSegmentStore((store) => store.geoJSON);
  const setFeederSegment = useFeederSegmentStore((store) => store.setSelectedFeederSegmentById);
  const selectedFeederSegment = useFeederSegmentStore((store) => store.selectedId);
  const fragmentPositions = useFeederSegmentStore((store) => store.positions);
  const additionalOverlayOpacity = useMapStore((store) => store.additionalOverlayOpacity);
  const selectedAssets = useMapSelectionStore((store) => store.selectedLayers);
  const selectedAsset = useMapSelectionStore((store) => store.selectedAssetType);
  const addSelectedByShiftKey = useMapSelectionStore((store) => store.addSelectedByShiftKey);
  const resetSelectionTool = useMapSelectionStore((store) => store.resetSelectionTool);
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );
  const maintenanceAreasBySpanId = useMaintenanceAreaStore((store) => store.bySpanId);
  const isMALayerVisible = useMaintenanceAreaStore((store) => store.isLayerVisible);
  const areCommentsVisible = useCommentsStore((store) => store.isLayerVisible);
  const areAlertsVisible = useAlertsStore((store) => store.isLayerVisible);
  const hiddenSelectionTool = useMapSelectionStore((store) => store.hidden);
  const isShiftKeyPressed = useKeyPress(Key.Shift);
  const isTreeCanopyVisible = useTreeCanopyStore((store) => store.layerVisible);

  const threats = useThreatsStore((store) => store.threats);
  const regionsByRiskPriority = useHierarchyStore((store) => store.regionsByRiskPriority);
  const isPatrolLayerVisible = usePatrolsStore((store) => store.isVisible);
  useDynamicSelectedAsset();
  useThreats();
  useStructures();
  useFeederSegments();
  useSpans();
  useParcels();

  const isLayerDisabled = areAlertsVisible || areCommentsVisible;

  const [isVantageTileServerLayerHidden] = useMapOptimizedHiddenFlag(
    currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_VANTAGE_TILE_LAYER &&
      imagerySources &&
      selectedImagerySourceId &&
      baseLayer !== BASE_LAYER.LIGHT_LAYER &&
      overlaySwitchControl.IMAGERY_SOURCES.checked
      ? false
      : true
  );
  const [isRoadsLayerHidden] = useMapOptimizedHiddenFlag(!overlayMapLayers[OVERLAY_LAYERS.ROADS]);

  const history = useHistory();

  const expandCollapseLegend = useCallback(
    (legendType: LegendStateType) => {
      setLegendExpandState(legendType);
    },
    [setLegendExpandState]
  );

  const openDrawer = useCallback(
    (e, type) => {
      if (!map || !e.target.feature || !e.target.feature.properties) {
        return;
      }
      clearDrawerStack();
      pushOnDrawer({
        type: type,
        id: e.target.feature.properties.id,
        coordinates: e.latlng as L.LatLngExpression,
      });
    },
    [map, pushOnDrawer, clearDrawerStack]
  );

  const openStructureDrawer = useCallback(
    (e, id) => {
      if (isShiftKeyPressed) {
        const lastId = addSelectedByShiftKey(id, ASSET_TYPES.STRUCTURE);
        //if user delete all but not last one
        if (!lastId) {
          return;
        }
        id = lastId;
      }
      resetSelectionTool(id, ASSET_TYPES.STRUCTURE);
      L.DomEvent.stopPropagation(e);
      const queryParams = getSearchParams();
      resetToRegionLevelSearchParams(queryParams);
      setStructureIdParam({ params: queryParams, id });
      setVisibleDrawerParam({ params: queryParams, visible: true });
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
      clearDrawerStack();
      pushOnDrawer({
        coordinates: e.latlng as L.LatLngExpression,
        id: id,
        type: ContentType.STRUCTURE,
      });
    },
    [pushOnDrawer, history, clearDrawerStack, isShiftKeyPressed, addSelectedByShiftKey, resetSelectionTool]
  );

  const onParcelClick = useCallback(
    (e, id) => {
      L.DomEvent.stopPropagation(e);
      if (!map || !id) {
        return;
      }
      if (isShiftKeyPressed) {
        const lastId = addSelectedByShiftKey(id, ASSET_TYPES.PARCEL);
        if (!lastId) {
          return;
        }
        id = lastId;
      }
      resetSelectionTool(id, ASSET_TYPES.PARCEL);
      const queryParams = getSearchParams();
      resetToLeftMenuSearchParams(queryParams);
      setParcelIdParam({ params: queryParams, id });
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
      clearDrawerStack();
      pushOnDrawer({
        id: id,
        type: ContentType.PARCEL,
      });
    },
    [pushOnDrawer, history, clearDrawerStack, map, isShiftKeyPressed, addSelectedByShiftKey, resetSelectionTool]
  );

  const clickOnEncroachment = useCallback(
    (e, encroachmentId) => {
      L.DomEvent.stopPropagation(e);
      openDrawer(e, ContentType.ENCROACHMENT);
      if (encroachmentId === selectedEncroachment) {
        return;
      }
      const queryParams = getSearchParams();
      resetToRegionLevelSearchParams(queryParams);
      setEncroachmentIdParam({ params: queryParams, id: encroachmentId });
      queryParams.set(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER, "true");
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
      clearDrawerStack();
      pushOnDrawer({
        coordinates: e.latlng as L.LatLngExpression,
        id: encroachmentId,
        type: ContentType.ENCROACHMENT,
      });
    },
    [selectedEncroachment, history, openDrawer, clearDrawerStack, pushOnDrawer]
  );

  const onEncroachmentClick = useCallback(
    (e: L.LeafletMouseEvent) => {
      if (isLayerDisabled) return;
      L.DomEvent.stopPropagation(e);
      const encroachmentId = e.target?.feature?.properties?.id;
      if (!encroachmentId) {
        return;
      }
      clickOnEncroachment(e, encroachmentId);
    },
    [clickOnEncroachment, isLayerDisabled]
  );

  const isSwitch = useCallback((id) => !!getSwitchByStructureId(id), [getSwitchByStructureId]);

  const onFeederClick = useCallback(
    (e) => {
      L.DomEvent.stopPropagation(e);
      const params = getSearchParams();
      const id = e.target.feature.properties.id;

      setLatestObjectIdSelectedForMapAnimation(id);
      // Set URL params
      resetToLeftMenuSearchParams(params);
      setFeederIdParam({ id, params });
      history.push({ pathname: getForecastRoute(), search: params.toString() });

      // Make sure LHS is opened
      // And open selected feeder
      setToggled(true);
      setSelectedFeederById(id);
    },
    [setLatestObjectIdSelectedForMapAnimation, history, setSelectedFeederById, setToggled]
  );

  const onSpanClick = useCallback(
    (e, id?: Nullable<string>) => {
      L.DomEvent.stopPropagation(e);

      const spanId = id || e.target?.feature?.properties?.spanId;
      if (!spanId) {
        return;
      }
      const lastSelectedSpanId = getQueryParamValue(FORECAST_QUERY_PARAMS.SPAN_ID);
      if (lastSelectedSpanId === spanId) {
        return;
      }

      if (isShiftKeyPressed) {
        const lastId = addSelectedByShiftKey(spanId, ASSET_TYPES.SPAN);
        if (!lastId) {
          return;
        }
        id = lastId;
      }
      resetSelectionTool(spanId, ASSET_TYPES.SPAN);
      openDrawer(e, ContentType.SPAN);
      const queryParams = getSearchParams();
      resetToLeftMenuSearchParams(queryParams);
      // set feeder id if not already set

      const feederId = e.target?.feature?.properties?.feederId;
      feederId &&
        setFeederIdParam({
          params: queryParams,
          id: feederId,
        });

      setFeederSegment(e.target?.feature?.properties.id);
      setSpanIdParam({ params: queryParams, id: spanId! });
      setVisibleDrawerParam({ params: queryParams, visible: true });
      return history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
    },
    [openDrawer, history, setFeederSegment, isShiftKeyPressed, addSelectedByShiftKey, resetSelectionTool]
  );

  const openPopup = useCallback(
    (e) => {
      if (!map || !e.target.feature || !e.target.feature.properties) {
        return;
      }
      const name: keyof typeof HYDROLOGY_LAYER_NAMES = e.target.feature.properties.name;
      const html = getHydrologyPopupHTML(HYDROLOGY_LAYER_COLORS[name], HYDROLOGY_LAYER_NAMES[name]);
      if (!html) {
        return;
      }
      const popup = L.popup();
      popup.setLatLng(e.latlng);
      popup.setContent(html);
      popup.openOn(map);
    },
    [map]
  );

  useEffect(() => {
    if (
      vectorLayers?.[ADDITIONAL_LAYER_TYPE.HYDROLOGY].checked &&
      currentZoomLevel <= MIN_ZOOM_LEVEL_FOR_ADDITIONAL_LAYER_MARKERS
    ) {
      closeMapPopup();
    }
  }, [currentZoomLevel, vectorLayers, closeMapPopup]);

  const checkedAdditionalVectorLayers = useMemo(() => {
    if (!vectorLayers) {
      return null;
    }

    return Object.keys(vectorLayers).map((key) => {
      return (
        <MapAdditionalLayer
          map={map}
          key={key}
          disableMarkers={
            key === ADDITIONAL_LAYER_TYPE.HYDROLOGY.toString() ||
            (key !== ADDITIONAL_LAYER_TYPE.HAZARD_TREE.toString() &&
              currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_ADDITIONAL_LAYER_MARKERS)
          }
          disablePolygons={
            key !== ADDITIONAL_LAYER_TYPE.HYDROLOGY.toString() &&
            (key === ADDITIONAL_LAYER_TYPE.HAZARD_TREE.toString() ||
              currentZoomLevel < MIN_ZOOM_LEVEL_FOR_ADDITIONAL_LAYER_MARKERS)
          }
          onHoverDisabled={key !== ADDITIONAL_LAYER_TYPE.HAZARD_TREE.toString()}
          additionalLayerType={key}
          url={`${getVectorsApiUrl()}/${key}`}
          zIndex={ADDITIONAL_LAYERS_Z_INDEX}
          hidden={!vectorLayers[key].checked}
          category={vectorLayers[key].category}
          checked={vectorLayers[key].checked}
          interactive={vectorLayers[ADDITIONAL_LAYER_TYPE.HYDROLOGY].checked}
          onClick={vectorLayers[ADDITIONAL_LAYER_TYPE.HYDROLOGY].checked ? openPopup : EMPTY_FN}
        />
      );
    });
  }, [vectorLayers, map, currentZoomLevel, openPopup]);

  const selectedSpansMarkers = useMemo(() => {
    if (!regionsByRiskPriority?.positions?.length) {
      return null;
    }
    const markersPositions: Nullable<Record<string, L.LatLngExpression>> = {};

    regionsByRiskPriority.positions.forEach((position) => {
      markersPositions[position.id] = (position.centerPoint ?? []) as L.LatLngExpression;
    });

    return markersPositions;
  }, [regionsByRiskPriority]);

  const filteredParcelsGeoJSON = useMemo(() => {
    if (
      (!selectedSpanId && hiddenParcelLayer && hiddenSelectionTool) ||
      !spansById ||
      !Object.keys(spansById)?.length ||
      isGeoJSONEmpty(parcelsGeoJSON)
    ) {
      return null;
    }

    const selectedSpan = selectedSpanId ? spansById?.[selectedSpanId] : null;
    const parcelIds = selectedSpan?.parcelIds || [];
    return {
      ...parcelsGeoJSON,
      type: "FeatureCollection" as const,
      features:
        parcelsGeoJSON!.features?.filter((feature) => {
          return filterParcel(parcelIds, hiddenParcelLayer && hiddenSelectionTool, parcelStatuses, feature.properties);
        }) || [],
    };
  }, [selectedSpanId, spansById, parcelsGeoJSON, hiddenParcelLayer, parcelStatuses, hiddenSelectionTool]);

  const spanMarkersIcon = useMemo(
    () =>
      new L.Icon({
        iconUrl: getSpanMarkerIconByRiskPriority(spanRiskPriority || RISK_PRIORITY.LOW),
        className: "span-risk-priority-marker",
        iconSize: [28, 28],
      }),
    [spanRiskPriority]
  );

  const rightOfWayFilter = useCallback(
    (feature: Span) => {
      return !(feature?.properties?.id in localRightOfWayOverrides);
    },
    [localRightOfWayOverrides]
  );

  const rightOfWayLocalOverrides = useMemo(() => {
    const overrides = Object.values(localRightOfWayOverrides);

    return {
      type: "FeatureCollection",
      features: overrides,
    } as Nullable<SpansResponse>;
  }, [localRightOfWayOverrides]);

  const isOverriden = useCallback(
    (id: string) => {
      return !!getOverrideBySpanId(id) || isSpanOverridenByParcel(id);
    },
    [getOverrideBySpanId, isSpanOverridenByParcel]
  );

  const visibleSpansBySelectedWorkTypes = useMemo(() => {
    if (
      !spansWithPredominantWorkType ||
      !visibleWorkTypes ||
      !visibleWorkTypes.length ||
      !Object.keys(spansWithPredominantWorkType).length
    ) {
      return null;
    }

    const result: Record<string, WorkTypeResponse> = {};
    Object.keys(spansWithPredominantWorkType)
      .filter((key) => visibleWorkTypes.find((workType) => workType === spansWithPredominantWorkType[key].id))
      .forEach((key) => (result[key] = spansWithPredominantWorkType[key]));

    return result;
  }, [spansWithPredominantWorkType, visibleWorkTypes]);

  const onMarkerMouseOver = useCallback(
    (e: LeafletMouseEvent, id: string) => {
      const riskScore = regionsByRiskPriority?.positions?.find((region) => region.id === id)?.riskPriority;
      const html = getRiskScorePopup(spanRiskPriority, riskScore?.score ?? 0);

      if (!map || !id || !html) {
        return;
      }

      const popup = L.popup({ className: "risk-priority-marker" });
      popup.setLatLng(e.latlng);
      popup.setContent(html);
      popup.openOn(map);
    },
    [map, regionsByRiskPriority, spanRiskPriority]
  );

  const onMarkerMouseOut = useCallback(() => {
    if (!map) {
      return;
    }
    map.closePopup();
  }, [map]);

  useEffect(() => {
    if (!areAlertsVisible) {
      map?.closePopup();
    }
  }, [map, areAlertsVisible]);

  const selectedStructuresIds = useMemo(() => {
    if (selectedAsset !== ASSET_TYPES.STRUCTURE && !selectedStructure) {
      return null;
    }

    const baseList = selectedAssets?.[ASSET_TYPES.STRUCTURE]?.length ? selectedAssets?.[ASSET_TYPES.STRUCTURE] : [];
    return selectedStructure ? baseList?.concat(selectedStructure) : baseList;
  }, [selectedStructure, selectedAsset, selectedAssets]);

  const selectedSpanIds = useMemo(() => {
    if (selectedAsset !== ASSET_TYPES.SPAN && !selectedSpanId) {
      return null;
    }

    const baseList = selectedAssets?.[ASSET_TYPES.SPAN]?.length ? selectedAssets?.[ASSET_TYPES.SPAN] : [];
    return selectedSpanId ? baseList?.concat(selectedSpanId) : baseList;
  }, [selectedSpanId, selectedAsset, selectedAssets]);

  const selectedParcelIds = useMemo(() => {
    if (selectedAsset !== ASSET_TYPES.PARCEL && !parcelSelectedId) {
      return null;
    }

    const baseList = selectedAssets?.[ASSET_TYPES.PARCEL]?.length ? selectedAssets?.[ASSET_TYPES.PARCEL] : [];
    return parcelSelectedId ? baseList?.concat(parcelSelectedId) : baseList;
  }, [parcelSelectedId, selectedAsset, selectedAssets]);

  return (
    <>
      <Portal selector="layer-menu-root">
        <LegendWrapper leftMenuHidden={!toggled}>
          <HydrologyLegend hidden={!vectorLayers?.[ADDITIONAL_LAYER_TYPE.HYDROLOGY].checked} />
          <TreeCanopyLegend
            hidden={!isTreeCanopyVisible}
            expanded={legendStateManagement?.[LegendStateType.TREE_RECON].expanded}
            setExpanded={() => expandCollapseLegend(LegendStateType.TREE_RECON)}
            leftMenuHidden={!toggled}
          />
          <ParcelLegend
            expanded={legendStateManagement?.[LegendStateType.PARCEL]?.expanded}
            setExpanded={() => expandCollapseLegend(LegendStateType.PARCEL)}
            hidden={hiddenParcelLayer}
            toggleStatus={toggleParcelStatus}
            toggleAll={toggleAllParcelStatuses}
            selectedAll={isSelectedAllParcelStatuses}
            selectedStatuses={parcelStatuses}
            leftMenuHidden={!toggled}
          />
          <WorkTypeLegend
            hidden={plannersViewHidden}
            workTypes={selectedWorkTypes}
            selectAll={selectAllWorkTypes}
            toggleWorkType={toggleWorkType}
            leftMenuHidden={!toggled}
            toggleLegend={() => expandCollapseLegend(LegendStateType.WORK_TYPE)}
            expanded={legendStateManagement?.[LegendStateType.WORK_TYPE]?.expanded}
            selectedAll={selectedAllWorkTypes}
          />
        </LegendWrapper>
      </Portal>
      <MapUrbanAreas
        map={map}
        url={getUrbanAreasApiUrl()}
        zIndex={URBAN_AREAS_Z_INDEX}
        hidden={!overlayMapLayers["Urban Areas"]}
      />
      {checkedAdditionalVectorLayers}
      <MapClearance
        filter={rightOfWayFilter}
        geoJSON={spansGeoJson}
        hidden={false}
        map={map}
        selectedSpans={selectedSpanIds ?? null}
        zIndex={CLEARANCE_Z_INDEX}
        isOverriden={isOverriden}
        maintenanceAreasBySpanId={isMALayerVisible || !hiddenSelectionTool ? maintenanceAreasBySpanId : null}
        invisibleActiveMA={!hiddenSelectionTool && selectedAsset !== ASSET_TYPES.MA}
      />

      <MapClearance
        geoJSON={rightOfWayLocalOverrides}
        hidden={false}
        map={map}
        selectedSpans={selectedSpanIds ?? null}
        zIndex={CLEARANCE_Z_INDEX}
        isOverriden={isOverriden}
      />
      <MapFeeders
        geoJSON={feedersGeoJson}
        hidden={false}
        map={map}
        onClick={onFeederClick}
        selectedFeeder={selectedFeeder}
        zIndex={FEEDERS_Z_INDEX}
        hiddenFeeders={hiddenFeeders}
      />
      <MapFeederSegments
        geoJSON={feederSegmentGeoJson}
        hidden={false}
        map={map}
        onClick={onSpanClick}
        selectedGroupId={selectedFeeder}
        selectedId={selectedFeederSegment}
        zIndex={SPANS_Z_INDEX}
        spansWithWorkType={visibleSpansBySelectedWorkTypes}
        enabledWorkType={!plannersViewHidden && currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_SPANS_LAYER}
        feederSegmentPositions={fragmentPositions}
        workTypesById={workTypesById}
        lineWidth={getSpanLineWeight(currentZoomLevel)}
        disableMarkers={plannersViewHidden || currentZoomLevel < MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE}
        hiddenFeeders={hiddenFeeders}
        selectedSpans={selectedSpanIds ?? null}
      />
      <MapMarkers
        markers={selectedSpansMarkers}
        hidden={currentZoomLevel >= 18}
        onClick={onSpanClick}
        icon={spanMarkersIcon}
        onMarkerMouseOver={onMarkerMouseOver}
        onMarkerMouseOut={onMarkerMouseOut}
      />
      <MapEncroachments
        data={threats}
        map={map}
        onClick={onEncroachmentClick}
        selectedEncroachment={selectedEncroachment}
        hidden={false}
        zIndex={ENCROACHMENTS_Z_INDEX}
        opacity={additionalOverlayOpacity["Infringements"]}
      />
      <MapStructures
        mode={getStructureMode(currentZoomLevel)}
        geoJSON={structuresGeoJSON}
        hidden={false}
        isSwitch={isSwitch}
        map={map}
        onClick={openStructureDrawer}
        displayStatusColors={!isRiskViewHidden}
        selectedStructures={selectedStructuresIds ?? null}
        structuresById={structuresById}
      />
      <MapParcels
        detailedMode={currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_DETAILED_PARCELS}
        geoJSON={filteredParcelsGeoJSON}
        hidden={false}
        map={map}
        markersZIndex={PARCELS_MARKERS_Z_INDEX}
        onClick={onParcelClick}
        zIndex={PARCELS_Z_INDEX}
        parcelLayerOn={!hiddenParcelLayer || !hiddenSelectionTool}
        multipleSelectedIds={selectedParcelIds ?? []}
      />

      {areAlertsVisible && <MapAlerts map={map} hidden={!areAlertsVisible} />}
      {isPatrolLayerVisible && <MapPatrols map={map} hidden={!isPatrolLayerVisible} />}

      {!isVantageTileServerLayerHidden && (
        <TileLayer
          id={VANTAGE_TILE_SERVER_ID}
          key={`${VANTAGE_TILE_SERVER_ID}_${imagerySources[selectedImagerySourceId!].layerCode}`}
          tileSize={256}
          maxZoom={MAX_ZOOM_LEVEL}
          url={`${VANTAGE_TILE_SERVER_URL}/${imagerySources[selectedImagerySourceId!].layerCode}/{z}/{x}/{y}.png`}
          zIndex={LEAFLET_VANTAGE_TILE_SERVER_LAYER}
        />
      )}
      {!isRoadsLayerHidden && (
        <TileLayer
          maxZoom={MAX_ZOOM_LEVEL}
          id="mapbox-roads"
          tileSize={512}
          zoomOffset={-1}
          accessToken={MAPBOX_ACCESS_TOKEN}
          url={`${MAPBOX_ROADS_URL}${MAPBOX_ACCESS_TOKEN}`}
          zIndex={LEAFLET_ROADS_TILE_LAYER}
        />
      )}
    </>
  );
});
