import styled from "styled-components";
import { Data3, FontStyle, Head2 } from "../../assets/styles/fonts.styled";

export const JobCompatibilityText = styled.div`
  cursor: pointer;
  ${Head2}
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 15px;
  width: 100%;
  max-width: 200px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 7px;
`;

export const JobWrapper = styled.div`
  display: flex;
  background: #e7f7f9;
  border-radius: 30px;
  min-width: 100px;
  height: 35px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  div {
    ${Data3}
    color: var(--colors-black);
    margin-right: 13px;
  }
  .close {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  .close:hover {
    path {
      fill: var(--colors-black);
    }
  }
  .close path {
    fill: var(--colors-vantage-green);
  }
`;

export const ModalWrapper = styled.div`
  position: absolute;
  min-width: 251px;
  min-height: 400px;
  top: 250px;
  left: 360px;
  background: var(--colors-white);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 2000;

  display: flex;
  flex-direction: column;
  .checkbox__text {
    margin-left: 10px;
  }
`;
export const ModalHeader = styled.div`
  padding: 20px;
  ${FontStyle}
  font-weight: 700;
  font-size: 14px;
  color: #1e1e1e;
`;
export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px 10px;
  overflow-x: hidden;
  max-height: 400px;
  box-sizing: border-box;
`;
export const ModalFooter = styled.div`
  height: 68px;
  background: var(--colors-white);
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);
  div button {
    width: 100px;
  }
`;
