import { FC, useMemo } from "react";

import {
  CloseIcon,
  RightDrawerHeader,
  RightDrawerHeaderPartLeft,
  RightDrawerWrapper,
  CloseImageWrapper,
  RightDrawerHeaderColumn,
  BackImageWrapper,
  BackButton,
  AssetImage,
} from "./RightDrawer.styled";
import LeftArrow from "../../assets/images/arrow_l.svg";
import StructureImg from "../../assets/images/structure_icon.svg";
import SpanIcon from "./../../assets/images/span_icon.svg";
import InfringementIcon from "./../../assets/images/infringement_icon.svg";
import { ContentType } from "../../stores/DrawerStore/DrawerStore.types";
import ParcelImg from "./../../assets/images/parcel_icon-selected.svg";
import { ReactComponent as CustomPolygonImg } from "./../../assets/images/cwc.svg";

interface RightDrawerProperties {
  content: Record<string, any>;
  hasPreviousLevel?: boolean;
  onBack: () => void;
  closeDrawer: () => void;
  type: Maybe<ContentType>;
  scroll: boolean;
}
export const RightDrawer: FC<RightDrawerProperties> = ({
  content,
  hasPreviousLevel,
  onBack,
  closeDrawer,
  type,
  scroll,
  children,
}) => {
  const headerIcon = useMemo(() => {
    if (!type) {
      return "";
    }
    switch (type) {
      case ContentType.SPAN:
        return SpanIcon;
      case ContentType.STRUCTURE:
        return StructureImg;
      case ContentType.ENCROACHMENT:
        return InfringementIcon;
      case ContentType.PARCEL:
        return ParcelImg;
    }
  }, [type]);

  if (!content) {
    return <></>;
  }

  return (
    <RightDrawerWrapper>
      <RightDrawerHeader singleColumn={!hasPreviousLevel} scroll={scroll}>
        <RightDrawerHeaderColumn singleColumn={!hasPreviousLevel}>
          {type !== ContentType.CUSTOM_POLYGON && <AssetImage src={headerIcon} singleColumn={!hasPreviousLevel} />}
          {type === ContentType.CUSTOM_POLYGON && <CustomPolygonImg />}
          {hasPreviousLevel && (
            <BackImageWrapper onClick={onBack}>
              <BackButton src={LeftArrow} />
            </BackImageWrapper>
          )}
          <RightDrawerHeaderPartLeft id="rhs-title">{content?.sectionName}</RightDrawerHeaderPartLeft>
          <CloseImageWrapper onClick={closeDrawer}>
            <CloseIcon />
          </CloseImageWrapper>
        </RightDrawerHeaderColumn>
      </RightDrawerHeader>
      {children}
    </RightDrawerWrapper>
  );
};
