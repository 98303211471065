import React, { FC } from "react";

import { getRiskPriorityColor } from "../../../pages/MapScreen/MapView/MapView.utils";
import { SpanRiskPriority } from "../../../stores";
import { RISK_PRIORITY } from "../../../types/responses";
import Chips from "../Chips/Chips";
import { SpanPriorityWrapper, SpanPriorityLabel, SpanPriorityItems, SpanPriorityItem } from "./SpanPriority.styled";

interface SpanPriorityProps {
  selectFilter: (e: unknown, priority: RISK_PRIORITY) => void;
  lengthByPriority: Record<string, number>;
  spanRiskPriority: SpanRiskPriority;
}
export const SpanPriority: FC<SpanPriorityProps> = ({ selectFilter, lengthByPriority, spanRiskPriority }) => {
  return (
    <SpanPriorityWrapper>
      <SpanPriorityLabel>Span Priority:</SpanPriorityLabel>
      <SpanPriorityItems>
        <Chips
          bg={getRiskPriorityColor(RISK_PRIORITY.VIOLATION)}
          onClick={(e) => selectFilter(e, RISK_PRIORITY.VIOLATION)}
          selected={spanRiskPriority === RISK_PRIORITY.VIOLATION}
          value={lengthByPriority[RISK_PRIORITY.VIOLATION]}
          label={"VIOLATION"}
          additionalLabel={"1 day"}
        />
        <Chips
          bg={getRiskPriorityColor(RISK_PRIORITY.CRITICAL)}
          onClick={(e) => selectFilter(e, RISK_PRIORITY.CRITICAL)}
          selected={spanRiskPriority === RISK_PRIORITY.CRITICAL}
          value={lengthByPriority[RISK_PRIORITY.CRITICAL]}
          label={"CRITICAL"}
          additionalLabel={"3 months"}
        />
        <Chips
          bg={getRiskPriorityColor(RISK_PRIORITY.HIGH)}
          onClick={(e) => selectFilter(e, RISK_PRIORITY.HIGH)}
          selected={spanRiskPriority === RISK_PRIORITY.HIGH}
          value={lengthByPriority[RISK_PRIORITY.HIGH]}
          label={"HIGH"}
          additionalLabel={"6 months"}
        />
        <Chips
          bg={getRiskPriorityColor(RISK_PRIORITY.MEDIUM)}
          onClick={(e) => selectFilter(e, RISK_PRIORITY.MEDIUM)}
          selected={spanRiskPriority === RISK_PRIORITY.MEDIUM}
          value={lengthByPriority[RISK_PRIORITY.MEDIUM]}
          label={"MEDIUM"}
          additionalLabel={"1 year"}
        />
        <Chips
          bg={getRiskPriorityColor(RISK_PRIORITY.LOW)}
          onClick={(e) => selectFilter(e, RISK_PRIORITY.LOW)}
          selected={spanRiskPriority === RISK_PRIORITY.LOW}
          value={lengthByPriority[RISK_PRIORITY.LOW]}
          label={"LOW"}
          additionalLabel={"2 years"}
        />
        <SpanPriorityItem></SpanPriorityItem>
      </SpanPriorityItems>
    </SpanPriorityWrapper>
  );
};
