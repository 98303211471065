import styled from "styled-components";
import { boxShadow, BoxShadowProps } from "styled-system";

export const RadioContainer = styled.div`
  position: relative;
  display: inline-block;
  stroke: ${(props) => props.theme.colors.frenchGray};
  & input[type="radio"]:hover {
    cursor: pointer;
  }
  & input[type="radio"]:disabled {
    cursor: not-allowed;
  }
  & input[type="radio"]:hover ~ svg circle:first-child {
    stroke: ${(props) => props.theme.colors.teal3};
  }
  & input[type="radio"]:hover ~ svg.radio-unchecked {
    stroke: ${(props) => props.theme.colors.frenchGray};
  }
  & input[type="radio"] ~ svg.radio-checked {
    display: none;
  }
  & input[type="radio"]:checked ~ svg.radio-unchecked {
    display: none;
  }
  & input[type="radio"]:checked ~ svg.radio-checked {
    display: block;
  }
`;

export const RadioInput = styled.input`
  position: absolute;
  appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-family: Barlow !important;
  font-weight: 500;
  font-size: 13px;
`;

export const RadioText = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.riverBad};
  font-size: 13px;
`;

export const RadioImage = styled.img<BoxShadowProps & { disabled?: boolean }>`
  background: ${(props) => props.theme.colors.frenchGray};
  border-radius: 5px;
  margin-left: 14px;
  margin-right: 14px;
  width: 30px;
  height: 30px;
  ${boxShadow}
  ${(props) => (props.disabled ? "filter: grayscale(100%);" : "")};
`;

export const AdditionalInfo = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--colors-oslo-gray-2);
`;

export const RadioImageWrapper = styled.div`
  background: #ececec;
  border: 1px solid ${(props) => props.theme.colors.frenchGray};
  box-sizing: border-box;
  border-radius: 4px;
  width: 18px;
  height: 18px;
`;

export const TextWrapper = styled.div``;
