import { useEffect } from "react";
import { useHistory } from "react-router";

import { getErrorRoute } from "../../routes";
import { logError } from "../../services";

export const EnvironmentVariablesCheck = () => {
  const history = useHistory();
  useEffect(() => {
    // Add check for new critical variables.
    if (
      !process.env.REACT_APP_BASE_URL ||
      !process.env.REACT_APP_PUBLIC_ENV_NAME ||
      !process.env.REACT_APP_ACCESS_TOKEN_MAP ||
      !process.env.REACT_APP_VANTAGE_TILE_SERVER_URL ||
      !process.env.REACT_APP_KEYCLOAK_URL ||
      !process.env.REACT_APP_KEYCLOAK_REALM ||
      !process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
      !process.env.REACT_APP_MAPBOX_SATELLITE_URL ||
      !process.env.REACT_APP_MAPBOX_WHITE_MODE_URL ||
      !process.env.REACT_APP_MAPBOX_ROADS_URL ||
      !process.env.REACT_APP_MAPBOX_TOPOGRAPHIC_URL ||
      !process.env.REACT_APP_WEBSITE_NAME ||
      !process.env.REACT_APP_FAVICON_NAME
    ) {
      // log error for future tracking tools
      logError("Required Environment variables are missing. Please check configuration files.");
      history.push(getErrorRoute());
    }
  }, [history]);

  return <></>;
};
