import { FC } from "react";
import { Wrapper, Name, Date } from "./WorkOrderTile.styled";

interface IProps {
  name: string;
  date: string;
  onClick?: () => void;
}

export const WorkOrderTile: FC<IProps> = ({ name, date, onClick }) => {
  return (
    <Wrapper onClick={onClick} isClickable={!!onClick}>
      <Name>{name}</Name>
      <Date>{date}</Date>
    </Wrapper>
  );
};
