import { FC } from "react";
import { Tooltip as ReachTooltip } from "@reach/tooltip";
import "@reach/tooltip/styles.css";

import { SectionItemWrapper, SectionLabel, SectionValue, SectionLabelWrapper } from "./DrawerSectionItems.styled";
import "./DrawerSectionItems.styled.css";
import { MarginProps } from "styled-system";

const TEXT_LENGTH = 20;

type SectionProps = {
  name: string;
  hasBorder?: boolean;
  bold?: boolean;
};

export const SectionItem: FC<SectionProps & MarginProps> = ({
  name,
  hasBorder = true,
  bold = false,
  children,
  ...props
}) => {
  const isStringValue = typeof children === "string";
  const useTooltip = isStringValue && (children as string).length > TEXT_LENGTH;
  return (
    <SectionItemWrapper hasBorder={hasBorder} {...props}>
      <SectionLabel bold={bold}>{name}</SectionLabel>
      {isStringValue ? (
        <SectionLabelWrapper>
          {useTooltip ? (
            <ReachTooltip label={useTooltip ? children : undefined}>
              <SectionValue showCursor={useTooltip}>{children}</SectionValue>
            </ReachTooltip>
          ) : (
            <SectionValue showCursor={useTooltip}>{children}</SectionValue>
          )}
        </SectionLabelWrapper>
      ) : (
        children
      )}
    </SectionItemWrapper>
  );
};
