import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";
import { Marker } from "react-leaflet";
import ReactDOM from "react-dom";

import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { useMapOptimizedHiddenFlag, useCrosshairCursor } from "../../hooks";
import "./MapComments.style.css";
import { CommentDto, PostCommentRequestDto, SearchCommentsResponseDto } from "../../types/responses";
import { getCommentIcon } from "./MapComments.utils";
import { postCommentUrl, postRepliesUrl } from "../../api";
import { useMutation } from "react-query";
import ApiClient from "../../utils/apiClient";
import { logError } from "../../services/Logger";
import MapHelper from "../../utils/mapHelper";
import { AnimationData } from "../../stores/MapStore";
import { POPUP_COMMENTS_OPT } from "../../constants";
import { EnterComment } from "../Comments/EnterComment";
import { CommentPopup } from "../Comments/CommentPopup";
import { useChangeCommentStatus } from "../../hooks/useChangeCommentStatus";
import { useDeleteComment } from "../../hooks/useDeleteComment";

const renderPopup = (
  comment: CommentDto,
  postReply: (text: string) => void,
  closePopup: () => void,
  updatePopup: () => void,
  fullContent: boolean,
  setResolved: () => void,
  deleteComment: () => void
) => {
  const container = L.DomUtil.create("div");
  ReactDOM.render(
    <CommentPopup
      comment={comment}
      postReply={postReply}
      closePopup={closePopup}
      fullContent={fullContent}
      popupUpdate={updatePopup}
      setResolved={setResolved}
      deleteComment={deleteComment}
    />,
    container
  );
  return container;
};
interface MapCommentsProps {
  commentData?: Nullable<SearchCommentsResponseDto>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
  reloadComments: () => void;
  selectedMarkerId: Nullable<string>;
  resetCommentID: () => void;
}

const COMMENTS_PANE_NAME = "comments-pane";

export const MapComments: FC<MapCommentsProps> = memo(
  ({ commentData, hidden = false, map, zIndex = 400, reloadComments, selectedMarkerId, resetCommentID }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    useCrosshairCursor(map);

    const postMessageMutation = useMutation((value: PostCommentRequestDto) => ApiClient.post(postCommentUrl(), value));

    const { mutate: updateStatusComment, isLoading } = useChangeCommentStatus();
    const deleteComment = useDeleteComment();

    useCustomMapPane({
      map,
      name: COMMENTS_PANE_NAME,
      zIndex: zIndex,
    });

    const removeComment = useCallback(
      (commentId: string) => {
        deleteComment.mutate(commentId);
        map?.closePopup();
        resetCommentID();
      },
      [resetCommentID, map, deleteComment]
    );

    const readComment = useCallback(
      (comment: CommentDto) => {
        if (!comment || comment.read) {
          return;
        }
        updateStatusComment({ commentId: comment.id, status: "read" });
      },
      [updateStatusComment]
    );

    const closePopup = useCallback(() => {
      if (!map) {
        return;
      }

      map.closePopup();
    }, [map]);

    useEffect(() => {
      if (!selectedMarkerId || !map) {
        return;
      }

      const comment = commentData?.comments?.find((comment) => comment.id === selectedMarkerId);

      if (!comment) {
        return;
      }
      const bounds = MapHelper.getBoundsFromPoint([comment.lon, comment.lat]);

      if (!bounds) {
        return;
      }
      map.addOneTimeEventListener("moveend", () => {
        map.closePopup();
        openPopup(comment, POPUP_COMMENTS_OPT, true);
      });
      L.Util.requestAnimFrame(() => {
        //disable previous animation
        map.setView(map.getCenter(), map.getZoom(), { animate: false });
        map.flyToBounds(bounds, AnimationData);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMarkerId, map]);

    const postComment = useCallback(
      (lat: number, lng: number, text: string) => {
        postMessageMutation
          .mutateAsync({ lat: lat, lon: lng, text: text })
          .then(() => {
            map?.closePopup();
            reloadComments();
          })
          .catch((e) => logError(e));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [map, postMessageMutation]
    );

    const setResolved = useCallback(
      async (commentId: string, status: "resolve" | "unresolve") => {
        if (!commentId) {
          return;
        }
        updateStatusComment({ commentId, status });
      },
      [updateStatusComment]
    );

    const openPopup = useCallback(
      (comment: CommentDto, options: L.PopupOptions, fullContent: boolean) => {
        if (!map) {
          return;
        }
        closePopup();
        const popup = new L.Popup(options);
        popup.setLatLng({ lat: comment.lat, lng: comment.lon });
        popup.setContent(
          renderPopup(
            comment,
            (text: string) => {
              postReply(comment, text);
            },
            closePopup,
            () => popup.update(),
            fullContent,
            () => setResolved(comment.id, comment.resolved ? "unresolve" : "resolve"),
            () => removeComment(comment.id)
          )
        );

        map.openPopup(popup);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [map, closePopup]
    );

    const postReply = useCallback(
      (comment: CommentDto, text: string) => {
        ApiClient.post<CommentDto>(postRepliesUrl(comment.id), { text: text })
          .then((res) => {
            openPopup(res.data, POPUP_COMMENTS_OPT, true);
            reloadComments();
          })
          .catch((e) => logError(e));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reloadComments, openPopup]
    );

    const createNewComment = useCallback(
      (e: L.LeafletMouseEvent) => {
        if (!map) {
          return;
        }

        L.DomEvent.stopPropagation(e);

        const popup = L.popup({
          className: "comment-popup",
          closeButton: false,
          closeOnEscapeKey: true,
          autoPan: true,
          offset: [110, 10],
        });
        popup.setLatLng(e.latlng);
        const container = L.DomUtil.create("div");
        ReactDOM.render(
          <EnterComment
            placeholder="Write note"
            postComment={(value) => {
              postComment(e.latlng.lat, e.latlng.lng, value);
            }}
            closePopup={closePopup}
          />,
          container
        );

        popup.setContent(container);
        closePopup();
        popup.openOn(map);
      },
      [map, closePopup, postComment]
    );

    const clickOnExistingMarker = useCallback(
      (e: L.LeafletMouseEvent, comment: CommentDto) => {
        readComment(comment);
        if (!map) {
          return;
        }

        L.DomEvent.stopPropagation(e);
        const popup = L.popup(POPUP_COMMENTS_OPT);
        popup.setLatLng(e.latlng);
        popup.setContent(
          renderPopup(
            comment,
            (text: string) => postReply(comment, text),
            closePopup,
            () => popup.update(),
            false,
            () => setResolved(comment.id, comment.resolved ? "unresolve" : "resolve"),
            () => removeComment(comment.id)
          )
        );
        closePopup();
        popup.openOn(map);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [map, closePopup, postReply, setResolved, readComment]
    );

    useEffect(() => {
      if (!map) {
        return;
      }
      map.addEventListener("click", createNewComment);
      return () => {
        map.removeEventListener("click", createNewComment);
      };
    }, [map, createNewComment]);

    const renderedComments = useMemo(() => {
      if (!commentData?.comments?.length) {
        return <></>;
      }

      return commentData?.comments.map((item) => {
        return (
          <Marker
            icon={getCommentIcon(item.read)}
            position={[item.lat, item.lon]}
            key={uuidv4()}
            pane={COMMENTS_PANE_NAME}
            interactive={true}
            eventHandlers={{
              mouseover: (e) => {
                if (isLoading) return;
                clickOnExistingMarker(e, item);
              },
            }}
          />
        );
      });
    }, [commentData, clickOnExistingMarker, isLoading]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{renderedComments}</>;
  }
);
