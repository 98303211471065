import React, { FC } from "react";
import { PositionProps } from "styled-system";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { Button } from "../Button";
import { TriangleTooltip } from "../Tooltip/TriangleTooltip";
import { MeasureToolWrapper } from "./MeasureToolButton.styled";
import { ReactComponent as MeasureImg } from "./../../assets/images/measure_distance.svg";
import { ReactComponent as MeasureActiveImg } from "./../../assets/images/measure_distance_active.svg";
import { Portal } from "../Portal";
interface MeasureToolButtonProps extends PositionProps {
  turnedOn: boolean;
  toggle: () => void;
}

export const MeasureToolButton: FC<MeasureToolButtonProps> = ({ turnedOn, toggle, ...props }) => {
  return (
    <Portal selector="layer-menu-root">
      <TriangleTooltip under={false} label={"Measure Tool"} light={false} marginTop={-6}>
        <MeasureToolWrapper id="measure-tool" {...props}>
          <Button variant={turnedOn ? "light" : "primary"} onClick={toggle} padding={0} margin={0}>
            <StyledFlex justifyContent="center" alignItems="center">
              {turnedOn ? <MeasureImg /> : <MeasureActiveImg />}
            </StyledFlex>
          </Button>
        </MeasureToolWrapper>
      </TriangleTooltip>
    </Portal>
  );
};
