import { FC, memo, useMemo } from "react";
import L, { LeafletMouseEvent } from "leaflet";
import { Marker } from "react-leaflet";

import MarkerShadow from "./../../assets/images/marker_shadow.svg";
import { EMPTY_FN } from "../../constants";
import { useMapOptimizedHiddenFlag } from "../../hooks";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { DEFAULT_ZOOM_LEVEL } from "../../stores/MapStore";

interface MapMarkersProps {
  hidden?: boolean;
  icon?: L.Icon | L.DivIcon;
  markers: Nullable<Record<string, L.LatLngExpression>>;
  onClick: (e: L.LeafletMouseEvent, objectId: string) => void;
  onMarkerMouseOver: (e: L.LeafletMouseEvent, id: string) => void;
  onMarkerMouseOut: () => void;
}

const markerIcon = new L.Icon({ iconUrl: MarkerShadow });

export const MapMarkers: FC<MapMarkersProps> = memo(
  ({
    markers,
    hidden = false,
    onClick = EMPTY_FN,
    icon = markerIcon,
    onMarkerMouseOver = EMPTY_FN,
    onMarkerMouseOut = EMPTY_FN,
  }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    const renderedMarkers = useMemo(() => {
      if (!markers) {
        return <></>;
      }

      return Object.keys(markers).map((id) => (
        <Marker
          icon={icon}
          position={markers[id]}
          key={id}
          eventHandlers={{
            click: (e: LeafletMouseEvent) => onClick(e, id),
            mouseover: (e) => onMarkerMouseOver(e, id),
            mouseout: () => onMarkerMouseOut(),
          }}
        />
      ));
    }, [markers, onClick, icon, onMarkerMouseOver, onMarkerMouseOut]);

    if (optimizedHidden) {
      return <></>;
    }

    return <MapMarkerCluster disableClusteringAtZoom={DEFAULT_ZOOM_LEVEL}>{renderedMarkers}</MapMarkerCluster>;
  }
);
