import styled from "styled-components";

import { Data2 } from "../../assets/styles/fonts.styled";

export const RangeContainer = styled.div<{ border?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: ${(props) => props.theme.colors.froth};
  border-radius: 5px;
  margin-right: 28px;
  margin-top: 13px;
  background: rgba(255, 255, 255, 0.18);
  border: ${({ border }) => border || "none"};
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
`;

export const RangePercents = styled.div`
  ${Data2}
  color: ${(props) => props.theme.colors.atoll};
  flex: 0.2;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 1em;
`;

export const Range = styled.input<{ percent: number }>`
  -webkit-appearance: none;
  background: transparent;
  flex: 1;
  align-items: center;
  padding: 0 13px;
  height: 20px;
  &:focus {
    outline: none;
  }

  /* Chrome */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.easternBlue};
    cursor: pointer;
    margin-top: -8px;
    border: 4px solid white;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    &:hover {
      background: #0b747c;
    }
    &:active {
      background: #0d8591;
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 5px;
    background: ${(props) =>
      `linear-gradient(to right, var(--colors-eastern-blue) 0%, var(--colors-eastern-blue)  ${props.percent}%, var(--colors-ziggurat) ${props.percent}%, var(--colors-ziggurat) 100%)`};
  }

  /* Firefox */
  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.easternBlue};
    cursor: pointer;
    margin-top: -8px;
    border: 4px solid white;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    &:hover {
      background: #0b747c;
    }
    &:active {
      background: #0d8591;
    }
  }

  &::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: ${(props) =>
      `linear-gradient(to right, var(--colors-eastern-blue) 0%, var(--colors-eastern-blue)  ${props.percent}%, var(--colors-ziggurat) ${props.percent}%, var(--colors-ziggurat) 100%)`};
    border-radius: 5px;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #0480f6;
    cursor: pointer;
    border: 4px solid white;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    &:hover {
      background: #0b747c;
    }
    &:active {
      background: #0f508e;
    }
  }
`;
