import { memo, useCallback, useMemo } from "react";
import L from "leaflet";
import { useHistory } from "react-router-dom";

import { useMapStore, OVERLAY_LAYERS } from "../../../stores/MapStore";
import { getForecastRoute } from "../../../routes";
import { getSearchParams, resetToRegionLevelSearchParams, setRegionIdParam } from "../../../utils/navigationUtils";
import { MapCounties } from "../../../components/MapCounties";
import { MapTopLevelRegions } from "../../../components/MapRegionsOutline/MapTopLevelRegions";
import { useMapOptimizedHiddenFlag } from "../../../hooks";
import {
  MAX_ZOOM_LEVEL_FOR_REGION_LAYER,
  MIN_ZOOM_LEVEL_FOR_COUNTIES_LAYER,
  REGIONS_Z_INDEX,
} from "./MapView.constants";
import { useHierarchyStore } from "../../../stores";

export const ServiceAreas = memo(() => {
  const map = useMapStore((store) => store.map);
  const isFeederLevel = useHierarchyStore((store) => store.isFeederLevel);
  const overlayMapLayers = useMapStore((store) => store.overlayMapLayers);
  const hoveredObjectId = useMapStore((store) => store.hoveredObjectId);
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );
  const selectedRegion = useHierarchyStore((store) => store.currentRegion);
  const zoomLevel = useMapStore((store) => store.currentZoomLevel);
  const regions = useHierarchyStore((store) => store.regions);
  const currentRegion = useHierarchyStore((store) => store.currentRegion);
  const rootRegionSelected = useHierarchyStore((store) => store.rootRegion);

  const history = useHistory();

  const [areTopLevelRegionsHidden] = useMapOptimizedHiddenFlag(!isFeederLevel);

  const goToRegion = useCallback(
    (e, id) => {
      if (!map || selectedRegion === id) {
        return;
      }
      L.DomEvent.stopPropagation(e);
      setLatestObjectIdSelectedForMapAnimation(id);
      const queryParams = getSearchParams();
      resetToRegionLevelSearchParams(queryParams);
      setRegionIdParam({ params: queryParams, id: id });
      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
    },
    [map, selectedRegion, history, setLatestObjectIdSelectedForMapAnimation]
  );

  const topLevelRegions = useMemo(() => {
    if (!rootRegionSelected || currentRegion !== rootRegionSelected || !regions?.[rootRegionSelected]?.regions) {
      return [];
    }

    return regions?.[rootRegionSelected]?.regions;
  }, [currentRegion, rootRegionSelected, regions]);

  return (
    <>
      <MapCounties
        enabled={currentRegion !== rootRegionSelected && zoomLevel <= MIN_ZOOM_LEVEL_FOR_COUNTIES_LAYER}
        map={map}
        hidden={!overlayMapLayers[OVERLAY_LAYERS.REGIONS]}
      />
      <MapTopLevelRegions
        hidden={!overlayMapLayers[OVERLAY_LAYERS.REGIONS] || zoomLevel > MAX_ZOOM_LEVEL_FOR_REGION_LAYER}
        hoveredObjectId={hoveredObjectId}
        interactive={areTopLevelRegionsHidden}
        map={map}
        onClick={goToRegion}
        regions={topLevelRegions}
        zIndex={REGIONS_Z_INDEX}
      />
    </>
  );
});
