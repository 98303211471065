import { Feature, Point } from "geojson";
import { LatLngExpression, LatLngBounds } from "leaflet";

import { DEFAULT_ZOOM_LEVEL } from "../../stores/MapStore";
import {
  HierarchyResponse,
  ImagerySource,
  ImagerySourcesResponse,
  RegionsResponse,
  RegionsResponseProperties,
  STRUCTURE_MODE,
  ThreatHierarchyResponse,
} from "../../types/responses";
import { EncroachmentResponse, EncroachmentResponseProperties } from "../../types/responses/EncroachmentsResponse";
import { FeedersResponse, MappedFeedersGeoJSON } from "../../types/responses/FeedersResponse";
import MapHelper from "../../utils/mapHelper";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { workTypeMap } from "./MapScreen.constants";

import aerial_spray from "../../assets/images/work_types/aerial_spray.svg";
import creek from "../../assets/images/work_types/creek.svg";
import excavator_pushout from "../../assets/images/work_types/excavator_pushout.svg";
import excavator from "../../assets/images/work_types/excavator.svg";
import fence from "../../assets/images/work_types/fence.svg";
import ground_spray from "../../assets/images/work_types/ground_spray.svg";
import mow_pushout from "../../assets/images/work_types/mow_pushout.svg";
import manual_trim from "../../assets/images/work_types/manual_trim.svg";
import small_mower from "../../assets/images/work_types/small_mower.svg";
import tree_rem_large_rural from "../../assets/images/work_types/tree_rem_large_rural.svg";
import tree_rem_large_urban from "../../assets/images/work_types/tree_rem_large_urban.svg";
import tree_rem_small_rural from "../../assets/images/work_types/tree_rem_small_rural.svg";
import tree_rem_small_urban from "../../assets/images/work_types/tree_rem_small_urban.svg";
import tree_rem_ash from "../../assets/images/work_types/tree_remove_ash.svg";
import tree_remove_hazard from "../../assets/images/work_types/tree_remove_hazard.svg";
import vine_rem from "../../assets/images/work_types/vine_rem.svg";
import work_type_placeholder from "../../assets/images/work_placeholder_icon.svg";

import aerial_spray_marker from "../../assets/images/work_types/areal_spray_marker.svg";
import creek_marker from "../../assets/images/work_types/creek_marker.svg";
import excavator_pushout_marker from "../../assets/images/work_types/excavator_pushout_marker.svg";
import excavator_marker from "../../assets/images/work_types/excavator_marker.svg";
import fence_marker from "../../assets/images/work_types/fence_marker.svg";
import ground_spray_marker from "../../assets/images/work_types/ground_spray_marker.svg";
import mow_pushout_marker from "../../assets/images/work_types/mow_pushout_marker.svg";
import manual_trim_marker from "../../assets/images/work_types/manual_trim_marker.svg";
import small_mower_marker from "../../assets/images/work_types/small_mower_marker.svg";
import tree_rem_large_rural_marker from "../../assets/images/work_types/tree_rem_large_rural_marker.svg";
import tree_rem_large_urban_marker from "../../assets/images/work_types/tree_rem_large_urban_marker.svg";
import tree_rem_small_rural_marker from "../../assets/images/work_types/tree_rem_small_rural_marker.svg";
import tree_rem_small_urban_marker from "../../assets/images/work_types/tree_rem_small_urban_marker.svg";
import tree_rem_ash_marker from "../../assets/images/work_types/tree_rem_ash_marker.svg";
import tree_remove_hazard_marker from "../../assets/images/work_types/tree_rem_hazard_marker.svg";
import vine_rem_marker from "../../assets/images/work_types/vine_marker.svg";
import work_type_placeholder_marker from "../../assets/images/work_types/manual_added_marker.svg";
import {
  MIN_ZOOM_LEVEL_FOR_DETAILED_STRUCTURES,
  MIN_ZOOM_LEVEL_FOR_DOTS_STRUCTURES,
  MIN_ZOOM_LEVEL_FOR_STRUCTURES,
} from "./MapView/MapView.constants";

export type MappedFeedersResponse = ReturnType<typeof mapFeedersResponse>;

export type LabelPoint = {
  position: Feature<Point>;
  angle?: number | null;
};

export const mapFeedersResponse = (feeders: FeedersResponse) => {
  if (isGeoJSONEmpty(feeders)) {
    return;
  }
  const center = MapHelper.centroid(false, feeders);
  const bounds = MapHelper.normalizeBounds(MapHelper.bBox(false, feeders));

  const map: MappedFeedersGeoJSON = {};
  feeders.features.forEach((feeder) => {
    const feederCenter = MapHelper.centroid(false, feeder);
    const feederBox = MapHelper.bBox(true, [feeder]);

    map[feeder.properties.id] = {
      center: [feederCenter.geometry.coordinates[1], feederCenter.geometry.coordinates[0]],
      properties: feeder.properties,
      feederBox: MapHelper.normalizeBounds(feederBox) as L.LatLngBoundsExpression,
    };
  });

  return {
    center: [center.geometry.coordinates[1], center.geometry.coordinates[0]] as L.LatLngExpression,
    feedersData: map,
    feedersGeoJson: feeders,
    bounds: bounds,
  };
};

export type EncroachmentsBySpan = Record<
  string,
  {
    encroachments: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>[];
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }
>;

export type EncroachmentPositions = Record<
  string,
  {
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
  }
>;

export type RegionPositions = Record<
  string,
  {
    center: Nullable<L.LatLngExpression>;
    bounds: Nullable<L.LatLngBoundsExpression>;
    parentId: string;
    feederLevel?: boolean;
    id: string;
    name: string;
  }
>;

export type MappedEncroachmentResponse = ReturnType<typeof mapEncroachmentResponse>;

export const createEncroachmentGeoJson = (
  item: EncroachmentResponse
): GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties> => {
  return { properties: item.properties, geometry: item.geometry, type: "Feature" };
};

export const mapEncroachmentResponse = (encroachments: EncroachmentResponse[]) => {
  if (!encroachments) {
    return null;
  }

  const encroachmentsBySpan: EncroachmentsBySpan = {};
  const encroachmentPositions: EncroachmentPositions = {};
  const encroachmentsGeoJSON: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>[] = [];
  for (const encroachment of encroachments) {
    const encroachmentGeoJSON = createEncroachmentGeoJson(encroachment);
    encroachmentsGeoJSON.push(encroachmentGeoJSON);
    const spanIds = encroachment.properties.spanIds;
    if (!spanIds || !spanIds.length) {
      continue;
    }
    spanIds.forEach((spanId) => {
      const spanEncroachments = [...(encroachmentsBySpan?.[spanId]?.encroachments || []), { ...encroachmentGeoJSON }];
      const center = MapHelper.centroid(true, spanEncroachments);
      const bounds = MapHelper.normalizeBounds(MapHelper.bBox(true, spanEncroachments));
      encroachmentsBySpan[spanId] = {
        bounds,
        center: [center.geometry.coordinates[1], center.geometry.coordinates[0]],
        encroachments: spanEncroachments,
      };
    });

    const encroachmentCenter = MapHelper.centerOfMass(true, [encroachment]);
    encroachmentPositions[encroachment.properties.id] = {
      center: [encroachmentCenter.geometry.coordinates[1], encroachmentCenter.geometry.coordinates[0]],
      bounds: MapHelper.normalizeBounds(MapHelper.bBox(true, [encroachment])),
    };
  }

  return { encroachmentsBySpan, encroachmentPositions, encroachmentsGeoJSON };
};

// TODO map response properties to include number of threats
export const mapThreatHierarchyResponse = (response: ThreatHierarchyResponse): ThreatHierarchyResponse => {
  Object.keys(response.regionsByParent).forEach((regionId) => {
    response.regionsByParent[regionId] = response.regionsByParent[regionId].map((region) => {
      region.feature = createGeoJson(region);
      return region;
    });
  });
  return response;
};

export const calculatePositionsAndBoundsForRegions = (response: ThreatHierarchyResponse): RegionPositions => {
  const regionPositions: RegionPositions = {};
  Object.keys(response.regionsByParent).forEach((regionId) => {
    const regionsForRegionId = getListOfFeature(response.regionsByParent[regionId], false);
    if (regionsForRegionId?.length) {
      response.regionsByParent[regionId].forEach((region) => {
        const feeders = getListOfFeature(response.regionsByParent[region.feature.properties.id], true) || [];
        let centerPoint = MapHelper.findPolylabel<GeoJSON.Feature<GeoJSON.Geometry, RegionsResponseProperties>>(
          region.feature
        );
        if (!centerPoint.length) {
          const centerOfMass = MapHelper.centerOfMass(true, [region.feature]);
          centerPoint = [centerOfMass?.geometry?.coordinates[1], centerOfMass?.geometry?.coordinates[0]];
        }
        regionPositions[region.feature.properties.id] = {
          bounds: MapHelper.normalizeBounds(MapHelper.bBox(true, [region.feature])),
          center: centerPoint as LatLngExpression,
          parentId: region.feature.properties.parentId,
          feederLevel: feeders && feeders?.length > 0,
          id: region.feature.properties.id,
          name: region.feature.properties.name,
        };
      });
    } else {
      if (regionPositions[regionId]) {
        regionPositions[regionId].feederLevel = true;
      }
    }
  });
  return regionPositions;
};

export const getListOfFeature = (regions: RegionsResponse[], feeders?: boolean) => {
  if (!regions) {
    return;
  }
  return regions
    .filter((region) => {
      return feeders ? region.feature.properties.feederId !== null : region.feature.properties.feederId === null;
    })
    .map((region) => {
      return region.feature;
    });
};

export const createGeoJson = (item: RegionsResponse): GeoJSON.Feature<GeoJSON.Geometry, RegionsResponseProperties> => {
  return { properties: item.feature.properties, geometry: item.feature.geometry, type: "Feature" };
};

export const mapImagerySourcesData = (data: ImagerySourcesResponse): Record<string, ImagerySource> => {
  if (!data || !data.sources) {
    return {};
  }

  return data.sources.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as Record<string, ImagerySource>);
};

export const computeExtentFetchData = ({
  currentMapBounds,
  currentZoomLevel,
}: {
  currentMapBounds: Nullable<LatLngBounds>;
  currentZoomLevel: number;
}) => ({
  zoomLevel: Math.round(currentZoomLevel) || DEFAULT_ZOOM_LEVEL,
  minLatitude: Number(
    Math.min(currentMapBounds?.getNorthEast().lat || 0, currentMapBounds?.getSouthWest().lat || 0).toFixed(6)
  ),
  maxLatitude: Number(
    Math.max(currentMapBounds?.getNorthEast().lat || 0, currentMapBounds?.getSouthWest().lat || 0).toFixed(6)
  ),
  minLongitude: Number(
    Math.min(currentMapBounds?.getNorthEast().lng || 0, currentMapBounds?.getSouthWest().lng || 0).toFixed(6)
  ),
  maxLongitude: Number(
    Math.max(currentMapBounds?.getNorthEast().lng || 0, currentMapBounds?.getSouthWest().lng || 0).toFixed(6)
  ),
});

export const getWorkTypeImage = (workTypeCode: string) => {
  return getImageWorkType(workTypeCode, true);
};

export const getWorkTypeColor = (workTypeCode: string) => {
  if (workTypeCode in workTypeMap) {
    return workTypeMap[workTypeCode].color;
  }

  return workTypeMap["placeholder"].color;
};

export const getWorkTypePriority = (workTypeCode: string) => {
  if (workTypeCode in workTypeMap) {
    return workTypeMap[workTypeCode].priority;
  }

  return workTypeMap["placeholder"].priority;
};

export const getWorkTypeMarker = (workTypeCode: string) => {
  return getImageWorkType(workTypeCode, true);
};

const getImageWorkType = (workTypeCode: string, isMarker: boolean) => {
  switch (workTypeCode) {
    case "excavator-existing":
      return isMarker ? excavator_marker : excavator;
    case "excavator-pushout":
      return isMarker ? excavator_pushout_marker : excavator_pushout;
    case "manual-trim":
      return isMarker ? manual_trim_marker : manual_trim;
    case "mow-existing":
      return isMarker ? small_mower_marker : small_mower;
    case "spray-high":
      return isMarker ? aerial_spray_marker : aerial_spray;
    case "spray-low":
      return isMarker ? ground_spray_marker : ground_spray;
    case "tree-removal-ash":
      return isMarker ? tree_rem_ash_marker : tree_rem_ash;
    case "tree-removal-hazard":
      return isMarker ? tree_remove_hazard_marker : tree_remove_hazard;
    case "tree-removal-large-rural":
      return isMarker ? tree_rem_large_rural_marker : tree_rem_large_rural;
    case "tree-removal-large-urban":
      return isMarker ? tree_rem_large_urban_marker : tree_rem_large_urban;
    case "tree-removal-small-rural":
      return isMarker ? tree_rem_small_rural_marker : tree_rem_small_rural;
    case "tree-removal-small-urban":
      return isMarker ? tree_rem_small_urban_marker : tree_rem_small_urban;
    case "vine-removal":
      return isMarker ? vine_rem_marker : vine_rem;
    case creek:
      return isMarker ? creek_marker : creek;
    case fence:
      return isMarker ? fence_marker : fence;
    case "mow-pushout":
      return isMarker ? mow_pushout_marker : mow_pushout;
    default:
      return isMarker ? work_type_placeholder_marker : work_type_placeholder;
  }
};

export const getStructureMode = (currentZoomLevel: number) => {
  if (currentZoomLevel > MIN_ZOOM_LEVEL_FOR_STRUCTURES && currentZoomLevel < MIN_ZOOM_LEVEL_FOR_DOTS_STRUCTURES) {
    return STRUCTURE_MODE.OVERVIEW;
  }
  if (
    currentZoomLevel > MIN_ZOOM_LEVEL_FOR_DOTS_STRUCTURES &&
    currentZoomLevel < MIN_ZOOM_LEVEL_FOR_DETAILED_STRUCTURES
  ) {
    return STRUCTURE_MODE.OVERVIEW_WITH_DETAILS;
  }

  return STRUCTURE_MODE.DETAILED;
};

export const calculateBounds = (regions: HierarchyResponse, feeders?: FeedersResponse) => {
  if (!regions?.regions?.length) {
    return null;
  }
  if (regions?.regions[0]?.properties?.feederId) {
    const filteredFeeders = feeders?.features
      ?.filter((feeder) => regions.regions.find((region) => region.properties.feederId === feeder.properties.id))
      .map((item) => item.geometry);
    return MapHelper.normalizeBounds(
      MapHelper.bBox(false, {
        type: "GeometryCollection",
        geometries: filteredFeeders,
      })
    ) as L.LatLngBoundsExpression;
  }

  return MapHelper.normalizeBounds(
    MapHelper.bBox(false, {
      type: "GeometryCollection",
      geometries: regions?.regions?.map((item) => item.geometry),
    })
  ) as L.LatLngBoundsExpression;
};
