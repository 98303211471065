import { CommentDto } from ".";

export type AddAttachmentsRequestDto = {
  filenames: string[];
};

export type AddAttachmentsResponseDto = {
  attachments: AddAttachmentResponseDto[];
};

export type AddAttachmentResponseDto = {
  id: string;
  fileName: string;
  uploadUrl: string;
};

export interface AttachmentDto {
  id: string;
  fileName: string;
  fileUrl: string;
  uploadedBy: string;
  createdDateTime: string;
  description: string;
  comments: CommentDto[] | [];
}

export enum AttachmentSize {
  Preview = "preview",
  Original = "original",
  Thumbnail = "thumbnail",
}
