import React, { FC, useCallback, useRef, useState } from "react";

import {
  PrioritySectionNameWrapper,
  PrioritySelectWrapper,
  NameWrapper,
  ArrowSelect,
  PriorityMenu,
  PriorityMenuItem,
  PriorityMenuName,
  CircleWrapper,
} from "./PrioritySelect.styled";
import { useClickOutside } from "../../hooks";
import { FeederCircleStatus, ShowAllImage, StatusViolationTriangle } from "../Taxonomy/StatusCircle";
import ViolationImg from "./../../assets/images/violation.svg";
import { getRiskPriorityColor } from "../../pages/MapScreen/MapView/MapView.utils";
import { RISK_PRIORITY } from "../../types/responses";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import ShowAll from "../../assets/images/show_all_button.svg";

interface PrioritySelectProps {
  selected: Nullable<RISK_PRIORITY>;
  selectRiskPriority: (riskPriority: Nullable<RISK_PRIORITY>) => void;
}

export const PrioritySelect: FC<PrioritySelectProps> = ({ selected, selectRiskPriority }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => opened && setOpened(false));

  const selectAndClose = useCallback(
    (riskPriority: Nullable<RISK_PRIORITY>) => {
      if (riskPriority === selected) {
        return;
      }
      selectRiskPriority(riskPriority);
      setOpened(false);
    },
    [selected, selectRiskPriority]
  );
  return (
    <PrioritySectionNameWrapper>
      <PrioritySelectWrapper onClick={() => setOpened(!opened)}>
        <NameWrapper isActive={opened}>
          <div>Priority: </div>
          {selected && (
            <CircleWrapper>
              {selected !== RISK_PRIORITY.VIOLATION ? (
                <FeederCircleStatus backgroundColor={getRiskPriorityColor(selected, true)} />
              ) : (
                <StatusViolationTriangle src={ViolationImg} />
              )}
            </CircleWrapper>
          )}
          <span>{selected ? capitalizeFirstLetter(selected) : "All"}</span>
        </NameWrapper>
        <ArrowSelect isActive={opened} />
      </PrioritySelectWrapper>
      <PrioritySelectWrapper>
        <PriorityMenu isActive={opened} ref={wrapperRef}>
          <PriorityMenuItem
            selected={selected === null}
            onClick={() => {
              selectAndClose(null);
            }}
          >
            <CircleWrapper>
              <ShowAllImage src={ShowAll} />
            </CircleWrapper>
            <PriorityMenuName>Show All </PriorityMenuName>
          </PriorityMenuItem>
          <PriorityMenuItem
            selected={selected === RISK_PRIORITY.VIOLATION}
            onClick={() => {
              selectAndClose(RISK_PRIORITY.VIOLATION);
            }}
          >
            <CircleWrapper>
              <StatusViolationTriangle src={ViolationImg} />
            </CircleWrapper>
            <PriorityMenuName>Violation </PriorityMenuName> <span>1 day</span>
          </PriorityMenuItem>
          <PriorityMenuItem
            selected={selected === RISK_PRIORITY.CRITICAL}
            onClick={() => {
              selectAndClose(RISK_PRIORITY.CRITICAL);
            }}
          >
            <CircleWrapper>
              <FeederCircleStatus backgroundColor={getRiskPriorityColor(RISK_PRIORITY.CRITICAL, true)} />
            </CircleWrapper>
            <PriorityMenuName>Critical </PriorityMenuName> <span>3 months</span>
          </PriorityMenuItem>
          <PriorityMenuItem
            selected={selected === RISK_PRIORITY.HIGH}
            onClick={() => {
              selectAndClose(RISK_PRIORITY.HIGH);
            }}
          >
            <CircleWrapper>
              <FeederCircleStatus backgroundColor={getRiskPriorityColor(RISK_PRIORITY.HIGH, true)} />
            </CircleWrapper>
            <PriorityMenuName>High </PriorityMenuName> <span>6 months</span>
          </PriorityMenuItem>
          <PriorityMenuItem
            selected={selected === RISK_PRIORITY.MEDIUM}
            onClick={() => {
              selectAndClose(RISK_PRIORITY.MEDIUM);
            }}
          >
            <CircleWrapper>
              <FeederCircleStatus backgroundColor={getRiskPriorityColor(RISK_PRIORITY.MEDIUM, true)} />
            </CircleWrapper>
            <PriorityMenuName>Medium </PriorityMenuName> <span>1 year</span>
          </PriorityMenuItem>
          <PriorityMenuItem
            selected={selected === RISK_PRIORITY.LOW}
            onClick={() => {
              selectAndClose(RISK_PRIORITY.LOW);
            }}
          >
            <CircleWrapper>
              <FeederCircleStatus backgroundColor={getRiskPriorityColor(RISK_PRIORITY.LOW, true)} />{" "}
            </CircleWrapper>
            <PriorityMenuName>Low </PriorityMenuName> <span>2 years</span>
          </PriorityMenuItem>
        </PriorityMenu>
      </PrioritySelectWrapper>
    </PrioritySectionNameWrapper>
  );
};
