import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SectionItem } from "../../../../components/RightDrawer/DrawerSectionItems/SectionItem";
import { DrawerSection } from "../../../../components/RightDrawer/DrawerSectionItems/DrawerSection";
import { ContentType } from "../../../../stores/DrawerStore/DrawerStore.types";
import { SectionLinks } from "../../../../components/RightDrawer/DrawerSectionItems/SectionLinks";
import { SectionData, SectionLinks as SectionLinksType } from "../RightDrawerMenu.utils";
import { LABELS } from "../../../../constants";
import { WorksheetArea } from "../../../../components/WorksheetArea";
import { getStructureWorksheetApiUrl, getWorkTypesApiUrl } from "../../../../api";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { useWorkOrdersForObject } from "../../../../feature/work-order/hooks/useWorkOrdersForObject";
import { WorkOrderTile } from "../../../../feature/work-order/components/WorkOrderTile";
import { toLocalDate } from "../../../../utils/dateUtils";
import { CreateWorkOrderBtn } from "../../../../components/RightDrawer/RightDrawer.styled";
import { workOrderRoute } from "../../../../routes";
import { IWorkOrderState } from "../../../../feature/work-order/types";
import { WorksheetResponse, WorkTypeResponse } from "../../../../types/responses";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";

const DATE_FORMAT = "MMMM d, yyyy";

// const worksheetResponse = {} as WorksheetResponse

export const StructureInfoSection: FC<{
  data: SectionData;
  openHyperlink?: (type: ContentType, id: string) => void;
  enableReloadForPredominatedWorkTypes: () => void;
}> = ({ data, openHyperlink, enableReloadForPredominatedWorkTypes }) => {
  const history = useHistory();
  const { data: workOrders = [] } = useWorkOrdersForObject(data?.id! as string);

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());
  const { data: worksheetResponse } = useCustomQuery<WorksheetResponse>(
    getStructureWorksheetApiUrl(data?.id as string)
  );

  const worktypes = useMemo(() => {
    if (workTypesResponse?.length === 0) return [];
    if (worksheetResponse?.items?.length === 0) return [];

    return (
      worksheetResponse?.items
        ?.filter((wt) => !wt.inherited)
        ?.map((item) => {
          const wtResponse = workTypesResponse
            ?.filter((wtItem) => wtItem.scope === "STRUCTURE")
            ?.find((wtItem) => wtItem.id === item.workTypeId)!;
          return {
            ...item,
            category: wtResponse.name,
            code: wtResponse.code,
            unit: wtResponse.unit,
            totalQuantity: item?.quantity,
            group: item.selected ? "Suggested" : ("All" as "Suggested" | "All"),
            added: false,
          };
        }) || []
    );
  }, [workTypesResponse, worksheetResponse]);

  const struktureWoData: IWorkOrderState = {
    spanId: data?.id as string,
    riskPriority: data?.riskPriority as string,
    riskScore: data?.riskScore as string,
    treeSpecies: data?.treeSpecies as string[],
    spanLength: data?.spanLength as string,
    rowArea: data?.rowArea as string,
    worktypes: worktypes,
    workTypeScope: "STRUCTURE",
  };

  const assignToNewWorkorder = (workTypeId: string) => {
    history.push(workOrderRoute, {
      ...struktureWoData,
      subtask: {
        id: uuidv4(),
        selectedCrewType: null,
        worktypes: worktypes.map((wt) => ({ ...wt, added: wt.workTypeId === workTypeId })),
      },
    });
  };

  return (
    <>
      <DrawerSection name="STRUCTURE INFO">
        <SectionItem name="Structure Status">{data?.structureStatus}</SectionItem>
        <SectionItem name="Structure Type">{data?.structureType}</SectionItem>
        <SectionItem name="Structure Label">{data?.structureLabel}</SectionItem>
        <SectionItem name={LABELS.circuitName}>
          <SectionLinks data={data?.circuits as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name="Spans">
          <SectionLinks data={data?.spans as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name={LABELS.voltage}>{data?.voltage}</SectionItem>
        <SectionItem name={LABELS.elevation}>{data?.elevation}</SectionItem>
        <SectionItem name={LABELS.demographic}>{data?.demographic}</SectionItem>
        <SectionItem name={LABELS.coordinates}>{data?.coordinates}</SectionItem>
      </DrawerSection>
      <DrawerSection name="Work Orders">
        {workOrders?.length > 0 && (
          <StyledFlex flexDirection="column" mb="10px" gap="10px">
            {workOrders.map((wOrder) => (
              <WorkOrderTile key={wOrder.id} name={`WO ${wOrder.id}`} date={toLocalDate(wOrder.dueDate, DATE_FORMAT)} />
            ))}
          </StyledFlex>
        )}
        <CreateWorkOrderBtn to={{ pathname: workOrderRoute, state: struktureWoData }}>
          Create Work Order
        </CreateWorkOrderBtn>
      </DrawerSection>
      <DrawerSection name="Worksheet">
        <WorksheetArea
          scope="STRUCTURE"
          onlyIds={true}
          readonly={false}
          worksheetUrl={getStructureWorksheetApiUrl(data?.id as string)}
          itemId={!!data?.id ? (data.id as string) : undefined}
          enableReloadForPredominatedWorkTypes={enableReloadForPredominatedWorkTypes}
          assignToNewWorkorder={assignToNewWorkorder}
        />
      </DrawerSection>
    </>
  );
};
