import { point, Position } from "@turf/turf";
import L from "leaflet";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Circle, FeatureGroup } from "react-leaflet";
import { Marker } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

import { TREE_MARKER_INDEX } from "../../constants";

import { useCustomMapPane, useMapOptimizedHiddenFlag, useMapTooltip } from "../../hooks";
import { useCreateTreeMarker } from "../../hooks/useCreateTreeMarker";
import { useDeleteTreeMarker } from "../../hooks/useDeleteTreeMarker";
import { useEditTreeMarker } from "../../hooks/useEditTreeMarker";
import { getCategoryImage, POPUP_OPT } from "../../pages/MapScreen/MapView/TreeMarkerView.utils";
import { TreeMarker, TreeMarkerCategory, TreeMarkerUpdate } from "../../types/responses";
import MapHelper from "../../utils/mapHelper";
import { MarkerPopup } from "../AlertPopup/MarkerPopup";
import TreeMarkerSVG from "./../../assets/images/trees/tree_marker.svg";

const TREES_PANE_NAME = "map-trees-pane";

const OFFSET = 10;

const marker = new L.Icon({
  iconUrl: TreeMarkerSVG,
  iconAnchor: [12, 12],
});

const CIRCLE_OPTIONS = {
  fillColor: "rgb(140, 38, 42)",
  fill: true,
  stroke: true,
  fillOpacity: 0.44,
  color: "#FF0000",
};

type CircleLayer = {
  _latlng: L.LatLng;
  _mRadius: number;
};

type MapTreesProps = {
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
  treeMarkers?: Nullable<TreeMarker[]>;
  editable?: boolean;
  categories: Nullable<TreeMarkerCategory[]>;
};
export const MapTreeMarkers: FC<MapTreesProps> = ({
  hidden = false,
  map,
  zIndex = TREE_MARKER_INDEX,
  treeMarkers,
  editable = false,
  categories,
}) => {
  useCustomMapPane({ map, name: TREES_PANE_NAME, zIndex });
  const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
  const [layer, setLayer] = useState<any>(null);
  const [drawnCircle, setDrawnCircle] = useState<Nullable<CircleLayer>>(null);
  const [displayMarker, setDisplayMarker] = useState<Nullable<CircleLayer>>(null);
  const createTreeMarker = useCreateTreeMarker();
  const deleteTreeMarker = useDeleteTreeMarker();
  const editTreeMarker = useEditTreeMarker();
  const itemsRef = useRef([]);
  const [editableId, setEditableId] = useState("");
  const { onMouseMove, onMouseOut } = useMapTooltip({ layer: map });

  useEffect(() => {
    if (!map) {
      return;
    }

    //@ts-ignore
    const circle = new L.Draw.Circle(map, { repeatMode: true });
    circle?.enable();
    setLayer(circle);
  }, [map]);

  useEffect(() => {
    if (!treeMarkers?.length) {
      return;
    }
    itemsRef.current = itemsRef.current.slice(0, treeMarkers.length);
  }, [treeMarkers]);

  const editMarker = useCallback(
    (id: string, value: TreeMarkerUpdate) => {
      editTreeMarker.mutateAsync({ markerID: id, value });
      map!.closePopup();
    },
    [map, editTreeMarker]
  );

  const onBlur = useCallback(() => {
    const resizeMarkers = document.getElementsByClassName(`leaflet-edit-resize`);
    const editMarkers = document.getElementsByClassName(`leaflet-edit-move`);
    if (resizeMarkers?.length) {
      for (let i = 0; i < resizeMarkers.length; i++) {
        resizeMarkers[i].addEventListener("mouseover", () => {
          layer?.disable();
        });
      }
    }
    if (editMarkers?.length) {
      for (let i = 0; i < editMarkers.length; i++) {
        editMarkers[i].addEventListener("mouseover", () => {
          layer?.disable();
        });
      }
    }
  }, [layer]);

  const clickOnExistingMarker = useCallback(
    (e: L.LeafletMouseEvent, item?: TreeMarker) => {
      L.DomEvent.stopPropagation(e);

      if (!map) {
        return;
      }

      const latLng = !drawnCircle ? [e.latlng.lng, e.latlng.lat] : [drawnCircle?._latlng.lng, drawnCircle?._latlng.lat];
      const radius = drawnCircle?._mRadius ?? 0;

      //calculate where to show a popup depending on radius
      const destinationMarker = MapHelper.getDestinationPoint(
        (drawnCircle?._mRadius ?? item?.radius ?? OFFSET) + OFFSET,
        (item ? item.geometry.coordinates : latLng) as Position
      );
      !item && setDisplayMarker(drawnCircle ?? { _latlng: e.latlng, _mRadius: 0 });

      const createMarker = (categoryId: string) => {
        createTreeMarker.mutate({
          categoryId: categoryId,
          radiusMeters: radius,
          geometry: point(latLng)?.geometry,
        });

        map.closePopup();
      };

      const deleteMarker = (id: string) => {
        deleteTreeMarker.mutate(id);
        map!.closePopup();
      };
      const popup = L.popup(POPUP_OPT);
      const container = L.DomUtil.create("div");
      ReactDOM.render(
        <MarkerPopup
          categories={categories ?? []}
          create={createMarker}
          deleteMarker={deleteMarker}
          edit={editMarker}
          getCategoryImage={getCategoryImage}
          showSubcategoryImage={false}
          item={item}
          updatePopup={() => popup.update()}
          closePopup={() => map!.closePopup()}
          title="Trees"
          editTitle="Tree"
        />,
        container
      );

      popup.setLatLng(
        destinationMarker?.geometry?.coordinates
          ? ([
              destinationMarker.geometry.coordinates[1],
              destinationMarker.geometry.coordinates[0],
            ] as L.LatLngExpression)
          : ([latLng[1], latLng[0]] as L.LatLngExpression)
      );
      container.addEventListener("mouseover", () => layer?.disable());
      container.addEventListener("mouseout", () => layer?.enable());
      popup.addEventListener("remove", () => {
        setEditableId("");
      });

      popup.setContent(container);
      popup.openOn(map!);
      setDrawnCircle(null);
    },
    [map, categories, deleteTreeMarker, createTreeMarker, editMarker, drawnCircle, layer]
  );

  const onCreated = useCallback((e) => {
    setDrawnCircle(e.layer);
    clickOnExistingMarker(e);
  }, []);

  const onClick = useCallback(
    (e) => {
      clickOnExistingMarker(e);
      layer?.disable();
      setTimeout(() => {
        layer?.enable();
      }, 200);
    },
    [clickOnExistingMarker]
  );

  useEffect(() => {
    if (!map) {
      return;
    }
    map.on(L.Draw.Event.CREATED, onCreated);
    map.on("click", onClick);
    return () => {
      map.off(L.Draw.Event.CREATED, onCreated);
      map.off("click", onClick);
    };
  }, [map, onCreated, onClick]);

  useEffect(() => {
    return () => {
      map?.closePopup();
    };
  }, [map]);

  useEffect(() => {
    if (!layer) {
      return;
    }
    return () => {
      layer.disable();
    };
  }, [layer]);

  const onPopupClose = useCallback(() => {
    setDisplayMarker(null);
    layer?.enable();
  }, [layer]);

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        layer?.disable();
        setTimeout(() => {
          layer?.enable();
        }, 500);
      }
    };
    document.addEventListener("keydown", onEscape, false);
    return () => {
      document.removeEventListener("keydown", onEscape, false);
    };
  }, [layer]);

  useEffect(() => {
    map?.on("popupclose", onPopupClose);
    return () => {
      map?.off("popupclose", onPopupClose);
    };
  }, [map, onPopupClose]);

  const renderedObjects = useMemo(() => {
    if (!treeMarkers?.length) {
      return <></>;
    }

    return treeMarkers.map((treeMarker, i) => {
      const mapMaker = (
        <Marker
          pane={TREES_PANE_NAME}
          eventHandlers={{
            click: (e) => clickOnExistingMarker(e, treeMarker),
            dragstart: () => {
              layer?.disable();
              map?.closePopup();
            },
            dragend: (e) => {
              layer?.enable();
              editMarker(treeMarker.id, {
                categoryId: treeMarker.categoryId,
                geometry: point([e.target._latlng.lng, e.target._latlng.lat])?.geometry,
                radiusMeters: e.target._mRadius,
              });
            },

            mouseover: () => layer?.disable(),
            mouseout: () => layer?.enable(),
          }}
          draggable={true}
          position={[treeMarker.geometry.coordinates[1], treeMarker.geometry.coordinates[0]]}
          icon={marker}
        />
      );
      if (!treeMarker.radius) {
        return mapMaker;
      }

      return (
        <FeatureGroup
          key={treeMarker.id}
          pane={TREES_PANE_NAME}
          eventHandlers={{
            mouseover: () => layer?.disable(),
            mouseout: () => layer?.enable(),
          }}
        >
          <Circle
            pane={TREES_PANE_NAME}
            {...CIRCLE_OPTIONS}
            className="circle-marker-tree"
            key={uuidv4()}
            eventHandlers={{
              add: (e) => {
                editableId === treeMarker.id ? e.target.editing.enable() : e.target.editing.disable();
              },
              click: (e) => {
                L.DomEvent.stopPropagation(e);
                setEditableId(!e.target.editing.enabled() ? treeMarker.id : "");
                if (e.target.editing.enabled()) {
                  layer?.enable();
                } else {
                  layer?.disable();
                  clickOnExistingMarker(e, treeMarker);
                  onBlur();
                }
              },
              mouseout: () => {
                layer?.enable();
                onMouseOut();
              },
              mouseover: (e) => {
                layer?.disable();
                if (!e.target.editing.enabled()) {
                  const tooltipMsg = `Click to ${e.target.editing.enabled() ? "disable" : "enable"} editing`;
                  onMouseMove(e, tooltipMsg);
                }
              },
              //@ts-ignore
              edit: (e) => {
                editMarker(treeMarker.id, {
                  categoryId: treeMarker.categoryId,
                  geometry: point([e.target._latlng.lng, e.target._latlng.lat])?.geometry,
                  radiusMeters: e.target.getRadius(),
                });
                setEditableId("");
                layer?.enable();
              },
              move: (e) => {
                //I should move marker below when move the circle
                //@ts-ignore
                if (itemsRef?.current[i] && e.latlng) {
                  //@ts-ignore
                  itemsRef?.current[i].setLatLng(e.latlng);
                }
              },
            }}
            center={[treeMarker.geometry.coordinates[1], treeMarker.geometry.coordinates[0]]}
            radius={treeMarker.radius}
          />

          <Marker
            ref={(ref) => {
              if (!itemsRef?.current) {
                return;
              }
              //@ts-ignore
              itemsRef.current[i] = ref;
            }}
            key={`marker-${treeMarker.id}`}
            pane={TREES_PANE_NAME}
            interactive={false}
            position={[treeMarker.geometry.coordinates[1], treeMarker.geometry.coordinates[0]]}
            icon={marker}
            zIndexOffset={1200}
          />
        </FeatureGroup>
      );
    });
  }, [
    treeMarkers,
    clickOnExistingMarker,
    layer,
    map,
    editMarker,
    onBlur,
    itemsRef,
    onMouseOut,
    onMouseMove,
    editableId,
  ]);

  const temporaryMarker = useMemo(() => {
    if (!displayMarker) {
      return <></>;
    }

    const treeMarker = <Marker icon={marker} position={displayMarker._latlng} />;
    if (displayMarker._mRadius > 0) {
      return (
        <Circle
          pane={TREES_PANE_NAME}
          {...CIRCLE_OPTIONS}
          center={displayMarker._latlng}
          radius={displayMarker._mRadius}
        >
          {treeMarker}
        </Circle>
      );
    }
    return treeMarker;
  }, [displayMarker]);

  if (optimizedHidden) {
    return <></>;
  }
  return (
    <>
      {renderedObjects}
      {temporaryMarker}
    </>
  );
};
