import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Wrapper, ComponentTitle, Section, LabelItem, List, ListItem } from "./StarCommandRHS.styled";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as CloseIcon } from "../../assets/images/close_custom.svg";
import { ReactComponent as DraggableIcon } from "../../assets/images/draggable.svg";
import { IconButton } from "../IconButton";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";

import { reorder } from "../../utils/arrayUtils";
import { Portal } from "../Portal";
import useStarCommandMapStore from "../../stores/StarCommandMapStore/StarCommandMapStore";
import { roundValue } from "../../utils/unitHelper";
import { shortDate } from "../../utils/dateUtils";
import { capitalizeFirstLetter } from "../../utils/stringUtils";

export const StarCommandRHS = () => {
  const images = useStarCommandMapStore((store) => store.images);
  const { updateImageChecked } = useStarCommandMapStore((store) => store.actions);
  const { setRhsState } = useStarCommandMapStore((store) => store.actions);
  const { resetImages } = useStarCommandMapStore((store) => store.actions);
  const { setImages } = useStarCommandMapStore((store) => store.actions);
  const rhsVisible = useStarCommandMapStore((store) => store.rhsVisible);
  const hoveredId = useStarCommandMapStore((store) => store.hoveredId);
  const { setHoveredId } = useStarCommandMapStore((store) => store.actions);

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    // dropped on the same place
    if (destination.index === source.index) {
      return;
    }

    const reorderedItems = reorder(images?.availableImages ?? [], source.index, destination.index);
    setImages({ availableImages: reorderedItems });
  };

  return (
    <Portal selector="layer-menu-root">
      <Wrapper className={rhsVisible ? "isOpen" : ""}>
        <StyledFlex justifyContent="space-between" alignItems="center" margin="20px 10px">
          <ComponentTitle>Aoi Images</ComponentTitle>
          <IconButton
            className="close"
            Icon={CloseIcon}
            onClick={() => {
              setRhsState(false);
              resetImages();
            }}
          />
        </StyledFlex>
        <Section>
          <StyledFlex
            className="list-header"
            justifyContent="flex-end"
            alignItems="center"
            gap="2px"
            marginLeft="68px"
            marginRight="10px"
          >
            <LabelItem>Source</LabelItem>
            <LabelItem>Age</LabelItem>
            <LabelItem>Collected</LabelItem>
            <LabelItem>Area Clouds</LabelItem>
            <LabelItem>Area of Nadir</LabelItem>
          </StyledFlex>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? "isDraggingOver" : ""}
                >
                  {images?.availableImages?.map((item, idx) => (
                    <Draggable key={item.generatedId} draggableId={item.generatedId} index={idx}>
                      {(provided, snapshot) => (
                        <StyledFlex ref={provided.innerRef} alignItems="center" width="100%" gap="7px">
                          <StyledFlex
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            alignItems="center"
                            padding="15px 0"
                          >
                            <DraggableIcon />
                          </StyledFlex>
                          <ListItem
                            data-testid="maxar-image"
                            onMouseLeave={() => setHoveredId(null)}
                            onMouseEnter={() => setHoveredId(item.generatedId)}
                            hovered={hoveredId === item.generatedId}
                            onClick={() => updateImageChecked(item.generatedId)}
                            className={
                              snapshot.draggingOver === item.generatedId
                                ? "draggingOver"
                                : snapshot.isDragging
                                ? "isDragging"
                                : ""
                            }
                            style={provided.draggableProps.style}
                          >
                            <Checkbox
                              id="image"
                              label={item.platform}
                              name={item.platform}
                              value={item.platform}
                              defaultChecked={item.checked}
                              checked={item.checked}
                              onClick={() => {}}
                              tooltipText={item.platform}
                            />
                            <span>{capitalizeFirstLetter(item.ageCategory)}</span>
                            <span>{shortDate(item.acquisitionDate)}</span>
                            <span>{`${roundValue(item.cloudCoverPercentage, 2)}%`}</span>
                            <span>{`${roundValue(item.offNadir, 2)}°`}</span>
                          </ListItem>
                        </StyledFlex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Section>
        <StyledFlex borderTop="1px solid #EFEFEF" alignItems="center" padding="15px" marginTop="auto">
          <Button variant="primary" width="100%" height="42px" fontSize="14px">
            Order
          </Button>
        </StyledFlex>
      </Wrapper>
    </Portal>
  );
};
