import { FC, useCallback, useMemo, useRef, useState } from "react";
import StateManager, { components } from "react-select";
import { useMutation, useQueryClient } from "react-query";
import { OptionProps } from "react-select";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { Spacer } from "../../assets/styles/spacer.styled";
import { useCustomQuery } from "../../hooks";
import { logError } from "../../services";
import { WorksheetResponse } from "../../types/responses/WorksheetResponse";

import ApiClient from "../../utils/apiClient";
import { Button } from "../Button";
import { Select, Option } from "../Select";
import {
  SelectWrapper,
  WorkTypeTotal,
  WorkTypeTotalLabel,
  WorkTypeTotalValue,
  StyledSelectImage,
  AutoAssignedContent,
} from "./WorksheetArea.styled";
import { mapOptionsFromWorkTypes, mapWorksheetById, mapWorkTypesById } from "./WorksheetArea.utils";
import { WorkTypes } from "./WorkTypes/WorkTypes";
import { toUsdCurrency } from "../../utils/stringUtils";
import { getWorkTypesApiUrl, getCutPlanApiUrl } from "../../api";
import { WorkTypeResponse } from "../../types/responses";
import { ModalDialog } from "../ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../ModalDialog/ModalDialog.styled";
import { Portal } from "../Portal";
import { StyledText } from "../../assets/styles/text.styled";
import { Loader } from "../Loader";
import { getWorkTypeImage } from "../../pages/MapScreen/MapScreen.utils";
import { ReactComponent as AutoAssignedImage } from "./../../assets/images/AI.svg";

export const CustomSelectOptionComponent = (props: OptionProps<any, any, any> | any) => (
  <StyledFlex alignItems="center">
    <StyledSelectImage width="22px" height="22px" src={getWorkTypeImage(props.data.code)} alt="option image" />
    <Spacer pl="9px" />
    <StyledFlex alignItems="center" flex={1}>
      <StyledText fontWeight={600} fontSize={14} color={props.isDisabled ? "#BEC0C6" : "var(--colors-mineral-green)"}>
        {props.data.label}
      </StyledText>
      {props.data.autoAssigned && (
        <AutoAssignedContent>
          <AutoAssignedImage />
        </AutoAssignedContent>
      )}
    </StyledFlex>
  </StyledFlex>
);

export const CustomOption = (props: OptionProps<any, any, any>) => (
  <components.Option {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.Option>
);

export const CustomSingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.SingleValue>
);

interface WorksheetAreaProps {
  readonly: boolean;
  worksheetUrl: string;
  initialWorkTypes?: WorkTypeResponse[];
  initialWorksheet?: WorksheetResponse;
  enableReloadForPredominatedWorkTypes?: () => void;
  scope?: Nullable<"SPAN" | "STRUCTURE">;
  showSections?: boolean;
  onlyIds?: boolean;
  itemId?: string;
  assignToNewWorkorder?: (workTypeId: string) => void;
}

export const WorksheetArea: FC<WorksheetAreaProps> = ({
  readonly = false,
  worksheetUrl = "",
  initialWorkTypes,
  initialWorksheet,
  enableReloadForPredominatedWorkTypes,
  assignToNewWorkorder,
  scope = null,
  showSections = false,
  onlyIds = false,
  itemId,
}) => {
  const selectInputRef = useRef<Nullable<StateManager>>(null);
  const [selectedIdForRemoval, setSelectedId] = useState<Nullable<string>>(null);
  const hideModal = () => setSelectedId(null);
  const queryClient = useQueryClient();

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl(), {
    ...(initialWorkTypes ? { initialData: initialWorkTypes } : {}),
    cacheTime: 0,
    staleTime: 0,
  });

  const workTypesById = useMemo(() => mapWorkTypesById(workTypesResponse), [workTypesResponse]);

  const {
    data: worksheetResponse,
    isLoading: isWorksheetLoading,
    refetch: refetchWorksheet,
  } = useCustomQuery<WorksheetResponse>(worksheetUrl, {
    ...(initialWorksheet ? { initialData: initialWorksheet } : {}),
    enabled: !!itemId,
    cacheTime: 0,
    staleTime: 0,
  });

  const worksheetById = useMemo(() => mapWorksheetById(worksheetResponse), [worksheetResponse]);

  const filteredWorkTypes = useMemo(() => {
    if (!workTypesResponse) {
      return [];
    }

    if (scope === null) {
      return workTypesResponse;
    }

    return workTypesResponse.filter((workType) => workType.scope === scope);
  }, [workTypesResponse, scope]);

  const updateWorksheet = useMutation((value: { workTypeId: string; selected: boolean }[]) =>
    ApiClient.put<WorksheetResponse>(worksheetUrl, { items: value })
  );

  const invokeUpdateWorksheet = useCallback(
    (value) =>
      updateWorksheet
        .mutateAsync(value)
        .then(() => {
          enableReloadForPredominatedWorkTypes && enableReloadForPredominatedWorkTypes();
          refetchWorksheet();
          queryClient.invalidateQueries(getCutPlanApiUrl());
        })
        .catch((error) => logError(error)),
    [updateWorksheet, enableReloadForPredominatedWorkTypes, queryClient, refetchWorksheet]
  );

  const addNewWorkType = useCallback(
    (workTypeOption: Option) => {
      const newValue = {
        workTypeId: workTypeOption?.value,
        selected: true,
      };

      const worksheetItems =
        worksheetResponse?.items?.map((worksheet) => {
          if (onlyIds) {
            return worksheet.workTypeId;
          }
          return {
            workTypeId: worksheet.workTypeId,
            selected: worksheet.selected,
          };
        }) || [];
      return invokeUpdateWorksheet(
        onlyIds ? worksheetItems.concat(newValue.workTypeId) : [...worksheetItems, newValue]
      ).then(() => selectInputRef.current?.select?.clearValue());
    },
    [worksheetResponse, invokeUpdateWorksheet, onlyIds]
  );

  const removeWorkType = useCallback(() => {
    const worksheetItems = worksheetResponse?.items
      ?.filter((worksheet) => worksheet.workTypeId !== selectedIdForRemoval)
      .map((item) => ({
        workTypeId: item.workTypeId,
        selected: item.selected,
      }));

    return invokeUpdateWorksheet(worksheetItems || []).then(hideModal);
  }, [worksheetResponse, invokeUpdateWorksheet, selectedIdForRemoval]);

  const confirmRemoval = useCallback(
    (id: string) => {
      if (!id) {
        return;
      }
      setSelectedId(id);
    },
    [setSelectedId]
  );

  const totalCost = useMemo(() => {
    if (!worksheetResponse || !worksheetResponse?.items || !worksheetResponse?.items?.length) {
      return 0;
    }
    return worksheetResponse.items
      .map((item) => item.cost)
      .reduce((prev, next) => {
        return prev + next;
      });
  }, [worksheetResponse]);

  const selectedWorkTypes = useMemo(() => {
    return (
      worksheetResponse?.items?.map((item) => ({
        ...item,
        category: workTypesById[item.workTypeId]?.name,
        unit: workTypesById[item.workTypeId]?.unit,
        code: workTypesById[item.workTypeId]?.code,
      })) || []
    );
  }, [worksheetResponse, workTypesById]);

  return (
    <>
      <Portal selector="layer-menu-root">
        <ModalDialog
          hidden={!selectedIdForRemoval}
          message={"Are you sure you want to delete this work type entry?"}
          onClose={hideModal}
          title="Delete work type"
          warning
        >
          <ButtonsWrapper>
            <ButtonWrapper>
              <Button variant="cancel" size="small" onClick={hideModal}>
                Cancel
              </Button>
              <Button variant="warning" size="small" onClick={removeWorkType}>
                Delete
              </Button>
            </ButtonWrapper>
          </ButtonsWrapper>
        </ModalDialog>
      </Portal>
      <StyledFlex flexDirection="column">
        {!readonly && (
          <StyledFlex alignItems="center" className="worksheet__form">
            <SelectWrapper>
              <Select
                innerRef={selectInputRef}
                isDisabled={false}
                name="workType"
                onChange={addNewWorkType}
                options={mapOptionsFromWorkTypes(filteredWorkTypes)}
                placeholder="+Add Work Type"
                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                isOptionDisabled={(item: Option) => item.value in worksheetById}
              />
            </SelectWrapper>
            <Spacer pr={"6px"} />
          </StyledFlex>
        )}

        {isWorksheetLoading ? (
          <StyledFlex alignItems="center" justifyContent="center">
            <Loader />
          </StyledFlex>
        ) : (
          <WorkTypes
            showSections={showSections}
            readonly={readonly}
            workTypes={selectedWorkTypes}
            removeWorkType={confirmRemoval}
            assignToNewWorkorder={assignToNewWorkorder}
          />
        )}
        <WorkTypeTotal>
          <WorkTypeTotalLabel>Total:</WorkTypeTotalLabel>
          <WorkTypeTotalValue>{toUsdCurrency(totalCost)}</WorkTypeTotalValue>
        </WorkTypeTotal>
      </StyledFlex>
    </>
  );
};
