import { useMutation } from "react-query";
import { FeatureCollection, Polygon, Geometry } from "@turf/turf";
import ApiClient from "../utils/apiClient";
import { updatePolygonUrl } from "../api";
import { useThreatsStore } from "../stores";

export const useEditCustomPolygon = () => {
  const { updateThreatById: updateEncroachmentById } = useThreatsStore((store) => store.actions);
  const { updateThreatRiskPriority: updateEncroachmentRiskPriority } = useThreatsStore((store) => store.actions);

  return useMutation(
    ({ polygonId, geometry, riskPriority }: { polygonId: string; riskPriority: string; geometry?: Geometry }) =>
      ApiClient.put<FeatureCollection<Polygon>>(updatePolygonUrl(polygonId), { geometry, riskPriority }),
    {
      onSuccess: ({ data }, variables) => {
        const encroachmentId = data.features[0].properties?.id;
        if (variables.geometry) {
          updateEncroachmentById(encroachmentId, data.features[0].geometry.coordinates);
        }

        if (variables.riskPriority) {
          updateEncroachmentRiskPriority(encroachmentId, variables.riskPriority);
        }
      },
    }
  );
};
