import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../Button";

export const Wrapper = styled.div`
  padding: 108px 52px;
  width: 100%;
  box-sizing: border-box;

  form {
    background-color: #f9f9f9;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 0;
  border-radius: 5px;
  color: var(--colors-eastern-blue);
  box-sizind: border-box;

  &:hover {
    background-color: #f9f9f9;
  }

  &.backLink {
    width: fit-content;
    margin-bottom: 5px;
    font-size: 13px;

    svg {
      margin-right: 8px;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  background-color: var(--colors-white);
  padding: 15px 20px;
  box-sizing: border-box;
  height: fit-content;
`;

export const FormTitle = styled.span`
  color: var(--colors-eastern-blue);
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: var(--colors-nevada);

  &.regionName {
    font-weight: 500;
    color: var(--colors-manatee);
  }

  &.info {
    font-size: 12px;
    line-height: 1;
  }

  &.year {
    font-weight: 600;
    font-size: 25px;
    color: var(--colors-river-bad);

    span {
      display: inline-flex;
      justify-content: center;
      width: 60px;
    }
  }
`;

export const AddCrew = styled.span`
  color: var(--colors-eastern-blue);
  background-color: transparent;
  border: none;
  padding: 0;
  position: relative;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  > div {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }

  &:not(&.isOpen) div {
    display: none;
  }
`;

export const HeaderButton = styled(Button)`
  height: fit-content;
  font-size: 15px;
`;
