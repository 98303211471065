import { v4 as uuidv4 } from "uuid";

import { MaxarResponseType } from "../../types/responses/MaxarResponse";

export const mapImageResponse = (response: MaxarResponseType) => {
  if (!response?.availableImages?.length) {
    return null;
  }

  return {
    availableImages: response.availableImages?.map((image, index) => ({
      ...image,
      generatedId: uuidv4(),
      checked: image.selectedByDefault,
      orderNumber: index,
    })),
  };
};
