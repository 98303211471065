import { Geometry } from "geojson";
import create from "zustand";

import { RightOfWayOverride, RightOfWayOverridesResponse, Span, SpansResponseProperties } from "../types/responses";

export type RightOfWayOverridesStore = {
  byParcelId: ById;
  bySpanId: ById;
  getByParcelId: (id: string) => Maybe<RightOfWayOverride>;
  getBySpanId: (id: string) => Maybe<RightOfWayOverride>;
  localOverrides: Record<string, Span>;
  overrides: Nullable<RightOfWayOverride[]>;
  resetLocalOverrides: () => void;
  setData: (data: RightOfWayOverridesResponse) => void;
  setLocalOverrides: ({
    rowOverrideId,
    polygons,
  }: {
    rowOverrideId: string;
    polygons: { spanId: string; polygon: Geometry }[];
  }) => void;
  isSpanOverridenByParcel: (id: string) => boolean;
  overridenSpansIdsByParcel: string[];
};

type ById = Record<string, RightOfWayOverride>;

const INITIAL_STATE = {
  localOverrides: {},
  overrides: null,
  bySpanId: {},
  byParcelId: {},
  overridenSpansIdsByParcel: [],
};

export const useRightOfWayOverridesStore = create<RightOfWayOverridesStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setData: (overridesResponse) => set(mapOverridesResponse(overridesResponse)),
  setLocalOverrides: ({ polygons }) => {
    if (!polygons || !polygons.length) {
      return;
    }
    const localOverrides = { ...get().localOverrides };
    polygons.forEach((override) => {
      localOverrides[override.spanId] = {
        type: "Feature",
        geometry: override.polygon,
        properties: { id: override.spanId } as SpansResponseProperties,
      };
    });
    set({ localOverrides });
  },
  resetLocalOverrides: () => set({ localOverrides: {} }),
  getBySpanId: (id: string) => get().bySpanId?.[id],
  getByParcelId: (id: string) => get().byParcelId?.[id],
  isSpanOverridenByParcel: (id: string) => get().overridenSpansIdsByParcel.includes(id),
}));

export default useRightOfWayOverridesStore;

const mapOverridesResponse = ({ overrides }: RightOfWayOverridesResponse) => {
  const bySpanId = overrides.reduce((prev, curr) => {
    if (!curr.spanId) {
      return prev;
    }
    prev[curr.spanId] = curr;

    return prev;
  }, {} as ById);

  const overridenSpansIdsByParcel: string[] = [];

  const byParcelId = overrides.reduce((prev, curr) => {
    if (!curr.parcelId) {
      return prev;
    }
    prev[curr.parcelId] = curr;

    curr.polygons.forEach((polygon) => overridenSpansIdsByParcel.push(polygon.spanId));

    return prev;
  }, {} as ById);

  return {
    overrides,
    bySpanId,
    byParcelId,
    overridenSpansIdsByParcel,
  };
};
