import styled from "styled-components";
import { IconButton } from "../IconButton";
import { LEAFLET_MAP } from "../../constants/z-index";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const Wrapper = styled.div<{ isRightDrawerVisible: boolean }>`
  position: absolute;
  right: ${({ isRightDrawerVisible }) => (isRightDrawerVisible ? "371px" : "12px")};
  top: 112px;
`;

export const LayerButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  padding: 0;
  z-index: ${LEAFLET_MAP + 1};

  &:not(.isActive) {
    svg,
    path,
    rect,
    circle {
      fill: #20a7b3;
    }
  }

  &.isActive {
    background-color: var(--colors-eastern-blue);
    border-color: var(--colors-eastern-blue);

    path {
      fill: var(--colors-white);
    }
  }
`;

export const ToolkitButton = styled(IconButton)`
  background-color: rgba(38, 44, 45, 0.78);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.42);
  padding: 0;
  border: 0;

  &[disabled] {
    background-color: rgba(38, 44, 45, 0.78);
  }
  svg,
  path {
    fill: var(--colors-white);
  }
  &:not(.isActive):not([disabled]):hover {
    svg,
    path,
    rect,
    circle {
      fill: var(--colors-teal-3);
    }
  }
`;

export const ButtonWrapper = styled(StyledFlex)`
  position: absolute;
  top: 0;
  right: 35px;
  z-index: ${LEAFLET_MAP + 1};
  max-width: 0;
  transition: all 0.15s ease-out;
  overflow: hidden;

  &.isOpen {
    padding-right: 10px;
    max-width: 500px;
    transition: all 0.25s ease-in;
  }
`;
