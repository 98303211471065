import styled from "styled-components";
import { LEFT_SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";

const PageWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

interface PageContentProps {
  toggled: boolean;
}

const PageContent = styled.div<PageContentProps>`
  display: inline-block;
  width: ${(props) => (props.toggled ? ` calc(100% - ${LEFT_SIDEBAR_WIDTH}px)` : `100%`)};
  left: ${(props) => (props.toggled ? `${LEFT_SIDEBAR_WIDTH}px` : `0px`)};
  top: ${TOP_HEADER_HEIGHT}px;
  overflow-x: hidden;
  position: relative;
`;

export { PageWrapper, PageContent };
