import L, { Layer } from "leaflet";
import { useEffect } from "react";
import { useCallback } from "react";
import { FC, memo, useMemo } from "react";

import { ImageOverlay, GeoJSON, LayerGroup } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

import { useCustomMapPane, useMapOptimizedHiddenFlag } from "../../hooks";
import { MaxarResponseType } from "../../types/responses/MaxarResponse";
import MapHelper from "../../utils/mapHelper";

const DEFAULT_STYLE = { fill: true, color: "transparent", fillColor: "transparent", className: "maxar-polygon" };
const HOVERED_STYE = {
  color: "#FAFF00",
  weight: 2,
  stroke: true,
  fillColor: "transparent",
  className: "maxar-polygon hovered",
};
const MAX_Z_INDEX = 3000;
const PANE_NAME = "star-command-images";
const PANE_GEOJSON_NAME = "star-command-polygon";
interface IProps {
  hidden?: boolean;
  images: Nullable<MaxarResponseType>;
  map: Nullable<L.Map>;
  zIndex?: number;
  setHoveredId: (hoveredId: Nullable<string>) => void;
  hoveredId: Nullable<string>;
}

export const StarCommandMapImages: FC<IProps> = memo(
  ({ hidden = false, images, map, zIndex = 397, setHoveredId, hoveredId }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    useCustomMapPane({
      map,
      name: PANE_NAME,
      zIndex,
    });

    useCustomMapPane({
      map,
      name: PANE_GEOJSON_NAME,
      zIndex: zIndex + 1,
    });

    useEffect(() => {
      return () => {
        images?.availableImages?.forEach((image) => {
          const paneName = `pane-${image.generatedId}`;
          if (map?.getPane(paneName)) map.getPane(paneName)?.remove();
        });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEachFeature = useCallback(
      (_feature, layer: Layer, id: string) => {
        layer.on({
          mouseover: (e: L.LeafletMouseEvent) => {
            setHoveredId(id);
            L.DomEvent.stopPropagation(e);
          },
          mouseout: () => setHoveredId(null),
        });
      },
      [setHoveredId]
    );

    const renderedImages = useMemo(() => {
      if (!images?.availableImages?.length) {
        return <></>;
      }

      return images.availableImages
        .filter((image) => image.checked)
        .map((image, index) => {
          const paneName = `pane-${image.generatedId}`;
          const pane = map?.getPane(paneName) ?? map?.createPane(paneName);
          if (pane) pane.style.zIndex = (4000 - index).toString();
          return (
            <LayerGroup>
              <GeoJSON
                key={uuidv4()}
                style={hoveredId === image.generatedId ? HOVERED_STYE : DEFAULT_STYLE}
                interactive={true}
                onEachFeature={(feature, layer) => onEachFeature(feature, layer, image.generatedId)}
                data={image?.geometry}
                pane={`pane-${image.generatedId}`}
              >
                {image.previewImages?.map((previewImage) => {
                  return (
                    <ImageOverlay
                      pane={PANE_NAME}
                      zIndex={MAX_Z_INDEX - index}
                      url={previewImage.url}
                      className={"maxar-image-overlay"}
                      bounds={
                        MapHelper.normalizeBounds(
                          MapHelper.bBox(false, previewImage.extent)
                        ) as L.LatLngBoundsExpression
                      }
                    />
                  );
                })}
              </GeoJSON>
            </LayerGroup>
          );
        });
    }, [images, hoveredId, onEachFeature, map]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{renderedImages}</>;
  }
);
