import styled from "styled-components";

import { Head1 } from "../../../../assets/styles/fonts.styled";
import { RIGHT_DRAWER } from "../../../../constants";
import { RIGHT_SIDEBAR_WIDTH } from "../../../../constants/dimensions";

export const Wrapper = styled.div``;

export const SecondLevelHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${RIGHT_DRAWER + 1};
  position: absolute;
  width: 359px;
  top: 0px;
  left: 0px;
  height: 54px;
  background: ${(props) => props.theme.colors.alabaster3};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChildrenContent = styled.div`
  width: ${RIGHT_SIDEBAR_WIDTH}px;
`;

export const ItemsWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const BackImageWrapper = styled.div`
  width: 28px;
  height: 28px;
  border: 1px solid ${(props) => props.theme.colors.mystic};
  box-sizing: border-box;
  border-radius: 5px;
  background: var(--colors-alabaster3);
  border-radius: 4px;
  display: flex;
  vertical-align: center;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-left: 20px;
  svg path {
    fill: #85919e;
  }
  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.easternBlue};
    }
  }
  &:active {
    svg path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  ${Head1}
  margin-left: 10px;
`;

export const CloseImageWrapper = styled.div`
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 20px;
  border: 1px solid ${(props) => props.theme.colors.mystic};
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 20px;
`;

export const CloseIcon = styled.div`
  svg path {
    fill: #85919e;
  }
  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.easternBlue};
    }
  }
  &:active {
    svg path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;
