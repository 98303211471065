import { PARCEL_STATUS } from "../../types/responses";

export const sortValues: Record<PARCEL_STATUS, number> = {
  [PARCEL_STATUS.REFUSED]: 1,
  [PARCEL_STATUS.CONCERNED]: 2,
  [PARCEL_STATUS.NOTIFIED]: 3,
  [PARCEL_STATUS.NOT_ASSIGNED]: 4,
  [PARCEL_STATUS.APPROVED]: 5,
};

export const sortParcels = (parcels: any) =>
  parcels.sort((a: { status: PARCEL_STATUS }, b: { status: PARCEL_STATUS }) => {
    const firstStatus = a.status as PARCEL_STATUS;
    const secondStatus = b.status as PARCEL_STATUS;
    return sortValues[firstStatus] - sortValues[secondStatus];
  });

export const getParcelDropdownColor = (status: PARCEL_STATUS) => {
  switch (status) {
    case PARCEL_STATUS.NOTIFIED:
      return "var(--colors-notified-legend)";
    case PARCEL_STATUS.NOT_ASSIGNED:
      return "var(--colors-not-assigned-legend)";
    case PARCEL_STATUS.APPROVED:
      return "var(--colors-approved-legend)";
    case PARCEL_STATUS.REFUSED:
      return "var(--colors-refused-legend)";
    case PARCEL_STATUS.CONCERNED:
      return "var(--colors-concerned-legend)";
    default:
      return "var(--colors-not-assigned-legend)";
  }
};

export const statusInitialSelectValue = { label: "Assign Status", value: "default" };
