import { useCallback } from "react";
import { GanttStatic } from "../types/dhtmlxgantt";

const gantt: GanttStatic = (window as any).gantt;

export const useUpdateParcelFlag = () => {
  const setParcelFlag = useCallback((parcelId: Nullable<string>, parcelFlag: boolean) => {
    gantt.batchUpdate(() => {
      gantt.eachTask((task) => {
        if (task.type !== "task") return;

        const isAffected = task.parcelIds.some((id: string) => id === parcelId) || false;
        if (isAffected) {
          const newState = {
            ...task,
            additionalData: {
              ...task.additionalData,
              parcelFlag,
            },
          };
          gantt.updateTask(task.id, newState);
        }
      });
    });
  }, []);

  return setParcelFlag;
};
