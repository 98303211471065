import styled from "styled-components";
import { color, ColorProps, border, BorderProps } from "styled-system";

export const HydrologyWrapper = styled.div`
  background-color: var(--colors-legend-popup-bgd);
  border-radius: 10px;
  z-index: 600;
  padding: 23px 20px 23px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HydrologyName = styled.span`
  font-size: 14px;
  color: var(--colors-white);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  div:last-child {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: var(--colors-oslo-gray);
  }
`;

export const HydrologyLegendColor = styled.div<ColorProps & BorderProps>`
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  width: 13px;
  height: 6px;
  box-sizing: border-box;
  ${color}
  ${border}
`;

export const HydrologyLegendItemName = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: var(--colors-white);
  line-height: 14px;
`;

export const HydrologyLegendItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;
