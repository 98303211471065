import { useMutation } from "react-query";
import { FeatureCollection, Feature, Polygon } from "@turf/turf";
import ApiClient from "../utils/apiClient";
import { customPolygonUrl } from "../api";
import { ThreatDto, ExtendedEncroachmentResponseProperties, ThreatsProperties } from "../types/responses";
import { useThreatsStore } from "../stores";

export const useCreateCustomPolygon = () => {
  const { addThreatFromCWC: addEncroachment } = useThreatsStore((store) => store.actions);

  return useMutation(
    (newPolygon: Feature<Polygon>) =>
      ApiClient.post<FeatureCollection<Polygon>>(customPolygonUrl, { geometry: newPolygon.geometry }),
    {
      onSuccess: async ({ data: addedPolygon }) => {
        const { geometry, properties } = addedPolygon.features[0];
        const { data: polygonData } = await ApiClient.get<ThreatDto>(`${customPolygonUrl}/${properties?.id}`);

        const newPolygon = {
          type: "Feature",
          geometry: {
            ...geometry,
            coordinates: [geometry.coordinates[0]],
          },
          properties: {
            ...properties,
            priority: properties?.riskPriority,
            metadata: polygonData.threat.properties?.metadata,
            pushout: polygonData.threat.properties?.pushout,
            spanIds: polygonData.threat.properties?.spanIds,
          },
        };

        const encroachment = {
          ...newPolygon,
          geometry: {
            ...newPolygon.geometry,
            coordinates: [...geometry.coordinates],
          },
        };

        addEncroachment(encroachment as Feature<Polygon, ExtendedEncroachmentResponseProperties & ThreatsProperties>);
      },
    }
  );
};
