import create from "zustand";

export interface MapPatrol {
  id: string;
  name: string;
  lat: number;
  lon: number;
  shortDescription: string | undefined;
  iconUrl: string;
  type: "VEGETATION" | "STRUCTURE";
}

export interface IPatrolsStore {
  isVisible: boolean;
  setToggled: (toggle: boolean) => void;
  data: MapPatrol[];
  setData: (data: MapPatrol[]) => void;
}

const usePatrolsStore = create<IPatrolsStore>((set) => ({
  isVisible: false,
  data: [],
  setData: (data) => set({ data }),
  setToggled: (toggle) => set(({ isVisible }) => ({ isVisible: toggle ?? !isVisible })),
}));

export { usePatrolsStore };
