import React, { FC, useCallback } from "react";

import { IntersectedLayersWrapper } from "./DrawerSectionItems.styled";
import "./DrawerSectionItems.styled.css";
import { Checkbox } from "../../Checkbox";
import { AdditionalLayer } from "../../../stores/MapStore";
import { IntersectedLayers } from "../../../pages/MapScreen/RightDrawerMenu/RightDrawerMenu.utils";

const SHOW_ALL = "show_all_on_map";

export const SectionIntersectedLayer: FC<{
  intersectedLayers: IntersectedLayers;
  setVectorLayerVisibility: ({ name, key, value }: { name: string; key: string; value: boolean | string }) => void;
  vectorLayers: Nullable<Record<string, AdditionalLayer>>;
}> = ({ setVectorLayerVisibility, vectorLayers, intersectedLayers }) => {
  const toggleAll = useCallback(
    (
      e: React.FormEvent<HTMLElement | HTMLInputElement>,
      intersectedLayers: Maybe<{ name: string; key: string; image?: string }>[]
    ) => {
      const selected = (e.target as HTMLInputElement).checked;
      if (!intersectedLayers) {
        return;
      }
      intersectedLayers.forEach((intersectedLayer) => {
        if (!intersectedLayer) {
          return;
        }
        if (vectorLayers?.[intersectedLayer.key]?.checked !== selected) {
          setVectorLayerVisibility({ name: intersectedLayer.name, key: intersectedLayer.key, value: selected });
        }
      });
    },
    [vectorLayers, setVectorLayerVisibility]
  );

  const isAllSelected = useCallback(
    (intersectedLayers: Maybe<{ name: string; key: string; image?: string }>[]) => {
      if (!intersectedLayers) {
        return false;
      }
      const selectedLayers = intersectedLayers
        .map((intersectedLayer) => {
          if (!intersectedLayer) {
            return Number("0");
          }
          if (vectorLayers?.[intersectedLayer.key]?.checked) {
            return Number("1");
          }
          return Number("0");
        })
        .reduce((prev, current) => {
          return Number(prev) + Number(current);
        });

      return selectedLayers === intersectedLayers.length;
    },
    [vectorLayers]
  );

  const isLayerChecked = useCallback(
    (key) => {
      return vectorLayers && key && vectorLayers[key] && vectorLayers[key].checked;
    },
    [vectorLayers]
  );
  if (!intersectedLayers) {
    return <></>;
  }

  if (!intersectedLayers || !intersectedLayers.length) {
    return <></>;
  }

  return (
    <IntersectedLayersWrapper>
      <Checkbox
        defaultChecked={isAllSelected(intersectedLayers)}
        id={SHOW_ALL}
        name={SHOW_ALL}
        {...{ checked: isAllSelected(intersectedLayers) }}
        onClick={(e: React.FormEvent<HTMLElement | HTMLInputElement>) => {
          toggleAll(e, intersectedLayers!);
        }}
        value={SHOW_ALL}
        label={"Show all on map"}
      />
      {intersectedLayers?.map((layer, index) => (
        <Checkbox
          key={layer.key}
          defaultChecked={isLayerChecked(layer.key)}
          id={layer.key}
          name={layer.name}
          onClick={(e: React.FormEvent<HTMLElement | HTMLInputElement>) => {
            setVectorLayerVisibility({
              name: layer.name,
              key: layer.key,
              value: (e.target as HTMLInputElement).checked,
            });
          }}
          value={layer.key}
          label={layer.name}
          image={layer.image}
          {...{ checked: isLayerChecked(layer.key) }}
        />
      ))}
    </IntersectedLayersWrapper>
  );
};
