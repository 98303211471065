import React, { FC } from "react";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { Wrapper, Title, DateLabel, Status, StyledLink, Updated } from "./PlanCard.styled";
import { editPlanRoute } from "../../routes";
import { PlanDto, PLAN_STATUS } from "../../types/responses";

export const PlanCard: FC<{ plan: PlanDto }> = ({ plan }) => {
  const isEditable =
    plan.status.toLocaleLowerCase() === PLAN_STATUS.Active.toLocaleLowerCase() ||
    plan.status.toLocaleLowerCase() === PLAN_STATUS.Draft.toLocaleLowerCase();
  return (
    <Wrapper>
      <Title>{plan.name}</Title>
      <StyledFlex justifyContent="space-between" marginTop="10px">
        <StyledFlex flexDirection="column" justifyContent="flex-start" gap="5px">
          <DateLabel>
            {new Date(plan.start).getFullYear()} - {new Date(plan.end).getFullYear()}
          </DateLabel>
          <Updated>
            Last edit:{" "}
            {new Date(plan.updated).toLocaleDateString("en-US", { month: "long", day: "2-digit", year: "numeric" })}
          </Updated>
        </StyledFlex>
        <Status data-status={plan.status.toLocaleLowerCase()}>{plan.status.toLocaleLowerCase()}</Status>
      </StyledFlex>
      {isEditable ? (
        <StyledLink className="primary" to={editPlanRoute(plan.id)}>
          Edit
        </StyledLink>
      ) : (
        <StyledLink className="secondary disabled" to={""}>
          View Report
        </StyledLink>
      )}
    </Wrapper>
  );
};
