import L, { LatLng } from "leaflet";

export const latLngsToCoords = (latlngs: LatLng[]) => {
  // GeoJSON uses lng/lat format, so
  // reverse them before transforming to coords
  // const reversedLatLngs = latlngs.map((latlng: LatLng) => ({
  //   lat: latlng.lng,
  //   lng: latlng.lat,
  // }));

  return L.GeoJSON.latLngsToCoords(latlngs, 0, true);
};
