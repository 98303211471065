import styled from "styled-components";
import { margin, MarginProps } from "styled-system";
import { StyledFlex } from "../../../assets/styles/flex.styled";

import { DataLabel, Data1, Link } from "../../../assets/styles/fonts.styled";
import { ReactComponent as Arrow } from "../../../assets/images/menu_arrow.svg";

export const SectionItemWrapper = styled.div<
  MarginProps & { intersectedLayers?: Maybe<boolean>; hasBorder?: Maybe<boolean> }
>`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  flex-direction: ${(props) => (props.intersectedLayers ? "column" : "row")};
  ${(props) => (props.hasBorder ? "border-bottom: 1px solid var(--colors-gallery);padding-bottom: 8px;" : "")};

  &:last-child {
    border: none;
  }
  ${margin}
`;

export const CommonItem = styled.div<{ bold?: boolean }>`
  height: 17px;
  flex: 1;
`;

export const SectionLabel = styled(CommonItem)`
  ${(props) => (props.bold ? Data1 : DataLabel)};
  font-size: 14px;
  user-select: none;
`;

export const SectionValue = styled(CommonItem)<{ showCursor?: boolean }>`
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  ${(props) => (props.showCursor ? "cursor: pointer;" : "")};
`;

export const Hyperlink = styled.span`
  .hyperlink-content {
    ${Link}
    display: inline-block;
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      color: #2bcfdd;
    }
  }
`;

export const StatusValue = styled.span<{ statusColor: string }>`
  width: 16px;
  height: 7px;
  background: ${(props) => (props.statusColor ? props.statusColor : "transparent")};
  border-radius: 21px;
  margin-top: 3px;
  margin-left: 5px;
  display: inline-block;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  right: 20px;
  top: 80px;
  background: var(--colors-white);
`;

export const SectionLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  ${Data1}
  align-items: center;
  display: inline-block;
`;

export const Links = styled.div`
  max-width: 60%;
  text-align: end;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  gap: 5px;
`;

export const IntersectedLayersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
    height: 45px;
    display: flex;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--colors-mineral-green);
    border-bottom: 1px solid var(--colors-gallery);
    label {
      img {
        background-color: transparent;
        width: 22px;
        height: 22px;
      }
      span {
        font-family: Barlow;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-left: 13px;
      }
    }
  }
  div:last-child {
    border: none;
  }
  div:first-child {
    span {
      font-weight: 600;
      padding-left: 13px;
    }
  }
`;

export const RightOfWayWrapper = styled.div`
  position: relative;
`;

export const RightOfWayFormWrapper = styled.div`
  display: flex;
  gap: 7px;
  padding-top: 10px;
  padding-bottom: 30px;
  align-items: center;
  padding-bottom: 15px;
`;

export const RightOfWayErrorMessage = styled.div`
  font-family: Barlow;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  color: red;
  bottom: 8px;
`;

export const ParcelDropdown = styled(StyledFlex)`
  position: absolute;
  top: 30px;
  right: 0;
  width: 150px;
  background-color: var(--colors-background);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);

  > span {
    padding: 7px 15px;
    flex: 1;
    border-bottom: 1px solid var(--colors-gallery);

    &:hover {
      background-color: var(--colors-gallery);
    }
  }
`;

export const ArrowDown = styled(Arrow)`
  fill: var(--colors-eastern-blue);
  width: 11px;
  height: 6px;
`;
