import { LatLngExpression } from "leaflet";
import { RiskPriority } from "../../types/responses";

export enum ContentType {
  SPAN = "Span",
  CIRCUIT = "Circuit",
  PARCEL = "Parcel",
  ENCROACHMENT = "Encroachment",
  STRUCTURE = "Structure",
  CUSTOM_POLYGON = "Custom Polygon",
}

export interface SectionItem {
  label: string;
  value?: string; //optional: if simple item
  statusColor?: string; //optional: show status with this color before label
  //list of hyperlinks (value will be shown on label, id will be used to change view)
  affectedObjects?: { value: string; id: string; type: ContentType }[];
  intersectedLayers?: { name: string; key: string; image?: string }[];
}

export interface Section {
  name?: string;
  items: SectionItem[];
}

export interface DrawerContent {
  name?: string;
  sections: Section[];
  viewPlan?: boolean;
}

export interface DrawerElement {
  type: ContentType;
  id: string;
  coordinates?: LatLngExpression;
  riskPriority?: Nullable<RiskPriority>;
}

export type DrawerStore = {
  stack: DrawerElement[];
  push: (element: DrawerElement, isInnerLevel?: boolean) => void;
  pop: () => void;
  clearStack: () => void;
  getLastElement: () => DrawerElement | undefined;
};
