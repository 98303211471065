import { GeoJSON, Marker } from "react-leaflet";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import L, { LeafletMouseEvent } from "leaflet";
import { Feature, Geometry } from "geojson";

import {
  filterByFeeder,
  getColorByWorkType,
  getSpanStyle,
  getWorkTypesFromResponse,
  isWorkTypeEnabled,
} from "./MapFeederSegments.utils";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";

import { EMPTY_FN } from "../../constants";
import { WorksheetResponse, WorkTypeResponse } from "../../types/responses";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { useCustomQuery, useMapOptimizedHiddenFlag } from "../../hooks";
import { getWorkTypeMarker } from "../../pages/MapScreen/MapScreen.utils";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE } from "../../pages/MapScreen/MapView/MapView.constants";
import { getWorkTypeContentPopup } from "../../pages/MapScreen/MapView/MapViewPopup.utils";
import { getSpanWorksheetApiUrl } from "../../api";
import { FeederSegmentResponse } from "../../types/responses/FeederSegmentResponse";
import { FeederSegmentPositions } from "../../stores/FeederSegmentStore";

interface MapFeederSegmentsProps {
  geoJSON: Nullable<FeederSegmentResponse>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  onClick: (e: L.LeafletMouseEvent) => void;
  zIndex?: number;
  selectedGroupId: Nullable<string>;
  selectedId: Nullable<string>;
  spansWithWorkType: Nullable<Record<string, WorkTypeResponse>>;
  enabledWorkType: boolean;
  feederSegmentPositions: FeederSegmentPositions;
  workTypesById: Nullable<Record<string, WorkTypeResponse>>;
  lineWidth?: number;
  disableMarkers: boolean;
  hiddenFeeders: Nullable<string[]>;
  selectedSpans: Nullable<string[]>;
}

const SPANS_PANE_NAME = "spans-pane";

const SPANS_MARKER_PANE_NAME = "spans-marker-pane";

type PopupMetadata = {
  spanId: Nullable<string>;
  latLng: Nullable<L.LatLng>;
};

const INITIAL_POPUP_METADATA = { spanId: null, latLng: null };

export const MapFeederSegments: FC<MapFeederSegmentsProps> = memo(
  ({
    geoJSON,
    hidden = false,
    map,
    zIndex = 401,
    onClick = EMPTY_FN,
    selectedGroupId,
    selectedId,
    spansWithWorkType,
    enabledWorkType,
    feederSegmentPositions,
    workTypesById,
    lineWidth = 13,
    disableMarkers,
    hiddenFeeders,
    selectedSpans,
  }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    const [popupEnabled, setPopupEnabled] = useState(false);
    const [popupMetadata, setPopupMetadata] = useState<Nullable<PopupMetadata>>(INITIAL_POPUP_METADATA);

    useCustomMapPane({
      name: SPANS_PANE_NAME,
      zIndex,
      map,
    });

    useCustomMapPane({
      name: SPANS_MARKER_PANE_NAME,
      zIndex: zIndex + 1,
      map,
    });

    const onSuccess = useCallback(
      (response: WorksheetResponse) => {
        if (!popupEnabled) {
          return;
        }
        const workTypes = getWorkTypesFromResponse(workTypesById, response);
        const html = workTypes && getWorkTypeContentPopup(workTypes);
        if (!popupMetadata?.spanId || !popupMetadata?.latLng || !html || !map || !workTypes) {
          return;
        }

        const popup = L.popup({ className: "work-type-popup", closeButton: false });
        popup.setLatLng(popupMetadata.latLng);
        popup.setContent(html);
        popup.openOn(map);
      },
      [map, popupMetadata, workTypesById, popupEnabled]
    );

    useCustomQuery<WorksheetResponse>(getSpanWorksheetApiUrl(popupMetadata?.spanId ?? ""), {
      cacheTime: 0,
      staleTime: 0,
      enabled: popupEnabled && popupMetadata?.spanId !== null,
      onSuccess: onSuccess,
      onError: EMPTY_FN,
    });

    const onEachFeature = useCallback(
      (feature, layer, isWorkTypeEnabled) => {
        layer.on({
          click: onClick,
          mouseover: (e: LeafletMouseEvent) => {
            if (!isWorkTypeEnabled || !map || !e.target?.feature?.properties?.spanId) {
              return;
            }
            setPopupEnabled(true);
            setPopupMetadata({ spanId: e.target.feature.properties.spanId, latLng: e.latlng });
          },
          mouseout: (e: LeafletMouseEvent) => {
            if (!isWorkTypeEnabled || !map) {
              return;
            }
            map.closePopup();
            setPopupEnabled(false);
            setPopupMetadata(INITIAL_POPUP_METADATA);
          },
        });
      },
      [onClick, map]
    );

    const feederSegments = useMemo(() => {
      if (isGeoJSONEmpty(geoJSON)) {
        return <></>;
      }
      return geoJSON!.features
        .filter((item) => filterByFeeder(hiddenFeeders, item.properties.feederId))
        .map((item) => {
          const workTypeEnabled = isWorkTypeEnabled(enabledWorkType, spansWithWorkType, item.properties.spanId);
          const colorByWorkType = getColorByWorkType(!!workTypeEnabled, spansWithWorkType, item.properties.spanId);
          return (
            <GeoJSON
              data={item}
              key={uuidv4()}
              onEachFeature={(feature: Feature<Geometry, unknown>, layer: L.Layer) => {
                onEachFeature(feature, layer, workTypeEnabled);
              }}
              pane={SPANS_PANE_NAME}
              style={getSpanStyle({
                item,
                selectedGroupId,
                selectedId,
                enabledWorkType: !!workTypeEnabled,
                color: colorByWorkType,
                lineWidth,
                selectedSpans,
              })}
            ></GeoJSON>
          );
        });
    }, [
      onEachFeature,
      geoJSON,
      selectedGroupId,
      selectedId,
      enabledWorkType,
      spansWithWorkType,
      lineWidth,
      hiddenFeeders,
      selectedSpans,
    ]);

    const renderedMarkers = useMemo(() => {
      if (
        isGeoJSONEmpty(geoJSON) ||
        !enabledWorkType ||
        disableMarkers ||
        !spansWithWorkType ||
        !Object.keys(spansWithWorkType).length
      ) {
        return <></>;
      }

      return geoJSON!.features
        .filter((item) => {
          return (
            isWorkTypeEnabled(enabledWorkType, spansWithWorkType, item.properties.spanId) &&
            filterByFeeder(hiddenFeeders, item.properties.feederId)
          );
        })
        .map((item) => {
          return (
            <Marker
              icon={
                new L.Icon({
                  iconUrl: getWorkTypeMarker(spansWithWorkType?.[item.properties.spanId].code ?? ""),
                  iconSize: [22, 22],
                })
              }
              interactive={false}
              position={
                (feederSegmentPositions[item.properties.id].center as L.LatLngTuple).reverse() as L.LatLngExpression
              }
              pane={SPANS_MARKER_PANE_NAME}
              key={uuidv4()}
            />
          );
        });
    }, [geoJSON, enabledWorkType, spansWithWorkType, feederSegmentPositions, disableMarkers, hiddenFeeders]);

    if (optimizedHidden) {
      return <></>;
    }

    return (
      <>
        {feederSegments}
        <MapMarkerCluster disableClusteringAtZoom={MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE}>
          {renderedMarkers}
        </MapMarkerCluster>
      </>
    );
  }
);
