import { FC, Ref } from "react";
import { LayoutProps, SpaceProps } from "styled-system";
import { components, IndicatorProps, Props } from "react-select";
import { useField } from "formik";

import { StyledSelect } from "./Select.styled";
import { ReactComponent as SelectOpenIcon } from "./../../assets/images/select_open_icon.svg";

export interface Option {
  label: string;
  value: string;
}

type SelectDefaultProps = Omit<Props, "onChange">;

export interface SelectProps<TOption = Option> extends SelectDefaultProps, LayoutProps, SpaceProps {
  options: TOption[];
  isDisabled?: boolean;
  value?: Nullable<Option>;
  onChange: (option: TOption) => void;
  name?: string;
  autoAssigned?: boolean;
  innerRef?: Ref<unknown>;
}

const DropdownIndicator = (props: IndicatorProps<any, any, any>) => (
  <components.DropdownIndicator {...props}>
    <SelectOpenIcon />
  </components.DropdownIndicator>
);

export const Select = <TOption extends Option>({
  options,
  value,
  onChange,
  innerRef,
  isDisabled = false,
  components = {},
  ...rest
}: React.PropsWithChildren<SelectProps<TOption>>): React.ReactElement | null => (
  <>
    <StyledSelect
      className="vantage-react-select-container"
      classNamePrefix="react-select"
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
      options={options}
      value={value}
      components={{ DropdownIndicator, ...components }}
      {...(innerRef && { ref: innerRef })}
      {...rest}
    />
  </>
);

export const ControlledSelect: FC<SelectProps> = ({ ...props }) => {
  const [, , helpers] = useField(props.name || "default");
  const { setValue } = helpers;

  return <Select {...props} onChange={(option) => setValue(option)} />;
};
