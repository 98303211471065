import { FC, useMemo, useState } from "react";
import { components } from "react-select";
import { OptionProps } from "react-select";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { Spacer } from "../../assets/styles/spacer.styled";
import { useCustomQuery } from "../../hooks";

import { Select, Option } from "../Select";
import { SelectWrapper, StyledSelectImage } from "./WorksheetArea.styled";
import { mapOptionsFromWorkTypes } from "./WorksheetArea.utils";
import { getWorkTypesApiUrl } from "../../api";
import { WorkTypeResponse } from "../../types/responses";
import { StyledText } from "../../assets/styles/text.styled";
import { getWorkTypeImage } from "../../pages/MapScreen/MapScreen.utils";

const CustomSelectOptionComponent = (props: OptionProps<any, any, any> | any) => (
  <StyledFlex alignItems="center">
    <StyledSelectImage width="22px" height="22px" src={getWorkTypeImage(props.data.code)} alt="option image" />
    <Spacer pl="9px" />
    <StyledText fontWeight={600} fontSize={14} color={props.isDisabled ? "#BEC0C6" : "var(--colors-mineral-green)"}>
      {props.data.label}
    </StyledText>
  </StyledFlex>
);

const CustomOption = (props: OptionProps<any, any, any>) => (
  <components.Option {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.Option>
);

const CustomSingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.SingleValue>
);

interface WorksheetAreaProps {
  readonly: boolean;
  initialWorkTypes?: WorkTypeResponse[];
  enableReloadForPredominatedWorkTypes?: () => void;
  addNewWorkType: (workType: Option) => void;
  selectedWorkTypes: string[];
  scope: Nullable<"SPAN" | "STRUCTURE">;
}

export interface WorkTypeSelect {
  workType: { label: string; value: string } | null;
}

export const WorksheetAreaControl: FC<WorksheetAreaProps> = ({
  readonly = false,
  initialWorkTypes,
  addNewWorkType,
  selectedWorkTypes,
  scope,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl(), {
    ...(initialWorkTypes ? { initialData: initialWorkTypes } : {}),
    cacheTime: 0,
    staleTime: 0,
  });

  const filteredWorkTypes = useMemo(() => {
    if (!workTypesResponse) {
      return [];
    }
    if (!scope) {
      return workTypesResponse;
    }
    return workTypesResponse.filter((workType) => workType.scope === scope);
  }, [workTypesResponse, scope]);

  return (
    <>
      <StyledFlex flexDirection="column">
        {!readonly && (
          <StyledFlex alignItems="center" className="worksheet__form">
            <SelectWrapper>
              <Select
                value={selectedValue}
                isDisabled={false}
                name="workType"
                onChange={(option) => {
                  addNewWorkType(option);
                  setSelectedValue(null);
                }}
                options={mapOptionsFromWorkTypes(filteredWorkTypes)}
                placeholder="+Add Work Type"
                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                isOptionDisabled={(item: Option) => selectedWorkTypes.find((workType) => workType === item.value)}
              />
            </SelectWrapper>
          </StyledFlex>
        )}
      </StyledFlex>
    </>
  );
};
