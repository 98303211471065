import { HYDROLOGY_LAYER_COLORS, HYDROLOGY_LAYER_NAMES } from "../../pages/MapScreen/MapView/MapView.utils";
import {
  HydrologyLegendColor,
  HydrologyLegendItem,
  HydrologyLegendItemName,
  HydrologyName,
  HydrologyWrapper,
} from "./HydrologyLegend.styled";

export const HydrologyLegend = ({ hidden }: { hidden: boolean }) => {
  if (hidden) {
    return <></>;
  }

  return (
    <HydrologyWrapper>
      <HydrologyName>
        <div>FEMA Hazard Zones</div>
        <div>100 Year Flood (1% Annual Chance)</div>
      </HydrologyName>
      <HydrologyLegendItem>
        <HydrologyLegendColor backgroundColor={HYDROLOGY_LAYER_COLORS["Floodplain"]} />
        <HydrologyLegendItemName>{HYDROLOGY_LAYER_NAMES["Floodplain"]}</HydrologyLegendItemName>
      </HydrologyLegendItem>
      <HydrologyLegendItem>
        <HydrologyLegendColor backgroundColor={HYDROLOGY_LAYER_COLORS["Floodway"]} />
        <HydrologyLegendItemName>{HYDROLOGY_LAYER_NAMES["Floodway"]}</HydrologyLegendItemName>
      </HydrologyLegendItem>
    </HydrologyWrapper>
  );
};
