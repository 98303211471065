import styled, { css } from "styled-components";
import { position, PositionProps } from "styled-system";

import { OPACITY_MAP_CONTROL } from "../../constants";
import { SelectionToolTypes } from "./SelectionTools.utils";

export const Container = styled.div<PositionProps>`
  display: flex;
  position: absolute;
  top: 157px;
  z-index: ${OPACITY_MAP_CONTROL};
  ${position}
`;

export const SelectionButton = styled.button<{
  isRectangle?: boolean;
  selectionType: SelectionToolTypes;
  isActive: boolean;
}>`
  color: black;
  background: rgba(0, 0, 0, 0.69);
  border: 1px solid black;
  border-radius: 5px;
  width: 38px;
  height: 35px;
  margin: 2px;

  :hover {
    cursor: pointer;
    ${({ isRectangle }) =>
      isRectangle
        ? css`
            > svg > path:nth-child(1) {
              stroke: #1fa7b2;
            }
          `
        : css`
            > svg > path:nth-child(4) {
              stroke: #1fa7b2;
            }
          `}
  }

  ${({ selectionType, isActive }) =>
    selectionType === "rectangle" &&
    isActive &&
    css`
      > svg > path:nth-child(1) {
        stroke: #1fa7b2;
      }
    `}

  ${({ selectionType, isActive }) =>
    selectionType === "lasso" &&
    isActive &&
    css`
      > svg > path:nth-child(4) {
        stroke: #1fa7b2;
      }
    `}
`;
