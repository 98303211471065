import styled from "styled-components";
import { color, ColorProps } from "styled-system";

import { Data2, Head2, InputTextPassive, DataLow } from "../../../assets/styles/fonts.styled";

export const ParcelStatusWrapper = styled.div`
  margin-bottom: 11px;
`;

export const ParcelStatusBodyWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 7px;
  .parcel-statuses {
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "defaults")};
    width: 100%;
    margin-left: 1px;
    height: 35px;
    .react-select__control {
      border: 1px solid #d6d6d6;
    }
    .react-select__option--is-selected {
      color: #118f9a;
      background-color: var(--colors-white);
    }
  }
`;

export const ParcelStatusItem = styled.div`
  display: flex;
  gap: 9px;
  ${Data2}
`;

export const ParcelStatusNoteBody = styled.div`
  padding-top: 18px;
`;

export const ParcelStatusNoteLabel = styled.div`
  height: 14px;
  ${Head2}
`;

export const ParcelButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InputContainer = styled.div<{ notVisible?: boolean }>`
  display: ${(props) => (props.notVisible ? "none" : "block")};
  margin-bottom: 11px;
  div {
    margin-bottom: 9px;
    div input:disabled {
      border-color: ${(props) => props.theme.colors.ebb};
      padding: 0.375rem 0.75rem;
    }
    span {
      text-align: start;
    }
  }
`;

export const ParcelContactFormWrapper = styled.div`
  margin-bottom: 11px;
  margin-top: 20px;
`;

export const ParcelStatusButtonBodyStatus = styled.div<ColorProps>`
  ${color}
  width: 16px;
  height: 7px;
  border-radius: 30px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.09);
  margin-right: 10px;
`;

export const CheckboxWrapper = styled.div`
  margin: 14px 0;
  span {
    ${InputTextPassive}
    color: var(--colors-black);
    margin-left: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  .save_cancel_wrapper {
    padding: 15px 0 25px 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
  }
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  button {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5px;
    padding: 5px;
  }
  .edit-icon {
    padding-top: 2px;
  }
`;

export const SectionFormLabel = styled.div`
  ${DataLow}
`;
