import { FC } from "react";

import { Error } from "../../components/Error";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constants";
import { getLandingRoute } from "../../routes";

export const GeneralError: FC = () => {
  return (
    <Error
      message={SOMETHING_WENT_WRONG_MESSAGE}
      onClick={() => window.location.assign(getLandingRoute())}
      btnLabel="Try Again"
    />
  );
};
