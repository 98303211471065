import React, { FC, useRef, useState, useEffect } from "react";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as EditPen } from "../../assets/images/edit_pen.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as Trash } from "../../assets/images/remove.svg";
import {
  StyledButton,
  Wrapper,
  CategoryButton,
  SubcategoryWrapper,
  ComponentTitle,
  Marker,
  CloseButton,
  CategoriesWrapper,
} from "./AddAlertPopup.styled";
import { getCategoryIcon, getSubcategoryIcon, getCategoryNameFromCode, sortByOrderNum } from "./AlertPopup.util";
import { AlertsCategoryDto, AlertDto } from "../../types/responses/Alerts";
import { UpdateOpts } from "../../hooks";
import { IconButton } from "../IconButton";

const intersectionOptions = {
  root: document.querySelector("#categories"),
  rootMargin: "0px",
  threshold: [0, 0.5, 1],
};

const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
  const elInViewport = entries.find((entry) => entry.isIntersecting && entry.intersectionRatio > 0.5);
  if (!elInViewport) return;

  document?.querySelector(".category.isSelected")?.classList.remove("isSelected");
  const selectedCategory = document?.querySelector(`[aria-label="${elInViewport.target.id}"]`);
  selectedCategory?.classList.add("isSelected");
};
interface IProps {
  categories: AlertsCategoryDto[];
  createAlert?: (categoryId: string) => void;
  deleteAlert: (alertId: string) => void;
  editAlert: (alertId: string, updateOptions: UpdateOpts) => void;
  item?: AlertDto;
  updatePopup?: () => void;
  closePopup?: () => void;
}

export const AlertPopup: FC<IProps> = ({
  categories,
  createAlert,
  deleteAlert,
  editAlert,
  updatePopup,
  closePopup,
  item,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isEdit, setIsEdit] = useState(!!item);
  const [canEdit, setCanEdit] = useState(false);

  const onSubcategoryClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, categoryId: string) => {
    // Remove previously selected subcategory class
    document
      ?.querySelectorAll(".subcategory.isSelected")
      ?.forEach((item: Element) => item?.classList.remove("isSelected"));
    // Add currently selected subcategory class
    (e.target as Element).classList.add("isSelected");
    if (canEdit) {
      editAlert(item?.id!, { categoryId });
    } else {
      createAlert && createAlert(categoryId);
    }
  };

  const renderIcon = (subCode: string) => {
    const Icon = getSubcategoryIcon(subCode);
    return <Icon />;
  };

  useEffect(() => {
    if (updatePopup && !isEdit) updatePopup();
  }, [isEdit, updatePopup]);

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, intersectionOptions);
    document.querySelectorAll("[data-code]")?.forEach((item) => observer.observe(item));
    // Initialy, select first tab item
    (tabsRef?.current?.firstChild as HTMLElement)?.classList.add("isSelected");

    return () => {
      observer.disconnect();
    };
  }, [isEdit]);

  if (categories.length === 0) return null;

  return (
    <>
      <Wrapper>
        {isEdit && (
          <>
            <StyledFlex justifyContent="space-between" alignItems="center" my="10px">
              <ComponentTitle>Alert</ComponentTitle>
              <CloseButton Icon={Close} onClick={closePopup} />
            </StyledFlex>
            <StyledFlex alignItems="center" gap="15px" my="20px">
              {renderIcon(item?.categoryCode!)}
              <span>{getCategoryNameFromCode(item?.categoryCode!)}</span>
            </StyledFlex>
            <StyledFlex justifyContent="space-between" mt="20px">
              <StyledButton className="delete" Icon={Trash} text="Delete" onClick={() => deleteAlert(item?.id!)} />
              <StyledButton
                className="primary edit"
                Icon={EditPen}
                text="Edit"
                onClick={() => {
                  setIsEdit(false);
                  setCanEdit(true);
                }}
              />
            </StyledFlex>
          </>
        )}
        {!isEdit && (
          <>
            <StyledFlex flexDirection="column" gap="5px" padding="5px">
              <StyledFlex justifyContent="space-between" alignItems="center" my="10px">
                <ComponentTitle>Alerts</ComponentTitle>
                <CloseButton Icon={Close} onClick={closePopup} />
              </StyledFlex>
              <StyledFlex justifyContent="space-between" alignItems="center" ref={tabsRef}>
                {categories.sort(sortByOrderNum).map((item) => (
                  <IconButton
                    as="a"
                    href={`#${item.code}`}
                    key={item.id}
                    Icon={getCategoryIcon(item.code)}
                    className="popupIcon category"
                    aria-label={item.code}
                  />
                ))}
              </StyledFlex>
            </StyledFlex>
            <CategoriesWrapper flexDirection="column" id="categories">
              {categories.map((item) => (
                <StyledFlex key={item.id} id={item.code} flexDirection="column" marginTop="20px" data-code={item.code}>
                  <StyledFlex alignItems="center" gap="5px">
                    <CategoryButton Icon={getCategoryIcon(item.code)} />
                    <span>{item.name}</span>
                  </StyledFlex>
                  <StyledFlex flexDirection="column" gap="5px">
                    {item.subCategories.sort(sortByOrderNum).map((subCat) => (
                      <SubcategoryWrapper
                        key={subCat.id}
                        id={subCat.id}
                        Icon={getSubcategoryIcon(subCat.code)}
                        text={subCat.name}
                        className="subcategory"
                        onClick={(e) => onSubcategoryClick(e, subCat.id)}
                      />
                    ))}
                  </StyledFlex>
                </StyledFlex>
              ))}
            </CategoriesWrapper>
          </>
        )}
      </Wrapper>
      {!item && <Marker />}
    </>
  );
};
