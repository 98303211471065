import create from "zustand";

import { TreeCanopy, TreeCanopyResponse } from "../types/responses/TreeCanopyResponse";

export type TreeCanopyById = Record<string, TreeCanopy>;

const INITIAL_OPACITY = 0.8;

export type TreeCanopyStore = {
  byId: Nullable<TreeCanopyById>;
  geoJSON: Nullable<TreeCanopyResponse>;
  setData: (treeCanopyData: Nullable<TreeCanopyResponse>) => void;
  selectCanopyId: (id: Nullable<string>) => void;
  selectedCanopyId: Nullable<string>;
  layerVisible: boolean;
  toggleLayer: () => void;
  setLayerState: (newState: boolean) => void;
  initialOpacity: number;
  setOpacity: (opacity: number) => void;
};
const INITIAL_STATE = {
  byId: null,
  geoJSON: null,
  selectedCanopyId: null,
  layerVisible: false,
  initialOpacity: INITIAL_OPACITY,
};

export const useTreeCanopyStore = create<TreeCanopyStore>((set, get) => ({
  ...INITIAL_STATE,
  setData: (treeCanopyData: Nullable<TreeCanopyResponse>) => {
    if (treeCanopyData?.features?.length) {
      set(mapTreeCanopyResponse(treeCanopyData));
    }
  },
  selectCanopyId: (id) => {
    if (id === get().selectedCanopyId) {
      return;
    }

    set({ selectedCanopyId: id });
  },
  toggleLayer: () => {
    const newVisibility = !get().layerVisible;
    set({ layerVisible: newVisibility, initialOpacity: newVisibility ? get().initialOpacity : INITIAL_OPACITY });
  },
  setLayerState: (state) => {
    if (get().layerVisible === state) {
      return;
    }

    set({ layerVisible: state, initialOpacity: state ? get().initialOpacity : INITIAL_OPACITY });
  },
  setOpacity: (opacity) => {
    if (get().initialOpacity === opacity) {
      return;
    }
    set({ initialOpacity: opacity });
  },
}));

export const mapTreeCanopyResponse = (geoJSON: TreeCanopyResponse) => {
  const byId: TreeCanopyById = geoJSON.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr;

    return prev;
  }, {} as TreeCanopyById);

  return {
    byId,
    geoJSON,
  };
};
