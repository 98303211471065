export const FEEDER_STYLE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 3,
  opacity: 1,
  color: "#3CE3FF",
};

export const FEEDER_STYLE_SELECTED = {
  fillColor: "#FDCA14",
  fillOpacity: 1,
  weight: 3,
  color: "#FFFFFF",
};

export const FEEDER_STYLE_HOVER = {
  color: "#FDCA14",
  weight: 3,
};
