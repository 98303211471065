export const ENCROACHMENT_STYLE = {
  fillOpacity: 0.5,
  opacity: 0.5,
  weight: 2,
  fill: true,
  color: "rgba(255,255,255,0.5)",
};

export const ENCROACHMENT_STYLE_SELECTED = {
  fillOpacity: 0.8,
  opacity: 0.8,
  weight: 2,
  fill: true,
};
