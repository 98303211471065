import styled from "styled-components";
import { space, PaddingProps, padding } from "styled-system";

import { MODAL } from "../../constants/z-index";

export const ModalOverlay = styled.div`
  ${space}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: ${MODAL};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div<PaddingProps>`
  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeInFromNone 0.3s ease;
  box-sizing: border-box;
  padding-bottom: 15px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  z-index: ${MODAL};
  padding-left: 68px;
  padding-right: 68px;
  padding-top: 35px;
  padding-bottom: 35px;
  ${padding}
  width: 551px;
  max-width: 551px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .admin-modal-enter {
    opacity: 0;
  }
  .admin-modal-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .admin-modal-exit {
    opacity: 1;
  }
  .admin-modal-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h2`
  font-size: 30px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.manatee};
`;

export const ModalClose = styled.div`
  background-color: ${(props) => props.theme.colors.easternBlue};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
`;

export const ModalMessage = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--colors-trout);
  padding-top: 53px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: initial;
`;

export const WarningImg = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
  left: 68px;
  top: 47px;
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 15px;
  justify-content: flex-end;
  left: 30px;
`;

export const ButtonWrapper = styled.div<{ inlineButton?: boolean }>`
  display: flex;
  align-items: center;
  button:last-child {
    margin-left: 9px;
  }
  justify-content: ${(props) => (props.inlineButton ? "flex-end" : "center")};
  button {
    ${(props) => (props.inlineButton ? "margin-top: 0px;margin-right: 5px; " : "")}
  }
`;
