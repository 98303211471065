import { FC, memo } from "react";
import { PositionProps, PaddingProps } from "styled-system";

import { getLandingRoute } from "../../routes";
import { VantageLogoStyled, StyledLink, LogoWrapper } from "./VantageLogo.styled";
import { ReactComponent as Logo } from "../../assets/images/lumada_logo.svg";

interface VantageLogoProps extends PositionProps, PaddingProps {
  variant?: "dark" | "white";
  hidden?: boolean;
}

const isLumadaWebContext = process.env.REACT_APP_WEB_CONTEXT === "lumada";

export const VantageLogo: FC<VantageLogoProps> = memo(({ variant = "dark", hidden = false, ...props }) => {
  const imageUrl = variant === "dark" ? "vantage_logo_dark.png" : "vantage_logo_white.png";

  if (hidden) {
    return <></>;
  }

  if (isLumadaWebContext) {
    return (
      <StyledLink to={getLandingRoute()} {...props}>
        <LogoWrapper alignItems="center" gap="5px">
          <Logo />
          <span className="delimiter">|</span>
          <span>Cut Planner</span>
        </LogoWrapper>
      </StyledLink>
    );
  }

  return (
    <StyledLink to={getLandingRoute()} {...props}>
      <VantageLogoStyled src={`${process.env.PUBLIC_URL}/${imageUrl}`} alt="Vantage Logo" {...props} />
    </StyledLink>
  );
});
