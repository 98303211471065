import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { plansUrl } from "../api";
import { logError } from "../services";

interface Plan {
  planDetails: {
    planName: string;
    start: string;
    end: string;
    status: string;
  };
  planBudget: {
    totalBudget: number;
    budgetByRegions: { budget: number; regionGroupId: string }[];
  };
  planCrew: {
    crewsByRegion: {
      crewId: string;
      regionGroupId: string;
    }[];
  };
}

export const useCreatePlan = () => {
  const queryClient = useQueryClient();
  return useMutation(({ plan }: { plan: Plan }) => ApiClient.post(plansUrl, plan), {
    onSuccess: () => queryClient.invalidateQueries("plans"),
    onError: (e) => logError(e),
  });
};

export default useCreatePlan;
