import styled, { css } from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)<{ isClickable: boolean }>`
  border: 1px solid #ebeaea;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  background-color: var(--colors-white);
  padding: 10px;
  flex-direction: column;
  gap: 5px;

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        border-color: var(--colors-eastern-blue);
      }
    `}
`;

export const Name = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262c2d;
`;

export const Date = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #6a6c71;
`;
