import { FC } from "react";

import {
  Wrapper,
  SecondLevelHeader,
  HeaderContainer,
  BackImageWrapper,
  LeftSide,
  CloseImageWrapper,
  CloseIcon,
  ChildrenContent,
  ItemsWrapper,
} from "./SecondLevel.styled";
import { ReactComponent as LeftArrow } from "../../../../assets/images/arrow_l.svg";
import { ReactComponent as Close } from "../../../../assets/images/close_custom.svg";
import { InputUpload } from "../../../../components/InputUpload";

type SecondLevelMenuProps = {
  name: string;
  actionName: string;
  onActionNameClick: () => void;
  actionDisabled: boolean;
  uploader: boolean;
  closeDrawer: () => void;
  onBack: () => void;
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SecondLevelMenu: FC<SecondLevelMenuProps> = ({
  name,
  actionName,
  onActionNameClick,
  actionDisabled,
  uploader,
  closeDrawer,
  onBack,
  onUpload,
  children,
}) => {
  return (
    <Wrapper>
      <SecondLevelHeader>
        <HeaderContainer>
          <BackImageWrapper onClick={onBack}>
            <LeftArrow />
          </BackImageWrapper>
          <LeftSide id="rhs-title">{name}</LeftSide>
          {onUpload && <InputUpload actionName={actionName} width={"60px"} onUpload={onUpload} />}
          <CloseImageWrapper onClick={closeDrawer}>
            <CloseIcon>
              <Close />
            </CloseIcon>
          </CloseImageWrapper>
        </HeaderContainer>
      </SecondLevelHeader>
      <ChildrenContent>
        <ItemsWrapper>{children} </ItemsWrapper>
      </ChildrenContent>
    </Wrapper>
  );
};
