import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const ErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  align-items: center;
  background-color: #fbfcfd;
`;

export const ErrorTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.trout2};
`;

export const ErrorCode = styled.h2`
  font-weight: 500;
  font-size: 72px;
  margin: 0px;
  color: #07a6b4;
`;

export const ErrorMessage = styled.span<SpaceProps>`
  ${space};
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.trout2};
`;
