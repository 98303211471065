import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

export const Wrapper = styled(StyledFlex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 502px;
  max-height: 280px;
  transform: translateY(40px);
  background-color: var(--colors-white);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 2;
  color: var(--colors-black);

  * {
    box-sizing: border-box;
  }
`;

export const MainContent = styled(StyledFlex)`
  padding: 20px 0 15px;
  overflow-y: scroll;

  > div:first-child {
    border-right: 1px solid #efefef;
  }

  > div {
    flex: 1;
  }

  & + div {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: var(--colors-black);
  line-height: 1;

  & + span {
    font-weight: 600;
    font-size: 12px;
    color: var(--colors-black);
  }
`;

export const CrewName = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 7px 50px;
  position: relative;

  &:hover {
    background-color: rgba(12, 178, 192, 0.1);
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #3f4952;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(25px, -50%);
  }

  path {
    fill: #3f4952;
  }
`;
