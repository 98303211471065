import { FC, useCallback, useMemo } from "react";

import { SectionItem } from "../../../../components/RightDrawer/DrawerSectionItems/SectionItem";
import { DrawerSection } from "../../../../components/RightDrawer/DrawerSectionItems/DrawerSection";
import { ContentType } from "../../../../stores/DrawerStore/DrawerStore.types";
import { SectionLinks } from "../../../../components/RightDrawer/DrawerSectionItems/SectionLinks";
import { SectionData, SectionLinks as SectionLinksType } from "../RightDrawerMenu.utils";
import { LABELS } from "../../../../constants";
import { SectionRiskPriority } from "../../../../components/RightDrawer/DrawerSectionItems/SectionRiskPriority";
import { TreeSpecies } from "../../../../components/RightDrawer/DrawerSectionItems/TreeSpecies";
import { SpecialLayers } from "../../../../components/RightDrawer/DrawerSectionItems/SpecialLayers";
import { getAllWorksheetsForThreatUrl, getThreatWorksheetApiUrl } from "../../../../api";
import { useCustomQuery } from "../../../../hooks";
import { useMutation } from "react-query";
import { UpdateThreatWorksheetRequestDto, WorksheetResponse, ThreatsWorksheetDto } from "../../../../types/responses";
import ApiClient from "../../../../utils/apiClient";
import { logError } from "../../../../services";
import { WorksheetAllArea } from "../../../../components/WorksheetArea/WorksheetAllArea";
import { calculateSubItem } from "../../../../components/SelectionToolRHS/SelectionToolRHS.utils";
import { PushoutRequiredWrapper } from "../RightDrawerMenu.styled";
import { ReactComponent as PushoutYes } from "./../../../../assets/images/check.svg";
import { ReactComponent as PushoutNo } from "./../../../../assets/images/close.svg";
import { SectionLabelWrapper } from "../../../../components/RightDrawer/DrawerSectionItems/DrawerSectionItems.styled";
import { LayerRow } from "../../../../types/Layers";

export const InfringementInfoSection: FC<{
  data: SectionData;
  openHyperlink?: (type: ContentType, id: string) => void;
  specialLayers?: Nullable<LayerRow[]>;
  setSpecialLayer: (id: string, value: boolean) => void;
  specialLayersState: Nullable<Record<string, boolean>>; //is layer checked: by layer key
  enableReloadForPredominatedWorkTypes: () => void;
}> = ({ data, openHyperlink, specialLayers, setSpecialLayer, specialLayersState }) => {
  const {
    data: worksheetThreats,
    isLoading: isWorksheetLoading,
    refetch: refetchWorksheet,
  } = useCustomQuery<ThreatsWorksheetDto>(`${getAllWorksheetsForThreatUrl(data?.id as string)}`, {
    enabled: !!data?.id,
  });

  const saveAllChanges = useMutation((value: UpdateThreatWorksheetRequestDto) =>
    ApiClient.put(getThreatWorksheetApiUrl(data?.id as string), value)
  );

  const worksheetData: Nullable<WorksheetResponse> = useMemo(() => {
    if (!worksheetThreats?.worksheets?.length || !data?.id) {
      return null;
    }

    const items = worksheetThreats.worksheets.find((item) => item.threatId === (data?.id as string));
    return {
      items: items ? items.items : [],
      totalCost: calculateSubItem(items?.items ?? []),
    };
  }, [worksheetThreats, data]);

  const invokeUpdateWorksheet = useCallback(
    (value: string[]) => {
      return saveAllChanges
        .mutateAsync({ items: value.map((val) => ({ workTypeId: val })) })
        .then(() => {
          refetchWorksheet();
        })
        .catch((error) => logError(error));
    },
    [saveAllChanges, refetchWorksheet]
  );

  return (
    <>
      <DrawerSection name="RISK INFO">
        <SectionItem name={LABELS.riskPriority}>
          <SectionRiskPriority color={data?.riskPriorityColor as string} priority={data?.riskPriority as string} />
        </SectionItem>
        <SectionItem name={LABELS.treeCount}>{data?.treeCount}</SectionItem>
        <SectionItem name="Tree Height Avg">{data?.treeHeightAvg}</SectionItem>
        <SectionItem name="Tree Height Max">{data?.treeHeightMax}</SectionItem>
        <SectionItem name={LABELS.pushoutRequired}>
          <SectionLabelWrapper>{data?.pushout} </SectionLabelWrapper>
          <PushoutRequiredWrapper>
            {data?.pushout === "YES" && <PushoutYes className="pushout-yes" />}
            {data?.pushout === "NO" && <PushoutNo className="pushout-no" />}
          </PushoutRequiredWrapper>
        </SectionItem>
        <SectionItem name={LABELS.specialLayers} hasBorder={false} bold={true}>
          {!data?.treeCount ? "N/A" : ""}
        </SectionItem>
        <SpecialLayers
          specialLayers={specialLayers}
          setSpecialLayer={setSpecialLayer}
          specialLayersState={specialLayersState}
        ></SpecialLayers>
        <SectionItem name={LABELS.treeSpecies}>{!data?.treeSpecies ? "N/A" : ""}</SectionItem>
        <TreeSpecies species={data?.treeSpecies as string[]} />
      </DrawerSection>
      <DrawerSection name="Worksheet">
        <WorksheetAllArea
          readonly={false}
          scope="INFRINGEMENT"
          invokeUpdateWorksheet={invokeUpdateWorksheet}
          worksheetData={worksheetData}
          isWorksheetLoading={isWorksheetLoading}
        />
      </DrawerSection>
      <DrawerSection name="INFRINGEMENT INFO">
        <SectionItem name="Infringement ID">{data?.infringementId}</SectionItem>
        <SectionItem name="Affected Circuits">
          <SectionLinks data={data?.circuits as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name={LABELS.elevation}>{data?.elevation}</SectionItem>
        <SectionItem name={LABELS.demographic}>{data?.demographic}</SectionItem>
        <SectionItem name={LABELS.coordinates}>{data?.coordinates}</SectionItem>
      </DrawerSection>
    </>
  );
};
