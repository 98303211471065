import styled from "styled-components";
import { Data3Underline } from "../../assets/styles/fonts.styled";

export const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px 0;
`;

export const ItemWrapper = styled.div`
  justify-content: space-between;
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const AllAttachmentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${Data3Underline}
  text-decoration: none;
  margin-top: 20px;
  cursor: pointer;
  svg {
    transform: rotate(-90deg);
  }
`;
