import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { getDeleteTreeMarkerUrl } from "../api";
import { TreeMarkers, TreeMarkerUpdate } from "../types/responses";
import { QUERY_KEY } from "../pages/MapScreen/MapView/TreeMarkerView.utils";

export const useEditTreeMarker = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ markerID, value }: { markerID: string; value: TreeMarkerUpdate }) =>
      ApiClient.put(getDeleteTreeMarkerUrl(markerID), value),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_KEY),
      onMutate: (params: { markerID: string; value: TreeMarkerUpdate }) => {
        //optimistic UI
        const previousData: Maybe<TreeMarkers> = queryClient.getQueryData(QUERY_KEY);
        const newData = {
          markers: previousData?.markers.map((item) =>
            item.id === params.markerID
              ? {
                  ...item,
                  geometry: params.value.geometry ?? item.geometry,
                  radius: params.value.radiusMeters ?? item.radius,
                }
              : item
          ),
        };

        queryClient.setQueryData(QUERY_KEY, newData);
        return { newData, params };
      },
    }
  );
};
