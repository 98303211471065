import { useMemo } from "react";
import { useQuery } from "react-query";
import { getWorkTypesApiUrl } from "../../../../../api";
import { logError } from "../../../../../services";
import { WorkTypeResponse } from "../../../../../types/responses";
import {
  CrewTypeResponse,
  CrewTypeWithMembersAndEquipment,
  EquipmentTypeResponse,
  MemberTypeResponse,
} from "../../../../../types/responses/Crews";
import ApiClient from "../../../../../utils/apiClient";

export const useCrewWithMembersAndEquipment = () => {
  const { data: crewTypeData, isLoading: loadingCrewTypes } = useQuery(
    "crew-types",
    () => ApiClient.get<CrewTypeResponse>("crew-types").then((response) => response.data.crewTypes),
    {
      onError: (e) => logError(e),
    }
  );

  const { data: memberTypeData, isLoading: loadingMemberTypes } = useQuery(
    "crew-member-types",
    () => ApiClient.get<MemberTypeResponse>("crew-member-types").then((response) => response.data.crewMemberTypes),
    {
      onError: (e) => logError(e),
    }
  );

  const { data: equipmentTypeData, isLoading: loadingEquipment } = useQuery(
    "equipment-types",
    () => ApiClient.get<EquipmentTypeResponse>("equipment-types").then((response) => response.data.equipmentTypes),
    {
      onError: (e) => logError(e),
    }
  );

  const { data: workTypes, isLoading: loadingWorkTypes } = useQuery(
    "work-types",
    () => ApiClient.get<WorkTypeResponse[]>(getWorkTypesApiUrl()).then((response) => response.data),
    {
      onError: (e) => logError(e),
    }
  );

  const data: Nullable<CrewTypeWithMembersAndEquipment[]> = useMemo(() => {
    if (!equipmentTypeData || !memberTypeData || !crewTypeData || !workTypes) {
      return null;
    }
    return crewTypeData.map((crewType) => {
      return {
        ...crewType,
        equipmentTypes: equipmentTypeData.filter((equipment) =>
          crewType.equipmentTypeIds.find((id) => id === equipment.id)
        ),
        crewMemberTypes: memberTypeData.filter((member) => crewType.crewMemberTypeIds.find((id) => id === member.id)),
        workTypes: workTypes.filter((workType) => crewType.workTypeIds.find((id) => id === workType.id)),
      };
    });
  }, [equipmentTypeData, memberTypeData, crewTypeData, workTypes]);

  return { loading: loadingCrewTypes || loadingEquipment || loadingMemberTypes || loadingWorkTypes, data };
};
