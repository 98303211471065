import { FC, useEffect } from "react";
import { MapComments } from "../../../components/MapComments";

import { COMMENTS_LAYER } from "../../../constants/z-index";
import { useComments } from "../../../hooks";
import useCommentsStore from "../../../stores/CommentStore";
import useCwcStore from "../../../stores/MapCwcStore";
import { CommentDrawer } from "../CommentDrawer";

export const Comments: FC<{ map: L.Map }> = ({ map }) => {
  const comments = useComments();
  const isLayerVisible = useCommentsStore((store) => store.isLayerVisible);
  const commentsData = useCommentsStore((store) => store.data);
  const filter = useCommentsStore((store) => store.filter);
  const editModeEnabled = useCwcStore((store) => store.isEditEnabled);
  const selectedCommentId = useCommentsStore((store) => store.selectedMarkerId);
  const toggleComments = useCommentsStore((store) => store.toggleLayer);
  const setCommentId = useCommentsStore((store) => store.setMarkerId);

  useEffect(() => {
    if (!isLayerVisible) {
      return;
    }

    comments.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayerVisible, filter]);

  useEffect(() => {
    if (!map || isLayerVisible) {
      return;
    }

    map.closePopup();
  }, [map, isLayerVisible]);

  useEffect(() => {
    if (!editModeEnabled) {
      return;
    }

    isLayerVisible && toggleComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModeEnabled, isLayerVisible]);

  const resetCommentID = () => setCommentId(null);

  return (
    <>
      {isLayerVisible && (
        <MapComments
          reloadComments={() => comments.mutate()}
          hidden={!isLayerVisible}
          commentData={commentsData}
          map={map}
          selectedMarkerId={selectedCommentId}
          zIndex={COMMENTS_LAYER}
          resetCommentID={resetCommentID}
        />
      )}

      <CommentDrawer hidden={!isLayerVisible} reloadComments={() => comments.mutate()} />
    </>
  );
};
