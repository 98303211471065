import { FC, memo, useEffect } from "react";
import { PositionProps } from "styled-system";

import { Portal } from "../Portal";
import { MapControlsWrapper, Control } from "../MapControl";

interface MapZoomControlProps extends PositionProps {
  map: L.Map;
}

interface LayerProps {
  id?: string;
  map: L.Map;
  layer?: L.Layer;
  checked: boolean;
  onClick: (layerName: string) => void;
  name: string;
}

export const Layer: FC<LayerProps> = memo(({ id, map, layer, checked, onClick, name, children }) => {
  useEffect(() => {
    if (!map || !layer) {
      return;
    }
    if (!checked) {
      map.removeLayer(layer);
    }
    if (checked) {
      map.addLayer(layer);
    }
  }, [map, checked, layer]);
  return (
    <Control id={id} checked={checked} onClick={() => onClick(name)}>
      {children}
    </Control>
  );
});

export const MapLayerControl: FC<MapZoomControlProps> = memo(({ map, children, ...props }) => (
  <Portal selector="layer-menu-root">
    <MapControlsWrapper {...props}>{children}</MapControlsWrapper>
  </Portal>
));
