import { GeoJSON } from "react-leaflet";
import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { useCustomMapPane } from "../../hooks";

import { Region, RISK_PRIORITY } from "../../types/responses";
import { getColorForRegion } from "./MapTopLevelRegions.utils";
import { useMapTooltip } from "../../hooks/useMapTooltip";
import { getRiskPriorityColor } from "../../pages/MapScreen/MapView/MapView.utils";

interface MapTopLevelRegionsProps {
  hidden?: boolean;
  hoveredObjectId: Nullable<string>;
  interactive?: boolean;
  map: Nullable<L.Map>;
  onClick: (e: unknown, id: string) => void;
  regions: Region[];
  zIndex?: number;
}

const REGIONS_PANE_NAME = "regions-pane";

export const MapTopLevelRegions: FC<MapTopLevelRegionsProps> = memo(
  ({ regions, onClick, zIndex = 400, map, hoveredObjectId, interactive = false, hidden = false }) => {
    const { onMouseMove, onMouseOut, closeTooltip } = useMapTooltip({ layer: map, showDelay: 100 });

    useEffect(() => {
      if (interactive) {
        return;
      }
      closeTooltip();
    }, [closeTooltip, interactive]);

    useCustomMapPane({
      name: REGIONS_PANE_NAME,
      zIndex: zIndex,
      map,
    });

    const onHoverForRegion = useCallback(
      (e, add: boolean, riskPriority: RISK_PRIORITY) => {
        if (!map || !e?.target) {
          return;
        }
        const layer = e.target;
        layer.setStyle(
          getColorForRegion({
            color: getRiskPriorityColor(riskPriority),
            hovered: add,
            interactive,
          })
        );
      },
      [map, interactive]
    );

    const onEachFeature = useCallback(
      (_feature, layer: L.Layer, priority: RISK_PRIORITY, name: string, id: string) => {
        layer.on({
          click: (e) => onClick(e, id),
          mouseover: (e) => onHoverForRegion(e, true, priority),
          mouseout: (e) => {
            onHoverForRegion(e, false, priority);
            onMouseOut();
          },
          mousemove: (e: L.LeafletMouseEvent) => onMouseMove(e, name),
        });
      },
      [onClick, onHoverForRegion, onMouseMove, onMouseOut]
    );

    const renderedRegions = useMemo(() => {
      if (!regions || regions?.length === 0) {
        return <></>;
      }

      return regions.map((item: Region) => (
        <GeoJSON
          key={uuidv4()} // re-render on every deps change
          style={getColorForRegion({
            color: getRiskPriorityColor(item?.properties.riskPriority?.priority ?? RISK_PRIORITY.LOW),
            hovered: item.properties.id === hoveredObjectId,
            interactive,
          })}
          data={item.geometry}
          onEachFeature={(feature, layer) => {
            if (!interactive) return;
            onEachFeature(
              feature,
              layer,
              item.properties?.riskPriority?.priority ?? RISK_PRIORITY.LOW,
              item.properties.name,
              item.properties.id
            );
          }}
          pane={REGIONS_PANE_NAME}
          interactive={interactive}
        />
      ));
    }, [onEachFeature, regions, hoveredObjectId, interactive]);

    if (hidden) {
      return <></>;
    }

    return <>{renderedRegions}</>;
  }
);
