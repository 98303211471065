import { Feature, Polygon } from "geojson";
import { LatLngBoundsExpression, LatLngExpression } from "leaflet";
import create from "zustand";

import { EMPTY_FN } from "../constants";
import { EncroachmentPositions } from "../pages/MapScreen/MapScreen.utils";
import { RISK_PRIORITY } from "../types/responses";
import { SingleThreatResponse, ThreatResponse, ThreatsProperties } from "../types/responses/EncroachmentsResponse";
import MapHelper from "../utils/mapHelper";

export type ThreatsStore = {
  actions: {
    addThreat: (id: string, record: SingleThreatResponse) => void;
    selectThreat: (threatId: Nullable<string>) => void;
    setThreats: (threats: Nullable<ThreatResponse>) => void;
    updateThreatById: (id: string, newCoordinates: any) => void;
    deleteThreatById: (id: string) => void;
    addThreatFromCWC: (threat: Feature<Polygon, ThreatsProperties>) => void;
    updateThreatRiskPriority: (id: string, riskPriority: string) => void;
  };
  threatsPosition: Nullable<EncroachmentPositions>;
  selectedThreat: Nullable<string>;
  threatsData: Nullable<Record<string, SingleThreatResponse>>;
  threats: Nullable<ThreatResponse>;
};
const INITIAL_STATE = {
  selectedThreat: null,
  threatsPosition: {},
  threatsData: {},
  threats: null,
  actions: {
    addThreat: EMPTY_FN,
    selectThreat: EMPTY_FN,
    setThreats: EMPTY_FN,
    updateThreatById: EMPTY_FN,
    deleteThreatById: EMPTY_FN,
    addThreatFromCWC: EMPTY_FN,
    updateThreatRiskPriority: EMPTY_FN,
  },
};

export const useThreatsStore = create<ThreatsStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    addThreat: (id: string, record: SingleThreatResponse) => {
      const threats = { ...get().threatsData };
      const threatsPosition = { ...get().threatsPosition };
      if (!threatsPosition[record.threat.properties.id]) {
        threatsPosition[record.threat.properties.id] = getPosition(record.threat);
      }
      threats[id] = record;
      set({ threatsData: threats, threatsPosition });
    },
    selectThreat: (id) => {
      if (get().selectedThreat === id) {
        return;
      }
      set({ selectedThreat: id });
    },
    setThreats: (threats) => {
      const threatsPosition = { ...get().threatsPosition };
      threats?.features?.forEach((feature) => {
        if (!threatsPosition[feature.properties.id]) {
          threatsPosition[feature.properties.id] = getPosition(feature);
        }
      });
      set({ threats, threatsPosition: threatsPosition });
    },
    updateThreatById: (id, newCoordinates) => {
      const threats = { ...get().threats };
      if (!threats) return;
      threats.features =
        threats?.features?.map((threat) =>
          threat.properties.id === id
            ? {
                ...threat,
                geometry: {
                  ...threat.geometry,
                  coordinates: newCoordinates,
                },
              }
            : threat
        ) ?? [];

      set({ threats: { type: "FeatureCollection", features: threats.features } });
    },
    deleteThreatById: (id) => {
      const threats = { ...get().threats };
      if (!threats?.features) return;
      threats.features = threats?.features?.filter((encroachment) => encroachment.properties.id !== id) ?? undefined;
      set({ threats: { type: "FeatureCollection", features: threats.features } });
    },
    addThreatFromCWC: (threat) => {
      const threats = { ...get().threats };
      const threatsPosition = { ...get().threatsPosition } ?? {};
      threats.features = threats?.features ? threats.features.concat(threat) : [threat];
      threatsPosition[threat.properties.id] = getPosition(threat);
      set({ threats: { type: "FeatureCollection", features: threats.features ?? [] }, threatsPosition });
    },
    updateThreatRiskPriority: (id, priority) => {
      const threats = { ...get().threats };
      const threatsData = { ...get().threatsData };
      if (!threats?.features) return;
      if (threatsData[id]) {
        threatsData[id].threat.properties.riskPriority = priority as RISK_PRIORITY;
        set({ threatsData });
      }
      threats.features = threats.features.map((item) =>
        item.properties.id === id
          ? {
              ...item,
              properties: { ...item.properties, riskPriority: priority as RISK_PRIORITY },
            }
          : item
      );
      set({ threats: { type: "FeatureCollection", features: threats.features } });
    },
  },
}));

const getPosition = (
  feature: Feature<GeoJSON.Geometry, unknown>
): { center: Nullable<LatLngExpression>; bounds: Nullable<LatLngBoundsExpression> } => {
  const encroachmentCenter = MapHelper.centerOfMass(true, [feature]);
  return {
    center: [encroachmentCenter.geometry.coordinates[1], encroachmentCenter.geometry.coordinates[0]],
    bounds: MapHelper.normalizeBounds(MapHelper.bBox(true, [feature])),
  };
};

export default useThreatsStore;
