import React, { memo } from "react";

import { getWorkTypeColor } from "../../pages/MapScreen/MapScreen.utils";
import { WorkTypeState } from "../../stores";
import { Checkbox } from "../Checkbox";
import {
  ExpandCollapseWorkTypeLegend,
  MinMaxImage,
  WorkTypeItem,
  WorkTypeItemColor,
  WorkTypeLegendBody,
  WorkTypeLegendHeader,
  WorkTypeLegendName,
  WorkTypeLegendNameWrapper,
  WorkTypeLegendSelectAll,
  WorkTypeLegendWrapper,
} from "./WorkTypeLegend.styled";
import Maximize from "./../../assets/images/maximize_arrow.svg";
import Minimize from "./../../assets/images/minimize.svg";

export const WorkTypeLegend = memo(
  ({
    hidden,
    workTypes,
    selectAll,
    toggleWorkType,
    leftMenuHidden,
    expanded,
    toggleLegend,
    selectedAll,
    bottom,
  }: {
    hidden: boolean;
    workTypes: Nullable<Record<string, WorkTypeState>>;
    selectAll: () => void;
    toggleWorkType: (id: string) => void;
    leftMenuHidden: boolean;
    expanded: boolean;
    toggleLegend: () => void;
    selectedAll: boolean;
    bottom?: number;
  }) => {
    if (hidden) {
      return <></>;
    }

    return (
      <WorkTypeLegendWrapper expanded={expanded} leftMenuHidden={leftMenuHidden} bottom={bottom}>
        <WorkTypeLegendHeader expanded={expanded}>
          <WorkTypeLegendNameWrapper>
            <WorkTypeLegendName>Work Plan</WorkTypeLegendName>
            <ExpandCollapseWorkTypeLegend onClick={toggleLegend}>
              <MinMaxImage expanded={expanded} src={expanded ? Minimize : Maximize} alt={""} />
            </ExpandCollapseWorkTypeLegend>
          </WorkTypeLegendNameWrapper>
          {expanded && (
            <WorkTypeLegendSelectAll
              onClick={() => {
                selectAll();
              }}
            >
              {selectedAll ? "Deselect All" : "Select All"}
            </WorkTypeLegendSelectAll>
          )}
        </WorkTypeLegendHeader>
        {workTypes && expanded && (
          <WorkTypeLegendBody>
            {Object.keys(workTypes).map((key) => {
              return (
                <WorkTypeItem key={`work-type-item-${workTypes[key].name}`}>
                  <WorkTypeItemColor backgroundColor={getWorkTypeColor(workTypes[key].code)} />
                  <Checkbox
                    defaultChecked={workTypes[key].checked}
                    id={workTypes[key].name}
                    name={workTypes[key].name}
                    onClick={() => {
                      toggleWorkType(key);
                    }}
                    value={workTypes[key].name}
                    label={workTypes[key].name}
                    {...{ checked: workTypes[key].checked }}
                  />
                </WorkTypeItem>
              );
            })}
          </WorkTypeLegendBody>
        )}
      </WorkTypeLegendWrapper>
    );
  }
);
