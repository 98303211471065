import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { plansUrl } from "../api";
import { logError } from "../services";
import { PlanDto } from "../types/responses";

interface PlansResponse {
  plans: PlanDto[];
}

export const usePlans = () => {
  return useQuery("plans", () => ApiClient.get<PlansResponse>(plansUrl).then((response) => response.data.plans), {
    onError: (e) => logError(e),
  });
};

export default usePlans;
