import styled from "styled-components";

export const ListEncroachment = styled.div`
  border-top: 1px solid #ededed;
  background-color: ${(props) => props.theme.colors.catskillWhite};
  border-radius: 0px 0px 8px 8px;
  overflow-y: hidden;
`;

interface EncroachmentItemsWrapperProps {
  selected: boolean;
}

export const EncroachmentName = styled.span`
  padding-left: 12px;
`;

export const EncroachmentItemsWrapper = styled.div<EncroachmentItemsWrapperProps>`
  color: ${(props) => props.theme.colors.trout2};
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    text-align: left;
  }
  padding: 9px 8px 9px 8px;
  margin-bottom: 6px;
  margin-left: 7px;
  margin-right: 7px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  ${(props) => (props.selected ? `color: ${props.theme.colors.white}; border-radius: 3px;` : "")};
  background-color: ${(props) => (props.selected ? props.theme.colors.blueChill : "transparent")};
  :hover {
    background-color: ${(props) => (props.selected ? props.theme.colors.blueChill : props.theme.colors.botticelli)};
    border-radius: 3px;
  }
  justify-content: space-between;
`;

export const EncroachmentId = styled.span`
  margin-left: 4px;
  color: ${(props) => (props.theme.selected ? props.theme.colors.white : props.theme.colors.easternBlue)};
`;

export const EncroachmentHashTag = styled.span<{
  selected?: boolean;
}>`
  color: ${(props) => (props.selected ? props.theme.colors.white : "#91A0AE")};
`;
