import styled from "styled-components";
import { FlexItem, StyledFlex } from "../../assets/styles/flex.styled";
import { Checkbox } from "../Checkbox";

export const Wrapper = styled(StyledFlex)`
  background-color: var(--colors-white);
  width: 406px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--colors-blue-chill);
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ebeaea;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);

  &:hover {
    border-color: var(--colors-blue-chill);
  }

  input {
    border: none;
  }

  .checkbox__text {
    font-weight: 600;
    font-size: 14px;
    color: #262c2d;
    margin-left: 10px;
  }
`;

export const StyledFlexItem = styled(FlexItem)`
  align-items: center;
  display: flex;
`;

export const Footer = styled(StyledFlex)`
  position: sticky;
  bottom: 0;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.03);

  > * {
    flex: 1;
    padding: 8px;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;

  > input {
    padding-left: 35px;
  }

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, 100%);

    path {
      fill: #6a6c71;
    }
  }
`;
