import { format } from "date-fns";

import { logError } from "../services";

const FORMAT = "yyyy-MM-dd'T'HH:mm";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const formatDate = (date: string) => {
  if (!date) {
    return date;
  }

  return new Intl.DateTimeFormat("en-US").format(new Date(date));
};

export const toLocalDate = (date: string = "", dateFormat: string = FORMAT) => {
  if (!date) {
    return date;
  }
  try {
    const newDate = new Date(date);
    return format(newDate, dateFormat);
  } catch (e) {
    logError(e);
  }

  return date;
};

export const shortDate = (date: string) => {
  if (!date) {
    return date;
  }
  try {
    const newDate = new Date(date);
    return `${monthNames[newDate.getMonth()]} ${newDate.getFullYear()}`;
  } catch (e) {
    logError(e);
  }

  return date;
};
