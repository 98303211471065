import { StructuresWithWorksheet } from "../../stores";
import {
  SpanWorksheetDto,
  SpanWorksheetsDto,
  UpdateSpanWorksheetsBulkRequestDto,
  UpdateStructuresWorksheetsBulkRequestDto,
  Worksheet,
} from "../../types/responses";

export const mapStructuresToRequest = (
  structures: Nullable<StructuresWithWorksheet>
): Nullable<UpdateStructuresWorksheetsBulkRequestDto> => {
  if (!structures?.items?.length) {
    return null;
  }
  return {
    items: structures?.items.map((item) => ({
      structureId: item.structureWithWorksheet.structureId,
      workTypeSelectionList:
        item.structureWithWorksheet.items?.map((item) => {
          return item.workTypeId;
        }) ?? [],
    })),
  };
};
export const cancelAllWorkTypesForStructures = (selectedStructuresLocally: Nullable<StructuresWithWorksheet>) => {
  if (!selectedStructuresLocally) {
    return selectedStructuresLocally;
  }
  const newStructures = Object.assign({}, selectedStructuresLocally);

  newStructures.items.forEach((item) => {
    const indexesForDeletion = item.structureWithWorksheet.items?.map((item, index) => {
      return item.manuallyAdded ? index : -1;
    });
    indexesForDeletion &&
      indexesForDeletion
        .filter((index) => index >= 0)
        .forEach((index) => {
          item.structureWithWorksheet?.items && item.structureWithWorksheet.items.splice(index, 1);
        });
  });
  return newStructures;
};

export const mapSpans = (
  selectedSpansFromResponse: Nullable<SpanWorksheetsDto>,
  spansById: Nullable<Record<string, string>>,
  voltages: Nullable<Record<string, string>>
) => {
  if (!selectedSpansFromResponse?.spanWorksheets?.length || !spansById || !Object.keys(spansById)?.length) {
    return null;
  }

  return selectedSpansFromResponse?.spanWorksheets
    .map((span) => {
      return {
        spanId: span.spanId,
        items: span.items,
        name: spansById[span.spanId] ?? "",
        voltage: voltages?.[span.spanId] ? Number(voltages?.[span.spanId]) : 0,
      };
    })
    .sort((item1, item2) => {
      if (!item1.voltage || !item2.voltage) {
        return 0;
      }
      return item2.voltage - item1.voltage;
    });
};

export const mapIntoUpdateBulkSpanRequest = (
  spans: Nullable<SpanWorksheetDto[]>
): Nullable<UpdateSpanWorksheetsBulkRequestDto> => {
  if (!spans) {
    return null;
  }

  return {
    items: spans.map((span) => {
      return {
        spanId: span.spanId,
        workTypesIds: span.items.filter((item) => item.selected).map((item) => item.workTypeId),
      };
    }),
  };
};

export const calculateSubItem = (items: Worksheet[]) => {
  if (!items?.length) {
    return 0;
  }
  const filteredItems = items.filter((item) => item.selected);
  if (!filteredItems?.length) {
    return 0;
  }
  return filteredItems
    .map((item) => item.cost)
    .reduce((prev, next) => {
      return prev + next;
    });
};

export const calculateTotalCost = (spans: Nullable<SpanWorksheetDto[]>) => {
  if (!spans?.length) {
    return 0;
  }

  return spans
    .map((span) => {
      return calculateSubItem(span.items);
    })
    .reduce((prev, next) => {
      return prev + next;
    });
};

export const calculateSpanTotalCost = (workTypes: Worksheet[]) => {
  const spansWorkTypes = workTypes.filter((workType) => !workType.inherited);

  const infringementWorkTypes = workTypes.filter((workType) => {
    const hasSpanWorkType = spansWorkTypes.filter((span) => span.workTypeId === workType.workTypeId && span.selected);
    return workType.inherited && !hasSpanWorkType?.length;
  });
  return calculateSubItem(infringementWorkTypes) + calculateSubItem(spansWorkTypes);
};
