import create from "zustand";

import { EMPTY_FN } from "../constants";
import { TreeMarker, TreeMarkerCategory } from "../types/responses";

export type TreeMarkersStore = {
  actions: {
    toggleLayer: () => void;
    setCategories: (categories: Nullable<TreeMarkerCategory[]>) => void;
    setData: (data: Nullable<TreeMarker[]>) => void;
  };
  isLayerVisible: boolean;
  categories: Nullable<TreeMarkerCategory[]>;
  data: Nullable<TreeMarker[]>;
};

const INITIAL_STATE = {
  isLayerVisible: false,
  categories: null,
  data: null,
  actions: {
    toggleLayer: EMPTY_FN,
    setCategories: EMPTY_FN,
    setData: EMPTY_FN,
  },
};

export const useTreeMarkersStore = create<TreeMarkersStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    toggleLayer: () => set((state) => ({ isLayerVisible: !state.isLayerVisible })),
    setCategories: (categories) => set({ categories }),
    setData: (data) => set({ data }),
  },
}));

export default useTreeMarkersStore;
