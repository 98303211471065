import { useRef, useState } from "react";
import { SectionLinks as SectionLinksType } from "../../../pages/MapScreen/RightDrawerMenu/RightDrawerMenu.utils";
import { ContentType } from "../../../stores/DrawerStore/DrawerStore.types";
import { Hyperlink, Links, ParcelDropdown, ArrowDown } from "./DrawerSectionItems.styled";
import { StyledFlex } from "../../../assets/styles/flex.styled";
import { useClickOutside } from "../../../hooks";

export const SectionLinks = ({
  data,
  openHyperlink,
}: {
  openHyperlink?: (type: ContentType, id: string) => void;
  data: SectionLinksType;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isOpen && setIsOpen(false));

  return (
    <>
      {data?.some((item) => item.type === ContentType.PARCEL) ? (
        <StyledFlex justifyContent="flex-end" alignItems="center" position="relative" gap="5px" width="160px">
          <Links>
            {data?.map((object, index) => (
              <Hyperlink key={index}>
                <span className="hyperlink-content" onClick={() => openHyperlink?.(object.type, object.id)}>
                  {object.value}
                </span>
              </Hyperlink>
            ))}
          </Links>
          {data.length > 1 && <ArrowDown onClick={() => setIsOpen(!isOpen)} />}
          {isOpen && (
            <ParcelDropdown ref={wrapperRef} flexDirection="column" gap="0px">
              {data.map((object, index) => (
                <Hyperlink key={index}>
                  <span className="hyperlink-content" onClick={() => openHyperlink?.(object.type, object.id)}>
                    {object.value}
                  </span>
                </Hyperlink>
              ))}
            </ParcelDropdown>
          )}
        </StyledFlex>
      ) : (
        <Links>
          {data?.map((object, index) => (
            <Hyperlink key={index}>
              <span
                className="hyperlink-content"
                onClick={() => openHyperlink?.(object.type, object.id)}
                title={object.value}
              >
                {object.value}
              </span>
            </Hyperlink>
          ))}
        </Links>
      )}
    </>
  );
};
