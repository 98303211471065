import create from "zustand";

export enum LoadingState {
  Loading = "LOADING",
  Success = "SUCCESS",
  Error = "ERROR",
  Idle = "IDLE",
}

export type AdminLoadingStore = {
  loadingState: LoadingState;
  setLoadingState: (newState: LoadingState) => void;
  resetStore: () => void;
};

const INITIAL_STATE = {
  loadingState: LoadingState.Idle,
};

export const useAdminLoadingStore = create<AdminLoadingStore>((set, get) => ({
  ...INITIAL_STATE,
  setLoadingState: (newState: LoadingState) => {
    if (newState === get().loadingState) {
      return;
    }
    set({ loadingState: newState });
  },
  resetStore: () => {
    set({ ...INITIAL_STATE });
  },
}));

export default useAdminLoadingStore;
