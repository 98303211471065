import { FC, memo, useCallback, useMemo } from "react";
import { GeoJSON } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { useCustomQuery, useMapOptimizedHiddenFlag } from "../../hooks";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { EMPTY_FN } from "../../constants";
import { TreeCanopyResponse } from "../../types/responses/TreeCanopyResponse";
import { getTreeCanopyStyle } from "./MapTreeCanopyLayers.utils";
import { TREE_CANOPY_PANE_NAME } from "./MapTreeCanopyLayer.constants";
interface MapTreeCanopyProps {
  geoJSON?: Nullable<TreeCanopyResponse>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  onClick: (e: L.LeafletMouseEvent, id: string) => void;
  url?: string;
  zIndex?: number;
  opacity?: number;
  selectedId: Nullable<string>;
}

export const MapTreeCanopyLayer: FC<MapTreeCanopyProps> = memo(
  ({ geoJSON, hidden = false, map, onClick = EMPTY_FN, url = "", zIndex = 401, opacity = 0.8, selectedId }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);

    useCustomMapPane({
      map,
      name: TREE_CANOPY_PANE_NAME,
      zIndex,
    });

    const onEachFeature = useCallback(
      (feature, layer) => {
        layer.on({
          click: (e: L.LeafletMouseEvent) => onClick(e, e?.target?.feature?.properties?.id),
        });
      },
      [onClick]
    );

    const shouldFetchData = !geoJSON && !!url;

    const { data } = useCustomQuery<TreeCanopyResponse>(url, {
      enabled: shouldFetchData,
    });

    const treeLayerData = shouldFetchData ? data : geoJSON;

    const renderedCanopyData = useMemo(() => {
      if (isGeoJSONEmpty(treeLayerData)) {
        return <></>;
      }

      return treeLayerData?.features.map((item) => {
        return (
          <GeoJSON
            data={item}
            key={uuidv4()}
            onEachFeature={onEachFeature}
            pane={TREE_CANOPY_PANE_NAME}
            style={() => getTreeCanopyStyle(item.properties.treeHeightMax, item.properties.id, selectedId, opacity)}
          />
        );
      });
    }, [treeLayerData, onEachFeature, opacity, selectedId]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{renderedCanopyData}</>;
  }
);
