import L from "leaflet";
import { FC, memo, useCallback, useMemo } from "react";
import { GeoJSON } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

import { useMapOptimizedHiddenFlag } from "../../hooks";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { BySpanId } from "../../stores";
import { Span, SpansResponse } from "../../types/responses";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { getClearanceStyle } from "./MapClearance.utils";

interface MapClearanceProps {
  geoJSON: Nullable<SpansResponse>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  selectedSpans: Nullable<string[]>;
  zIndex?: number;
  filter?: (item: Span) => boolean;
  isOverriden?: (id: string) => boolean;
  maintenanceAreasBySpanId?: Nullable<BySpanId>;
  invisibleActiveMA?: boolean;
}

const CLEARANCE_NAME_PANE = "clearance-pane";

export const MapClearance: FC<MapClearanceProps> = memo(
  ({
    geoJSON,
    hidden = false,
    map,
    zIndex = 400,
    selectedSpans,
    filter,
    isOverriden = () => false,
    maintenanceAreasBySpanId,
    invisibleActiveMA,
  }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);

    useCustomMapPane({
      name: CLEARANCE_NAME_PANE,
      zIndex,
      map,
    });

    const onEachFeature = useCallback((layer: L.Layer, maintenanceAreaName: Maybe<string>) => {
      if (!maintenanceAreaName) {
        return;
      }

      layer.bindPopup(`<div class="maintenance-popup-name">${maintenanceAreaName}</div>`, {
        closeButton: false,
      });
      layer.on("mouseover", (e: L.LeafletMouseEvent) => {
        layer.openPopup(e.latlng);
      });
      layer.on("mouseout", () => {
        layer.closePopup();
      });
    }, []);

    const clearance = useMemo(() => {
      if (isGeoJSONEmpty(geoJSON)) {
        return <></>;
      }

      const filteredData = filter ? geoJSON?.features.filter(filter) : geoJSON?.features;
      return filteredData!.map((item) => {
        const overriden = isOverriden(item.properties.id);
        const selected =
          selectedSpans !== null && selectedSpans.find((span) => span === item.properties.id) !== undefined;
        const maintenanceArea = maintenanceAreasBySpanId?.[item.properties.id];

        return (
          <GeoJSON
            data={item}
            key={uuidv4()}
            pane={CLEARANCE_NAME_PANE}
            onEachFeature={(_feature, layer) => {
              onEachFeature(layer, maintenanceArea?.name);
            }}
            style={() =>
              getClearanceStyle({
                selected,
                overriden,
                maintenanceColor: maintenanceArea?.color,
                invisibleActiveMA,
              })
            }
            interactive={true}
          />
        );
      });
    }, [geoJSON, selectedSpans, filter, isOverriden, maintenanceAreasBySpanId, onEachFeature, invisibleActiveMA]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{clearance}</>;
  }
);
