import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { accessPointsUrl } from "../api";
import { QUERY_KEY } from "../pages/MapScreen/MapView/AccessPoints.util";

export const useAddAccessPoint = () => {
  const queryClient = useQueryClient();
  return useMutation(QUERY_KEY, (newItem: any) => ApiClient.post(accessPointsUrl, newItem), {
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEY);
    },
  });
};
