import { FC, useMemo, useCallback, memo } from "react";
import { GeoJSON } from "react-leaflet";

import { EMPTY_FN } from "../../constants";
import { useMapOptimizedHiddenFlag } from "../../hooks";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { RISK_PRIORITY, ThreatResponse } from "../../types/responses";
import { getEncroachmentStyle } from "./MapEncroachments.util";
import { v4 as uuidv4 } from "uuid";

interface MapEncroachmentsProps {
  hidden?: boolean;
  map: Nullable<L.Map>;
  onClick: (e: L.LeafletMouseEvent) => void;
  selectedEncroachment: Nullable<string>;
  zIndex?: number;
  opacity?: number;
  data: Nullable<ThreatResponse>;
}

const ENCROACHMENTS_PANE_NAME = "encroachments-pane";

export const MapEncroachments: FC<MapEncroachmentsProps> = memo(
  ({ hidden = false, map, onClick = EMPTY_FN, selectedEncroachment, zIndex = 400, opacity = 1, data }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);

    useCustomMapPane({
      name: ENCROACHMENTS_PANE_NAME,
      zIndex,
      map,
    });

    const onEachFeature = useCallback(
      (_feature, layer) => {
        layer.on({
          click: onClick,
        });
      },
      [onClick]
    );

    const renderedEncroachments = useMemo(() => {
      if (!data || !data?.features?.length) {
        return <></>;
      }
      return data?.features?.map((item) => (
        <GeoJSON
          data={item}
          key={uuidv4()}
          onEachFeature={onEachFeature}
          pane={ENCROACHMENTS_PANE_NAME}
          style={() =>
            getEncroachmentStyle({
              opacity,
              selected: item.properties.id === selectedEncroachment,
              priority: item.properties.riskPriority || RISK_PRIORITY.LOW,
            })
          }
        />
      ));
    }, [onEachFeature, selectedEncroachment, data, opacity]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{renderedEncroachments}</>;
  }
);
