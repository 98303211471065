import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { postSatelliteQueryUrl } from "../api";
import { SatelliteQueryRequest } from "../types/responses";
import { MaxarResponseType } from "../types/responses/MaxarResponse";

const POST_SATELLITE_QUERY_KEY = "satellite-query";

export const usePostSatelliteQuery = () => {
  return useMutation(POST_SATELLITE_QUERY_KEY, (postData: SatelliteQueryRequest) =>
    ApiClient.post<MaxarResponseType>(postSatelliteQueryUrl, postData)
  );
};
