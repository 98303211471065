import styled from "styled-components";

export const SpanPriorityWrapper = styled.div`
  background-color: #e5edf2;
  display: flex;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: var(--colors-trout);
  height: 44px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SpanPriorityLabel = styled.div`
  padding-left: 13px;
`;
export const SpanPriorityItems = styled.div`
  display: flex;
  gap: 4px;
  height: 26px;
  padding-right: 4px;
`;

export const SpanPriorityItem = styled.div``;
