import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { searchAlerts } from "../api";
import { AlertsResponseDto } from "../types/responses";

const options = {
  sortBy: "DATE",
  showResolved: false,
  showRead: true,
  page: 0,
  pageSize: 2000,
};
export const useAlerts = () => {
  return useQuery("alerts", () =>
    ApiClient.post(searchAlerts, options).then((res) => res.data as Nullable<AlertsResponseDto>)
  );
};
