import styled from "styled-components";
import {
  StylesProps,
  ButtonStyleProps,
  BorderProps,
  PaddingProps,
  MarginProps,
  margin,
  border,
  padding,
} from "styled-system";

export const Button = styled.button<StylesProps & ButtonStyleProps & BorderProps & PaddingProps & MarginProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: ${({ padding }) => padding || "6px 12px"};
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #e8ecf1;
  border-radius: ${({ borderRadius }) => borderRadius || "5px"};
  color: #e8ecf1;
  background-color: var(--colors-white);
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  text-decoration: none;
  ${border}
  ${padding}
  ${margin}

  &.secondary {
    border-color: var(--colors-eastern-blue);
    color: var(--colors-eastern-blue);
    background-color: var(--colors-white);
  }

  &.primary {
    border-color: var(--colors-eastern-blue);
    color: var(--colors-white);
    background-color: var(--colors-eastern-blue);
  }

  &.close {
    width: 30px;
    aspect-ratio: 1;
    padding: 0;

    svg {
      fill: #85919e;
      &:hover {
        fill: var(--colors-eastern-blue);
      }
      &:active {
        fill: var(--colors-white);
      }
    }
  }

  &[disabled] {
    border-color: var(--colors-oslo-gray);
    background-color: var(--colors-oslo-gray);
    cursor: not-allowed;
  }

  &.popupIcon {
    background-color: var(--colors-black);
    border: none;
    border-bottom: 2px solid transparent;
    padding: 5px 2px;
    border-radius: 0;
    svg,
    path {
      fill: #8d8e90;
    }
    &.isSelected {
      border-color: #1ea7b2;

      svg,
      path {
        fill: #1ea7b2;
      }
    }
  }
`;
