import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { QUERY_KEY } from "../pages/MapScreen/MapView/TreeMarkerView.utils";
import { treeMarkersUrl } from "../api";

export const CREATE_TREE_MARKER_MUTATION_KEY = "create-tree-marker";

export const useCreateTreeMarker = () => {
  const queryClient = useQueryClient();
  return useMutation(CREATE_TREE_MARKER_MUTATION_KEY, (value: any) => ApiClient.post(treeMarkersUrl, value), {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEY),
  });
};
