import React, { FC, useRef, useState, useEffect, useMemo } from "react";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as EditPen } from "../../assets/images/edit_pen.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as Trash } from "../../assets/images/remove.svg";
import {
  StyledButton,
  Wrapper,
  CategoryButton,
  SubcategoryWrapper,
  ComponentTitle,
  CloseButton,
  CategoriesWrapper,
} from "./AddAlertPopup.styled";
import { getSubcategoryIcon, sortByOrderNum } from "./AlertPopup.util";
import { AccessPoint, TreeMarker, TreeMarkerCategory, TreeMarkerCategoryType } from "../../types/responses";

const intersectionOptions = {
  root: document.querySelector("#categories"),
  rootMargin: "0px",
  threshold: [0, 0.5, 1],
};

const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
  const elInViewport = entries.find((entry) => entry.isIntersecting && entry.intersectionRatio > 0.5);
  if (!elInViewport) return;

  document?.querySelector(".category.isSelected")?.classList.remove("isSelected");
  document?.getElementById(elInViewport.target.id)?.classList.add("isSelected");
};
interface IProps {
  categories: TreeMarkerCategory[];
  create?: (categoryId: string) => void;
  deleteMarker: (id: string) => void;
  edit: (id: string, { categoryId }: { categoryId: string }) => void;
  item?: TreeMarker | AccessPoint;
  updatePopup?: () => void;
  closePopup?: () => void;
  showSubcategoryImage?: boolean;
  title?: string;
  getCategoryImage: (categoryCode: string) => Nullable<React.FC<React.SVGProps<SVGSVGElement>>>;
  editTitle?: string;
}

export const MarkerPopup: FC<IProps> = ({
  categories,
  create,
  edit,
  updatePopup,
  closePopup,
  deleteMarker,
  showSubcategoryImage = true,
  title = "",
  item,
  editTitle,
  getCategoryImage,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isEdit, setIsEdit] = useState(!!item);
  const [canEdit, setCanEdit] = useState(false);

  const category: Nullable<{ name?: string; code?: string; subCategoryName?: string }> = useMemo(() => {
    if (!item?.categoryCode || !categories?.length) {
      return null;
    }
    const mainCategory: Maybe<TreeMarkerCategory> = categories.find(
      (category) => category.subCategories?.find((subCategory) => subCategory.code === item?.categoryCode) !== undefined
    );
    const subCategory = mainCategory?.subCategories?.find(
      (subCategory: TreeMarkerCategory) => subCategory.code === item?.categoryCode
    );
    return { name: mainCategory?.name, code: mainCategory?.code, subCategoryName: subCategory?.name };
  }, [item, categories]);

  const onSubcategoryClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, categoryId: string) => {
    // Remove previously selected subcategory class
    document
      ?.querySelectorAll(".subcategory.isSelected")
      ?.forEach((item: Element) => item?.classList.remove("isSelected"));
    // Add currently selected subcategory class
    (e.target as Element).classList.add("isSelected");
    if (canEdit) {
      edit(item?.id!, { categoryId });
    } else {
      create && create(categoryId);
    }
  };

  const onCategoryClick = (categoryCode: string) => {
    const targetEl = document.querySelector(`[data-code="${categoryCode}"]`);
    try {
      // @ts-ignore
      targetEl?.scrollIntoViewIfNeeded();
    } catch (err) {
      // fallback for browser that doesn't support scrollIntoViewIfNeeded
      targetEl?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const renderIcon = (code: string) => {
    const Icon = getCategoryImage(code as TreeMarkerCategoryType);
    return Icon && <Icon />;
  };

  useEffect(() => {
    if (updatePopup && !isEdit) updatePopup();
  }, [isEdit, updatePopup]);

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback, intersectionOptions);
    document.querySelectorAll("[data-code]")?.forEach((item) => observer.observe(item));
    // Initialy, select first tab item
    (tabsRef?.current?.firstChild as HTMLElement)?.classList.add("isSelected");

    return () => {
      observer.disconnect();
    };
  }, [isEdit]);

  if (categories.length === 0) return null;

  return (
    <>
      <Wrapper>
        {isEdit && (
          <>
            <StyledFlex justifyContent="space-between" alignItems="center" my="10px">
              <ComponentTitle>{isEdit && editTitle ? editTitle : title}</ComponentTitle>
              <CloseButton Icon={Close} onClick={closePopup} />
            </StyledFlex>
            <StyledFlex alignItems="center" gap="15px" my="20px">
              {renderIcon(category?.code || "")}
              <span>{category?.subCategoryName ?? ""}</span>
            </StyledFlex>
            <StyledFlex justifyContent="space-between" mt="20px">
              <StyledButton className="delete" Icon={Trash} text="Delete" onClick={() => deleteMarker(item?.id!)} />
              <StyledButton
                className="primary edit"
                Icon={EditPen}
                text="Edit"
                onClick={() => {
                  setIsEdit(false);
                  setCanEdit(true);
                }}
              />
            </StyledFlex>
          </>
        )}
        {!isEdit && (
          <>
            <StyledFlex flexDirection="column" gap="5px" padding="5px">
              <StyledFlex justifyContent="space-between" alignItems="center" my="10px">
                <ComponentTitle>{title}</ComponentTitle>
                <CloseButton Icon={Close} onClick={closePopup} />
              </StyledFlex>
              <StyledFlex justifyContent="space-between" alignItems="center" ref={tabsRef}>
                {categories.sort(sortByOrderNum).map((item) => (
                  <CategoryButton
                    key={item.id}
                    id={item.code}
                    Icon={getCategoryImage(item.code)}
                    onClick={() => onCategoryClick(item.code)}
                    className="category"
                  />
                ))}
              </StyledFlex>
            </StyledFlex>
            <CategoriesWrapper flexDirection="column" id="categories">
              {categories.map((item) => (
                <StyledFlex key={item.id} id={item.code} flexDirection="column" marginTop="20px" data-code={item.code}>
                  <StyledFlex alignItems="center" gap="5px">
                    <CategoryButton Icon={getCategoryImage(item.code)} />
                    <span>{item.name}</span>
                  </StyledFlex>
                  <StyledFlex flexDirection="column" gap="5px">
                    {item?.subCategories &&
                      item.subCategories
                        .sort(sortByOrderNum)
                        .map((subCat) => (
                          <SubcategoryWrapper
                            key={subCat.id}
                            id={subCat.id}
                            Icon={showSubcategoryImage ? getSubcategoryIcon(subCat.code) : null}
                            text={subCat.name}
                            className="subcategory"
                            onClick={(e) => onSubcategoryClick(e, subCat.id)}
                          />
                        ))}
                  </StyledFlex>
                </StyledFlex>
              ))}
            </CategoriesWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};
