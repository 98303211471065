import { FC, memo, useEffect } from "react";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { StarCommandLHS } from "../../components/StarCommandLHS";
import { Toast } from "../../components/Toast";
import { StarCommandRHS } from "../../components/StarCommandRHS";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constants";
import { useFeeders, useStarCommand, useStarCommandMaintenanceArea } from "../../hooks";
import { useFeederStore, useLeftControlStore } from "../../stores";
import useStarCommandMapStore from "../../stores/StarCommandMapStore/StarCommandMapStore";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { PageContent, PageWrapper } from "./StarCommandMap.styled";
import { StarCommandMap } from "./StarCommandMap";

export const StarCommandScreen: FC = memo(() => {
  const feedersGeoJson = useFeederStore((store) => store.feedersGeoJson);
  const checkedMaintenanceAreas = useStarCommandMapStore((store) => store.checkedMaintenanceAreas);
  const toggle = useLeftControlStore((store) => store.toggled);

  const { isLoading: feedersDataIsLoading, isError: feedersDataIsError } = useFeeders();

  const { isLoading: isLoadingStarCommand, isError: isStarCommandError } = useStarCommand();

  const starCommandMaintenanceAreas = useStarCommandMaintenanceArea();

  useEffect(() => {
    starCommandMaintenanceAreas.mutate(checkedMaintenanceAreas ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedMaintenanceAreas]);

  const isLoading = feedersDataIsLoading || isLoadingStarCommand;
  const shouldDisplayErrorMessage =
    !isLoading && (feedersDataIsError || isStarCommandError || !feedersGeoJson || isGeoJSONEmpty(feedersGeoJson));

  const shouldDisplayMap =
    !feedersDataIsLoading && !feedersDataIsError && feedersGeoJson && !isGeoJSONEmpty(feedersGeoJson);
  return (
    <div>
      <PageWrapper>
        <StarCommandLHS />
        <StarCommandRHS />
        <PageContent toggled={toggle}>
          <Toast kind="error" hidden={!shouldDisplayErrorMessage} margin="auto" mt={2} data-testid="map-error">
            {SOMETHING_WENT_WRONG_MESSAGE}
          </Toast>
          {shouldDisplayMap && <StarCommandMap />}
        </PageContent>
      </PageWrapper>
      <LoadingOverlay hidden={!isLoading} />
    </div>
  );
});
