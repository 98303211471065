import { useField } from "formik";
import React, { FC, useState } from "react";
import { LayoutProps } from "styled-system";

import { ErrorIcon, ErrorMessage, TableInputItem } from "./FooterCell.styled";

interface FooterCellProperties extends LayoutProps {
  name: string;
  placeholder?: string;
  style?: React.CSSProperties;
  type: "number" | "string";
  value?: string;
}
export const ControlledFooterCell: FC<FooterCellProperties> = ({ style, type, placeholder, name, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const [field, meta] = useField(name);
  const hasError = meta.error && meta.touched ? true : false;
  return (
    <div>
      <TableInputItem
        style={style}
        type={type}
        error={hasError}
        placeholder={placeholder}
        {...field}
        onBlur={(e) => {
          setIsActive(false);
          field.onBlur(e);
        }}
        onFocus={() => setIsActive(true)}
        autocomplete="off"
      />
      {hasError && <ErrorIcon />}
      {hasError && isActive && <ErrorMessage style={style}>{meta.error}</ErrorMessage>}
    </div>
  );
};
