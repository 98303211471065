import { FC, memo } from "react";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";
import { Marker } from "react-leaflet";
import "./MapPatrols.style.css";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { useMapOptimizedHiddenFlag, useMapTooltip } from "../../hooks";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { usePatrolsStore } from "../../stores/PatrolsStore";

interface IProps {
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
}

const PATROLS_PANE_NAME = "patrols-pane";

const renderCategoryIcon = (iconUrl: string, type: "VEGETATION" | "STRUCTURE") => {
  const image = document.createElement("img");
  image.src = iconUrl;
  const html = document.createElement("div");
  html.appendChild(image);

  return L.divIcon({
    html,
    className: `patrolIcon ${type === "STRUCTURE" ? "isStructureType" : ""}`,
    iconSize: [36, 36],
  });
};

export const MapPatrols: FC<IProps> = memo(({ hidden = false, map, zIndex = 402 }) => {
  useCustomMapPane({ map, name: PATROLS_PANE_NAME, zIndex });
  const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
  const patrolsData = usePatrolsStore((store) => store.data);
  const { onMouseMove, onMouseOut } = useMapTooltip({ layer: map, offset: new L.Point(25, 1) });

  if (optimizedHidden || patrolsData.length === 0) return <></>;

  return (
    <MapMarkerCluster disableClusteringAtZoom={8}>
      {patrolsData.map((item) => (
        <Marker
          key={uuidv4()}
          icon={renderCategoryIcon(item.iconUrl, item.type)}
          position={[item.lat, item.lon]}
          pane={PATROLS_PANE_NAME}
          interactive={true}
          eventHandlers={{
            mouseover: (e) => onMouseMove(e, `${item.shortDescription}`),
            mouseout: () => onMouseOut(),
          }}
        />
      ))}
    </MapMarkerCluster>
  );
});
