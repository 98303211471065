import { MappedFeederGeoJson } from "../../../../types/responses/FeedersResponse";

export const highestCircuitVoltage = (circuits: Maybe<MappedFeederGeoJson>[]) => {
  if (!circuits?.length) {
    return 0;
  }
  return (
    circuits
      ?.map((circuit) => Number(circuit?.properties?.voltage ?? 0))
      .reduce((curr, prev) => {
        if (curr > prev) {
          return curr;
        }
        return prev;
      }, 0) || 0
  );
};

export const sortByVoltage = (item1: { voltage: number }, item2: { voltage: number }) => {
  if (!item1.voltage || !item2.voltage) {
    return 0;
  }
  return item2.voltage - item1.voltage;
};
