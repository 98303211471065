import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import { getParcelsApiUrl } from "../api";
import { computeExtentFetchData } from "../pages/MapScreen/MapScreen.utils";
import { useMapStore } from "../stores";

export const useReloadParcels = () => {
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const currentMapBounds = useMapStore((store) => store.currentMapBounds);

  const parcelsFetchData = useMemo(() => computeExtentFetchData({ currentZoomLevel, currentMapBounds }), [
    currentMapBounds,
    currentZoomLevel,
  ]);
  const queryClient = useQueryClient();

  const reloadParcels = useCallback(() => {
    queryClient.invalidateQueries(getParcelsApiUrl(parcelsFetchData));
  }, [parcelsFetchData, queryClient]);

  return { reloadParcels };
};
