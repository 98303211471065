export const CLEARANCE_STYLE = {
  fillColor: "transparent",
  opacity: 0.44,
  color: "white",
  weight: 1.5,
};

export const CLEARANCE_STYLE_ACTIVE_INVISIBLE = {
  fillColor: "transparent",
  opacity: 0,
  color: "white",
  weight: 1.5,
};

export const CLEARANCE_STYLE_SELECTED = {
  fillColor: "rgb(253, 202, 20)",
  opacity: 1,
  color: "rgb(253, 202, 20)",
  weight: 4,
};

export const CLEARANCE_STYLE_OVERRIDEN = {
  opacity: 1,
};
