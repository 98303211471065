import L, { DrawMap } from "leaflet";
import { FC, useCallback, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import { PositionProps } from "styled-system";

import "leaflet-lasso";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw/dist/leaflet.draw-src.css";

import { SelectionButton, Container } from "./SelectionTools.styled";
import { ReactComponent as LassoImage } from "../../../src/assets/images/lasso.svg";
import { ReactComponent as RectangleImage } from "../../../src/assets/images/re_icn.svg";
import { MapSelectionPopup } from "../MapSelectionPopup/MapSelectionPopup";
import {
  eventHandlers,
  getLayersInPolygon,
  lassoOptions,
  rectangleOptions,
  SelectionToolTypes,
} from "./SelectionTools.utils";
import { ASSET_TYPES, LayerType, useMapSelectionStore } from "../../stores";
import { Portal } from "../Portal";

interface LassoHandlerFinishedEventData {
  latLngs: L.LatLng[];
  layers: L.Layer[];
}

type LassoHandlerFinishedEvent = L.LeafletEvent & LassoHandlerFinishedEventData;

interface SelectionToolsProps extends PositionProps {
  rightMenuEnabled?: boolean;
  layerMenuEnabled?: boolean;
  leftMenuEnabled?: boolean;
  hidden: boolean;
  selectedType: Nullable<ASSET_TYPES>;
  selectType: (type: ASSET_TYPES) => void;
  setSelectedFeatures: (layers: Nullable<LayerType[]>, latLngs?: Nullable<L.LatLng[]>) => void;
  selectionFrameHidden: boolean;
  setSelectionFrame: (value: boolean) => void;
}

export const SelectionTools: FC<SelectionToolsProps> = ({
  rightMenuEnabled,
  layerMenuEnabled,
  leftMenuEnabled,
  hidden,
  selectType,
  selectedType,
  setSelectedFeatures,
  selectionFrameHidden,
  setSelectionFrame,
  ...props
}) => {
  const map = useMap();
  const lassoRef = useRef<any>(null);
  const rectangleRef = useRef<any>(null);

  const resetState = useMapSelectionStore((store) => store.resetState);
  const hiddenSelectionPopup = useMapSelectionStore((store) => store.hiddenSelectionPopup);
  const setSelectionPopup = useMapSelectionStore((store) => store.setSelectionPopup);
  const activeSelectionTool = useMapSelectionStore((store) => store.activeSelectionTool);
  const setActiveSelectionTool = useMapSelectionStore((store) => store.setActiveSelectionTool);

  const onRectangleCreated = useCallback(
    (e) => {
      setSelectionPopup(false);
      const pol: L.Polygon = L.polygon(e.layer.getLatLngs());
      const layers: L.Layer[] = [];
      map.eachLayer((layer) => {
        if (L.MarkerCluster && layer instanceof L.MarkerCluster) {
          layers.push(...layer.getAllChildMarkers());
        } else if (layer instanceof L.Marker || layer instanceof L.Path) {
          layers.push(layer);
        }
      });
      const selectedFeatures = getLayersInPolygon(pol, layers, {
        intersect: true,
      });
      setSelectedFeatures(selectedFeatures as LayerType[], e.layer.getLatLngs()?.[0]);
      setSelectionFrame(false);
    },
    [map, setSelectedFeatures, setSelectionPopup, setSelectionFrame]
  );

  const onLassoCreated = useCallback(
    (e: LassoHandlerFinishedEvent) => {
      setSelectionPopup(false);
      const selectedFeatures = e.layers;
      setSelectedFeatures(selectedFeatures as LayerType[], e.latLngs);
      setSelectionFrame(false);
    },
    [setSelectedFeatures, setSelectionPopup, setSelectionFrame]
  );

  useEffect(() => {
    if (!L.drawLocal?.draw?.handlers?.rectangle) {
      return;
    }
    L.drawLocal.draw.handlers.rectangle.tooltip = {
      start: "Click and draw to select area.",
    };
    if (!L.drawLocal?.draw?.handlers?.simpleshape) {
      return;
    }
    L.drawLocal.draw.handlers.simpleshape.tooltip = {
      end: "",
    };
  }, []);

  useEffect(() => {
    if (!map || hidden) return;

    const rectangle = new L.Draw.Rectangle(map as DrawMap, rectangleOptions);
    rectangleRef.current = rectangle;

    return () => {
      rectangleRef.current.disable();
      rectangleRef.current = null;
    };
  }, [map, hidden]);

  useEffect(() => {
    if (!map && hidden) return;
    const lasso = L.lasso(map, lassoOptions);

    lassoRef.current = lasso;

    return () => {
      lassoRef.current.disable();
      lassoRef.current = null;
    };
  }, [map, hidden]);

  useEffect(() => {
    if (!map || hidden) {
      return;
    }
    map.on(L.Draw.Event.CREATED, onRectangleCreated);
    map.on(eventHandlers.onLassoCreated, onLassoCreated as L.LeafletEventHandlerFn);

    return () => {
      setSelectionPopup(true);
      map.off(L.Draw.Event.CREATED, onRectangleCreated);
      map.off(eventHandlers.onLassoCreated, onLassoCreated as L.LeafletEventHandlerFn);
      setSelectedFeatures([]);
    };
  }, [map, onLassoCreated, onRectangleCreated, setSelectedFeatures, setSelectionPopup, hidden]);

  const onSelectionClick = (ref: any, selectionToolType: SelectionToolTypes) => {
    // disable all at the beginning
    rectangleRef.current.disable();
    lassoRef.current.disable();

    if (ref.current) {
      ref.current.enable();
      setActiveSelectionTool(selectionToolType);
    }
  };

  if (hidden) {
    return <></>;
  }

  return (
    <Portal selector="layer-menu-root">
      <Container id="selection-tool" {...props}>
        <SelectionButton
          onClick={() => onSelectionClick(lassoRef, "lasso")}
          isActive={activeSelectionTool === "lasso"}
          selectionType={"lasso"}
        >
          <LassoImage />
        </SelectionButton>
        <SelectionButton
          isRectangle
          onClick={() => onSelectionClick(rectangleRef, "rectangle")}
          isActive={activeSelectionTool === "rectangle"}
          selectionType={"rectangle"}
        >
          <RectangleImage />
        </SelectionButton>
      </Container>
      <MapSelectionPopup
        hidden={hiddenSelectionPopup}
        onClick={() => {
          resetState();
        }}
        rightMenuEnabled={rightMenuEnabled}
        leftMenuEnabled={leftMenuEnabled}
        layerMenuEnabled={layerMenuEnabled}
        selectType={selectType}
        selectedType={selectedType}
        selectionFrameHidden={selectionFrameHidden}
        setSelectionFrame={setSelectionFrame}
      />
    </Portal>
  );
};
