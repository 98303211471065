import React, { FC } from "react";

import { Label } from "../LayersMenu/LayersMenu.styled";
import { ReactComponent as RadioChecked } from "./../../assets/images/radio_checked.svg";
import { ReactComponent as RadioUnchecked } from "./../../assets/images/radio_unchecked.svg";
import { AdditionalInfo, RadioContainer, RadioImage, RadioInput, RadioText, TextWrapper } from "./Radio.styled";

interface RadioProps {
  defaultChecked: boolean;
  id: string;
  label: string;
  name: string;
  onClick: () => void;
  value: string;
  image?: string;
  additionalInfo?: string;
  boxShadow?: string;
}

export const Radio: FC<RadioProps> = ({
  label,
  name,
  value,
  defaultChecked,
  id,
  onClick,
  image,
  additionalInfo,
  boxShadow,
  ...props
}) => {
  return (
    <RadioContainer {...props}>
      <Label htmlFor={value}>
        <RadioInput defaultChecked={defaultChecked} id={id} name={name} onClick={onClick} type="radio" value={value} />
        <RadioChecked className="radio-checked" />
        <RadioUnchecked className="radio-unchecked" />
        {image && <RadioImage boxShadow={boxShadow} src={image} alt={"option"} />}
        <TextWrapper>
          <RadioText>{label}</RadioText>
          {additionalInfo && <AdditionalInfo>{additionalInfo}</AdditionalInfo>}
        </TextWrapper>
      </Label>
      {props?.children}
    </RadioContainer>
  );
};
