import { ReactComponent as AccessIssue } from "../../assets/images/alerts/markers/access_issue.svg";
import { ReactComponent as AccessCode } from "../../assets/images/alerts/markers/access_code.svg";
import { ReactComponent as UtilityLock } from "../../assets/images/alerts/markers/utility_lock.svg";
import { ReactComponent as Bees } from "../../assets/images/alerts/markers/bees.svg";
import { ReactComponent as Bat } from "../../assets/images/alerts/markers/bat.svg";
import { ReactComponent as Dog } from "../../assets/images/alerts/markers/dog.svg";
import { ReactComponent as Snake } from "../../assets/images/alerts/markers/snake.svg";
import { ReactComponent as Bird } from "../../assets/images/alerts/markers/bird.svg";
import { ReactComponent as EndangeredAnimal } from "../../assets/images/alerts/markers/endangered_animal.svg";
import { ReactComponent as DeadAnimal } from "../../assets/images/alerts/markers/dead_animal.svg";
import { ReactComponent as OtherAnimal } from "../../assets/images/alerts/markers/other_animal.svg";
import { ReactComponent as Aquatic } from "../../assets/images/alerts/markers/aquatic.svg";
import { ReactComponent as PoisonIvy } from "../../assets/images/alerts/markers/poison_ivy.svg";
import { ReactComponent as PoisonOak } from "../../assets/images/alerts/markers/poison_oak.svg";
import { ReactComponent as EndangeredPlant } from "../../assets/images/alerts/markers/flower.svg";
import { ReactComponent as Erosion } from "../../assets/images/alerts/markers/erosion.svg";
import { ReactComponent as NoHerbicide } from "../../assets/images/alerts/markers/no_herbicide.svg";
import { ReactComponent as NoAerialSpray } from "../../assets/images/alerts/markers/no_aerial_spray.svg";
import { ReactComponent as SepticLines } from "../../assets/images/alerts/markers/septic_lines.svg";
import { ReactComponent as VolatileSoils } from "../../assets/images/alerts/markers/volatile_soils.svg";
import { ReactComponent as Flooded } from "../../assets/images/alerts/markers/flooded.svg";
import { ReactComponent as Frozen } from "../../assets/images/alerts/markers/frozen.svg";
import { ReactComponent as DangerousTerrain } from "../../assets/images/alerts/markers/dangerous_terrain.svg";
import { ReactComponent as FallIn } from "../../assets/images/alerts/markers/fall_in.svg";
import { ReactComponent as BlowIn } from "../../assets/images/alerts/markers/blow_in.svg";
import { ReactComponent as GrowIn } from "../../assets/images/alerts/markers/grow_in.svg";
import { ReactComponent as Disease } from "../../assets/images/alerts/markers/disease.svg";
import { ReactComponent as DyingTree } from "../../assets/images/alerts/markers/dying_tree.svg";
import { ReactComponent as DeadTree } from "../../assets/images/alerts/markers/dead_tree.svg";
import { ReactComponent as OtherWorkarea } from "../../assets/images/alerts/markers/other_workarea.svg";
import { ReactComponent as EnvironmentalOther } from "../../assets/images/alerts/markers/environmental_other.svg";
import {
  AccessSubcategories,
  HerbicideSubcategories,
  AnimalSubcategories,
  DangerTreeSubcategories,
  WorkAreaSubcategories,
  EnvironmentSubcategories,
} from "../AlertPopup/AlertPopup.util";

export const getAlertIcon = (subCategoryCode: string) => {
  // access
  if (subCategoryCode === AccessSubcategories.AccessIssue) return AccessIssue;
  if (subCategoryCode === AccessSubcategories.AccessCode) return AccessCode;
  if (subCategoryCode === AccessSubcategories.UtilityLock) return UtilityLock;
  // environmental
  if (subCategoryCode === EnvironmentSubcategories.PoisonIvy) return PoisonIvy;
  if (subCategoryCode === EnvironmentSubcategories.PoisonOak) return PoisonOak;
  if (subCategoryCode === EnvironmentSubcategories.ErosionProtection) return Erosion;
  if (subCategoryCode === EnvironmentSubcategories.EndangeredPlant) return EndangeredPlant;
  if (subCategoryCode === EnvironmentSubcategories.Other) return EnvironmentalOther;
  // animal
  if (subCategoryCode === AnimalSubcategories.Bees) return Bees;
  if (subCategoryCode === AnimalSubcategories.Snake) return Snake;
  if (subCategoryCode === AnimalSubcategories.Dog) return Dog;
  if (subCategoryCode === AnimalSubcategories.Bird) return Bird;
  if (subCategoryCode === AnimalSubcategories.Bat) return Bat;
  if (subCategoryCode === AnimalSubcategories.EndangeredAnimal) return EndangeredAnimal;
  if (subCategoryCode === AnimalSubcategories.DeadAnimal) return DeadAnimal;
  if (subCategoryCode === AnimalSubcategories.Other) return OtherAnimal;
  // herbicide
  if (subCategoryCode === HerbicideSubcategories.AquaticMixOnly) return Aquatic;
  if (subCategoryCode === HerbicideSubcategories.NoHerbicide) return NoHerbicide;
  if (subCategoryCode === HerbicideSubcategories.NoAerialSpray) return NoAerialSpray;
  // workarea
  if (subCategoryCode === WorkAreaSubcategories.SepticLines) return SepticLines;
  if (subCategoryCode === WorkAreaSubcategories.VolatileSoils) return VolatileSoils;
  if (subCategoryCode === WorkAreaSubcategories.Flooded) return Flooded;
  if (subCategoryCode === WorkAreaSubcategories.FrozenGround) return Frozen;
  if (subCategoryCode === WorkAreaSubcategories.DangerousTerrain) return DangerousTerrain;
  if (subCategoryCode === WorkAreaSubcategories.Other) return OtherWorkarea;
  // tree
  if (subCategoryCode === DangerTreeSubcategories.FallIn) return FallIn;
  if (subCategoryCode === DangerTreeSubcategories.BlowIn) return BlowIn;
  if (subCategoryCode === DangerTreeSubcategories.GrowIn) return GrowIn;
  if (subCategoryCode === DangerTreeSubcategories.Disease) return Disease;
  if (subCategoryCode === DangerTreeSubcategories.DyingTree) return DyingTree;
  if (subCategoryCode === DangerTreeSubcategories.DeadTree) return DeadTree;
  return AccessIssue;
};
