import { UpdateWorkTypeRateFieldType, WorkTypeColumnType } from "./WorkTypeRates";

export const checkError = (error: { response: { status: number; data: { message: string } } }) => {
  return error?.response?.status >= 400 && error?.response?.status < 500 && error.response?.data?.message;
};

export const getBulkRequestBodyForWorkTypes = (
  workTypes: Nullable<WorkTypeColumnType[]>,
  regionsByName: Nullable<Record<string, string>>
) => {
  if (!workTypes || !regionsByName) {
    return { workUnits: [] };
  }

  const updatedFields: UpdateWorkTypeRateFieldType[] = [];
  workTypes.forEach((item, index) => {
    if (!item?.dirtyFields?.length) {
      return;
    }
    (item.dirtyFields as string[]).forEach((dirtyField: string) => {
      updatedFields.push({
        regionId: regionsByName[dirtyField as string] as string,
        workTypeId: item.id as string,
        rate: Number(item[dirtyField]),
      });
    });
  });
  return { workUnits: updatedFields };
};
