import styled, { css } from "styled-components";
import { MAP_MENU } from "../../constants";
import { Control } from "../MapControl";

export const StyledControl = styled(Control)<{ isDisabled?: boolean; turnedOn?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${MAP_MENU};
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.easternBlue
      : props.turnedOn
      ? props.theme.colors.easternBlue
      : props.theme.colors.white};
  & > svg {
    fill: transparent;
  }

  &:hover {
    background-color: ${(props) => (props.checked ? props.theme.colors.easternBlue : props.theme.colors.oysterBay)};
  }

  ${({ turnedOn, theme }) =>
    turnedOn &&
    css`
      &:hover {
        background-color: ${theme.colors.easternBlue}};
      }
    `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      cursor: default;
    `}
`;
