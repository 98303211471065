import styled from "styled-components";
import { flexbox, position, PositionProps, FlexboxProps } from "styled-system";

import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";

type CutPlanStyledProps = { selected: boolean } & PositionProps;

export const CutPlanStyled = styled.div<CutPlanStyledProps>`
  ${position}
  z-index: ${CUT_PLAN_Z_INDEX};
  .cut-plan__chain-saw path:first-child {
    fill: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.trout2)};
  }
  .cut-plan__chain-saw path:not(:first-of-type) {
    fill: ${(props) => (props.selected ? "#A6F8FF" : "#1FA7B2")};
  }
  button {
    width: 51px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.42);
    border-radius: 42px;
    height: 34px;
  }
`;

export const CutPlanName = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const ResizeButton = styled.button`
  height: 2px;
  cursor: ns-resize;
  border: none;
  background-color: transparent;
  color: transparent;
`;

export const CutPlanWrapper = styled.div<PositionProps & FlexboxProps & { height?: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${({ height }) => height};
  z-index: ${CUT_PLAN_Z_INDEX};
  background-color: ${(props) => props.theme.colors.white};
  & > div {
    z-index: ${CUT_PLAN_Z_INDEX}!important;
  }
  border: 2px solid var(--colors-alabaster-2);
  border-bottom: none;
  display: flex;
  flex-direction: column;
  resize: vertical;
  ${flexbox}
  ${position}
  max-height: 70%;
  min-height: 33%;
`;

export const CutPlanErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.persimmon};
`;
