import create from "zustand";

export type CustomWorkContainerStore = {
  isLayerVisible: boolean;
  isDrawerVisible: boolean;
  isEditEnabled: boolean;
  actions: {
    toggleLayer: () => void;
    toggleDrawer: (show?: boolean) => void;
    setEditState: (enabled: boolean) => void;
  };
};

const INITIAL_STATE = {
  isLayerVisible: false,
  isDrawerVisible: false,
  isEditEnabled: false,
};

const useCwcStore = create<CustomWorkContainerStore>((set) => ({
  ...INITIAL_STATE,
  actions: {
    toggleLayer: () => set((state) => ({ isLayerVisible: !state.isLayerVisible })),
    toggleDrawer: (show) => set((state) => ({ isDrawerVisible: show ?? !state.isDrawerVisible })),
    setEditState: (enabled) => set({ isEditEnabled: enabled }),
  },
}));

export default useCwcStore;
