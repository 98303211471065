import { GeoJSON } from "react-leaflet";
import { FC, memo, useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { getFeederStyle } from "./MapFeeders.utils";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { EMPTY_FN } from "../../constants";
import { FeedersResponse } from "../../types/responses";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { useMapOptimizedHiddenFlag } from "../../hooks";

interface MapFeederProps {
  geoJSON: Nullable<FeedersResponse>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  onClick: (e: L.LeafletMouseEvent) => void;
  selectedFeeder: Nullable<string>;
  zIndex?: number;
  hiddenFeeders: Nullable<string[]>;
}

const FEEDERS_PANE_NAME = "feeders-pane";

export const MapFeeders: FC<MapFeederProps> = memo(
  ({ geoJSON, hidden = false, map, selectedFeeder, zIndex = 401, onClick = EMPTY_FN, hiddenFeeders }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);

    useCustomMapPane({
      name: FEEDERS_PANE_NAME,
      zIndex,
      map,
    });

    const onEachFeature = useCallback(
      (feature, layer) => {
        layer.on({
          click: onClick,
        });
      },
      [onClick]
    );

    const feeders = useMemo(() => {
      if (isGeoJSONEmpty(geoJSON)) {
        return <></>;
      }

      return geoJSON!.features
        .filter((feeder) => !hiddenFeeders?.length || !hiddenFeeders.find((item) => item === feeder.properties.id))
        .map((item) => (
          <GeoJSON
            data={item}
            key={uuidv4()}
            onEachFeature={onEachFeature}
            pane={FEEDERS_PANE_NAME}
            style={getFeederStyle(item, selectedFeeder)}
          />
        ));
    }, [selectedFeeder, onEachFeature, geoJSON, hiddenFeeders]);

    if (optimizedHidden) {
      return <></>;
    }

    return <>{feeders}</>;
  }
);
