import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../utils/apiClient";
import { getAllWorksheetsForSpanUrl, getPredominatedWorkTypesApiUrl, getWorksheetsForSpansUrl } from "../api";
import { usePredominatedWorkTypesStore } from "../stores/PredominatedWorkTypesStore";
import { UpdateSpanWorksheetsBulkRequestDto } from "../types/responses";

export const useUpdateSpans = (spanId: string) => {
  const isPlannersViewHidden = usePredominatedWorkTypesStore((store) => store.hidden);
  const queryClient = useQueryClient();
  return useMutation(
    (value: UpdateSpanWorksheetsBulkRequestDto) => ApiClient.put(getAllWorksheetsForSpanUrl(), value),
    {
      onSuccess: () => {
        // Update RHS dropdown
        queryClient.invalidateQueries(`${getWorksheetsForSpansUrl()}?spanIds=${spanId}`);
        // Update span color and WT marker on map
        if (!isPlannersViewHidden) queryClient.invalidateQueries(getPredominatedWorkTypesApiUrl());
      },
    }
  );
};
