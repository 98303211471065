import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  computeBreadcrumbsHierarchy,
  getTaxonomyItemId,
  getTaxonomyItemType,
  sortTaxonomyData,
} from "./LeftSideControl.utils";
import { LeftSideControlStyled, PrioritySection, LeftSideContentStyled, PriorityTitle } from ".";
import { useFeederStore, useHierarchyStore, useLeftControlStore, useMapStore, useSpansStore } from "../../../stores";
import { Taxonomy } from "../../../components/Taxonomy/";
import { Breadcrumb, Breadcrumbs } from "../../../components/Breadcrumbs";
import { getForecastRoute } from "../../../routes";
import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import {
  getSearchParams,
  resetToFeederLevelSearchParams,
  resetToRegionLevelSearchParams,
  resetToLeftMenuSearchParams,
  deleteFeederIdParam,
  deleteSpanRiskPriorityParam,
  setFeederIdParam,
  deleteSpanIdParam,
  setSpanIdParam,
  setSpanRiskPriorityParam,
  setRegionIdParam,
  setVisibleDrawerParam,
} from "../../../utils/navigationUtils";
import { TaxonomyItem } from "../../../types/TaxonomyItem";
import { getFeederName } from "../MapView/MapView.utils";
import { SpanRiskPriority } from "../../../stores";
import { PrioritySelect } from "../../../components/PrioritySelect";
import useFeederSegmentStore from "../../../stores/FeederSegmentStore";
import { RISK_PRIORITY } from "../../../types/responses";

const LeftSideControl = () => {
  const toggled = useLeftControlStore((store) => store.toggled);
  const selectedSpanId = useSpansStore((store) => store.selectedId);
  const selectedFeeder = useFeederStore((store) => store.selectedFeeder);
  const { getFeederDataById } = useFeederStore((store) => store.actions);
  const setHoveredObjectId = useMapStore((store) => store.setHoveredObjectId);
  const isFeederLevel = useHierarchyStore((store) => store.isFeederLevel);
  const spanRiskPriority = useHierarchyStore((store) => store.riskPrioritySelected);
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );
  const { hideFeeder } = useFeederStore((store) => store.actions);
  const hiddenFeeders = useFeederStore((store) => store.hiddenFeeders);
  const { resetHiddenFeeders } = useFeederStore((store) => store.actions);
  const setFeederSegment = useFeederSegmentStore((store) => store.setSelectedFeederSegmentById);
  const history = useHistory();

  const currentRegionSelected = useHierarchyStore((store) => store.currentRegion);
  const rootRegion = useHierarchyStore((store) => store.rootRegion);
  const regions = useHierarchyStore((store) => store.regions);

  const [filterRiskPriority, setFilterRiskPriority] = useState<Nullable<RISK_PRIORITY>>(null);

  const selectedFeederId =
    selectedFeeder &&
    regions?.[currentRegionSelected ?? ""]?.regions?.find((region) => region.properties.feederId === selectedFeeder)
      ?.properties.id;

  const currentRegion = currentRegionSelected ?? rootRegion;

  const taxonomyData: TaxonomyItem[] = useMemo(() => {
    if (!currentRegion || !regions?.[currentRegion]?.regions) {
      return [];
    }
    // region data
    return regions?.[currentRegion]?.regions.map((region) => {
      const data: Nullable<TaxonomyItem[]> =
        selectedFeederId && region.properties.feederId
          ? regions?.[selectedFeederId]?.regions?.map((span) => ({
              name: span.properties.name,
              id: span.properties.spanId ?? "",
              type: "span",
              riskPriority: span.properties.riskPriority?.priority ?? RISK_PRIORITY.LOW,
              riskScore: span.properties.riskPriority?.score || null,
              feederSegmentId: span.properties.feederId,
            })) || null
          : null;
      const sortedData = data ? data.sort(sortTaxonomyData) : data;
      const feederProperties = region.properties.feederId
        ? getFeederDataById(region.properties.feederId)?.properties
        : null;
      return {
        riskScore: region?.properties?.riskPriority?.score || null,
        id: getTaxonomyItemId(region.properties),
        name: feederProperties ? `${getFeederName(feederProperties, false)} - ` : region.properties.name,
        type: getTaxonomyItemType(region.properties),
        riskPriority: region?.properties?.riskPriority?.priority ?? RISK_PRIORITY.LOW,
        data: sortedData,
        regionId: region.properties.id,
        additionalLabel: feederProperties ? getFeederName(feederProperties, true) : null,
      };
    });
  }, [currentRegion, getFeederDataById, regions, selectedFeederId]);

  const sortedTaxonomyData = useMemo(() => taxonomyData.sort(sortTaxonomyData), [taxonomyData]);

  const breadcrumbs = useMemo(
    () =>
      computeBreadcrumbsHierarchy({
        regions,
        selectedRegion: currentRegionSelected,
        selectedFeeder: getFeederDataById(selectedFeeder),
        rootRegionId: rootRegion,
      }),
    [regions, selectedFeeder, getFeederDataById, rootRegion, currentRegionSelected]
  );

  const onSelect = useCallback(
    (item: TaxonomyItem, riskPriority?: SpanRiskPriority) => {
      const queryParams = getSearchParams();

      // allow animation for spans
      setLatestObjectIdSelectedForMapAnimation(item.type === "span" || !riskPriority ? item.id : riskPriority);

      if (riskPriority) {
        if (riskPriority === spanRiskPriority && item.type === "feeder" && selectedFeeder === item.id) {
          deleteSpanRiskPriorityParam({ params: queryParams });
        } else {
          setSpanRiskPriorityParam({ params: queryParams, id: riskPriority });
        }
      } else {
        deleteSpanRiskPriorityParam({ params: queryParams });
      }

      if (item.type === "region") {
        setRegionIdParam({ params: queryParams, id: item.id });
        // deselect encroachment/feeder on region change
        resetToRegionLevelSearchParams(queryParams);
      }

      if (item.type === "feeder") {
        // deselect encroachment and span in either case
        resetToFeederLevelSearchParams(queryParams);
        // if both risk priority and item are set it means that user has clicked on risk priority filter, no need to deselect feeder
        if (selectedFeederId === item.id && !riskPriority) {
          // deselect feeder
          deleteFeederIdParam({ params: queryParams });
          deleteSpanRiskPriorityParam({ params: queryParams });

          return history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
        }
        setFeederIdParam({ id: item.id, params: queryParams });
      }

      if (item.type === "span") {
        // deselect encroachment in either case
        resetToLeftMenuSearchParams(queryParams);
        if (selectedSpanId === item.id) {
          // deselect encroachment and span
          deleteSpanIdParam({ params: queryParams });
          return history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
        }
        setSpanIdParam({ params: queryParams, id: item.id });
        setVisibleDrawerParam({ params: queryParams, visible: true });
        item.feederSegmentId && setFeederSegment(item.feederSegmentId);
      }

      return history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
    },
    [
      history,
      selectedSpanId,
      setLatestObjectIdSelectedForMapAnimation,
      spanRiskPriority,
      setFeederSegment,
      selectedFeederId,
      selectedFeeder,
    ]
  );

  const onBreadcrumbSelect = (breadcrumb: Breadcrumb) => {
    const queryParams = getSearchParams();
    if (!breadcrumb.key) {
      // root element selected, deselect all others
      history.push({ pathname: getForecastRoute(), search: "" });
      resetHiddenFeeders();
      filterRiskPriority !== null && setFilterRiskPriority(null);
      return;
    }
    // return to region level, deselect feeder/encroachment
    if (breadcrumb.key !== FORECAST_QUERY_PARAMS.FEEDER_ID) {
      resetToRegionLevelSearchParams(queryParams);
    }
    queryParams.set(breadcrumb.key, breadcrumb.value);
    history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
  };

  const menuName = isFeederLevel ? "CIRCUITS" : "REGIONS";
  return (
    <LeftSideControlStyled toggled={toggled}>
      <Breadcrumbs data={breadcrumbs} onClick={onBreadcrumbSelect} />
      <PrioritySection>
        <PriorityTitle>{menuName}</PriorityTitle>
        {isFeederLevel && <PrioritySelect selectRiskPriority={setFilterRiskPriority} selected={filterRiskPriority} />}
      </PrioritySection>
      <LeftSideContentStyled>
        <Taxonomy
          data={sortedTaxonomyData}
          onSelect={onSelect}
          selectedItem={selectedFeeder}
          selectedSubItem={selectedSpanId}
          setHoveredObjectId={setHoveredObjectId}
          spanRiskPriority={spanRiskPriority}
          hideFeeder={hideFeeder}
          hiddenFeeders={hiddenFeeders}
          selectedRiskPriority={filterRiskPriority}
        />
      </LeftSideContentStyled>
    </LeftSideControlStyled>
  );
};

export default LeftSideControl;
