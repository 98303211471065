import React, { FC, useEffect, createRef } from "react";
import { Wrapper } from "./Backdrop.styled";
import { useKeyPress } from "../../hooks/useKeyPress";

interface Props {
  isHidden: boolean;
  setIsHidden: () => void;
}

export const Backdrop: FC<Props> = ({ children, isHidden, setIsHidden }) => {
  const escPressed = useKeyPress("Escape");
  const backdropRef = createRef<HTMLDivElement>();

  useEffect(() => {
    backdropRef?.current?.addEventListener("click", (e: any) => {
      const isBackdrop = e?.target?.classList?.contains("backdrop");
      if (isBackdrop) setIsHidden();
    });
  }, [backdropRef, setIsHidden]);

  useEffect(() => {
    escPressed && setIsHidden();
  }, [escPressed, setIsHidden]);

  if (isHidden) return null;

  return (
    <Wrapper ref={backdropRef} className="backdrop">
      {children}
    </Wrapper>
  );
};
