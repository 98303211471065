import React, { FC, SyntheticEvent, useRef, useState, ReactElement } from "react";

import { getWorkTypeImage } from "../../pages/MapScreen/MapScreen.utils";
import {
  WorkTypeCost,
  WorkTypeExtendedName,
  WorkTypeImage,
  WorkTypeLeftSide,
  WorkTypeNameWrapper,
  WorkTypeRightSide,
  WorkTypeWrapper,
  MoreOptions,
  OptionButton,
  Tooltip,
  DeleteWorktypeButton,
  AdditionalLabelText,
} from "./WorkTypeItem.styled";
import { ReactComponent as CloseIcon } from "./../../assets/images/close.svg";
import { ReactComponent as MoreIcon } from "./../../assets/images/more.svg";
import { toUsdCurrency } from "../../utils/stringUtils";
import { useClickOutside } from "../../hooks";
import { ReactComponent as AIImage } from "./../../assets/images/AI.svg";

interface ISlots {
  additionalDescription: React.ReactNode | null;
}
interface IAdditionalDescription {
  label: string;
  onClick: () => void;
}
interface IWorkTypeComposition {
  AdditionalDescription: React.FC<IAdditionalDescription>;
}
interface WorkTypeItemProps {
  deleteWorkType: (workTypeId: string, objectId?: string, manuallyAdded?: boolean, cost?: number) => void;
  cost: number;
  name: string;
  code: string;
  id: string;
  objectId?: string;
  manuallyAdded?: boolean;
  isEditMode?: boolean;
  additionalLabel?: string;
  isAutoAssigned?: boolean;
  showOnlyDelete?: boolean;
  children?: ReactElement<IWorkTypeComposition> | ReactElement<IWorkTypeComposition>[];
  assignToNewWorkorder?: (workTypeId: string) => void;
}

const AdditionalDescription: FC<IAdditionalDescription> = ({ label, onClick }) => {
  return <AdditionalLabelText onClick={onClick}>{label}</AdditionalLabelText>;
};

export const WorkTypeItem: FC<WorkTypeItemProps> & IWorkTypeComposition = ({
  deleteWorkType,
  cost,
  name,
  code,
  id,
  objectId,
  assignToNewWorkorder,
  showOnlyDelete = false,
  manuallyAdded = false,
  isEditMode = true,
  additionalLabel = null,
  isAutoAssigned = false,

  children,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const moreOptionsRef = useRef(null);
  useClickOutside(moreOptionsRef, () => showOptions && setShowOptions(false));

  const slots: ISlots = {
    additionalDescription: null,
  };

  React.Children.forEach(children, (child: ReactElement | undefined) => {
    if (!child) return;
    // Filter acceptable children
    switch (child.type) {
      case WorkTypeItem.AdditionalDescription: {
        slots.additionalDescription = child;
      }
    }
  });

  const handleDeleteWT = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isEditMode) return;

    deleteWorkType(id, objectId, manuallyAdded, cost);
  };

  return (
    <WorkTypeWrapper isEditMode={isEditMode}>
      <WorkTypeLeftSide>
        <WorkTypeImage src={getWorkTypeImage(code)} />
        <WorkTypeNameWrapper hasAdditionalText={additionalLabel !== null}>
          <WorkTypeExtendedName isAutoAssigned={isAutoAssigned}>
            <div title={name} className="worktype-name">
              {name}
            </div>{" "}
            {isAutoAssigned && <AIImage />}
          </WorkTypeExtendedName>
          {additionalLabel !== null && <span className="additionalLabel">{additionalLabel}</span>}
          {slots.additionalDescription}
        </WorkTypeNameWrapper>
      </WorkTypeLeftSide>
      <WorkTypeRightSide>
        <WorkTypeCost>{toUsdCurrency(cost)}</WorkTypeCost>
        {showOnlyDelete ? (
          <DeleteWorktypeButton Icon={CloseIcon} onClick={handleDeleteWT} />
        ) : (
          <MoreOptions
            onClick={() => setShowOptions(isEditMode && !showOptions)}
            isEditMode={isEditMode}
            ref={moreOptionsRef}
            className={`${showOptions ? "open" : ""}`}
          >
            <MoreIcon />
            {
              <div>
                {assignToNewWorkorder && (
                  <OptionButton onClick={() => assignToNewWorkorder(id)}>Assign to New Work Order</OptionButton>
                )}
                <OptionButton onClick={handleDeleteWT}>Delete</OptionButton>
              </div>
            }
            <Tooltip>Click Edit to enable this button</Tooltip>
          </MoreOptions>
        )}
      </WorkTypeRightSide>
    </WorkTypeWrapper>
  );
};

WorkTypeItem.AdditionalDescription = AdditionalDescription;
