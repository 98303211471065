import { useEffect } from "react";
import { useHistory } from "react-router";

import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import { getForecastRoute } from "../../../routes";
import { ASSET_TYPES, useMapSelectionStore } from "../../../stores";
import {
  deleteParcelIdParam,
  deleteSpanIdParam,
  deleteStructureIdParam,
  getQueryParamValue,
  getSearchParams,
} from "../../../utils/navigationUtils";

export const useDynamicSelectedAsset = () => {
  const selectedAsset = useMapSelectionStore((store) => store.selectedAssetType);
  const selectedLayers = useMapSelectionStore((store) => store.selectedLayers);

  const history = useHistory();

  useEffect(() => {
    if (!selectedAsset || !selectedLayers?.[selectedAsset]?.length) {
      return;
    }

    const queryParams = getSearchParams();

    if (selectedAsset === ASSET_TYPES.SPAN) {
      const spanId = getQueryParamValue(FORECAST_QUERY_PARAMS.SPAN_ID)!;
      if (spanId && selectedLayers?.[ASSET_TYPES.SPAN]?.find((id) => id === spanId) === undefined) {
        deleteSpanIdParam({ params: queryParams });
      }
    }

    if (selectedAsset === ASSET_TYPES.STRUCTURE) {
      const structureId = getQueryParamValue(FORECAST_QUERY_PARAMS.STRUCTURE_ID)!;
      if (structureId && selectedLayers?.[ASSET_TYPES.STRUCTURE]?.find((id) => id === structureId) === undefined) {
        deleteStructureIdParam({ params: queryParams });
      }
    }
    if (selectedAsset === ASSET_TYPES.PARCEL) {
      const parcelId = getQueryParamValue(FORECAST_QUERY_PARAMS.PARCEL_ID)!;
      if (parcelId && selectedLayers?.[ASSET_TYPES.PARCEL]?.find((id) => id === parcelId) === undefined) {
        deleteParcelIdParam({ params: queryParams });
      }
    }
    history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
  }, [selectedAsset, selectedLayers, history]);
};
