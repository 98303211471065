import { FC } from "react";
import { LayoutProps } from "styled-system";
import { Label, InputWrapper } from "./InputUpload.styled";

const ALLOWED_FILE_TYPE =
  ".jpg, .jpeg, .png, .mp4, .mov, .mpeg4, .pdf, .doc, .txt, .docx, .doc, .odt, .pages, .rtf, .ppt, .pptx, .odp, .key, .xls, .xlsx, .numbers, .ods";

interface InputProps extends LayoutProps {
  actionName: string;
  allowedFileTypes?: string;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputUpload: FC<InputProps> = ({
  actionName,
  allowedFileTypes = ALLOWED_FILE_TYPE,
  onUpload,
  ...props
}) => (
  <InputWrapper {...props}>
    <input
      id="files"
      type="file"
      accept={allowedFileTypes}
      multiple
      onChange={(e) => {
        onUpload(e);
        e.target.value = "";
      }}
    />

    <Label htmlFor="files">{actionName}</Label>
  </InputWrapper>
);
