import React, { FC } from "react";
import { LayoutProps } from "styled-system";
import { ThumbnailWrapper, DeleteButton } from "./Thumbnail.styled";
import { ReactComponent as PdfIcon } from "./../../assets/images/pdf.svg";
import { ReactComponent as VideoIcon } from "./../../assets/images/video.svg";

export interface FileAttachment {
  id: string;
  type: "PDF" | "IMAGE" | "VIDEO";
  fileName: string;
  url: string;
}
export type Attachments = {
  attachments: FileAttachment[];
};

interface Props extends LayoutProps {
  file: FileAttachment;
  onClick?: () => void;
  onDelete?: (fileId: string) => void;
  isSelected?: boolean;
}

export const Thumbnail: FC<Props> = ({ file, onClick, onDelete, isSelected, ...props }) => (
  <ThumbnailWrapper onClick={onClick} isSelected={isSelected} {...props}>
    {file.type === "PDF" && <PdfIcon />}
    {file.type === "IMAGE" && <img src={file.url} width="100%" height="100%" alt={file.fileName} />}
    {file.type === "VIDEO" && <VideoIcon />}
    {onDelete && (
      <DeleteButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete(file.id);
        }}
      />
    )}
  </ThumbnailWrapper>
);
