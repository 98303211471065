import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TREE_CANOPY_POPUP_CLASS_NAME } from "../../components/MapTreeCanopyLayer/MapTreeCanopyLayer.constants";

import { FORECAST_QUERY_PARAMS } from "../../constants/navigation";
import { getForecastRoute } from "../../routes";
import { useFeederStore, useHierarchyStore, useMapStore, useSpansStore, useThreatsStore } from "../../stores";
import useFeederSegmentStore from "../../stores/FeederSegmentStore";
import useParcelsStore from "../../stores/ParcelsStore";
import useStructuresStore from "../../stores/StructuresStore";
import { RISK_PRIORITY } from "../../types/responses";
import {
  deleteEncroachmentIdParam,
  deleteFeederIdParam,
  deleteParcelIdParam,
  deleteSpanIdParam,
  deleteSpanRiskPriorityParam,
  deleteStructureIdParam,
  getQueryParamValue,
  getSearchParams,
  hasQueryParam,
  setVisibleDrawerParam,
} from "../../utils/navigationUtils";

export const useDynamicQueryParams = ({ map }: { map: Nullable<L.Map> }) => {
  const { setSelectedFeederById } = useFeederStore((store) => store.actions);
  const setSelectedImagerySource = useMapStore((store) => store.setSelectedImagerySource);
  const { selectThreat } = useThreatsStore((store) => store.actions);
  const setSelectedStructureId = useStructuresStore((store) => store.setSelectedId);
  const setSelectedParcelId = useParcelsStore((store) => store.setSelectedId);
  const selectedImagerySourceId = useMapStore((store) => store.selectedImagerySourceId);
  const setSelectedSpanById = useSpansStore((store) => store.setSelectedSpanById);
  const { setSpanRiskPriority } = useHierarchyStore((store) => store.actions);
  const setFeederSegment = useFeederSegmentStore((store) => store.setSelectedFeederSegmentById);
  const feederSegments = useFeederSegmentStore((store) => store.geoJSON);

  const { setCurrentRegion } = useHierarchyStore((store) => store.actions);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!map) {
      return;
    }
    const feederId = getQueryParamValue(FORECAST_QUERY_PARAMS.FEEDER_ID);

    setSelectedImagerySource(getQueryParamValue(FORECAST_QUERY_PARAMS.SOURCE_ID)!);
    setCurrentRegion(getQueryParamValue(FORECAST_QUERY_PARAMS.REGION_ID)!);
    setSelectedFeederById(feederId!);
    selectThreat(getQueryParamValue(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID)!);
    setSelectedSpanById(getQueryParamValue(FORECAST_QUERY_PARAMS.SPAN_ID)!);
    setSelectedStructureId(getQueryParamValue(FORECAST_QUERY_PARAMS.STRUCTURE_ID));
    setSelectedParcelId(getQueryParamValue(FORECAST_QUERY_PARAMS.PARCEL_ID));
    setSpanRiskPriority(getQueryParamValue(FORECAST_QUERY_PARAMS.SPAN_RISK_PRIORITY) as RISK_PRIORITY, feederId);

    const feederSegment = feederSegments?.features.find((item) => item.properties.feederId === feederId);
    setFeederSegment(feederSegment?.properties.id || null);
  }, [
    location.search,
    map,
    setSelectedFeederById,
    setSelectedImagerySource,
    selectThreat,
    selectedImagerySourceId,
    setSelectedSpanById,
    setSelectedStructureId,
    setSelectedParcelId,
    setSpanRiskPriority,
    setFeederSegment,
    feederSegments,
    setCurrentRegion,
  ]);

  // deselect object on map click
  useEffect(() => {
    if (!map) {
      return;
    }

    map.on("click", (e) => {
      const queryParams = getSearchParams();
      //don't delete params to keep RHS visible only for encroachment and span
      if (e.sourceTarget?._popup?.options?.className === TREE_CANOPY_POPUP_CLASS_NAME) {
        return;
      }
      if (hasQueryParam(FORECAST_QUERY_PARAMS.PARCEL_ID)) {
        deleteParcelIdParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.STRUCTURE_ID)) {
        deleteStructureIdParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID)) {
        deleteEncroachmentIdParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.SPAN_ID)) {
        deleteSpanIdParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.FEEDER_ID)) {
        deleteFeederIdParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.SPAN_RISK_PRIORITY)) {
        deleteSpanRiskPriorityParam({ params: queryParams });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER)) {
        setVisibleDrawerParam({ params: queryParams, visible: false });
      }

      if (hasQueryParam(FORECAST_QUERY_PARAMS.SPAN_RISK_PRIORITY)) {
        deleteSpanRiskPriorityParam({ params: queryParams });
      }

      history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
    });
  }, [map, history]);
};
