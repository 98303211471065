import React, { FC, ReactNode, memo } from "react";
import { TooltipPopup, useTooltip } from "@reach/tooltip";

import { TriangleTooltipContent } from "./TriangleTooltip.styled";
import { Portal } from "../Portal";
import "./TriangleTooltip.styles.css";

interface TriangleTooltipProps {
  label: ReactNode | string;
  under?: boolean;
  light?: boolean;
  marginTop?: number;
}

export const MARGIN = 90;

export const TRIANGLE_HEIGHT = 9;

const centered = (triggerRect: any, tooltipRect: any, under: boolean, marginTop: number) => {
  if (!triggerRect || !tooltipRect) {
    return { left: 0, top: 0 };
  }

  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + window.scrollY - (under ? MARGIN : -TRIANGLE_HEIGHT) + marginTop,
  };
};

const calcTop = (bottom: Nullable<number>, under: Maybe<boolean>, scrollY: number, marginTop: number) => {
  if (bottom) {
    return (under ? bottom + scrollY : bottom + scrollY + MARGIN / 2) + marginTop;
  }
};

export const TriangleTooltip: FC<TriangleTooltipProps> = memo(
  ({ children, label, under = true, light = true, marginTop = 0 }) => {
    const [trigger, tooltip] = useTooltip();
    const { isVisible, triggerRect } = tooltip;

    const bottom = triggerRect && triggerRect.bottom - MARGIN / 2;

    return (
      <React.Fragment>
        {/* @ts-ignore */}
        {React.cloneElement(children, trigger)}

        {isVisible && (
          <Portal selector="layer-menu-root">
            <TriangleTooltipContent
              left={triggerRect && triggerRect.left}
              bottom={bottom}
              width={triggerRect && triggerRect.width}
              scrollY={window.scrollY}
              rotate={!under ? 180 : undefined}
              top={calcTop(bottom, under, window.scrollY, marginTop)}
              light={light}
            />
          </Portal>
        )}
        <TooltipPopup
          className={`${!light ? "triangle-tooltip-dark" : "triangle-tooltip"}`}
          {...tooltip}
          label={label}
          aria-label={"area-label"}
          position={(triggerRect, tooltipRect) => {
            return centered(triggerRect, tooltipRect, under, under ? 0 : marginTop);
          }}
        />
      </React.Fragment>
    );
  }
);
