import { FC } from "react";
// @ts-ignore
import MarkerClusterGroup from "react-leaflet-markercluster";

import "./MapMarkerCluster.styles.css";

interface MarkerClusterGroupProps {
  disableClusteringAtZoom?: number;
  maxClusterRadius?: number;
}

export const MapMarkerCluster: FC<MarkerClusterGroupProps> = ({
  children,
  disableClusteringAtZoom = 15,
  maxClusterRadius = 80,
  ...props
}) => (
  <MarkerClusterGroup
    removeOutsideVisibleBounds={true}
    showCoverageOnHover={false}
    zoomToBoundsOnClick={false}
    disableClusteringAtZoom={disableClusteringAtZoom}
    maxClusterRadius={maxClusterRadius}
    {...props}
  >
    {children}
  </MarkerClusterGroup>
);
