export const uniq = <T>(array: T[]) => Array.from(new Set(array));

export const uniqByField = <T>(array: T[], getKey: (item: T) => string): T[] =>
  Array.from(new Map(array.map((item: any) => [getKey(item), item])).values());

export const reorder = <TArray>(list: TArray[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
