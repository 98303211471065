import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import ReactSelect from "react-select";

export const StyledSelect = styled(ReactSelect)<LayoutProps & SpaceProps>`
  ${layout};
  ${space}
  box-sizing: border-box;
  .react-select__control,
  .react-select__value-container,
  .react-select__input,
  .react-select__input input {
    height: 32px;
  }
  .react-select__control {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
  }
  .react-select__control:hover {
    background-color: var(--colors-link-water);
  }
  .react-select__control:focus {
    background-color: var(--colors-link-water);
    border-width: 1px;
  }
  .react-select__single-value {
    font-family: "Barlow";
    font-weight: 500;
    font-size: 16px;
    color: var(--colors-river-bad);
    top: 45%;
  }
  .react-select__menu,
  .react-select__option--is-focused {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};
  }
  .react-select__option {
    color: var(--colors-river-bad);
  }
  .react-select__option:hover {
    background-color: var(--colors-link-water);
    color: var(--colors-river-bad);
  }
  .react-select__option--is-selected {
    color: #118f9a;
    background-color: var(--colors-white);
  }

  .react-select__option--is-disabled {
    color: #898ea1;
  }
  .react-select__option--is-disabled.react-select__option:hover {
    background-color: var(--colors-white);
    color: #898ea1;
  }
  .react-select__indicator-separator {
    background-color: transparent;
  }
  .react-select__control--is-disabled {
    background-color: transparent;
    border-width: 0px;
  }
  .react-select__control--is-disabled .react-select__value-container {
    padding-left: 0px;
  }
  .react-select__control--is-disabled .react-select__indicators {
    display: none;
  }
  .react-select__control--is-focused,
  .react-select__control:hover {
    border-color: var(--colors-eastern-blue);
    box-shadow: 0px 0px 0px 1px var(--colors-eastern-blue);
  }
  .react-select__control {
    min-height: 32px;
    box-sizing: border-box;
  }
  .react-select__indicators {
    height: 32px;
  }
`;
