import { EncroachmentResponse } from "./EncroachmentsResponse";
import { RegionsResponse } from "./RegionsResponse";
import { Feature, Polygon, Properties } from "@turf/turf";

export enum RISK_PRIORITY {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
  VIOLATION = "VIOLATION",
}

export type RiskPriority = {
  priority: RISK_PRIORITY;
  score: number;
};

export type ThreatHierarchyResponse = {
  threats: EncroachmentResponse[];
  regionsByParent: Record<string, RegionsResponse[]>;
  riskPriorities: Record<string, RiskPriority>;
};

export type ThreatDto = {
  threat: Feature<Polygon, Properties>;
};
