import create from "zustand";

import { EMPTY_FN } from "../constants";
import { RISK_PRIORITY, SpansResponse, SpansResponseProperties, WorkTypeResponse } from "../types/responses";
import { GetPredominantWorkResponseDto } from "../types/responses/SpanWorkTypeResponse";

export type SpansById = Record<string, SpansResponseProperties>;

export type SpansByFeeder = Record<string, GeoJSON.Feature<GeoJSON.Geometry, SpansResponseProperties>[]>;

export type SpanRiskPriority = Nullable<RISK_PRIORITY>;

export type SpansStore = {
  byId: SpansById;
  geoJSON: Nullable<SpansResponse>;
  selectedId: Nullable<string>;
  spansWithPredominatedWorkType: Nullable<Record<string, WorkTypeResponse>>;
  getById: (id: string) => Nullable<SpansResponseProperties>;
  resetStore: () => void;
  setData: (spans: SpansResponse) => void;
  addSpan: (span: SpansResponseProperties) => void;
  setSelectedSpanById: (selectedSpanId: Maybe<string>) => void;
  setSpanWithPredominatedWorkType: (spans: Nullable<Record<string, WorkTypeResponse>>) => void;
};

const INITIAL_STATE = {
  byFeederId: {},
  byId: {},
  geoJSON: null,
  positions: {},
  selectedId: null,
  spansWithPredominatedWorkType: null,
  getById: () => {
    return null;
  },
  resetStore: EMPTY_FN,
  setSpanWithPredominatedWorkType: EMPTY_FN,
  setData: EMPTY_FN,
  setSelectedSpanById: EMPTY_FN,
  addSpan: EMPTY_FN,
};

export const useSpansStore = create<SpansStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setData: (spans) => set({ geoJSON: spans }),
  getById: (id: string) => get().byId?.[id],
  setSelectedSpanById: (selectedSpanId) => {
    if (selectedSpanId && selectedSpanId === get().selectedId) {
      return;
    }
    set({
      selectedId: selectedSpanId || null,
    });
  },
  setSpanWithPredominatedWorkType: (spansWithPredominatedWorkType) => {
    set({ spansWithPredominatedWorkType });
  },
  addSpan: (span) => {
    const byId = { ...get().byId };
    byId[span.id] = span;
    set({ byId });
  },
}));

const mapSpansById = (collection: GeoJSON.FeatureCollection<GeoJSON.Geometry, SpansResponseProperties>) => {
  return collection.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr.properties as SpansResponseProperties;

    return prev;
  }, {} as SpansById);
};

export const mapSpansResponse = (geoJSON: SpansResponse, previousById: SpansById) => {
  const spansById = mapSpansById(geoJSON);
  const byId = previousById && Object.keys(previousById)?.length ? { ...previousById, ...spansById } : spansById;
  return {
    byId,
    geoJSON,
  };
};
export const mapSpansWithWorkTypes = (
  response: GetPredominantWorkResponseDto
): Nullable<Record<string, WorkTypeResponse>> => {
  if (!response || !response?.spanPredominantWorkTypes || !response.workTypes) {
    return null;
  }
  const spanWithWorkTypes: Record<string, WorkTypeResponse> = {};
  response.spanPredominantWorkTypes.forEach((span) => {
    const workType = response.workTypes.find((workType) => workType.id === span.predominantWorkTypeId);
    if (workType) {
      spanWithWorkTypes[span.spanId] = workType;
    }
  });
  return spanWithWorkTypes;
};

export default useSpansStore;
