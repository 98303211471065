import React, { FC, useState, InputHTMLAttributes } from "react";

import { Range, RangeContainer, RangePercents } from "./RangeInput.styled";
import { roundValue } from "../../utils/unitHelper";

export interface RangeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: number;
  onClick: React.MouseEventHandler<HTMLInputElement>;
  border?: string;
  showPercentage?: boolean;
}

export const calculatePercent = (value: number) => {
  return value * 100;
};

export const RangeInput: FC<RangeInputProps> = ({ defaultValue, onClick, border, showPercentage = true, ...props }) => {
  const [percent, setPercent] = useState(calculatePercent(defaultValue));
  return (
    <RangeContainer border={border}>
      <Range
        percent={percent}
        type="range"
        defaultValue={defaultValue}
        onClick={onClick}
        min="0"
        max="1"
        step="0.1"
        {...props}
        onChange={(e) => {
          setPercent(calculatePercent(Number(e.target.value)));
        }}
      />
      {showPercentage && (
        <RangePercents>
          {roundValue(percent, 2)}
          {"%"}
        </RangePercents>
      )}
    </RangeContainer>
  );
};
