import { useState, useRef } from "react";
import { StyledGrid, StyledFlex } from "../../assets/styles/flex.styled";
import {
  Wrapper,
  Title,
  StyledLink,
  FilterButton,
  FilterDropdown,
  FilterItem,
  StyledSelect,
  StyledOption,
} from "./PlanDashboard.styled";
import { createPlanRoute } from "../../routes";
import { PlanDto, PLAN_STATUS } from "../../types/responses";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import useClickOutside from "../../hooks/useClickOutside";
import usePlans from "../../hooks/usePlans";
import { Chip } from "../Chip";
import { PlanCard } from "../PlanCard";
import { Option } from "../Select";
import { Spinner } from "../Loader/Spinner";

const MIN_YEAR = 2000;
const MAX_YEAR = 2040;
const range = (min: number, max: number) => Array.from({ length: max - min + 1 }, (_, i) => min + i);
const YEARS_ARRAY = range(MIN_YEAR, MAX_YEAR);
const YEARS_OPTIONS = YEARS_ARRAY.map((value) => ({ label: value.toString(), value: value.toString() }));

type FilterType = "status" | "year";
interface IChip {
  type: FilterType;
  value: string;
}

const byEndDate = (a: PlanDto, b: PlanDto) => {
  const endtDateA = new Date(a.end).getTime();
  const endDateB = new Date(b.end).getTime();

  return endtDateA < endDateB ? -1 : 1;
};

export const PlanDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredFilter, setHoveredFilter] = useState<FilterType>("status");
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isOpen && setIsOpen(false));
  const [chips, setChips] = useState<IChip[]>([]);
  const { data: plans, isLoading: arePlansLoading } = usePlans();

  const addFilter = ({ type, value }: IChip) => setChips((prevChips) => [...prevChips, { type, value }]);

  const resetFilter = (type: string, value: string) =>
    setChips((prevChips) => prevChips.filter((chip) => !(chip.type === type && chip.value === value)));

  const byChips = (plan: PlanDto) => {
    if (chips.length === 0) return true;
    return chips.some((chip) => {
      if (chip.type === "year") {
        const startYear = new Date(plan.start).getFullYear().toString();
        const endYear = new Date(plan.end).getFullYear().toString();
        return chip.value === startYear || chip.value === endYear;
      }
      if (chip.type === "status") {
        return chip.value.toLocaleLowerCase() === plan.status.toLocaleLowerCase();
      }
      return true;
    });
  };

  return (
    <Wrapper>
      <StyledFlex
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="10px"
        borderBottom="1px solid #EEEEEE"
        flexWrap="wrap"
      >
        <Title>Admin plan dashboard</Title>
        <StyledLink to={createPlanRoute}>New Plan</StyledLink>
      </StyledFlex>
      <StyledFlex marginTop="30px" alignItems="center" position="relative" gap="20px" maxWidth="1187px">
        {chips.map(
          (chip, idx) =>
            chip.value !== "" && <Chip key={idx} value={chip.value} type={chip.type} onDelete={resetFilter} />
        )}

        {plans?.length !== 0 && <FilterButton onClick={() => setIsOpen(!isOpen)}>Filter</FilterButton>}
        <FilterDropdown data-visible={isOpen} ref={wrapperRef}>
          <StyledFlex borderRight="1px solid #F0F0F0" flexDirection="column">
            <FilterItem
              className={hoveredFilter === "status" ? "isSelected" : ""}
              onClick={() => setHoveredFilter("status")}
            >
              Status
            </FilterItem>
            <FilterItem
              className={hoveredFilter === "year" ? "isSelected" : ""}
              onClick={() => setHoveredFilter("year")}
            >
              Year
            </FilterItem>
          </StyledFlex>
          <StyledFlex flexDirection="column" height="208px" overflow="hidden">
            {hoveredFilter === "status" ? (
              Object.entries(PLAN_STATUS).map(([key, value], idx) => (
                <FilterItem
                  key={idx}
                  onClick={() => {
                    const isSelected = chips.some((chip) => chip.type === "status" && chip.value === value);
                    if (isSelected) {
                      resetFilter("status", value);
                      return;
                    }
                    addFilter({ type: "status", value });
                  }}
                >
                  <i>{chips.some((chip) => chip.type === "status" && chip.value === value) ? <Check /> : null}</i>
                  <span>{value}</span>
                  <span className="count">
                    {plans?.filter((plan) => plan.status.toLocaleLowerCase() === value.toLocaleLowerCase()).length}
                  </span>
                </FilterItem>
              ))
            ) : (
              <StyledSelect
                isDisabled={false}
                value={null}
                name="year"
                placeholder="Search year"
                options={YEARS_OPTIONS}
                onChange={(option: Option) => {
                  const isSelected = chips.find((chip) => chip.type === "year" && chip.value === option.value);
                  if (isSelected) {
                    resetFilter("year", option.value);
                    return;
                  }
                  addFilter({ type: "year", value: option.value });
                }}
                formatOptionLabel={(option: Option) => (
                  <StyledOption gap="5px" alignItems="center">
                    <i>
                      {chips.some((chip) => chip.type === "year" && chip.value === option.value) ? <Check /> : null}
                    </i>
                    <span>{option.label}</span>
                  </StyledOption>
                )}
                isSearchable
                menuIsOpen
              />
            )}
          </StyledFlex>
        </FilterDropdown>
      </StyledFlex>
      <StyledFlex marginTop="20px" height="680px" overflowY="scroll" padding="10px 5px" flexDirection="column">
        <StyledGrid
          gridTemplateColumns="repeat(auto-fit, minmax(260px, 360px))"
          gridAutoRows="min-content"
          gap="50px"
          width="100%"
          maxWidth="1187px"
        >
          {arePlansLoading ? (
            <StyledFlex justifyContent="center" mt="150px">
              <Spinner width="50px" height="50px" />
            </StyledFlex>
          ) : (
            plans?.length === 0 && (
              <StyledFlex flexDirection="column" alignItems="center" gap="5px" mt="150px">
                <span>No plans</span>
                <StyledLink to={createPlanRoute}>Create New Plan</StyledLink>
              </StyledFlex>
            )
          )}
          {plans
            ?.sort(byEndDate)
            .filter(byChips)
            .map((plan, idx) => (
              <PlanCard key={plan.id} plan={plan} />
            ))}
        </StyledGrid>
      </StyledFlex>
    </Wrapper>
  );
};
