import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { alertsUrl } from "../api";

export const CREATE_ALERT_MUTATION_KEY = "create-alert";

export const useCreateAlert = () => {
  const queryClient = useQueryClient();
  return useMutation(CREATE_ALERT_MUTATION_KEY, (newAlert: any) => ApiClient.post(alertsUrl, newAlert), {
    onSettled: () => queryClient.invalidateQueries("alerts"),
  });
};
