import { useCallback, useMemo } from "react";
import { AdditionalOverlays, getImageForAdditionalOverlay } from "../pages/MapScreen/MapView/MapView.utils";
import { useMapStore } from "../stores";
import { LAYER_SWITCH_CONTROL } from "../stores/MapStore";

export const useLandfireLayers = () => {
  const selectedAdditionalOverlay = useMapStore((store) => store.selectedAdditionalOverlay);
  const additionalOverlayList = useMapStore((store) => store.additionalOverlayList);
  const setOverlaySwitchState = useMapStore((store) => store.setOverlaySwitchState);
  const setAdditionalOverlay = useMapStore((store) => store.setAdditionalOverlay);
  const setOpacityOverlayByKey = useMapStore((store) => store.setOpacityOverlayByKey);
  const additionalOverlayOpacity = useMapStore((store) => store.additionalOverlayOpacity);
  const overlaySwitchControl = useMapStore((store) => store.overlaySwitchControl);

  const additionalLandfireData = useMemo(() => {
    return {
      showSlideControl: true,
      onChangeSlideControl: (item: { key: string; value: number }) => setOpacityOverlayByKey(item.key, item.value),
      defaultValue: additionalOverlayOpacity[selectedAdditionalOverlay!],
      selectedAdditionalOverlay: selectedAdditionalOverlay,
    };
  }, [additionalOverlayOpacity, selectedAdditionalOverlay, setOpacityOverlayByKey]);

  const additionalOverlaysData = useMemo(() => {
    if (!additionalOverlayList) {
      return undefined;
    }
    return Object.keys(additionalOverlayList).map((key: string) => {
      const data = additionalOverlayList[key].data;
      return {
        name: data.name,
        key: data.key,
        image: getImageForAdditionalOverlay(data.key as AdditionalOverlays),
        checked: selectedAdditionalOverlay === data.key || data.checked,
        additionalInfo: data.additionalInfo,
      };
    });
  }, [additionalOverlayList, selectedAdditionalOverlay]);

  const toggleLandfireLayers = useCallback(() => {
    setOverlaySwitchState({
      name: LAYER_SWITCH_CONTROL.ADDITIONAL_OVERLAYS,
      value: !overlaySwitchControl.ADDITIONAL_OVERLAYS.checked,
    });
  }, [overlaySwitchControl, setOverlaySwitchState]);

  return {
    data: additionalOverlaysData,
    toggleLayer: toggleLandfireLayers,
    changeOverlay: setAdditionalOverlay,
    additionalLandfireData,
    checked: overlaySwitchControl.ADDITIONAL_OVERLAYS.checked,
    showSwitch: overlaySwitchControl.ADDITIONAL_OVERLAYS.showSwitch,
  };
};
