import { AGE_CATEGORY } from "../../types/responses";

export const getAcquisitionName = (ageCategory: AGE_CATEGORY) => {
  switch (ageCategory) {
    case "FRESH":
      return "Fresh (Less than 90 days)";
    case "RECENT":
      return "Recent (91 days - 1 Year)";
    case "STANDARD":
      return "Standard (1-2 Years)";
    case "DATED":
      return "Dated (2-3 Years)";
    case "STALE":
      return "Stale (3+ Years)";
    default:
      return "";
  }
};
