import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { starCommandUrl } from "../api";
import { logError } from "../services";

import useStarCommandMapStore from "../stores/StarCommandMapStore/StarCommandMapStore";
import { StarCommandResponse } from "../types/responses/StarCommand";

export const QUERY_STAR_COMMAND_KEY = "star-command";

export const useStarCommand = () => {
  const { setStarCommandData } = useStarCommandMapStore((store) => store.actions);
  return useQuery(
    QUERY_STAR_COMMAND_KEY,
    () => ApiClient.get<StarCommandResponse>(starCommandUrl).then((response) => response.data),
    {
      onError: (e) => logError(e),
      onSuccess: setStarCommandData,
      staleTime: 0,
      cacheTime: 0,
    }
  );
};

export default useStarCommand;
