import { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { isInputEmpty } from "../../../pages/Admin/subpages/Elevation.utils";
import { LoadingState } from "../../../stores";
import { Button } from "../../Button/Button";
import { FooterCell } from "../FooterCell/FooterCell";
import { ValidationParams } from "../Table";
import { Rows } from "../RowsWrapper/RowsWrapper";
import { SingleItemWrapper, TableFooterRowWrapper } from "./SingleItemFooter.styled";

interface SingleFooterProperties {
  rows: number;
  addItem?: (singleItem: Nullable<string>) => Promise<LoadingState>;
  validationParams: ValidationParams;
  type: "number" | "string";
}

export const SingleItemFooter: FC<SingleFooterProperties> = ({ rows, addItem, validationParams, type }) => {
  const [inputValue, setInputValue] = useState("");
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.Idle);
  const [schemaValidatorObject, setSchemaValidatorObject] = useState(Yup.object().shape({}));
  const [hasValidationError, setValidationError] = useState(false);

  useEffect(() => {
    if (!validationParams) {
      return;
    }
    setSchemaValidatorObject(Yup.object().shape(validationParams));
  }, [validationParams]);

  const onChange = (value: string) => {
    setInputValue(value);
  };

  const disabled = loadingState === LoadingState.Loading || hasValidationError || isInputEmpty(inputValue);

  const saveNewItem = () => {
    if (!addItem) {
      return;
    }
    schemaValidatorObject.validate({ value: inputValue }).then(() => {
      addItem(inputValue).then((res) => {
        if (res === LoadingState.Success) {
          setInputValue("");
        }
        setLoadingState(LoadingState.Success);
        setValidationError(false);
      });
    });
  };

  const onError = (hasError: boolean) => {
    setValidationError(hasError);
  };

  return (
    <TableFooterRowWrapper>
      <Rows rows={rows} />
      <SingleItemWrapper>
        <FooterCell type={type} validationParams={validationParams} onChangeEvent={onChange} onError={onError} />
        <Button variant="primary" size={"small"} mt={24} onClick={saveNewItem} disabled={disabled}>
          {"Add"}
        </Button>
      </SingleItemWrapper>
    </TableFooterRowWrapper>
  );
};
