import styled from "styled-components";

import { DataButton, InputLabel } from "../../assets/styles/fonts.styled";

export const AddRoleText = styled.div<{ editable?: boolean }>`
  cursor: ${(props) => (props.editable ? "pointer" : "not-allowed")};
  text-align: left;
  padding-left: 20px;
  ${DataButton}
`;

export const RoleWrapper = styled.div`
  position: absolute;
  min-width: 188px;
  max-height: 147px;
  overflow-y: auto;
  gap: 15px;
  margin-left: 60px;
  margin-top: 10px;
  background: var(--colors-white);
  box-shadow: 0px 4px 14px rgb(0 0 0 / 25%);
  border-radius: 8px;
  z-index: 2000;
  padding: 13px;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export const RoleItem = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
  ${InputLabel}
  color: var(--colors-river-bad);

  &[disabled] {
    cursor: not-allowed;
    color: #c4c4c4;
  }

  &:hover:not([disabled]) {
    cursor: pointer;
    color: var(--colors-link-water);
  }
`;
