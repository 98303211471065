import styled from "styled-components";
import { ButtonStyled } from "../Button/Button.styled";
import { TOP_HEADER_HEIGHT } from "../../constants/dimensions";

export const ImageWrapper = styled.div`
  margin-right: 10px;
  width: 17px;
  height: 16px;
`;
export const CancelButton = styled(ButtonStyled)`
  border: none;
  color: var(--colors-river-bad);

  :hover {
    background: transparent;
    border: none;
    color: var(--colors-eastern-blue);
  }
`;

export const CancelSaveWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  gap: 5px;
`;

export const SectionHeaderActionWrapper = styled.div`
  margin: 0 5px;
`;

export const ItemsWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 60px - ${TOP_HEADER_HEIGHT}px);
  width: 100%;
  overflow-x: hidden;
`;

export const RightDrawerHeader = styled.div`
  height: 60px;
  display: flex;
  padding-left: 15px;
  padding-right: 13px;
  flex-direction: column;
  justify-content: center;
  div:first-child {
    height: 28px;
    display: flex;
    align-items: center;
    margin-top: 0px;
  }
  border-bottom: 1px solid #f7f7f7;
`;

export const LoaderContent = styled.div`
  margin-left: 50%;
`;
