import { logError } from "../../services";
import { GanttStatic } from "../../types/dhtmlxgantt";
import { RISK_PRIORITY } from "../../types/responses";
import { CutPlanNode, UpdateCutPlanDto } from "../../types/responses/CutPlanResponse";

const gantt: GanttStatic = (window as any).gantt;

const dateToStr = gantt.date.date_to_str("%M %d");

const structureWorkTepmlate =
  '<div class="gantt-additional-work hasStructureWork"><div class="badge-red-button"></div></div>';
const parcelFlagTepmlate =
  '<div class="gantt-additional-work hasParcelFlag"><div class="badge-red-button"></div></div>';

export const zoomConfig = {
  levels: [
    {
      name: "year",
      scales: [{ unit: "year", step: 1, format: "%Y" }],
    },
    {
      name: "quarter",
      scales: [
        {
          unit: "quarter",
          step: 1,
          format: (date: Date) => {
            const dateToStr = gantt.date.date_to_str("%M");
            const endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
            return dateToStr(date) + " - " + dateToStr(endDate);
          },
        },
      ],
    },
    {
      name: "month",
      scales: [{ unit: "month", format: "%M %Y" }],
    },
    {
      name: "week",
      scales: [{ unit: "day", step: 1, format: "%D" }],
    },
    {
      name: "day",
      scales: [{ unit: "day", step: 1, format: "%d %M" }],
    },
  ],
  useKey: "shiftKey",
};

const DATE_FORMAT = "%Y-%m-%d %H:%i";
// @ts-ignore
const strToDate = gantt.date.str_to_date(DATE_FORMAT, gantt.config.server_utc);

export const configureGanttChartOptions = () => {
  gantt.config.readonly = true;
  gantt.config.drag_resize = true;
  gantt.config.columns = [
    {
      name: "text",
      label: "",
      tree: true,
      template: (task: {
        additionalData: { hasStructureWork: boolean; parcelFlag: boolean };
        type: "task" | "project";
        text: string;
      }) => {
        if (task.additionalData.hasStructureWork || task.additionalData.parcelFlag) {
          return `
            <div class="gantt-content-name">
              <div>${task.text}</div>
              <div class="gantt-additional-work-wrapper">
                ${task.additionalData.hasStructureWork ? structureWorkTepmlate : ""}
                ${task.additionalData.parcelFlag ? parcelFlagTepmlate : ""}
              </div>
            </div>
          `;
        }

        return task.text;
      },
    },
  ];
  gantt.config.bar_height = 17;
  gantt.config.row_height = 38;
  gantt.config.scale_height = 50;
  gantt.config.min_grid_column_width = 350;
  gantt.ext.zoom.init(zoomConfig);
  gantt.ext.zoom.setLevel("month");
  gantt.config.date_format = DATE_FORMAT;
  gantt.templates.parse_date = (date) => strToDate(date.replace(/T/, " "));
  gantt.config.show_progress = false;
  gantt.plugins({
    fullscreen: true,
    marker: true,
    undo: true,
    multiselect: true,
    tooltip: true,
  });
  gantt.addMarker({
    start_date: new Date(),
    css: "today",
    text: dateToStr(new Date()),
    title: dateToStr(new Date()),
  });
  gantt.templates.task_class = function (start, end, task) {
    return `task-priority__${task?.priority ? task?.priority.toLowerCase() : RISK_PRIORITY.LOW.toLowerCase()}`;
  };
  gantt.templates.task_text = function (start, end, task) {
    return task.text;
  };
  gantt.templates.task_time = () => {
    return "";
  };

  gantt.config.lightbox.sections = [
    { name: "description", height: 0 },
    { name: "time", type: "duration", map_to: "auto" },
  ];
  gantt.templates.tooltip_text = (start, end, task) => {
    const dateToStrWithYear = gantt.date.date_to_str("%M %d, %Y");

    return `
      <strong class="task-title">${task.text}</strong>
      <span>Start: <strong>${dateToStrWithYear(start)}</strong></span>
      <span>Finish: <strong>${dateToStrWithYear(end)}</strong></span>
      <span>Duration: <strong>${task.duration}d</strong></span>
    `;
  };

  // custom handler to support opening tree by clicking on the whole row instead of the just the icon
  // @ts-ignore
  gantt.attachEvent("onTaskRowClick", (id: string, element) => {
    try {
      // if user has clicked on a arrow icon do nothing as arrow handler is invoked
      if (element.classList.contains("gantt_tree_icon")) {
        return;
      }
      const task = gantt.getTask(id);
      if (task.parent) {
        return;
      }
      task.$open = !task.$open;
      gantt.render();
    } catch (e) {
      logError(e);
    }
  });
};

// configure gannt only once as it is global instance
configureGanttChartOptions();

export const closeAllTasks = () => {
  gantt.eachTask(function (task) {
    task.$open = false;
  });
};

export const mapDataForUpdateCutPlan = (cutPlanRecords: Record<string, CutPlanNode>): UpdateCutPlanDto => {
  if (!Object.keys(cutPlanRecords)?.length) {
    return { cutPlanNodes: [] };
  }
  return { cutPlanNodes: Object.keys(cutPlanRecords).map((key) => cutPlanRecords[key]) };
};
