import { useEffect } from "react";
import { useQuery } from "react-query";

import { getThreatsUrl } from "../api";
import { ZOOM_LEVEL_LIMIT } from "../pages/MapScreen/MapView/MapView.constants";
import { useMapStore, useThreatsStore } from "../stores";
import { OVERLAY_LAYERS } from "../stores/MapStore/MapStore.types";
import { ThreatResponse } from "../types/responses";
import ApiClient from "../utils/apiClient";
import { useExtentParams } from "./useExtentParams";

const QUERY_KEY = "threats-by-extent";

export const useThreats = () => {
  const requestParams = useExtentParams();
  const sourceId = useMapStore((store) => store.selectedImagerySourceId);
  const threatsEnabled = useMapStore((store) => store.overlayMapLayers?.[OVERLAY_LAYERS.ENCROACHMENTS] ?? false);
  const { setThreats } = useThreatsStore((store) => store.actions);
  const areEncroachmentsVisible =
    threatsEnabled && requestParams !== null && requestParams.zoomLevel >= ZOOM_LEVEL_LIMIT;

  const query = useQuery(
    QUERY_KEY,
    () => ApiClient.get(requestParams ? getThreatsUrl({ ...requestParams, sourceId: sourceId ?? "" }) : "/threats"),
    {
      enabled: requestParams !== null && areEncroachmentsVisible,
      onSuccess: (response) => setThreats(response?.data as ThreatResponse),
    }
  );

  useEffect(() => {
    if (!requestParams || !areEncroachmentsVisible || !sourceId) {
      return;
    }
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams, areEncroachmentsVisible, sourceId]);
};
