import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { crewsUrl } from "../api";
import { logError } from "../services";
import { CrewConfiguration } from "../types/responses/Crews";

export const useCrews = () => {
  return useQuery("crews", () => ApiClient.get<CrewConfiguration>(crewsUrl).then((response) => response.data.crews), {
    onError: (e) => logError(e),
  });
};

export default useCrews;
