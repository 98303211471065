import L from "leaflet";
import { AccessPointCategory, AccessPointCategoryType } from "../../../types/responses";
import MapHelper from "../../../utils/mapHelper";
import { ReactComponent as CleanupIcon } from "./../../../assets/images/cleanup.svg";
import { ReactComponent as EquipmentIcon } from "./../../../assets/images/equipment-staging.svg";
import { ReactComponent as WorkIcon } from "./../../../assets/images/work-staging.svg";
import marker from "./../../../assets/images/access_point_first_marker.svg";

export const QUERY_KEY = "access-points";

export const ACCESS_POINTS_ZOOM_LEVEL = 15;
export const ICON_SIZE = [21, 21] as L.PointExpression;
export const ANGLE_DIFF = 10;
export const MARKER_CLASS_NAME = "access-path-marker";

export const getCategoryIcon = (categoryCode: string) => {
  if (categoryCode === AccessPointCategoryType.CLEANUP) return CleanupIcon;
  if (categoryCode === AccessPointCategoryType.EQUIPMENT_STAGING) return EquipmentIcon;
  if (categoryCode === AccessPointCategoryType.WORK_STAGING) return WorkIcon;

  return EquipmentIcon;
};
const OFFSET = 120;
export const POPUP_OPT: L.PopupOptions = {
  className: "alerts-popup",
  closeButton: false,
  closeOnEscapeKey: true,
  autoPan: true,
  keepInView: true,
  offset: [OFFSET, -4],
  autoPanPadding: [50, 70],
};

export const getCategoryBySubCategory = (categories: Nullable<AccessPointCategory[]>, subCategoryId: string) => {
  if (!subCategoryId || !categories?.length) {
    return;
  }

  const category = categories.find(
    (category) => category.subCategories.find((subCategory) => subCategoryId === subCategory.code) !== undefined
  );
  const subCategory = category?.subCategories?.find((subCategory) => subCategory.code === subCategoryId);
  return { name: category?.name, id: category?.code, subCategoryName: subCategory?.name };
};

export const getFirstMarker = (firstPoint: number[], secondPoint: number[]) => {
  return L.divIcon({
    iconSize: ICON_SIZE,
    html: `<img src=${marker} style="transform: rotate(${
      firstPoint && secondPoint ? MapHelper.getAngle(secondPoint, firstPoint) - ANGLE_DIFF : "0"
    }deg)"></div>`,
    className: `access-path-first-marker ${MARKER_CLASS_NAME}`,
  });
};

export const getGreenMarker = () => {
  return L.divIcon({
    iconSize: ICON_SIZE,
    html: `<div><div>`,
    className: `.access-path-marker ${MARKER_CLASS_NAME}`,
  });
};
