import React, { FC, useMemo, useCallback, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { ReactComponent as PushoutYes } from "../../assets/images/check.svg";
import { ReactComponent as PushoutNo } from "../../assets/images/close.svg";
import { ReactComponent as Trash } from "../../assets/images/remove.svg";
import { DrawerSection } from "../RightDrawer/DrawerSectionItems/DrawerSection";
import { SectionLabelWrapper } from "../RightDrawer/DrawerSectionItems/DrawerSectionItems.styled";
import { SectionItem } from "../RightDrawer/DrawerSectionItems/SectionItem";
import { LABELS } from "../../constants";
import { PushoutRequiredWrapper } from "../../pages/MapScreen/RightDrawerMenu/RightDrawerMenu.styled";
import { SectionLinks } from "../RightDrawer/DrawerSectionItems/SectionLinks";
import {
  SectionData,
  SectionLinks as SectionLinksType,
} from "../../pages/MapScreen/RightDrawerMenu/RightDrawerMenu.utils";
import { SpecialLayers } from "../RightDrawer/DrawerSectionItems/SpecialLayers";
import { ContentType } from "../../stores/DrawerStore/DrawerStore.types";
import useCwcStore from "../../stores/MapCwcStore";
import { WorksheetAllArea } from "../WorksheetArea/WorksheetAllArea";
import { useCustomQuery, useEditCustomPolygon } from "../../hooks";
import { UpdateThreatWorksheetRequestDto, WorksheetResponse, ThreatsWorksheetDto } from "../../types/responses";
import { getAllWorksheetsForThreatUrl, getThreatWorksheetApiUrl } from "../../api";
import { calculateSubItem } from "../SelectionToolRHS/SelectionToolRHS.utils";
import ApiClient from "../../utils/apiClient";
import { logError } from "../../services";
import { LayerRow } from "../../types/Layers";
import { DeleteButton, SelectWrapper } from "./CustomPolygonRHS.styled";
import { Select, Option } from "../Select";
import { RISK_PRIORITY } from "../../types/responses";
import { StyledFlex } from "../../assets/styles/flex.styled";

interface IProps {
  data?: SectionData;
  openHyperlink?: (type: ContentType, id: string) => void;
  deleteWorkTypeFromInfringement?: (workTypeId: string) => Promise<unknown>;
  specialLayers?: Nullable<LayerRow[]>;
  setSpecialLayer: (id: string, value: boolean) => void;
  specialLayersState: Nullable<Record<string, boolean>>; //is layer checked: by layer key
  enableReloadForPredominatedWorkTypes: () => void;
  deleteCustomWorkContainer: (polygonId: string) => void;
  closeDrawer: () => void;
}

const RiskPriorityOptions = [
  { label: "Low", value: RISK_PRIORITY.LOW },
  { label: "Medium", value: RISK_PRIORITY.MEDIUM },
  { label: "High", value: RISK_PRIORITY.HIGH },
  { label: "Critical", value: RISK_PRIORITY.CRITICAL },
  { label: "Violation", value: RISK_PRIORITY.VIOLATION },
];

const formatOptionLabel = ({ label }: { label: string }) => (
  <StyledFlex gap="5px" alignItems="center">
    <i data-risk-priority={label.toLocaleLowerCase()}></i>
    <span>{label}</span>
  </StyledFlex>
);

export const CustomPolygonRHS: FC<IProps> = ({
  data,
  openHyperlink,
  specialLayers,
  setSpecialLayer,
  specialLayersState,
  deleteWorkTypeFromInfringement,
  deleteCustomWorkContainer,
  closeDrawer,
}) => {
  const { toggleDrawer } = useCwcStore((store) => store.actions);
  const isLayerVisible = useCwcStore((store) => store.isLayerVisible);
  const isDrawerVisible = useCwcStore((store) => store.isDrawerVisible);
  const { mutateAsync: editCustomPolygon } = useEditCustomPolygon();
  const {
    data: worksheetThreats,
    isLoading: isWorksheetLoading,
    refetch: refetchWorksheet,
  } = useCustomQuery<ThreatsWorksheetDto>(`${getAllWorksheetsForThreatUrl(data?.id as string)}`, {
    enabled: !!data?.id,
  });
  const [riskPriority, setRiskPriority] = useState<string>(data?.riskPriority as string);

  const saveAllChanges = useMutation((value: UpdateThreatWorksheetRequestDto) =>
    ApiClient.put(getThreatWorksheetApiUrl(data?.id as string), value)
  );

  const invokeUpdateWorksheet = useCallback(
    (value: string[]) => {
      return saveAllChanges
        .mutateAsync({ items: value.map((val) => ({ workTypeId: val })) })
        .then(() => {
          refetchWorksheet();
        })
        .catch((error) => logError(error));
    },
    [saveAllChanges, refetchWorksheet]
  );

  const worksheetData: Nullable<WorksheetResponse> = useMemo(() => {
    if (!worksheetThreats?.worksheets?.length || !data?.id) {
      return null;
    }

    const items = worksheetThreats.worksheets.find((item) => item.threatId === (data?.id as string));
    return {
      items: items ? items.items : [],
      totalCost: calculateSubItem(items?.items ?? []),
    };
  }, [worksheetThreats, data]);

  const handleRiskPriorityChange = (option: Option) => {
    editCustomPolygon({ polygonId: data?.id as string, riskPriority: option.value }).then(() =>
      setRiskPriority(option.label)
    );
  };

  useEffect(() => {
    if (!isLayerVisible && isDrawerVisible) {
      closeDrawer();
      toggleDrawer(false);
    }
  }, [isLayerVisible, isDrawerVisible, closeDrawer, toggleDrawer]);

  useEffect(() => {
    toggleDrawer(true);

    return () => {
      toggleDrawer(false);
    };
  }, [toggleDrawer]);

  return (
    <>
      <DrawerSection name="RISK INFO">
        <SectionItem name={LABELS.riskPriority}>
          <SelectWrapper>
            <Select
              isDisabled={false}
              name="riskPriority"
              value={RiskPriorityOptions.find((option) =>
                option.label.toLocaleLowerCase() === riskPriority.toLowerCase() ? option : null
              )}
              onChange={handleRiskPriorityChange}
              options={RiskPriorityOptions}
              isOptionDisabled={(item: Option) => item.label.toLowerCase() === riskPriority.toLowerCase()}
              formatOptionLabel={formatOptionLabel}
            />
          </SelectWrapper>
        </SectionItem>
        <SectionItem name={LABELS.treeCount}>{data?.treeCount}</SectionItem>
        <SectionItem name="Tree Height Avg">{data?.treeHeightAvg}</SectionItem>
        <SectionItem name={LABELS.pushoutRequired}>
          <SectionLabelWrapper>{data?.pushout}</SectionLabelWrapper>
          <PushoutRequiredWrapper>
            {data?.pushout === "YES" && <PushoutYes className="pushout-yes" />}
            {data?.pushout === "NO" && <PushoutNo className="pushout-no" />}
          </PushoutRequiredWrapper>
        </SectionItem>
        <SectionItem name={LABELS.specialLayers} hasBorder={false} bold={true}>
          {!data?.treeCount?.toString() ? "N/A" : ""}
        </SectionItem>
        <SpecialLayers
          specialLayers={specialLayers}
          setSpecialLayer={setSpecialLayer}
          specialLayersState={specialLayersState}
        ></SpecialLayers>
      </DrawerSection>
      <DrawerSection name="WORKSHEET">
        <WorksheetAllArea
          invokeUpdateWorksheet={invokeUpdateWorksheet}
          readonly={false}
          worksheetData={worksheetData}
          showSections={true}
          scope={"INFRINGEMENT"}
          isWorksheetLoading={isWorksheetLoading}
          deleteWorkTypeFromInfringement={deleteWorkTypeFromInfringement}
        />
      </DrawerSection>
      <DrawerSection name="WORK AREA INFO">
        <SectionItem name="Affected Circuits">
          <SectionLinks data={data?.circuits as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name={LABELS.elevation}>{data?.elevation}</SectionItem>
        <SectionItem name={LABELS.demographic}>{data?.demographic}</SectionItem>
        <SectionItem name={LABELS.coordinates}>{data?.coordinates}</SectionItem>
      </DrawerSection>

      <DeleteButton
        Icon={Trash}
        text="Delete Custom work container"
        onClick={() => deleteCustomWorkContainer(data?.id as string)}
      />
    </>
  );
};
