import { LineString } from "@turf/turf";

export type AccessPoint = {
  id: string;
  username: string;
  categoryId: string;
  categoryCode: string;
  postedAt: string;
  geometry: LineString;
};

export type AccessPoints = {
  markers: AccessPoint[];
};

export type AccessPointsCategories = {
  categories: AccessPointCategory[];
};

export type AccessPointCategory = {
  id: string;
  code: string;
  name: string;
  subCategories: AccessPointCategory[];
  sortNumber: number;
};

export enum AccessPointCategoryType {
  EQUIPMENT_STAGING = "equipment-staging",
  WORK_STAGING = "work-staging",
  CLEANUP = "cleanup-and-haul-away",
}
