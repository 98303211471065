import styled from "styled-components";

export const TextareaStyled = styled.textarea<{ editable?: boolean }>`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 11px;
  height: 121px;
  background: ${(props) => props.theme.colors.alabaster2};
  border: 1px solid ${(props) => props.theme.colors.ebb};
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
  padding: 15px;
  color: ${({ editable, theme }) => (editable ? theme.colors.notePassive : theme.colors.noteDisabled)};
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  &:focus {
    color: ${(props) => props.theme.colors.trout2};
  }
`;
