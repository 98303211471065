import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";

import { DataButton } from "../../assets/styles/fonts.styled";

export const InputWrapper = styled.div<LayoutProps>`
  input {
    display: none;
  }

  label {
    ${layout}
  }
`;

export const Label = styled.label`
  ${DataButton}
  border-radius: 6px;
  border: 1px solid var(--colors-eastern-blue);
  width: 140px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  cursor: pointer;
`;
