import React, { FC } from "react";
import { PositionProps } from "styled-system";

import { Portal } from "../Portal";
import { RangeInput } from "../RangeInput";
import { MapOpacityControlSlider, MapOpacityHeader, MapOpacityLayerWrapper } from "./MapOpacityControl.styled";

interface MapOpacityControlProps extends PositionProps {
  hidden: boolean;
  opacity: number;
  setOpacity: (opacity: number) => void;
}

export const MapOpacityControl: FC<MapOpacityControlProps> = ({ hidden, opacity = 0.3, setOpacity, ...props }) => {
  if (hidden) {
    return <></>;
  }
  return (
    <Portal selector="layer-menu-root">
      <MapOpacityLayerWrapper {...props} id="map-opacity-control">
        <MapOpacityHeader>
          <div>Map Overlay</div>
        </MapOpacityHeader>
        <MapOpacityControlSlider>
          <RangeInput
            defaultValue={opacity}
            onClick={(e) => setOpacity(Number((e.target as HTMLInputElement).value))}
          />
        </MapOpacityControlSlider>
      </MapOpacityLayerWrapper>
    </Portal>
  );
};
