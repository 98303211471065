import { getRiskPriorityColor } from "../../../pages/MapScreen/MapView/MapView.utils";
import { SpanRiskPriority } from "../../../stores";
import { RISK_PRIORITY } from "../../../types/responses";
import { TaxonomyItem } from "../../../types/TaxonomyItem";

export const getChipsBgColor = (
  riskPriority: RISK_PRIORITY,
  selectedRiskPriority: SpanRiskPriority,
  isItemSelected: boolean,
  isSubItemSelected: boolean
): string => {
  if (selectedRiskPriority === null && isItemSelected && !isSubItemSelected) {
    return "#2FA2AC";
  }

  if (!isItemSelected) {
    return "var(--colors-lynx-white)";
  }

  if (riskPriority !== selectedRiskPriority) {
    return "var(--colors-white)";
  }

  return getRiskPriorityColor(selectedRiskPriority);
};

export const getChipsTextColor = (
  name: RISK_PRIORITY,
  selectedFilter: SpanRiskPriority,
  isItemSelected: boolean,
  isSubItemSelected: boolean
) => {
  if (
    (isItemSelected && selectedFilter !== null && name === selectedFilter) ||
    (selectedFilter === null && isItemSelected && !isSubItemSelected)
  ) {
    return "var(--colors-white)";
  }

  return "var(--colors-trout-2)";
};

export const filterOptionsByStatus = (options: Maybe<Nullable<TaxonomyItem[]>>, riskPriority: SpanRiskPriority) => {
  if (!options || !options.length) {
    return [];
  }

  return options.filter((option) => option.riskPriority === riskPriority);
};
