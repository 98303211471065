import React, { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Wrapper } from "./FilePreviewer.styled";
import { AttachmentDto } from "../../types/responses/Attachments";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  file: AttachmentDto;
  type: string;
}

export const FilePreviewer: FC<Props> = ({ file, type }) => {
  return (
    <Wrapper>
      {type === "PDF" && (
        <Document file={file.fileUrl}>
          <Page pageNumber={1} width={365} />
        </Document>
      )}
      {type === "IMAGE" && <img src={file.fileUrl} alt={file.fileName} />}
      {type === "VIDEO" && (
        <video controls>
          <source src={file.fileUrl} />
        </video>
      )}
    </Wrapper>
  );
};
