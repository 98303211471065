import styled from "styled-components";
import { IconButton } from "../IconButton";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const Wrapper = styled.div`
  background-color: var(--colors-black);
  color: var(--colors-white);
  padding: 0 15px 10px;
  scroll-behavior: smooth;
  border-radius: 10px;
`;

export const ComponentTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const CategoriesWrapper = styled(StyledFlex)`
  max-height: 300px;
  overflow-y: scroll;
`;

export const StyledButton = styled(IconButton)`
  background-color: var(--colors-black);

  svg,
  path {
    fill: var(--colors-white);
  }

  &.delete {
    background-color: var(--colors-black);
    border-color: var(--colors-white);
  }

  &.edit {
    padding: 6px 21.5px;
  }
`;

export const CategoryButton = styled(IconButton)`
  background-color: var(--colors-black);
  border: none;
  border-bottom: 2px solid transparent;
  padding: 5px 2px;
  border-radius: 0;
  svg,
  path {
    fill: #8d8e90;
  }
  &.isSelected {
    border-color: #1ea7b2;

    svg,
    path {
      fill: #1ea7b2;
    }
  }
`;

export const SubcategoryWrapper = styled(IconButton)`
  background-color: #212221;
  border-color: #212221;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #c4c4c4;
  gap: 15px;
  white-space: nowrap;

  &:hover {
    background: rgba(31, 167, 179, 0.3);
    color: var(--colors-white);

    svg,
    path {
      fill: var(--colors-white);
    }
  }

  &.isSelected {
    background-color: #20949e;
    color: var(--colors-white);

    svg,
    path {
      fill: var(--colors-white);
    }
  }
`;

export const Marker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(30px);

  &:before {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid var(--colors-white);
    background-color: #ff0000;
  }
`;

export const CloseButton = styled(IconButton)`
  border: none;
  background-color: transparent;
  padding-right: 0;

  svg {
    width: 15px;
    height: 12px;
  }

  path {
    fill: #c4c4c4;
  }
`;
