import React, { FC } from "react";

import { ContentWrapper, LabelContent, AdditionalLabelContent } from "./PriorityTooltipContent.styled";

export const PriorityTooltipContent: FC<{ label: string; additionalLabel?: string }> = ({
  label,
  additionalLabel = null,
}) => {
  return (
    <ContentWrapper>
      <LabelContent>{label}</LabelContent>
      {additionalLabel && <AdditionalLabelContent>{additionalLabel}</AdditionalLabelContent>}
    </ContentWrapper>
  );
};
