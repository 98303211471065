import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { putContractorRoleUrl } from "../api";

export const ADD_ROLE_MUTATION_KEY = "add-crew-config-role";

export const useAddCrewRole = () => {
  return useMutation(ADD_ROLE_MUTATION_KEY, (item: { id: string; crewId: string }) =>
    ApiClient.put(putContractorRoleUrl(item.crewId), { contractorRoleId: item.id })
  );
};
