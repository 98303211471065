import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Comments } from "../../../components/Comments";
import { Input } from "../../../components/Input/Input.styled";
import { Portal } from "../../../components/Portal/Portal";
import {
  CloseIcon,
  CloseImageWrapper,
  RightDrawerHeader,
  RightDrawerHeaderColumn,
  RightDrawerHeaderPartLeft,
} from "../../../components/RightDrawer/RightDrawer.styled";
import useCommentsStore from "../../../stores/CommentStore";
import {
  CommentDrawerWrapper,
  CommentSearchWrapper,
  CommentSearchFilterWrapper,
  CommentsWrapper,
  Filter,
  FilterPopup,
  FilterPopupItem,
  CloseButtonWrapper,
} from "./CommentDrawer.styled";
import { ReactComponent as Magnifier } from "./../../../assets/images/magnifier.svg";
import { ReactComponent as CheckIcon } from "../../../assets/images/check_mark.svg";
import { ReactComponent as CloseSearch } from "../../../assets/images/close.svg";
import { useClickOutside } from "../../../hooks";
import { useMapStore } from "../../../stores";

const PopupItem = ({
  name,
  selected,
  onClick,
  hasBorder = false,
}: {
  name: string;
  selected: boolean;
  onClick: () => void;
  hasBorder?: boolean;
}) => {
  return (
    <FilterPopupItem selected={selected} onClick={onClick} hasBorder={hasBorder}>
      <div className="popup-image">{selected && <CheckIcon />}</div>
      <div>{name}</div>
    </FilterPopupItem>
  );
};

type CommentDrawerProps = {
  hidden: boolean;
  reloadComments: () => void;
};

export const CommentDrawer: FC<CommentDrawerProps> = ({ hidden, reloadComments }) => {
  const comments = useCommentsStore((store) => store.data);
  const toggleLayer = useCommentsStore((store) => store.toggleLayer);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const filter = useCommentsStore((store) => store.filter);
  const showRead = useCommentsStore((store) => store.showRead);
  const showResolved = useCommentsStore((store) => store.showResolved);
  const setSortBy = useCommentsStore((store) => store.setSortBy);
  const setText = useCommentsStore((store) => store.setTextFilter);
  const setCommentId = useCommentsStore((store) => store.setMarkerId);
  const [searchText, setSearchText] = useState("");
  const setLatestObjectIdSelectedForMapAnimation = useMapStore(
    (store) => store.setLatestObjectIdSelectedForMapAnimation
  );
  const selectedCommentId = useCommentsStore((store) => store.selectedMarkerId);

  const [filterSearchActive, setFilterSearchActive] = useState(false);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => isPopupVisible && setPopupVisible(false));

  const selectComment = useCallback(
    (id: string) => {
      setLatestObjectIdSelectedForMapAnimation(id);
      setCommentId(id);
    },
    [setLatestObjectIdSelectedForMapAnimation, setCommentId]
  );

  const debounced = useDebouncedCallback((value) => {
    setText(value);
  }, 300);

  useEffect(() => {
    debounced(searchText);
  }, [searchText, debounced]);

  if (hidden) {
    return <></>;
  }
  return (
    <Portal selector="layer-menu-root">
      <CommentDrawerWrapper>
        {/* TODO: PUT RightDrawerHEader in single component */}
        <RightDrawerHeader singleColumn={true}>
          <RightDrawerHeaderColumn>
            <RightDrawerHeaderPartLeft id="rhs-title">Comments</RightDrawerHeaderPartLeft>
            <CloseImageWrapper onClick={toggleLayer}>
              <CloseIcon />
            </CloseImageWrapper>
          </RightDrawerHeaderColumn>
        </RightDrawerHeader>
        <CommentsWrapper>
          <CommentSearchFilterWrapper>
            <CommentSearchWrapper active={filterSearchActive}>
              <Magnifier className="magnifier" />
              <Input
                value={searchText}
                type="string"
                hasError={false}
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                onFocus={() => setFilterSearchActive(true)}
                onBlur={() => setFilterSearchActive(false)}
              />
              <CloseButtonWrapper>
                {searchText?.length > 0 && <CloseSearch className="clear-button" onClick={() => setSearchText("")} />}
              </CloseButtonWrapper>
            </CommentSearchWrapper>
            <Filter
              onClick={() => {
                setPopupVisible(!isPopupVisible);
              }}
            ></Filter>
          </CommentSearchFilterWrapper>
          <Comments
            selectedCommentId={selectedCommentId}
            selectComment={selectComment}
            comments={comments?.comments ?? null}
            reloadComments={reloadComments}
          />
        </CommentsWrapper>
        <FilterPopup isOpen={isPopupVisible} ref={wrapperRef}>
          <PopupItem
            onClick={() => {
              if (filter.sortBy === "DATE") {
                return;
              }
              setSortBy("DATE");
            }}
            selected={filter.sortBy === "DATE"}
            name={"Sort by date"}
          />
          <PopupItem
            hasBorder
            onClick={() => {
              if (filter.sortBy === "UNREAD") {
                return;
              }
              setSortBy("UNREAD");
            }}
            selected={filter.sortBy === "UNREAD"}
            name={"Sort by unread"}
          />
          <PopupItem
            onClick={() => {
              showRead(!filter.showRead);
            }}
            selected={filter.showRead}
            name={"Show read comments"}
          />
          <PopupItem
            onClick={() => {
              showResolved(!filter.showResolved);
            }}
            selected={filter.showResolved}
            name={"Show resolved comments"}
          />
        </FilterPopup>
      </CommentDrawerWrapper>
    </Portal>
  );
};
