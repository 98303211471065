import React, { FC, useState, InputHTMLAttributes } from "react";
import { useField } from "formik";

import {
  Input as InputStyled,
  InputErrorMessage,
  InputWrapper,
  Label,
  InputShowPasswordIcon,
  FormatElement,
} from "./Input.styled";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  type: string;
  variant?: string;
  unitSign?: Nullable<string>;
  displayErrorMessage?: boolean;
}

export const Input: FC<InputProps> = ({
  name,
  label,
  type,
  variant,
  unitSign,
  onChange,
  value,
  displayErrorMessage = true,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.error && meta.touched ? true : false;
  const [isPasswordShown, setPasswordShown] = useState(false);

  return (
    <div>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        {unitSign && <FormatElement>{unitSign}</FormatElement>}
        <InputStyled
          {...field}
          {...otherProps}
          hasError={hasError}
          variant={variant}
          type={type === "password" && isPasswordShown ? "text" : type}
          className={unitSign ? "withSymbol" : ""}
          value={field.value ?? value}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
        />
        {type === "password" && (
          <InputShowPasswordIcon hasError={hasError} onClick={() => setPasswordShown(!isPasswordShown)} />
        )}
      </InputWrapper>
      {displayErrorMessage && <InputErrorMessage>{(hasError && meta.error) || ""}</InputErrorMessage>}
    </div>
  );
};
