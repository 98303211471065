import React, { FC, useState } from "react";
import { Wrapper, MainContent, Label, CrewName } from "./AddCrewType.styled";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { ReactComponent as CheckIcon } from "../../../../assets/images/check.svg";
import { Button } from "../../../../components/Button";
import { ICrewType } from "../../types";

interface Props {
  crewTypes: ICrewType[];
  setSelectedCrew: (crew: ICrewType) => void;
  closePopup: () => void;
}

export const AddCrewType: FC<Props> = ({ crewTypes, setSelectedCrew, closePopup }) => {
  const [selectedCrewIdx, setSelectedCrewIdx] = useState(0);
  const selectedCrew = crewTypes.find((crew, idx) => idx === selectedCrewIdx)!;

  return (
    <Wrapper flexDirection="column" gap="0" onClick={(e) => e.stopPropagation()}>
      <MainContent>
        <StyledFlex flexDirection="column" overflowY="scroll" gap="0" pb="10px">
          {crewTypes.map((crew, idx) => (
            <CrewName key={crew.id} title={crew.name} onClick={() => setSelectedCrewIdx(idx)}>
              {selectedCrewIdx === idx && <CheckIcon />}
              <span>{crew.name}</span>
            </CrewName>
          ))}
        </StyledFlex>
        <StyledFlex flexDirection="column" overflowY="scroll" gap="20px" pl="20px">
          <StyledFlex flexDirection="column" gap="5px">
            <Label>Mandatory Members</Label>
            <span>{selectedCrew?.memberTypes?.map((memType) => memType.name).join(", ")}</span>
          </StyledFlex>
          <StyledFlex flexDirection="column" gap="5px">
            <Label>Mandatory Equipment</Label>
            <span>{selectedCrew?.equipmentTypes?.map((memType) => memType.name).join(", ")}</span>
          </StyledFlex>
        </StyledFlex>
      </MainContent>
      <StyledFlex p="16px" gap="20px" justifyContent="flex-end">
        <Button variant="secondary" fontSize="14px" p="9px" width="93px" onClick={() => closePopup()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          fontSize="14px"
          p="9px"
          width="93px"
          onClick={() => {
            setSelectedCrew(selectedCrew);
            closePopup();
          }}
        >
          Done
        </Button>
      </StyledFlex>
    </Wrapper>
  );
};
