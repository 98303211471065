import { FC } from "react";

import {
  NoWorkTypesWrapper,
  WorkTypeItem as WorkTypeItemStyled,
  WorkTypesContainer,
  WorkTypesWrapper,
} from "./WorkTypes.styled";

import { Worksheet } from "../../../types/responses/WorksheetResponse";
import { Spacer } from "../../../assets/styles/spacer.styled";
import { StyledFlex } from "../../../assets/styles/flex.styled";
import { StyledText } from "../../../assets/styles/text.styled";
import { getWorkTypeImage } from "../../../pages/MapScreen/MapScreen.utils";
import { WorkTypeItem } from "../../WorkTypeItem";
import { getUnitPluralName, roundValue } from "../../../utils/unitHelper";

export interface ExtendedWorksheet extends Worksheet {
  category: string;
  unit: string;
  code: string;
}

export const UNIT_NAMES: Record<string, string> = {
  DOLLAR_PER_TREE: "tree",
  DOLLAR_PER_ACRE: "acre",
};

export const WorkTypes: FC<{
  workTypes: Maybe<ExtendedWorksheet[]>;
  removeWorkType: (id: string, fromInfringement?: boolean) => void;
  readonly?: boolean;
  showSections?: boolean;
  assignToNewWorkorder?: (workTypeId: string) => void;
}> = ({ workTypes, removeWorkType, assignToNewWorkorder, readonly = true, showSections = false }) => {
  if (!workTypes || !workTypes.length) {
    return (
      <NoWorkTypesWrapper>
        <StyledText fontWeight={500} fontSize={14} color="#BEC0C6">
          No Work Items
        </StyledText>
      </NoWorkTypesWrapper>
    );
  }
  const spansWorkTypes = workTypes.filter((workType) => !workType.inherited);

  const infringementWorkTypes = workTypes.filter((workType) => {
    const hasSpanWorkType = spansWorkTypes.filter((span) => span.workTypeId === workType.workTypeId && span.selected);
    return workType.inherited && !hasSpanWorkType?.length;
  });

  return (
    <WorkTypesContainer>
      {showSections && (
        <>
          <WorkTypeSection
            sectionName="Infringement"
            workTypes={infringementWorkTypes}
            removeWorkType={(id) => {
              removeWorkType(id, true);
            }}
            readonly={readonly}
            bottomBorder={!spansWorkTypes || !spansWorkTypes.length}
          />
          <WorkTypeSection
            sectionName="Span"
            workTypes={spansWorkTypes}
            removeWorkType={removeWorkType}
            readonly={readonly}
            bottomBorder={true}
            assignToNewWorkorder={assignToNewWorkorder}
          />
        </>
      )}
      {!showSections && (
        <WorkTypeSection
          sectionName=""
          workTypes={workTypes}
          removeWorkType={removeWorkType}
          readonly={readonly}
          bottomBorder={true}
          assignToNewWorkorder={assignToNewWorkorder}
        />
      )}
    </WorkTypesContainer>
  );
};

const WorkTypeSection = ({
  sectionName,
  workTypes,
  readonly,
  removeWorkType,
  bottomBorder = true,
  assignToNewWorkorder,
}: {
  sectionName: string;
  workTypes: Maybe<ExtendedWorksheet[]>;
  removeWorkType: (id: string) => void;
  readonly?: boolean;
  bottomBorder?: boolean;
  assignToNewWorkorder?: (workTypeId: string) => void;
}) => {
  if (!workTypes || !workTypes.length) {
    return <></>;
  }
  return (
    <>
      <WorkTypesWrapper bottomBorder={bottomBorder}>
        <StyledText
          color="#898EA1"
          fontSize={14}
          fontWeight={500}
          borderBottom={"1px solid var(--colors-gallery)"}
          paddingBottom={"4px"}
        >
          {sectionName}
        </StyledText>
        {workTypes.map((workType) => (
          <WorkTypeItemStyled key={workType.workTypeId}>
            {readonly ? (
              <StyledFlex alignItems="center">
                <img src={getWorkTypeImage(workType.code)} alt="work type" className="work-type__image" />
                <Spacer ml="9px" />
                {workType.category}
              </StyledFlex>
            ) : (
              <WorkTypeItem
                isEditMode={true}
                key={workType.code}
                code={workType.code}
                cost={workType.cost}
                name={workType.category}
                deleteWorkType={removeWorkType}
                id={workType.workTypeId}
                manuallyAdded={false}
                additionalLabel={`${roundValue(workType.quantity, 2)} ${getUnitPluralName(
                  UNIT_NAMES[workType.unit],
                  workType.quantity
                )}`}
                isAutoAssigned={workType.autoAssigned}
                assignToNewWorkorder={assignToNewWorkorder}
              ></WorkTypeItem>
            )}
          </WorkTypeItemStyled>
        ))}
      </WorkTypesWrapper>
    </>
  );
};
