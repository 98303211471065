import styled from "styled-components";

export const StyledFooterSelect = styled.div`
  .react-select__control,
  .react-select__value-container,
  .react-select__input,
  .react-select__input input {
    height: 30px;
  }
  .react-select__control {
    min-height: 30px;
    box-sizing: border-box;
    box-shadow: none;
  }
  .react-select__control:hover {
    box-shadow: none;
  }
  .react-select__indicators {
    height: 30px;
  }
  .react-select__control {
    background-color: ${(props) => props.theme.colors.alabaster2};
  }
  .react-select__placeholder {
    font-family: "Barlow";
    font-weight: 500;
    font-size: 14px;
    color: var(--colors-trout-2);
  }
  .react-select__single-value {
    font-family: "Barlow";
    font-weight: 500;
    font-size: 14px;
    color: var(--colors-trout-2);
  }

  .react-select__option {
    color: var(--colors-trout-2);
    font-family: "Barlow";
    font-weight: 500;
    font-size: 14px;
    left: 0;
    text-align: left;
  }
`;
