import { useAccessPointsStore, useHierarchyStore, useMapMeasureStore, useMapStore } from "../../../stores";
import useStructuresStore from "../../../stores/StructuresStore";
import { OVERLAY_LAYERS } from "../../../stores/MapStore";
import {
  MIN_ZOOM_LEVEL_FOR_COMMENTS_LAYER,
  MIN_ZOOM_LEVEL_FOR_ALERTS_LAYER,
  MIN_ZOOM_LEVEL_FOR_COUNTIES_LAYER,
  MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER,
  MIN_ZOOM_LEVEL_FOR_PARCEL_MARKERS,
  MIN_ZOOM_LEVEL_FOR_SPANS_LAYER,
  MIN_ZOOM_LEVEL_FOR_STRUCTURES,
  MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER,
  ZOOM_LEVEL_LIMIT,
} from "./MapView.constants";
import { MapDynamicStyles as MapDynamicStyledStyled } from "./MapView.styled";
import useCommentsStore from "../../../stores/CommentStore";
import useAlertsStore from "../../../stores/AlertsStore";
import useCwcStore from "../../../stores/MapCwcStore";
import useTreeMarkersStore from "../../../stores/TreeMarkersStore";
import { BASE_LAYER } from "../../../constants/map";

export const MapDynamicStyles = () => {
  const overlayMapLayers = useMapStore((store) => store.overlayMapLayers);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const selectedStructureId = useStructuresStore((store) => store.selectedId);
  const isFeederLevel = useHierarchyStore((store) => store.isFeederLevel);
  const hoveredObjectId = useMapStore((store) => store.hoveredObjectId);
  const baseLayer = useMapStore((store) => store.baseLayer);
  const commentLayerVisible = useCommentsStore((store) => store.isLayerVisible);
  const alertsLayerVisible = useAlertsStore((store) => store.isLayerVisible);
  const areAccessPointsVisible = useAccessPointsStore((store) => store.isLayerVisible);
  const isDrawerVisible = useCwcStore((store) => store.isDrawerVisible);
  const areTreeMarkersVisible = useTreeMarkersStore((store) => store.isLayerVisible);
  const isMeasurementToolVisible = useMapMeasureStore((store) => !store.hidden);

  return (
    <MapDynamicStyledStyled
      data-zoom-level-info={currentZoomLevel}
      mapStructuresHidden={currentZoomLevel < MIN_ZOOM_LEVEL_FOR_STRUCTURES}
      spansHidden={!overlayMapLayers[OVERLAY_LAYERS.FEEDERS] || currentZoomLevel < MIN_ZOOM_LEVEL_FOR_SPANS_LAYER}
      encroachmentsHidden={!overlayMapLayers[OVERLAY_LAYERS.ENCROACHMENTS] || currentZoomLevel < ZOOM_LEVEL_LIMIT}
      feedersHidden={!overlayMapLayers[OVERLAY_LAYERS.FEEDERS]}
      clearanceHidden={!overlayMapLayers[OVERLAY_LAYERS.CLEARANCE]}
      countiesHidden={
        !overlayMapLayers[OVERLAY_LAYERS.REGIONS] ||
        currentZoomLevel > MIN_ZOOM_LEVEL_FOR_COUNTIES_LAYER ||
        !isFeederLevel
      }
      hoveredObjectId={hoveredObjectId}
      satelliteMode={baseLayer === BASE_LAYER.SATELLITE_LAYER}
      selectedStructureId={selectedStructureId}
      parcelsHidden={currentZoomLevel < MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER}
      treeCanopyHidden={currentZoomLevel < MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER}
      parcelMarkersHidden={currentZoomLevel < MIN_ZOOM_LEVEL_FOR_PARCEL_MARKERS}
      commentsVisible={currentZoomLevel > MIN_ZOOM_LEVEL_FOR_COMMENTS_LAYER}
      commentLayerVisible={commentLayerVisible}
      alertsLayerVisible={alertsLayerVisible}
      alertsVisible={currentZoomLevel > MIN_ZOOM_LEVEL_FOR_ALERTS_LAYER}
      areAccessPointsVisible={areAccessPointsVisible}
      isPolygonDrawerVisible={isDrawerVisible}
      areTreeMarkersVisible={areTreeMarkersVisible}
      isMeasurementToolVisible={isMeasurementToolVisible}
    />
  );
};
