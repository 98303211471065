import React, { FC } from "react";
import Lottie from "react-lottie";

import animationData from "../../assets/lottie/astronaut.json";
import { Button } from "../Button";
import { ErrorCode, ErrorContainer, ErrorMessage, ErrorTitle } from "./Error.styled";

interface ErrorProps {
  errorCode?: number;
  onClick: () => void;
  message: string;
  btnLabel: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

export const Error: FC<ErrorProps> = ({ errorCode, onClick, message, btnLabel }) => (
  <ErrorContainer>
    <Lottie options={defaultOptions} height={260} width={599} />
    {errorCode && (
      <>
        <ErrorTitle>ERROR</ErrorTitle>
        <ErrorCode>{errorCode}</ErrorCode>
      </>
    )}
    <ErrorMessage mt={10}>{message}</ErrorMessage>
    <Button variant="primary" mt={24} onClick={onClick}>
      {btnLabel}
    </Button>
  </ErrorContainer>
);
