import { FC, memo } from "react";
import { PositionProps } from "styled-system";

import { Portal } from "../Portal";
import { MapControlsWrapper, Control } from "../MapControl";
import { ReactComponent as Plus } from "./../../assets/images/plus.svg";
import { ReactComponent as Minus } from "./../../assets/images/minus.svg";

interface MapZoomControlProps extends PositionProps {
  map: L.Map;
  hidden?: boolean;
}

export const MapZoomControl: FC<MapZoomControlProps> = memo(({ map, hidden = false, ...props }) => {
  if (hidden) {
    return <></>;
  }

  return (
    <Portal selector="layer-menu-root">
      <MapControlsWrapper {...props}>
        <Control id="zoom-in" onClick={() => map.zoomIn(1)}>
          <Plus />
        </Control>
        <Control id="zoom-out" onClick={() => map.zoomOut(1)}>
          <Minus />
        </Control>
      </MapControlsWrapper>
    </Portal>
  );
});
