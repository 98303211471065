import { FC } from "react";

import { TREE_CANOPY_LEGEND } from "../MapTreeCanopyLayer/MapTreeCanopyLayer.constants";
import {
  TreeCanopyLegendWrapper,
  TreeCanopyLegendTitle,
  TreeCanopyLegendInfo,
  TreeCanopyTitleWrapper,
  TreeCanopyGradientWrapper,
  TreeCanopyLegendValues,
  TreeCanopyLegendValueItem,
  TreeCanopyLegendColor,
  TreeCanopyLegendName,
  ExpandCollapseLegend,
  MinMaxImage,
} from "./TreeCanopyLegend.styled";
import Maximize from "./../../assets/images/maximize_arrow.svg";
import Minimize from "./../../assets/images/minimize.svg";

type TreeCanopyLegendProps = { hidden: boolean; expanded: boolean; setExpanded: () => void; leftMenuHidden: boolean };

export const TreeCanopyLegend: FC<TreeCanopyLegendProps> = ({ hidden, expanded, setExpanded, leftMenuHidden }) => {
  if (hidden) {
    return <></>;
  }
  return (
    <TreeCanopyLegendWrapper>
      <TreeCanopyTitleWrapper>
        <TreeCanopyLegendTitle>
          <div>TREEconnaissance</div>
          <ExpandCollapseLegend onClick={setExpanded}>
            <MinMaxImage expanded={expanded} src={expanded ? Minimize : Maximize} alt={""} />
          </ExpandCollapseLegend>
        </TreeCanopyLegendTitle>
        <TreeCanopyLegendInfo>Height</TreeCanopyLegendInfo>
      </TreeCanopyTitleWrapper>
      {expanded && (
        <TreeCanopyGradientWrapper>
          <TreeCanopyLegendValues>
            {Object.keys(TREE_CANOPY_LEGEND).map((key) => (
              <TreeCanopyLegendValueItem key={key}>
                <TreeCanopyLegendColor backgroundColor={TREE_CANOPY_LEGEND[key].color} />
                <TreeCanopyLegendName>{TREE_CANOPY_LEGEND[key].name}</TreeCanopyLegendName>
              </TreeCanopyLegendValueItem>
            ))}
          </TreeCanopyLegendValues>
        </TreeCanopyGradientWrapper>
      )}
    </TreeCanopyLegendWrapper>
  );
};
