import React, { FC } from "react";

import { BreadcrumbsStyled, StyledLink } from "./Breadcrumbs.styled";

export interface Breadcrumb {
  name: string;
  key: string;
  value: string;
}

interface BreadcrumbProps {
  data: Breadcrumb[];
  onClick: (breadcrumb: Breadcrumb) => void;
}

export const Breadcrumbs: FC<BreadcrumbProps> = ({ data, onClick }) => {
  if (!data || !data.length) {
    return <></>;
  }
  return (
    <BreadcrumbsStyled data-testid="breadcrumb-navigation">
      {data.map((breadcrumb, index) => (
        <StyledLink
          key={breadcrumb.value || index}
          data-testid="breadcrumb-item"
          onClick={() => onClick(breadcrumb)}
          isActive={index === data.length - 1}
        >
          {breadcrumb.name}
        </StyledLink>
      ))}
    </BreadcrumbsStyled>
  );
};
