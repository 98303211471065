import { Point } from "geojson";

export type StructureStatus =
  | "GOOD"
  | "NEEDS REPAIR"
  | "NOT INSPECTED"
  | "REJECT"
  | "REPLACED"
  | "CRITICAL"
  | "URGENT REPAIR"
  | "REPAIRS MADE"
  | "undetermined";

export type StructureName =
  | "Wood Pole"
  | "Steel Pole"
  | "Concrete Pole"
  | "Lattice"
  | "FOS"
  | "Wood H-Frame"
  | "Steel H-Frame"
  | "Concrete H-Frame";

export enum STRUCTURE_MODE {
  DETAILED,
  OVERVIEW,
  OVERVIEW_WITH_DETAILS,
}

export interface StructuresProperties {
  id: string;
  type: {
    id: string;
    name: StructureName;
  };
  metadata: {
    circuit: string;
    label: string;
    status: StructureStatus;
    isUrban: boolean;
    elevation: string;
    voltage: string;
  };
  feederIds: string[];
}

export type Structure = GeoJSON.Feature<Point, StructuresProperties>;

export type StructuresResponse = GeoJSON.FeatureCollection<Point, StructuresProperties>;
