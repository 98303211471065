import styled from "styled-components";
import { Data1, Data2, FontStyle, InputLabel } from "../../../assets/styles/fonts.styled";

export const SubPageWrapper = styled.div`
  overflow: hidden;
`;

export const HeaderText = styled.h1`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => props.theme.colors.trout}; ;
`;

export const SpanInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.manatee};
  padding-left: 9px;
`;

export const Placeholder = styled.div`
  width: 30px;
`;

export const CellWrapper = styled.div``;

export const TableFooterRowWrapper = styled.div``;

export const WorkTypeRatesWrapper = styled.div`
  tbody tr td input {
    width: 90%;
  }
  .sticky-work-type {
    position: sticky;
    left: 0px;
    background-color: inherit;
    z-index: 10;
  }
  .sticky-work-type::before {
    position: absolute;
    left: -30px;
    width: 30px;
    height: 101%;
    background-color: var(--colors-athens-gray);
    content: "";
    top: 0px;
  }
  .sticky-unit {
    position: sticky;
    left: 236px;
    background-color: inherit;
    z-index: 10;
  }
`;

export const CrewTableWrapper = styled.div`
  table {
    padding-right: 0;
    margin-right: 0;
  }
  thead tr:first-child {
    background: var(--colors-alabaster-3);
    th {
      ${Data1}
      text-transform: uppercase;
      color: var(--colors-pale-sky);
      text-align: center;
    }
    th:last-child {
      background: var(--colors-white);
    }
  }
  tfoot {
    th:nth-child(1),
    th:nth-child(2) {
      background: var(--colors-white);
    }
    th:nth-child(n + 3) {
      background: var(--colors-alabaster-3);
    }
    th:last-child {
      background: var(--colors-white);
    }
    tr:nth-child(n + 2) {
      display: none;
    }
  }
  thead .billing-rate,
  th .billing-rate {
    ${FontStyle}
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--colors-pale-sky);
  }
  .billing-rate {
    background-color: var(--colors-alabaster-2);
    color: var(--colors-pale-sky);
  }
  .billing-rate:nth-child(2n + 2) {
    // border-right: 10px solid white;
  }
  .billing-rate input {
    color: var(--colors-pale-sky);
    text-align: center;
    width: 80%;
  }
  tbody tr td {
    ${Data1}
    text-align: right;
    color: var(--colors-alabaster-2);
  }

  .role-name input,
  .role-name div {
    ${Data2}
    color: #111827;
    padding-left: 20px;
    text-align: left;
  }

  .footer-number-of-people,
  .number-of-people input {
    ${Data1}
    width: 80%;
    color: var(--colors-pale-sky);
  }
  .footer-billing-rate {
    ${InputLabel}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: not-allowed;
    color: #c4c4c4;
  }

  .footer-number-of-people {
    padding-left: 11px;
    text-align: left;
    cursor: not-allowed;
    color: #c4c4c4;
  }
  .remove-role div:first-child {
    justify-content: center;
  }
  .remove-role .editable-input {
    display: none;
  }
  table tr,
  table tbody tr {
    height: 86px;
  }
  table thead tr {
    height: 49px;
  }

  table tbody tr:hover {
    outline: 1px solid var(--colors-teal-3);
    outline-offset: -1px;

    .remove-role img {
      display: inline-block;
    }
  }

  tbody tr td:first-child {
    border-right: none;
  }
  tbody tr td:nth-child(2n + 2),
  tfoot tr th:nth-child(2n + 2) {
    //border-right: 10px solid white;
  }
  tbody tr td:nth-child(2n + 3),
  tfoot tr th:nth-child(2n + 3) {
    border-right: none;
  }
  thead tr:last-child th:nth-child(2n + 2):nth-last-child(n + 3),
  tbody tr td:nth-child(2n + 2):nth-last-child(n + 3),
  tfoot tr th:nth-child(2n + 2):nth-last-child(n + 3) {
    border-right: 10px solid var(--colors-white);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  align-items: center;
  .delete-button {
    width: fit-content;

    &:not(:disabled) {
      color: var(--colors-priority-violation);
    }
  }
  .add-crew {
    background: #eceff3;
  }
  button {
    width: 136px;
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
  button:not(:disabled):hover:nth-child(2) {
    color: white;
    background-color: var(--colors-teal-3);
  }
`;
