import React, { FC, useState, memo } from "react";
import axios from "axios";
import { SectionItem } from "../../../../components/RightDrawer/DrawerSectionItems/SectionItem";
import { DrawerSection } from "../../../../components/RightDrawer/DrawerSectionItems/DrawerSection";
import { SectionData } from "../RightDrawerMenu.utils";
import { RightOfWay } from "../../../../components/RightDrawer/DrawerSectionItems/RightOfWay";
import { PARCEL_STATUS } from "../../../../types/responses";
import { ParcelStatusContact } from "../../../../components/RightDrawer/DrawerSectionItems/ParcelStatusContact";
import { AttachmentThumbnails } from "../../../../components/AttachmentThumbnails";
import { Attachments, FileAttachment } from "../../../../components/Thumbnail";
import { SecondLevelMenu } from "../SecondLevelMenu/SecondLevelMenu";
import { EMPTY_FN } from "../../../../constants";
import { UploadingProgress } from "../../../../components/UploadingProgress";
import { useCustomQuery } from "../../../../hooks";
import { getAttachementsUrl, uploadAttachementsUrl, deleteAttachmentUrl, getAttachmentUrl } from "../../../../api";
import ApiClient from "../../../../utils/apiClient";
import { AddAttachmentsResponseDto, AttachmentDto, AttachmentSize } from "../../../../types/responses/Attachments";
import { logError } from "../../../../services";
import { getFileType } from "../RightDrawerMenu.utils";

const ONE_SECOND = 1000;

export enum MUTATION_STATE {
  "Idle",
  "Loading",
  "Done",
}

export const ParcelInfoSection: FC<{
  data: SectionData;
  onActionNameClick: () => void;
  onRightOfWayChange: (args: any) => void;
  rightOfWayValue: Nullable<{ left: Nullable<number>; right: Nullable<number> }>;
  actionDisabled?: boolean;
  closeDrawer: () => void;
}> = memo(({ data, onRightOfWayChange, onActionNameClick, rightOfWayValue, actionDisabled = false, closeDrawer }) => {
  const [editableContact, setEditableContact] = useState(false);
  const [secondLevel, setSecondLevel] = useState(false);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);
  const [processingAttachments, setProcessingAttachments] = useState(false);
  const [attachments, setAttachments] = useState<Attachments>({ attachments: [] });

  const { isLoading: attachmentsLoading, refetch: refetchAttachments } = useCustomQuery<Attachments>(
    getAttachementsUrl(data?.id as string, "parcels", AttachmentSize.Thumbnail, true),
    {
      cacheTime: ONE_SECOND,
      staleTime: 0,
      retry: 1,
      enabled: data?.id !== undefined,
      onSuccess: (data: Attachments) => {
        setProcessingAttachments(false);
        setAttachments({
          attachments: data.attachments.map((item: any) => ({
            ...item,
            type: getFileType(item.fileName),
          })) as FileAttachment[],
        });
      },
    }
  );

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files?.length === 0) return;

    const files = Array.from(event.target.files);

    setUploadingInProgress(true);
    try {
      const apiResponse = await ApiClient.post<AddAttachmentsResponseDto>(
        uploadAttachementsUrl(data?.id as string, "parcels"),
        {
          filenames: files.map((file) => file.name),
        }
      );

      const uploadPromises = apiResponse.data.attachments.map((item) => {
        const targetFile = files.find((file) => file.name === item.fileName);
        if (!targetFile) return null;

        return axios.put(item.uploadUrl, targetFile, {
          headers: {
            "Content-Type": targetFile.type,
          },
        });
      });

      await Promise.allSettled(uploadPromises);

      refetchAttachments();
      setProcessingAttachments(true);
    } catch (error) {
      logError(error);
    } finally {
      setUploadingInProgress(false);
    }
  };

  const onDelete = async (fileId: string) => {
    try {
      const res = await ApiClient.delete(deleteAttachmentUrl(fileId));
      if (res.status === 200) refetchAttachments();
    } catch (error) {
      logError(error);
    }
  };

  const getFile = async (attachmentId: string, size: AttachmentSize): Promise<Maybe<AttachmentDto>> => {
    try {
      const res = await ApiClient.get<AttachmentDto>(getAttachmentUrl(attachmentId, size));
      if (res.status !== 200) return;
      return res.data;
    } catch (error) {
      logError(error);
    }
  };

  return (
    <>
      {secondLevel ? (
        <SecondLevelMenu
          name="ALL Attachments"
          actionDisabled={false}
          actionName="+Add"
          onActionNameClick={EMPTY_FN}
          uploader={true}
          closeDrawer={closeDrawer}
          onUpload={onUpload}
          onBack={() => setSecondLevel(false)}
        >
          <AttachmentThumbnails
            attachments={attachments?.attachments}
            showAllAttachments={false}
            onDelete={onDelete}
            getFile={getFile}
          />
        </SecondLevelMenu>
      ) : (
        <div>
          <DrawerSection name="PARCEL INFO">
            <SectionItem name="Parcel ID">{data?.parcelId}</SectionItem>
            <SectionItem name="Address">{data?.address}</SectionItem>
            <SectionItem name="City">{data?.city}</SectionItem>
            <ParcelStatusContact
              formData={{
                ownerFirstName: data?.ownerFirstName ? (data.ownerFirstName as string) : "",
                ownerLastName: data?.ownerLastName ? (data.ownerLastName as string) : "",
                contactEmail: data?.contactEmail ? (data.contactEmail as string) : "",
                contactPhone: data?.contactPhone ? (data.contactPhone as string) : "",
                ownerSameAsOnSiteContact:
                  data?.ownerSameAsOnSiteContact !== undefined ? (data.ownerSameAsOnSiteContact as boolean) : false,
                onSiteOwnerContactEmail: data?.onSiteOwnerEmail ? (data.onSiteOwnerEmail as string) : "",
                onSiteOwnerContactPhone: data?.onSiteOwnerPhone ? (data.onSiteOwnerPhone as string) : "",
                onSiteOwnerFirstName: data?.onSiteOwnerFirstName ? (data.onSiteOwnerFirstName as string) : "",
                onSiteOwnerLastName: data?.onSiteOwnerLastName ? (data.onSiteOwnerLastName as string) : "",
                note: data?.note ? (data.note as string) : "",
                status: data?.status ? (data?.status as PARCEL_STATUS) : PARCEL_STATUS.NOT_ASSIGNED,
              }}
              editable={editableContact}
              setEditable={(editable: boolean) => {
                setEditableContact(editable);
              }}
              parcelId={data?.id ? (data.id as string) : undefined}
            />
          </DrawerSection>

          <DrawerSection
            name="ATTACHMENTS"
            actionName="+Add"
            actionDisabled={false}
            actionHeader={true}
            onUpload={onUpload}
          >
            <AttachmentThumbnails
              attachments={attachments?.attachments}
              showAllAttachments
              isLoading={attachmentsLoading}
              sliceNumberOfElements={3}
              onDelete={onDelete}
              getFile={getFile}
              onClick={() => setSecondLevel(!secondLevel)}
            />
          </DrawerSection>
          <DrawerSection
            name="PARCEL RIGHT OF WAY"
            actionName="Restore Defaults"
            onActionNameClick={onActionNameClick}
            actionDisabled={actionDisabled}
            actionHeader={false}
          >
            <RightOfWay onChange={onRightOfWayChange} value={rightOfWayValue} />
          </DrawerSection>
          {(uploadingInProgress || processingAttachments) && (
            <UploadingProgress
              status={uploadingInProgress ? "LOADING" : processingAttachments ? "PROCESSING" : "DONE"}
            />
          )}
        </div>
      )}
    </>
  );
});
