import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { crewsIdUrl } from "../api";

export const DELETE_CREW_MUTATION_KEY = "add-new-crew";

export const useDeleteCrew = () => {
  return useMutation(DELETE_CREW_MUTATION_KEY, (crewId: string) => ApiClient.delete(crewsIdUrl(crewId)));
};
