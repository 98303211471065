import styled from "styled-components";

import { Data1 } from "../../../assets/styles/fonts.styled";

export const TreeSpeciesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TreeSpeciesImg = styled.img`
  width: 28px;
  height: 28px;
`;

export const TreeSpeciesName = styled.div`
  ${Data1}
  padding-left: 16px;
`;

export const TreeSpeciesItemWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid var(--colors-gallery);
  height: 45px;
  width: 100%;
  align-items: center;
  :last-child {
    border: none;
  }
`;
