import { FC, useEffect, useRef, useState, memo } from "react";
import cloneDeep from "lodash.clonedeep";
import { useHistory } from "react-router";

import "./GanttChart.styles.css";
import MaximizeIcon from "./../../assets/images/maximize.png";
import ZoomIn from "./../../assets/images/zoom_in.png";
import ZoomOut from "./../../assets/images/zoom_out.png";
import GanttExportIcon from "./../../assets/images/gantt_export.png";
import { ReactComponent as GanttCloseIcon } from "./../../assets/images/gantt_close.svg";

import { closeAllTasks, mapDataForUpdateCutPlan, zoomConfig } from "./GanttChart.utils";
import { Divider } from "./GanttChart.styled";
import { Button } from "../Button";
import { Spacer } from "../../assets/styles/spacer.styled";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ButtonContainer, GanttChartContainer, SelectContainer } from "./GanttChart.styled";
import { Select, Option } from "../Select";
import { MappedCutPlan } from "../CutPlan/CutPlan";
import { DownloadLink } from "../DownloadLink/DownloadLink";
import { GanttStatic } from "../../types/dhtmlxgantt";
import { CutPlanNode, UpdateCutPlanDto } from "../../types/responses/CutPlanResponse";
import { toLocalDate } from "../../utils/dateUtils";
import { Portal } from "../Portal";
import { ModalDialog } from "../ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../ModalDialog/ModalDialog.styled";
import {
  getSearchParams,
  setSpanIdParam,
  setVisibleDrawerParam,
  resetToLeftMenuSearchParams,
} from "../../utils/navigationUtils";
import { getForecastRoute } from "../../routes";
import { useMapStore } from "../../stores";
import { Point } from "@turf/turf";

interface GanttChartProps {
  data: Nullable<{ data: Maybe<MappedCutPlan>[] }>;
  selectedId: Nullable<string>;
  setCutPlanSelectedId: (name: string) => void;
  onClose: () => void;
  exportUrl: string;
  updateCutPlan: (value: UpdateCutPlanDto) => void;
  addPosition: (point: Point, id: string) => void;
  addSelectedSpanBy: (id: string[]) => void;
  removeSelectedSpanBy: (id: string) => void;
  height?: number;
  minDate: Maybe<number>;
  maxDate: Maybe<number>;
}

const dateNow = new Date();

const DEFAULT_SCALE_START = new Date(dateNow.getFullYear(), 1, 1);

const DEFAULT_SCALE_END = new Date(dateNow.getFullYear() + 4, 12, 31);

const DATE_OFFSET = 3;

const options = [
  { label: "Year", value: "year" },
  { label: "Quarter", value: "quarter" },
  { label: "Month", value: "month" },
  { label: "Week", value: "week" },
  { label: "Day", value: "day" },
];

const gantt: GanttStatic = (window as any).gantt;

export const GanttChart: FC<GanttChartProps> = memo(
  ({
    data,
    selectedId,
    setCutPlanSelectedId,
    onClose,
    exportUrl,
    updateCutPlan,
    addPosition,
    addSelectedSpanBy,
    removeSelectedSpanBy,
    height = 550,
    minDate,
    maxDate,
  }) => {
    const ganttContainer = useRef<any>();
    const [selectedScale, setSelectedScale] = useState(options[2]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [ganttChartInitialized, setGanttChartInitilaized] = useState(false);
    const [changedRecords, setChangedRecords] = useState<Record<string, CutPlanNode>>({});
    const [modalHidden, setModalHidden] = useState(true);
    const setLatestObjectIdSelectedForMapAnimation = useMapStore(
      (store) => store.setLatestObjectIdSelectedForMapAnimation
    );
    const history = useHistory();

    const setReadonlyGantt = (readOnly: boolean) => {
      if (!readOnly) {
        gantt.config.readonly = false;
        gantt.config.multiselect = true;
      } else {
        gantt.config.readonly = true;
        gantt.config.multiselect = false;
      }
      gantt.refreshData();
    };

    const setEditModeOnGantt = (editMode: boolean) => {
      setReadonlyGantt(!editMode);
      setIsEditMode(editMode);
    };

    const onGanttMaximize = () => {
      if (!gantt.getState().fullscreen) {
        gantt.expand();
      } else {
        gantt.collapse();
      }
    };

    const onZoomIn = () => gantt.ext.zoom.zoomOut();

    const onZoomOut = () => gantt.ext.zoom.zoomIn();

    useEffect(() => {
      if (!ganttContainer.current) {
        return;
      }

      gantt.ext.zoom.init(zoomConfig);
      gantt.ext.zoom.setLevel(selectedScale.value);
      gantt.init(ganttContainer.current);
    }, [selectedScale.value]);

    // scroll to task
    useEffect(() => {
      if (!ganttChartInitialized) return;

      const selectTaskInGantt = (selectedId: Nullable<string>) => {
        // First, unselect already selected tasks, if any
        const selectedTasksIDs = gantt.getSelectedTasks();
        selectedTasksIDs.forEach((taskId) => taskId !== selectedId && gantt.unselectTask(taskId));

        const selectedSpan = data?.data?.find((item) => item?.spanId === selectedId);
        if (!selectedSpan?.id) {
          return;
        }

        const task = gantt.getTask(selectedSpan.id);
        if (!task) {
          return;
        }

        closeAllTasks();
        if (task.parent) {
          gantt.open(task.parent);
        }
        gantt.selectTask(selectedSpan.id);
        gantt.ext.zoom.setLevel("day");
        setSelectedScale(options[4]);

        setTimeout(() => {
          // @ts-ignore
          const sizes = gantt.getTaskPosition(task);
          gantt.scrollTo(sizes.left, sizes.top - 100);
        }, 0);
      };

      selectTaskInGantt(selectedId);
    }, [selectedId, ganttChartInitialized, data?.data]);

    useEffect(() => {
      if (!data) {
        return;
      }
      gantt.clearAll();
      const firstItemDate = minDate ? new Date(minDate - DATE_OFFSET) : DEFAULT_SCALE_START;
      const lastItemDate = maxDate ? new Date(maxDate + DATE_OFFSET) : DEFAULT_SCALE_END;
      gantt.config.start_date = firstItemDate;
      gantt.config.end_date = lastItemDate;
      gantt.parse({ ...data });
      gantt.render();
      gantt.refreshData();
      setGanttChartInitilaized(true);
    }, [data, maxDate, minDate]);

    useEffect(() => {
      gantt.attachEvent(
        "onCollapse",
        () => {
          setIsFullScreen(false);
        },
        {}
      );
    }, []);

    useEffect(() => {
      gantt.attachEvent(
        "onExpand",
        () => {
          setIsFullScreen(true);
        },
        {}
      );
    }, []);

    useEffect(() => {
      gantt.attachEvent(
        "onAfterTaskUpdate",
        (id: string, task: { start_date: string; end_date: string; parent: string; nodeId: string }) => {
          const records = cloneDeep(changedRecords);
          records[id] = {
            startTime: toLocalDate(task.start_date),
            finishTime: toLocalDate(task.end_date),
            id: task.nodeId,
          };
          // this is how we can get parent task and values for start and end date in order to update on BE
          const parentTask = gantt.getTask(task.parent);
          if (parentTask) {
            records[parentTask.id] = {
              startTime: toLocalDate(parentTask.start_date),
              finishTime: toLocalDate(parentTask.end_date),
              id: parentTask.nodeId,
            };
          }
          setChangedRecords(records);
        },
        {}
      );
    }, [changedRecords]);

    useEffect(() => {
      gantt.ext.zoom.attachEvent("onAfterZoom", (level: number) => setSelectedScale(options[level]));
    }, []);

    useEffect(() => {
      const onSingleSpanSelected = (id: string) => {
        const task = gantt.getTask(id);
        // make sure that selected element is span
        if (!task || task.type !== "task") return;

        // make sure that only one span is selected
        const selectedTasks = gantt.getSelectedTasks();
        if (selectedTasks.length >= 2) return;

        task.centerPoint && addPosition(task.centerPoint, task.spanId);

        const params = getSearchParams();
        resetToLeftMenuSearchParams(params);
        setLatestObjectIdSelectedForMapAnimation(task.spanId);
        setSpanIdParam({ params, id: task.spanId });
        setVisibleDrawerParam({ params, visible: true });
        setCutPlanSelectedId(id);

        return history.push({ pathname: getForecastRoute(), search: params.toString() });
      };

      gantt.attachEvent("onTaskRowClick", onSingleSpanSelected, {});
    }, [history, setCutPlanSelectedId, setLatestObjectIdSelectedForMapAnimation, addPosition]);

    useEffect(() => {
      const onMultiSpanSelected = () => {
        const selectedTasks = gantt.getSelectedTasks();
        if (selectedTasks.length <= 1) return;

        const selectedTasksSpandIDs = selectedTasks.map((taskId) => {
          const task = gantt.getTask(taskId);
          return task.spanId;
        });

        addSelectedSpanBy(selectedTasksSpandIDs);
      };

      gantt.attachEvent("onMultiSelect", onMultiSpanSelected, {});

      gantt.attachEvent(
        "onTaskUnselected",
        (id) => {
          const task = gantt.getTask(id);
          if (!task || task.type !== "task") return;

          removeSelectedSpanBy(task.spanId);
        },
        {}
      );
    }, [removeSelectedSpanBy, addSelectedSpanBy]);

    return (
      <>
        <Portal selector="layer-menu-root">
          <ModalDialog
            hidden={modalHidden}
            message={"Are you sure you want to turn off editing mode?All changes will be lost."}
            onClose={() => {
              setModalHidden(true);
            }}
            title=""
            warning
          >
            <ButtonsWrapper>
              <ButtonWrapper>
                <Button
                  variant="cancel"
                  size="small"
                  onClick={() => {
                    setModalHidden(true);
                    setEditModeOnGantt(false);
                    setChangedRecords({});
                    const undoStack = gantt.getUndoStack();
                    for (let i = 0; i <= undoStack.length; i++) {
                      gantt.undo();
                    }
                    gantt.clearUndoStack();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="warning"
                  size="small"
                  onClick={() => {
                    setModalHidden(true);
                  }}
                >
                  Close
                </Button>
              </ButtonWrapper>
            </ButtonsWrapper>
          </ModalDialog>
        </Portal>
        <ButtonContainer>
          {isEditMode && (
            <StyledFlex>
              <Button
                variant="primary"
                onClick={() => {
                  updateCutPlan(mapDataForUpdateCutPlan(changedRecords));
                  setEditModeOnGantt(false);
                  setChangedRecords({});
                  gantt.refreshData();
                }}
                fontSize="14px"
                pl={1}
                pr={1}
                pt="7px"
                pb={2}
                height={31}
                width={61}
              >
                Save
              </Button>
              <Spacer mr="12px" />
              <Button
                variant="light"
                onClick={() => {
                  setModalHidden(false);
                }}
                border="1px solid #DADADA"
                fontSize="14px"
                pl={1}
                pr={1}
                pt="7px"
                pb={2}
                height={31}
                width={61}
              >
                Cancel
              </Button>
            </StyledFlex>
          )}
          {!isEditMode && (
            <StyledFlex>
              <Button
                variant="primary"
                onClick={() => {
                  setEditModeOnGantt(!isEditMode);
                }}
                fontSize="14px"
                pl={1}
                pr={1}
                pt="7px"
                pb={2}
                height={31}
                width={61}
              >
                Edit
              </Button>
            </StyledFlex>
          )}

          <StyledFlex alignItems="center">
            <Button
              variant="light"
              onClick={onZoomIn}
              fontSize="14px"
              border="1px solid rgba(0, 0, 0, 0.06)"
              pl={1}
              pr={1}
              pt="7px"
              pb={2}
              height={31}
              width={31}
              disabled={gantt.ext.zoom.getCurrentLevel() === gantt.ext.zoom.getLevels().length - 1}
            >
              <img src={ZoomIn} alt="zoom in icon" />
            </Button>
            <Button
              variant="light"
              onClick={onZoomOut}
              fontSize="14px"
              border="1px solid rgba(0, 0, 0, 0.06)"
              pt="7px"
              pb={2}
              px={1}
              ml="15px"
              height={31}
              width={31}
              disabled={gantt.ext.zoom.getCurrentLevel() === 0}
            >
              <img src={ZoomOut} alt="zoom in icon" />
            </Button>
            <Divider />

            <DownloadLink href={exportUrl} filename="cut-plan.csv">
              <Button
                variant="primary"
                fontSize="14px"
                border="1px solid rgba(0, 0, 0, 0.06)"
                pl={"10px"}
                pt={2}
                pb={2}
                height={34}
                width={108}
              >
                <StyledFlex>
                  <img src={GanttExportIcon} alt="export-icon" /> <Spacer pl="13px" />
                  Export
                </StyledFlex>
              </Button>
            </DownloadLink>
            <Button
              variant="light"
              onClick={onGanttMaximize}
              border="1px solid rgba(0, 0, 0, 0.06)"
              pl={1}
              pr={1}
              pt="7px"
              pb={2}
              height={31}
              width={31}
            >
              <img src={MaximizeIcon} alt="maximize-icon" /> <Spacer pl="3" />
            </Button>
            <Spacer mr="7px" />
            <Button
              variant="light"
              onClick={() => {
                setReadonlyGantt(true);
                onClose();
              }}
              fontSize="14px"
              border="1px solid rgba(0, 0, 0, 0.06)"
              height={31}
              width={31}
              pl={1}
              pr={1}
              pt="7px"
              pb={2}
            >
              <GanttCloseIcon />
            </Button>
          </StyledFlex>
        </ButtonContainer>
        <GanttChartContainer ref={ganttContainer} height={height} />
        <SelectContainer isFullScreen={isFullScreen}>
          <Select
            height={32}
            isDisabled={false}
            ml={2}
            options={options}
            value={selectedScale}
            width={108}
            onChange={(value: Option) => {
              setSelectedScale(value);
              gantt.ext.zoom.setLevel(value.value);
            }}
          />
        </SelectContainer>
      </>
    );
  }
);
