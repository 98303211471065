import { FC, useCallback, useEffect, useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { polygon } from "@turf/turf";
import useCwcStore from "../../../stores/MapCwcStore";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./MapCwcTool.css";
import L, { DrawEvents, DrawMap, LatLng } from "leaflet";
import { FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useCreateCustomPolygon, useEditCustomPolygon, useMapTooltip, useCustomMapPane } from "../../../hooks";
import { latLngsToCoords } from "./MapCwcTool.utils";
import { useDrawerStore, useThreatsStore } from "../../../stores";
import { ContentType } from "../../../stores/DrawerStore/DrawerStore.types";
import { RISK_PRIORITY } from "../../../types/responses";

const PANE_NAME = "CUSTOM_POLYGON";

const PolygonOptions = {
  allowIntersection: false, // Restricts shapes to simple polygons
  clickable: true,
  shapeOptions: {
    color: "#FF0000",
    stroke: true,
    weight: 2,
    opacity: 1,
    fill: true,
  },
};
interface IProps {
  map: Nullable<L.Map>;
  zIndex?: number;
}
export const MapCwcTool: FC<IProps> = ({ map, zIndex = 600 }) => {
  const isDrawerVisible = useCwcStore((store) => store.isDrawerVisible);

  const polygonLayer = useMemo(() => new L.Draw.Polygon(map as DrawMap, PolygonOptions), [map]);
  const [editControlRef, setEditControlRef] = useState<Nullable<L.Control>>(null);
  const [featureGroupRef, setFeatureGroupRef] = useState<Nullable<L.FeatureGroup<any>>>(null);
  const [editingPolygonId, setEditingPolygonId] = useState("");
  const { onMouseMove, onMouseOut } = useMapTooltip({ layer: map });
  const { mutateAsync: createCustomPolygon } = useCreateCustomPolygon();
  const { mutate: editCustomPolygon } = useEditCustomPolygon();
  const { threats: polygons } = useThreatsStore();
  const pushOnStack = useDrawerStore((state) => state.push);
  const popFromStack = useDrawerStore((state) => state.pop);

  useCustomMapPane({
    map,
    name: PANE_NAME,
    zIndex,
  });

  const onEditVertex = useCallback(
    (e: DrawEvents.EditVertex) => {
      const coords = latLngsToCoords(e.poly.getLatLngs()[0] as LatLng[]);
      const editedPoly = polygons?.features?.find((feature) => feature.properties?.id === editingPolygonId);
      const polyData = {
        polygonId: editingPolygonId,
        riskPriority: editedPoly?.properties?.riskPriority ?? RISK_PRIORITY.LOW,
        geometry: {
          type: "Polygon",
          ...editedPoly?.geometry,
          coordinates: [coords],
        },
      };
      editCustomPolygon(polyData);
    },
    [editCustomPolygon, polygons, editingPolygonId]
  );

  const onPolygonClick = useCallback(
    (e: L.LeafletEvent, featureId: string) => {
      L.DomEvent.stopPropagation(e);

      if (e.target.editing.enabled()) {
        polygonLayer.enable();
        setEditingPolygonId("");
        popFromStack();
      } else {
        const polygonData = polygons?.features?.find((feature) => feature.properties?.id === featureId);
        const drawerElement = {
          type: ContentType.CUSTOM_POLYGON,
          id: featureId,
          coordinates: e.target.getBounds().getCenter(),
          riskPriority: {
            priority: polygonData?.properties?.riskPriority as RISK_PRIORITY,
            score: polygonData?.properties?.score || 0,
          },
        };
        pushOnStack(drawerElement);
        polygonLayer.disable();
        setEditingPolygonId(featureId);
      }
    },
    [polygonLayer, polygons, popFromStack, pushOnStack]
  );

  useEffect(() => {
    if (!map || !polygonLayer) return;

    const onCreated = (e: DrawEvents.Created) => {
      const poly = e.layer as L.Polygon;
      const latLngs = poly.getLatLngs() as LatLng[][];

      const coords = latLngsToCoords(latLngs[0]);
      const newPoligon = polygon([coords]);
      createCustomPolygon(newPoligon).then(({ data }) => {
        const { properties } = data.features[0];
        const drawerElement = {
          type: ContentType.CUSTOM_POLYGON,
          id: properties?.id,
          coordinates: e.target.getCenter(),
          riskPriority: {
            priority: properties?.riskPriority,
            score: properties?.score || 0,
          },
        };
        pushOnStack(drawerElement);
        setEditingPolygonId(properties?.id);
        featureGroupRef?.removeLayer(e.layer);
      });
    };

    map.on(L.Draw.Event.CREATED, onCreated as L.LeafletEventHandlerFn);
    map.on(L.Draw.Event.EDITVERTEX, onEditVertex as L.LeafletEventHandlerFn);

    return () => {
      map.off(L.Draw.Event.CREATED, onCreated as L.LeafletEventHandlerFn);
      map.off(L.Draw.Event.EDITVERTEX, onEditVertex as L.LeafletEventHandlerFn);
    };
  }, [polygonLayer, map, onEditVertex, createCustomPolygon, featureGroupRef, pushOnStack]);

  useEffect(() => {
    if (!editControlRef || !map) return;

    return () => {
      map.removeControl(editControlRef);
      editControlRef.remove();
    };
  }, [map, editControlRef]);

  useEffect(() => {
    if (isDrawerVisible) {
      polygonLayer.disable();
    } else {
      polygonLayer.enable();
    }
    return () => {
      polygonLayer?.disable();
      featureGroupRef?.clearLayers();
    };
  }, [isDrawerVisible, polygonLayer, featureGroupRef]);

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        // Cancel Drawing
        featureGroupRef?.clearLayers();
        polygonLayer?.disable();
        setTimeout(() => {
          polygonLayer?.enable();
        }, 500);
      }
    };

    document.addEventListener("keydown", onEscape, false);

    return () => {
      document.removeEventListener("keydown", onEscape, false);
    };
  }, [featureGroupRef, polygonLayer]);

  const renderedPolygons = useMemo(() => {
    return (
      <FeatureGroup>
        {polygons?.features?.map((feature) => (
          <Polygon
            pane={PANE_NAME}
            ref={(ref) => {
              if (editingPolygonId !== feature?.properties?.id) return;

              if (isDrawerVisible) {
                // @ts-ignore
                ref?.editing.enable();
              } else {
                // @ts-ignore
                ref?.editing.disable();
              }
            }}
            key={uuidv4()}
            className={"polygon"}
            interactive={true}
            pathOptions={{ color: "red", fill: true, fillColor: "transparent" }}
            //@ts-ignore
            positions={[feature.geometry.coordinates[0].map((crds: Position) => [crds[1], crds[0]])]}
            eventHandlers={{
              click: (e) => onPolygonClick(e, feature.properties?.id),
              mouseover: (e) => {
                // @ts-ignore
                if (polygonLayer._markers?.length === 0) {
                  // Disable layer only if user has not started drawing
                  polygonLayer?.disable();
                }
                const tooltipMsg = `Click to ${e.target.editing.enabled() ? "disable" : "enable"} editing polygon`;
                onMouseMove(e, tooltipMsg);
              },
              mouseout: () => {
                onMouseOut();
                if (!isDrawerVisible) {
                  polygonLayer?.enable();
                }
              },
            }}
          />
        ))}
      </FeatureGroup>
    );
  }, [polygons, polygonLayer, onPolygonClick, editingPolygonId, onMouseMove, onMouseOut, isDrawerVisible]);

  return (
    <>
      <FeatureGroup ref={(featureGroupRef) => setFeatureGroupRef(featureGroupRef)}>
        <EditControl
          onMounted={(ref: L.Control) => setEditControlRef(ref)}
          position="bottomleft"
          draw={{
            polygon: true,
            polyline: false,
            rectangle: false,
            circle: false,
            marker: false,
            circlemarker: false,
          }}
        />
      </FeatureGroup>
      {renderedPolygons}
    </>
  );
};
