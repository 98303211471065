import { useQuery } from "react-query";
import ApiClient from "../../../utils/apiClient";
import { MemberTypesResponse, EquipmentTypesResponse, CrewTypes } from "../types";
import { memberTypesUrl, equipmentTypesUrl, crewTypesUrl } from "../../../api";

const getMemberTypes = () =>
  ApiClient.get<MemberTypesResponse>(memberTypesUrl).then((response) => response.data.crewMemberTypes);

const getEquipmentTypes = () =>
  ApiClient.get<EquipmentTypesResponse>(equipmentTypesUrl).then((response) => response.data.equipmentTypes);

export const useCrewTypes = () => {
  const { data: crewMemberTypes, isFetched: memberTypesFetched } = useQuery("crew-member-types", getMemberTypes);
  const { data: equipmentTypes, isFetched: equipmentTypesFetched } = useQuery("equipment-types", getEquipmentTypes);

  return useQuery(
    "crew-types",
    () => ApiClient.get<CrewTypes>(crewTypesUrl).then((response) => response.data.crewTypes),
    {
      enabled: memberTypesFetched && equipmentTypesFetched,
      select: (data) => {
        // Extending API data with data that UI needs
        return data.map((item) => {
          const equipmentTypesData = equipmentTypes?.filter((eqItem) =>
            item.equipmentTypeIds.some((idItem) => idItem === eqItem.id)
          );

          const memberTypesData = crewMemberTypes?.filter((cmItem) =>
            item.crewMemberTypeIds.some((idItem) => idItem === cmItem.id)
          );

          return {
            ...item,
            equipmentTypes: equipmentTypesData ?? [],
            memberTypes: memberTypesData ?? [],
          };
        });
      },
    }
  );
};
