import styled from "styled-components";
import { DataButton } from "../../../assets/styles/fonts.styled";

import { RIGHT_SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../../../constants/dimensions";
import { RIGHT_DRAWER } from "../../../constants/z-index";
import FilterIcon from "./../../../assets/images/filter_comments.svg";

export const CommentDrawerWrapper = styled.div`
  width: ${RIGHT_SIDEBAR_WIDTH}px;
  background-color: ${(props) => props.theme.colors.alabaster3};
  position: absolute;
  right: 0px;
  top: ${TOP_HEADER_HEIGHT}px;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  display: block;
  z-index: ${RIGHT_DRAWER + 1};
`;
export const CommentSearchFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;
export const CommentSearchWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  flex: 1;
  margin-right: 10px;
  height: 30px;
  align-items: center;
  border: ${(props) => (props.active ? "1px solid #2AAAB6" : "1px solid transparent")};
  background: rgba(234, 232, 232, 0.48);
  border-radius: 3px;
  svg {
    height: 12px;
    width: 12px;
  }
  .magnifier {
    margin-left: 7px;
    path {
      fill: ${(props) => (props.active ? "var(--colors-eastern-blue)" : "#c5c5c5")};
    }
  }
  input {
    height: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 14px;
  }
  input:focus {
    border: none;
    outline: none;
  }
  .clear-button {
    cursor: pointer;
    :hover {
      path {
        fill: var(--colors-eastern-blue);
      }
    }
  }
`;
export const CommentsWrapper = styled.div`
  margin: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - 60px - 48px);
  overflow-x: hidden;
  margin-top: 60px;
  border-top: 1px solid #e9e9e9;
`;

export const Filter = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.colors.easternBlue};
  border-radius: 4px;
  background-image: url(${FilterIcon});
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  cursor: pointer;
`;

export const FilterPopup = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100px;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  z-index: ${RIGHT_DRAWER + 1};
  position: absolute;
  width: 248px;
  right: 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const FilterPopupItem = styled.div<{ selected: boolean; hasBorder?: boolean }>`
  cursor: pointer;
  ${DataButton}
  height: 36px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected ? props.theme.colors.easternBlue : props.theme.colors.black)};
  &:hover {
    background-color: ${(props) => props.theme.colors.whiteIce};
  }
  border-bottom: ${(props) => (props.hasBorder ? "1px solid var(--colors-gallery)" : "none")};
  .popup-image {
    margin-left: 7px;
    margin-right: 7px;
    min-height: 10px;
    min-width: 10px;
    svg {
      height: 16px;
      width: 12px;
    }
  }
`;

export const CloseButtonWrapper = styled.div`
  height: 30px;
  width: 13px;
  margin-right: 7px;
  display: flex;
  align-items: center;
`;
