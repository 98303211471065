import React, { FC, AnchorHTMLAttributes } from "react";
import { Button } from "./IconButton.styled";
import { SpaceProps, LayoutProps, BorderProps, PaddingProps, MarginProps } from "styled-system";
import { Spinner } from "../Loader/Spinner";

interface Props
  extends SpaceProps,
    LayoutProps,
    BorderProps,
    PaddingProps,
    MarginProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  Icon: Nullable<FC<React.SVGProps<SVGSVGElement>>>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  as?: React.ElementType;
  text?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const IconButton: FC<Props> = ({
  Icon,
  text,
  onClick,
  className,
  as = "button",
  isLoading = false,
  disabled,
  ...props
}) => (
  <Button as={as} onClick={onClick} className={className} {...props} disabled={disabled}>
    {isLoading ? (
      <Spinner color={`var(--colors-eastern-blue)`} width="10px" height="10px" m={0} />
    ) : Icon !== null ? (
      <Icon />
    ) : (
      <></>
    )}
    {text}
  </Button>
);
