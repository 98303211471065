import { FC } from "react";

import { Button } from "../../../components/Button";
import { ModalDialog } from "../../../components/ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";
import { EMPTY_FN, SOMETHING_WENT_WRONG_MESSAGE } from "../../../constants";
import { ModalErrorMessage } from "./RightDrawerMenu.styled";
import { Loader } from "../../../components/Loader";

interface RightDrawerModalProps {
  message: string;
  hidden: boolean;
  loading: boolean;
  error: boolean;
  onCancel: () => void;
  onChange: (args: unknown) => void;
}

export const RightDrawerModal: FC<RightDrawerModalProps> = ({
  children,
  hidden,
  message,
  loading,
  error,
  onCancel = EMPTY_FN,
  onChange = EMPTY_FN,
}) => (
  <ModalDialog onClose={EMPTY_FN} hidden={hidden} message={message} warning={true}>
    {children}
    <ButtonsWrapper>
      <ButtonWrapper>
        <Button
          variant="cancel"
          size="small"
          onClick={onCancel}
          fontSize="14px"
          paddingLeft="16px"
          paddingRight="16px"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={onChange}
          fontSize="14px"
          paddingLeft="16px"
          paddingRight="16px"
          height="34px"
          disabled={loading}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth="78px"
        >
          {loading ? <Loader /> : "Change"}
        </Button>
      </ButtonWrapper>
    </ButtonsWrapper>
    {error && <ModalErrorMessage>{SOMETHING_WENT_WRONG_MESSAGE}</ModalErrorMessage>}
  </ModalDialog>
);
