import { useQuery } from "react-query";
import { useEffect, useState } from "react";

import ApiClient from "../utils/apiClient";
import { getThreatsInfoUrl } from "../api";
import { logError } from "../services";
import { useDrawerStore, useThreatsStore } from "../stores";
import { SingleThreatResponse } from "../types/responses";
import { ContentType } from "../stores/DrawerStore/DrawerStore.types";

export const useSingleThreat = () => {
  const currentSelectedThreat = useThreatsStore((store) => store.selectedThreat);
  const getLastElement = useDrawerStore((store) => store.getLastElement);
  const stack = useDrawerStore((store) => store.stack);
  const { addThreat } = useThreatsStore((store) => store.actions);

  const [selectedThreat, setThreat] = useState<Nullable<string>>(null);

  const { refetch } = useQuery(
    "single-threat",
    () => ApiClient.get<SingleThreatResponse>(getThreatsInfoUrl(selectedThreat ?? "")),
    {
      onError: (e) => logError(e),
      onSuccess: (data) => addThreat(selectedThreat ?? "", data.data),
      enabled: selectedThreat !== null,
    }
  );

  useEffect(() => {
    if (!selectedThreat) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThreat]);

  //get data for the selected threat
  useEffect(() => {
    if (!currentSelectedThreat) {
      return;
    }
    setThreat(currentSelectedThreat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedThreat]);

  //gor get data for the selected custom work container
  useEffect(() => {
    const lastElement = getLastElement();
    if (!lastElement || lastElement.type !== ContentType.CUSTOM_POLYGON) {
      return;
    }
    setThreat(lastElement.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack]);
};

export default useSingleThreat;
