import { FC, memo, useCallback, useMemo, useState } from "react";
import { GeoJSON, Marker } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import { v4 as uuidv4 } from "uuid";

import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { useCustomQuery, useMapOptimizedHiddenFlag } from "../../hooks";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { EMPTY_FN } from "../../constants";
import { ParcelsResponse, ParcelsResponseProperties } from "../../types/responses/ParcelsResponse";
import { getParcelIcon, getParcelsStyle, getSmallParcelIcon } from "./MapParcels.utils";
import MapHelper from "../../utils/mapHelper";
import "./MapParcels.styles.css";
import { MapMarkerCluster } from "../MapMarkerCluster";
import {
  MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER,
  MIN_ZOOM_LEVEL_FOR_PARCEL_MARKERS,
} from "../../pages/MapScreen/MapView/MapView.constants";
import { ASSET_TYPES, useMapSelectionStore } from "../../stores";

interface MapParcelsProps {
  detailedMode?: boolean;
  geoJSON?: Nullable<ParcelsResponse>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  markersZIndex?: number;
  onClick: (e: L.LeafletMouseEvent, id: string) => void;
  url?: string;
  zIndex?: number;
  parcelLayerOn: boolean;
  multipleSelectedIds: Nullable<string[]>;
}

const PARCELS_PANE_NAME = "parcels-pane";

const PARCELS_MARKERS_PANE_NAME = "parcels-markers-pane";

export const MapParcels: FC<MapParcelsProps> = memo(
  ({
    detailedMode = false,
    geoJSON,
    hidden = false,
    map,
    markersZIndex = 401,
    onClick = EMPTY_FN,
    url = "",
    zIndex = 401,
    parcelLayerOn,
    multipleSelectedIds,
  }) => {
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    const [hoveredId, setHoveredId] = useState<Nullable<string>>(null);
    const isSelectionToolHidden = useMapSelectionStore((store) => store.hidden);
    const areMultiParcelsSelected = useMapSelectionStore((store) => store.selectedAssetType) === ASSET_TYPES.PARCEL;

    useCustomMapPane({
      map,
      name: PARCELS_PANE_NAME,
      zIndex,
    });

    useCustomMapPane({
      map,
      name: PARCELS_MARKERS_PANE_NAME,
      zIndex: markersZIndex,
    });

    const onEachFeature = useCallback(
      (feature, layer) => {
        layer.on({
          click: (e: L.LeafletMouseEvent) => onClick(e, e?.target?.feature?.properties?.id),
        });
      },
      [onClick]
    );

    const shouldFetchData = !geoJSON && !!url;

    const { data } = useCustomQuery<ParcelsResponse>(url, {
      enabled: shouldFetchData,
    });

    const parcels = shouldFetchData ? data : geoJSON;

    const renderedParcels = useMemo(() => {
      if (isGeoJSONEmpty(parcels)) {
        return <></>;
      }

      return (
        <GeoJSON
          data={parcels!}
          key={uuidv4()} // force re-render on every dependency change
          interactive={true}
          onEachFeature={onEachFeature}
          pane={PARCELS_PANE_NAME}
          style={(item) =>
            getParcelsStyle({
              item,
              hoveredId,
              parcelStatusEnabled: parcelLayerOn,
              multipleSelectedIds,
              isSelectionToolHidden,
              areMultiParcelsSelected,
            })
          }
        />
      );
    }, [
      parcels,
      onEachFeature,
      hoveredId,
      parcelLayerOn,
      multipleSelectedIds,
      isSelectionToolHidden,
      areMultiParcelsSelected,
    ]);

    const renderedParcelsMarkers = useMemo(() => {
      if (isGeoJSONEmpty(parcels)) {
        return <></>;
      }

      return parcels!.features.map((item: GeoJSON.Feature<GeoJSON.Geometry, ParcelsResponseProperties>) => {
        const center = MapHelper.centerOfMass(false, item);

        return (
          <Marker
            icon={detailedMode ? getParcelIcon(item.properties.status) : getSmallParcelIcon(item.properties.status)}
            interactive={true}
            key={item.properties.id}
            pane={PARCELS_MARKERS_PANE_NAME}
            position={[...center.geometry.coordinates].reverse() as LatLngExpression}
            eventHandlers={{
              click: (e) => onClick(e, item?.properties?.id),
              mouseover: () => setHoveredId(item?.properties?.id),
              mouseout: () => setHoveredId(null),
            }}
          />
        );
      });
    }, [parcels, onClick, detailedMode]);

    if (optimizedHidden) {
      return <></>;
    }

    return (
      <>
        <MapMarkerCluster disableClusteringAtZoom={MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER}>
          {renderedParcels}
        </MapMarkerCluster>
        <MapMarkerCluster disableClusteringAtZoom={MIN_ZOOM_LEVEL_FOR_PARCEL_MARKERS}>
          {renderedParcelsMarkers}
        </MapMarkerCluster>
      </>
    );
  }
);
