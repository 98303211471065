import React, { FC } from "react";
import { LayerRow } from "../../../types/Layers";
import { Checkbox } from "../../Checkbox";
import { SpecialLayerWrapper } from "./SpecialLayers.styled";

export type SpecialLayersProps = {
  specialLayers?: Nullable<LayerRow[]>;
  setSpecialLayer: (id: string, value: boolean) => void;
  specialLayersState: Nullable<Record<string, boolean>>;
};

export const SpecialLayers: FC<SpecialLayersProps> = ({ specialLayers, setSpecialLayer, specialLayersState }) => {
  if (!specialLayers?.length) {
    return <></>;
  }
  return (
    <SpecialLayerWrapper>
      {specialLayers.map((specialLayer) => {
        return (
          <Checkbox
            key={specialLayer.key}
            defaultChecked={specialLayersState?.[specialLayer.key] ?? false}
            id={specialLayer.key}
            name={specialLayer.name}
            onClick={(e: React.FormEvent<HTMLElement | HTMLInputElement>) => {
              setSpecialLayer(specialLayer.key, !specialLayersState?.[specialLayer.key]);
            }}
            value={specialLayer.name}
            label={specialLayer.name}
            image={specialLayer.image}
            additionalText={specialLayer.additionalInfo}
            {...{ checked: specialLayersState?.[specialLayer.key] }}
          />
        );
      })}
    </SpecialLayerWrapper>
  );
};
