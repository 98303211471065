import {
  METER_TO_FEET_CONST,
  FEET_TO_METER_CONST,
  SQUARE_METERS_TO_ACRES,
  MILES,
  FEET_UNIT_SHORT_NAME,
  ACRES_UNIT_NAME_SHORT,
  FEET_TO_MILES_CONST,
} from "../constants";

const LIMIT_DISTANCE_IN_FEET = 1320;

export const unitConverter = (meterToFeet: boolean, value: number, fractionDigits: number = 2) => {
  if (meterToFeet) {
    return roundValue(METER_TO_FEET_CONST * value, fractionDigits);
  } else {
    return roundValue(FEET_TO_METER_CONST * value, fractionDigits);
  }
};

// TODO: When zero is last fraction digit parseFloat does not returns it. Check if this should be handled.
export const roundValue = (value: number, fractionDigits = 0): number => {
  if (!value) {
    return 0;
  }

  return parseFloat(value.toFixed(fractionDigits));
};

export const squareMetersToAcres = (squareMeters: number, fractionDigits: number = 2) =>
  roundValue(squareMeters * SQUARE_METERS_TO_ACRES, fractionDigits);

export const getUnitPluralName = (unit: string, quantity: number) => {
  if (quantity <= 1) {
    return unit;
  }
  return `${unit}s`;
};

export const feetToMiles = (feetValue: number) => {
  return feetValue * FEET_TO_MILES_CONST;
};

export const getReadableDistance = (distanceInFeet: number) => {
  if (distanceInFeet > LIMIT_DISTANCE_IN_FEET) {
    return roundValue(feetToMiles(distanceInFeet), 2);
  }
  return roundValue(distanceInFeet, 2);
};

export const getDistanceUnit = (distanceInFeet: number) => {
  if (distanceInFeet > LIMIT_DISTANCE_IN_FEET) {
    return MILES;
  }
  return FEET_UNIT_SHORT_NAME;
};

export const getFormattedDistance = (distanceInFeet: number) => {
  return `Distance: ${getReadableDistance(distanceInFeet)} ${getDistanceUnit(distanceInFeet)}`;
};

export const getFormattedArea = (area: Nullable<number>) => {
  if (!area) {
    return null;
  }
  return `${roundValue(area, 2)} ${ACRES_UNIT_NAME_SHORT}`;
};
