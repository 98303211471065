import styled from "styled-components";
import {
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  FontFamilyProps,
  FontSizeProps,
  FontWeightProps,
  FontStyleProps,
  color,
  TextColorProps,
  BorderProps,
  border,
  PaddingProps,
  padding,
} from "styled-system";

export const StyledText = styled.span<
  FontFamilyProps & FontSizeProps & FontWeightProps & FontStyleProps & TextColorProps & BorderProps & PaddingProps
>`
  ${fontFamily}
  ${fontSize}
  ${fontStyle}
  ${fontWeight}
  ${color}
  ${border}
  ${padding}
`;
