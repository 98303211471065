import create from "zustand";

import { EMPTY_FN } from "../constants";
import { SearchCommentsResponseDto } from "../types/responses";

export type FilterComments = {
  text: Nullable<string>;
  sortBy: "UNREAD" | "DATE";
  showResolved: boolean;
  showRead: boolean;
};

const INITIAL_FILTER: FilterComments = { text: null, sortBy: "DATE", showResolved: false, showRead: true };

export type CommentsStore = {
  toggleLayer: () => void;
  isLayerVisible: boolean;
  data: Nullable<SearchCommentsResponseDto>;
  setData: (data: SearchCommentsResponseDto) => void;
  filter: FilterComments;
  showResolved: (state: boolean) => void;
  showRead: (state: boolean) => void;
  setSortBy: (sortBy: "UNREAD" | "DATE") => void;
  setTextFilter: (text: string) => void;
  selectedMarkerId: Nullable<string>;
  setMarkerId: (id: Nullable<string>) => void;
};

const INITIAL_STATE = {
  toggleLayer: EMPTY_FN,
  isLayerVisible: false,
  data: null,
  filter: INITIAL_FILTER,
  selectedMarkerId: null,
};

export const useCommentsStore = create<CommentsStore>((set, get) => ({
  ...INITIAL_STATE,
  toggleLayer: () => {
    const isLayerVisible = !get().isLayerVisible;
    if (!isLayerVisible) {
      set({ data: null, filter: INITIAL_FILTER, selectedMarkerId: null });
    }
    set({ isLayerVisible: isLayerVisible });
  },
  setData: (data) => {
    set({ data });
  },
  showResolved: (state) => {
    const filter = { ...get().filter };
    filter.showResolved = state;
    set({ filter });
  },
  showRead: (state) => {
    const filter = { ...get().filter };
    filter.showRead = state;
    set({ filter });
  },
  setSortBy: (sortBy) => {
    const filter = { ...get().filter };
    filter.sortBy = sortBy;
    set({ filter });
  },
  setTextFilter: (text) => {
    const filter = { ...get().filter };
    filter.text = text;
    set({ filter });
  },
  setMarkerId: (id) => {
    set({ selectedMarkerId: id });
  },
}));

export default useCommentsStore;
