import React, { FC } from "react";
import { ColorProps } from "styled-system";
import { PriorityTooltipContent } from "../../PriorityTooltipContent";
import { TriangleTooltip } from "../../Tooltip/TriangleTooltip";

import { ChipsStyled, SpanPriorityCircle } from "./Chips.styled";
interface ChipsProps extends ColorProps {
  value: number;
  selected: boolean;
  onClick?: (e: unknown) => void;
  color?: string;
  label: string;
  additionalLabel: string;
}
export const Chips: FC<ChipsProps> = ({ value, selected, onClick = () => {}, bg, label, additionalLabel }) => (
  <TriangleTooltip
    under={false}
    label={<PriorityTooltipContent label={label} additionalLabel={additionalLabel}></PriorityTooltipContent>}
  >
    <ChipsStyled selected={selected} hasItems={value > 0} onClick={onClick}>
      <SpanPriorityCircle backgroundColor={bg} />
      <span>{value}</span>
    </ChipsStyled>
  </TriangleTooltip>
);

export default Chips;
