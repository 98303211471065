import styled from "styled-components";
import { color, ColorProps, flexbox, FlexboxProps, layout, LayoutProps } from "styled-system";

import { Data1 } from "../../assets/styles/fonts.styled";

import {
  LEFT_SIDEBAR_WIDTH,
  RIGHT_SIDEBAR_WIDTH,
  LAYERS_MENU_WIDTH,
  TOP_HEADER_HEIGHT,
} from "../../constants/dimensions";
import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";
import { ReactComponent as Close } from "./../../assets/images/close_custom.svg";

const UPLOADER_HEIGHT = 39;

const OFFSET = 10;

interface UploadingContentProps {
  rhsEnabled?: boolean;
  lhsEnabled?: boolean;
  layerMenuEnabled?: boolean;
}

export const UploadingBarContent = styled.div<UploadingContentProps>`
  position: fixed;
  top: ${TOP_HEADER_HEIGHT + UPLOADER_HEIGHT + OFFSET}px;
  z-index: 1000;
  right: 0;
  height: 1px;
  ${(props) => {
    const width =
      (props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0) +
      (props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : 0) +
      (props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0);
    const marginLeft = props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0;
    const marginRight = props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0;
    return `width: calc(100% - ${width}px);x margin-left: ${marginLeft}px; margin-right: ${marginRight}px;`;
  }}
  z-index: ${CUT_PLAN_Z_INDEX};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${Data1}
  color:  ${(props) => props.theme.colors.white};
`;

export const MessagePopup = styled.div<FlexboxProps>`
  width: 204px;
  height: ${UPLOADER_HEIGHT}px;
  left: 594px;
  display: flex;
  align-items: center;
  background: rgba(17, 24, 41, 0.85);
  border-radius: 62px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  div:nth-child(2) {
    margin: 0 10px;
  }
  ${flexbox}
`;

export const PopupMessage = styled.div``;

export const StatusWrapper = styled.div<ColorProps & LayoutProps>`
  background-color: #17d284;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${color}
  ${layout}
  svg path {
    fill: ${(props) => props.theme.colors.white};
  }
`;
export const CloseIcon = styled(Close)<{ hasCursor?: boolean }>`
  height: 12px;
  width: 12px;
  flex: 1;
  cursor: ${(props) => (props.hasCursor ? "pointer" : "default")};
  fill: ${(props) => props.theme.colors.white};
  &:hover {
    fill: ${(props) => props.theme.colors.easternBlue};
  }
  &:active {
    fill: ${(props) => props.theme.colors.white};
  }
`;
