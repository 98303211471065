import styled from "styled-components";

export const WorksheetAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .worksheet__form {
    padding-top: 3px;
    padding-bottom: 16px;
  }
`;

export const WorkTypeTotal = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
`;
export const WorkTypeTotalLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--colors-manatee);
`;
export const WorkTypeTotalValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: var(--colors-mineral-green);
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  button {
    margin: 0;
  }
  width: 100%;
  .vantage-react-select-container {
    width: 100%;
  }
  .react-select__control {
    border: 1px solid #dddddd;
  }
  .react-select__placeholder {
    font-weight: 500;
    font-size: 14px;
    color: #898ea1;
    transform: translateY(-60%);
  }
  .react-select__option {
    border-bottom: 1px solid var(--colors-gallery);
  }
  .react-select__option::first-of-type {
    border-top: 1px solid var(--colors-gallery);
  }
`;

export const StyledSelectImage = styled.img`
  width: 22px;
  height: 22px;
  position: relative;
  top: 3px;
`;

export const AutoAssignedContent = styled.div`
  color: var(--colors-white);
  display: flex;
  align-items: center;
  svg {
    margin-left: 3px;
  }
`;
