import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { getDeleteTreeMarkerUrl } from "../api";
import { QUERY_KEY } from "../pages/MapScreen/MapView/TreeMarkerView.utils";

export const useDeleteTreeMarker = () => {
  const queryClient = useQueryClient();
  return useMutation((markerID: string) => ApiClient.delete(getDeleteTreeMarkerUrl(markerID)), {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEY),
  });
};
