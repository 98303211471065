import { useMutation } from "react-query";

import ApiClient from "../utils/apiClient";
import { updateNumberOfPeopleUrl } from "../api";
import { ValueType } from "../types/responses/Crews";

export const UPDATE_CREW_CONFIG_MUTATION_KEY = "update-NOF-config";

export const useUpdateNumberOfPeople = () => {
  return useMutation(
    UPDATE_CREW_CONFIG_MUTATION_KEY,
    (item: { crewId: string; contractorRoleId: string; value: ValueType }) =>
      ApiClient.put(updateNumberOfPeopleUrl(item.crewId, item.contractorRoleId), item.value)
  );
};
