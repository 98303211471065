import { PropsWithChildren, ReactElement } from "react";
import { Column, Row, useTable } from "react-table";
import { AnySchema } from "yup";
import Lazy from "yup/lib/Lazy";
import Reference from "yup/lib/Reference";

import { LoadingState } from "../../stores";
import { EditableCell } from "./EditableCell/EditableCell";
import {
  TableStyleWrapper,
  TableStyle,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableDataCell,
  TableFooterStyled,
  TableRowHead,
} from "./Table.styled";

interface TableProperties<T extends object> {
  columns: Column<T>[];
  data?: T[] | null;
  updateField: (row: Row<T>, column: Column, value: string) => Promise<LoadingState>;
  multiItem?: boolean;
  removeField?: (row: Row<T>) => void;
  validationParams: ValidationParams;
  displayColumnFooter?: boolean;
  stripes?: boolean;
}

export type ValidationParams = Record<string, AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>;

const defaultColumn = {
  Cell: EditableCell,
  canRemove: false,
  editable: false,
};

export function Table<T extends object>({
  columns,
  data,
  updateField,
  multiItem,
  removeField,
  validationParams,
  children,
  displayColumnFooter = true,
  stripes = false,
}: PropsWithChildren<TableProperties<T>>): ReactElement {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
    columns,
    data: data || [],
    defaultColumn,
    //@ts-ignore
    updateField,
    removeField,
    validationParams,
  });

  return (
    <TableStyleWrapper multiItem={multiItem} stripes={stripes}>
      <TableStyle {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRowHead {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  {...column.getHeaderProps({
                    // @ts-ignore
                    className: column.className,
                  })}
                >
                  {column.render("Header")}
                </TableHeaderCell>
              ))}
            </TableRowHead>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableDataCell
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                        // @ts-ignore
                        className: cell.column.className,
                      })}
                    >
                      {cell.render("Cell")}
                    </TableDataCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooterStyled>
          {displayColumnFooter &&
            footerGroups.map((footerGroup) => (
              <TableRow noBorder {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => (
                  <TableHeaderCell {...column.getFooterProps()}>{column.render("Footer")}</TableHeaderCell>
                ))}
              </TableRow>
            ))}
          <TableRow noBorder>
            <TableHeaderCell colSpan={columns ? columns.length : 0}>{children}</TableHeaderCell>
          </TableRow>
        </TableFooterStyled>
      </TableStyle>
    </TableStyleWrapper>
  );
}

export default Table;
