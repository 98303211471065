import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Wrappper = styled.div<any & SpaceProps>`
  display: flex;
  flex-direction: column;
  ${space}

  label {
    margin-bottom: 7px;
  }

  .react-datepicker__day {
    width: 1.9rem;
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
    border-radius: 5px;
  }

  .react-datepicker__header {
    border: none;
    background-color: var(--colors-white);
  }

  .react-datepicker__day--selected {
    background-color: var(--colors-eastern-blue);
    outline: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 6px;
  }

  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
    border-top-color: var(--colors-black);
  }

  .react-datepicker__year-read-view--down-arrow {
    border-color: var(--colors-black);
    width: 7px;
    height: 7px;
  }

  .react-datepicker__day-names {
    margin-top: 15px;
    background-color: rgba(12, 178, 192, 0.1);
  }

  .react-datepicker__day--keyboard-selected {
    background-color: var(--colors-eastern-blue);
    border-color: var(--colors-eastern-blue);
  }

  .react-datepicker__input-container {
    font-weight: 600;
    font-size: 14px;
    color: var(--colors-outer-space);

    div {
      border-color: var(--colors-alto);
    }
  }

  .react-datepicker__close-icon:after {
    background-color: var(--colors-alto);
  }

  .react-datepicker__navigation--years {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    height: 3px;
    width: 3px;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border: solid var(--colors-black);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 0;
  }

  .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: var(--colors-black);
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    top: 4px;
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.hasError .react-datepicker__input-container div {
    border-color: #d01e1e;
  }

  span.errorMsg {
    color: var(--colors-priority-violation);
    display: inline-block;
    width: 100%;
    text-align: end;
    font-weight: 500;
    font-size: 15px;
  }

  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--colors-eastern-blue);
  }

  .react-datepicker__close-icon {
    padding-right: 10px;
  }
`;

export const DateInputWrapper = styled.div`
  background-color: #fbfbfb;
  border: 1px solid var(--colors-alto);
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  outline: none;

  input {
    border: none;
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: var(--parcel-note-passive);
`;

export const DatepickerPlaceholder = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: var(--colors-nevada);
`;
