import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { getAccessPointsMarkerUrl } from "../api";

export const DELETE_AP_MUTATION_KEY = "delete-access-points";

export const useDeleteAccessPoint = () => {
  const queryClient = useQueryClient();
  return useMutation(
    DELETE_AP_MUTATION_KEY,
    (accessPointId: string) => ApiClient.delete(getAccessPointsMarkerUrl(accessPointId)),
    {
      onSettled: () => queryClient.invalidateQueries("access-points"),
    }
  );
};
