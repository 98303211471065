import { Point } from "@turf/turf";

export enum TreeMarkerCategoryType {
  Prune = "prune",
  Danger = "danger",
  Remove = "remove",
  Damage = "damage",
  Transplant = "transplant",
  Dead = "dead-dying",
  Disease = "disease-insect",
}

export type TreeMarkerCategory = {
  id: string;
  code: TreeMarkerCategoryType;
  name: string;
  subCategories: Nullable<TreeMarkerCategory[]>;
  image?: string;
  sortNumber: number;
};

export type TreeMarkerCategories = {
  categories: TreeMarkerCategory[];
};

export type TreeMarkerUpdate = {
  categoryId?: string;
  radiusMeters?: number;
  geometry?: Point;
};

export type TreeMarker = {
  id: string;
  username: string;
  categoryId: string;
  categoryCode: string;
  postedAt: string;
  geometry: Point;
  radius?: Nullable<number>;
};

export type TreeMarkers = {
  markers: TreeMarker[];
};
