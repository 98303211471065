import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Column, Row, TableCommonProps } from "react-table";
import { useMutation } from "react-query";
import * as Yup from "yup";

import { SubPageWrapper, HeaderText, SpanInfo } from "./SubPages.styled";
import { useCustomQuery } from "./../../../hooks";
import { getFeederVoltageUrl, getFeederVoltageEntryUrl } from "../../../api";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { Toast } from "../../../components/Toast";
import {
  SOMETHING_WENT_WRONG_MESSAGE,
  getDeleteMessage,
  NUMBER_ERROR,
  VOLTAGE_UNIT_SHORT_NAME,
} from "../../../constants";
import ApiClient from "../../../utils/apiClient";
import { VoltageFeederConfiguration } from "../../../types/responses";
import { CustomColumn, Table } from "../../../components/Table";
import { ModalDialog } from "../../../components/ModalDialog";
import { Button } from "../../../components/Button";
import { logError } from "../../../services";
import useAdminLoadingStore, { LoadingState } from "../../../stores/AdminLoadingStore";
import { useMessageStore } from "../../../stores";
import { SingleItemFooter } from "../../../components/Table/SingleItemFooter/SingleItemFooter";
import { checkError } from "./SubPages.util";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";

type VoltageConfigurationColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

const validationParams = {
  value: Yup.number().typeError(NUMBER_ERROR).required().integer().positive().min(10).max(800),
};

export const VoltageConfiguration: FC = () => {
  const [selectedIdForRemoval, setSelectedId] = useState<Nullable<string>>(null);
  const setLoadingState = useAdminLoadingStore((store) => store.setLoadingState);
  const setErrorMessage = useMessageStore((store) => store.setErrorMessage);
  const resetLoadingStore = useAdminLoadingStore((store) => store.resetStore);

  useEffect(() => {
    resetLoadingStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isLoading: loading,
    isError: hasError,
    data: voltageConfigurationResponse,
    refetch: refetchVoltages,
  } = useCustomQuery<VoltageFeederConfiguration[]>(getFeederVoltageUrl());

  const onRequestMutate = useCallback(() => {
    setLoadingState(LoadingState.Loading);
    setErrorMessage(null);
  }, [setLoadingState, setErrorMessage]);

  const onRequestError = useCallback(
    (error) => {
      setLoadingState(LoadingState.Error);
      let message = SOMETHING_WENT_WRONG_MESSAGE;
      if (checkError(error)) {
        message = error.response?.data?.message;
      }
      setErrorMessage(message);
    },
    [setLoadingState, setErrorMessage]
  );

  const onRequestSuccess = useCallback(() => {
    setLoadingState(LoadingState.Success);
  }, [setLoadingState]);

  const saveNewVoltage = useMutation(
    (newValue: VoltageFeederConfiguration) => ApiClient.post(getFeederVoltageUrl(), newValue),
    { onMutate: onRequestMutate, onSuccess: onRequestSuccess, onError: onRequestError }
  );

  const deleteVoltage = useMutation((id: string) => ApiClient.delete(getFeederVoltageEntryUrl(id)), {
    onMutate: onRequestMutate,
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });

  const updateVoltage = useMutation(
    (newValue: VoltageFeederConfiguration) =>
      ApiClient.put(getFeederVoltageEntryUrl(newValue.id), { value: newValue.value }),
    { onMutate: onRequestMutate, onSuccess: onRequestSuccess, onError: onRequestError }
  );

  const addField = useCallback(
    (singleItem: Nullable<string>): Promise<LoadingState> => {
      if (singleItem && singleItem.trim().length > 0) {
        return saveNewVoltage
          .mutateAsync({ value: Number(singleItem) })
          .then(() => {
            refetchVoltages();
            return Promise.resolve(LoadingState.Success);
          })
          .catch((error) => {
            logError(error);
            return Promise.resolve(LoadingState.Error);
          });
      }
      return Promise.resolve(LoadingState.Idle);
    },
    [saveNewVoltage, refetchVoltages]
  );

  const columns: VoltageConfigurationColumns<VoltageFeederConfiguration>[] = useMemo(() => {
    return [
      {
        Header: () => {
          return (
            <div>
              {"Voltage"} <SpanInfo>{`(${VOLTAGE_UNIT_SHORT_NAME})`}</SpanInfo>
            </div>
          );
        },
        accessor: "value",
        editable: true,
        canRemove: true,
        type: "number",
        Footer: () => (
          <SingleItemFooter
            type={"number"}
            addItem={addField}
            validationParams={validationParams}
            rows={voltageConfigurationResponse ? voltageConfigurationResponse.length : 0}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voltageConfigurationResponse]);

  const hideModalDialog = useCallback(() => {
    setSelectedId(null);
  }, []);

  const deleteItem = useCallback(() => {
    hideModalDialog();
    if (selectedIdForRemoval) {
      deleteVoltage
        .mutateAsync(selectedIdForRemoval)
        .then(() => {
          refetchVoltages();
        })
        .catch((error) => {
          logError(error);
        });
    }
  }, [selectedIdForRemoval, deleteVoltage, refetchVoltages, hideModalDialog]);

  const updateField = useCallback(
    (row: Row<VoltageFeederConfiguration>, column: Column, value: string): Promise<LoadingState> => {
      if (row.original.value === Number(value)) {
        return Promise.resolve(LoadingState.Idle);
      }
      return updateVoltage
        .mutateAsync({ value: Number(value), id: row.original.id })
        .then(() => {
          refetchVoltages();
          return Promise.resolve(LoadingState.Success);
        })
        .catch((error) => {
          logError(error);
          return Promise.resolve(LoadingState.Error);
        });
    },
    [refetchVoltages, updateVoltage]
  );

  const removeField = useCallback((row: Row<VoltageFeederConfiguration>) => {
    if (row.original.id) {
      setSelectedId(row.original.id);
    }
  }, []);

  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!loading} />
      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>

      <ModalDialog
        hidden={!selectedIdForRemoval}
        warning
        title="Delete voltage"
        message={getDeleteMessage("voltage")}
        onClose={hideModalDialog}
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <Button variant="cancel" size="small" onClick={hideModalDialog}>
              Cancel
            </Button>

            <Button variant="warning" size="small" onClick={deleteItem}>
              Delete
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </ModalDialog>
      <HeaderText>Voltage configuration</HeaderText>
      {!loading && !hasError && (
        <Table<VoltageFeederConfiguration>
          columns={columns}
          data={voltageConfigurationResponse}
          updateField={updateField}
          removeField={removeField}
          validationParams={validationParams}
        />
      )}
    </SubPageWrapper>
  );
};
