import { FC, useCallback, useEffect, useRef, useState } from "react";

import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import {
  JobCompatibilityText,
  JobWrapper,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "./JobCompatibility.styled";

import { useClickOutside } from "../../hooks";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";

interface JobCompatibilityProps {
  skills: { id: string; name: string }[];
  updateSkills: (skillList: string[]) => void;
  selectedSkills?: Maybe<{ id: string; name: string }[]>;
}
export const JobCompatibility: FC<JobCompatibilityProps> = ({ skills, selectedSkills, updateSkills }) => {
  const [modalHidden, setModalHidden] = useState(true);
  const [chosenSkills, setChosenSkills] = useState(selectedSkills);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => !modalHidden && setModalHidden(true));

  useEffect(() => {
    setChosenSkills(selectedSkills);
  }, [selectedSkills]);

  const toggleSkill = useCallback(
    (item: { id: string; name: string }) => {
      const existingItem = chosenSkills?.find((skill) => skill.id === item.id);

      if (existingItem) {
        setChosenSkills((preValues) => [...(chosenSkills?.filter((skill) => skill.id !== item.id) ?? [])]);
        return;
      }
      setChosenSkills((prevValues) => [...(prevValues ?? []), item]);
    },
    [chosenSkills]
  );

  const cancel = () => {
    setChosenSkills([...(selectedSkills ?? [])]);
  };

  const removeFormList = (id: string) => {
    updateSkills(chosenSkills?.filter((skill) => skill.id !== id)?.map((item) => item.id) ?? []);
    setChosenSkills(() => [...(chosenSkills?.filter((skill) => skill.id !== id) ?? [])]);
  };

  return (
    <StyledFlex flexDirection="column" marginBottom="20px">
      <JobCompatibilityText
        onClick={() => {
          setModalHidden(!modalHidden);
        }}
      >
        Job Compatibility
      </JobCompatibilityText>
      {!modalHidden && (
        <ModalWrapper ref={wrapperRef}>
          <ModalHeader>Job Compatibility</ModalHeader>
          <ModalBody>
            <StyledFlex flexDirection={"column"} gap="15px" className="check-wrapper">
              {skills &&
                skills.map((skill) => {
                  return (
                    <Checkbox
                      key={skill.id}
                      label={skill.name}
                      name={skill.name}
                      value={skill.name}
                      defaultChecked={
                        chosenSkills?.find((selectedSkill) => selectedSkill.id === skill.id) !== undefined
                      }
                      id={skill.id}
                      checked={chosenSkills?.find((selectedSkill) => selectedSkill.id === skill.id) !== undefined}
                      onClick={() => toggleSkill(skill)}
                    />
                  );
                })}
            </StyledFlex>
          </ModalBody>
          <ModalFooter>
            <StyledFlex justifyContent="space-between" padding="15px" gap="20px">
              <Button
                variant="secondary"
                fontWeight="600"
                fontSize="14px"
                padding="8px 29px"
                type="button"
                onClick={() => {
                  cancel();
                  setModalHidden(true);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                fontWeight="600"
                fontSize="14px"
                padding="8px 19.5px"
                type="button"
                onClick={() => {
                  updateSkills(chosenSkills?.map((item) => item.id) ?? []);
                  setModalHidden(true);
                }}
              >
                Save
              </Button>
            </StyledFlex>
          </ModalFooter>
        </ModalWrapper>
      )}
      {selectedSkills && (
        <StyledFlex gap="10px" flexWrap="wrap">
          {selectedSkills?.map((skill) => {
            return (
              <JobWrapper key={skill.id}>
                <div>{skill.name}</div>
                <Close className="close" onClick={() => removeFormList(skill.id)} />
              </JobWrapper>
            );
          })}
        </StyledFlex>
      )}
    </StyledFlex>
  );
};
