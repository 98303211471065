import styled, { css } from "styled-components";

export const TableStyleWrapper = styled.div<{ multiItem?: boolean; stripes: boolean }>`
  padding-right: 1em;
  overflow-x: auto;
  padding-bottom: 150px;
  margin-right: 35px;
  table {
    border-spacing: 0;
    thead {
      th {
        border-right: none;
        padding-left: 18px;
      }
      tr {
        height: 45px;
        background-color: var(--colors-white);
      }
    }
    tbody {
      tr {
        height: 39px;
      }

      ${(props) =>
        props.stripes &&
        css`
          tr:nth-child(odd) {
            background-color: #f9f9f9;
          }
          tr:nth-child(even) {
            background-color: var(--colors-white);
          }
        `}
    }
    tfoot {
      th {
        border: none;
      }
      td {
        border: none;
      }
    }
    th,
    td {
      ${(props) => (props.multiItem ? `width: 320px;` : `width: 631px;`)};
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.athensGray2};
      border-right: 1px solid ${(props) => props.theme.colors.athensGray2};

      :last-child {
        border-right: 0;
      }
      :first-child {
        ${(props) => (props.multiItem ? " width: 657px;" : "")};
      }
    }
  }
`;

export const TableStyle = styled.table`
  background: ${(props) => props.theme.colors.white};
  border-radius: 5px 5px 5px 5px;
`;

export const TableHeader = styled.thead`
  height: 64px;
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: ${(props) => props.theme.colors.trout2};
  th {
    border-right: none;
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{ noBorder?: boolean }>`
  display: table-row;
  width: 100%;
  ${(props) => (props.noBorder ? ` border: none;` : ``)};
  th {
    ${(props) => (props.noBorder ? ` border: none;` : ``)};
  }
`;

export const TableRowHead = styled(TableRow)``;

export const TableHeaderCell = styled.th`
  padding-left: 15px;
  border: none;
`;

export const TableDataCell = styled.td<{ footer?: boolean }>`
  ${(props) => (props.footer ? ` border-top:1px solid  ${props.theme.colors.athensGray2};` : ``)};
`;

export const TableFooterStyled = styled.tfoot`
  background: ${(props) => props.theme.colors.alabaster};
  width: 45px;
  height: 17px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.manatee};
  border-top: 1px solid ${(props) => props.theme.colors.athensGray2};
`;

export const TableFooterData = styled.td`
  width: 45px;
  height: 17px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.manatee};
  border-top: 1px solid ${(props) => props.theme.colors.athensGray2};
`;

export const TableDataCellFooter = styled(TableDataCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemsStyled = styled.div``;

export const AddNewItem = styled.div``;
