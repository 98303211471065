import React, { FC } from "react";

import { ToggleSideBarStyled, ArrowImg } from "./ToggleSideBar.styled";
import { useLeftControlStore } from "../../stores/LeftToggleStore";
import LeftArrow from "./../../assets/images/arrow_l.svg";
import RightArrow from "./../../assets/images/arrow_r.svg";

const ToggleSideBar: FC = () => {
  const setToggled = useLeftControlStore((store) => store.setToggled);
  const toggled = useLeftControlStore((store) => store.toggled);

  return (
    <ToggleSideBarStyled
      toggled={toggled}
      onClick={() => {
        setToggled(!toggled);
      }}
    >
      <ArrowImg src={toggled ? LeftArrow : RightArrow} alt="arrow" />
    </ToggleSideBarStyled>
  );
};

export default ToggleSideBar;
