import styled from "styled-components";

import { ReactComponent as MenuArrowSvg } from "../../assets/images/menu_arrow.svg";
import { SIDEBAR_MENU } from "../../constants/z-index";

export const PrioritySectionNameWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 284px;
  justify-content: flex-end;
  padding-right: 16px;
`;

export const CircleWrapper = styled.div`
  width: 21px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  padding-right: 9px;
  img {
    height: 15px;
    width: 15px;
  }
`;

export const PrioritySelectWrapper = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  cursor: pointer;
  text-transform: none;
  letter-spacing: normal;
`;

export const NameWrapper = styled.div<{ isActive: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--colors-trout);
  display: flex;
  gap: 0px;
  align-items: center;
  div:first-child {
    padding-right: 3px;
  }
  span:last-child {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--colors-mineral-green);
    padding-left: 3px;
  }
  ${CircleWrapper} {
    margin: 0px;
    padding: 0px;
    div {
      margin-left: 4px;
      margin-top: 0px;
      padding: 0px;
    }
  }
`;

export const ArrowSelect = styled(MenuArrowSvg)<{ isActive: boolean }>`
  margin-top: 4px;
  margin-left: 6px;
  fill: ${(props) => (props.isActive ? props.theme.colors.teal1 : props.theme.colors.manatee)};
  transform: ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const PriorityMenu = styled.div<{ isActive: boolean }>`
  position: absolute;
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};
  width: 100%;
  z-index: ${SIDEBAR_MENU + 1};
  min-width: 162px;
  right: 0px;
  background: var(--colors-white);
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 220px;
  top: 20px;
`;
export const PriorityMenuItem = styled.div<{ selected?: boolean }>`
  display: flex;
  height: 37px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-mineral-green);
  align-items: center;
  border-bottom: 1px solid var(--colors-gallery);
  background: ${(props) => (props.selected ? "#eff3f6" : "transparent")};
  box-sizing: border-box;
  span {
    padding-left: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: var(--colors-trout);
  }
`;
export const PriorityMenuName = styled.div``;
