import { FC, useCallback, useMemo } from "react";
import { ContentType } from "../../../../stores/DrawerStore/DrawerStore.types";

import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { SectionItem } from "../../../../components/RightDrawer/DrawerSectionItems/SectionItem";
import { DrawerSection } from "../../../../components/RightDrawer/DrawerSectionItems/DrawerSection";
import { SectionLinks } from "../../../../components/RightDrawer/DrawerSectionItems/SectionLinks";
import { SectionRiskPriority } from "../../../../components/RightDrawer/DrawerSectionItems/SectionRiskPriority";
import { SectionIntersectedLayer } from "../../../../components/RightDrawer/DrawerSectionItems/SectionIntersectedLayer";
import { AdditionalLayer } from "../../../../stores/MapStore";
import { IntersectedLayers, SectionData, SectionLinks as SectionLinksType } from "../RightDrawerMenu.utils";
import { RightOfWay } from "../../../../components/RightDrawer/DrawerSectionItems/RightOfWay";
import { LABELS, MISSING_VALUE } from "../../../../constants";
import { TreeSpecies } from "../../../../components/RightDrawer/DrawerSectionItems/TreeSpecies";
import { WorksheetAllArea } from "../../../../components/WorksheetArea/WorksheetAllArea";
import { deleteInfringementWorkTypeUrl, getWorksheetsForSpansUrl, getWorkTypesApiUrl } from "../../../../api";
import { useCustomQuery, useUpdateSpans } from "../../../../hooks";
import { SpanWorksheetsDto, WorksheetResponse, WorkTypeResponse } from "../../../../types/responses";
import ApiClient from "../../../../utils/apiClient";
import { logError } from "../../../../services";
import { calculateSpanTotalCost } from "../../../../components/SelectionToolRHS/SelectionToolRHS.utils";
import { Button } from "../../../../components/Button";
import { ButtonWrapper, CreateWorkOrderBtn } from "../../../../components/RightDrawer/RightDrawer.styled";
import { workOrderRoute } from "../../../../routes";
import { IWorkOrderState } from "../../../../feature/work-order/types";
import { useWorkOrdersForObject } from "../../../../feature/work-order/hooks/useWorkOrdersForObject";
import { toLocalDate } from "../../../../utils/dateUtils";
import { WorkOrderTile } from "../../../../feature/work-order/components/WorkOrderTile";
import { StyledFlex } from "../../../../assets/styles/flex.styled";

const DATE_FORMAT = "MMMM d, yyyy";

export const SpanInfoSection: FC<{
  data: SectionData;
  openHyperlink?: (type: ContentType, id: string) => void;
  setVectorLayerVisibility: ({ name, key, value }: { name: string; key: string; value: boolean | string }) => void;
  vectorLayers: Nullable<Record<string, AdditionalLayer>>;
  onActionNameClick: () => void;
  onRightOfWayChange: (args: any) => void;
  rightOfWayValue: Nullable<{ left: Nullable<number>; right: Nullable<number> }>;
  actionDisabled?: boolean;
  onViewInPlanClick: (id: string) => void;
}> = ({
  openHyperlink,
  data,
  setVectorLayerVisibility,
  vectorLayers,
  onActionNameClick,
  onRightOfWayChange,
  rightOfWayValue,
  actionDisabled = false,
  onViewInPlanClick,
}) => {
  const {
    data: worksheetSpans,
    isLoading: isWorksheetLoading,
    refetch: refetchWorksheet,
  } = useCustomQuery<SpanWorksheetsDto>(`${getWorksheetsForSpansUrl()}?spanIds=${data?.id as string}`, {
    enabled: !!data?.id,
  });

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());
  const { data: workOrders } = useWorkOrdersForObject(data?.id! as string);
  const history = useHistory();

  const updateSpans = useUpdateSpans(data?.id as string);

  const deleteWorkTypeFromInfringement = useCallback(
    (workTypeId: string) => {
      return ApiClient.delete(deleteInfringementWorkTypeUrl(data?.id as string, workTypeId))
        .then(() => {
          refetchWorksheet();
        })
        .catch((e) => logError(e));
    },
    [data, refetchWorksheet]
  );

  const worksheetData: Nullable<WorksheetResponse> = useMemo(() => {
    if (!worksheetSpans?.spanWorksheets?.length || !data?.id) {
      return null;
    }
    const items = worksheetSpans.spanWorksheets.find((item) => item.spanId === (data?.id as string));
    return {
      items: items ? items.items : [],
      totalCost: calculateSpanTotalCost(items?.items ?? []),
    };
  }, [worksheetSpans, data]);

  const invokeUpdateWorksheet = useCallback(
    (value: string[]) => {
      return updateSpans.mutateAsync({ items: [{ workTypesIds: value, spanId: data?.id as string }] });
    },
    [updateSpans, data]
  );

  const worktypes = useMemo(() => {
    if (workTypesResponse?.length === 0) return [];
    if (worksheetData?.items.length === 0) return [];

    return (
      worksheetData?.items
        .filter((wt) => !wt.inherited)
        .map((item) => {
          const wtResponse = workTypesResponse
            ?.filter((wtItem) => wtItem.scope === "SPAN")
            ?.find((wtItem) => wtItem.id === item.workTypeId)!;
          return {
            ...item,
            category: wtResponse.name,
            code: wtResponse.code,
            unit: wtResponse.unit,
            totalQuantity: item?.quantity,
            group: item.selected ? "Suggested" : ("All" as "Suggested" | "All"),
            added: false,
          };
        }) || []
    );
  }, [worksheetData, workTypesResponse]);

  const spanWoData: IWorkOrderState = {
    spanId: data?.id as string,
    riskPriority: data?.riskPriority as string,
    riskScore: data?.riskScore as string,
    treeSpecies: data?.treeSpecies as string[],
    spanLength: data?.spanLength as string,
    rowArea: data?.rowArea as string,
    worktypes: worktypes,
    workTypeScope: "SPAN",
  };

  const assignToNewWorkorder = (workTypeId: string) => {
    history.push(workOrderRoute, {
      ...spanWoData,
      subtask: {
        id: uuidv4(),
        selectedCrewType: null,
        worktypes: worktypes.map((wt) => ({ ...wt, added: wt.workTypeId === workTypeId })),
      },
    });
  };

  return (
    <>
      <DrawerSection name="RISK INFO">
        <SectionItem name={LABELS.riskPriority}>
          <SectionRiskPriority color={data?.riskPriorityColor as string} priority={data?.riskPriority as string} />
        </SectionItem>
        <SectionItem name={LABELS.riskScore}>{data?.riskScore}</SectionItem>
        <SectionItem name={LABELS.treeSpecies}>{!data?.treeSpecies ? "N/A" : ""}</SectionItem>
        <TreeSpecies species={data?.treeSpecies as string[]} />
        <SectionItem name="Additional Risks" marginTop={20} />
        <SectionIntersectedLayer
          intersectedLayers={data?.intersectedLayers as IntersectedLayers}
          setVectorLayerVisibility={setVectorLayerVisibility}
          vectorLayers={vectorLayers}
        />
      </DrawerSection>
      <DrawerSection name="Work Orders">
        <StyledFlex flexDirection="column" mb="10px" gap="10px">
          {workOrders?.map((wOrder) => (
            <WorkOrderTile key={wOrder.id} name={`WO ${wOrder.id}`} date={toLocalDate(wOrder.dueDate, DATE_FORMAT)} />
          ))}
        </StyledFlex>
        <CreateWorkOrderBtn to={{ pathname: workOrderRoute, state: spanWoData }}>Create Work Order</CreateWorkOrderBtn>
      </DrawerSection>
      <DrawerSection name="Worksheet">
        <WorksheetAllArea
          invokeUpdateWorksheet={invokeUpdateWorksheet}
          readonly={false}
          worksheetData={worksheetData}
          showSections={true}
          scope={"SPAN"}
          isWorksheetLoading={isWorksheetLoading}
          deleteWorkTypeFromInfringement={deleteWorkTypeFromInfringement}
          assignToNewWorkorder={assignToNewWorkorder}
        />
        <ButtonWrapper>
          <Button
            disabled={data?.riskScore === MISSING_VALUE}
            variant="view"
            size={"small"}
            mb={"20px"}
            width={"100%"}
            onClick={() => onViewInPlanClick && onViewInPlanClick(data?.id as string)}
          >
            {"View In Plan"}
          </Button>
        </ButtonWrapper>
      </DrawerSection>
      <DrawerSection
        name="SPAN RIGHT OF WAY"
        actionName="Restore Defaults"
        onActionNameClick={onActionNameClick}
        actionDisabled={actionDisabled}
        actionHeader={false}
      >
        <RightOfWay onChange={onRightOfWayChange} value={rightOfWayValue} />
      </DrawerSection>
      <DrawerSection name="SPAN INFO">
        <SectionItem name="Span Name">{data?.spanName}</SectionItem>
        <SectionItem name="Span Length">{data?.spanLength}</SectionItem>
        <SectionItem name={LABELS.rowArea}>{data?.rowArea}</SectionItem>
        <SectionItem name={LABELS.rowWorkableArea}>{data?.rowWorkableArea}</SectionItem>
        <SectionItem name="Structures">
          <SectionLinks data={data?.structures as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name="Nearest Switches">
          <SectionLinks data={data?.switches as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name="Parcels">
          <SectionLinks data={data?.parcels as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name={LABELS.circuitName}>
          <SectionLinks data={data?.circuits as SectionLinksType} openHyperlink={openHyperlink} />
        </SectionItem>
        <SectionItem name={LABELS.voltage}>{data?.voltage}</SectionItem>
        <SectionItem name={LABELS.demographic}>{data?.demographic}</SectionItem>
        <SectionItem name={LABELS.coordinates}>{data?.coordinates}</SectionItem>
      </DrawerSection>
      <DrawerSection name="ELEVATION">
        <SectionItem name={LABELS.elevationMin}>{data?.elevationMin}</SectionItem>
        <SectionItem name={LABELS.elevationAvg}>{data?.elevation}</SectionItem>
        <SectionItem name={LABELS.elevationMax}>{data?.elevationMax}</SectionItem>
      </DrawerSection>
    </>
  );
};
