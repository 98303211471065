import create from "zustand";

import { EMPTY_FN } from "../constants";
import { StructuresResponse, Structure } from "../types/responses";

export type StructuresById = Record<string, Structure>;

export type StructureStore = {
  selectedId: Nullable<string>;
  structuresGeoJSON: Nullable<StructuresResponse>;
  structuresById: StructuresById;
  riskViewHidden: boolean;
  resetStore: () => void;
  setStructuresData: (structures: StructuresResponse) => void;
  getStructureById: (id: string) => Nullable<Structure>;
  setSelectedId: (id: Nullable<string>) => void;
  toggleRiskViewHidden: () => void;
  addStructure: (structure: StructuresResponse) => void;
};

const INITIAL_STATE = {
  selectedId: null,
  structuresGeoJSON: null,
  structuresById: {} as StructuresById,
  riskViewHidden: true,
  addStructure: EMPTY_FN,
  resetStore: EMPTY_FN,
};

export const useStructuresStore = create<StructureStore>((set, get) => ({
  ...INITIAL_STATE,
  setStructuresData: (structures) => set(mapStructuresResponse(structures, get().structuresById)),
  getStructureById: (id: string) => get().structuresById?.[id],
  resetStore: () => set({ ...INITIAL_STATE }),
  setSelectedId: (id) => {
    if (id === get().selectedId) {
      return;
    }

    set({ selectedId: id });
  },
  toggleRiskViewHidden: () => {
    set({
      riskViewHidden: !get().riskViewHidden,
    });
  },
  addStructure: (structure) => {
    if (!structure?.features?.length) {
      return;
    }
    const structuresById = { ...get().structuresById };
    const newStructure = structure.features[0];
    if (!newStructure || !!structuresById[newStructure.properties.id]) {
      return;
    }
    structuresById[newStructure.properties.id] = newStructure;
    set({ structuresById });
  },
}));

export const mapStructuresResponse = (structuresGeoJSON: StructuresResponse, oldStructuresById: StructuresById) => {
  const structuresById: StructuresById = structuresGeoJSON.features.reduce((prev, curr) => {
    prev[curr.properties.id] = curr;

    return prev;
  }, {} as StructuresById);

  return {
    structuresGeoJSON,
    structuresById:
      oldStructuresById && Object.keys(oldStructuresById).length
        ? { ...oldStructuresById, ...structuresById }
        : structuresById,
  };
};

export default useStructuresStore;
