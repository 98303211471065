import styled from "styled-components";
import { Data1, FontStyle, Data2, InputLabel } from "../../../../assets/styles/fonts.styled";

export const CrewTypeTableWrapper = styled.div`
  table {
    padding-right: 0;
    margin-right: 0;
    thead tr th,
    .crew-type-row {
      ${Data1}
      background: #fbfbfb;

      color: var(--colors-pale-sky);
      text-align: center;
    }
    tbody tr td:nth-child(n + 2) {
      background: #fbfbfb;
    }
  }

  tfoot {
    display: hidden;
  }
  /* thead .billing-rate,
  th .billing-rate {
    ${FontStyle}
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--colors-pale-sky);
  }
  .billing-rate {
    background-color: var(--colors-alabaster-2);
    color: var(--colors-pale-sky);
  }
  .billing-rate:nth-child(2n + 2) {
    // border-right: 10px solid white;
  }
  .billing-rate input {
    color: var(--colors-pale-sky);
    text-align: center;
    width: 80%;
  }
  tbody tr td {
    ${Data1}
    text-align: right;
    color: var(--colors-alabaster-2);
  }

  .role-name input,
  .role-name div {
    ${Data2}
    color: #111827;
    padding-left: 20px;
    text-align: left;
  }

  .footer-number-of-people,
  .number-of-people input {
    ${Data1}
    width: 80%;
    color: var(--colors-pale-sky);
  }
  .footer-billing-rate {
    ${InputLabel}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: not-allowed;
    color: #c4c4c4;
  }

  .footer-number-of-people {
    padding-left: 11px;
    text-align: left;
    cursor: not-allowed;
    color: #c4c4c4;
  }
  .remove-role div:first-child {
    justify-content: center;
  }
  .remove-role .editable-input {
    display: none;
  }
  table tr,
  table tbody tr {
    height: 86px;
  }
  table thead tr {
    height: 49px;
  }

  table tbody tr:hover {
    outline: 1px solid var(--colors-teal-3);
    outline-offset: -1px;

    .remove-role img {
      display: inline-block;
    }
  }

  tbody tr td:first-child {
    border-right: none;
  }
  tbody tr td:nth-child(2n + 2),
  tfoot tr th:nth-child(2n + 2) {
    //border-right: 10px solid white;
  }
  tbody tr td:nth-child(2n + 3),
  tfoot tr th:nth-child(2n + 3) {
    border-right: none;
  }
  thead tr:last-child th:nth-child(2n + 2):nth-last-child(n + 3),
  tbody tr td:nth-child(2n + 2):nth-last-child(n + 3),
  tfoot tr th:nth-child(2n + 2):nth-last-child(n + 3) {
    border-right: 10px solid var(--colors-white);
  } */
  tbody tr td:nth-child(2n + 3),
  tfoot tr th:nth-child(2n + 3) {
    border-right: none;
  }

  tbody tr td:nth-child(n + 1),
  tfoot tr th:nth-child(2n + 2):nth-last-child(n + 3) {
    border-right: 10px solid var(--colors-white);
  }
`;
