import { FC } from "react";
import { MapAccessPoints } from "../../../components/MapAccessPoints";

import { useAccessPoints } from "../../../hooks/useAccessPoints";
import { useAccessPointsStore, useMapStore } from "../../../stores";
import { ACCESS_POINTS_ZOOM_LEVEL } from "./AccessPoints.util";

export const AccessPoints: FC<{ map: L.Map }> = ({ map }) => {
  const accessPoints = useAccessPointsStore((store) => store.data);
  const categories = useAccessPointsStore((store) => store.categories);
  const isLayerVisible = useAccessPointsStore((store) => store.isLayerVisible);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  useAccessPoints();
  const areAccessPointsVisible = isLayerVisible && currentZoomLevel > ACCESS_POINTS_ZOOM_LEVEL;

  return <>{areAccessPointsVisible && <MapAccessPoints categories={categories} data={accessPoints} map={map} />}</>;
};
