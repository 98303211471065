export const getLandingRoute = () => "/";

export const getForecastRoute = () => "/forecast";

export const getErrorRoute = () => "/general-error";

export const getAdminRoute = () => "/admin";

export const getAdminVoltageConfigurationRoute = () => `${getAdminRoute()}/voltage-configuration`;

export const getAdminElevationRoute = () => `${getAdminRoute()}/elevation`;

export const getAdminFacilitiesRoute = () => `${getAdminRoute()}/facilities`;

export const getAdminWorkTypeRoute = () => `${getAdminRoute()}/work-types`;

export const getAdminWorkTypeRatesRoute = () => `${getAdminWorkTypeRoute()}/rates`;

export const getAdminCrewConfigurationRoute = () => `${getAdminRoute()}/crew`;

export const getAdminCrewTypeConfigurationRoute = () => `${getAdminRoute()}/crew-type-config`;

export const plansRoute = `/plans`;
export const createPlanRoute = `${plansRoute}/new`;
export const editPlanRoute = (id: string) => `${plansRoute}/${id}`;

export const getStarCommandRoute = () => "/star-command";

export const workOrderRoute = "/work-order";
