import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import { Data2 } from "../../../assets/styles/fonts.styled";

export const ParcelStatusButtonWrapper = styled.div<{ selected: boolean; disabled: boolean }>`
  width: 141px;
  height: 44px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  border: 2px solid
    ${(props) =>
      props.disabled ? "rgba(249, 235, 235, 0.47)" : props.selected ? props.theme.colors.fountainBlue : "#EBEAEA"};
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: ${(props) => (props.selected ? "#D3F8FC" : "var(--colors-white)")};
  &:hover {
    ${(props) => {
      if (!props.disabled) {
        return `border: 2px solid ${props.theme.colors.fountainBlue};`;
      }
    }};
  }
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const ParcelStatusButtonBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ParcelStatusButtonBodyName = styled.div`
  ${Data2}
  text-align: center;
  flex: 1;
  line-height: 14px;
`;
export const ParcelStatusButtonBodyStatus = styled.div<ColorProps>`
  ${color}
  width: 16px;
  height: 7px;
  border-radius: 30px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.09);
`;
