import {
  CLEARANCE_STYLE,
  CLEARANCE_STYLE_OVERRIDEN,
  CLEARANCE_STYLE_SELECTED,
  CLEARANCE_STYLE_ACTIVE_INVISIBLE,
} from "./MapClearance.constants";

export const getClearanceStyle = ({
  selected,
  overriden,
  maintenanceColor,
  invisibleActiveMA,
}: {
  selected: boolean;
  overriden: boolean;
  maintenanceColor?: string;
  invisibleActiveMA?: boolean;
}) => {
  if (invisibleActiveMA) {
    return Object.assign({}, CLEARANCE_STYLE_ACTIVE_INVISIBLE, { className: "maintenance-area" });
  }

  const style = selected ? CLEARANCE_STYLE_SELECTED : CLEARANCE_STYLE;
  if (maintenanceColor !== undefined) {
    return Object.assign({}, style, { fillColor: maintenanceColor, fillOpacity: 0.4, className: "maintenance-area" });
  }

  if (!overriden) {
    return style;
  }

  return { ...style, ...CLEARANCE_STYLE_OVERRIDEN };
};
