export const PARCELS_STYLE = {
  color: "var(--colors-white)",
  fill: false,
  opacity: 0.47,
  weight: 3,
};

export const PARCELS_HOVER_STYLE = {
  color: "var(--colors-cyan)",
  fill: false,
  opacity: 1,
  weight: 3,
};

export const PARCELS_SELECTED_STYLE = {
  color: "var(--colors-cyan)",
  fill: true,
  fillColor: "rgba(46, 187, 196, 0.33)",
  opacity: 1,
  weight: 2,
};

export const PARCELS_NOTIFIED_STYLE = {
  color: "var(--colors-notified)",
  fill: true,
  fillColor: "var(--colors-notified)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_APPROVED_STYLE = {
  color: "var(--colors-approved)",
  fill: true,
  fillColor: "var(--colors-approved)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_REFUSED_STYLE = {
  color: "var(--colors-refused)",
  fill: true,
  fillColor: "var(--colors-refused)",
  opacity: 1,
  fillOpacity: 0.1,
  weight: 3,
};

export const PARCELS_CONCERNED_STYLE = {
  color: "var(--colors-concerned)",
  fill: true,
  fillColor: "var(--colors-concerned)",
  fillOpacity: 0.1,
  opacity: 1,
  weight: 3,
};

export const PARCELS_NOT_ASSIGNED_STYLE = {
  color: "var(--colors-not-assigned-outline)",
  fill: true,
  fillColor: "var(--colors-not-assigned)",
  opacity: 1,
  fillOpacity: 0.2,
  weight: 3,
};

export const PARCELS_SELECTED_LAYER_ON_STATUS = {
  color: "var(--colors-white)",
  fill: true,
  fillOpacity: 0.44,
  fillColor: "rgba(255, 255, 255)",
  opacity: 1,
  weight: 3,
};

export const PARCEL_STATUSES_LEGEND = {
  REFUSED: { bgColor: "var(--colors-refused-legend)", name: "Refused", markerColor: "var(--colors-refused-marker)" },
  APPROVED: { bgColor: "var(--colors-approved)", name: "Approved", markerColor: "var(--colors-approved-marker)" },
  CONCERNED: {
    bgColor: "var(--colors-concerned-legend)",
    name: "Concerned",
    markerColor: "var(--colors-concerned-marker)",
  },
  NOTIFIED: {
    bgColor: "var(--colors-notified-legend)",
    name: "Notified",
    markerColor: "var(--colors-notified-marker)",
  },
  NOT_ASSIGNED: {
    bgColor: "var(--colors-not-assigned)",
    name: "Unassigned",
    markerColor: "var(--colors-not-assigned-marker)",
  },
};
