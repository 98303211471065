import { Formik, Form, ErrorMessage } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import { Button } from "../../Button";
import { Input } from "../../Input/Input";

import { RightOfWayErrorMessage, RightOfWayFormWrapper, RightOfWayWrapper } from "./DrawerSectionItems.styled";

export const normalizeNumberInput = (value: number): number | undefined => (isNaN(value) ? undefined : value);

const validateNumber = Yup.lazy((value) => {
  if (value !== undefined || value !== null) {
    return Yup.number()
      .transform(normalizeNumberInput)
      .integer("Positive integers only allowed.")
      .max(350, "Value must be less than or equal to 350.")
      .positive("Positive integers only allowed.");
  }

  return Yup.mixed().notRequired();
});

const RightOfWaySchema = Yup.object().shape({
  leftOverride: validateNumber,
  rightOverride: validateNumber,
});

const DEFAULT_VALUES = {
  leftOverride: "",
  rightOverride: "",
};

interface RightOfWayProps {
  onChange: (args: any) => void;
  value: Nullable<{
    left: Nullable<number>;
    right: Nullable<number>;
  }>;
}

export const RightOfWay: FC<RightOfWayProps> = ({ onChange, value }) => (
  <RightOfWayWrapper>
    <Formik
      initialValues={value ? { leftOverride: value.left, rightOverride: value.right } : DEFAULT_VALUES}
      onSubmit={onChange}
      enableReinitialize={true}
      validationSchema={RightOfWaySchema}
    >
      {({ isValid, handleSubmit, dirty, errors }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <RightOfWayFormWrapper>
            <Input name="leftOverride" type="number" label="Left (ft)" displayErrorMessage={false} />
            <Input name="rightOverride" type="number" label="Right (ft)" displayErrorMessage={false} />
            <Button
              variant="primary"
              disabled={!dirty || !isValid}
              height="30px"
              minWidth="70px"
              fontSize="14px"
              position="relative"
              top="12px"
              type="submit"
              padding="0px"
            >
              Save
            </Button>
          </RightOfWayFormWrapper>
          <ErrorMessage component={RightOfWayErrorMessage} name="leftOverride" />
          {!errors.leftOverride && <ErrorMessage component={RightOfWayErrorMessage} name="rightOverride" />}
        </Form>
      )}
    </Formik>
  </RightOfWayWrapper>
);
