import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

import MapScreenPage from "./pages/MapScreen/MapScreen";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { USER_ROLES } from "./stores/AuthStore";
import {
  getLandingRoute,
  getForecastRoute,
  getErrorRoute,
  getAdminRoute,
  getStarCommandRoute,
  plansRoute,
  createPlanRoute,
  workOrderRoute,
} from "./routes";
import { logError } from "./services";
import { GeneralError } from "./pages/GeneralError";
import { PublicRoute } from "./components/PublicRoute";
import { Admin } from "./pages/Admin";
import "./App.css";
import { theme } from "./theme";
import ApiClient from "./utils/apiClient";
import useAuthStore from "./stores/AuthStore";
import { EnvironmentVariablesCheck } from "./components/EnvironmentVariablesCheck";
import { queryClient } from "./reactQueryAppClient";
import { StarCommandScreen } from "./pages/StarCommandScreen/StarCommandScreen";
import { PlanDashboard } from "./components/PlanDashboard";
import { AddEditPlan } from "./components/AddEditPlan";
import { TopHeader } from "./components/TopHeader";
import { WorkOrder } from "./pages/WorkOrder";

function App() {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const setProvider = useAuthStore((store) => store.setProvider);
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();

  useEffect(() => {
    ApiClient.init();
  }, []);

  useEffect(() => {
    setProvider(keycloak);
  }, [setProvider, keycloak]);

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    keycloak.onTokenExpired = () => {
      window.location.reload();
    };
  }, [keycloak]);

  const isLandingPage = [getLandingRoute(), getForecastRoute()].includes(pathname);

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={TopLevelErrorBoundaryFallback}>
        <EnvironmentVariablesCheck />
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <TopHeader commentDisabled={!isLandingPage} cutPlanDisabled={!isLandingPage} />
            <Switch>
              <PublicRoute path={getErrorRoute()} component={GeneralError} exact />
              <PrivateRoute path={[getAdminRoute()]} component={Admin} role={USER_ROLES.ADMIN} />
              <Route path={[getLandingRoute(), getForecastRoute()]} component={MapScreenPage} exact />
              <Route path={plansRoute} component={PlanDashboard} exact />
              <Route path={createPlanRoute} component={AddEditPlan} exact />
              <Route path={`${plansRoute}/:id`} component={AddEditPlan} />
              <Route path={[getStarCommandRoute()]} component={StarCommandScreen} exact />
              <Route
                path={workOrderRoute}
                render={(props) => (props.location.state ? <WorkOrder /> : <Redirect to={getLandingRoute()} />)}
                exact
              />

              {isAuthenticated && <Redirect to={getLandingRoute()} />}
            </Switch>
          </QueryClientProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

const TopLevelErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  logError(error);
  resetErrorBoundary();

  return <Redirect to={getErrorRoute()} push={true} />;
};

export default App;
