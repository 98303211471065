import { FC, useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Column, Row, TableCommonProps } from "react-table";
import * as Yup from "yup";

import { getStructuresAdminEntryUrl, getStructuresAdminUrl } from "../../../api";
import { Button } from "../../../components/Button/Button";
import { LoadingOverlay } from "../../../components/LoadingOverlay/LoadingOverlay";
import { ModalDialog } from "../../../components/ModalDialog";
import { ButtonsWrapper, ButtonWrapper } from "../../../components/ModalDialog/ModalDialog.styled";
import { CustomColumn, Table } from "../../../components/Table";
import { SingleItemFooter } from "../../../components/Table/SingleItemFooter/SingleItemFooter";
import { Toast } from "../../../components/Toast";
import { getDeleteMessage, SOMETHING_WENT_WRONG_MESSAGE, SPECIAL_CHARS_ARE_NOT_ALLOWED } from "../../../constants";
import { WITHOUT_SPECIAL_CHARS } from "../../../constants/regex";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { logError } from "../../../services";
import { LoadingState, useAdminLoadingStore, useMessageStore } from "../../../stores";
import { FacilityType } from "../../../types/responses";
import ApiClient from "../../../utils/apiClient";
import { HeaderText, SubPageWrapper } from "./SubPages.styled";
import { checkError } from "./SubPages.util";

type FacilityTypesColumns<T extends object> = Column<T> & TableCommonProps & CustomColumn;

const validationParams = {
  value: Yup.string()
    .required()
    .typeError("Please enter string value!")
    .max(50)
    .min(1)
    .test("withoutSpecChars", SPECIAL_CHARS_ARE_NOT_ALLOWED, (value) =>
      value ? WITHOUT_SPECIAL_CHARS.test(value.toString()) : true
    ),
};

export const Facilities: FC = () => {
  const setLoadingState = useAdminLoadingStore((store) => store.setLoadingState);
  const setErrorMessage = useMessageStore((store) => store.setErrorMessage);
  const [selectedIdForRemoval, setSelectedId] = useState<Nullable<string>>(null);

  const { isLoading: loading, isError: hasError, data: facilityTypes, refetch: refetchFacilities } = useCustomQuery<
    FacilityType[]
  >(getStructuresAdminUrl());

  const onRequestMutate = useCallback(() => {
    setLoadingState(LoadingState.Loading);
    setErrorMessage(null);
  }, [setLoadingState, setErrorMessage]);

  const onRequestError = useCallback(
    (error) => {
      setLoadingState(LoadingState.Error);
      let message = SOMETHING_WENT_WRONG_MESSAGE;
      if (checkError(error)) {
        message = error.response?.data?.message;
      }
      setErrorMessage(message);
    },
    [setLoadingState, setErrorMessage]
  );

  const onRequestSuccess = useCallback(() => {
    setLoadingState(LoadingState.Success);
  }, [setLoadingState]);

  const saveItem = useMutation((newValue: FacilityType) => ApiClient.post(getStructuresAdminUrl(), newValue), {
    onMutate: onRequestMutate,
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });

  const deleteItem = useMutation((id: string) => ApiClient.delete(getStructuresAdminEntryUrl(id)), {
    onMutate: onRequestMutate,
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });

  const updateItem = useMutation(
    (newValue: FacilityType) => ApiClient.put(getStructuresAdminEntryUrl(newValue.id), { name: newValue.name }),
    { onMutate: onRequestMutate, onSuccess: onRequestSuccess, onError: onRequestError }
  );

  const addField = useCallback(
    (value: Nullable<string>): Promise<LoadingState> => {
      if (value && value.trim().length > 0) {
        return saveItem
          .mutateAsync({ name: value })
          .then(() => {
            refetchFacilities();
            return Promise.resolve(LoadingState.Success);
          })
          .catch((error) => {
            logError(error);
            return Promise.resolve(LoadingState.Error);
          });
      }
      return Promise.resolve(LoadingState.Idle);
    },
    [saveItem, refetchFacilities]
  );

  const columns: FacilityTypesColumns<FacilityType>[] = useMemo(() => {
    return [
      {
        Header: "Structure Type",
        accessor: "name",
        editable: true,
        canRemove: false,
        type: "string",
        Footer: () => (
          <SingleItemFooter
            type={"string"}
            rows={facilityTypes ? facilityTypes.length : 0}
            validationParams={validationParams}
            addItem={addField}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityTypes]);

  const hideModalDialog = useCallback(() => {
    setSelectedId(null);
  }, []);

  const deleteField = useCallback(() => {
    hideModalDialog();
    if (selectedIdForRemoval) {
      deleteItem
        .mutateAsync(selectedIdForRemoval)
        .then(() => {
          refetchFacilities();
        })
        .catch((error) => {
          logError(error);
        });
    }
  }, [selectedIdForRemoval, deleteItem, refetchFacilities, hideModalDialog]);

  const updateField = useCallback(
    (row: Row<FacilityType>, column: Column, value: string): Promise<LoadingState> => {
      if (row.original.name === value) {
        return Promise.resolve(LoadingState.Idle);
      }
      return updateItem
        .mutateAsync({ name: value, id: row.original.id })
        .then(() => {
          refetchFacilities();
          return Promise.resolve(LoadingState.Success);
        })
        .catch((error) => {
          logError(error);
          return Promise.resolve(LoadingState.Error);
        });
    },
    [refetchFacilities, updateItem]
  );

  const removeField = useCallback((row: Row<FacilityType>) => {
    //disable remove for now for this table: in agreement with PO
    return;
  }, []);
  return (
    <SubPageWrapper>
      <LoadingOverlay hidden={!loading} />

      <Toast kind="error" hidden={!hasError} margin="auto" mt={2}>
        {SOMETHING_WENT_WRONG_MESSAGE}
      </Toast>

      <ModalDialog
        hidden={!selectedIdForRemoval}
        warning
        title="Delete voltage"
        message={getDeleteMessage("facility type")}
        onClose={hideModalDialog}
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <Button variant="cancel" size="small" onClick={hideModalDialog}>
              Cancel
            </Button>
            <Button variant="warning" size="small" onClick={deleteField}>
              Delete
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </ModalDialog>
      <HeaderText>Structures configuration</HeaderText>
      {!loading && !hasError && (
        <Table<FacilityType>
          columns={columns}
          data={facilityTypes}
          updateField={updateField}
          removeField={removeField}
          validationParams={validationParams}
        />
      )}
    </SubPageWrapper>
  );
};
