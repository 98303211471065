import { getRiskPriorityColor } from "../../pages/MapScreen/MapView/MapView.utils";
import { EncroachmentResponseProperties, RISK_PRIORITY } from "../../types/responses";
import { ENCROACHMENT_STYLE, ENCROACHMENT_STYLE_SELECTED } from "./MapEncroachments.constants";

export const getEncroachmentKey = (
  item: GeoJSON.Feature<GeoJSON.Geometry, EncroachmentResponseProperties>,
  selectedEncroachment: Nullable<string>
) => (item.properties.id === selectedEncroachment ? `${item.properties.id}_SELECTED` : item.properties.id);

export const getEncroachmentStyle = ({
  selected,
  priority,
  opacity,
}: {
  selected: boolean;
  priority: Maybe<RISK_PRIORITY>;
  opacity?: number;
}) => {
  const style = selected ? ENCROACHMENT_STYLE_SELECTED : ENCROACHMENT_STYLE;
  if (opacity !== undefined && !selected) {
    const scaledOpacity = opacity * 0.5;

    style.opacity = scaledOpacity;
    style.fillOpacity = scaledOpacity;
  }
  return { ...style, fillColor: getRiskPriorityColor(priority), color: getRiskPriorityColor(priority) };
};
