import { useEffect } from "react";
import { useQuery } from "react-query";

import { getStructuresApiUrlByExtent } from "../api";
import { MIN_ZOOM_LEVEL_FOR_STRUCTURES } from "../pages/MapScreen/MapView/MapView.constants";
import useStructuresStore from "../stores/StructuresStore";
import { StructuresResponse } from "../types/responses/StructuresResponse";
import ApiClient from "../utils/apiClient";
import { useExtentParams } from "./useExtentParams";

const QUERY_KEY = "structures";

const OFFSET = 1;

export const useStructures = () => {
  const params = useExtentParams();
  const setStructuresData = useStructuresStore((store) => store.setStructuresData);

  const isLayerVisible = params !== null && params.zoomLevel >= MIN_ZOOM_LEVEL_FOR_STRUCTURES - OFFSET;

  const query = useQuery(
    QUERY_KEY,
    () =>
      ApiClient.get(params !== null ? getStructuresApiUrlByExtent(params) : `/${QUERY_KEY}`).then(
        (res) => res.data as Nullable<StructuresResponse>
      ),
    {
      onSuccess: setStructuresData,
      enabled: isLayerVisible && params !== null,
    }
  );

  useEffect(() => {
    if (!params || !isLayerVisible) {
      return;
    }
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, isLayerVisible]);
};
