import { useEffect } from "react";
import { useQuery } from "react-query";

import { useCustomQuery } from ".";
import { getAccessPointsCategories, getAccessPointsUrl } from "../api";
import { QUERY_KEY } from "../pages/MapScreen/MapView/AccessPoints.util";
import useAccessPointsStore from "../stores/AccessPointsStore";
import { AccessPoints, AccessPointsCategories } from "../types/responses";
import ApiClient from "../utils/apiClient";
import { useExtentParams } from "./useExtentParams";

export const useAccessPoints = () => {
  const { setCategories, setData } = useAccessPointsStore((store) => store.actions);

  const requestParams = useExtentParams();

  const isLayerVisible = useAccessPointsStore((store) => store.isLayerVisible);

  useCustomQuery<Nullable<AccessPointsCategories>>(getAccessPointsCategories(), {
    enabled: isLayerVisible,
    onSuccess: (data: Nullable<AccessPointsCategories>) => {
      setCategories(data?.categories ?? null);
    },
  });

  const setResponse = (data: Nullable<AccessPoints>) => {
    setData(data?.markers ?? null);
  };

  const query = useQuery(
    QUERY_KEY,
    () =>
      ApiClient.get(requestParams !== null ? getAccessPointsUrl(requestParams) : `/${QUERY_KEY}`).then(
        (res) => res.data as Nullable<AccessPoints>
      ),
    {
      enabled: isLayerVisible && requestParams !== null,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (response: Nullable<AccessPoints>) => {
        setResponse(response);
      },
    }
  );

  useEffect(() => {
    if (!isLayerVisible || !requestParams) {
      return;
    }
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayerVisible, requestParams]);
};
