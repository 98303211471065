import { FC, memo } from "react";

import { PARCEL_STATUS } from "../../types/responses";
import { Checkbox } from "../Checkbox";
import { PARCEL_STATUSES_LEGEND } from "../MapParcels/MapParcels.constants";
import {
  ParcelLegendWrapper,
  ParcelLegendBody,
  ParcelLegendHeader,
  ParcelLegendNameWrapper,
  ParcelLegendSelectAll,
  WorkTypeItemColor,
  ParcelLegendName,
  ParcelStatusItem,
  ExpandCollapseLegend,
  MinMaxImage,
} from "./ParcelLegend.styled";
import Maximize from "./../../assets/images/maximize_arrow.svg";
import Minimize from "./../../assets/images/minimize.svg";
interface ParcelLegendProps {
  hidden: boolean;
  selectedStatuses: Nullable<Record<string, boolean>>;
  toggleAll: () => void;
  toggleStatus: (parcelStatus: PARCEL_STATUS) => void;
  leftMenuHidden: boolean;
  selectedAll: boolean;
  bottom?: number;
  expanded: boolean;
  setExpanded: () => void;
}
export const ParcelLegend: FC<ParcelLegendProps> = memo(
  ({
    hidden,
    selectedStatuses,
    toggleAll,
    toggleStatus,
    leftMenuHidden,
    selectedAll,
    bottom = 0,
    expanded,
    setExpanded,
  }) => {
    if (hidden) {
      return <></>;
    }

    return (
      <ParcelLegendWrapper leftMenuHidden={leftMenuHidden} bottom={bottom}>
        <ParcelLegendHeader expanded={expanded}>
          <ParcelLegendNameWrapper>
            <ParcelLegendName>
              <div>Parcel Management</div>
              <ExpandCollapseLegend onClick={setExpanded}>
                <MinMaxImage expanded={expanded} src={expanded ? Minimize : Maximize} alt={""} />
              </ExpandCollapseLegend>
            </ParcelLegendName>
          </ParcelLegendNameWrapper>
          <ParcelLegendSelectAll onClick={toggleAll}>
            {selectedAll ? "Deselect All" : "Select All"}
          </ParcelLegendSelectAll>
        </ParcelLegendHeader>
        {selectedStatuses && expanded && (
          <ParcelLegendBody>
            {Object.keys(PARCEL_STATUSES_LEGEND).map((key) => {
              return (
                <ParcelStatusItem key={`parcel-status-legend-${key}`}>
                  <WorkTypeItemColor backgroundColor={PARCEL_STATUSES_LEGEND[key as PARCEL_STATUS].bgColor} />
                  <Checkbox
                    defaultChecked={selectedStatuses !== null && selectedStatuses[key as PARCEL_STATUS]}
                    id={PARCEL_STATUSES_LEGEND[key as PARCEL_STATUS].name}
                    name={PARCEL_STATUSES_LEGEND[key as PARCEL_STATUS].name}
                    onClick={() => {
                      toggleStatus(key as PARCEL_STATUS);
                    }}
                    value={PARCEL_STATUSES_LEGEND[key as PARCEL_STATUS].name}
                    label={PARCEL_STATUSES_LEGEND[key as PARCEL_STATUS].name}
                    {...{ checked: selectedStatuses && selectedStatuses[key as PARCEL_STATUS] }}
                  />
                </ParcelStatusItem>
              );
            })}
          </ParcelLegendBody>
        )}
      </ParcelLegendWrapper>
    );
  }
);
