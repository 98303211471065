import { LineString, lineString } from "@turf/turf";
import L, { DrawMap, LatLng, LatLngExpression } from "leaflet";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { FeatureGroup, Marker, Polyline } from "react-leaflet";

import { useCustomMapPane, useMapOptimizedHiddenFlag } from "../../hooks";
import { useAddAccessPoint } from "../../hooks/useAddAccessPoint";
import { useDeleteAccessPoint } from "../../hooks/useDeleteAccessPoint";
import {
  getCategoryBySubCategory,
  getCategoryIcon,
  getFirstMarker,
  getGreenMarker,
  ICON_SIZE,
  MARKER_CLASS_NAME,
  POPUP_OPT,
} from "../../pages/MapScreen/MapView/AccessPoints.util";
import { AccessPoint, AccessPointCategory } from "../../types/responses/AccessPoints";
import { useEditAccessPoints } from "../../hooks/useEditAccessPoints";
import { MarkerPopup } from "../AlertPopup/MarkerPopup";
import { TreeMarkerCategory } from "../../types/responses";

import "./MapAccessPoints.styled.css";
import { TREE_MARKER_INDEX } from "../../constants";

const Z_INDEX_OFFSET = 1200;

const END_TOOLTIP = "Double click to finish";

const START_TOOLTIP = "Click to draw path";

const SHAPE_OPTIONS = {
  color: "#05FF00",
  weight: 2,
  opacity: 1,
  fillColor: "transparent",
};
const DRAW_CONTROL_OPTIONS = {
  metric: false,
  feet: false,
  zIndexOffset: 0,
  shapeOptions: SHAPE_OPTIONS,
  showLength: false,
  repeatMode: true,
};

interface MapAccessPointsProps {
  hidden?: boolean;
  map: Nullable<L.Map>;
  zIndex?: number;
  categories: Nullable<AccessPointCategory[]>;
  data: Nullable<AccessPoint[]>;
}

type PolylineLayer = {
  disable: () => void;
  enable: () => void;
};

const GREEN_MARKER = getGreenMarker();

const ACCESS_POINT_PANE = "access-points-pane";

export const MapAccessPoints: FC<MapAccessPointsProps> = memo(
  ({ hidden = false, map, zIndex = TREE_MARKER_INDEX, categories, data }) => {
    useCustomMapPane({ map, name: ACCESS_POINT_PANE, zIndex });
    const [optimizedHidden] = useMapOptimizedHiddenFlag(hidden);
    const [layer, setLayer] = useState<Nullable<PolylineLayer>>(null);
    const [drawnPolyline, setDrawnPolyline] = useState<Nullable<{ _latlngs: LatLng[] }>>(null);
    const addAccessPoint = useAddAccessPoint();
    const removeAccessPoint = useDeleteAccessPoint();
    const editAccessPoints = useEditAccessPoints();

    useEffect(() => {
      L.Edit.PolyVerticesEdit = L.Edit.PolyVerticesEdit.extend({
        _removeMarker: () => {
          return;
        },
        //override methods in order to stop adding a new points
        _createMiddleMarker: () => {
          return;
        },
        _updatePrevNext: () => {
          return;
        },
        _onMarkerClick: () => {
          return;
        },
      });
    }, []);

    useEffect(() => {
      if (!map) {
        return;
      }

      const polylineLayer: PolylineLayer = new L.Draw.Polyline(map as DrawMap, DRAW_CONTROL_OPTIONS);
      polylineLayer?.enable();
      setLayer(polylineLayer);
    }, [map]);

    useEffect(() => {
      const onEscape = (e: KeyboardEvent) => {
        if (e.code === "Escape") {
          layer?.disable();
          setTimeout(() => {
            layer?.enable();
          }, 500);
        }
      };
      document.addEventListener("keydown", onEscape, false);
      return () => {
        document.removeEventListener("keydown", onEscape, false);
      };
    }, [layer]);

    const onPopupClose = useCallback(() => {
      setDrawnPolyline(null);
      layer?.enable();
    }, [layer]);

    useEffect(() => {
      return () => {
        map?.closePopup();
      };
    }, [map]);

    useEffect(() => {
      map?.on("popupclose", onPopupClose);
      return () => {
        map?.off("popupclose", onPopupClose);
      };
    }, [map, onPopupClose]);

    useEffect(() => {
      if (!layer) {
        return;
      }
      return () => {
        layer.disable();
      };
    }, [layer]);

    useEffect(() => {
      if (!L.drawLocal?.draw?.handlers?.polyline) {
        return;
      }

      L.drawLocal.draw.handlers.polyline.tooltip = {
        start: START_TOOLTIP,
        end: END_TOOLTIP,
        cont: END_TOOLTIP,
      };
    }, []);

    const editAccessPoint = useCallback(
      (id: string, updateOpts: { geometry?: LineString; categoryId?: string }) => {
        editAccessPoints.mutateAsync({ markerID: id, value: updateOpts });
        map!.closePopup();
      },
      [map, editAccessPoints]
    );

    const createAccessPoint = useCallback(
      (e: L.LeafletMouseEvent, item?: AccessPoint, drawnItem?: { _latlngs: LatLng[] }) => {
        L.DomEvent.stopPropagation(e);
        drawnItem && setDrawnPolyline({ _latlngs: drawnItem._latlngs });
        const latLng = drawnItem?._latlngs?.length ? drawnItem._latlngs[drawnItem._latlngs.length - 1] : e.latlng;

        const createAccessPoint = (categoryId: string) => {
          if (!drawnItem) {
            return;
          }

          addAccessPoint.mutate({
            categoryId: categoryId,
            geometry: lineString(drawnItem._latlngs.map((item) => [item.lng, item.lat])).geometry,
          });
          map!.closePopup();
        };

        const deleteAccessPoint = (id: string) => {
          removeAccessPoint.mutate(id);
          map!.closePopup();
        };
        const popup = L.popup(POPUP_OPT);
        const container = L.DomUtil.create("div");

        ReactDOM.render(
          <MarkerPopup
            categories={(categories as TreeMarkerCategory[]) ?? []}
            create={createAccessPoint}
            deleteMarker={deleteAccessPoint}
            edit={editAccessPoint}
            title="Access Path"
            showSubcategoryImage={false}
            closePopup={() => popup.remove()}
            updatePopup={() => popup.update()}
            getCategoryImage={getCategoryIcon}
            item={item}
          />,
          container
        );

        container.addEventListener("mouseover", () => layer?.disable());
        container.addEventListener("mouseout", () => layer?.enable());

        popup.setLatLng(latLng);
        popup.setContent(container);
        popup.openOn(map!);
      },
      [map, categories, addAccessPoint, removeAccessPoint, editAccessPoint, layer]
    );

    const onCreate = useCallback(
      (e) => {
        createAccessPoint(e, undefined, e.layer);
      },
      [createAccessPoint]
    );

    useEffect(() => {
      map?.addEventListener(L.Draw.Event.CREATED, onCreate);
      return () => {
        map?.removeEventListener(L.Draw.Event.CREATED, onCreate);
      };
    }, [map, onCreate]);

    const renderedPolyLines = useMemo(() => {
      if (!data?.length) {
        return;
      }

      return data.map((item) => {
        const firstMarker = getFirstMarker(item.geometry.coordinates[0], item.geometry.coordinates[1]);
        const category = getCategoryBySubCategory(categories, item?.categoryCode ?? "");
        const lastMarker = L.divIcon({
          iconSize: ICON_SIZE,
          html: `<div></div>`,
          className: `${MARKER_CLASS_NAME} category-access-point-${category?.id}`,
        });

        return (
          <FeatureGroup>
            <Polyline
              interactive={false}
              {...SHAPE_OPTIONS}
              pane={ACCESS_POINT_PANE}
              ref={(ref) => {
                if (!ref) {
                  return;
                }
                //@ts-ignore
                ref.editing.enable();
              }}
              key={uuidv4()}
              positions={item.geometry?.coordinates.map((c) => [c[1], c[0]]) as LatLngExpression[]}
              eventHandlers={{
                mouseout: () => layer?.enable(),
                mouseover: () => layer?.disable(),
                //@ts-ignore
                edit: (e) => {
                  layer?.enable();
                  editAccessPoint(item.id, {
                    geometry: lineString(e.target._latlngs.map((coord: LatLng) => [coord.lng, coord.lat])).geometry,
                    categoryId: item.categoryId,
                  });
                },
                add: (e) => {
                  //@ts-ignore
                  const markers: L.Marker[] = e.target.editing._poly?.editing?._verticesHandlers?.[0]?._markers;
                  if (markers?.length) {
                    markers[markers.length - 1].addEventListener("click", (e) => {
                      createAccessPoint(e as L.LeafletMouseEvent, item);
                    });
                    markers[0].setIcon(firstMarker) && markers[markers.length - 1].setIcon(lastMarker);

                    markers.forEach((markerItem) => {
                      markerItem.setZIndexOffset(Z_INDEX_OFFSET);
                      markerItem.addEventListener("mouseover", () => {
                        layer?.disable();
                      });
                      markerItem.addEventListener("mouseout", () => {
                        layer?.enable();
                      });
                    });
                  }
                },
                editstart: (e: unknown) => {
                  layer?.disable();
                },
              }}
            />
          </FeatureGroup>
        );
      });
    }, [data, createAccessPoint, editAccessPoint, categories, layer]);

    const temporaryPolyline = useMemo(() => {
      if (!drawnPolyline?._latlngs?.length || drawnPolyline?._latlngs?.length <= 1) {
        return <></>;
      }

      const firstMarker = getFirstMarker(
        [drawnPolyline._latlngs[0].lng, drawnPolyline._latlngs[0].lat],
        [drawnPolyline._latlngs[1].lng, drawnPolyline._latlngs[1].lat]
      );

      return (
        <Polyline
          interactive={false}
          {...SHAPE_OPTIONS}
          positions={drawnPolyline._latlngs.map((item) => [item.lat, item.lng])}
        >
          {drawnPolyline._latlngs.map((item, i) => {
            return <Marker position={[item.lat, item.lng]} icon={i === 0 ? firstMarker : GREEN_MARKER} />;
          })}
        </Polyline>
      );
    }, [drawnPolyline]);

    if (optimizedHidden) return <></>;

    return (
      <>
        {renderedPolyLines}
        {temporaryPolyline}
      </>
    );
  }
);
