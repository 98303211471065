import styled from "styled-components";
import { IconButton } from "../IconButton";

export const DeleteButton = styled(IconButton)`
  border: none;
  color: var(--colors-outer-space);
  font-weight: 500;
  font-size: 13px;
  padding: 16px 20px;
  justify-content: flex-start;
  width: 338px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 1px rgb(0 0 0 / 1%);
  border-radius: 6px;

  svg path {
    fill: red;
  }

  &:hover {
    background-color: var(--colors-link-water);
  }
`;

export const SelectWrapper = styled.div`
  width: 124px;

  .react-select__control {
    background-color: #fbfbfb;
    border: 1px solid #d6d6d6;
  }

  .react-select__single-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }

  [data-risk-priority] {
    width: 5px;
    height: 14px;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 9%);
    border-radius: 5px;
  }

  [data-risk-priority="low"] {
    background-color: var(--colors-priority-low);
  }

  [data-risk-priority="medium"] {
    background-color: var(--colors-priority-medium);
  }

  [data-risk-priority="high"] {
    background-color: var(--colors-priority-high);
  }

  [data-risk-priority="critical"] {
    background-color: var(--colors-priority-critical);
  }

  [data-risk-priority="violation"] {
    background-color: var(--colors-priority-violation);
  }
`;
