import create from "zustand";

import { EMPTY_FN } from "../../constants";
import { BASE_LAYER } from "../../constants/map";
import {
  MaintenanceAreaGeometryDto,
  RefreshFilterType,
  RESOLUTION_SET,
  StarCommandResponse,
} from "../../types/responses";
import { MaxarResponseType } from "../../types/responses/MaxarResponse";
import MapHelper from "../../utils/mapHelper";

const INITIAL_FILTER: RefreshFilterType = {
  resolutionSet: [RESOLUTION_SET.HIGH],
  ageCategorySet: [],
  seasonalFilter: null,
  maxCloudCover: 30,
  maxAreaOffNadir: 20,
};

export type StarCommandStoreState = {
  actions: {
    setCurrentZoomLevel: (currentZoomLevel: number) => void;
    setCurrentBounds: (currentBounds: Nullable<L.LatLngBoundsExpression>) => void;
    setMap: (map: Nullable<L.Map>) => void;
    changeSelectedBaseLayer: (currentLayerSelected: BASE_LAYER) => void;
    setStarCommandData: (data: Nullable<StarCommandResponse>) => void;
    setMaintenanceAreas: (areas: Nullable<MaintenanceAreaGeometryDto[]>) => void;
    toggleMaintenanceArea: (id: string) => void;
    resetMap: () => void;
    calculateBounds: () => void;
    toggleFeederMAs: (feederId: string, checked: boolean) => void;
    updateFilter: (filter: RefreshFilterType) => void;
    setImages: (images: Nullable<MaxarResponseType>) => void;
    updateImageChecked: (itemId: string) => void;
    setRhsState: (state: boolean) => void;
    setHoveredId: (hoveredId: Nullable<string>) => void;
    resetImages: () => void;
  };
  defaultBounds?: Nullable<L.LatLngBoundsExpression>;
  currentBounds?: Nullable<L.LatLngBoundsExpression>;
  currentZoomLevel: Nullable<number>;
  defaultCenter?: Nullable<L.LatLngExpression>;
  map: Nullable<L.Map>;
  currentLayerSelected: BASE_LAYER;
  starCommandData: Nullable<StarCommandResponse>;
  maintenanceAreas: Nullable<MaintenanceAreaGeometryDto[]>;
  checkedMaintenanceAreas: Nullable<string[]>;
  colorById: Nullable<Record<string, string>>;
  filter: RefreshFilterType;
  images: Nullable<MaxarResponseType>;
  rhsVisible: boolean;
  hoveredId: Nullable<string>;
};
const INITIAL_STATE = {
  map: null,
  defaultBounds: null,
  defaultCenter: null,
  currentBounds: null,
  currentZoomLevel: null,
  currentLayerSelected: BASE_LAYER.LIGHT_LAYER,
  starCommandData: null,
  maintenanceAreas: null,
  checkedMaintenanceAreas: [],
  images: null,
  filter: INITIAL_FILTER,
  colorById: {},
  rhsVisible: false,
  hoveredId: null,
  actions: {
    setCurrentZoomLevel: EMPTY_FN,
    setCurrentBounds: EMPTY_FN,
    setMap: EMPTY_FN,
    changeSelectedBaseLayer: EMPTY_FN,
    setStarCommandData: EMPTY_FN,
    setMaintenanceAreas: EMPTY_FN,
    toggleMaintenanceArea: EMPTY_FN,
    resetMap: EMPTY_FN,
    calculateBounds: EMPTY_FN,
    toggleFeederMAs: EMPTY_FN,
    updateFilter: EMPTY_FN,
    setImages: EMPTY_FN,
    updateImageChecked: EMPTY_FN,
    setRhsState: EMPTY_FN,
    setHoveredId: EMPTY_FN,
    resetImages: EMPTY_FN,
  },
};
export const useStarCommandMapStore = create<StarCommandStoreState>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    setCurrentBounds: (currentBounds: Nullable<L.LatLngBoundsExpression>) => {
      set({ currentBounds });
    },
    setCurrentZoomLevel: (currentZoomLevel: Nullable<number>) => set({ currentZoomLevel }),
    setMap: (map: Nullable<L.Map>) => {
      if (!map) {
        return;
      }
      map.on("zoomend", () => {
        set({ currentZoomLevel: map.getZoom() });
      });

      set({ map: map });
    },
    changeSelectedBaseLayer: (currentLayerSelected: BASE_LAYER) => {
      if (get().currentLayerSelected === currentLayerSelected) {
        return;
      }

      set({ currentLayerSelected });
    },

    setStarCommandData: (starCommandData) => set({ starCommandData }),
    setMaintenanceAreas: (maintenanceAreas) => {
      set({ maintenanceAreas });
      get().actions.calculateBounds();
    },
    toggleMaintenanceArea: (id: string) =>
      set(({ checkedMaintenanceAreas }) => {
        const isExist = checkedMaintenanceAreas?.some((itemId) => itemId === id);
        if (isExist) {
          return { checkedMaintenanceAreas: checkedMaintenanceAreas?.filter((itemId) => itemId !== id) ?? null };
        }

        return { checkedMaintenanceAreas: [...checkedMaintenanceAreas!, id] };
      }),

    resetMap: () =>
      set({
        map: INITIAL_STATE.map,
      }),
    calculateBounds: () => {
      const maintenanceAreas = get().maintenanceAreas;
      const checked = get().checkedMaintenanceAreas;
      if (maintenanceAreas?.length && checked?.length) {
        const boundBox = MapHelper.bBox(false, {
          type: "GeometryCollection",
          geometries: maintenanceAreas
            .filter((item) => checked.find((checkedItem) => checkedItem === item.id))
            ?.map((item) => item.polygon),
        });
        boundBox && get().actions.setCurrentBounds(MapHelper.normalizeBounds(boundBox) as L.LatLngBoundsExpression);
      }
    },
    toggleFeederMAs: (feederId: string, checked: boolean) => {
      const starCommandData = get().starCommandData;
      const feeder = starCommandData?.feederList?.find((feeder) => feeder.id === feederId);
      if (!feeder?.maintenanceAreaIds) return;

      let checkedMaintenanceAreas = get().checkedMaintenanceAreas;
      feeder.maintenanceAreaIds.forEach((maId) => {
        const isExist = checkedMaintenanceAreas?.some((itemId) => itemId === maId);
        if (isExist && !checked) {
          checkedMaintenanceAreas = checkedMaintenanceAreas?.filter((itemId) => itemId !== maId) ?? null;
        }

        if (checked && !isExist) checkedMaintenanceAreas = [...checkedMaintenanceAreas!, maId];
      });
      set({ checkedMaintenanceAreas: Object.assign([], checkedMaintenanceAreas) });
    },
    updateFilter: (filter) => set({ filter }),
    setImages: (images) => set({ images }),
    updateImageChecked: (itemId: string) => {
      const images = { ...get().images };
      set({
        images: {
          availableImages:
            images?.availableImages?.map((listItem) => {
              if (listItem.generatedId === itemId) {
                return {
                  ...listItem,
                  checked: !listItem.checked,
                };
              }
              return listItem;
            }) ?? [],
        },
      });
    },
    setRhsState: (rhsVisible) => set({ rhsVisible }),
    setHoveredId: (hoveredId) => set({ hoveredId }),
    resetImages: () => set({ images: INITIAL_STATE.images, hoveredId: INITIAL_STATE.hoveredId }),
  },
}));

export default useStarCommandMapStore;
