import { roundValue } from "../../utils/unitHelper";

export const calculatePercent = (total: number, value: number) => {
  if (!total || !value) {
    return 0;
  }
  const percent = roundValue((100 * value) / total);
  return percent > 100 ? 100 : percent;
};

export const getAmountFromPercent = (percent: number, total: number) => {
  return percent !== 0 && total !== 0 ? (percent * total) / 100 : 0;
};
