import L from "leaflet";

import { ParcelsResponseProperties, PARCEL_STATUS } from "../../types/responses/ParcelsResponse";
import {
  PARCELS_HOVER_STYLE,
  PARCELS_SELECTED_STYLE,
  PARCELS_STYLE,
  PARCELS_APPROVED_STYLE,
  PARCELS_NOTIFIED_STYLE,
  PARCELS_REFUSED_STYLE,
  PARCELS_CONCERNED_STYLE,
  PARCELS_NOT_ASSIGNED_STYLE,
  PARCELS_SELECTED_LAYER_ON_STATUS,
  PARCEL_STATUSES_LEGEND,
} from "./MapParcels.constants";

const getParcelStyleByStatus = (status: Maybe<PARCEL_STATUS>) => {
  switch (status) {
    case PARCEL_STATUS.APPROVED:
      return PARCELS_APPROVED_STYLE;
    case PARCEL_STATUS.NOTIFIED:
      return PARCELS_NOTIFIED_STYLE;
    case PARCEL_STATUS.REFUSED:
      return PARCELS_REFUSED_STYLE;
    case PARCEL_STATUS.CONCERNED:
      return PARCELS_CONCERNED_STYLE;
    default:
      return PARCELS_NOT_ASSIGNED_STYLE;
  }
};

export const getParcelsStyle = ({
  item,
  hoveredId,
  parcelStatusEnabled,
  multipleSelectedIds,
  isSelectionToolHidden,
  areMultiParcelsSelected,
}: {
  item: Maybe<GeoJSON.Feature<GeoJSON.Geometry, ParcelsResponseProperties>>;
  hoveredId: Nullable<string>;
  parcelStatusEnabled: boolean;
  multipleSelectedIds: Nullable<string[]>;
  isSelectionToolHidden: boolean;
  areMultiParcelsSelected: boolean;
}) => {
  const itemId = item?.properties.id ?? "";
  const className = `map__parcel parcel-id-${itemId} parcel-status-${item?.properties.status} ${
    multipleSelectedIds && multipleSelectedIds.includes(itemId) ? "parcel-selected" : ""
  }`;
  if (parcelStatusEnabled || (multipleSelectedIds && multipleSelectedIds.includes(itemId) && !isSelectionToolHidden)) {
    if (multipleSelectedIds && multipleSelectedIds.includes(itemId) && areMultiParcelsSelected) {
      return {
        className: className,
        ...PARCELS_SELECTED_LAYER_ON_STATUS,
      };
    }
    return {
      className: className,
      ...getParcelStyleByStatus(item?.properties.status),
    };
  }
  if (multipleSelectedIds && multipleSelectedIds.includes(itemId)) {
    return {
      className: className,
      ...PARCELS_SELECTED_STYLE,
    };
  }

  if (hoveredId === itemId) {
    return {
      className: className,
      ...PARCELS_HOVER_STYLE,
    };
  }

  return {
    className: className,
    ...PARCELS_STYLE,
  };
};

export const getParcelIcon = (status: PARCEL_STATUS) => {
  return new L.DivIcon({
    html: getIcon(PARCEL_STATUSES_LEGEND[status ? status : PARCEL_STATUS.NOT_ASSIGNED].markerColor),
    className: "parcel_icon_details",
    iconSize: [24, 24],
  });
};

export const getSmallParcelIcon = (status: PARCEL_STATUS) => {
  return new L.DivIcon({
    html: getSmallIcon(PARCEL_STATUSES_LEGEND[status ? status : PARCEL_STATUS.NOT_ASSIGNED].markerColor),
    className: "parcel_icon_details",
    iconSize: [12, 12],
  });
};

export const getIcon = (bgColor: string) => {
  return `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g >
<circle cx="14" cy="10" r="10" fill="${bgColor}"/>
<circle cx="14" cy="10" r="9.5" stroke="white"/>
</g>
<path d="M17.9815 8.89783L14.2305 4.94278C14.1325 4.83939 13.9777 4.83939 13.8798 4.94278L10.1251 8.90191C10.0456 8.98931 10 9.10971 10 9.23277C10 9.48889 10.1896 9.69744 10.4224 9.69744H11.0138V13.0429C11.0138 13.4536 11.3162 13.7863 11.6897 13.7863H16.4206C16.794 13.7863 17.0965 13.4536 17.0965 13.0429V9.69744H17.6878C17.9206 9.69744 18.1102 9.48889 18.1102 9.23277C18.1102 9.10971 18.0646 8.98931 17.9815 8.89783Z" fill="white"/>
<defs>
<filter id="filter0_d" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>`;
};

export const getSmallIcon = (bgColor: string) => {
  return `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_4445:311)">
  <path d="M14.8095 6.42436L9.25946 1.1043C9.11453 0.965232 8.88547 0.965232 8.74054 1.1043L3.18503 6.42985C3.06747 6.54741 3 6.70937 3 6.8749C3 7.21942 3.28052 7.49995 3.62503 7.49995H4.5V12C4.5 12.5525 4.94751 13 5.50003 13H12.5C13.0525 13 13.5 12.5525 13.5 12V7.49995H14.375C14.7195 7.49995 15 7.21942 15 6.8749C15 6.70937 14.9325 6.54741 14.8095 6.42436Z" fill="${bgColor}"/>
  <path d="M14.8095 6.42436L9.25946 1.1043C9.11453 0.965232 8.88547 0.965232 8.74054 1.1043L3.18503 6.42985C3.06747 6.54741 3 6.70937 3 6.8749C3 7.21942 3.28052 7.49995 3.62503 7.49995H4.5V12C4.5 12.5525 4.94751 13 5.50003 13H12.5C13.0525 13 13.5 12.5525 13.5 12V7.49995H14.375C14.7195 7.49995 15 7.21942 15 6.8749C15 6.70937 14.9325 6.54741 14.8095 6.42436Z" stroke="white" stroke-width="1.5"/>
  </g>
  <defs>
  <filter id="filter0_d_4445:311" x="0.25" y="0.25" width="17.5" height="17.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="2"/>
  <feGaussianBlur stdDeviation="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4445:311"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4445:311" result="shape"/>
  </filter>
  </defs>
  </svg>
   
  `;
};
