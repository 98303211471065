import styled from "styled-components";
import { Data1, Data2, DataLabel } from "../../assets/styles/fonts.styled";
import { IconButton } from "../IconButton";

export const PreviewModal = styled.div`
  position: relative;
  width: 760px;
  height: 523px;
  background-color: var(--colors-black);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  background-color: var(--colors-white);
  border-radius: 5px 5px 0 0;
`;

export const Title = styled.span`
  ${Data1}
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--colors-mineral-green);
`;

export const Date = styled.span`
  ${DataLabel}
  color: var(--colors-nevada);
`;

export const NavigationButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  padding: 7px 10px;
  transition: transform 0.2s;
  aspect-ratio: 1;
  width: 32px;

  &.prev {
    left: 0;
    transform: translate(-49px, -50%);
    &:hover {
      transform: translate(-51px, -50%) scale(1.1);
    }
  }

  &.next {
    right: 0;
    transform: translate(49px, -50%);
    &:hover {
      transform: translate(51px, -50%) scale(1.1);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
`;

export const FilePreview = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessagesWrapper = styled.div`
  height: 100%;
  width: 279px;
  background: var(--colors-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e8ecf1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  box-sizing: border-box;
  overflow-y: overlay;
  .editable-content {
    cursor: pointer;
  }
`;

export const Message = styled.span`
  height: 93px;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fbfbfb;
  border: 1px solid #d6d6d6;
  ${Data2}
`;

export const ThumnbailsWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 5px;
  max-width: 480px;
  overflow-x: scroll;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  padding: 10px 0;

  ::-webkit-scrollbar {
    display: none;
  }

  > div:first-child {
    margin-left: 2px;
  }
  > div:last-child {
    margin-right: 2px;
  }
`;

export const DescriptionUsername = styled.div`
  ${Data1}
`;

export const DescriptionContent = styled.div`
  ${DataLabel}
  font-size: 12px;
  color: #7b8182;
  min-height: 20px;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  textarea {
    border: none;
    font-family: "Barlow";
    font-style: normal;
    outline: none;
    resize: none;
    font-weight: 600;
    font-size: 11px;
    color: var(--colors-black);
    border-radius: 10px;
    padding: 7px 9px 9px 9px;
    width: 90%;
    height: auto;

    ::placeholder {
      font-weight: 600;
      font-size: 11px;
      color: var(--colors-silver-2);
    }
  }
`;

export const LeaveDescriptionWrapper = styled.div<{ enabled: boolean }>`
  background: var(--colors-white);
  div:first-child {
    border: 1px solid ${(props) => (props.enabled ? "var(--colors-teal-3)" : "var(--colors-alto)")};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const ReplyWrapper = styled.div<{ enabled: boolean }>`
  position: absolute;
  bottom: 0;
  width: 275px;
  background: var(--colors-white);
  div:first-child {
    margin: 10px;
    border: 1px solid ${(props) => (props.enabled ? "var(--colors-teal-3)" : "var(--colors-alto)")};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--colors-seashell);
  display: list-item;
`;
