import React, { FC, useMemo, useCallback } from "react";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Backdrop } from "../Backdrop";
import { RangeInput } from "../RangeInput";
import { SwitchButton } from "../SwitchButton";
import { Datepicker } from "../Datepicker";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as CloseIcon } from "../../assets/images/close_custom.svg";
import { Wrapper, Header, SectionElement, SectionHeader, SeasonRadio } from "./StarCommandFilterLHS.styled";
import { MenuSection } from "../LayersMenu";
import { RefreshFilterType, RESOLUTION_SET, SEASON, AGE_CATEGORY } from "../../types/responses";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { getAcquisitionName } from "./StarCommandFilterLHS.utils";
import { Loader } from "../Loader/Loader";

interface IProps {
  show: boolean;
  setShow: (show: boolean) => void;
  filter: RefreshFilterType;
  saveFilter: (filter: RefreshFilterType) => void;
  callRefresh: () => void;
  loading?: boolean;
}

export const StarCommandFilterLHS: FC<IProps> = ({
  show,
  setShow,
  filter,
  saveFilter,
  callRefresh,
  loading = false,
}) => {
  const seasonYear = filter?.seasonalFilter?.year ? new Date(filter?.seasonalFilter?.year, 0) : new Date();

  const setSeasonYear = (year: Date) => {
    if (!year) return;
    const newFilter = { ...filter };
    if (!newFilter.seasonalFilter) {
      newFilter.seasonalFilter = { year: new Date().getFullYear(), season: SEASON.SPRING };
    }

    newFilter.seasonalFilter.year = year.getFullYear();
    saveFilter(newFilter);
  };

  const resolutionData = useMemo(() => {
    return Object.values(RESOLUTION_SET).map((key) => ({
      checked: !!filter?.resolutionSet?.find((resolutionKey) => resolutionKey === key),
      key: key,
      name: capitalizeFirstLetter(key),
    }));
  }, [filter]);

  const acquisitionData = useMemo(() => {
    return Object.values(AGE_CATEGORY).map((key) => ({
      checked: !!filter?.ageCategorySet?.find((ageCategorySet) => ageCategorySet === key),
      key: key,
      name: getAcquisitionName(key),
    }));
  }, [filter]);

  const toggleResolutionData = useCallback(
    (key: string) => {
      const newFilter = { ...filter };
      const resolutionDataIndex = newFilter?.resolutionSet?.findIndex((resolutionKey) => resolutionKey === key);
      if (resolutionDataIndex < 0) {
        newFilter.resolutionSet.push(key as RESOLUTION_SET);
      } else {
        newFilter.resolutionSet.splice(resolutionDataIndex, 1);
      }
      saveFilter(newFilter);
    },
    [filter, saveFilter]
  );

  const toggleAgeCategory = useCallback(
    (key: string) => {
      const newFilter = { ...filter };
      const ageCategoryIndex = newFilter?.ageCategorySet?.findIndex((resolutionKey) => resolutionKey === key);
      if (ageCategoryIndex < 0) {
        newFilter.ageCategorySet.push(key as AGE_CATEGORY);
      } else {
        newFilter.ageCategorySet.splice(ageCategoryIndex, 1);
      }
      saveFilter(newFilter);
    },
    [filter, saveFilter]
  );

  const setOpacity = useCallback(
    (value: number, areaOfNadir?: boolean) => {
      const newFilter = { ...filter };
      if (areaOfNadir) {
        newFilter.maxAreaOffNadir = value;
      } else {
        newFilter.maxCloudCover = value;
      }
      saveFilter(newFilter);
    },
    [filter, saveFilter]
  );

  return (
    <Backdrop isHidden={!show} setIsHidden={() => setShow(false)}>
      <Wrapper>
        <Header>
          <span>Filter</span>
          <IconButton className="close" Icon={CloseIcon} onClick={() => setShow(false)} />
        </Header>
        <StyledFlex
          flexDirection="column"
          gap="10px"
          background="var(--colors-alabaster-3)"
          flex={1}
          padding="10px 20px 20px"
          overflowY="scroll"
        >
          <MenuSection
            data={resolutionData}
            onClick={({ name, key, value }) => toggleResolutionData(key)}
            title="Resolution"
            type="multiple-select"
            data-testid="resolution-select"
          />
          <MenuSection
            data={acquisitionData}
            onClick={({ name, key, value }) => toggleAgeCategory(key)}
            title="Acquisition date"
            type="multiple-select"
            data-testid="acquisition-date-select"
            disabled={!!filter?.seasonalFilter}
          >
            <StyledFlex padding="7px 20px 20px" flexDirection="column" gap="15px">
              <SectionHeader>
                <span>Seasonal</span>
                <SwitchButton
                  id="test"
                  data-testid="seasonal-switch"
                  disabled={false}
                  checked={filter?.seasonalFilter !== null}
                  defaultChecked={false}
                  name={`seasonal`}
                  onClick={() => {
                    const newFilter = { ...filter };
                    if (!filter?.seasonalFilter) {
                      newFilter.seasonalFilter = { year: new Date().getFullYear() - 1, season: SEASON.SPRING };
                      newFilter.ageCategorySet = [];
                    } else {
                      newFilter.seasonalFilter = null;
                    }
                    saveFilter(newFilter);
                  }}
                />
              </SectionHeader>
              {filter?.seasonalFilter && (
                <>
                  <Datepicker
                    showYearPicker={true}
                    dateFormat="yyyy"
                    name="seasonYear"
                    placeholderText="Year"
                    onChange={(date) => setSeasonYear(date!)}
                    selected={seasonYear}
                    isClearable
                  />
                  <StyledFlex gap="0px" background="var(--colors-gallery)" borderRadius="3px">
                    {Object.values(SEASON).map((season) => (
                      <SeasonRadio key={season}>
                        <input
                          id={season}
                          type="radio"
                          name="seasonal"
                          data-testid="seasonal-radio"
                          defaultChecked={filter?.seasonalFilter?.season === season}
                          onChange={() => {
                            const newFilter = { ...filter };
                            if (!newFilter.seasonalFilter) {
                              newFilter.seasonalFilter = { year: new Date().getFullYear() - 1, season: season };
                            }
                            newFilter.seasonalFilter.season = season;
                            saveFilter(newFilter);
                          }}
                        />
                        <label htmlFor={season}>
                          {capitalizeFirstLetter(season === SEASON.AUTUMN ? "Fall" : season)}
                        </label>
                      </SeasonRadio>
                    ))}
                  </StyledFlex>
                </>
              )}
            </StyledFlex>
          </MenuSection>
          <SectionElement>
            <div>
              <span>{`Cloud Cover: < ${filter?.maxCloudCover ?? "0"}%`}</span>
              <RangeInput
                defaultValue={filter?.maxCloudCover ? filter?.maxCloudCover / 100 : 0}
                border="1px solid #e9e9e9"
                onClick={(e) => setOpacity(Number((e.target as HTMLInputElement).value) * 100)}
                showPercentage={false}
                data-testid="cloud-cover-range"
              />
            </div>
            <div>
              <span>{`Area of Nadir: < ${filter?.maxAreaOffNadir ?? "0"}°`}</span>
              <RangeInput
                defaultValue={filter?.maxAreaOffNadir ? filter?.maxAreaOffNadir / 100 : 0}
                border="1px solid #e9e9e9"
                onClick={(e) => setOpacity(Number((e.target as HTMLInputElement).value) * 100, true)}
                showPercentage={false}
                data-testid="area-of-nadir-range"
              />
            </div>
          </SectionElement>
        </StyledFlex>
        <StyledFlex borderTop="1px solid #EFEFEF" alignItems="center" padding="15px" marginTop="auto">
          <Button
            justifyContent="center"
            alignItems="center"
            disabled={loading}
            variant="primary"
            paddingLeft={loading ? "48%" : ""}
            width="100%"
            height="42px"
            fontSize="14px"
            data-testid="refresh"
            onClick={callRefresh}
          >
            {loading ? <Loader /> : "Refresh"}
          </Button>
        </StyledFlex>
      </Wrapper>
    </Backdrop>
  );
};
