import { DEFAULT_STYLE } from "./MapTopLevelRegions.constants";

export const getColorForRegion = ({
  color,
  hovered,
  interactive,
}: {
  color: string;
  hovered: boolean;
  interactive: boolean;
}) => {
  const hoverStyle = hovered
    ? {
        ...DEFAULT_STYLE,
        fillColor: color,
        fillOpacity: 0.6,
      }
    : {};

  if (!interactive) {
    return {
      ...DEFAULT_STYLE,
      fill: false,
      color: "var(--colors-white)",
    };
  }

  return {
    ...DEFAULT_STYLE,
    fillColor: color,
    ...hoverStyle,
  };
};
