import { TreeMarkerCategoryType } from "../../../types/responses";

import { ReactComponent as prune } from "./../../../assets/images/trees/prune.svg";
import { ReactComponent as danger } from "./../../../assets/images/trees/danger_tree.svg";
import { ReactComponent as remove } from "./../../../assets/images/trees/remove.svg";
import { ReactComponent as damage } from "./../../../assets/images/trees/damage.svg";
import { ReactComponent as dead } from "./../../../assets/images/trees/dead_tree.svg";
import { ReactComponent as transplant } from "./../../../assets/images/trees/transplant.svg";
import { ReactComponent as disease } from "./../../../assets/images/trees/insects.svg";

export const POPUP_OPT: L.PopupOptions = {
  className: "alerts-popup",
  closeButton: false,
  closeOnEscapeKey: true,
  autoPan: true,
  keepInView: true,
  offset: [120, 20],
  autoPanPadding: [50, 70],
};

export const QUERY_KEY = "/tree-markers";

export const MARKER_ZOOM_LEVEL = 15;

export const getCategoryImage = (code: string) => {
  switch (code) {
    case TreeMarkerCategoryType.Damage:
      return damage;
    case TreeMarkerCategoryType.Danger:
      return danger;
    case TreeMarkerCategoryType.Dead:
      return dead;
    case TreeMarkerCategoryType.Prune:
      return prune;
    case TreeMarkerCategoryType.Remove:
      return remove;
    case TreeMarkerCategoryType.Transplant:
      return transplant;
    case TreeMarkerCategoryType.Disease:
      return disease;
    default:
      return damage;
  }
};
