import { FC, memo } from "react";
import { isChrome } from "react-device-detect";

import { TaxItem } from "./";
import { TaxonomyWrapper, TaxonomyItemBox, TaxonomyCoverItem } from "./Taxonomy.styled";
import { TaxonomyItem } from "../../types/TaxonomyItem";
import { SpanRiskPriority } from "../../stores";
import { EMPTY_FN } from "../../constants";
import { RISK_PRIORITY } from "../../types/responses";

// TODO optimize with virtualized list. This list can be big so its better to display just visible parts.
interface TaxonomyProps {
  data: TaxonomyItem[];
  onSelect: (item: TaxonomyItem, riskPriority?: SpanRiskPriority) => void;
  selectedItem: Nullable<string>;
  selectedSubItem: Nullable<string>;
  setHoveredObjectId: (id: Nullable<string>) => void;
  spanRiskPriority: SpanRiskPriority;
  hideFeeder?: (feederId: string) => void;
  hiddenFeeders: Nullable<string[]>;
  selectedRiskPriority: Nullable<RISK_PRIORITY>;
}

export const Taxonomy: FC<TaxonomyProps> = memo(
  ({
    data,
    onSelect,
    selectedItem,
    spanRiskPriority,
    selectedSubItem,
    setHoveredObjectId,
    hideFeeder = EMPTY_FN,
    hiddenFeeders,
    selectedRiskPriority,
  }) => {
    const taxonomyItems = data
      .filter((item) => !selectedRiskPriority || (selectedRiskPriority && selectedRiskPriority === item.riskPriority))
      .map((item) => (
        <TaxItem
          item={item}
          key={item.id}
          onSelect={onSelect}
          selectedItem={selectedItem}
          selectedSubItem={selectedSubItem}
          setHoveredObjectId={setHoveredObjectId}
          spanRiskPriority={spanRiskPriority}
          hideFeeder={hideFeeder}
          isItemHidden={!!(item.type === "feeder" && hiddenFeeders && hiddenFeeders?.find((id) => id === item.id))}
        />
      ));
    return (
      <TaxonomyWrapper>
        <TaxonomyItemBox scroll={isChrome ? "overlay" : "auto"}>{taxonomyItems}</TaxonomyItemBox>
        <TaxonomyCoverItem />
      </TaxonomyWrapper>
    );
  }
);
