import { useEffect, useState, createRef, useCallback, useMemo } from "react";
import {
  Wrapper,
  ToggleLhsButton,
  SearchWrapper,
  Hint,
  TabHeader,
  TabItem,
  ListItem,
  PriorityCount,
  PriorityTitle,
} from "./StarCommandLHS.styled";
import { useLeftControlStore } from "../../stores/LeftToggleStore";
import { ReactComponent as Magnifier } from "../../assets/images/magnifier.svg";
import { ReactComponent as ViolationIcon } from "../../assets/images/violation.svg";
import { ReactComponent as RightArrow } from "../../assets/images/arrow_r.svg";
import { Input } from "../Input/Input.styled";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { StarCommandFilterLHS } from "../StarCommandFilterLHS";
import useStarCommandMapStore from "../../stores/StarCommandMapStore/StarCommandMapStore";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { SatelliteQueryRequest, StarCommandNode } from "../../types/responses";
import { RISK_PRIORITY } from "../../types/responses/ThreatHierarchyResponse";
import { usePostSatelliteQuery } from "../../hooks";
import useMessageStore from "../../stores/MessagesStore";
import {
  SOMETHING_WENT_WRONG_MESSAGE,
  CHOOSE_MAINTENANCE_AREA,
  CHOOSE_RESOLUTION_SET,
  CHOOSE_AGE_CATEGORY,
  NO_AVAILABLE_IMAGES,
} from "../../constants";
import { mapImageResponse } from "../../pages/StarCommandScreen/StarCommand.utils";

enum TabType {
  MA = "MA",
  Feeder = "Feeder",
}

type FilterType = keyof typeof RISK_PRIORITY;

export const StarCommandLHS = () => {
  const toggled = useLeftControlStore((store) => store.toggled);
  const setToggled = useLeftControlStore((store) => store.setToggled);
  const starCommandData = useStarCommandMapStore((store) => store.starCommandData);
  const checkedMaintenanceAreas = useStarCommandMapStore((store) => store.checkedMaintenanceAreas);
  const { toggleMaintenanceArea } = useStarCommandMapStore((store) => store.actions);
  const { setImages } = useStarCommandMapStore((store) => store.actions);
  const { resetImages } = useStarCommandMapStore((store) => store.actions);
  const { toggleFeederMAs: toggleFeeder } = useStarCommandMapStore((store) => store.actions);
  const { setRhsState } = useStarCommandMapStore((store) => store.actions);
  const maintenanceAreas = useStarCommandMapStore((store) => store.maintenanceAreas);
  const [searchText, setSearchText] = useState("");
  const [itemsList, setItemsList] = useState(starCommandData?.maintenanceAreaList ?? []);
  const [activeTab, setActiveTab] = useState(TabType.MA);
  const [priorityFilter, setPriorityFilter] = useState<Nullable<FilterType>>(null);
  const filterRef = createRef<HTMLDivElement>();
  const [showFilters, setShowFilters] = useState(false);
  const filter = useStarCommandMapStore((store) => store.filter);
  const { updateFilter } = useStarCommandMapStore((store) => store.actions);
  const { mutateAsync: mutateAsyncSatellite, isLoading } = usePostSatelliteQuery();
  const setMessage = useMessageStore((store) => store.setErrorMessage);
  const resetStore = useMessageStore((store) => store.resetStore);

  useEffect(() => {
    setToggled(true);
  }, [setToggled]);

  useEffect(() => {
    if (!starCommandData) return;

    const activeData = activeTab === TabType.MA ? starCommandData.maintenanceAreaList : starCommandData.feederList;
    setItemsList(activeData);
  }, [starCommandData, activeTab]);

  const requestData: Nullable<SatelliteQueryRequest> = useMemo(() => {
    const checkedMAs = maintenanceAreas?.filter((item) =>
      checkedMaintenanceAreas?.find((checked) => checked === item.id)
    );
    if (!checkedMAs) {
      return null;
    }
    return {
      filters: filter,
      maintenanceAreaList: checkedMAs,
    };
  }, [filter, maintenanceAreas, checkedMaintenanceAreas]);

  const callRefresh = useCallback(() => {
    resetImages();
    resetStore();
    setRhsState(false);
    if (!requestData?.maintenanceAreaList?.length) {
      setMessage(CHOOSE_MAINTENANCE_AREA);
      return;
    }
    if (!requestData?.filters?.resolutionSet?.length) {
      setMessage(CHOOSE_RESOLUTION_SET);
      return;
    }

    if (!requestData?.filters?.seasonalFilter && !requestData.filters?.ageCategorySet?.length) {
      setMessage(CHOOSE_AGE_CATEGORY);
      return;
    }
    mutateAsyncSatellite(requestData!)
      .then((response) => {
        setImages(mapImageResponse(response?.data ?? null));
        if (!response?.data?.availableImages?.length) {
          setMessage(NO_AVAILABLE_IMAGES);
        } else {
          setRhsState(true);
        }
      })
      .catch((e) => setMessage(e?.response?.data?.message ?? SOMETHING_WENT_WRONG_MESSAGE));
  }, [requestData, mutateAsyncSatellite, setMessage, setImages, resetStore, setRhsState, resetImages]);

  const onTabClick = (type: TabType) => {
    setPriorityFilter(null);
    setSearchText("");
    setActiveTab(type);
    if (type === TabType.MA) {
      setItemsList(starCommandData?.maintenanceAreaList ?? []);
    } else {
      setItemsList(starCommandData?.feederList ?? []);
    }
  };

  const isChecked = useCallback(
    (itemId: string) => {
      if (activeTab === TabType.MA) {
        return checkedMaintenanceAreas?.some((id) => id === itemId);
      } else {
        const feeder = starCommandData?.feederList?.find((feeder) => feeder.id === itemId);
        if (!feeder?.maintenanceAreaIds?.length) return false;
        return (
          feeder?.maintenanceAreaIds?.filter((mId) =>
            checkedMaintenanceAreas?.find((checkedMaId) => checkedMaId === mId)
          )?.length === feeder?.maintenanceAreaIds?.length
        );
      }
    },
    [activeTab, checkedMaintenanceAreas, starCommandData]
  );

  const onItemClick = (id: string) => {
    if (activeTab === TabType.MA) {
      toggleMaintenanceArea(id);
    } else {
      toggleFeeder(id, !isChecked(id));
    }
  };

  const byPriority = (item: StarCommandNode) => {
    if (!priorityFilter) return true;
    return item.priority === priorityFilter;
  };

  const bySearchTerm = (item: StarCommandNode) => {
    if (!searchText || searchText.length < 2) return true;
    return item.name.toLowerCase().match(new RegExp(searchText.toLowerCase(), "g"));
  };

  return (
    <Wrapper className={toggled ? "isOpen" : ""}>
      <ToggleLhsButton Icon={RightArrow} onClick={() => setToggled(!toggled)} />
      <SearchWrapper>
        <Magnifier />
        <Input
          value={searchText}
          type="string"
          list="browsers"
          hasError={false}
          placeholder="Search"
          onChange={(e) => setSearchText((e.target as HTMLInputElement)?.value)}
        />
      </SearchWrapper>
      <Hint>SELECT AOI</Hint>
      <TabHeader>
        <TabItem onClick={() => onTabClick(TabType.MA)} className={activeTab === TabType.MA ? "isActive" : ""}>
          MA
        </TabItem>
        <TabItem onClick={() => onTabClick(TabType.Feeder)} className={activeTab === TabType.Feeder ? "isActive" : ""}>
          Feeder
        </TabItem>
        <span>Last visit</span>
        <span>Next visit</span>
        <span>Risk</span>
      </TabHeader>{" "}
      <StyledFlex
        ref={filterRef}
        background="#E5EDF2"
        padding="10px 10px 10px 16px"
        alignItems="center"
        marginBottom="10px"
      >
        <PriorityTitle>Priority: </PriorityTitle>
        <StyledFlex gap="4px" width="100%" justifyContent="center">
          {Object.keys(RISK_PRIORITY)
            .reverse()
            .map((priority) => (
              <PriorityCount
                key={priority}
                className={priorityFilter === priority ? "isActive" : ""}
                onClick={() => setPriorityFilter(priorityFilter === priority ? null : (priority as RISK_PRIORITY))}
              >
                <span data-priority={priority}></span>
                <span>{itemsList.filter((item) => item.priority === priority).length}</span>{" "}
              </PriorityCount>
            ))}
        </StyledFlex>
      </StyledFlex>
      <StyledFlex
        flexDirection="column"
        overflowY="scroll"
        data-testid={activeTab === TabType.Feeder ? "feeder-list" : "maintenance-list"}
      >
        {itemsList
          .filter(bySearchTerm)
          .filter(byPriority)
          ?.map((item) => (
            <ListItem
              key={item.id}
              onClick={() => onItemClick(item.id)}
              title={item.name}
              data-testid={activeTab === TabType.Feeder ? "feeder-item" : "maintenance-item"}
            >
              <Checkbox
                key={item.id}
                id={item.id}
                label={item.name.toLowerCase()}
                name={item.name}
                value={item.name}
                defaultChecked={false}
                checked={isChecked(item.id)}
                onClick={() => {}}
              />
              <span>{item.lastVisit ?? " "}</span>
              <span>{item.nextVisit ?? " "}</span>
              <div data-priority={item.priority} title={item.priority}>
                {item.priority === RISK_PRIORITY.VIOLATION ? <ViolationIcon /> : <span></span>}
              </div>
            </ListItem>
          ))}
      </StyledFlex>
      <StyledFlex borderTop="1px solid #EFEFEF" alignItems="center" padding="15px" marginTop="auto">
        <Button
          variant="primary"
          width="100%"
          height="42px"
          fontSize="14px"
          onClick={() => setShowFilters(!showFilters)}
        >
          Filter
        </Button>
        <StarCommandFilterLHS
          callRefresh={callRefresh}
          show={showFilters}
          filter={filter}
          setShow={setShowFilters}
          saveFilter={updateFilter}
          loading={isLoading}
        />
      </StyledFlex>
    </Wrapper>
  );
};
