import { useMemo } from "react";

import { getImageForAdditionalLayer } from "../pages/MapScreen/MapView/MapView.utils";
import { useMapStore } from "../stores";
import { ADDITIONAL_LAYER_TYPE } from "../stores/MapStore";
import { LayerRow } from "../types/Layers";

const ENVIRONMENTAL_LAYERS = [
  ADDITIONAL_LAYER_TYPE.BATS,
  ADDITIONAL_LAYER_TYPE.NO_SPRAY,
  ADDITIONAL_LAYER_TYPE.CONSERVATION_LANDS,
  ADDITIONAL_LAYER_TYPE.NATIONAL_FOREST,
  ADDITIONAL_LAYER_TYPE.ORCHIDS,
  ADDITIONAL_LAYER_TYPE.CREES_FLOWER,
  ADDITIONAL_LAYER_TYPE.HYDROLOGY,
  ADDITIONAL_LAYER_TYPE.WETLANDS,
];

export const useEnvironmentalLayers = () => {
  const vectorLayers = useMapStore((store) => store.vectorLayers);
  const setVectorLayerVisibility = useMapStore((store) => store.setVectorLayerVisibility);
  const overlaySwitchControl = useMapStore((store) => store.overlaySwitchControl);

  const vectorLayersData = useMemo(() => {
    if (!vectorLayers) {
      return undefined;
    }
    return ENVIRONMENTAL_LAYERS.map((key: string) => {
      const item = vectorLayers[key as ADDITIONAL_LAYER_TYPE];
      return {
        checked: item.checked,
        key: key,
        name: item.name,
        image: getImageForAdditionalLayer(key as ADDITIONAL_LAYER_TYPE),
      } as LayerRow;
    });
  }, [vectorLayers]);

  return {
    data: vectorLayersData,
    setVectorLayerVisibility,
    switchChecked: overlaySwitchControl.ADDITIONAL_LAYERS.checked,
    showSwitch: overlaySwitchControl.ADDITIONAL_LAYERS.showSwitch,
  };
};
