import { useEffect } from "react";
import { useQuery } from "react-query";

import { useCustomQuery } from ".";
import { getTreeMarkerCategories, getTreeMarkersUrl } from "../api";
import { QUERY_KEY } from "../pages/MapScreen/MapView/TreeMarkerView.utils";
import useTreeMarkersStore from "../stores/TreeMarkersStore";
import { TreeMarkerCategories, TreeMarkers } from "../types/responses/TreeMarkers";
import ApiClient from "../utils/apiClient";
import { useExtentParams } from "./useExtentParams";

export const useTreeMarkers = () => {
  const { setCategories } = useTreeMarkersStore((store) => store.actions);
  const requestParams = useExtentParams();
  const { setData } = useTreeMarkersStore((store) => store.actions);
  const isLayerVisible = useTreeMarkersStore((store) => store.isLayerVisible);

  const { data: categoriesData } = useCustomQuery<Nullable<TreeMarkerCategories>>(getTreeMarkerCategories(), {
    enabled: isLayerVisible,
  });

  const setResponse = (data: Nullable<TreeMarkers>) => {
    setData(data?.markers ?? null);
  };

  const query = useQuery(
    QUERY_KEY,
    () =>
      ApiClient.get(requestParams !== null ? getTreeMarkersUrl(requestParams) : QUERY_KEY).then(
        (res) => res.data as Nullable<TreeMarkers>
      ),
    {
      enabled: isLayerVisible,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (response: Nullable<TreeMarkers>) => setResponse(response),
    }
  );

  useEffect(() => {
    if (!isLayerVisible || !requestParams) {
      return;
    }
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayerVisible, requestParams]);

  useEffect(() => {
    if (!categoriesData) {
      return;
    }
    setCategories(categoriesData?.categories ?? null);
  }, [categoriesData, setCategories]);
};
