import { ReactComponent as AccessIcon } from "../../assets/images/alerts/categories/access.svg";
import { ReactComponent as AnimalIcon } from "../../assets/images/alerts/categories/animal.svg";
import { ReactComponent as EnvironmentalIcon } from "../../assets/images/alerts/categories/environmental.svg";
import { ReactComponent as HerbicideIcon } from "../../assets/images/alerts/categories/herbicide.svg";
import { ReactComponent as WorkareaIcon } from "../../assets/images/alerts/categories/work_area.svg";
import { ReactComponent as DangerTreeIcon } from "../../assets/images/alerts/categories/danger_tree.svg";
// subcategories
import { ReactComponent as AccessIssueIcon } from "../../assets/images/alerts/categories/access_issue.svg";
import { ReactComponent as AccessCodeIcon } from "../../assets/images/alerts/categories/access_code.svg";
import { ReactComponent as UtilityLockIcon } from "../../assets/images/alerts/categories/utility_lock.svg";
import { ReactComponent as PoisonIvyIcon } from "../../assets/images/alerts/categories/poison_ivy.svg";
import { ReactComponent as PoisonOakIcon } from "../../assets/images/alerts/categories/poison_oak.svg";
import { ReactComponent as ErosionProtectionIcon } from "../../assets/images/alerts/categories/erosion_protection_soil_control.svg";
import { ReactComponent as EndangeredPlant } from "../../assets/images/alerts/categories/endangered_plant.svg";
import { ReactComponent as BeesIcon } from "../../assets/images/alerts/categories/bees.svg";
import { ReactComponent as SnakeIcon } from "../../assets/images/alerts/categories/snake.svg";
import { ReactComponent as DogIcon } from "../../assets/images/alerts/categories/dog.svg";
import { ReactComponent as LargeBirdIcon } from "../../assets/images/alerts/categories/large_bird.svg";
import { ReactComponent as BatIcon } from "../../assets/images/alerts/categories/bat.svg";
import { ReactComponent as EndangeredAnimalIcon } from "../../assets/images/alerts/categories/endangered_animal.svg";
import { ReactComponent as DeadAnimalIcon } from "../../assets/images/alerts/categories/dead_animal.svg";
import { ReactComponent as AquaticMixOnlyIcon } from "../../assets/images/alerts/categories/aquatic_mix_only.svg";
import { ReactComponent as NoHerbicideIcon } from "../../assets/images/alerts/categories/no_herbicide.svg";
import { ReactComponent as NoAerialSprayIcon } from "../../assets/images/alerts/categories/no_aerial_spray.svg";
import { ReactComponent as SepticLinesIcon } from "../../assets/images/alerts/categories/septic_lines.svg";
import { ReactComponent as VolatileSoilIcon } from "../../assets/images/alerts/categories/volatile_soils.svg";
import { ReactComponent as FloodedIcon } from "../../assets/images/alerts/categories/flooded.svg";
import { ReactComponent as FrozenGroundIcon } from "../../assets/images/alerts/categories/frozen_ground.svg";
import { ReactComponent as DangerousTerrainIcon } from "../../assets/images/alerts/categories/dangerous_terrain.svg";
import { ReactComponent as FallInIcon } from "../../assets/images/alerts/categories/fall_in.svg";
import { ReactComponent as BlowInIcon } from "../../assets/images/alerts/categories/blow_in.svg";
import { ReactComponent as GrowInIcon } from "../../assets/images/alerts/categories/grow_in.svg";
import { ReactComponent as DiseaseIcon } from "../../assets/images/alerts/categories/disease.svg";
import { ReactComponent as DyingTreeIcon } from "../../assets/images/alerts/categories/dying_tree.svg";
import { ReactComponent as DeadTreeIcon } from "../../assets/images/alerts/categories/dead_tree.svg";

export enum Categories {
  Access = "access",
  Environment = "environment",
  Animal = "animal",
  Herbicide = "herbicide",
  WorkArea = "work-area",
  DangerTree = "danger-tree",
}

export enum AccessSubcategories {
  AccessIssue = "access-issue",
  AccessCode = "access-code",
  UtilityLock = "utility-lock",
}

export enum EnvironmentSubcategories {
  PoisonIvy = "poison-ivy",
  PoisonOak = "poison-oak",
  ErosionProtection = "erosion-protection-soil-control",
  EndangeredPlant = "endangered-plant-flower",
  Other = "environment-other",
}

export enum AnimalSubcategories {
  Bees = "bees",
  Snake = "snake",
  Dog = "dog",
  Bird = "bird",
  Bat = "bat",
  EndangeredAnimal = "endangered",
  DeadAnimal = "dead",
  Other = "animal-other",
}

export enum HerbicideSubcategories {
  AquaticMixOnly = "aquatic-mix-only",
  NoHerbicide = "no-herbicide",
  NoAerialSpray = "no-aerial-spray",
}

export enum WorkAreaSubcategories {
  SepticLines = "septic-lines",
  VolatileSoils = "volatile-soils",
  Flooded = "flooded",
  FrozenGround = "frozen-ground",
  DangerousTerrain = "dangerous-terrain",
  Other = "work-area-other",
}

export enum DangerTreeSubcategories {
  FallIn = "fall-in",
  BlowIn = "blow-in",
  GrowIn = "grow-in",
  Disease = "Disease",
  DyingTree = "dying-tree",
  DeadTree = "dead-tree",
}

export const getCategoryIcon = (categoryCode: string) => {
  if (categoryCode === Categories.Access) return AccessIcon;
  if (categoryCode === Categories.Environment) return EnvironmentalIcon;
  if (categoryCode === Categories.Animal) return AnimalIcon;
  if (categoryCode === Categories.Herbicide) return HerbicideIcon;
  if (categoryCode === Categories.WorkArea) return WorkareaIcon;
  if (categoryCode === Categories.DangerTree) return DangerTreeIcon;
  return AccessIcon;
};

export const getSubcategoryIcon = (subCategoryCode: string) => {
  // access
  if (subCategoryCode === AccessSubcategories.AccessIssue) return AccessIssueIcon;
  if (subCategoryCode === AccessSubcategories.AccessCode) return AccessCodeIcon;
  if (subCategoryCode === AccessSubcategories.UtilityLock) return UtilityLockIcon;
  // environmental
  if (subCategoryCode === EnvironmentSubcategories.PoisonIvy) return PoisonIvyIcon;
  if (subCategoryCode === EnvironmentSubcategories.PoisonOak) return PoisonOakIcon;
  if (subCategoryCode === EnvironmentSubcategories.ErosionProtection) return ErosionProtectionIcon;
  if (subCategoryCode === EnvironmentSubcategories.EndangeredPlant) return EndangeredPlant;
  if (subCategoryCode === EnvironmentSubcategories.Other) return EnvironmentalIcon;
  // animal
  if (subCategoryCode === AnimalSubcategories.Bees) return BeesIcon;
  if (subCategoryCode === AnimalSubcategories.Snake) return SnakeIcon;
  if (subCategoryCode === AnimalSubcategories.Dog) return DogIcon;
  if (subCategoryCode === AnimalSubcategories.Bird) return LargeBirdIcon;
  if (subCategoryCode === AnimalSubcategories.Bat) return BatIcon;
  if (subCategoryCode === AnimalSubcategories.EndangeredAnimal) return EndangeredAnimalIcon;
  if (subCategoryCode === AnimalSubcategories.DeadAnimal) return DeadAnimalIcon;
  if (subCategoryCode === AnimalSubcategories.Other) return AnimalIcon;
  // herbicide
  if (subCategoryCode === HerbicideSubcategories.AquaticMixOnly) return AquaticMixOnlyIcon;
  if (subCategoryCode === HerbicideSubcategories.NoHerbicide) return NoHerbicideIcon;
  if (subCategoryCode === HerbicideSubcategories.NoAerialSpray) return NoAerialSprayIcon;
  // workarea
  if (subCategoryCode === WorkAreaSubcategories.SepticLines) return SepticLinesIcon;
  if (subCategoryCode === WorkAreaSubcategories.VolatileSoils) return VolatileSoilIcon;
  if (subCategoryCode === WorkAreaSubcategories.Flooded) return FloodedIcon;
  if (subCategoryCode === WorkAreaSubcategories.FrozenGround) return FrozenGroundIcon;
  if (subCategoryCode === WorkAreaSubcategories.DangerousTerrain) return DangerousTerrainIcon;
  if (subCategoryCode === WorkAreaSubcategories.Other) return WorkareaIcon;
  // tree
  if (subCategoryCode === DangerTreeSubcategories.FallIn) return FallInIcon;
  if (subCategoryCode === DangerTreeSubcategories.BlowIn) return BlowInIcon;
  if (subCategoryCode === DangerTreeSubcategories.GrowIn) return GrowInIcon;
  if (subCategoryCode === DangerTreeSubcategories.Disease) return DiseaseIcon;
  if (subCategoryCode === DangerTreeSubcategories.DyingTree) return DyingTreeIcon;
  if (subCategoryCode === DangerTreeSubcategories.DeadTree) return DeadTreeIcon;
  return AccessIcon;
};

export const getCategoryNameFromCode = (subCategoryCode: string) => {
  if (subCategoryCode === AccessSubcategories.AccessIssue) return "Access Issue";
  if (subCategoryCode === AccessSubcategories.AccessCode) return "Access Code";
  if (subCategoryCode === AccessSubcategories.UtilityLock) return "Utility Lock";
  // environmental
  if (subCategoryCode === EnvironmentSubcategories.PoisonIvy) return "Poison Ivy";
  if (subCategoryCode === EnvironmentSubcategories.PoisonOak) return "Poison Oak";
  if (subCategoryCode === EnvironmentSubcategories.ErosionProtection) return "Erosion Protection Soil Control";
  if (subCategoryCode === EnvironmentSubcategories.EndangeredPlant) return "Endangered Plant";
  if (subCategoryCode === EnvironmentSubcategories.Other) return "Environmenta Other";
  // animal
  if (subCategoryCode === AnimalSubcategories.Bees) return "Bees";
  if (subCategoryCode === AnimalSubcategories.Snake) return "Snake";
  if (subCategoryCode === AnimalSubcategories.Dog) return "Dog";
  if (subCategoryCode === AnimalSubcategories.Bird) return "Large Bird";
  if (subCategoryCode === AnimalSubcategories.Bat) return "Bat";
  if (subCategoryCode === AnimalSubcategories.EndangeredAnimal) return "Endangered Animal";
  if (subCategoryCode === AnimalSubcategories.DeadAnimal) return "Dead Animal";
  if (subCategoryCode === AnimalSubcategories.Other) return "Other Animal";
  // herbicide
  if (subCategoryCode === HerbicideSubcategories.AquaticMixOnly) return "Aquatic Mix Only";
  if (subCategoryCode === HerbicideSubcategories.NoHerbicide) return "No Herbicide";
  if (subCategoryCode === HerbicideSubcategories.NoAerialSpray) return "No Aerial Spray";
  // workarea
  if (subCategoryCode === WorkAreaSubcategories.SepticLines) return "Septic Lines";
  if (subCategoryCode === WorkAreaSubcategories.VolatileSoils) return "Volatile Soil";
  if (subCategoryCode === WorkAreaSubcategories.Flooded) return "Flooded";
  if (subCategoryCode === WorkAreaSubcategories.FrozenGround) return "Frozen Ground";
  if (subCategoryCode === WorkAreaSubcategories.DangerousTerrain) return "Dangerous Terrain";
  if (subCategoryCode === WorkAreaSubcategories.Other) return "Other";
  // tree
  if (subCategoryCode === DangerTreeSubcategories.FallIn) return "Fall In";
  if (subCategoryCode === DangerTreeSubcategories.BlowIn) return "Blow In";
  if (subCategoryCode === DangerTreeSubcategories.GrowIn) return "Grow In";
  if (subCategoryCode === DangerTreeSubcategories.Disease) return "Disease";
  if (subCategoryCode === DangerTreeSubcategories.DyingTree) return "Dying Tree";
  if (subCategoryCode === DangerTreeSubcategories.DeadTree) return "Dead Tree";
  return "Unknown category";
};

export const sortByOrderNum = (item: { sortNumber: number }, item2: { sortNumber: number }) => {
  if (!item.sortNumber || !item2.sortNumber) {
    return 0;
  }
  return item.sortNumber - item2.sortNumber;
};
