export const CanopyGradient = () => {
  return (
    <svg width="120" height="240" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id="CanopyGradient">
          <stop offset="0%" stop-color="rgba(255, 26, 26, 0)" />
          <stop offset="63.02%" stop-color="rgba(244, 33, 33, 0)" />
          <stop offset="65.1%" stop-color="rgba(244, 33, 33, 0.13)" />
          <stop offset="100%" stop-color="rgba(244, 33, 33, 0.5)" />
        </radialGradient>
      </defs>
    </svg>
  );
};
