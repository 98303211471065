import { useCallback, useEffect, useState } from "react";
import { useMapSelectionStore, ASSET_TYPES, useParcelsStore } from "../../stores";
import { ParcelsResponseProperties } from "../../types/responses";

const useParcelDetails = () => {
  const [parcelDetails, setParcelDetails] = useState<Array<ParcelsResponseProperties | undefined>>([]);

  const parcelIds = useMapSelectionStore((store) => store.selectedLayers[ASSET_TYPES.PARCEL]);
  const setMultipleIds = useParcelsStore((store) => store.setMultipleSelectedIds);

  const getParcelById = useParcelsStore((store) => store.getById);

  const setInitialParcelDetails = useCallback(() => {
    const initialParcelProperties = parcelIds?.map((id) => getParcelById(id)?.properties) ?? [];
    setParcelDetails(initialParcelProperties);
  }, [getParcelById, parcelIds]);

  useEffect(() => {
    setInitialParcelDetails();
    parcelIds?.forEach((id) => {
      setMultipleIds(id);
    });
    return () => {
      setParcelDetails([]);
    };
  }, [getParcelById, parcelIds, setInitialParcelDetails, setMultipleIds, setParcelDetails]);

  return {
    parcelDetails,
    setParcelDetails,
    reset: setInitialParcelDetails,
  };
};

export default useParcelDetails;
