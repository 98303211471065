import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const ModalContent = styled.span<SpaceProps>`
  ${space}
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.trout};
  line-height: 25px;
`;

export const ModalContentValue = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: var(--colors-eastern-blue);
`;

export const ModalErrorMessage = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: var(--colors-persimmon);
`;

export const PushoutRequiredWrapper = styled.div`
  display: flex;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.black};
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  .pushout-no path {
    fill: ${(props) => props.theme.colors.white};
  }
  .pushout-no {
    height: 8px;
    width: 8px;
  }
  .pushout-yes {
    height: 7px;
    width: 10px;
  }
`;
