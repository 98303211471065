export const SPAN_STYLE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 5,
  opacity: 1,
  color: "#3CE3FF",
};

export const SPAN_STYLE_WORK_TYPE = {
  fillColor: "#2EB5D2",
  fillOpacity: 1,
  weight: 13,
  opacity: 1,
  color: "#2EB5D2",
};

export const SPAN_STYLE_HOVER_SELECTED = {
  fillColor: "var(--colors-white)",
  fillOpacity: 1,
  weight: 5,
  opacity: 1,
  color: "var(--colors-white)",
};

export const SPAN_STYLE_GROUP_SELECTED = {
  fillColor: "rgb(253, 202, 20)",
  weight: 5,
  color: "#3CE3FF",
};
