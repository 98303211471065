import React, { FC, ReactNode } from "react";
import { PaddingProps } from "styled-system";

import {
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  ModalClose,
  ModalHeader,
  ModalMessage,
  WarningImg,
} from "./ModalDialog.styled";
import { ReactComponent as Close } from "./../../assets/images/close_white.svg";
import Warning from "./../../assets/images/warning.svg";

interface ModalProperties extends PaddingProps {
  title?: string;
  onClose?: () => void;
  message?: string | ReactNode;
  showHeader?: boolean;
  warning?: boolean;
  hidden: boolean;
}

export const ModalDialog: FC<ModalProperties> = ({
  title = "",
  children,
  onClose = () => {},
  message,
  showHeader,
  warning,
  hidden,
  ...props
}) => {
  if (hidden) {
    return <></>;
  }
  return (
    <>
      <ModalOverlay />
      <ModalContainer {...props}>
        {showHeader && (
          <ModalHeader>
            {title && <ModalTitle>{title}</ModalTitle>}
            <ModalClose onClick={onClose}>
              <Close />
            </ModalClose>
          </ModalHeader>
        )}
        {warning && <WarningImg src={Warning} />}
        {message && <ModalMessage>{message}</ModalMessage>}
        {children}
      </ModalContainer>
    </>
  );
};
