import { FC, memo, useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  getStructureWorksheetApiUrl,
  getWorksheetsForStructuresUrl,
  getWorkTypesApiUrl,
  getCutPlanApiUrl,
} from "../../../../api";
import { Portal } from "../../../../components/Portal";
import { SelectionToolRHS } from "../../../../components/SelectionToolRHS/SelectionToolRHS";
import { useCustomQuery } from "../../../../hooks";
import { logError } from "../../../../services";
import {
  ASSET_TYPES,
  SelectedStructures,
  StructuresWithWorksheet,
  useFeederStore,
  useMapSelectionStore,
  useParcelsStore,
} from "../../../../stores";
import useStructuresStore from "../../../../stores/StructuresStore";
import {
  DeleteStructuresWorksheetsBulkRequestDto,
  DeleteStructureWorksheetRequestDto,
  MappedFeederGeoJson,
  StructureWorksheetBulkResponseDto,
  StructureWorksheetDto,
  UpdateStructuresWorksheetsBulkRequestDto,
  WorkTypeResponse,
} from "../../../../types/responses";
import ApiClient from "../../../../utils/apiClient";
import { highestCircuitVoltage, sortByVoltage } from "./SelectionToolMenu.utils";

export const SelectionToolMenu: FC = memo(() => {
  const selectedAsset = useMapSelectionStore((store) => store.selectedAssetType);
  const selectedFeatures = useMapSelectionStore((store) => store.selectedLayers);
  const structuresById = useStructuresStore((store) => store.structuresById);
  const { getFeederDataById: getFeederById } = useFeederStore((store) => store.actions);

  const queryClient = useQueryClient();

  const { data: workTypesResponse } = useCustomQuery<WorkTypeResponse[]>(getWorkTypesApiUrl());

  const workTypesById = useMemo(() => {
    if (!workTypesResponse) {
      return null;
    }
    const workTypesByIds: Record<string, WorkTypeResponse> = {};
    workTypesResponse.forEach((item) => {
      workTypesByIds[item.id] = item;
    });
    return workTypesByIds;
  }, [workTypesResponse]);

  const { data, refetch: refetchStructures } = useCustomQuery<StructureWorksheetBulkResponseDto>(
    getWorksheetsForStructuresUrl(),
    {
      enabled: selectedAsset === ASSET_TYPES.STRUCTURE && !!selectedFeatures?.[ASSET_TYPES.STRUCTURE]?.length,
      onSettled: () => {
        queryClient.invalidateQueries("structures");
      },
    },
    {
      structureIds: selectedFeatures?.[ASSET_TYPES.STRUCTURE]?.join(","),
    }
  );

  const deleteWorkTypeMutation = useMutation(
    ({ value, id }: { value: DeleteStructureWorksheetRequestDto; id: string }) =>
      ApiClient.delete(getStructureWorksheetApiUrl(id), { data: value })
  );

  const saveAllStructureChangesMutation = useMutation((value: UpdateStructuresWorksheetsBulkRequestDto) =>
    ApiClient.put(getWorksheetsForStructuresUrl(), value)
  );

  const deleteAllWorkTypesFromObject = useMutation((value: DeleteStructuresWorksheetsBulkRequestDto) =>
    ApiClient.delete(getWorksheetsForStructuresUrl(), { data: value })
  );

  const deleteAllWorkTypes = useCallback(() => {
    if (!selectedFeatures?.[ASSET_TYPES.STRUCTURE]?.length) {
      return;
    }
    deleteAllWorkTypesFromObject
      .mutateAsync({ structureIds: selectedFeatures?.[ASSET_TYPES.STRUCTURE] ?? [] })
      .then(() => {
        refetchStructures();
      })
      .catch((e) => logError(e));
  }, [refetchStructures, deleteAllWorkTypesFromObject, selectedFeatures]);

  const deleteWorkType = useCallback(
    (workTypeId: string, objectId: string) => {
      if (!workTypeId || !objectId) {
        return;
      }
      deleteWorkTypeMutation
        .mutateAsync({ value: { workTypeId: workTypeId }, id: objectId })
        .then(() => {
          refetchStructures();
        })
        .catch((e) => logError(e));
    },
    [refetchStructures, deleteWorkTypeMutation]
  );

  const saveAllWorkTypesForStructures = useCallback(
    (structures: Nullable<UpdateStructuresWorksheetsBulkRequestDto>) => {
      if (!structures) {
        return;
      }
      saveAllStructureChangesMutation
        .mutateAsync(structures)
        .then(() => {
          refetchStructures();
          queryClient.invalidateQueries(getCutPlanApiUrl());
        })
        .catch((e) => logError(e));
    },
    [saveAllStructureChangesMutation, refetchStructures, queryClient]
  );

  const selectedStructures = useMemo(() => {
    if (selectedAsset !== ASSET_TYPES.STRUCTURE || !selectedFeatures?.[ASSET_TYPES.STRUCTURE]?.length || !data) {
      return null;
    }

    const features: StructuresWithWorksheet = { totalCost: data?.totalCost ?? 0, items: [] };
    const items = selectedFeatures?.[ASSET_TYPES.STRUCTURE]?.map((structureId) => {
      const structure = structuresById[structureId];
      const structureCircuits: Maybe<MappedFeederGeoJson>[] = structure?.properties?.feederIds.map((feederId) =>
        getFeederById(feederId)
      );
      const filteredData = data?.structureWorksheets?.filter((item) => item.structureId === structureId);
      return {
        name: `${structure?.properties?.type?.name} ${structure?.properties?.metadata?.label}`,
        voltage: highestCircuitVoltage(structureCircuits),
        structureWithWorksheet: filteredData?.length
          ? filteredData.reduce((item) => item as StructureWorksheetDto)
          : {},
      } as SelectedStructures;
    });
    features.items = items?.sort(sortByVoltage) ?? [];
    return features;
  }, [selectedAsset, selectedFeatures, structuresById, getFeederById, data]);

  const isDrawerVisible = useMapSelectionStore((store) => store.isSelectionRHSDrawerVisible);
  const resetSelectionState = useMapSelectionStore((store) => store.resetState);
  const resetParcelState = useParcelsStore((store) => store.resetStore);

  const handleClose = () => {
    resetSelectionState();
    resetParcelState();
  };

  if (!selectedAsset || !isDrawerVisible) {
    return <></>;
  }

  return (
    <Portal selector="layer-menu-root">
      <SelectionToolRHS
        type={selectedAsset}
        isVisible={isDrawerVisible}
        closeDrawer={handleClose}
        cancelSelection={handleClose}
        saveAllChanges={saveAllWorkTypesForStructures}
        deleteAllWorkTypes={deleteAllWorkTypes}
        deleteWorkType={deleteWorkType}
        selectedStructures={selectedStructures ?? null}
        workTypeResponse={workTypesById}
      />
    </Portal>
  );
});
