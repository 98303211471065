import { FC, memo, useEffect } from "react";

import { getRiskPriorityColor } from "../../../pages/MapScreen/MapView/MapView.utils";
import { SpanRiskPriority } from "../../../stores";
import { TaxonomyItem } from "../../../types/TaxonomyItem";
import { StatusCircle } from "../StatusCircle";

import {
  ListEncroachment,
  EncroachmentItemsWrapper,
  EncroachmentName,
  EncroachmentId,
  EncroachmentHashTag,
} from "./TaxSubOptions.styled";

interface TaxSubOptionsProps {
  options: TaxonomyItem[];
  selectedItem: Nullable<string>;
  selectedSubItem: Nullable<string>;
  onSelect: (item: TaxonomyItem) => void;
  isTaxItemSelected: boolean;
  spanRiskPriority: SpanRiskPriority;
  setRef: (ref: Nullable<HTMLDivElement>) => void;
}

const OPTION_CLASS_PREFIX = "suboption-";

export const TaxSubOptions: FC<TaxSubOptionsProps> = ({ options, selectedSubItem, onSelect, setRef }) => {
  useEffect(() => {
    if (!selectedSubItem) {
      return;
    }
    const element = document.querySelector(`.${OPTION_CLASS_PREFIX}${selectedSubItem}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [selectedSubItem]);

  const encroachments = options.map((item, key) => (
    <EncroachmentItemsWrapper
      key={item.id}
      className={`${OPTION_CLASS_PREFIX}${item.id}`}
      selected={item.id === selectedSubItem}
      onClick={() => onSelect(item)}
    >
      <div>
        <EncroachmentId>
          <EncroachmentHashTag selected={item.id === selectedSubItem}>
            {`#${key}`} {item.code}
          </EncroachmentHashTag>
        </EncroachmentId>
        <EncroachmentName>{item.name.substring(0, 33)}</EncroachmentName>
      </div>
      <StatusCircle backgroundColor={getRiskPriorityColor(item.riskPriority)} />
    </EncroachmentItemsWrapper>
  ));

  if (!options) {
    return <></>;
  }

  return <ListEncroachment ref={setRef}>{encroachments}</ListEncroachment>;
};

export default memo(TaxSubOptions);
