import { Feature, Geometry, Point } from "geojson";
import { LatLng, LatLngExpression, LatLngTuple } from "leaflet";

import { getLayerImage } from "../../../components/MapAdditionalLayer/MapAdditionalLayer.utils";
import {
  FEET_UNIT_SHORT_NAME,
  VOLTAGE_UNIT_SHORT_NAME,
  LABELS,
  PERCENT_UNIT,
  ACRES_UNIT_NAME,
} from "../../../constants";
import { FORECAST_QUERY_PARAMS } from "../../../constants/navigation";
import { ContentType } from "../../../stores/DrawerStore/DrawerStore.types";
import { AdditionalLayer, ADDITIONAL_LAYER_TYPE } from "../../../stores/MapStore/MapStore.types";
import { SwitchesById } from "../../../stores/SwitchesStore";
import {
  EncroachmentResponse,
  EncroachmentResponseProperties,
  FeedersResponse,
  FeedersResponseProperties,
  MappedFeedersGeoJSON,
  SpansResponse,
  SpansResponseProperties,
} from "../../../types/responses";
import { FeederSegment, FeederSegmentResponse } from "../../../types/responses/FeederSegmentResponse";
import { Parcel } from "../../../types/responses/ParcelsResponse";
import { Structure, StructuresProperties, StructuresResponse } from "../../../types/responses/StructuresResponse";
import { getQueryParamValue } from "../../../utils/navigationUtils";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import { getUnitPluralName, roundValue, squareMetersToAcres, unitConverter } from "../../../utils/unitHelper";
import { EncroachmentPositions } from "../MapScreen.utils";
import { getRiskPriorityColor } from "../MapView/MapView.utils";

export const MISSING_VALUE = "N/A";

const getElevationValue = (elevation: Maybe<string>) => {
  if (!elevation) {
    return MISSING_VALUE;
  }

  return `${unitConverter(true, Number(elevation), 0)} ${FEET_UNIT_SHORT_NAME}`;
};

const getAreaValue = (area: Maybe<Nullable<number>>) => {
  if (!area) {
    return MISSING_VALUE;
  }

  const acres = squareMetersToAcres(area, 0);

  return `${acres} ${getUnitPluralName(ACRES_UNIT_NAME, acres)}`;
};

const getVoltageValue = (voltage: Maybe<string | number>) => {
  if (!voltage) {
    return MISSING_VALUE;
  }

  return `${Number(voltage).toFixed(0)} ${VOLTAGE_UNIT_SHORT_NAME}`;
};

const getUrbanAreaValue = (isUrban: Maybe<Nullable<boolean>>) => {
  if (isUrban === undefined || isUrban === null) {
    return MISSING_VALUE;
  }

  return isUrban ? LABELS.urban : LABELS.rural;
};

const getCoordinatesValue = (coordinates: Maybe<number[]>) => {
  if (!coordinates) {
    return MISSING_VALUE;
  }

  return `${Number(coordinates[0]).toFixed(4)}, ${Number(coordinates[1]).toFixed(4)}`;
};

export type IntersectedLayers = { name: string; key: string; image?: string }[];

export type SectionLinks = Maybe<{ type: ContentType; id: string; value: string }[]>;

export type SectionData = Nullable<Record<string, string | SectionLinks | IntersectedLayers | string[] | boolean>>;

export const getSpanContent = (
  span: Nullable<SpansResponseProperties>,
  feeders: Nullable<FeedersResponse>,
  coordinates: Nullable<LatLng>,
  structuresGeoJSON: Nullable<StructuresResponse>,
  center: Maybe<Nullable<L.LatLngExpression>>,
  switchesById: SwitchesById,
  getParcelById: (id: string) => Nullable<Parcel>,
  layers: Nullable<Record<string, AdditionalLayer>>,
  urbanLayerId: Nullable<string>,
  feederSegment: Nullable<FeederSegment>
): Nullable<Record<string, any>> => {
  if (!span) {
    return null;
  }

  const feeder =
    feeders &&
    feederSegment &&
    feeders.features.find((feeder) => feeder.properties.id === feederSegment?.properties.feederId);
  const fromStructure =
    structuresGeoJSON &&
    structuresGeoJSON.features.find((structure) => structure.properties.id === span.fromStructureId);

  const toStructure =
    structuresGeoJSON && structuresGeoJSON.features.find((structure) => structure.properties.id === span.toStructureId);

  const structuresWithSwitches: Feature<Point, StructuresProperties>[] = [];
  if (feederSegment?.properties?.leftSwitchId) {
    if (switchesById[feederSegment?.properties?.leftSwitchId]) {
      const structure = structuresGeoJSON?.features.find(
        (item) => item.properties.id === switchesById[feederSegment?.properties?.leftSwitchId!].properties.structureId
      );
      structure && structuresWithSwitches.push(structure);
    }
  }
  if (feederSegment?.properties?.rightSwitchId) {
    if (switchesById[feederSegment?.properties?.rightSwitchId]) {
      const structure = structuresGeoJSON?.features.find(
        (item) => item.properties.id === switchesById[feederSegment?.properties?.rightSwitchId!].properties.structureId
      );
      structure && structuresWithSwitches.push(structure);
    }
  }
  const parcels = getSpanParcels(span?.parcelIds?.map((id) => getParcelById(id)).filter(Boolean) as Parcel[]);

  return {
    sectionName: span.name,
    viewPlan: true,
    id: span.id,
    ...getSpanInfoItems(
      span,
      coordinates ?? (createLatLng(center as LatLngTuple) as LatLng),
      feeder,
      fromStructure,
      toStructure,
      structuresWithSwitches,
      parcels,
      urbanLayerId
    ),
    ...getSpanRiskInfoItems(span, layers),
  };
};

export const getParcelContent = ({ parcel }: { parcel: Nullable<Parcel> }) => {
  return {
    sectionName: `Parcel Info`,
    ...getParcelItems({ parcel }),
  };
};

const getParcelItems = ({ parcel }: { parcel: Nullable<Parcel> }) => {
  const data: Record<string, any> = [];

  data.parcelId = parcel?.properties?.parcelId ? String(parcel?.properties?.parcelId) : MISSING_VALUE;
  data.ownerFullName = getParcelOwnerFullName({
    firstName: parcel?.properties?.ownerFirstName,
    lastName: parcel?.properties?.ownerLastName,
  });

  data.address = parcel?.properties?.address ?? MISSING_VALUE;
  data.city = parcel?.properties?.city ?? MISSING_VALUE;
  data.zip = parcel?.properties?.zip ?? MISSING_VALUE;
  data.state = parcel?.properties?.state ?? MISSING_VALUE;
  data.status = parcel?.properties?.status;
  data.note = parcel?.properties.note;
  data.id = parcel?.properties.id;
  data.ownerFirstName = parcel?.properties?.ownerFirstName;
  data.ownerLastName = parcel?.properties?.ownerLastName;
  data.contactEmail = parcel?.properties?.contactEmail;
  data.contactPhone = parcel?.properties?.contactPhone;
  data.ownerSameAsOnSiteContact = parcel?.properties?.ownerSameAsOnSiteContact;
  data.onSiteOwnerPhone = parcel?.properties?.onSiteOwnerPhone;
  data.onSiteOwnerEmail = parcel?.properties?.onSiteOwnerEmail;
  data.onSiteOwnerLastName = parcel?.properties?.onSiteOwnerLastName;
  data.onSiteOwnerFirstName = parcel?.properties?.onSiteOwnerFirstName;

  return data;
};

const getParcelOwnerFullName = ({ firstName, lastName }: { firstName: Maybe<string>; lastName: Maybe<string> }) => {
  if (!firstName && !lastName) {
    return MISSING_VALUE;
  }

  return [firstName, lastName].filter(Boolean).join(" ");
};

export const getCustomPolygonContent = (
  encroachment: Maybe<EncroachmentResponse>,
  feeders: Nullable<FeedersResponse>,
  id: string,
  coordinates: Nullable<LatLng>,
  encroachmentsPositions: Nullable<EncroachmentPositions>,
  feederSegments: Nullable<FeederSegmentResponse>
) => {
  if (!encroachment || !id) {
    return null;
  }

  const encroachmentCoordinates = coordinates
    ? (coordinates as LatLng)
    : (getEncroachmentCoordinates(id, encroachmentsPositions) as LatLng);
  const feedersBySpans = getFeedersBySpans(encroachment.properties.spanIds, feeders, feederSegments);

  return {
    sectionName: "Custom Work Container",
    ...getEncroachmentItems(encroachment.properties, encroachmentCoordinates, feedersBySpans),
  };
};

export const getEncroachmentContent = (
  encroachment: Nullable<EncroachmentResponse>,
  feeders: Nullable<FeedersResponse>,
  id: string,
  coordinates: Nullable<LatLng>,
  encroachmentsPositions: Nullable<EncroachmentPositions>,
  feederSegments: Nullable<FeederSegmentResponse>
) => {
  if (!encroachment || !id) {
    return null;
  }

  const encroachmentCoordinates = coordinates
    ? (coordinates as LatLng)
    : (getEncroachmentCoordinates(id, encroachmentsPositions) as LatLng);
  const feedersBySpans = getFeedersBySpans(encroachment.properties.spanIds, feeders, feederSegments);

  return {
    sectionName: "Vegetation Infringement",
    ...getEncroachmentItems(encroachment.properties, encroachmentCoordinates, feedersBySpans),
  };
};

export const getStructureContent = ({
  structure,
  feeders = null,
  spans,
  isSwitch,
}: {
  structure: Nullable<Structure>;
  feeders: Nullable<FeedersResponse>;
  spans: Nullable<SpansResponse>;
  isSwitch: boolean;
}): Nullable<Record<string, any>> => {
  const structureProperties = structure?.properties;
  const coordinates = structure?.geometry?.coordinates;
  const structureCircuits = structureProperties?.feederIds
    .map((feederId) => getCircuitById(feeders, feederId))
    .filter(Boolean) as Feature<Geometry, FeedersResponseProperties>[];

  const sectionName = isSwitch
    ? `${structureProperties?.type?.name ?? "Structure "} with Switches`
    : structureProperties?.type?.name;

  return {
    sectionName,
    ...getStructureItems({
      structure: structureProperties,
      circuits: getAffectedCircuits(structureCircuits),
      spans: getAffectedSpans(getStructureSpans(spans, structureProperties?.id)),
      coordinates,
      isSwitch,
    }),
  };
};

const getStructureItems = ({
  structure,
  circuits,
  spans,
  coordinates,
  isSwitch,
}: {
  structure: Maybe<StructuresProperties>;
  circuits: ReturnType<typeof getAffectedCircuits>;
  spans: ReturnType<typeof getAffectedSpans>;
  coordinates: Maybe<number[]>;
  isSwitch: boolean;
}) => {
  const highestCircuitsVoltage: number =
    circuits
      ?.map((circuit) => Number(circuit.voltage))
      .reduce((curr, prev) => {
        if (curr > prev) {
          return curr;
        }
        return prev;
      }, 0) || 0;
  const data = {} as Record<string, any>;
  data.structureStatus = structure?.metadata?.status ?? MISSING_VALUE;
  data.structureType = structure?.type?.name ?? MISSING_VALUE;
  data.structureLabel = structure?.metadata?.label ?? MISSING_VALUE;
  data.id = structure?.id;
  if (isSwitch) {
    data.hasSwitches = "Yes";
  }
  data.circuits = circuits;
  data.spans = spans;
  data.voltage = getVoltageValue(highestCircuitsVoltage);
  data.elevation = getElevationValue(structure?.metadata?.elevation);
  data.demographic = getUrbanAreaValue(structure?.metadata?.isUrban);
  data.coordinates = getCoordinatesValue(coordinates);

  return data;
};

const getStructureSpans = (spans: Nullable<SpansResponse>, id: Maybe<string>) =>
  spans?.features.filter((span) => span.properties.fromStructureId === id || span.properties.toStructureId === id);

const getAffectedSpans = (spans: Maybe<Nullable<Feature<Geometry, SpansResponseProperties>[]>>) => {
  if (!spans || !spans.length) {
    return;
  }

  return spans.map((item) => ({
    value: item?.properties?.name || "",
    id: item.properties.id,
    type: ContentType.SPAN,
  }));
};

const getEncroachmentItems = (
  encroachment: EncroachmentResponseProperties,
  coordinates: Nullable<LatLng>,
  affectedFeeders: Nullable<Feature<Geometry, FeedersResponseProperties>[]>
) => {
  const data: Record<string, any> = {};
  data.infringementId = encroachment.code ?? MISSING_VALUE;
  data.circuits = getAffectedCircuits(affectedFeeders);
  data.treeHeightAvg = getElevationValue(encroachment.metadata?.treeHeightAvg);
  data.treeHeightMax = getElevationValue(encroachment.metadata?.treeHeightMax);
  data.treeCount = encroachment.metadata?.treeCount?.toString() ?? MISSING_VALUE;
  data.elevation = getElevationValue(encroachment.metadata?.elevationAvg);
  data.demographic = getUrbanAreaValue(affectedFeeders && affectedFeeders.pop()?.properties.isUrban);
  data.coordinates = getCoordinatesLabel(coordinates);
  data.riskPriority = encroachment?.riskPriority ? capitalizeFirstLetter(encroachment.riskPriority) : MISSING_VALUE;
  data.riskPriorityColor = getRiskPriorityColor(encroachment?.riskPriority);
  data.treeSpecies = encroachment.metadata?.species;
  data.pushout = encroachment.pushout ? "YES" : "NO";
  data.id = encroachment.id;

  return data;
};

export const getCircuitById = (feeders: Nullable<FeedersResponse>, feederId: string) => {
  if (!feeders) {
    return;
  }

  return feeders.features.find((item) => item.properties.id === feederId);
};

export const getFeedersBySpans = (
  spanIds: string[],
  feeders: Nullable<FeedersResponse>,
  feederSegments: Nullable<FeederSegmentResponse>
): Feature<Geometry, FeedersResponseProperties>[] | null => {
  if (!spanIds || !feederSegments?.features || !feeders?.features) {
    return null;
  }

  const filteredFeeders = feederSegments.features
    .filter((item) => spanIds.find((span) => span === item.properties.spanId))
    .map((item) => item.properties.feederId);

  return Array.from(
    new Set(feeders.features.filter((feeder) => filteredFeeders.find((item) => item === feeder.properties.id)))
  );
};

const getAffectedCircuits = (circuits?: Nullable<Feature<Geometry, FeedersResponseProperties>[]>) => {
  if (!circuits) {
    return;
  }

  return circuits.map((item) => ({
    value: item?.properties?.name || "",
    id: item.properties.id,
    type: ContentType.CIRCUIT,
    voltage: item.properties.voltage,
  }));
};

const getCoordinatesLabel = (coordinates: LatLng | null) => {
  if (!coordinates) {
    return MISSING_VALUE;
  }
  return `${coordinates?.lng?.toFixed(4)} / ${coordinates?.lat?.toFixed(4)}`;
};

export const getEncroachmentCoordinates = (
  id: string,
  encroachments: Nullable<EncroachmentPositions>
): Nullable<LatLngExpression> => {
  return encroachments && encroachments[id] && createLatLng(encroachments[id].center as LatLngTuple);
};

export const getFeedersCoordinates = (
  id: string,
  feeders: Nullable<MappedFeedersGeoJSON>
): Nullable<LatLngExpression> => {
  return feeders && feeders[id] && createLatLng(feeders[id].center as LatLngTuple);
};

export const createLatLng = (coordinates: Nullable<LatLngTuple>) => {
  if (!coordinates) {
    return null;
  }

  return { lat: coordinates[0], lng: coordinates[1] };
};

export const getUrlParam = (contentType: ContentType) => {
  switch (contentType) {
    case ContentType.SPAN:
      return FORECAST_QUERY_PARAMS.SPAN_ID;
    case ContentType.ENCROACHMENT:
      return FORECAST_QUERY_PARAMS.ENCROACHMENT_ID;
    case ContentType.CIRCUIT:
      return FORECAST_QUERY_PARAMS.FEEDER_ID;
    case ContentType.STRUCTURE:
      return FORECAST_QUERY_PARAMS.STRUCTURE_ID;
    case ContentType.PARCEL:
      return FORECAST_QUERY_PARAMS.PARCEL_ID;
    default:
      //TODO resolve default
      return "";
  }
};

export const getSelectedObjectFromQueryParams = (queryParams: URLSearchParams) => {
  const encroachmentId = getQueryParamValue(FORECAST_QUERY_PARAMS.ENCROACHMENT_ID);
  const feederId = getQueryParamValue(FORECAST_QUERY_PARAMS.FEEDER_ID);
  const isDrawerVisible = getQueryParamValue(FORECAST_QUERY_PARAMS.VISIBLE_DRAWER);
  const structureId = getQueryParamValue(FORECAST_QUERY_PARAMS.STRUCTURE_ID);
  const spanId = getQueryParamValue(FORECAST_QUERY_PARAMS.SPAN_ID);
  const parcelId = getQueryParamValue(FORECAST_QUERY_PARAMS.PARCEL_ID);

  const selectedObject = {
    type: "" as ContentType,
    id: "",
  };
  //Only if drawer is open has effect
  if (!isDrawerVisible) {
    return null;
  }

  if (feederId) {
    selectedObject.type = ContentType.CIRCUIT;
    selectedObject.id = feederId;
  }

  if (spanId) {
    selectedObject.type = ContentType.SPAN;
    selectedObject.id = spanId;
  }

  if (encroachmentId) {
    selectedObject.type = ContentType.ENCROACHMENT;
    selectedObject.id = encroachmentId;
  }

  if (structureId) {
    selectedObject.type = ContentType.STRUCTURE;
    selectedObject.id = structureId;
  }

  if (parcelId) {
    selectedObject.type = ContentType.PARCEL;
    selectedObject.id = parcelId;
  }

  if (!selectedObject.id || !selectedObject.type) {
    return null;
  }

  return selectedObject;
};

const getSpanInfoItems = (
  span: Maybe<SpansResponseProperties>,
  coordinates: Nullable<LatLng>,
  feeder: Maybe<Nullable<Feature<Geometry, FeedersResponseProperties>>>,
  fromStructure: Maybe<Nullable<Feature<Geometry, StructuresProperties>>>,
  toStructure: Maybe<Nullable<Feature<Geometry, StructuresProperties>>>,
  structuresWithSwitches: Feature<Point, StructuresProperties>[],
  spanParcels: ReturnType<typeof getSpanParcels>,
  urbanLayerId: Nullable<string>
): Record<string, any> => {
  const data: Record<string, any> = {};
  if (!span) {
    return data;
  }
  const structures: { value: string; id: string; type: ContentType }[] = [];
  if (fromStructure) {
    structures.push({
      value: fromStructure.properties.metadata?.label?.toString(),
      id: fromStructure.properties.id,
      type: ContentType.STRUCTURE,
    });
  }
  if (toStructure) {
    structures.push({
      value: toStructure.properties.metadata?.label?.toString(),
      id: toStructure.properties.id,
      type: ContentType.STRUCTURE,
    });
  }
  data.id = span.id;
  const isUrban =
    span.intersectedLayerIds &&
    urbanLayerId !== null &&
    span.intersectedLayerIds.find((layer) => layer === urbanLayerId) !== null;

  const circuits = [
    {
      id: feeder?.properties.id,
      value: feeder?.properties?.name || MISSING_VALUE,
      type: ContentType.CIRCUIT,
    },
  ];

  data.spanName = span.name;
  data.spanLength = `${unitConverter(true, Number(span?.length), 0)} ${FEET_UNIT_SHORT_NAME}`;
  data.structures = structures;
  data.switches = getSwitches(structuresWithSwitches);
  data.parcels = spanParcels;
  data.circuitName = feeder && feeder.properties.name ? feeder.properties.name : MISSING_VALUE;
  data.circuits = circuits;
  data.voltage = getVoltageValue(feeder?.properties.voltage);
  data.elevation = getElevationValue(span?.elevation);
  data.elevationMin = getElevationValue(span?.elevationMin);
  data.elevationMax = getElevationValue(span?.elevationMax);
  data.demographic = getUrbanAreaValue(!!isUrban);
  data.coordinates = getCoordinatesLabel(coordinates);
  data.treeSpecies = span?.treeSpecies;
  data.rowArea = getAreaValue(span.rowArea);
  const area = calculateRowAreaInPercents(span.rowArea, span.rowWorkableArea);
  data.rowWorkableArea = area ? `${area}${PERCENT_UNIT}` : MISSING_VALUE;

  return data;
};

const getSpanRiskInfoItems = (
  span: Maybe<SpansResponseProperties>,
  layers: Nullable<Record<string, AdditionalLayer>>
): Record<string, any> => {
  const data: Record<string, any> = [];
  if (!span) {
    return data;
  }

  let intersectedLayers: IntersectedLayers = [];
  if (layers) {
    intersectedLayers = Object.keys(layers)
      .filter((key) => span?.intersectedLayerIds?.find((id) => id === layers[key].id))
      .map((key) => ({ name: layers[key].name, key: key, image: getLayerImage(key as ADDITIONAL_LAYER_TYPE) }));
  }

  data.riskPriority = span.riskPriority ? capitalizeFirstLetter(span.riskPriority) : MISSING_VALUE;
  data.riskPriorityColor = getRiskPriorityColor(span.riskPriority);
  data.riskScore = span.riskScore ? String(span.riskScore) : MISSING_VALUE;
  data.intersectedLayers = intersectedLayers ?? MISSING_VALUE;

  return data;
};

const getSwitches = (structuresWithSwitches: Feature<Point, StructuresProperties>[]) => {
  if (structuresWithSwitches?.length === 0) {
    return undefined;
  }

  return structuresWithSwitches.map((switchItem) => {
    return {
      type: ContentType.STRUCTURE,
      value: switchItem.properties?.metadata?.label,
      id: switchItem.properties?.id,
    };
  });
};

const getSpanParcels = (parcels: Parcel[] | null) => {
  if (!parcels?.length) {
    return undefined;
  }

  return parcels.map((parcel) => ({
    type: ContentType.PARCEL,
    value: String(parcel?.properties?.parcelId) || MISSING_VALUE,
    id: parcel?.properties.id,
  }));
};

export const calculateRowAreaInPercents = (rowArea: Maybe<Nullable<number>>, workableArea: Maybe<Nullable<number>>) => {
  if (!rowArea || !workableArea) {
    return null;
  }
  if (workableArea <= 0 || rowArea <= 0) {
    return 0;
  }

  return roundValue((workableArea / rowArea) * 100, 0);
};

const endsWithAny = (arr: string[], str: string) => {
  return arr.some((suffix: string) => str.endsWith(suffix));
};

const imageFileTypes = ["jpg", "jpeg", "png"];
const pdfFileTypes = [
  "doc",
  "txt",
  "docx",
  "doc",
  "odt",
  "pages",
  "rtf",
  "ppt",
  "pptx",
  "odp",
  "key",
  "xls",
  "xlsx",
  "numbers",
  "ods",
  "pdf",
];

export const getFileType = (fileName: string) => {
  if (endsWithAny(imageFileTypes, fileName)) return "IMAGE";
  if (endsWithAny(pdfFileTypes, fileName)) return "PDF";
  return "VIDEO";
};
