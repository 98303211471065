import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { regionGroupsUrl } from "../api";
import { logError } from "../services";

export interface RegionsResponse {
  regionGroups: {
    id: string;
    name: string;
    regions: [{ id: string; name: string }];
  }[];
}

export const useRegions = () => {
  return useQuery(
    "regions",
    () => ApiClient.get<RegionsResponse>(regionGroupsUrl).then((response) => response.data.regionGroups),
    {
      onError: (e) => logError(e),
    }
  );
};

export default useRegions;
