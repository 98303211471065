import { WorkTypeResponse } from "./WorkTypeResponse";

export type CrewRoleRegion = {
  regionGroupId: string;
  straightTimeBillingRate: number;
  overtimeTimeBillingRate: number;
};

export type CrewConfig = {
  numberOfPeople: number;
  costByRegions: CrewRoleRegion[];
  roleId: string;
  roleName: string;
};

export type Crew = {
  id: string;
  name: string;
  configuration: CrewConfig[];
  compatibleWorkTypes?: { id: string; name: string }[];
  updated?: string;
};

export type CrewConfiguration = {
  crews: Crew[];
};

export enum ConfigType {
  STRAIGHT = "straight-billing-rate",
  OVERTIME = "overtime-billing-rate",
}

export type ValueType =
  | { contractorRoleId: string; regionGroupId: string; billingRate: number; overtime: boolean }
  | { numberOfPeople: number };

export type CrewType = {
  crewMemberTypeIds: string[];
  equipmentTypeIds: string[];
  id: string;
  name: string;
  workTypeIds: [];
  workTypeScope: "STRUCTURE" | "SPAN";
};

export type CrewTypeResponse = {
  crewTypes: CrewType[];
};

export type MemberType = {
  id: string;
  name: string;
  workTypeScope: "STRUCTURE" | "SPAN";
};

export type MemberTypeResponse = {
  crewMemberTypes: MemberType[];
};

export type EquipmentType = {
  id: string;
  name: string;
};

export type EquipmentTypeResponse = {
  equipmentTypes: EquipmentType[];
};

export type CrewTypeWithMembersAndEquipment = {
  crewMemberTypes: MemberType[];
  equipmentTypes: EquipmentType[];
  id: string;
  name: string;
  workTypes: WorkTypeResponse[];
  workTypeScope: "STRUCTURE" | "SPAN";
};
