import styled from "styled-components";

export const WorkTypeItem = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--colors-mineral-green);
  div:first-of-type {
    margin: 0px;
  }
`;

export const WorkTypeCostWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const WorkTypeCostItem = styled.div``;

export const WorkTypeCostDelete = styled.img`
  margin-left: 12px;
  cursor: pointer;
  display: none;
  ${WorkTypeItem}:hover & {
    display: block;
  }
`;
export const WorkTypesWrapper = styled.div<{ bottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 9px;
  ${WorkTypeItem}:first-of-type {
    margin-top: 9px;
  }
`;

export const WorkTypesContainer = styled.div`
  ${WorkTypesWrapper}:not(:first-of-type) {
    margin-top: 10px;
  }
`;

export const NoWorkTypesWrapper = styled.div`
  border-top: 1px solid var(--colors-gallery);
  border-bottom: 1px solid var(--colors-gallery);
  padding: 14px 0px;
`;
