type StateType = "MIN" | "MAX" | "CLEAR";
interface ReducerState {
  minValue: string;
  maxValue: string;
}

export interface ErrorReducerState {
  minValueError: boolean;
  maxValueError: boolean;
}

export type ReducerAction = { type: StateType; payload: string };

type ErrorReducerAction = { type: StateType; payload?: boolean };

export const valueReducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case "MIN":
      return { minValue: action.payload, maxValue: state.maxValue };
    case "MAX":
      return { maxValue: action.payload, minValue: state.minValue };
    case "CLEAR":
      return { minValue: "", maxValue: "" };
    default:
      return state;
  }
};

export const errorReducer = (state: ErrorReducerState, action: ErrorReducerAction) => {
  if (action.payload === undefined) {
    return state;
  }
  switch (action.type) {
    case "MIN":
      return { minValueError: action.payload, maxValueError: state.maxValueError };
    case "MAX":
      return { maxValueError: action.payload, minValueError: state.minValueError };
    case "CLEAR":
      return { minValueError: false, maxValueError: false };
    default:
      return state;
  }
};
