import React, { FC } from "react";

import { Portal } from "../Portal";
import {
  CloseImageWrapper,
  ImageWrapper,
  MapMeasurePopupCloseImage,
  MapMeasurePopupContent,
  MapMeasurePopupImage,
  MapMeasurePopupLabel,
  MapMeasurePopupLabelWrapper,
  MapMeasurePopupWrapper,
} from "./MapMeasurePopup.styled";
import MeasureDistanceImg from "./../../assets/images/measure_distance.svg";
import MeasureAreaImg from "./../../assets/images/measure_area.svg";
import Close from "./../../assets/images/close_white.svg";
import Replay from "./../../assets/images/replay.svg";
import { ACRES_UNIT_NAME_SHORT } from "../../constants";

interface MapMeasurePopupProps {
  hidden: boolean;
  area: Nullable<number>;
  restartDrawing: () => void;
  cancelDrawing: () => void;
  distance: Nullable<number>;
  distanceUnit: Nullable<string>;
  areaUnit?: string;
  rightMenuEnabled?: boolean;
  layerMenuEnabled?: boolean;
  leftMenuEnabled?: boolean;
}

export const MapMeasurePopup: FC<MapMeasurePopupProps> = ({
  hidden,
  area,
  distance,
  restartDrawing,
  cancelDrawing,
  distanceUnit,
  areaUnit = ACRES_UNIT_NAME_SHORT,
  rightMenuEnabled,
  leftMenuEnabled,
  layerMenuEnabled,
}) => {
  if (hidden) {
    return <></>;
  }

  return (
    <Portal selector="layer-menu-root">
      <MapMeasurePopupContent
        rhsEnabled={rightMenuEnabled}
        lhsEnabled={leftMenuEnabled}
        layerMenuEnabled={layerMenuEnabled}
      >
        <MapMeasurePopupWrapper>
          <ImageWrapper>
            <MapMeasurePopupImage src={area ? MeasureAreaImg : MeasureDistanceImg} />
          </ImageWrapper>
          <MapMeasurePopupLabelWrapper textAlign={area && distance ? "left" : "center"}>
            {area && (
              <MapMeasurePopupLabel>
                Area: <span>{area} </span> {areaUnit}
              </MapMeasurePopupLabel>
            )}
            {distance && (
              <MapMeasurePopupLabel>
                Distance: <span>{distance}</span> {distanceUnit}
              </MapMeasurePopupLabel>
            )}
            {!distance && !area && <MapMeasurePopupLabel>Click area to calculate distance</MapMeasurePopupLabel>}
          </MapMeasurePopupLabelWrapper>
          <CloseImageWrapper onClick={distance ? restartDrawing : cancelDrawing}>
            <MapMeasurePopupCloseImage src={distance ? Replay : Close} />
          </CloseImageWrapper>
        </MapMeasurePopupWrapper>
      </MapMeasurePopupContent>
    </Portal>
  );
};
