export enum PLAN_STATUS {
  Active = "Active",
  Draft = "Draft",
  Past_Due = "Past Due",
  Complete = "Complete",
  Archived = "Archived",
}

export type PlanDto = {
  id: string;
  name: string;
  start: string;
  end: string;
  updated: string;
  status: PLAN_STATUS;
};
