import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FORECAST_QUERY_PARAMS } from "../constants/navigation";
import { getImagerySourceImage } from "../pages/MapScreen/MapView/MapView.utils";
import { getForecastRoute } from "../routes";
import { useMapStore } from "../stores";
import { LAYER_SWITCH_CONTROL } from "../stores/MapStore";
import {
  getSearchParams,
  resetToRegionLevelSearchParams,
  deleteSpanRiskPriorityParam,
  setVisibleDrawerParam,
} from "../utils/navigationUtils";

export const useImagerySource = () => {
  const selectedImagerySourceId = useMapStore((store) => store.selectedImagerySourceId);
  const setOverlaySwitchState = useMapStore((store) => store.setOverlaySwitchState);
  const imagerySources = useMapStore((store) => store.imagerySources);
  const overlaySwitchControl = useMapStore((store) => store.overlaySwitchControl);

  const queryParams = getSearchParams();
  const history = useHistory();

  const changeImagerySource = (key: string) => {
    queryParams.set(FORECAST_QUERY_PARAMS.SOURCE_ID, key);
    setVisibleDrawerParam({ params: queryParams, visible: false });
    deleteSpanRiskPriorityParam({ params: queryParams });
    resetToRegionLevelSearchParams(queryParams);
    history.push({ pathname: getForecastRoute(), search: queryParams.toString() });
  };

  const toggleLayer = useCallback(() => {
    setOverlaySwitchState({
      name: LAYER_SWITCH_CONTROL.IMAGERY_SOURCES,
      value: !overlaySwitchControl.IMAGERY_SOURCES.checked,
    });
  }, [overlaySwitchControl, setOverlaySwitchState]);

  const imagerySourceOptions = useMemo(
    () =>
      Object.keys(imagerySources)
        .sort((a, b) => imagerySources[a].ordinal - imagerySources[b].ordinal)
        .map((sourceKey) => ({
          name: imagerySources[sourceKey].name,
          key: imagerySources[sourceKey].id,
          checked: imagerySources[sourceKey].id === selectedImagerySourceId,
          image: getImagerySourceImage(imagerySources[sourceKey].resolution),
        })),
    [imagerySources, selectedImagerySourceId]
  );

  return {
    data: imagerySourceOptions,
    changeImagerySource,
    toggleLayer,
    checked: overlaySwitchControl.IMAGERY_SOURCES.checked,
    showSwitch: overlaySwitchControl.IMAGERY_SOURCES.showSwitch,
  };
};
