import React, { FC, ReactNode, useCallback, useState } from "react";

import { Checkbox } from "../Checkbox";
import { Radio } from "../Radio";
import { Portal } from "../Portal";
import Close from "../../assets/images/close.svg";
import LayerMenuIcon from "../../assets/images/layers_menu.svg";

import {
  ExpandCollapseButton,
  LayersMenuClose,
  LayersMenuContainer,
  LayersMenuContent,
  LayersMenuContentTitle,
  LayersMenuLabelWrapper,
  LayersMenuOption,
  LayersMenuTitle,
  LayersMenuTitleWrapper,
  LayersMenuWrapper,
  LayersMenuItemWrapper,
  LayersMenuList,
} from "./LayersMenu.styled";
import { SwitchButton } from "../SwitchButton";
import { RangeInput } from "../RangeInput";
import ArrowUp from "../../assets/images/menu_arrow_up.svg";
import ArrowDown from "../../assets/images/menu_arrow_down.svg";
import { EMPTY_FN } from "../../constants";
import { LayerRow } from "../../types/Layers";

const INITIAL_RANGE = 0.8;

const ENVIRONMENTAL_BOX_SHADOW = "0px 2px 2px rgba(31, 167, 179, 0.29);";

const DEFAULT_BOX_SHADOW = "0px 4px 4px rgba(0, 0, 0, 0.09);";
interface LayersMenuProps {
  children: ReactNode;
  isLayerControlVisible: boolean;
  toggleLayerControl: () => void;
  isRightDrawerVisible: boolean;
  clearStack: () => void;
}
export const LayersMenu: FC<LayersMenuProps> = ({
  children,
  isLayerControlVisible,
  toggleLayerControl,
  isRightDrawerVisible,
  clearStack,
}) => (
  <Portal selector="layer-menu-root">
    <LayersMenuContainer
      id="layers"
      isRightDrawerVisible={isRightDrawerVisible}
      isLayerControlVisible={isLayerControlVisible}
      onClick={() => {
        // close control only by clicking on X
        toggleLayerControl();
        if (!isLayerControlVisible) {
          clearStack();
        }
      }}
    >
      <LayersMenuContent onClick={(e) => e.stopPropagation()} isLayerControlVisible={isLayerControlVisible}>
        {children}
      </LayersMenuContent>
    </LayersMenuContainer>
  </Portal>
);

interface MenuSectionProps {
  title: string;
  data?: Nullable<LayerRow[]>;
  onClick: ({ name, key, value }: { name: string; key: string; value: boolean | string }) => void;
  type: "single-select" | "multiple-select";
  showSwitch?: boolean;
  isSwitchChecked?: boolean;
  switchOverlay?: () => void;
  additionalOverlayData?: {
    showSlideControl: boolean;
    onChangeSlideControl: ({ key, value }: { key: string; value: number }) => void;
    defaultValue?: number;
    selectedAdditionalOverlay: string | null;
  };
  isExpandedByDefault?: boolean;
  disabled?: boolean;
  navButtons?: React.ReactNode | React.ReactNode[];
}

export const MenuSection: FC<MenuSectionProps> = ({
  title,
  data,
  type,
  onClick,
  isSwitchChecked,
  switchOverlay = EMPTY_FN,
  additionalOverlayData,
  showSwitch = false,
  isExpandedByDefault = false,
  disabled = false,
  navButtons,
  children,
}) => {
  const [isExpanded, setExpanded] = useState(isExpandedByDefault);

  const toggleLayers = useCallback(() => {
    setExpanded(!isExpanded);
  }, [setExpanded, isExpanded]);

  const boxShadow = title === "Environmental" ? ENVIRONMENTAL_BOX_SHADOW : DEFAULT_BOX_SHADOW;

  return (
    <LayersMenuItemWrapper>
      <LayersMenuTitleWrapper onClick={toggleLayers}>
        <LayersMenuLabelWrapper>
          {data && <ExpandCollapseButton disabled={disabled} src={isExpanded ? ArrowUp : ArrowDown} />}
          <LayersMenuContentTitle disabled={disabled}>{title} </LayersMenuContentTitle>
        </LayersMenuLabelWrapper>
        {showSwitch && (
          <SwitchButton
            disabled={disabled}
            checked={isSwitchChecked ?? false}
            defaultChecked={isSwitchChecked ?? false}
            id={`switch_${title}`}
            name={`switch_${title}`}
            onClick={switchOverlay}
            pr={navButtons ? "10px" : "0"}
          />
        )}
        {navButtons}
      </LayersMenuTitleWrapper>
      {isExpanded && data && (
        <>
          <LayersMenuList className={disabled ? "isDisabled" : ""}>
            {data.map((item) => {
              const showRange = item.showRangeButton || additionalOverlayData?.showSlideControl;
              const initialRangeValue = item.showRangeButton ? item.initialRange : additionalOverlayData?.defaultValue;

              return (
                <LayersMenuOption
                  isRangeVisible={showRange! && item.checked}
                  key={`${item.name}_${item.checked}`}
                  disabled={item.disabled ?? false}
                >
                  <>
                    {type === "single-select" ? (
                      <Radio
                        boxShadow={boxShadow}
                        {...{ disabled: item.disabled }}
                        defaultChecked={item.checked}
                        id={item.name}
                        name={title}
                        onClick={() => onClick({ name: item.name, key: item.key, value: item.name })}
                        value={item.key}
                        label={item.name}
                        image={item.image}
                        additionalInfo={item.additionalInfo}
                      ></Radio>
                    ) : (
                      <Checkbox
                        boxShadow={boxShadow}
                        {...{ disabled: item.disabled ?? false }}
                        id={item.name}
                        backgroundColor={item.backgroundColor}
                        name={item.name}
                        onClick={(e: React.FormEvent<HTMLElement | HTMLInputElement>) =>
                          onClick({ name: item.name, key: item.key, value: (e.target as HTMLInputElement).checked })
                        }
                        checked={item.checked}
                        defaultChecked={item.checked}
                        additionalText={item.additionalInfo}
                        value={item.key}
                        label={item.name}
                        image={item.image}
                      />
                    )}
                    {showRange && item.checked && (
                      <RangeInput
                        defaultValue={initialRangeValue || INITIAL_RANGE}
                        border="1px solid #e9e9e9"
                        onClick={(e) =>
                          additionalOverlayData?.onChangeSlideControl({
                            key: item.key,
                            value: Number((e.target as HTMLInputElement).value),
                          })
                        }
                      />
                    )}
                  </>
                </LayersMenuOption>
              );
            })}
          </LayersMenuList>
          {children}
        </>
      )}
    </LayersMenuItemWrapper>
  );
};
export const LayerMenuTitle = ({ toggleLayerControl }: { toggleLayerControl: () => void }) => {
  return (
    <LayersMenuWrapper>
      <LayersMenuTitle>
        <img src={LayerMenuIcon} alt="layers menu" width={20} height={20} />
        Layers
      </LayersMenuTitle>
      <LayersMenuClose
        onClick={(e) => {
          e.stopPropagation();
          toggleLayerControl();
        }}
      >
        <img src={Close} alt="close" width={12} height={12} />
      </LayersMenuClose>
    </LayersMenuWrapper>
  );
};
