import styled from "styled-components";

export const EyeWrapper = styled.div<{
  selected: boolean;
  violation: boolean;
  isSubItemSelected: boolean;
  isItemHidden: boolean;
}>`
  height: 21px;
  align-items: center;
  margin-right: ${(props) => (props.violation ? "9px" : "3px")};
  display: ${(props) => (props.selected || props.isItemHidden ? "flex" : "none")};
  svg path,
  svg circle {
    fill: ${(props) => (props.selected ? (!props.isSubItemSelected ? "var(--colors-white)" : "#7D8FAD") : "#7D8FAD")};
  }
`;

export const LeftItem = styled.div<{ itemSelected: boolean }>`
  display: flex;
  flex-direction: row;
  line-height: 1em;
  height: 43px;
  overflow: hidden;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  :hover {
    ${EyeWrapper} {
      display: flex;
    }
  }
`;

export const NameTax = styled.div<{ selected: boolean }>`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.035em;
  color: ${(props) => (props.selected ? "var(--colors-white)" : "rgb(63, 73, 81);")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 183px;
  padding-left: 15px;
`;

export const AdditionalNameTax = styled.span<{ selected: boolean }>`
  color: ${(props) => (props.selected ? "var(--color-white)" : "rgb(116, 130, 143)")};
`;

export const FeederLevelWrapper = styled.div<{ violation?: boolean }>`
  display: flex;
  align-items: center;
  padding-right: 15px;
`;
export const CircuitWrapper = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
`;
