import { FC, useMemo, memo } from "react";
import { GeoJSON } from "react-leaflet";
import L from "leaflet";
import { v4 as uuidv4 } from "uuid";

import { MaintenanceAreaGeometryDto } from "../../types/responses";
import { useCustomMapPane } from "../../hooks/useCustomMapPane";
import { MapMarkerCluster } from "../MapMarkerCluster";
import { DEFAULT_ZOOM_LEVEL } from "../../stores/MapStore";

interface MapMAStarCommandProps {
  geoJSON: Nullable<MaintenanceAreaGeometryDto[]>;
  hidden?: boolean;
  map: Nullable<L.Map>;
  checkedMaintenanceAreas?: Nullable<string[]>;
  zIndex?: number;
  colorsById: Nullable<Record<string, string>>;
}
export const DEFAULT_STYLE = {
  fillColor: "rgb(33, 101, 176)",
  opacity: 1,
  color: "#DCC23A",
  weight: 4,
  fillOpacity: 0.51,
};

const MA_STAR_COMMAND_PANE_NAME = "maintenance-area-star-command";

export const MapMAStarCommand: FC<MapMAStarCommandProps> = memo(
  ({ geoJSON, hidden = false, map, zIndex = 399, colorsById, checkedMaintenanceAreas }) => {
    useCustomMapPane({
      map,
      name: MA_STAR_COMMAND_PANE_NAME,
      zIndex,
    });

    const maintenanceAreas = useMemo(() => {
      if (!geoJSON?.length || !checkedMaintenanceAreas?.length) {
        return <></>;
      }

      return geoJSON
        .filter((item) => checkedMaintenanceAreas?.find((checkedId) => checkedId === item.id) !== undefined)
        .map((item) => {
          const style = colorsById?.[item.id]
            ? Object.assign({}, DEFAULT_STYLE, {
                fillColor: colorsById?.[item.id],
                fillOpacity: 0.4,
                className: `maintenance-area-star-command selected`,
              })
            : DEFAULT_STYLE;

          return (
            <GeoJSON
              data={item.polygon}
              interactive={false}
              key={uuidv4()}
              style={style}
              pane={MA_STAR_COMMAND_PANE_NAME}
            ></GeoJSON>
          );
        });
    }, [geoJSON, colorsById, checkedMaintenanceAreas]);

    if (hidden) {
      return <></>;
    }

    return <MapMarkerCluster disableClusteringAtZoom={DEFAULT_ZOOM_LEVEL}>{maintenanceAreas}</MapMarkerCluster>;
  }
);
