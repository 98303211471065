import { useEffect, useMemo } from "react";

import { getParcelsApiUrl } from "../api";
import { MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER } from "../pages/MapScreen/MapView/MapView.constants";
import { useMapSelectionStore, useParcelsStore, useSpansStore } from "../stores";
import { ParcelsResponse } from "../types/responses";
import { useCustomQuery } from "./useCustomQuery";
import { useExtentParams } from "./useExtentParams";

const QUERY_KEY = "parcel";

export const useParcels = () => {
  const params = useExtentParams();
  const selectedSpanId = useSpansStore((store) => store.selectedId);
  const parcelLayerHidden = useParcelsStore((store) => store.parcelLayerHidden);
  const setParcels = useParcelsStore((store) => store.setData);
  const hiddenSelectionTool = useMapSelectionStore((store) => store.hidden);

  const isParcelApiEnabled = useMemo(() => {
    return (
      (!!selectedSpanId || !parcelLayerHidden || !hiddenSelectionTool) &&
      params !== null &&
      params?.zoomLevel >= MIN_ZOOM_LEVEL_FOR_PARCELS_LAYER
    );
  }, [selectedSpanId, parcelLayerHidden, hiddenSelectionTool, params]);

  const { refetch } = useCustomQuery<ParcelsResponse>(params !== null ? getParcelsApiUrl(params) : `/${QUERY_KEY}`, {
    onSuccess: (response: ParcelsResponse) => setParcels(response),
    enabled: isParcelApiEnabled,
  });

  useEffect(() => {
    if (!isParcelApiEnabled) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParcelApiEnabled]);
};
