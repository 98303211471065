import styled from "styled-components";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const CTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262c2d;
  background-color: transparent;
  padding: 0 15px;
`;

export const CHeader = styled(StyledFlex)`
  div:first-child {
    flex: 1;
  }

  .react-select__control {
    height: 35px;
  }

  .react-select__option {
    font-weight: 600;
    font-size: 14px;
    color: #262c2d;
  }

  .react-select__option:first-child {
    color: #1fa7b2;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #efefef;
  border: none;
`;
