import { useEffect } from "react";
import { getSpansByExtentUrl } from "../api";

import { MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE } from "../pages/MapScreen/MapView/MapView.constants";
import { useSpansStore } from "../stores";
import { SpansResponse } from "../types/responses";

import { useCustomQuery } from "./useCustomQuery";

import { useExtentParams } from "./useExtentParams";

const QUERY_KEY = "spans-segments";

export const useSpans = () => {
  const params = useExtentParams();
  const setSpans = useSpansStore((store) => store.setData);

  const isLayerVisible = params !== null && params.zoomLevel >= MIN_ZOOM_LEVEL_FOR_SPAN_WITH_WORK_TYPE;

  const { refetch } = useCustomQuery<SpansResponse>(params !== null ? getSpansByExtentUrl(params) : `/${QUERY_KEY}`, {
    onSuccess: (response: SpansResponse) => setSpans(response),
    enabled: isLayerVisible && params !== null,
  });

  useEffect(() => {
    if (!params || !isLayerVisible) {
      return;
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, isLayerVisible]);
};
