import styled from "styled-components";

import { CUT_PLAN_Z_INDEX } from "../../pages/MapScreen/MapView/MapView.constants";
import { LAYERS_MENU_WIDTH, LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "../../constants/dimensions";

interface MapMeasurePopupWrapperProps {
  rhsEnabled?: boolean;
  lhsEnabled?: boolean;
  layerMenuEnabled?: boolean;
}

export const MapMeasurePopupContent = styled.div<MapMeasurePopupWrapperProps>`
  position: absolute;
  bottom: 29px;
  z-index: 1000;
  right: 0;
  height: 1px;
  ${(props) => {
    const width =
      (props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0) +
      (props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : 0) +
      (props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0);
    const marginLeft = props.lhsEnabled ? LEFT_SIDEBAR_WIDTH : 0;
    const marginRight = props.rhsEnabled ? RIGHT_SIDEBAR_WIDTH : props.layerMenuEnabled ? LAYERS_MENU_WIDTH : 0;
    return `width: calc(100% - ${width}px); margin-left: ${marginLeft}px; margin-right: ${marginRight}px;`;
  }}
  z-index: ${CUT_PLAN_Z_INDEX};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const MapMeasurePopupWrapper = styled.div`
  z-index: ${CUT_PLAN_Z_INDEX + 1};
  width: 244px;
  height: 54px;
  background: rgba(0, 0, 0, 0.69);
  border-radius: 62px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
`;

export const MapMeasurePopupLabel = styled.div`
  color: var(--colors-white);
  span {
    color: #11abb9;
  }
`;
export const MapMeasurePopupLabelWrapper = styled.div<{ textAlign?: string }>`
  display: flex;
  flex-direction: column;
  width: 126px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

export const ImageWrapper = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid #494848;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const CloseImageWrapper = styled.div`
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

export const MapMeasurePopupImage = styled.img``;

export const MapMeasurePopupCloseImage = styled.img``;
