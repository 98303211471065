import { LineString } from "@turf/turf";
import { useMutation, useQueryClient } from "react-query";

import { getAccessPointsMarkerUrl } from "../api";
import { QUERY_KEY } from "../pages/MapScreen/MapView/AccessPoints.util";
import { AccessPoints } from "../types/responses";
import ApiClient from "../utils/apiClient";

export const useEditAccessPoints = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ markerID, value }: { markerID: string; value: { geometry?: LineString; categoryId?: string } }) =>
      ApiClient.put(getAccessPointsMarkerUrl(markerID), value),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_KEY),
      onMutate: (params: { markerID: string; value: { geometry?: LineString; categoryId?: string } }) => {
        //optimistic UI
        const previousData: Maybe<AccessPoints> = queryClient.getQueryData(QUERY_KEY);
        const newData = params.value.geometry
          ? {
              markers: previousData?.markers.map((item) =>
                item.id === params.markerID ? { ...item, geometry: params.value.geometry } : item
              ),
            }
          : previousData;
        queryClient.setQueryData(QUERY_KEY, newData);
        return { newData, params };
      },
    }
  );
};
