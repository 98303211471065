import styled from "styled-components";
import { InputLabel } from "../../assets/styles/fonts.styled";
import { Input } from "../Input/Input.styled";

export const CrewLabel = styled.div`
  ${InputLabel}
`;
export const InputField = styled(Input)``;

export const SelectWrapper = styled.div`
  .react-select__menu {
    width: 70%;
    left: 30%;
  }
`;

export const EmptyCheck = styled.div`
  height: 12px;
  width: 10px;
`;

export const CheckWrapper = styled.div`
  margin-right: 12px;
  svg path {
    fill: var(--colors-river-bad);
  }
`;
