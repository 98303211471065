import { useMutation, useQueryClient } from "react-query";

import ApiClient from "../utils/apiClient";
import { getPlanUrl } from "../api";
import { logError } from "../services";

interface Plan {
  planDetails: {
    planName: string;
    start: string;
    end: string;
    status: string;
  };
  planBudget: {
    totalBudget: number;
    budgetByRegions: { budget: number; regionGroupId: string }[];
  };
  planCrew: {
    crewsByRegion: {
      crewId: string;
      regionGroupId: string;
    }[];
  };
}

export const useEditPlan = () => {
  const queryClient = useQueryClient();
  return useMutation(({ planId, plan }: { planId: string; plan: Plan }) => ApiClient.put(getPlanUrl(planId), plan), {
    onSuccess: ({ data }, variables) => {
      queryClient.invalidateQueries("plans");
      queryClient.setQueryData(`plan-${variables.planId}`, () => data);
    },
    onError: (e) => logError(e),
  });
};

export default useEditPlan;
