import create from "zustand";
import { EMPTY_FN } from "../constants";

import { MappedFeedersResponse } from "../pages/MapScreen/MapScreen.utils";
import { FeedersResponse, MappedFeederGeoJson, MappedFeedersGeoJSON } from "../types/responses/FeedersResponse";

export type FeederStoreState = {
  defaultCenter?: Nullable<L.LatLngExpression>;
  feedersData: Nullable<MappedFeedersGeoJSON>;
  feedersGeoJson: Nullable<FeedersResponse>;
  hiddenFeeders: Nullable<string[]>;
  selectedFeeder: Nullable<string>;
  actions: {
    setData: (data: MappedFeedersResponse) => void;
    getFeederDataById: (feederId: Nullable<string>) => Maybe<MappedFeederGeoJson>;
    hideFeeder: (feederId: string) => void;
    resetHiddenFeeders: () => void;
    setSelectedFeederById: (feederId: string | undefined) => void;
  };
};

const INITIAL_STATE = {
  defaultCenter: null,
  feedersData: {},
  feedersGeoJson: null,
  hiddenFeeders: [],
  selectedFeeder: null,
  actions: {
    setData: EMPTY_FN,
    getFeederDataById: EMPTY_FN,
    hideFeeder: EMPTY_FN,
    resetHiddenFeeders: EMPTY_FN,
    setSelectedFeederById: EMPTY_FN,
  },
};

export const useFeederStore = create<FeederStoreState>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    setData: (data) =>
      set({
        defaultCenter: data?.center,
        feedersData: data?.feedersData ?? null,
        feedersGeoJson: data?.feedersGeoJson ?? null,
      }),
    getFeederDataById: (feederId) => {
      if (!feederId) {
        return;
      }

      return get().feedersData?.[feederId];
    },
    hideFeeder: (feederId: string) => {
      const hiddenFeeders = get().hiddenFeeders;
      if (!hiddenFeeders) {
        Object.assign([], hiddenFeeders);
      }

      const index = hiddenFeeders?.findIndex((item) => item === feederId) ?? -1;
      if (index >= 0) {
        hiddenFeeders && hiddenFeeders.splice(index, 1);
      } else {
        hiddenFeeders?.push(feederId);
      }
      set({ hiddenFeeders: Object.assign([], hiddenFeeders) });
    },
    resetHiddenFeeders: () => set({ hiddenFeeders: [] }),
    setSelectedFeederById: (feederId) => {
      if (feederId === get().selectedFeeder) {
        return;
      }

      if (!feederId) {
        // unselect
        set({
          // defaultZoomLevel: DEFAULT_ZOOM_LEVEL,
          // selectedEncroachment: null,
          selectedFeeder: null,
        });
      } else {
        set({
          selectedFeeder: feederId,
          // defaultZoomLevel: get().feederZoomLevel,
          //selectedEncroachment: null,
        });
      }
    },
  },
}));

export default useFeederStore;
