import { useMutation } from "react-query";
import ApiClient from "../utils/apiClient";
import { maintenanceAreas } from "../api";
import { useMaintenanceAreaStore } from "../stores";
import { MaintenanceAreaWithSpansDto } from "../types/responses";

export const CREATE_COMMENT_MUTATION_KEY = "create-comment";

export const useAssignMaToSpans = () => {
  const assignMaToSpans = useMaintenanceAreaStore((store) => store.assignMaToSpans);

  return useMutation(
    "ma",
    ({ maId, spanIdsToAdd }: { maId: string; spanIdsToAdd: string[] }) =>
      ApiClient.put<MaintenanceAreaWithSpansDto>(`${maintenanceAreas}/${maId}`, { spanIdsToAdd }),
    {
      onSuccess: ({ data }, variables) => assignMaToSpans(variables.maId, data.spanIds),
    }
  );
};
