import styled from "styled-components";
import { StyledFlex } from "../../../../assets/styles/flex.styled";
import { ModalDialog } from "../../../../components/ModalDialog";
import { Select } from "../../../../components/Select";

export const Wrapper = styled(StyledFlex)`
  background-color: var(--colors-white);
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  height: fit-content;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  box-sizing: border-box;
`;

export const SectionTitle = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  color: var(--colors-eastern-blue);

  &.capitalize {
    text-transform: capitalize;
  }
`;

export const DropdownInput = styled.div`
  background-color: #fbfbfb;
  border: 1px solid var(--colors-alto);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;

  > span {
    font-weight: 400;
    font-size: 14px;
    color: #6a6c71;
  }

  > span.haveCrew {
    font-weight: 700;
    font-size: 14px;
    color: var(--colors-nevada);
  }

  > svg path {
    fill: #1fa7b2;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: var(--colors-black);
  line-height: 1;

  & + span {
    font-weight: 600;
    font-size: 12px;
    color: var(--colors-black);
  }

  &.modalLabel {
    font-weight: 500;
    font-size: 11px;
  }

  &.totalCost {
    font-weight: 400;
    font-size: 14px;
    color: #6a6c71;

    span {
      font-weight: 700;
      font-size: 16px;
      color: #41595b;
    }
  }
`;

export const Divider = styled.h1`
  height: 1px;
  width: 100%;
  background-color: #eeeeee;
`;

export const WorksheetSelect = styled(Select)`
  max-width: 300px;

  .react-select__option {
    padding: 10px;
    border: 1px solid #ebeaea;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
  }

  .react-select__option:hover {
    border-color: var(--colors-eastern-blue);
  }

  .react-select__option:not(:last-child) {
    margin-bottom: 5px;
  }

  .react-select__menu-list {
    padding: 10px;
  }

  .react-select__control,
  .react-select__value-container,
  .react-select__input,
  .react-select__input input {
    height: auto;
  }

  .checkbox__text {
    font-weight: 600;
    font-size: 14px;
    color: #262c2d;
  }

  .react-select__group-heading {
    font-weight: 500;
    font-size: 11px;
    color: #828b93;
    padding-left: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
`;

export const WorksheetWrapper = styled(StyledFlex)`
  gap: 20px;
  flex-wrap: wrap;
  max-height: 130px;
  overflow-y: auto;

  > div {
    width: 290px;
    margin: 0 !important;
  }

  .worktype-name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
  }
`;

export const StyledModal = styled(ModalDialog)`
  padding: 0;
`;

export const modalFooterStyles = {
  boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.03)",
  justifyContent: "space-between",
  alignItems: "center",
  mt: "20px",
  p: "20px",
};
