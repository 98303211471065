import { FC } from "react";

import { parcelSelectOptions } from "../../stores";
import { ParcelsResponseProperties, PARCEL_STATUS } from "../../types/responses";
import { ButtonStyled } from "../Button/Button.styled";
import { PARCEL_STATUSES_LEGEND } from "../MapParcels/MapParcels.constants";
import { ParcelStatusButtonBodyStatus } from "../RightDrawer/DrawerSectionItems/ParcelStatusButton.styled";
import { Select } from "../Select";
import { Wrapper, ParcelItemsContainer, StatusWrapper, ParcelItem, ParcelStatus } from "./ParcelRHSContent.styled";
import { sortParcels } from "./ParcelRHSContent.utils";

interface ParcelRHSContentProps {
  isEditMode: boolean;
  selectedStatus: {
    label: string;
    value: string;
  };
  setSelectedStatus: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  parcelDetails: Array<any>;
  setParcelDetails: React.Dispatch<Array<any>>;
}

export const ParcelRHSContent: FC<ParcelRHSContentProps> = ({
  selectedStatus,
  setSelectedStatus,
  isEditMode,
  parcelDetails,
  setParcelDetails,
}) => {
  const parcelItem = (parcel: ParcelsResponseProperties) => {
    const { parcelId, status } = parcel;
    return (
      <ParcelItemsContainer key={parcel.id}>
        <ParcelItem> {parcelId}</ParcelItem>
        <StatusWrapper>
          <ParcelStatus>{PARCEL_STATUSES_LEGEND[status.toUpperCase() as PARCEL_STATUS].name}</ParcelStatus>
          <ParcelStatusButtonBodyStatus
            backgroundColor={PARCEL_STATUSES_LEGEND[status.toUpperCase() as PARCEL_STATUS].bgColor}
          />
        </StatusWrapper>
      </ParcelItemsContainer>
    );
  };

  const handleApply = () => {
    const parcelsWithNewSelectedStatus = parcelDetails.map((parcel) => ({
      ...parcel,
      status: selectedStatus.value,
    }));
    setParcelDetails(parcelsWithNewSelectedStatus);
  };

  sortParcels(parcelDetails);

  return (
    <>
      {isEditMode && (
        <Wrapper>
          <Select
            isDisabled={!isEditMode}
            ml={2}
            options={parcelSelectOptions}
            value={selectedStatus}
            width={208}
            onChange={(value) => {
              setSelectedStatus(value);
            }}
          />

          <ButtonStyled
            variant="primary"
            height="30px"
            minWidth="70px"
            fontSize="14px"
            position="relative"
            type="submit"
            padding="0px"
            disabled={selectedStatus.value === "default" || !isEditMode}
            onClick={handleApply}
          >
            Assign
          </ButtonStyled>
        </Wrapper>
      )}
      {parcelDetails.map((parcel) => parcelItem(parcel))}
    </>
  );
};
