import { useMutation } from "react-query";

import { searchCommentsUrl } from "../api";
import { logError } from "../services";
import useCommentsStore from "../stores/CommentStore";
import { SearchCommentsResponseDto } from "../types/responses";
import ApiClient from "../utils/apiClient";

export const COMMENTS_MUTATION_KEY = "comments-mutation";

export const useComments = () => {
  const setComments = useCommentsStore((store) => store.setData);
  const filter = useCommentsStore((store) => store.filter);

  const postData = {
    searchFor: filter.text,
    sortBy: filter.sortBy,
    showResolved: filter.showResolved,
    showRead: filter.showRead,
    page: 0,
    pageSize: 2000,
  };

  return useMutation(
    COMMENTS_MUTATION_KEY,
    () => ApiClient.post<SearchCommentsResponseDto>(searchCommentsUrl(), postData),
    {
      onSuccess: (response) => setComments(response?.data ?? {}),
      onError: (e) => logError(e),
    }
  );
};
