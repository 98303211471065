import styled from "styled-components";
import { RIGHT_DRAWER } from "../../constants/z-index";
import { STAR_COMMAND_LHS_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { RISK_PRIORITY } from "../../types/responses/ThreatHierarchyResponse";
import { IconButton } from "../IconButton";

export const ToggleLhsButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 10px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 29px;
  height: 45px;
  background-color: var(--colors-teal-1);
  border: none;
  padding: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: var(--colors-teal-2);
  }
`;

export const Wrapper = styled.div`
  width: ${STAR_COMMAND_LHS_WIDTH}px;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  max-height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  position: fixed;
  top: ${TOP_HEADER_HEIGHT}px;
  z-index: ${RIGHT_DRAWER + 1};
  background-color: var(--colors-white);
  left: -${STAR_COMMAND_LHS_WIDTH}px;
  display: flex;
  flex-direction: column;

  &.isOpen {
    left: 0;

    ${ToggleLhsButton} svg {
      transform: rotate(180deg);
    }
  }
`;

export const SearchWrapper = styled(StyledFlex)`
  border: 1px solid transparent;
  background: rgba(234, 232, 232, 0.48);
  align-items: center;
  border-radius: 3px;
  height: 30px;
  padding-left: 7px;
  margin: 16px 16px 30px;

  input {
    border: none;
    height: 30px;
  }

  input::placeholder {
    color: #d3d3d3;
  }

  &:focus-within {
    border-color: var(--colors-eastern-blue);

    path {
      fill: var(--colors-eastern-blue);
    }
  }
`;

export const TabHeader = styled(StyledFlex)`
  margin: 25px 16px 0;

  span:nth-child(2) {
    margin-left: 10px;
  }

  span:nth-child(3) {
    margin-left: auto;
  }
  span:nth-child(4) {
    margin-left: 25px;
  }

  span:nth-child(5) {
    margin-left: 15px;
    margin-right: 10px;
  }

  span:nth-child(3),
  span:nth-child(4),
  span:nth-child(5) {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: -0.035em;
    color: #74828f;
  }
`;

export const TabItem = styled.span`
  width: 50px;
  padding-bottom: 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3f4952;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &.isActive {
    color: #20949e;
    border-bottom-color: #20949e;
  }
`;

export const Hint = styled.span`
  color: #74828f;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  padding: 0 16px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;

  &:hover {
    background-color: var(--colors-botticelli);
  }

  label svg {
    width: 16px;
    height: 16px;
  }
  .checkbox__text {
    display: inline-block;
    width: 165px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3f4952;
    text-transform: capitalize;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  > span:nth-child(2) {
    margin-left: 10px;
  }

  > span:nth-child(3) {
    margin-left: 20px;
  }

  [data-priority] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  [data-priority],
  [data-priority] svg {
    width: 20px;
    height: 20px;
  }

  [data-priority] span {
    display: inline-block;
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
  }

  [data-priority=${RISK_PRIORITY.LOW}] span {
    background-color: var(--colors-priority-low);
  }

  [data-priority=${RISK_PRIORITY.MEDIUM}] span {
    background-color: var(--colors-priority-medium);
  }

  [data-priority=${RISK_PRIORITY.HIGH}] span {
    background-color: var(--colors-priority-high);
  }

  [data-priority=${RISK_PRIORITY.CRITICAL}] span {
    background-color: var(--colors-priority-critical);
  }
`;

export const PriorityTitle = styled.span`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: var(--colors-trout);
`;

export const PriorityCount = styled.div`
  display: inline-flex;
  padding: 4px;
  width: 41px;
  height: 26px;
  background-color: var(--colors-white);
  border-radius: 4px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--colors-alabaster-2);
    cursor: pointer;
  }

  &.isActive {
    background-color: #aac4d4;
  }

  > * {
    pointer-events: none;
  }

  span:last-child {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #4b515b;
  }

  [data-priority] {
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }

  [data-priority=${RISK_PRIORITY.LOW}] {
    background-color: var(--colors-priority-low);
  }

  [data-priority=${RISK_PRIORITY.MEDIUM}] {
    background-color: var(--colors-priority-medium);
  }

  [data-priority=${RISK_PRIORITY.HIGH}] {
    background-color: var(--colors-priority-high);
  }

  [data-priority=${RISK_PRIORITY.CRITICAL}] {
    background-color: var(--colors-priority-critical);
  }

  [data-priority=${RISK_PRIORITY.VIOLATION}] {
    background-color: var(--colors-priority-violation);
  }
`;
