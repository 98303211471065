import { ParcelsResponseProperties, RISK_PRIORITY } from "../../../types/responses";
import ViolationMarker from "./../../../assets/images/violation_marker.svg";
import MiddleMarker from "./../../../assets/images/middle_marker.svg";
import HighMarker from "./../../../assets/images/high_marker.svg";
import LowMarker from "./../../../assets/images/low_marker.svg";
import CriticalMarker from "./../../../assets/images/critical.svg";

export const getSpanMarkerIconByRiskPriority = (priority: RISK_PRIORITY) => {
  switch (priority) {
    case RISK_PRIORITY.CRITICAL:
      return CriticalMarker;
    case RISK_PRIORITY.HIGH:
      return HighMarker;
    case RISK_PRIORITY.LOW:
      return LowMarker;
    case RISK_PRIORITY.MEDIUM:
      return MiddleMarker;
    case RISK_PRIORITY.VIOLATION:
      return ViolationMarker;
    default:
      return LowMarker;
  }
};

export const filterParcel = (
  parcelIds: Maybe<string[]>,
  hiddenParcelLayer: boolean,
  selectedStatuses: Record<string, boolean>,
  properties: ParcelsResponseProperties
) => {
  if (!hiddenParcelLayer) {
    return selectedStatuses && selectedStatuses[properties.status];
  }
  return parcelIds && parcelIds.includes(properties.id);
};
