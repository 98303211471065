import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { components, OptionProps } from "react-select";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { Input } from "../Input/Input";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { Select } from "../Select";
import { CheckWrapper, CrewLabel, EmptyCheck, SelectWrapper } from "./CrewConfig.styled";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import { Crew } from "../../types/responses/Crews";

interface CrewConfigProps {
  crews: Crew[];
  addCrew: (name: string) => void;
  editCrew: (name: string, id: string) => void;
  selectedCrew?: string;
  selectCrew: (crewId: string) => void;
  setCreateCrewDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomSelectOptionComponent = (props: OptionProps<any, any, any> | any) => {
  return (
    <StyledFlex alignItems="center">
      <CheckWrapper>
        {props.getValue()?.[0]?.value === props.data.value ? <Check className="_check-image" /> : <EmptyCheck />}
      </CheckWrapper>

      <div>{props.data.label}</div>
    </StyledFlex>
  );
};

const CustomOption = (props: OptionProps<any, any, any>) => (
  <components.Option {...props}>
    <CustomSelectOptionComponent {...props} />
  </components.Option>
);

export const CustomSingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <div>{props.data.label}</div>
  </components.SingleValue>
);

export const CrewConfig: FC<CrewConfigProps> = ({
  selectedCrew,
  addCrew,
  editCrew,
  crews,
  selectCrew,
  setCreateCrewDisabled,
}) => {
  const [currentCrew, setCurrentCrew] = useState(selectedCrew);
  const options = useMemo(() => {
    if (!crews) {
      return [];
    }
    return crews.map((crew) => ({ label: crew.name, value: crew.id }));
  }, [crews]);

  const formik = useFormik({
    initialValues: {
      crewName: options?.find((item) => item.value === currentCrew)?.label ?? "",
    },
    validationSchema: Yup.object().shape({
      crewName: Yup.string()
        .min(2, "Crew name must be between 2 and 64 characters.")
        .required("Crew name is required."),
    }),
    onSubmit: () => {},
    isInitialValid: false,
    enableReinitialize: true,
  });

  const { values: formData, setFieldTouched, isValid, dirty, resetForm } = formik;

  useEffect(() => {
    resetForm();
    setCurrentCrew(selectedCrew);
  }, [selectedCrew, resetForm]);

  useEffect(() => {
    if (selectedCrew) {
      setCreateCrewDisabled?.(false);
      return;
    }

    setCreateCrewDisabled?.(!dirty || !isValid);
  }, [isValid, dirty, setCreateCrewDisabled, selectedCrew]);

  const selectedOption = useMemo(() => {
    if (!options) {
      return null;
    }
    return options?.find((item) => item.value === currentCrew) ?? null;
  }, [options, currentCrew]);

  const saveOrUpdate = useCallback(
    (crewName: string) => {
      if (selectedCrew) {
        editCrew(crewName ?? "", selectedCrew);
      } else {
        addCrew(crewName ?? "");
      }
    },
    [selectedCrew, addCrew, editCrew]
  );

  const handleBlur = () => {
    if (isValid && dirty) {
      saveOrUpdate(formData.crewName);
    }
  };

  return (
    <StyledFlex marginBottom={"30px"}>
      <StyledFlex flexDirection={"column"} minWidth={"300px"} marginRight={"50px"} gap={"7px"}>
        <CrewLabel>Crew's</CrewLabel>
        <SelectWrapper>
          <Select
            isDisabled={false}
            ml={2}
            className="crews"
            options={options}
            value={selectedOption}
            width={"100%"}
            height="35px"
            marginLeft="0px"
            placeholder="New Crew"
            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            onChange={(value) => {
              setCurrentCrew(value.value);
              selectCrew(value.value);
            }}
          />
        </SelectWrapper>
      </StyledFlex>
      <StyledFlex flexDirection={"column"} minWidth={"300px"} gap={"7px"}>
        <FormikProvider value={formik}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input
              name="crewName"
              type="string"
              label="Edit crew name"
              onFocus={() => setFieldTouched("crewName", true)}
              onBlur={handleBlur}
            />
          </form>
        </FormikProvider>
      </StyledFlex>
    </StyledFlex>
  );
};
