import React, { FC } from "react";
import { useField } from "formik";
import { TextareaStyled } from "./Textarea.styled";

interface IProps {
  name: string;
  placeholder: string;
  editable?: boolean;
  disabled?: boolean;
}

export const Textarea: FC<IProps> = ({ placeholder, name, editable, disabled }) => {
  const [field] = useField(name);

  return (
    <TextareaStyled
      {...field}
      placeholder={placeholder}
      disabled={disabled}
      editable={editable}
      name={name}
    ></TextareaStyled>
  );
};
