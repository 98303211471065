import styled from "styled-components";
import { LayoutProps, layout } from "styled-system";

import { ERROR_MESSAGE } from "../../../constants/z-index";
import { ReactComponent as WarningIcon } from "./../../../assets/images/warning_icon.svg";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorIcon = styled(WarningIcon)`
  width: 10px;
  height: 10px;
  position: absolute;
  margin-top: 10px;
  margin-left: -30px;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.burntSienna};
  display: block;
  text-align: start;
  position: absolute;
  width: 195px;
  background: ${(props) => props.theme.colors.fairPink};
  border: 1px solid ${(props) => props.theme.colors.tuftBush};
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  z-index: ${ERROR_MESSAGE};
  margin-top: 4px;
  margin-left: 5px;
  text-align: center;
`;

export const TableInputItem = styled.input<{ error?: boolean; autocomplete?: string } & LayoutProps>`
  ${layout}
  width: 195px;
  height: 30px;
  appearance: none;
  outline: none;
  background: ${(props) => props.theme.colors.alabaster2};
  border: 1px solid ${(props) => props.theme.colors.ebb};
  box-sizing: border-box;
  border-radius: 5px;
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
  padding-left: 11px;
  color: ${(props) => (props.error ? props.theme.colors.burntSienna : props.theme.colors.trout2)};
  &:hover {
    border: 1px solid #acdee2;
  }
  &:focus {
    border: 1px solid ${(props) => (props.error ? props.theme.colors.burntSienna : props.theme.colors.easternBlue)};
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  &::placeholder {
    color: var(--colors-trout-2);
    font-size: 14px;
  }
`;
