import create from "zustand";

import { EMPTY_FN } from "../constants";

export type CutPlanStore = {
  actions: {
    setCutPlanHidden: (hidden: boolean) => void;
    toggleCutPlanHidden: () => void;
    setCutPlanSelectedId: (id: Nullable<string>) => void;
  };
  cutPlanSelectedId: Nullable<string>;
  cutPlanHidden: boolean;
};
const INITIAL_STATE = {
  cutPlanSelectedId: null,
  cutPlanHidden: true,
  actions: {
    setCutPlanHidden: EMPTY_FN,
    toggleCutPlanHidden: EMPTY_FN,
    setCutPlanSelectedId: EMPTY_FN,
  },
};

export const useCutPlanStore = create<CutPlanStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    setCutPlanHidden: (hidden: boolean) => set({ cutPlanHidden: hidden, cutPlanSelectedId: null }),
    toggleCutPlanHidden: () =>
      set({
        cutPlanHidden: !get().cutPlanHidden,
        cutPlanSelectedId: !get().cutPlanHidden ? null : get().cutPlanSelectedId,
      }),
    setCutPlanSelectedId: (id: Nullable<string>) => set({ cutPlanSelectedId: id }),
  },
}));

export default useCutPlanStore;
