import { useMutation, useQueryClient } from "react-query";
import ApiClient from "../utils/apiClient";
import { deleteAlert } from "../api";

export const useDeleteAlert = () => {
  const queryClient = useQueryClient();
  return useMutation((alertId: string) => ApiClient.delete(deleteAlert(alertId)), {
    onSettled: () => queryClient.invalidateQueries("alerts"),
  });
};
