import styled from "styled-components";
import { STAR_COMMAND_LHS_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";

export const StarCommandMapStyle = styled.div<{ numberOfElements?: number }>`
  position: relative;
  & + .star-command-map .maxar-polygon.leaflet-interactive {
    cursor: ${(props) => (props.numberOfElements ? "grab" : "inherited")};
  }
`;

const PageWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

interface PageContentProps {
  toggled: boolean;
}

const PageContent = styled.div<PageContentProps>`
  display: inline-block;
  width: ${(props) => (props.toggled ? ` calc(100% - ${STAR_COMMAND_LHS_WIDTH}px)` : `100%`)};
  left: ${(props) => (props.toggled ? `${STAR_COMMAND_LHS_WIDTH}px` : `0px`)};
  top: ${TOP_HEADER_HEIGHT}px;
  overflow-x: hidden;
  position: relative;
`;

export { PageWrapper, PageContent };
