import { useMutation } from "react-query";
import ApiClient from "../utils/apiClient";
import { updatePolygonUrl } from "../api";
import { useThreatsStore } from "../stores";

export const useDeleteCustomPolygon = () => {
  const { deleteThreatById: deleteEncroachmentById } = useThreatsStore((store) => store.actions);

  return useMutation((polygonId: string) => ApiClient.delete(updatePolygonUrl(polygonId)), {
    onSuccess: (_data, variables) => {
      deleteEncroachmentById(variables);
    },
  });
};
