import { useMutation } from "react-query";
import { putBulkParcelsStatus } from "../../api";
import { useReloadParcels } from "../../hooks";
import { UpdateParcelStatus } from "../../types/responses";
import ApiClient from "../../utils/apiClient";

const useUpdateParcels = () => {
  const { reloadParcels } = useReloadParcels();

  const { mutateAsync } = useMutation(
    (value: UpdateParcelStatus) => ApiClient.put<UpdateParcelStatus>(putBulkParcelsStatus(), value),
    {
      onSettled: () => {
        reloadParcels();
      },
    }
  );

  return { updateParcels: mutateAsync };
};

export default useUpdateParcels;
