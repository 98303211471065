import { FC, useCallback, useMemo } from "react";
import L from "leaflet";

import {
  CommentContent,
  CommentContentHeader,
  CommentContentReplies,
  CommentContentTxt,
  CommentStatus,
  CommentWrapper,
} from "./Comments.styled";

import { ReactComponent as Reply } from "./../../assets/images/reply.svg";
import { ReactComponent as Unresloved } from "./../../assets/images/unread.svg";
import { ReactComponent as Resolved } from "./../../assets/images/read.svg";
import { ReactComponent as CommentUnread } from "./../../assets/images/unread_comment.svg";
import { ReactComponent as CommentRead } from "./../../assets/images/read_comment.svg";
import { CommentDto } from "../../types/responses";
import { timeLabel } from "../../pages/MapScreen/CommentDrawer/CommentDrawer.utils";
import { TriangleTooltip } from "../Tooltip/TriangleTooltip";
import { useChangeCommentStatus } from "../../hooks/useChangeCommentStatus";

type CommentProp = {
  comment: CommentDto;
  reloadComments: () => void;
  showStatus?: boolean;
  showReplies?: boolean;
  showReadStatus?: boolean;
  selectComment: (id: string) => void;
  selectedCommentId: Nullable<string>;
  hoverDisabled?: boolean;
};

export const Comment: FC<CommentProp> = ({
  comment,
  reloadComments,
  showStatus = true,
  showReplies = true,
  showReadStatus = false,
  selectComment,
  selectedCommentId,
  hoverDisabled = false,
}) => {
  const useCommentChangeStatus = useChangeCommentStatus();

  const setCommentStatus = useCallback(
    (status: "resolve" | "unresolve", e) => {
      L.DomEvent.stopPropagation(e);

      useCommentChangeStatus.mutate({ commentId: comment.id, status });
    },
    [useCommentChangeStatus, comment]
  );

  const readStatus = useMemo(() => {
    if (!showReadStatus) {
      return <></>;
    }
    return !comment.read ? <CommentRead /> : <CommentUnread />;
  }, [showReadStatus, comment]);

  const readComment = useCallback(
    (comment: CommentDto) => {
      if (!comment || comment.read) {
        return;
      }
      useCommentChangeStatus.mutate({ commentId: comment.id, status: "read" });
    },
    [useCommentChangeStatus]
  );

  const selectCommentWithRead = useCallback(
    (comment: CommentDto, e) => {
      if (hoverDisabled) {
        return;
      }
      L.DomEvent.stopPropagation(e);
      readComment(comment);
      selectComment(comment.id);
    },
    [readComment, selectComment, hoverDisabled]
  );

  if (!comment) {
    return <></>;
  }
  return (
    <CommentWrapper>
      {readStatus}

      <CommentContent
        selected={comment?.id === selectedCommentId}
        hoverDisabled={hoverDisabled}
        onClick={(e) => selectCommentWithRead(comment, e)}
      >
        <CommentContentHeader>
          <div className="comment-username">
            <div>{comment.username}</div>
            <div className="comment-time">{`${timeLabel(comment.postedAt)}`}</div>
          </div>
          {showStatus && (
            <CommentStatus
              onClick={(e) => {
                setCommentStatus(comment.resolved ? "unresolve" : "resolve", e);
              }}
            >
              <TriangleTooltip
                under={false}
                label={`${comment.resolved ? "Resolve" : "Unresolve"}`}
                light={false}
                marginTop={6}
              >
                {!comment.resolved ? <Resolved /> : <Unresloved />}
              </TriangleTooltip>
            </CommentStatus>
          )}
        </CommentContentHeader>
        <div>
          <CommentContentTxt>{comment.text}</CommentContentTxt>
          {showReplies && comment.replies?.length && (
            <CommentContentReplies>
              <Reply />
              <div> {`${comment.replies.length} ${comment.replies.length === 1 ? "reply" : "replies"}`}</div>
            </CommentContentReplies>
          )}
        </div>
      </CommentContent>
    </CommentWrapper>
  );
};
