import styled from "styled-components";
import { LayoutProps } from "styled-system";
import RemoveImage from "./../../assets/images/remove.svg";

export const ThumbnailWrapper = styled.div<LayoutProps & { isSelected?: boolean }>`
  height: ${({ height }) => height || "67px"};
  width: ${({ width }) => width || "92px"};
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #d9e3ea;
  overflow: hidden;
  box-sizing: border-box;
  outline: 2px solid ${({ isSelected }) => (isSelected ? "#1FA7B2" : "transparent")};

  ${({ maxWidth }) => maxWidth && `flex: 0 0 ${maxWidth}`};

  &:hover {
    outline-color: #187f86;
  }
`;

export const DeleteButton = styled.button`
  content: "";
  right: 5px;
  bottom: 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: #ffffff url(${RemoveImage}) no-repeat center;
  opacity: 0;
  transition: opacity 0.2s;
  border: none;
  cursor: pointer;

  ${ThumbnailWrapper}:hover & {
    opacity: 1;
  }
`;
