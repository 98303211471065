import styled from "styled-components";

import { STICKY_HEADER_LHS } from "../../../constants/z-index";
import { NameTax } from "../TaxName/TaxName.styled";

interface ContentItemStyledProps {
  isOpen: boolean;
  isItemSelected: boolean;
  isSubItemSelected: boolean;
  hasItems: boolean;
  sticky: boolean;
}

export const ContentItemStyled = styled.div<ContentItemStyledProps>`
  color: ${(props) => props.theme.colors.white};
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${(props) => {
    if (props.isItemSelected && props.sticky) {
      return `position: sticky;
      top: 0px;
      z-index: ${STICKY_HEADER_LHS};`;
    }
  }};
  background-color: ${(props) => {
    if (props.isItemSelected && !props.isSubItemSelected) {
      return props.theme.colors.blueChill;
    }
    return props.isOpen ? props.theme.colors.catskillWhite : "inherit";
  }};
  &:hover {
    background-color: ${(props) =>
      props.isItemSelected && !props.isSubItemSelected ? props.theme.colors.blueChill : props.theme.colors.iceberg};
  }
  ${NameTax} {
    color: ${(props) =>
      props.isItemSelected && !props.isSubItemSelected ? props.theme.colors.white : props.theme.colors.riverBad};
  }
`;

export const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-width: 274px;
  justify-content: space-between;
  margin-right: 6px;
`;
