import { useQuery } from "react-query";

import ApiClient from "../utils/apiClient";
import { rolesCrewsUrl } from "../api";
import { logError } from "../services";

export const QUERY_KEY = "crew-roles";

export type Roles = {
  contractorRoles: {
    name: string;
    id: string;
  }[];
};

export const useCrewRoles = () => {
  return useQuery(
    QUERY_KEY,
    () => ApiClient.get<Roles>(rolesCrewsUrl).then((response) => response.data.contractorRoles),
    {
      onError: (e) => logError(e),
    }
  );
};

export default useCrewRoles;
