import { useQuery } from "react-query";
import ApiClient from "../utils/apiClient";
import { logError } from "../services";
import { patrolSearchUrl } from "../api";
import { PatrolSearchResposnse } from "../types/responses/Patrols";

const getPatrolsForSearch = () =>
  ApiClient.get<PatrolSearchResposnse>(patrolSearchUrl).then((response) => response.data.patrols);

export const usePatrolsSearch = () => {
  return useQuery("patrols-search", getPatrolsForSearch, {
    onError: logError,
  });
};

export default usePatrolsSearch;
