import { FC, useEffect, useState, useCallback, useMemo, useRef } from "react";

import { Portal } from "../Portal";
import {
  PreviewModal,
  Header,
  Date as DateStyled,
  Title,
  NavigationButton,
  Content,
  MessagesWrapper,
  ReplyWrapper,
  ThumnbailsWrapper,
  Divider,
} from "./Preview.styled";
import { StyledFlex } from "../../assets/styles/flex.styled";
import { ReactComponent as ExternalLink } from "../../assets/images/external_link.svg";
import { ReactComponent as Download } from "../../assets/images/download.svg";
import { ReactComponent as Trash } from "../../assets/images/remove.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow_l.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow_r.svg";
import { ReactComponent as Close } from "../../assets/images/close_custom.svg";
import { IconButton } from "../IconButton";
import { Thumbnail, FileAttachment } from "../Thumbnail";
import { FilePreviewer } from "../FilePreviewer";
import { Backdrop } from "../Backdrop";
import { useKeyPress } from "../../hooks/useKeyPress";
import { AttachmentDto, AttachmentSize } from "../../types/responses/Attachments";
import { toLocalDate } from "../../utils/dateUtils";
import { logError } from "../../services";
import { getFileType } from "../../pages/MapScreen/RightDrawerMenu/RightDrawerMenu.utils";
import { Comments } from "../Comments";
import { Description } from "./Description";
import { ReplyTextArea } from "../Comments/ReplyTextArea";
import { useAuthStore } from "../../stores";
import { useCreateComment } from "../../hooks/useCreateComment";
import { CommentDto } from "../../types/responses";
import { useUpdateAttachmentDescription } from "../../hooks/useUpdateAttachmentDescription";
import { Key } from "../../constants/keys";

const SLIDER_CLASS = "slider";

interface Props {
  closePreivew: () => void;
  files: FileAttachment[];
  initialFile?: Maybe<AttachmentDto>;
  showPreview: boolean;
  onDelete?: (fileId: string) => void;
  getFile: (attachmentId: string, size: AttachmentSize) => Promise<Maybe<AttachmentDto>>;
  selectedIdx: number;
}

const fetchAndDownloadBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link?.parentNode?.removeChild(link);
};

export const Preview: FC<Props> = ({
  files,
  initialFile,
  selectedIdx,
  showPreview,
  closePreivew,
  onDelete,
  getFile,
}) => {
  const [selectedFile, setSelectedFile] = useState<Maybe<AttachmentDto>>(initialFile);
  const [selectedIndex, setSelectedIndex] = useState(selectedIdx);
  const [isLoading, setIsLoading] = useState(false);
  const [replyEnabled, setReplyEnabled] = useState(false);
  const [initialTextValue, setInitialTextValue] = useState<string>("");
  const leftArrow = useKeyPress(Key.ArrowLeft);
  const rightArrow = useKeyPress(Key.ArrowRight);
  const user = useAuthStore((store) => store.user);
  const postNewComment = useCreateComment({ reloadComments: true });
  const updateDescription = useUpdateAttachmentDescription();
  const commentsEndRef = useRef<Nullable<HTMLDivElement>>(null);

  const hasDescription = selectedFile?.description && selectedFile.description?.length > 0;

  const onPrev = useCallback(async () => {
    const index = selectedIndex === 0 ? files.length - 1 : selectedIndex - 1;
    const file = files.find((file, idx) => idx === index);
    const previewFile = await getFile(file?.id!, AttachmentSize.Preview);
    setSelectedIndex(index);
    setSelectedFile(previewFile);
  }, [files, getFile, selectedIndex]);

  const onNext = useCallback(async () => {
    const index = selectedIndex === files.length - 1 ? 0 : selectedIndex + 1;
    const file = files.find((file, idx) => idx === index);
    const previewFile = await getFile(file?.id!, AttachmentSize.Preview);
    setSelectedIndex(index);
    setSelectedFile(previewFile);
  }, [files, getFile, selectedIndex]);

  const onThumbnail = async (file: FileAttachment) => {
    const previewFile = await getFile(file?.id!, AttachmentSize.Preview);

    if (previewFile !== null) {
      setSelectedFile(previewFile!);
      setSelectedIndex(files?.indexOf(file) || 0);
    }
  };

  const scrollToLastComment = () => {
    if (!commentsEndRef?.current) {
      return;
    }
    commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onDownload = async () => {
    const original = await getFile(selectedFile?.id!, AttachmentSize.Original);

    setIsLoading(true);
    fetch(original?.fileUrl!, { method: "GET" })
      .then((response) => response.blob())
      .then((blob) => fetchAndDownloadBlob(blob, selectedFile?.fileName!))
      .catch((error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    document
      .querySelector(`.${SLIDER_CLASS} > div:nth-child(${selectedIndex + 1})`)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [selectedFile, selectedIndex]);

  useEffect(() => {
    leftArrow && onPrev();
    rightArrow && onNext();
  }, [leftArrow, rightArrow, onPrev, onNext]);

  const postComment = useCallback(
    (entityId: string, text: string) => {
      setInitialTextValue(" ");
      postNewComment
        .mutateAsync({ text: text, entityId: entityId })
        .then((res) => {
          setSelectedFile(
            (prevValue) =>
              ({
                ...prevValue,
                comments: [...prevValue!.comments, res.data],
              } as Maybe<AttachmentDto>)
          );
        })
        .catch((e) => logError(e))
        .finally(() => {
          setInitialTextValue("");
          scrollToLastComment();
        });
    },
    [postNewComment]
  );

  const updateAttachmentDescription = useCallback(
    (description: string) => {
      updateDescription
        .mutateAsync({ description: description, entityId: selectedFile?.id ?? "" })
        .then((res) => {
          if (!res?.data) {
            return;
          }
          setSelectedFile(res.data as AttachmentDto);
        })
        .catch((e) => logError(e));
    },
    [updateDescription, selectedFile]
  );

  const comments: CommentDto[] = useMemo(() => {
    if (!selectedFile?.comments) {
      return [];
    }
    return selectedFile.comments.sort((a, b) => new Date(a.postedAt).getTime() - new Date(b.postedAt).getTime());
  }, [selectedFile?.comments]);

  return (
    <Portal selector="layer-menu-root">
      <Backdrop isHidden={!showPreview} setIsHidden={closePreivew}>
        <div>
          <PreviewModal>
            <Header>
              <StyledFlex width={"100%"} justifyContent={"space-between"}>
                <StyledFlex gap={"10px"}>
                  <IconButton
                    as="a"
                    href={selectedFile?.fileUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    text="Open in new tab"
                    className="secondary"
                    Icon={ExternalLink}
                  />
                  <IconButton
                    className="secondary"
                    onClick={onDownload}
                    text="Download"
                    Icon={Download}
                    isLoading={isLoading}
                  />
                </StyledFlex>
                <StyledFlex gap={"10px"}>
                  {onDelete && (
                    <IconButton
                      className="secondary"
                      text="Delete"
                      Icon={Trash}
                      onClick={() => {
                        onDelete(selectedFile?.id!);
                        if (files.length <= 1) return closePreivew();
                        onNext();
                      }}
                    />
                  )}
                  <IconButton className="close" onClick={closePreivew} Icon={Close} />
                </StyledFlex>
              </StyledFlex>
            </Header>
            <Content>
              {selectedFile && <FilePreviewer file={selectedFile!} type={getFileType(selectedFile.fileName)} />}
              <MessagesWrapper>
                <StyledFlex
                  flexDirection={"column"}
                  marginTop={"15px"}
                  marginLeft={"20px"}
                  marginBottom={"15px"}
                  gap={"5px"}
                >
                  <Title title={selectedFile?.fileName}>{selectedFile?.fileName}</Title>
                  <DateStyled>{toLocalDate(selectedFile?.createdDateTime!, "MMMM dd, yyyy'-'HH:mm")}</DateStyled>
                </StyledFlex>
                <Description
                  description={selectedFile?.description ?? ""}
                  placeholder={selectedFile?.description ? "" : "Leave description"}
                  username={selectedFile?.uploadedBy ?? ""}
                  isEditable={(user && selectedFile && user?.email === selectedFile?.uploadedBy) ?? false}
                  saveDescription={updateAttachmentDescription}
                />
                <Divider />
                <StyledFlex flexDirection={"column"} marginBottom={"60px"}>
                  <Comments
                    hoverDisabled
                    comments={comments}
                    reloadComments={() => {}}
                    selectedCommentId={null}
                    selectComment={(_id) => {}}
                    showResolvedButton={false}
                    showReadButton={false}
                    showReplies={false}
                  />
                  <div ref={commentsEndRef}></div>
                  <ReplyWrapper enabled={replyEnabled}>
                    {hasDescription && (
                      <ReplyTextArea
                        maxRows={10}
                        initialValue={initialTextValue}
                        setReplyAreaState={setReplyEnabled}
                        {...{ rows: 3 }}
                        onSubmit={(value: string) => postComment(selectedFile?.id ?? "", value)}
                        placeholder={"Leave additional description"}
                      />
                    )}
                  </ReplyWrapper>
                </StyledFlex>
              </MessagesWrapper>
            </Content>
            <NavigationButton Icon={ArrowLeft} className="primary prev" onClick={onPrev} />
            <NavigationButton Icon={ArrowRight} className="primary next" onClick={onNext} />
          </PreviewModal>
          <ThumnbailsWrapper className={SLIDER_CLASS}>
            {files?.map((file) => (
              <Thumbnail
                key={file.id}
                file={file}
                isSelected={file.id === selectedFile?.id}
                onClick={() => onThumbnail(file)}
                maxWidth="53px"
                height="43px"
              />
            ))}
          </ThumnbailsWrapper>
        </div>
      </Backdrop>
    </Portal>
  );
};
