import styled from "styled-components";
import { color, ColorProps } from "styled-system";
import { LEGEND_WORK_TYPE } from "../../constants";

export const TreeCanopyLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--colors-legend-popup-bgd);
  min-width: 185px;
  bottom: 24px;
  padding: 19px 10px 26px 15px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 10px;
  z-index: ${LEGEND_WORK_TYPE};
`;

export const TreeCanopyLegendTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--colors-white);
  display: flex;
  justify-content: space-between;
`;
export const TreeCanopyLegendInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--colors-oslo-gray);
`;

export const TreeCanopyTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TreeCanopyGradientWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TreeCanopyLegendValues = styled.div`
  display: flex;
  text-align: center;
  gap: 8px;
  flex-direction: column;
  min-height: 20px;
`;

export const TreeCanopyLegendValueItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TreeCanopyLegendColor = styled.div<ColorProps>`
  border-radius: 10px;
  width: 14px;
  height: 7px;
  box-sizing: border-box;
  ${color}
`;

export const TreeCanopyLegendName = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: var(--colors-white);
  font-style: normal;
  font-weight: normal;
  padding-left: 10px;
`;

export const ExpandCollapseLegend = styled.div<{ selected?: boolean }>`
  width: 17px;
  height: 17px;
  display: inline-flex;
  align-items: flex-end;
  border-radius: 2px;
  margin-top: -9px;
  border: 1px solid #80888a;
  cursor: pointer;

  :hover {
    border: 1px solid #27a4ae;
  }
`;
export const MinMaxImage = styled.img<{ expanded: boolean }>`
  width: 10px;
  height: 10px;
  padding-bottom: ${(props) => (props.expanded ? "0px" : "5px")};
  padding-left: ${(props) => (props.expanded ? "0px" : "7px")};
`;
