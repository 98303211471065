import { FC, useEffect, useMemo, useState } from "react";
import { EMPTY_FN } from "../../constants";
import { timeLabel } from "../../pages/MapScreen/CommentDrawer/CommentDrawer.utils";
import { CommentDto } from "../../types/responses";
import {
  CommentContainer,
  CommentContent,
  CommentContentHeader,
  CommentContentReplies,
  CommentContentTxt,
  CommentContentWithReply,
  CommentContentWrapper,
  CommentPopupWrapper,
  CommentStatus,
  PopupClose,
  PopupHeader,
  PopupStatusHeader,
  ReplyButtonWrapper,
  ReplyPopupContent,
} from "./CommentPopup.styled";
import { ReactComponent as Reply } from "./../../assets/images/reply.svg";
import { ReplyTextArea } from "./ReplyTextArea";
import { ReactComponent as UnreadComment } from "./../../assets/images/unread_comment.svg";
import { ReactComponent as ReadComment } from "./../../assets/images/read_comment.svg";
import { TriangleTooltip } from "../Tooltip/TriangleTooltip";
import { ReactComponent as RemoveImage } from "./../../assets/images/remove_2.svg";
import { StyledFlex } from "../../assets/styles/flex.styled";

export const ReplyComment: FC<{
  comment: CommentDto;
  showReplies: boolean;
  setFullContent: () => void;
  isFullContentVisible: boolean;
  popupUpdate: () => void;
}> = ({ comment, showReplies, setFullContent, isFullContentVisible, popupUpdate }) => {
  if (!comment) {
    return <></>;
  }
  return (
    <CommentContentWithReply fullContent={isFullContentVisible}>
      <CommentContentHeader>
        <div className="comment-username">
          <div>{comment.username}</div>
          <div className="comment-time">{`${timeLabel(comment.postedAt)}`}</div>
        </div>
      </CommentContentHeader>
      <ReplyPopupContent
        onClick={() => {
          setFullContent();
          popupUpdate();
        }}
      >
        <CommentContentTxt>{comment.text}</CommentContentTxt>
        {showReplies && comment.replies?.length && (
          <CommentContentReplies>
            <Reply />
            <div> {`${comment.replies.length} ${comment.replies.length === 1 ? "reply" : "replies"}`}</div>
          </CommentContentReplies>
        )}
      </ReplyPopupContent>
    </CommentContentWithReply>
  );
};
type CommentPopupProps = {
  comment: CommentDto;
  fullContent?: boolean;
  closePopup?: () => void;
  postReply: (text: string) => void;
  popupUpdate: () => void;
  setResolved?: () => void;
  deleteComment?: () => void;
};
export const CommentPopup: FC<CommentPopupProps> = ({
  comment,
  fullContent = false,
  closePopup = EMPTY_FN,
  postReply,
  popupUpdate,
  setResolved,
  deleteComment = EMPTY_FN,
}) => {
  const [isFullContentVisible, setFullContentVisible] = useState(fullContent);
  const [replyEnabled, setReplyEnabled] = useState(false);

  useEffect(() => {
    if (!isFullContentVisible) {
      return;
    }
    popupUpdate();
  }, [isFullContentVisible, popupUpdate]);

  const commentWithReplies: { showReplies: boolean; comment: CommentDto }[] = useMemo(() => {
    if (!comment) {
      return [];
    }
    if (!isFullContentVisible) {
      return [{ showReplies: true, comment: comment }];
    }
    const replies: { showReplies: boolean; comment: CommentDto }[] =
      comment?.replies?.map((reply) => ({
        showReplies: false,
        comment: {
          postedAt: reply.postedAt,
          text: reply.text,
          replies: [],
          read: false,
          resolved: false,
          username: reply.username,
          id: reply.id,
          lat: 0,
          lon: 0,
        },
      })) ?? [];
    return [{ showReplies: false, comment: comment }].concat(replies);
  }, [comment, isFullContentVisible]);

  if (!comment) {
    return <></>;
  }

  return (
    <CommentPopupWrapper>
      {isFullContentVisible && (
        <PopupHeader>
          <TriangleTooltip
            under={false}
            label={`${comment.resolved ? "Resolve" : "Unresolve"}`}
            light={false}
            marginTop={0}
          >
            <PopupStatusHeader resolved={comment.resolved} onClick={() => setResolved && setResolved()} />
          </TriangleTooltip>
          <StyledFlex alignItems={"center"}>
            <RemoveImage className="remove-comment" onClick={deleteComment} />
            <PopupClose onClick={closePopup} />
          </StyledFlex>
        </PopupHeader>
      )}
      <CommentContentWrapper fullContent={isFullContentVisible}>
        <CommentContainer>
          {isFullContentVisible && <CommentStatus>{comment.read ? <UnreadComment /> : <ReadComment />}</CommentStatus>}
          <CommentContent>
            {commentWithReplies?.length &&
              commentWithReplies.map((comment) => (
                <ReplyComment
                  popupUpdate={popupUpdate}
                  isFullContentVisible={isFullContentVisible}
                  setFullContent={() => {
                    if (!comment.showReplies) {
                      return;
                    }
                    setFullContentVisible(true);
                    popupUpdate();
                  }}
                  key={`popup-comment-${comment.showReplies}-${comment.comment.id}`}
                  comment={comment.comment}
                  showReplies={comment.showReplies}
                />
              ))}
          </CommentContent>
        </CommentContainer>
        {isFullContentVisible && (
          <ReplyButtonWrapper enabled={replyEnabled}>
            <ReplyTextArea
              {...{ rows: 1 }}
              setReplyAreaState={setReplyEnabled}
              onSubmit={postReply}
              onEcape={() => closePopup()}
              placeholder={"Reply"}
              maxRows={7}
            />
          </ReplyButtonWrapper>
        )}
      </CommentContentWrapper>
    </CommentPopupWrapper>
  );
};
