export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

export const ONLY_NUMBERS_WITH_TWO_DECIMALS = /^\d+(\.\d{1,2})?$/;

export const WITHOUT_SPECIAL_CHARS = /^[a-zA-Z0-9_-\s]*$/;

//only letters and numbers, space is also allowed
export const ONLY_NUMBER_AND_LETTERS = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

export const PHONE_NUMBER = /^[+]?([0-9][\s]?|[0-9]?)([(][0-9][)][\s]?|[0-9][-\s.]?)[0-9][-\s.]?[0-9]*$/im;
