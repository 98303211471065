import React, { FC, useEffect, useMemo, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { getLandingRoute, getForecastRoute, getStarCommandRoute } from "../../routes";

import ToggleSideBar from "../../components/ToggleSideBar/ToggleSideBar";
import { PageWrapper, PageContent } from "./MapScreen.styled";
import LeftSideControl from "./LeftSideControl/LeftSideControl";
import { MapView } from "./MapView/MapView";
import {
  useMapStore,
  useLeftControlStore,
  useSpansStore,
  useRightOfWayOverridesStore,
  usePredominatedWorkTypesStore,
  useHierarchyStore,
  useFeederStore,
  mapSpansWithWorkTypes,
} from "../../stores";
import { useCustomQuery, useFeeders, useSelectedRegion } from "../../hooks";
import {
  getImagerySourcesApiUrl,
  getServiceAreasApiUrl,
  getSwitchesApiUrl,
  getRightOfWayOverridesApiUrl,
  getTreeCanopyUrl,
  getPredominatedWorkTypesApiUrl,
  getHierarchyApiUrl,
  getVectorLayersApiUrl,
} from "../../api";
import { mapImagerySourcesData, computeExtentFetchData } from "./MapScreen.utils";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { Toast } from "../../components/Toast";
import {
  ImagerySourcesResponse,
  ServiceAreaList,
  RightOfWayOverridesResponse,
  HierarchyResponse,
  VectorLayers,
} from "../../types/responses";
import additionalData from "../../assets/test-data/additional_overlays.json";
import { SOMETHING_WENT_WRONG_MESSAGE } from "../../constants";
import { AdditionalOverlay } from "../../stores/MapStore";
import { isGeoJSONEmpty } from "../../utils/mapUtils";
import { SwitchesResponse } from "../../types/responses/SwitchesResponse";
import useSwitchesStore from "../../stores/SwitchesStore";
import { MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER } from "./MapView/MapView.constants";
import { TreeCanopyResponse } from "../../types/responses/TreeCanopyResponse";
import { useTreeCanopyStore } from "../../stores/TreeCanopyStore";
import { GetPredominantWorkResponseDto } from "../../types/responses/SpanWorkTypeResponse";
import { StarCommandScreen } from "../StarCommandScreen/StarCommandScreen";
import useSingleThreat from "../../hooks/useSingleThreat";
import useSingleSpan from "../../hooks/useSingleSpan";
import useSingleStructure from "../../hooks/useSingleStructure";

const REFETCH_RIGHT_OF_WAY_DATA_TIMEOUT = 1000;

const MapScreen: FC = () => {
  const toggled = useLeftControlStore((store) => store.toggled);

  const feedersGeoJson = useFeederStore((store) => store.feedersGeoJson);
  const feeders = useFeederStore((store) => store.feedersGeoJson);

  const setSpanWithWorkTypes = useSpansStore((store) => store.setSpanWithPredominatedWorkType);

  const setImagerySourcesData = useMapStore((store) => store.setImagerySourcesData);
  const map = useMapStore((store) => store.map);
  const selectedImagerySourceId = useMapStore((store) => store.selectedImagerySourceId);
  const setServiceAreas = useMapStore((store) => store.setServiceAreas);
  const selectedServiceArea = useMapStore((store) => store.selectedServiceArea);
  const setAdditionalOverlays = useMapStore((store) => store.setAdditionalOverlays);
  const setVectorLayers = useMapStore((store) => store.setVectorLayers);
  const setSwitchesData = useSwitchesStore((store) => store.setSwitchesData);
  const currentZoomLevel = useMapStore((store) => store.currentZoomLevel);
  const currentMapBounds = useMapStore((store) => store.currentMapBounds);

  const setRightOfWayOverridesData = useRightOfWayOverridesStore((store) => store.setData);
  const localRightOfWayOverrides = useRightOfWayOverridesStore((store) => store.localOverrides);

  const setTreeCanopyData = useTreeCanopyStore((store) => store.setData);
  const isTreeCanopyVisible = useTreeCanopyStore((store) => store.layerVisible);

  const initWorkTypes = usePredominatedWorkTypesStore((store) => store.initSelectedWorkTypes);
  const isPlannersViewHidden = usePredominatedWorkTypesStore((store) => store.hidden);
  const reloadPredominatedWorkTypesEnabled = usePredominatedWorkTypesStore((store) => store.reloadEnabled);

  const { setRootRegion } = useHierarchyStore((store) => store.actions);
  const rootRegion = useHierarchyStore((store) => store.rootRegion);
  const { setRegion } = useHierarchyStore((store) => store.actions);

  const selectedRegion = useSelectedRegion();

  useSingleThreat();
  useSingleSpan();
  useSingleStructure();
  useFeeders();

  const refetchClearanceTimeoutRef = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  // clear timeout ref
  useEffect(() => {
    return () => {
      refetchClearanceTimeoutRef.current && clearTimeout(refetchClearanceTimeoutRef.current);
    };
  }, []);

  const { isLoading: imagerySourcesDataIsLoading, isError: imagerySourcesDataIsError } =
    useCustomQuery<ImagerySourcesResponse>(getImagerySourcesApiUrl(), {
      select: mapImagerySourcesData,
      onSuccess: setImagerySourcesData,
    });

  const { isLoading: serviceAreaDataLoading, isError: serviceAreaDataIsError } = useCustomQuery<ServiceAreaList>(
    getServiceAreasApiUrl(),
    {
      onSuccess: (serviceAreas: ServiceAreaList) => {
        setServiceAreas(serviceAreas);
        setRootRegion(serviceAreas?.serviceAreas?.find((areas) => areas.isDefault)?.rootRegionId ?? null);
      },
    }
  );
  const enabledHierarchy =
    !serviceAreaDataLoading &&
    !imagerySourcesDataIsLoading &&
    !!selectedServiceArea &&
    !!selectedServiceArea?.id &&
    selectedServiceArea?.id !== "" &&
    !!selectedImagerySourceId;

  const {
    refetch,
    isLoading: loading,
    isError: isHierarchyError,
  } = useCustomQuery<HierarchyResponse>(
    getHierarchyApiUrl(selectedServiceArea?.id!, selectedImagerySourceId!, selectedRegion),
    {
      enabled: enabledHierarchy,
      onSuccess: (data: HierarchyResponse) => setRegion(selectedRegion ?? selectedServiceArea?.id ?? "", data, feeders),
    }
  );

  useEffect(() => {
    if (!selectedRegion) {
      return;
    }
    refetch();
  }, [selectedRegion, refetch]);

  useEffect(() => {
    if (!selectedServiceArea?.id || !selectedImagerySourceId || selectedServiceArea?.id === "") {
      return;
    }
    refetch();
  }, [selectedServiceArea, selectedImagerySourceId, refetch]);

  const topPriorityRequestsLoaded = !imagerySourcesDataIsLoading && !serviceAreaDataLoading;

  const { refetch: refetchRightOfWayOverridesData } = useCustomQuery<RightOfWayOverridesResponse>(
    getRightOfWayOverridesApiUrl(),
    {
      enabled: topPriorityRequestsLoaded,
      onSuccess: setRightOfWayOverridesData,
    }
  );

  const { data: predominantWorkTypes, refetch: refetchPredominatedWorkTypes } =
    useCustomQuery<GetPredominantWorkResponseDto>(getPredominatedWorkTypesApiUrl(), {
      enabled: topPriorityRequestsLoaded,
    });

  useEffect(() => {
    if (isPlannersViewHidden || !topPriorityRequestsLoaded || !reloadPredominatedWorkTypesEnabled) {
      return;
    }
    refetchPredominatedWorkTypes();
  }, [
    topPriorityRequestsLoaded,
    reloadPredominatedWorkTypesEnabled,
    isPlannersViewHidden,
    refetchPredominatedWorkTypes,
  ]);

  useCustomQuery<VectorLayers>(getVectorLayersApiUrl(), {
    enabled: topPriorityRequestsLoaded,
    onSuccess: setVectorLayers,
  });

  //re-fetch overrides data on change of local overrides
  useEffect(() => {
    if (!Object.keys(localRightOfWayOverrides).length) {
      return;
    }

    refetchRightOfWayOverridesData();
  }, [refetchRightOfWayOverridesData, localRightOfWayOverrides]);

  // set timeout to refetch computed data on change of local overrides
  useEffect(() => {
    if (!Object.keys(localRightOfWayOverrides).length) {
      return;
    }

    if (refetchClearanceTimeoutRef.current) {
      clearTimeout(refetchClearanceTimeoutRef.current);
      refetchClearanceTimeoutRef.current = null;
    }

    refetchClearanceTimeoutRef.current = setTimeout(() => {}, REFETCH_RIGHT_OF_WAY_DATA_TIMEOUT);
  }, [localRightOfWayOverrides]);

  useEffect(() => {
    if (!additionalData) {
      return;
    }
    setAdditionalOverlays(additionalData as Record<string, AdditionalOverlay>);
  }, [setAdditionalOverlays]);

  useCustomQuery<SwitchesResponse>(getSwitchesApiUrl(), {
    onSuccess: setSwitchesData,
  });

  const treeCanopyFetchData = useMemo(
    () => computeExtentFetchData({ currentZoomLevel, currentMapBounds }),
    [currentMapBounds, currentZoomLevel]
  );

  const { data: treeCanopyResponseData } = useCustomQuery<TreeCanopyResponse>(getTreeCanopyUrl(treeCanopyFetchData), {
    enabled: !!map && currentZoomLevel >= MIN_ZOOM_LEVEL_FOR_TREE_CANOPY_LAYER && isTreeCanopyVisible,
  });

  useEffect(() => {
    if (!treeCanopyResponseData) {
      return;
    }
    setTreeCanopyData(treeCanopyResponseData);
  }, [treeCanopyResponseData, setTreeCanopyData]);

  useEffect(() => {
    if (!predominantWorkTypes) {
      return;
    }
    initWorkTypes(predominantWorkTypes.workTypes);
    setSpanWithWorkTypes(mapSpansWithWorkTypes(predominantWorkTypes));
  }, [predominantWorkTypes, setSpanWithWorkTypes, initWorkTypes]);

  const dataLoading =
    (loading && selectedRegion === rootRegion) || //only on initial load
    serviceAreaDataLoading ||
    imagerySourcesDataIsLoading;

  const isError = isHierarchyError || serviceAreaDataIsError || imagerySourcesDataIsError;

  const shouldDisplayMap = !isError && !dataLoading && !isGeoJSONEmpty(feedersGeoJson);

  return (
    <div className="vantage-map">
      <PageWrapper>
        <Switch>
          <Route path={getStarCommandRoute()} component={StarCommandScreen} />
          <Route path={[getLandingRoute(), getForecastRoute()]}>
            <ToggleSideBar />
            <LeftSideControl />
            <PageContent toggled={toggled}>
              <Toast kind="error" hidden={!isError} margin="auto" mt={2} data-testid="map-error">
                {SOMETHING_WENT_WRONG_MESSAGE}
              </Toast>
              {shouldDisplayMap && <MapView />}
            </PageContent>
          </Route>
        </Switch>
      </PageWrapper>
      <LoadingOverlay hidden={!dataLoading} />
    </div>
  );
};

export default MapScreen;
