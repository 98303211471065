import React, { FC, useCallback } from "react";

import { Portal } from "../Portal";
import Close from "./../../assets/images/close_white.svg";
import ParcelImg from "./../../assets/images/parcel_icn.svg";
import SpanImg from "./../../assets/images/span_icn.svg";
import StructureImg from "./../../assets/images/structure_icn.svg";
import MaImg from "./../../assets/images/MA.svg";

import { MapSelectionPopupWrapper, ImageWrapper } from "./MapSelectionPopup.styled";
import {
  CloseImageWrapper,
  MapMeasurePopupCloseImage,
  MapMeasurePopupContent,
  MapMeasurePopupImage,
} from "../MapMeasurePopup/MapMeasurePopup.styled";
import { ASSET_TYPES, useMapSelectionStore, useAuthStore } from "../../stores";
import { TriangleTooltip } from "../Tooltip/TriangleTooltip";

interface MapSelectionPopupProps {
  hidden: boolean;
  onClick: () => void;
  rightMenuEnabled?: boolean;
  layerMenuEnabled?: boolean;
  leftMenuEnabled?: boolean;
  selectedType: Nullable<ASSET_TYPES>;
  selectType: (type: ASSET_TYPES) => void;
  selectionFrameHidden: boolean;
  setSelectionFrame: (value: boolean) => void;
}

export const MapSelectionPopup: FC<MapSelectionPopupProps> = ({
  hidden,
  onClick,
  rightMenuEnabled,
  leftMenuEnabled,
  layerMenuEnabled,
  selectedType,
  selectType,
  selectionFrameHidden,
  setSelectionFrame,
}) => {
  const isAdmin = useAuthStore((store) => store.isAdmin);
  const setDrawerVisbility = useMapSelectionStore((store) => store.setSelectionRHSDrawerVisibility);
  const handleClick = useCallback(
    (type: ASSET_TYPES) => {
      selectType(type);
      setDrawerVisbility(true);
    },
    [selectType, setDrawerVisbility]
  );

  if (hidden) {
    return <></>;
  }

  return (
    <Portal selector="layer-menu-root">
      <MapMeasurePopupContent
        rhsEnabled={rightMenuEnabled}
        lhsEnabled={leftMenuEnabled}
        layerMenuEnabled={layerMenuEnabled}
      >
        <MapSelectionPopupWrapper>
          <TriangleTooltip under label="Spans" light={false} marginTop={-12}>
            <ImageWrapper
              id="selection-tool-span"
              isActive={selectedType === ASSET_TYPES.SPAN}
              onClick={() => {
                handleClick(ASSET_TYPES.SPAN);
              }}
            >
              <MapMeasurePopupImage src={SpanImg} />
            </ImageWrapper>
          </TriangleTooltip>

          <TriangleTooltip under label="Parcels" light={false} marginTop={-12}>
            <ImageWrapper
              id="selection-tool-parcel"
              isActive={selectedType === ASSET_TYPES.PARCEL}
              onClick={() => {
                handleClick(ASSET_TYPES.PARCEL);
              }}
            >
              <MapMeasurePopupImage src={ParcelImg} />
            </ImageWrapper>
          </TriangleTooltip>
          <TriangleTooltip under label="Structures" light={false} marginTop={-12}>
            <ImageWrapper
              id="selection-tool-structure"
              isActive={selectedType === ASSET_TYPES.STRUCTURE}
              onClick={() => {
                handleClick(ASSET_TYPES.STRUCTURE);
              }}
            >
              <MapMeasurePopupImage src={StructureImg} />
            </ImageWrapper>
          </TriangleTooltip>
          {isAdmin && (
            <TriangleTooltip under label="MA Management" light={false} marginTop={-12}>
              <ImageWrapper
                id="selection-tool-ma"
                isActive={selectedType === ASSET_TYPES.MA}
                onClick={() => {
                  handleClick(ASSET_TYPES.MA);
                }}
              >
                <MapMeasurePopupImage src={MaImg} />
              </ImageWrapper>
            </TriangleTooltip>
          )}
          <CloseImageWrapper id="selection-tool-cancel" onClick={onClick}>
            <MapMeasurePopupCloseImage src={Close} />
          </CloseImageWrapper>
        </MapSelectionPopupWrapper>
      </MapMeasurePopupContent>
    </Portal>
  );
};
