import styled from "styled-components";

import { Data1, Data2 } from "../../assets/styles/fonts.styled";

import CloseImg from "./../../assets/images/close.svg";
import Unresolved from "./../../assets/images/read.svg";
import Resolved from "./../../assets/images/unread.svg";
import ReplyButtonImg from "./../../assets/images/reply_submit.svg";
import ReplyButtonEnabled from "./../../assets/images/reply_submit_enabled.svg";

export const CommentPopupWrapper = styled.div``;
export const CommentContainer = styled.div`
  display: flex;
`;
export const CommentContent = styled.div``;
export const CommentContentWrapper = styled.div<{ fullContent?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.fullContent ? "33px" : "0px")};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
export const CommentStatus = styled.div`
  height: 100%;
  min-height: 30px;
  margin-top: 13px;
  display: flex;
  min-width: 20px;
  margin-left: 10px;
  margin-right: 10px;

  svg path {
    stroke: none;
  }
`;

export const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;
  width: 99%;
  border-bottom: 1px solid #e9e9e9;
  padding: 0;
  position: fixed;
  background: var(--colors-white);
  z-index: 1200;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 0px;
  .remove-comment {
    cursor: pointer;
    margin-right: 7px;
    path {
      fill: var(--colors-black);
    }
  }

  .remove-comment:hover path {
    fill: var(--colors-eastern-blue);
  }
`;

export const PopupStatusHeader = styled.div<{ resolved: boolean }>`
  width: 33px;
  height: 33px;
  background-position: center;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.resolved ? Resolved : Unresolved)});
  cursor: pointer;
`;

export const PopupClose = styled.div`
  width: 33px;
  height: 33px;
  background-position: center;
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-image: url(${CloseImg});
  cursor: pointer;
`;

export const CommentContentWithReply = styled.div<{ fullContent: boolean }>`
  background: var(--colors-white);
  border-radius: 6px;
  margin-left: ${(props) => (props.fullContent ? "0px" : "10px")};
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CommentContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .comment-username {
    ${Data1}
    display: flex;
    align-items: center;
    .comment-time {
      margin-left: 5px;
      font-weight: 500;
      font-size: 11px;
      color: #b1b1b1;
    }
  }
`;

export const CommentContentTxt = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #7b8182;
  min-height: 20px;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  padding-right: 10px;
`;

export const CommentContentReplies = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: var(--colors-eastern-blue);
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 2px;
  }
`;

export const ReplyPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ReplyActionWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  background: #fbfbfb;
  border: ${(props) => (props.active ? "1px solid #2aaab6" : "1px solid #d6d6d6")};
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  margin: 0px 10px 10px 10px;
  textarea {
    border: none;
    outline: none;
    resize: none;
    flex: 1;
    padding-left: 9px;
    background-color: transparent;
    ${Data2}
    ::placeholder {
      font-weight: 600;
      font-size: 14px;
      color: var(--colors-silver);
    }
  }
`;

export const ReplyButton = styled.div<{ enabled: boolean }>`
  width: 19px;
  height: 19px;
  margin: 9px 9px 9px 0px;
  background-color: #e7ecee;
  border-radius: 3px;
  cursor: pointer;
  background-position: center;
  background-size: ${(props) => (props.enabled ? "18px 18px" : "10px 10px")};
  background-repeat: no-repeat;
  background-image: url(${(props) => (props.enabled ? ReplyButtonEnabled : ReplyButtonImg)});
`;

export const ReplyButtonWrapper = styled.div<{ enabled: boolean }>`
  div:first-child {
    margin: 10px;
    border: 1px solid ${(props) => (props.enabled ? "var(--colors-teal-3)" : "var(--colors-alto)")};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
