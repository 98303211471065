import styled from "styled-components";

import { RIGHT_DRAWER, SIDEBAR_MENU } from "../../constants/z-index";
import { ReactComponent as Close } from "../../assets/images/close_custom.svg";
import { RIGHT_SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../../constants/dimensions";
import { Head1, Head2 } from "../../assets/styles/fonts.styled";
import { space, SpaceProps } from "styled-system";
import { Link } from "react-router-dom";

export const RightDrawerWrapper = styled.div`
  width: ${RIGHT_SIDEBAR_WIDTH}px;
  background-color: ${(props) => props.theme.colors.alabaster3};
  position: absolute;
  right: 0px;
  top: ${TOP_HEADER_HEIGHT}px;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
  display: block;
  z-index: ${RIGHT_DRAWER + 1};
`;

export const SectionWrapper = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 60px - ${TOP_HEADER_HEIGHT}px);
  width: 100%;
  overflow-x: hidden;
  margin-top: 60px;
`;

export const Sections = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

export const AssetImage = styled.img<{ singleColumn: boolean }>`
  display: ${(props) => (props.singleColumn ? "block" : "none")};
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
`;

export const RightDrawerHeader = styled.div<{ singleColumn: boolean; borderBottom?: boolean; scroll?: boolean }>`
  height: 60px;
  display: flex;
  background-color: var(--colors-alabaster-3);
  padding-left: 20px;
  flex-direction: column;
  justify-content: center;
  div:first-child {
    height: 28px;
    display: flex;
    align-items: center;
    margin-top: 0px;
  }
  border-bottom: ${(props) => props.borderBottom && "1px solid var(--colors-lynx-white)"};
  ${(props) => {
    if (props.scroll) {
      return `background: ${props.theme.colors.white};
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.23);`;
    }
  }};
  z-index: ${SIDEBAR_MENU};
  position: fixed;
  width: ${RIGHT_SIDEBAR_WIDTH}px;
`;
export const RightDrawerHeaderColumn = styled.div<{ singleColumn?: boolean }>`
  margin-top: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightDrawerHeaderPartLeft = styled.div`
  flex: 1;
  ${Head1}
  margin-left: 10px;
`;

export const RightDrawerHeaderPartRight = styled.div`
  flex: 1;
`;

export const CloseIcon = styled(Close)`
  height: 12px;
  width: 12px;
  flex: 1;
  fill: #85919e;
  &:hover {
    fill: ${(props) => props.theme.colors.easternBlue};
  }
  &:active {
    fill: ${(props) => props.theme.colors.white};
  }
`;

export const BackButton = styled.img`
  height: 12px;
  width: 7px;
  flex: 1;
`;

export const CloseImageWrapper = styled.div<SpaceProps>`
  width: 30px;
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors.mystic};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  vertical-align: center;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  margin-right: 30px;
  &:hover {
    background: #d2f0f2;
  }
  &:active {
    background: #0c848e;
  }
  ${space}
`;

export const BackImageWrapper = styled.div`
  width: 28px;
  height: 28px;
  background: var(--colors-eastern-blue);
  border-radius: 4px;
  display: flex;
  vertical-align: center;
  align-items: center;
  cursor: pointer;
`;

export const SectionHeader = styled.div`
  ${Head2}
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  user-select: none;
  padding-top: 16px;
  padding-bottom: 16px;
  .input-file {
    display: none;
  }
`;

export const SectionHeaderAction = styled.span<{ disabled: boolean }>`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: ${(props) => (props.disabled ? "#C2CDCE" : "#9F9F9F")};
  cursor: pointer;
  text-transform: none;
  user-select: none;
`;

export const SectionAction = styled.span<{ disabled: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;
  color: ${(props) => (props.disabled ? "#C2CDCE" : "#9F9F9F")};
  cursor: pointer;
  text-transform: none;
  user-select: none;
  display: flex;
  padding-bottom: 25px;
  padding-left: 20px;
`;

export const SectionContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const SectionContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  width: 338px;
`;

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`;

export const CreateWorkOrderBtn = styled(Link)`
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  margin-bottom: 20px;
  line-height: 1;
  font-size: 15px;
  padding: 9.5px;
  background-color: var(--colors-eastern-blue);
  color: var(--colors-white);
  border-radius: 5px;
  font-weight: 700;
  border: 1px solid transparent;

  &:hover {
    background-color: var(--colors-java);
    border-color: #29adb9;
  }
`;
