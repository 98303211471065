import styled from "styled-components";

export const SingleItemWrapper = styled.div`
  flex: 1;
  vertical-align: center;
  display: flex;
  align-items: center;
  position: relative;
`;

export const TableFooterRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin-top: 0;
  }
`;
