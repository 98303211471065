import styled from "styled-components";

import { position, PositionProps } from "styled-system";
import { MAP_MENU } from "../../constants";
import { ReactComponent as MeasureImg } from "./../../assets/images/measure_distance.svg";

export const MeasureToolWrapper = styled.div<PositionProps>`
  ${position}
  z-index: ${MAP_MENU};
  cursor: pointer;
  button {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.42);
  }
`;

export const MeasureToolImg = styled(MeasureImg)<{ turnedOn: boolean }>`
  path {
    fill: ${(props) => (!props.turnedOn ? "var(--colors-white)" : "var(--colors-trout-2)")};
  }
`;
